








































































































































@import '@/styles/mixins/index';

.idt-event-prize {
  --border-style: 3px solid var(--border-color);

  $prize: &;
  display: flex;
  flex-direction: column;
  width: 375px;
  border: 1px solid var(--grey);
  border-bottom: var(--border-style);
  border-radius: var(--global-radius);
  overflow: hidden;

  @include respond-to(max-small) {
    width: 300px;
  }

  &.inline {
    // Inline styles only apply on bigger screens
    @include respond-to(xlarge) {
      flex-direction: row;
      width: 100%;
      border-bottom: none;
      border-left: var(--border-style);

      #{$prize}__image {
        flex: 1 1 20%;
        height: 100%;
        max-width: 205px;
        min-width: 50px;
      }

      #{$prize}__content {
        flex: 1 1 60%;
        min-height: 0;
        padding: 1rem 2rem;
        text-align: left;

        &__title {
          font-size: var(--rem-size-18);
        }

        &__ref {
          font-size: var(--rem-size-24);
        }

        &__description {
          margin-bottom: 0;
          font-size: var(--rem-size-14);
        }

        &__sponsor {
          display: none;
        }
      }

      #{$prize}__sponsor-inline {
        display: flex;
        flex: 1 1 20%;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: 100px;
        padding: 1rem;
        background: var(--white);
        border-left: 1px solid var(--grey-light);
        text-align: center;

        &__logo {
          width: 100%;
          max-width: 130px;
          height:auto;
        }
      }
    }

  }

  &__image {
    height: 195px;
    width: 100%;
    object-fit: cover;

    @include respond-to(max-small) {
      height: 150px;
    }

    &--placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--grey);
    }
  }

  &__content {
    flex-grow: 1;
    min-height: 195px;
    padding: 2rem 2.5rem;
    text-align: center;
    background: white;

    @include respond-to(max-small) {
      min-height: 0;
    }

    @include respond-to(max-medium) {
      padding: 1rem 1.5rem;
    }

    &__title {
      color: var(--black);
      font-weight: var(--font-weight-bold);
    }

    &__ref {
      font-weight: var(--font-weight-bold);
    }

    &__description {
      color: var(--black);
      font-size: var(--rem-size-14);

      @include respond-to(large) {
        font-size: var(--rem-size-16);
      }

      @include respond-to(max-small) {
        font-size: var(--rem-size-12);
      }
    }

    &__sponsor {

      &__hint {
        margin-bottom: .5rem;
        font-size: var(--rem-size-12);
        color: var(--black);
      }

      &__link.no-link {
        cursor: default;
      }

      &__logo {
        max-width: 100px;
        height: auto;
      }
    }
  }

  &__sponsor-inline {
    display: none;
  }
}
