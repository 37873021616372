.idt-card-file-upload-header__preview {
  position: relative;
  width: 100%;
  padding-top: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: var(--global-radius);
  border-top-left-radius: var(--global-radius);
}

.idt-card-file-upload-header__preview:hover .idt-card-file-upload-header__file.has-file {
  cursor: pointer;
  background-color: rgba(0,0,0,.7);
}

.idt-card-file-upload-header__preview:hover .idt-card-file-upload-header__file.has-file span.white-hovered {
  color: var(--white);
}

.idt-card-file-upload-header__file {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--global-radius) var(--global-radius) 0 0;
  border: solid 2px var(--main-color);
  font-weight: 400;
  cursor: pointer;
}

.idt-card-file-upload-header__file--error {
  border: solid 2px var(--secondary-color);
}

.idt-card-file-upload-header__file__button {
  color: var(--main-color);
  background-color: var(--white);
  font-size: 16.8px;
  text-align: center;
}

.idt-card-file-upload-header__file__label {
  color: var(--main-color);
}

.idt-card-file-upload-header__file__circle {
  color: var(--main-color);
  font-size: 36px;
  cursor: pointer;
}

.idt-card-file-upload-header__file__info {
  margin-top: 11px;
  color: #7f7f7f;
  font-size: 12.8px;
  text-align: center;
}

.idt-card-file-upload-header__file__delete {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  padding: .5rem;
  color: #fff;
  background-color: #eb4a80;
  border-radius: 0 0 0 var(--global-radius);
  font-size: 1.2rem;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}

