.line-chart__line {
  fill: none;
  stroke: var(--main-color);
  stroke-width: 3px;
}

.line-chart__circle {
  fill: var(--main-color);
  stroke: var(--main-color);
}

