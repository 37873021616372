
















































































































.idt-span-editable {
  &__editable {
    display: block;
    min-height: 2rem;
    padding: 0.4rem;
    resize: none;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid var(--grey-light);
    border-radius: 5px;
    background-color: var(--white);

    &:focus {
      outline-color: transparent;
    }

    &.has-margin {
      margin-bottom: 0.5rem;
    }

    &[placeholder]:empty::before {
      content: attr(placeholder);
      color: var(--grey);
      font-size: 14px;
    }

    &[placeholder]:empty:focus::before {
      content: "";
    }
  }
  &__textarea {
    line-height: 1.2;
    white-space: pre-line;
    word-break: break-word;

    &[contenteditable~="true"].overflow {
      overflow-y: auto;
    }
  }
  &__show-more {
    color: var(--main-color-dark);
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
    cursor: pointer;
  }
}
