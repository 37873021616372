.idt-confirm-email-headband {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: var(--menu-height);
  color: var(--warning-color-dark);
  background-color: var(--warning-color-alpha20);
  z-index: 999;
}

@media only screen and (max-width: 639px) {
  .idt-confirm-email-headband {
    flex-direction: column;
    padding: var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .idt-confirm-email-headband {
    padding: 0 var(--rem-size-16);
  }
}

@media only screen and (max-width: 639px) {
  .idt-confirm-email-headband__text {
    margin: var(--rem-size-16) 0;
    text-align: center;
  }
}

@media only screen and (min-width: 640px) {
  .idt-confirm-email-headband__text {
    margin: 0 var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .idt-confirm-email-headband__btn {
    margin-left: auto !important;
  }
}

