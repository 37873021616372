






















































@import '@/styles/functions';
@import '@/styles/mixins';

.footer-link {
  display: flex;
  align-items: center;
  gap: remCalc(8);
  margin-bottom: remCalc(8);

  &__container {
    &--one {
      @include respond-to(medium) {
        flex: 1 1 auto;
      }
    }
    &--two {
      @include respond-to(medium) {
        flex: 1 1 auto;
      }
    }
    &--three {
      @include respond-to(medium) {
        flex: 0 0 auto;
      }
    }
  }
  &__input {
    padding-bottom: 0;
  }
  &__delete {
    color: var(--secondary-color);
    font-size: var(--rem-size-14);
    cursor: pointer;

    &:hover {
      color: var(--secondary-color-dark);
    }
  }
}
