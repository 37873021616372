.idt-sidebar {
  display: block;
  position: relative;
  width: 0;
  height: 1rem;
  transition: all .3s ease;
}

.idt-sidebar__content {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--white);
  background-color: var(--dark-color);
  overflow: hidden;
}

@media only screen and (max-width: 639px) {
  .idt-sidebar__content {
    width: 100%;
    height: calc(var(--vh, 1vh)*100);
  }
}

@media only screen and (min-width: 640px) {
  .idt-sidebar__content {
    width: 360px;
    height: calc(100vh - var(--menu-height));
  }
}

.idt-sidebar__content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: var(--chat-min-with);
  height: 2.75rem;
  padding: var(--rem-size-12) var(--rem-size-16);
  background-color: var(--dark-color-dark);
  box-shadow: 0 0 6px 0 var(--black-alpha20);
}

.idt-sidebar__content__header__title {
  display: flex;
  align-items: center;
  width: 90%;
  font-family: var(--title-font-family);
  text-transform: uppercase;
}

.idt-sidebar__content__header__title__slot {
  width: 90%;
  margin-left: var(--rem-size-5);
}

.idt-sidebar__content__header__title .ideacons {
  margin-right: var(--rem-size-8);
  font-size: 1.3rem;
  vertical-align: text-top;
}

.idt-sidebar__content__header--white {
  background-color: var(--white);
}

.idt-sidebar__content--white {
  color: var(--black);
  background-color: var(--grey-lightest);
}

.idt-sidebar__content__slot {
  height: calc(100% - 2.75rem);
}

@media only screen and (max-width: 639px) {
  .idt-sidebar.open {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .idt-sidebar.open:not(.fullsize) {
    width: 22.5rem;
  }
}

.idt-sidebar.fullsize {
  width: 100%;
  height: 100%;
}

.idt-sidebar.fullsize .idt-sidebar__content {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1441px) {
  .idt-sidebar {
    flex: 0 0 auto;
  }
}

