






















































































































































































































































































































































































@import '@/styles/mixins';

.conference-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--grey-light);

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: var(--rem-size-6);
    background-color: var(--white);
    border-bottom: 1px solid var(--grey);
  }
  &__viewers-count {
    &:hover,
    &.unClickable,
    &.selected {
      background-color: var(--grey-lighter);
      color: var(--black);
    }
    &.unClickable {
      cursor: default;
    }
  }
  &__chat-surveys {
    position: absolute;
    top: 120px;
    right: 0;
    left:0;
    z-index: 3;
  }
  &__tabs {
    display: flex;
    min-height: 60px;
    border-bottom: 1px solid var(--grey);
    z-index: 1;

    @include respond-to(medium) {
      min-height: 75px;
    }
  }
  &__tab {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    flex: 1;
    font-size: 0.8rem;
    cursor: pointer;

    &-content {
      position: relative;
      display:flex;
      flex-direction: column;
      justify-content:center;
      align-items:center;
      width: 2rem;
    }
    &--active {
      background-color: var(--white);
      color: var(--main-color);
      font-weight: var(--font-weight-bold);
    }
  }
}
