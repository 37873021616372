


































































































































































































































































.fo-project-section-presentation-edit {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);

  &__title {
    &--required {
      margin-bottom: var(--rem-size-8);
    }
  }
  &__upload-file {
    border: none;

    &--required {
      & ::v-deep .idt-fs-upload-file__button {
        width: 100%;
        border: 1px dashed var(--red);
      }
    }
    &:not(&--required) {
      & ::v-deep .idt-fs-upload-file__button {
        width: 100%;
        border: 2px dashed var(--grey-light);
      }
    }
    &__content {
      min-height: 630px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__label {
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
        color: var(--grey-dark);
      }
      &__button {
        position: relative;
        padding: var(--rem-size-10) var(--rem-size-40);
        border-radius: var(--rem-size-25) !important;

        .idt-spinner {
          position: absolute;
        }
      }
    }
  }
  &__preview {
    position: relative;

    &:hover &__container {
      display: flex;
    }

    &__container {
      position: absolute;
      inset: 0;
      bottom: 38px; // Leave room for button bar
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &__buttons {
        display: flex;
        align-items: center;
        padding: var(--rem-size-10);
        background: var(--black-alpha80);

        .button:first-child {
          margin-right: var(--rem-size-8);
        }
      }
    }

  }
  &__url {
    margin-top: var(--rem-size-40);
    margin-bottom: var(--rem-size-40);
  }
  &__slideshare {
    position: relative;
    width: 100%;

    &:hover &__container {
      display: flex;
    }

    &__container {
      position: absolute;
      inset: 0;
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &__buttons {
        display: flex;
        align-items: center;
        padding: var(--rem-size-10);
        background: rgba(56, 58, 64, 0.8);

        .button {
          margin-bottom: 0;
          border-radius: var(--rem-size-23);
          padding: var(--rem-size-5) var(--rem-size-24);
        }
      }
    }

    .embed-responsive-item {
      width: 100% !important;
      height: 70vh !important;
    }
  }
  .idt-required-field {
    margin-top: var(--rem-size-8);
  }
}
