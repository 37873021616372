.project-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  background: var(--white);
  overflow: hidden;
  color: var(--grey-darkest);

  &__preview {
    width: 100%;
    height: 180px;
    background-color: var(--main-color-lighter);
    background-size: cover;
    border-radius: var(--rem-size-5);
    font-size: var(--rem-size-16);
  }
  &__texts {
    margin-top: var(--rem-size-22);
    
    &__title {
      font-weight: var(--font-weight-bold);
    }
    &__description {
      height: var(--rem-size-48);
    }
    &__challenges {
      height: var(--rem-size-24);
      margin-top: var(--rem-size-6);
      margin-bottom: var(--rem-size-10);
      overflow: hidden;
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: remCalc(8);
    padding-top: remCalc(10);

    &__vote {
      display: inline-block;
      flex: 0 0 auto;
      padding: var(--rem-size-3) var(--rem-size-14);
      border: 2px solid var(--white);
      border-radius: var(--rem-size-30);
      font-size: var(--rem-size-14);

      &.allin {
        color: var(--project-vote-0);
        border-color: var(--project-vote-0);
      }
      &.pivot {
        color: var(--project-vote-1);
        border-color: var(--project-vote-1);
      }
      &.kill {
        color: var(--project-vote-2);
        border-color: var(--project-vote-2);
      }
    }
  }
}
