.modal-answers__question {
  padding: 1.2rem 1.5rem;
  color: var(--white);
  background-color: var(--main-color-dark);
}

.modal-answers__question__header {
  display: flex;
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid var(--white);
}

.modal-answers__question__header__user {
  display: inline-block;
  vertical-align: middle;
  width: 75%;
  cursor: pointer;
}

.modal-answers__question__header__user__avatar {
  display: inline-block;
  margin-right: .85rem;
  background-color: var(--white);
  border-radius: 100%;
  box-shadow: 2px 2px 5px -1px var(--black);
  vertical-align: middle;
}

.modal-answers__question__header__user__information {
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: middle;
}

@media only screen and (max-width: 639px) {
  .modal-answers__question__header__user__information .idt-user-information__username {
    display: block;
  }
}

.modal-answers__question__header__votes {
  display: inline-block;
  width: 25%;
  font-size: 1.05rem;
  vertical-align: middle;
  text-align: right;
}

.modal-answers__question__header__votes__number {
  display: inline-block;
  vertical-align: middle;
}

.modal-answers__question__header__votes__label {
  display: inline-block;
  margin-right: .5rem;
  vertical-align: middle;
}

.modal-answers__question__header__votes__icon {
  display: inline-block;
  font-size: 1.6rem;
  vertical-align: middle;
}

.modal-answers__question__content__text {
  margin-bottom: var(--rem-size-8);
  white-space: pre-wrap;
  word-break: break-word;
}

.modal-answers__question__content__created {
  color: var(--grey-light);
  font-size: .9rem;
  font-weight: 700;
}

