




































































.custom-select {
  &__container {

    ::v-deep .v-select {
      background: var(--white);
    }
  }
}
