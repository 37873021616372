.embedded-solution-player {
  height: 100%;
}

.embedded-solution-player__video-container {
  width: 100%;
  height: calc(var(--vh, 1vh)*100 - (var(--conference-footer-height) + var(--menu-height))) !important;
}

.embedded-solution-player__video-container ::v-deep .video-js {
  width: 100%;
  height: 100%;
}

