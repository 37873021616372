.bo-gamification .gamification_setting {
  padding-bottom: var(--rem-size-16);
}

.bo-gamification .gamification_setting .gamification_title {
  font-weight: var(--font-weight-medium);
}

.bo-gamification .gamification_setting .gamification_label {
  margin: auto;
  padding-left: var(--rem-size-12);
}

.bo-gamification .gamification_setting ::v-deep .container-only {
  display: inherit;
}

.bo-gamification ::v-deep .idt-input-counter {
  right: var(--rem-size-32);
}

.bo-gamification ::v-deep .idt-required-field {
  left: unset;
}

