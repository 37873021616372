.how-it-works-card {
  width: 300px;
  height: 100%;
  padding: var(--rem-size-16);
  text-align: left;
  background-color: var(--grey-lightest);
  border-radius: var(--global-radius);
}

.how-it-works-card__content__title {
  color: var(--grey-darkest);
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
}

.how-it-works-card__content__description {
  margin-top: calc(-1*var(--rem-size-16));
}

.how-it-works-card__content__image {
  display: flex;
}

.how-it-works-card__content__image__preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--rem-size-16);
  min-width: 78px;
  max-width: 78px;
  min-height: 78px;
  max-height: 78px;
  margin-right: var(--rem-size-16);
  border-radius: var(--rem-size-12);
  background-color: var(--main-color-lighter);
  background-size: contain;
  background-repeat: no-repeat;
}

.how-it-works-card__content__image__preview__inside {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  max-width: 68px;
  min-height: 68px;
  max-height: 68px;
  border-radius: var(--rem-size-12);
  font-size: var(--rem-size-36);
  font-weight: var(--font-weight-bold);
  background-color: var(--main-color);
  background-size: contain;
  background-repeat: no-repeat;
  color: var(--white);
}

