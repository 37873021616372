






















































































































































































































































































































































































































































































































































































::v-deep .idt-conference-table {
  .idt-table-cell--camera {
    max-width: 7vw;
    text-align: center;

    .ideacons {
      font-size: 2rem;
    }

    .idea-ht-circle-checked {
      color: var(--main-color);
    }

    .idea-ht-circle-info {
      color: var(--yellow);
    }

    .idea-ht-circle-close {
      color: var(--red);
    }
  }

  .idt-table-header-cell--camera {
    max-width: 7vw;
    text-align: center;
  }
  .idt-table-header-cell--free {
    max-width: 7vw;
    text-align: center;
  }
  .idt-table-cell--free {
    max-width: 7vw;
    text-align: center;
    .free {
      display: block;
      width: 78px;
      margin: 3px 0;
      border: solid 1px;
      border-radius: 3px;
      font-size: 0.55rem;
      text-align: center;
      text-transform: uppercase;
    }
    .live {
      color: var(--secondary-color);
    }
    .replay {
      color: #484848;
    }
  }
}
