


































@import '@/styles/functions';

.idt-code-block {
  position: relative;

  &__code {
    display: block;
    padding: remCalc(8) remCalc(48) remCalc(8) remCalc(8);
    border: 1px solid var(--grey);
    background-color: var(--grey-lightest);
    font-size: remCalc(14);
    line-height: 1.6;
    word-break: break-all;
  }
  &__copy {
    position: absolute;
    top: 0;
    right: 0;
    min-height: remCalc(40);
  }
}
