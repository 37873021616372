









































































































































































@import '@/styles/mixins';

.meeting-participants-list {
  width: 100%;
  padding: var(--rem-size-10);
  background-color: var(--white);

  &__filters {
    display: flex;

    & .idt-select-container {
      width: 120px;
    }

    & .idt-user-search-input {
      width: 100%;
      margin-right: 8px;
    }
  }

  &__uninvite {
    width: var(--rem-size-28);
    height: var(--rem-size-28);
    color: var(--white);
    background: var(--grey);
    border-radius: 50%;
    line-height: 36px;
    cursor: pointer;

    &:hover {
      background: var(--grey-dark);
    }
  }
}
