.idt-create-survey-question {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--grey-light);
  border-left: 4px solid var(--main-color);
  border-radius: var(--global-radius);
}

.idt-create-survey-question__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.idt-create-survey-question__header button {
  cursor: pointer;
}

.idt-create-survey-question__header button.idt-create-survey-question__header__duplicate {
  margin-left: auto;
}

.idt-create-survey-question__header button.idt-create-survey-question__header__delete {
  margin-left: 1rem;
}

.idt-create-survey-question__header ::v-deep .ideacon {
  color: var(--grey-dark);
}

.idt-create-survey-question__content {
  padding-top: 1rem;
}

.idt-create-survey-question__content .idt-select-container {
  display: inline-block;
  min-width: 300px;
  padding-bottom: 0;
}

.idt-create-survey-question__content .idt-select-container ::v-deep label {
  margin-bottom: .25rem;
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

