.conference-list-item {
  display: flex;
  max-width: 890px;
  width: 100%;
  height: 202px;
  margin: 0 0 var(--rem-size-20) 0;
  background-color: var(--white);
  border-radius: var(--global-radius);
  overflow: hidden;
  cursor: pointer;
  --flow-mobile: column nowrap;
  --height-mobile: 470px;
  --right-padding-mobile: var(--rem-size-30) var(--rem-size-15) 55px var(--rem-size-25);
  --right-border-top-mobile: 0;
  --badge-bottom-mobile: 0;
  --badge-left-mobile: 0;
  --badge-radius-mobile: 3px;
  --track--text--color: var(--grey-darker);
  --track--text--title: var(--main-color);
  --track--text--desc: var(--grey-darker);
}

.conference-list-item:hover {
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

.conference-list-item:hover .conference-list-item__image__favorite {
  display: block;
}

.conference-list-item.mobile {
  display: inline-block;
  max-width: 275px;
  height: 382px;
}

.conference-list-item.mobile .conference-list-item__image__favorite {
  display: block;
}

.conference-list-item.canceled {
  background-color: var(--grey-light);
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  pointer-events: none;
  cursor: auto;
}

.conference-list-item.canceled .conference-list-item__image {
  opacity: .5;
}

.conference-list-item.no-access {
  --track--text--color: var(--grey-dark);
  --track--text--title: var(--grey-dark);
  --track--text--desc: var(--grey-dark);
  background-color: var(--grey-lighter);
  border-color: var(--grey-lighter);
  transition: all .3s ease-in-out;
}

@media (hover: hover) {
  .conference-list-item.no-access:hover {
    --track--text--color: var(--grey-darker);
    --track--text--title: var(--grey-darker);
    --track--text--desc: var(--grey-darker);
  }
}

.conference-list-item.restricted:hover {
  cursor: not-allowed;
}

.conference-list-item__image {
  flex: 0 0 auto;
  position: relative;
  width: 275px;
  height: 202px;
  max-width: 275px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: var(--global-radius) 0 0 var(--global-radius);
  overflow: hidden;
}

.conference-list-item__image.mobile {
  height: 144px;
  border-radius: var(--global-radius) var(--global-radius) 0 0;
}

.conference-list-item__image__unlock {
  position: absolute;
  top: var(--rem-size-10);
  left: var(--rem-size-10);
}

.conference-list-item__image__free {
  position: absolute;
  top: var(--rem-size-10);
  left: var(--rem-size-10);
  background-color: var(--main-color);
  border-radius: var(--global-radius);
  color: var(--white);
  text-transform: uppercase;
}

.conference-list-item__image__favorite {
  display: none;
  position: absolute;
  top: var(--rem-size-10);
  right: var(--rem-size-10);
}

.conference-list-item__image__favorite.active {
  display: block;
}

.conference-list-item__image__favorite:hover {
  cursor: pointer;
}

.conference-list-item__image__reward {
  position: absolute;
  bottom: var(--rem-size-10);
  left: var(--rem-size-10);
  width: 91px;
  height: var(--rem-size-24);
  background-color: var(--white);
  border-radius: var(--rem-size-15);
}

.conference-list-item__image__reward__icon {
  display: inline-block;
  margin-left: var(--rem-size-10);
  color: var(--yellow);
}

.conference-list-item__image__reward__text {
  display: inline-block;
  margin-left: 5px;
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-14);
}

.conference-list-item__image__duration {
  position: absolute;
  right: var(--rem-size-10);
  bottom: var(--rem-size-10);
  width: fit-content;
  padding: var(--rem-size-2) var(--rem-size-8);
  border-radius: var(--global-radius);
  background-color: var(--black-alpha50);
  color: var(--white);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-14);
  text-align: center;
  vertical-align: middle;
}

.conference-list-item.no-access .conference-list-item__image {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.conference-list-item__information {
  padding: var(--rem-size-10) var(--rem-size-20);
}

.conference-list-item__information__status-info {
  display: flex;
  gap: var(--rem-size-16);
}

.conference-list-item__information__status-info.mobile {
  margin-bottom: var(--rem-size-10);
}

.conference-list-item__information__status-info__status {
  border-radius: var(--global-radius);
}

.conference-list-item__information__status-info__status.live {
  background-color: var(--red);
  color: var(--white);
  text-transform: uppercase;
}

.conference-list-item__information__status-info__status.canceled {
  background-color: var(--grey-dark);
  color: var(--white);
}

.conference-list-item__information__status-info__status.replay {
  background-color: var(--black);
  color: var(--white);
}

.conference-list-item__information__status-info__status.onDemand {
  background-color: var(--main-color);
  color: var(--white);
}

.conference-list-item__information__status-info__counter {
  background-color: var(--grey-light);
  border-radius: var(--global-radius);
  color: var(--black);
}

.conference-list-item__information__date {
  align-items: center;
  min-height: var(--rem-size-24);
}

.conference-list-item__information__date.mobile {
  margin-bottom: var(--rem-size-10);
}

.conference-list-item__information__date__content {
  width: 100%;
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-16);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conference-list-item__information__title {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.conference-list-item__information__title.mobile {
  margin-bottom: var(--rem-size-10);
}

.conference-list-item__information__title__content {
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-20);
}

.conference-list-item__information__description {
  display: flex;
  min-height: 48px;
}

.conference-list-item__information__description.mobile {
  margin-bottom: var(--rem-size-10);
}

.conference-list-item__information__description__content {
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-16);
}

.conference-list-item__information__separator {
  width: 275px;
  margin: var(--rem-size-8) 0 0 -20px;
  border-top: solid 1px var(--grey-light);
}

.conference-list-item__information__speaker-info {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;
  border-radius: var(--global-radius);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conference-list-item__information__speaker-info:hover {
  margin-left: -5px;
  padding-left: 5px;
  background-color: var(--grey-lighter);
  cursor: pointer;
}

.conference-list-item__information__speaker-info.mobile:hover {
  width: 275px;
}

.conference-list-item__information__speaker-info.mobile {
  margin: 0;
  padding: var(--rem-size-8) 0 var(--rem-size-10) 0;
  border-radius: 0 0 5px 5px;
}

.conference-list-item__information__speaker-info.mobile .conference-list-item__information__speaker-info__mentor__username {
  font-family: var(--title-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-20);
}

.conference-list-item__information__speaker-info__mentor {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: var(--rem-size-10);
}

.conference-list-item__information__speaker-info__mentor__username {
  max-width: 100%;
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-16);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conference-list-item__information__speaker-info__mentor__job-company {
  max-width: 100%;
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conference-list-item__information__track-tags {
  display: flex;
}

.conference-list-item__information ::v-deep .idt-tag-list {
  width: 100%;
  height: var(--rem-size-24);
  overflow: hidden;
}

.conference-list-item__information ::v-deep .idt-tag-list__item {
  max-width: 75%;
}

.conference-list-item__speakers {
  width: fit-content;
  margin: var(--rem-size-4) 0;
}

@media (hover: hover) {
  .conference-list-item:hover .conference-list-item__information {
    border-color: rgba(0,0,0,0);
  }
}

.conference-list-item.no-access .conference-list-item-status {
  opacity: .5;
}

