.conference-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--grey-light);
}

.conference-sidebar__header {
  display: flex;
  justify-content: flex-end;
  padding: var(--rem-size-6);
  background-color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.conference-sidebar__viewers-count:hover,
.conference-sidebar__viewers-count.unClickable,
.conference-sidebar__viewers-count.selected {
  background-color: var(--grey-lighter);
  color: var(--black);
}

.conference-sidebar__viewers-count.unClickable {
  cursor: default;
}

.conference-sidebar__chat-surveys {
  position: absolute;
  top: 120px;
  right: 0;
  left: 0;
  z-index: 3;
}

.conference-sidebar__tabs {
  display: flex;
  min-height: 60px;
  border-bottom: 1px solid var(--grey);
  z-index: 1;
}

@media only screen and (min-width: 640px) {
  .conference-sidebar__tabs {
    min-height: 75px;
  }
}

.conference-sidebar__tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: .8rem;
  cursor: pointer;
}

.conference-sidebar__tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2rem;
}

.conference-sidebar__tab--active {
  background-color: var(--white);
  color: var(--main-color);
  font-weight: var(--font-weight-bold);
}

