
































































































@import '@/styles/mixins/index';

.lobby-sponsor-line {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 36px;
  padding: 0 var(--rem-size-16);
  color: var(--grey-darkest);
  background-color: var(--white);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  transition: background-color .1s ease-in-out;

  &--opened {
    border-radius: var(--global-radius) var(--global-radius) 0 0;
  }

  &:after {
    content: '';
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--white);
    opacity: 0;
    pointer-events: none;
    border-radius: var(--global-radius);
    transition: opacity .1s ease-in-out;
  }

  &:focus:not(:active) {
    &:after {
      opacity: .2;
    }
  }

  &:hover,
  &:focus {
    color: var(--grey-darkest);
  }

  &__chevron {
    display: inline-flex;
    color: var(--grey-darkest);
    margin-right: var(--rem-size-10);
    transition: transform .2s ease-in-out;
  }

  &--opened {
    .lobby-sponsor-line__chevron {
      transform: rotate(-180deg);
    }
  }

  &__icon {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: var(--rem-size-10);
    background-color: var(--white);
    border-radius: 100%;
    overflow: hidden;

    img {
      object-fit: contain;
      width: 100%;
      opacity: 0;
      transition: opacity .15s ease-in-out;
    }

    &--loaded {
      img {
        opacity: 1;
      }
    }
    &--hidden {
      visibility: hidden;
    }
  }

  &__name {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  // simple fade in/out animation
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s ease;
  }
}
