.menu-mobile-shortcut-list {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  padding: var(--rem-size-20) 0;
  border-bottom: 1px solid var(--grey);
}

.menu-mobile-shortcut-list__separator {
  width: 100%;
}

