










































































































.idt-survey-result-nps {

  &__progressbar {
    display: flex;
    align-items: center;
    height: 25px;
    width: 100%;
    margin-top: var(--rem-size-10);
    overflow: hidden;
    border-radius: var(--global-radius);

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 1;
      height: 100%;
      font-weight: var(--font-weight-bold);
      font-size: var(--rem-size-12);
      color: var(--white);

      &.detractors {
        background-color: var(--secondary-color);
      }

      &.passives {
        background-color: var(--yellow);
      }

      &.promoters {
        background-color: var(--main-color);
      }
    }
  }

  &__labels {
    margin-top: var(--rem-size-10);

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor: pointer;

      &.detractors {
        color: var(--secondary-color);
      }

      &.passives {
        color: var(--yellow);
      }

      &.promoters {
        color: var(--main-color);
      }
    }
  }
}
