





















































































































.dropdown-list-field {
  max-width: 500px;

  &__conditional-wrapper {
    margin-bottom: 20px;
    background-color: var(--white);
    border: 1px solid var(--grey);
    border-radius: var(--global-radius);
  }
}
