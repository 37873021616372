











































































































































































::v-deep .idt-dropdown--pane {
  padding: 0;
}
.days-container {

  &__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: var(--rem-size-10);

    &__buttons {
      &__button {
        margin: 7px;
        color: var(--grey-darker);

        &:hover {
          cursor: pointer;
        }
      }
    }
    &__label {
      color: var(--black);
      font-size: var(--rem-size-15);
      font-weight: var(--font-weight-bold);
      line-height: var(--rem-size-24);
    }
  }

  &__calendar {
    background-color: var(--white);
    border: 1px solid var(--grey-lighter);
    z-index: 100;
  }
  &__dropdown {
    width: 100%;
  }
  &__input {
    display: flex;
    flex-flow: wrap;
    max-width: 100%;
    margin-bottom: var(--rem-size-25);

    &:hover {
      cursor: pointer;
    }

    &__days {
      display: flex;
      flex-flow: wrap;
    }

    &.on-edition {
      flex-flow: row;
      align-items: baseline;
      justify-content: space-between;
      max-width: 100%;
      min-height: 38px;
      padding-left: 5px;
      border: solid 1px var(--grey);
      border-radius: var(--global-radius);
    }
  }
}
