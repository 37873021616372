.idt-account-complete {
  display: flex;
  align-items: stretch;
  justify-content: center;

  @include respond-to(max-slarge) {
    flex-direction: column;
  }

  &__side {
    flex: 1 1 auto;

    @include respond-to(slarge) {
      width: 45%;
      padding: 0 40px;
      border-left: 1px solid var(--grey);
    }

    &:first-child {
      padding-left: 0;
      border: none;
    }
    &:last-child {
      padding-right: 0;
    }
    &--mentor {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid var(--grey);

      @include respond-to(slarge) {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
      }
    }
  }

  &__upload-avatar-block {
    flex: 0 0 auto;
    max-width: 140px;

    @include respond-to(max-medium) {
      margin-bottom: 1.25rem;
    }

    @include respond-to(medium) {
      margin-right: 40px;
    }

    .required-field {
      border-radius: 100%;
      box-shadow: 0 0 0 1px var(--secondary-color);

      @include respond-to(medium) {
        position: relative;
      }
      required-field {

        @include respond-to(medium) {
          position: absolute;
          bottom: -20px;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
        }

        div {
          text-align: center;
        }
      }
    }
  }

  &__avatar {
    &__container {
      position: relative;
      border-radius: 100%;
      overflow: hidden;

      .idt-spiner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__delete {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 40px);
      color: var(--secondary-color);
      background-color: rgba($black, 0.75);
      transition: all 0.3s ease;
      cursor: pointer;
      opacity: 0;

      &__label {
        display: block;
        margin-top: 0.5rem;
      }
      .ideacons {
        font-size: 2rem;
      }
    }
    &__container:hover &__delete {
      opacity: 1;
    }
  }
  &__upload-avatar {
    display: block;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;

    .upload-file,
    .upload-file__btn {
      height: 140px;
      width: 140px;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      overflow: hidden;
      &:hover,
      &:focus {
        background: none;
      }
      &:focus {
        outline: none;
      }
      & span {
        display: inline-block;
        position: relative;
      }
      & .upload-file__label,
      & .upload-file__progress {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background-color: var(--main-color);
        line-height: 38px;
      }
      & .idt-spinner,
      & idt-spiner {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
      }
    }
    &:hover .idt-account-complete__upload-icon {
      background-color: var(--main-color-dark);
    }
  }
  &__upload-icon {
    display: inline-block;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 125px;
    left: 50%;
    background-color: var(--main-color);
    border: 5px solid white;
    border-radius: 50%;
    font-size: 1.25em;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);

    &:before {
      position: absolute;
      display: block;
      left: 49%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
    }
  }

  &__wrap-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    color: var(--grey);
    background-color: $white;
    border: 1px solid var(--grey);
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      min-height: 100%;
    }
    .ideacons {
      font-size: 3rem;
    }
    &.required {
      border-color: var(--secondary-color);
    }
  }

  &__inputs-block {
    flex: 1 1 auto;
    @include respond-to(max-medium) {
      width: 100%;
    }

    .input-lastname {
      margin: 0;
    }
  }
}
.register__tags-btn {
  padding: 4px 12px;
  margin: 6px;
}
.register__tags-list {
  img {
    width: 34px;
    height: 34px;
  }
  input[type=checkbox] {
    float: right;
  }
  label {
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.idt-account-complete__avatar-icon {
  font-size: 2.8em;
  display: block;
  margin-bottom: 5px;
}
.register__wrap-avatar-container {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 80%;
  line-height: 1.1;
  transform: translate(-50%, -50%);
}
.idt-account-complete .vue-tel-input input.required-field {
  box-shadow: 0 0 0 1px var(--secondary-color) !important;
}
