.gamification-section__title {
  width: 90%;
  margin-bottom: 1rem;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  color: var(--black);
  overflow: hidden;
}

.gamification-section__title::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-right: -100%;
  margin-left: .5rem;
  border-top: 2px solid var(--grey);
}

.gamification-section__content {
  width: 90%;
}

