.bo-project-settings__bo-section__right {
  display: flex;
  flex-direction: column;
}

.bo-project-settings__bo-section__right__deadline {
  display: flex;
  justify-content: space-between;
  margin-top: var(--rem-size-32);
}

.bo-project-settings__bo-section__right__deadline__date-picker {
  width: 150px;
}

.bo-project-settings__bo-section__right__deadline__timezones {
  width: 40%;
}

