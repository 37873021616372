.modal-question__step {
  padding: var(--rem-size-16);
}

.modal-question__step:not(:last-child) {
  border-bottom: 2px solid var(--grey);
}

.modal-question__step__header {
  margin-bottom: var(--rem-size-16);
}

.modal-question__step__header__number {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: .5rem;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 100%;
  font-size: .9rem;
  text-align: center;
  line-height: 24px;
  vertical-align: middle;
}

.modal-question__step__header__title {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.modal-question__step__counter {
  position: relative;
}

.modal-question__step__counter__textarea {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  padding: var(--rem-size-8) var(--rem-size-16) var(--rem-size-8) var(--rem-size-8);
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  transition: border .3s ease-in-out;
  resize: vertical;
}

.modal-question__step__counter__textarea:focus {
  border: 1px solid var(--main-color);
  outline: 0;
}

.modal-question__step__counter__number {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: var(--rem-size-4);
  border-radius: 0 var(--global-radius) 0 var(--global-radius);
  background-color: var(--main-color);
  font-size: 11px;
  color: var(--white);
}

.modal-question__step__button.button--submit {
  display: inline-block;
  width: 100%;
  max-width: 80px;
  padding: var(--rem-size-4) var(--rem-size-12);
  border-radius: var(--global-radius);
  border: none;
  background-color: var(--main-color);
  vertical-align: middle;
  color: var(--white);
  transition: all .4s ease;
}

.modal-question__step__button.button--submit:hover {
  background-color: var(--main-color-dark);
}

.modal-question__step__button.button--download .upload-file__btn {
  padding: var(--rem-size-4) var(--rem-size-8);
}

.modal-question__step__panels {
  flex-flow: row wrap;
  margin: 0;
}

.modal-question__step__panels .button {
  margin-right: var(--rem-size-4);
}

.modal-question__step__separator {
  position: relative;
  margin-top: var(--rem-size-16);
  margin-bottom: var(--rem-size-16);
  height: 1px;
  background-color: var(--grey);
  text-align: center;
}

.modal-question__step__separator:before,
.modal-question__step__separator:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -7px;
  border-left: 14px solid rgba(0,0,0,0);
  border-right: 14px solid rgba(0,0,0,0);
}

.modal-question__step__separator:before {
  border-top: 14px solid var(--grey);
}

.modal-question__step__separator:after {
  top: -1px;
  border-top: 14px solid var(--grey-lighter);
}

.modal-question__step__preview {
  display: block;
}

.modal-question__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 2px solid var(--grey);
}

@media only screen and (max-width: 639px) {
  .modal-question__actions {
    height: 70px;
  }
}

@media only screen and (min-width: 640px) {
  .modal-question__actions {
    height: 96px;
  }
}

.modal-question__actions__button {
  margin: 0;
}

.modal-question__actions__button.button--pending {
  background-color: var(--grey-lightest);
  border-color: var(--grey-lightest);
}

