::v-deep .idt-dropdown--pane {
  padding: 0;
}

.idt-time-picker-input {
  user-select: none;
}

.idt-time-picker-input__dropdown {
  width: 100%;
}

.idt-time-picker-input__input {
  position: relative;
}

.idt-time-picker-input__input ::v-deep .idt-input-container label {
  display: block;
  line-height: var(--rem-size-30);
}

.idt-time-picker-input__input ::v-deep .idt-input-container input {
  width: 100%;
  height: 40px;
  padding: var(--rem-size-10) var(--rem-size-16);
  color: var(--grey-darker);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  box-sizing: border-box;
  outline: none;
}

.idt-time-picker-input__input ::v-deep .idt-input-container input:focus {
  border-color: var(--main-color);
}

.idt-time-picker-input__input ::v-deep .idt-input-container input[readonly] {
  background: var(--white);
  cursor: text;
}

.idt-time-picker-input__input ::v-deep .idt-input-container .idt-icon {
  color: var(--grey-darker);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
}

.idt-time-picker-input__container {
  padding: var(--rem-size-16) 38px;
  color: var(--black);
  background: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--black-alpha15);
  z-index: 2;
}

.idt-time-picker-input__container__title {
  margin-bottom: var(--rem-size-6);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-24);
}

.idt-time-picker-input__container__picker {
  display: flex;
}

.idt-time-picker-input__container__picker__separator {
  width: var(--rem-size-10);
  align-self: center;
  margin: 0 var(--rem-size-1);
  color: var(--black);
  font-size: var(--rem-size-30);
}

.idt-time-picker-input__container__picker__meridiem {
  display: flex;
  flex-direction: column;
  margin-left: var(--rem-size-9);
}

.idt-time-picker-input__container__picker__meridiem>span {
  width: 54px;
  height: 34px;
  line-height: 34px;
  color: var(--black);
  background: var(--grey-lighter);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-20);
  text-align: center;
  cursor: default;
}

@media only screen and (max-width: 639px) {
  .idt-time-picker-input__container__picker__meridiem>span {
    height: 57px;
    line-height: 57px;
  }
}

.idt-time-picker-input__container__picker__meridiem>span[disabled] {
  color: var(--grey-light);
  background: var(--white);
  border: 1px solid var(--grey-light);
  cursor: pointer;
}

.idt-time-picker-input__container__picker__meridiem>span[disabled]:hover {
  color: var(--grey-darker);
  border-color: var(--grey-darker);
}

.idt-time-picker-input__container__picker__meridiem>span:not(:last-child) {
  margin-bottom: var(--rem-size-8);
}

.idt-time-picker-input ::v-deep .idt-input-container label {
  color: var(--grey-darkest);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
}

