



























































































































































@import '@/styles/functions';
@import '@/styles/mixins';

.notification-webhook {
  &__list {
    margin-bottom: remCalc(16);
    box-shadow: 0 2px 3px var(--grey-darker-alpha15);
    border: 1px solid var(--grey-light);
    border-radius: var(--global-radius);
  }
  &__head,
  &__item {
    display: grid;
    gap: remCalc(16);
    padding: remCalc(16);

    @include respond-to(max-slarge) {
      grid-template-row: 1fr 1fr 1fr;
    }
    @include respond-to(slarge) {
      grid-template-columns: 40% 40% calc(20% - remCalc(32));
    }
  }
  &__head {
    @include respond-to(max-slarge) {
      display: none;
    }
  }
  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-light);
    }
  }
  &__actions {
    text-align: right;
  }
}
.api-notification {
  &__item {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid var(--main-color);
    }
  }
  &__display {
    width: 100%;
    padding-bottom: 1.5rem;

    &__title {
      display: flex;
      align-items: center;
    }
    &__api-name {
      flex: 1 1 auto;
    }
    &__actions {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }
  }
  &__form {
    flex: 1 1 auto;
  }
  &__delete {
    flex: 0 0 auto;
    margin-left: var(--rem-size-16);
  }
  &__edit-button,
  &__delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    font-size: var(--rem-size-14);
    cursor: pointer;

    span {
      display: block;
      margin-right: 0.5rem;
    }
  }
  &__delete-button {
    color: var(--secondary-color);

    &:hover {
      color: var(--secondary-color-dark);
    }
  }
}
