












































.yA path, .grid path, .yA .tick line {
  stroke: transparent;
}
.xA path {
  stroke: transparent;
}
.grid line, .xA .tick line {
  opacity: 0.3;
}
text {
  color: var(--grey);
}
