
























































































.textarea-field {

  textarea {
    border: 1px solid var(--grey);
    border-radius: var(--global-radius);
    padding-bottom: 0;
  }
}
