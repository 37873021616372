// Chat component styles
//
//  Table of Contents:
//
//  1. Main
//  2. Mentor modal
//  3. Messages from others
//  4. Message from you
//  5. Dark mode
//
// -----------------------------

.wizz {
  animation: wizz .3s ease-in-out 1 backwards !important;
}

@keyframes wizz {
  0% {
    transform: translateX(-3vw) rotate(-5deg);
  }
  15% {
    transform: translateX(3vw) rotate(0deg);
  }
  30% {
    transform: translateX(-3vw) rotate(5deg);
  }
  45% {
    transform: translateX(3vw) rotate(0deg);
  }
  60% {
    transform: translateX(-3vw) rotate(-5deg);
  }
  75% {
    transform: translateX(3vw) rotate(0deg);
  }
  90% {
    transform: translateX(-3vw) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
