.how-it-works-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--rem-size-16);
}

.how-it-works-card__header__menu-icon {
  cursor: grabbing;
}

.how-it-works-card__header__delete-icon {
  cursor: pointer;
}

.how-it-works-card__content__label {
  text-align: left;
}

.how-it-works-card__content__image {
  display: flex;
  margin-bottom: var(--rem-size-16);
}

.how-it-works-card__content__image__upload {
  text-align: left;
}

.how-it-works-card__content__image__upload__title {
  margin-bottom: var(--rem-size-8);
  font-weight: var(--font-weight-medium);
  color: var(--black);
}

.how-it-works-card__content__image__upload__description {
  margin-bottom: var(--rem-size-8);
}

.how-it-works-card__content__image__upload__input {
  border: 0;
}

.how-it-works-card ::v-deep .upload-file__btn {
  height: 35px;
  margin-bottom: 0;
  height: fit-content;
  line-height: 50%;
  font-weight: var(--font-weight-medium);
  border-radius: 23px;
  background-color: var(--white);
  color: var(--black);
}

