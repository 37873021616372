











































.export-dropdown {
  .idt-dropdown--pane {
    padding: 0;
    border-radius: 0;
  }
}
.idt-export-list {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.idt-export-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--rem-size-8);
  gap: var(--rem-size-16);
  color: var(--grey-darker);
  border-radius: 0;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-normal);
  line-height: 1;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lightest);
  }
  &:hover {
    background-color: var(--grey-lightest);
  }
  &__label {
    color: var(--grey-darker);
  }
  &__icon {
    flex: 0 0 auto;
  }
  &__input-file {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }
}
