

































































































































































































@import '@/styles/mixins/index';

.resource-list {
  margin-bottom: 50px;

  &__header {
    margin: 50px 0;
    color: var(--black);
    text-align: center;
  }
  &__title {
    font-family: var(--title-font-family);
    font-size: 2rem;
    line-height: 2.5rem;
  }
  &__subtitle {
    font-size: var(--rem-size-20);
  }
  &__wrapper {
    display: flex;

    &:not(.reverse) {
      flex-direction: column;
    }
    &.reverse {
      flex-direction: column-reverse;
    }
  }
  &__container {
    display: grid;
    gap: var(--rem-size-20);

    @include respond-to(max-small) {
      grid-template-columns: 100%;
    }
    @include respond-to(small-medium) {
      grid-template-columns: 1fr 1fr;
    }
    @include respond-to(medium-slarge) {
      grid-template-columns: 1fr 1fr;
    }
    @include respond-to(slarge-large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include respond-to(large) {
      grid-template-columns: 1fr;
    }
  }
  &__add-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    color: var(--main-color);
    border: 1px dashed var(--main-color);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: var(--main-color-dark);
      border-color: var(--main-color-dark);
    }
    &:not(.reverse) {
      margin-top: var(--rem-size-20);
    }
    &.reverse {
      margin-bottom: var(--rem-size-20);
    }
    &__label {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
}
