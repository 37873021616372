















































































.custom-form-field-list {
  padding-left: 30px;

  &__row {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 25px;
    }
  }

  .ghost {
    width: 600px;
    background: var(--grey-lighter);
    opacity: 0.5;
    cursor: grab;
  }
}
