.event-mentor {
  text-align: center;
  margin-bottom: 2.5rem;
  cursor: pointer;
}

.event-mentor__avatar {
  width: 125px;
  height: 125px;
  margin: 0 auto 8px;
  border: 10px solid var(--grey-lighter);
  border-radius: 100%;
}

.event-mentor__avatar .idt-user-avatar {
  background-color: var(--grey-lighter);
}

.event-mentor__avatar .idt-user-avatar span {
  color: var(--grey);
}

.event-mentor__avatar>img {
  border-radius: 100%;
}

.event-mentor__name {
  margin-bottom: 10px;
  color: var(--grey-darkest);
  line-height: 1.3125rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.event-mentor__job {
  color: var(--grey-darker);
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 500;
}

.event-mentor__company {
  color: var(--grey-darker);
  font-size: .875rem;
  line-height: 1rem;
  font-weight: 300;
}

.event-mentor__see-more {
  margin: .625rem 0 4.375rem 0;
}

.event-mentor__see-more .btn {
  display: inline-block;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  border: 1px solid var(--main-color);
  padding: 10px 18px;
  border-radius: 3px;
}

.event-mentor__jury-title {
  margin-top: 4rem;
}

.event-mentor__jurys {
  pointer-events: none;
}

