
























.skeleton {
  display: flex;
  height: 127px;
  width: 282px;
  animation: shine 1s infinite;
  background: var(--white);
  border-radius: 5px;

  &__wrapper {

    &__speaker {
        height: 30px;
        width: 30px;
        margin: 10px 15px 0px 10px;
        animation: glowing 1300ms infinite;
        background-color: var(--grey);
        border-radius: 100%;
      }

      &__label {
        height: 8px;
        width: 90px;
        margin: 25px 0px 10px 10px;
        animation: glowing 1300ms infinite;
        background-color: var(--grey);
        border-radius: 5px;
      }

      &__content {
        height: 8px;
        width: 200px;
        margin-bottom: 5px;
        margin-left: 10px;
        animation: glowing 1300ms infinite;
        border-radius: 5px;
        background-color: var(--grey);
      }

      &__separation {
        height: 1px;
        width: 282px;
        margin-bottom: 5px;
        margin-top: 25px;
        background-color: var(--grey-light);
        border-radius: 5px;
      }
  }

  @keyframes glowing {
    0% {
      background-color: var(--grey-light);
    }
    25% {
      background-color: var(--grey);
    }
    50% {
      background-color: var(--grey-light);
    }
    75% {
      background-color: var(--grey-lighter);
    }
    100% {
      background-color: var(--grey-light);
    }
  }
}
