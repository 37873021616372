.idt-file-download-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  min-height: 50px;
  padding: 0 .5rem;
  border-radius: var(--global-radius);
  color: var(--black);
  text-align: left;
  transition: backaground-color var(--default-duration) ease;
  cursor: pointer;
}

.idt-file-download-item__label {
  flex: 1 0;
}

.idt-file-download-item__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: var(--grey-light);
  transition: background-color var(--default-duration) ease;
}

.idt-file-download-item:hover {
  background-color: var(--grey-lightest);
}

.idt-file-download-item:hover .idt-file-download-item__action {
  background-color: var(--grey);
}

