.ticketing-payment-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .ticketing-payment-success {
    flex-direction: column;
  }
}

@media only screen and (min-width: 992px) {
  .ticketing-payment-success {
    flex-direction: row;
  }
}

.ticketing-payment-success__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 479px) {
  .ticketing-payment-success__infos {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 480px)and (max-width: 639px) {
  .ticketing-payment-success__infos {
    margin-bottom: 50px;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .ticketing-payment-success__infos {
    margin-bottom: 60px;
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .ticketing-payment-success__infos {
    font-size: 3.125rem;
  }
}

.ticketing-payment-success__infos__message {
  display: flex;
  align-items: baseline;
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
}

@media only screen and (max-width: 1023px) {
  .ticketing-payment-success__infos__message {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .ticketing-payment-success__infos__message {
    margin-bottom: 50px;
  }
}

.ticketing-payment-success__infos__message__icon.idt-icon ::v-deep .ideacon {
  color: var(--main-color);
}

@media only screen and (max-width: 1023px) {
  .ticketing-payment-success__infos__message__icon.idt-icon ::v-deep .ideacon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .ticketing-payment-success__infos__message__icon.idt-icon ::v-deep .ideacon {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 1rem;
  }
}

.ticketing-payment-success__infos__continue {
  margin: 0;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  line-height: .75;
}

@media only screen and (max-width: 639px) {
  .ticketing-payment-success__infos__continue {
    width: 180px;
    height: 36px;
    margin-top: 5px;
    font-size: 1rem;
    line-height: .5;
  }
}

@media only screen and (min-width: 640px) {
  .ticketing-payment-success__infos__continue {
    width: 200px;
    height: 50px;
    font-size: 1.125rem;
  }
}

.ticketing-payment-success__ticket {
  width: 280px;
}

@media only screen and (min-width: 1024px) {
  .ticketing-payment-success__ticket {
    margin-left: 50px;
  }
}

