.agenda-categories__content__delete {
  color: var(--red);
}

.agenda-categories__content__delete:hover {
  margin-top: 1px;
  cursor: pointer;
  filter: brightness(75%);
}

.agenda-categories__content__delete__icon {
  color: var(--red);
}

.agenda-categories__content__cat-button .button {
  height: 48px;
  width: 122px;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  margin: 0 0 1.75rem 0;
}

