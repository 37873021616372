.bo-prizes-layouts {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.bo-prizes-layouts .idt-prize-layout {
  margin-right: 9px;
  cursor: pointer;
}

