.idt-create-survey-multiple .idt-checkbox {
  margin-bottom: var(--rem-size-8);
  padding: 3px .5rem;
  border: 2px solid var(--grey-light);
  border-radius: var(--global-radius);
  box-shadow: none;
}

.idt-create-survey-multiple ::v-deep .idt-checkbox label {
  padding: var(--rem-size-8);
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.idt-create-survey-multiple ::v-deep .idt-checkbox .box {
  margin-right: var(--rem-size-18);
  background: var(--grey);
}

.idt-create-survey-multiple__title {
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-create-survey-multiple__answers {
  margin-bottom: 1rem;
}

.idt-create-survey-multiple__answers__item.ghost {
  cursor: grab;
  opacity: .5;
  background: var(--grey-lighter);
}

.idt-create-survey-multiple__answers__item__remove {
  position: relative;
  cursor: pointer;
}

.idt-create-survey-multiple__answers__item__remove ::v-deep .ideacon {
  position: relative !important;
  color: var(--grey-dark) !important;
}

