



























































































































@import '@/styles/mixins/index';

.idt-footer {
  color: var(--white);
  background-color: var(--grey-darkest);

  &__content {
    display: flex;
    flex-flow: row wrap;
    min-height: 120px;

    @include respond-to(max-medium) {
      padding: 2rem 0;
    }
    @include respond-to(medium) {
      padding: 2rem;
    }
    @include respond-to(max-slarge) {
      flex-flow: column wrap;
      justify-content: center;
    }
    @include respond-to(slarge) {
      justify-content: space-between;
    }
  }
  &__info {
    background-color: var(--black-alpha30);

    @include respond-to(max-medium) {
      padding: 0.5rem 2rem;
    }
    @include respond-to(medium) {
      padding: 1.5rem 4rem;
    }
  }
  &__copy {
    @include respond-to(max-medium) {
      font-size: var(--rem-size-14);
    }
  }
  &__container {
    display: flex;
    align-items: center;
    padding: 0 2rem;

    &--right {
      justify-content: space-between;

      @include respond-to(max-medium) {
        margin-bottom: 2rem;
      }
    }
  }
  &__link-list {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      flex: 0 0 auto;
      margin-bottom: 0.5rem;
      padding-right: 1rem;
      line-height: 1.2;

      @include respond-to(max-medium) {
        width: 50%;
      }
      @include respond-to(medium-large) {
        width: 33.33333%;
      }
      @include respond-to(large) {
        width: 25%;
      }

      a {
        color: var(--main-color-light);

        &:hover {
          color: var(--main-color-lighter);
        }
      }
    }
  }
  &__logo {
    ::v-deep &__svg {
      fill: var(--white);

      @include respond-to(max-medium) {
        width: 200px !important;
        height: 38px !important;
      }
      @include respond-to(medium) {
        width: 242px !important;
        height: 55px !important;
      }
    }
    &__img {
      max-width: 200px;
      max-height: 44px;
    }
  }
  &__back-to-top {
    display: inline-block;
    flex: 0 0 auto;
    width: 44px;
    height: 44px;
    margin-left: 2rem;
    color: var(--white-alpha50);
    border: 2px solid var(--white-alpha50);
    border-radius: 5px;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
  }
}
