




































































































































































































@import '@/styles/mixins/index';

.idt-sidebar-users-list {
  height: calc(100%);
  overflow-y: auto;

  &__loader {
    width: 50px;
    height: 50px;
    margin: var(--rem-size-24) auto;
  }
  &__not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: var(--chat-min-with);
    padding: 0 var(--rem-size-16) var(--rem-size-16) var(--rem-size-16);

    label {
      display: block;
      color: var(--white);
      font-weight: var(--font-weight-light);
      text-align: center;
    }
  }
}
