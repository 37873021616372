.file-preview {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &__figure,
  &__file {
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: var(--global-radius);
  }
  &__figure {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

  }
  &__file {
    position: relative;
    background-color: var(--grey-lighter);
    text-align: center;

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      color: var(--grey);
      font-size: 50px;
      transform: translate(-50%, -50%);
    }
  }
  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25px;
    height: 25px;
    background-color: var(--danger-color);
    border: none;
    border-radius: var(--global-radius) 0;
    text-align: center;
    z-index: 5;
    transition: all ease .4s;
    cursor: pointer;

    &__icon {
      color: var(--white);
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: var(--global-radius);
    background-color: var(--black-alpha40);
    text-align: center;
    opacity: 0;
    transition: all ease .4s;
    z-index: 4;

    &:hover {
      opacity: 1;
    }
    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 24px;
      color: var(--white);
      transform: translate(-50%, -50%);
    }
  }
}
