























































































































.fo-project-bar-team-notes {
  &__edit {
    &__content {
      padding: var(--rem-size-16);
      font-size: var(--rem-size-12);
      font-weight: var(--font-weight-medium);

      &__textarea {
        margin-top: var(--rem-size-16);
      }

      &__buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__view {
    &__notes {
      height: 100px;
      margin-top: var(--rem-size-16);
      margin-bottom: var(--rem-size-24);
      padding: var(--rem-size-16);
      border-radius: var(--global-radius);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-normal);
      background-color: var(--white);
      color: var(--grey-darkest);
      overflow: auto;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}
