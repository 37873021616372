// Shadow for scrollable elements
@mixin shadowScroll() {
  background: linear-gradient(var(--white) 30%, rgba(255, 255, 255, 0)),
              linear-gradient(rgba(255, 255, 255, 0), var(--white) 70%) 0 100%,
              radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)),
              radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: var(--white);
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
