.statistics-number {
  font-size: var(--rem-size-14);
}

@media only screen and (max-width: 479px) {
  .statistics-number {
    padding: .75rem 1rem;
  }
}

@media only screen and (min-width: 480px) {
  .statistics-number {
    padding: 1.5rem 2rem;
  }
}

.statistics-number__title {
  font-weight: var(--font-weight-bold);
}

.statistics-number__data {
  font-size: var(--rem-size-40);
}

.statistics-number__status {
  display: flex;
  height: 100%;
}

.statistics-number__status .status__loader {
  width: 50px;
  height: 50px;
  margin: auto;
}

.statistics-number__status span {
  margin: auto;
}

.statistics-number__evolution {
  display: flex;
  align-items: center;
}

.statistics-number__evolution__icon {
  margin-right: 5px;
}

.statistics-number__evolution__icon--decreased {
  transform: rotate(90deg);
}

