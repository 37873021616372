.selection-container__block__text {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100px;
  padding-left: 40px;
  font-weight: 500;
}

.selection-container__block__text__title {
  color: var(--black);
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
}

.selection-container__block__timeslots {
  min-height: 200px;
  max-height: calc(100vh - 260px);
  overflow-x: auto;
}

.selection-container__block:not(:last-child) {
  border-bottom: 1px solid var(--grey);
}

