

































































































































@import '@/styles/mixins/index';

.idt-ticket-carousel {
  width: 100%;

  & ::v-deep .slick-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;

    .slick-prev {
      flex: 1 1 auto;
      margin-right: 25px;
    }
    .slick-next {
      flex: 1 1 auto;
      margin-left: 25px;
    }
    .slick-disabled {
      opacity: .3;
    }
    .slick-list {
      flex: 1 1 auto;
      width: 100%;

      .slick-slide {
        @include respond-to(max-medium) {
          padding: 0 5px;
        }
        @include respond-to(medium) {
          padding: 0 10px;
        }
      }
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      width: 100%;
      list-style-type: none;

      @include respond-to(max-medium) {
        margin: 0.5rem 0 0 0;
      }
      @include respond-to(medium) {
        margin: 0;
      }

      li {
        position: relative;
        width: 30px;
        height: 20px;
        padding: 0;
        cursor: pointer;

        &.slick-active {
          button {
            &:before {
              opacity: 1;
            }
          }
        }
        button {
          display: block;
          width: 30px;
          height: 20px;
          padding: 5px;
          font-size: 0;
          line-height: 0;
          cursor: pointer;

          &:before {
            content: '•';
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            color: var(--main-color);
            font-family: 'slick';
            font-size: 2rem;
            line-height: 1;
            transform: translate(-50%, -50%);
            opacity: 0.5;
          }
        }
      }
    }
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      border: solid 1px var(--main-color);
      border-radius: 50%;

      .ideacons {
        color: var(--main-color);
        font-size: 1.25rem;
      }
    }
  }
  &.register {
    & ::v-deep .slick-slider {
      .slick-list {
        @include respond-to(medium-large) {
          max-width: calc(280px * 2);
        }
        @include respond-to(small-xlarge) {
          width: calc(100vw - 242px);
        }
        @include respond-to(xlarge) {
          max-width: calc(280px * 4);
        }
      }
    }
  }
  &.blocking {
    & ::v-deep .slick-slider {
      flex-flow: column wrap;

      .slick-list {
        @include respond-to(medium-xlarge) {
          flex: 0 0 auto;
          max-width: 600px;
        }
        @include respond-to(xlarge) {
          flex: 1 1 auto;
          max-width: 950px;
        }
      }
    }
  }
}
// Transition styles
.show-payment-enter-active,
.show-payment-leave-active {
  transition: all 0.3s ease;
}
.show-payment-enter,
.show-payment-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
