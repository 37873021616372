


















































































































































































































.idt-fs-upload-file {

  &.draggable {
    display: flex;
    align-items: stretch;
    border: 1px solid var(--grey);
    border-radius: var(--global-radius);
  }

  &__right-content {
    display: flex;
    align-items: center;
    padding: 0 var(--rem-size-8);

    &, > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
