











































































































@import '@/styles/mixins/index';

.gamification-history-row {
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-bottom: 10px;
  background-color: var(--white);
  border-radius: 5px;
  overflow: hidden;

  &--reward {
    background-color: var(--main-color-lighter);
  }
  &--targetable:hover {
    cursor: pointer;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
    background-color: var(--grey-lighter);
    border-radius: 50%;

    @include respond-to(max-slarge) {
      width: 30px;
      height: 30px;
      margin: auto 0.5rem;
    }
    @include respond-to(slarge) {
      width: 40px;
      height: 40px;
      margin: auto 1rem;
    }

    &--reward {
      color: var(--white);
      background-color: var(--main-color);
    }
    ::v-deep .ideacon {

      @include respond-to(slarge) {
        width: 1.25rem;
        height: 1.25rem;
      }

      &.idea-ht-bubbles {

        @include respond-to(max-slarge) {
          width: 1.5rem;
          height: 1.5rem;
        }
        @include respond-to(slarge) {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
  &__content {
    width: 0;
    padding: 0.75rem 0;

    @include respond-to(max-slarge) {
      flex-grow: 8;
    }
    @include respond-to(slarge) {
      flex-grow: 6;
    }

    .content__title {
      width: 100%;
      color: var(--grey-darkest);
      font-weight: var(--font-weight-medium);
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include respond-to(max-slarge) {
        font-size: var(--rem-size-16);
      }
      @include respond-to(slarge) {
        font-size: var(--rem-size-18);
      }

      &--reward {
        color: var(--main-color)
      }
    }
    .content__description {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include respond-to(max-slarge) {
        font-size: var(--rem-size-12);
      }
      @include respond-to(slarge) {
        font-size: var(--rem-size-14);
      }

      &--reward {
        color: var(--black);
      }
      &__type {
        @include respond-to(max-slarge) {
          display: none;
        }
      }
    }
  }
  &__flag {
    @include respond-to(max-slarge) {
      flex-grow: 1;
      margin: auto 0.5rem;
    }
    @include respond-to(slarge) {
      margin: auto 1rem;
    }

    .flag__reward {
      vertical-align: middle;
      color: var(--white);
      background-color: var(--main-color);
      border-radius: 50px;
      font-size: var(--rem-size-18);
      text-align: center;

      @include respond-to(max-slarge) {
        padding: 0.25rem 0.5rem;
      }
      @include respond-to(slarge) {
        padding: 0.25rem 1rem;
      }
    }
    .gamification-points {
      @include respond-to(max-slarge) {
        padding: 0.25rem 0.5rem;
      }

      ::v-deep &__value {
        @include respond-to(max-slarge) {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
