.payment-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.payment-loading__title {
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
  line-height: normal;
  letter-spacing: .5px;
}

@media only screen and (max-width: 639px) {
  .payment-loading__title {
    margin-bottom: 20px;
    font-size: 1.75rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .payment-loading__title {
    margin-bottom: 25px;
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-loading__title {
    margin-bottom: 30px;
    font-size: 2.5rem;
  }
}

.payment-loading__text {
  color: var(--grey-darker);
  font-weight: var(--font-weight-light);
  line-height: 1.25;
}

@media only screen and (max-width: 639px) {
  .payment-loading__text {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .payment-loading__text {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-loading__text {
    font-size: 1.375rem;
  }
}

.payment-loading__spinner {
  color: var(--secondary-color);
}

@media only screen and (max-width: 639px) {
  .payment-loading ::v-deep .idt-spinner {
    --spinner-radius: 25px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .payment-loading ::v-deep .idt-spinner {
    --spinner-radius: 35px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-loading ::v-deep .idt-spinner {
    --spinner-radius: 40px;
    margin-bottom: 30px;
  }
}

