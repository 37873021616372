.old-browsers {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .page__content {
    flex-grow: 1;
    background-color: #E5E5E5;
  }

  &__page {
    padding: 3.5rem 0;
    background-color: #E5E5E5;

    &__content {
      width: 100%;
      margin: auto;

      @include respond-to(max-medium) {
        padding: 1rem;
      }
      @include respond-to(medium-slarge) {
        padding: 2rem;
      }
      @include respond-to(slarge) {
        max-width: 650px;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }
    }

    &__warn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 88px;
      min-width: 88px;
      margin-right: 2rem;
      background-color: #FBD9D8;
      border-radius: 50px;
      font-size: 22px;

      i {
        color:#FF0000;
      }
    }

    &__title {
      color: #000000;
      font-weight: bold;
      font-size: 1.8rem;
    }

    &__list {
      margin-bottom: 24px;
    }

    &__info {
      display: flex;
      width: 100%;
      padding: 24px;
      background-color: #D1D1D1;
      border-radius: 5px;

      &__text {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        h4 {
          color: #000000;
          font-size: 1rem;
        }
      }
    }
  }
}

.browser-support-card {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  height: 6.75rem;
  margin-bottom: 4px;
  padding: 24px;
  color: #000000;
  box-shadow: 0px 2px 3px 0px #24263629;
  border-radius: 5px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #D6F4F5;
    border-radius: 50px;

    img {
      height: 20px;
      width: 20px;
    }
  }
  &__name {
    display: flex;
    flex-grow: 1;
    margin-left: 1rem;
    font-weight: bold;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 9px 24px;
    background-color:#D6F4F5;
    font-weight: bold;
    color: #2DAEB2;
    font-size: 14px;
    border-radius: 50px;

    i {
      margin-left: 1rem;
    }
  }

  &--no-shadow {
    border: 1px solid #D1D1D1;
    box-shadow: none;
  }

  &--no-border {
    padding: 0 !important;
    border: none;
  }
}
