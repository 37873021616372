







































.favorite {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: var(--rem-size-32);
  width: fit-content;
  margin: 0;
  min-width: var(--rem-size-32);
  color: var(--white);
  background-color: var(--black-alpha50);
  border-radius: var(--rem-size-16);

  &__icon {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 7px;
  }

  &__text {
    max-width: 0;
    margin-right: 33px;
    padding-left: 0;
    font-family: var(--text-font-family);
    font-size: var(--rem-size-12);
    font-weight: var(--font-weight-bold);
    line-height: var(--rem-size-14);
    overflow: hidden;
    opacity: 0;
    text-align: left;
    transition: max-width 200ms linear, padding-left 200ms;
    white-space: nowrap;
    animation: hover-opac2 100ms;
  }
  &:hover &__text {
    display: inline-block;
    max-width: 400px;
    padding-left: 15px;
    animation: hover-opac 100ms;
    animation-delay: 200ms;
    animation-fill-mode: forwards;
  }

  @keyframes hover-opac {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes hover-opac2 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
