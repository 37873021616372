










































.bo-account-shortlist-menu {
  &__item {
    width: 100%;
    padding: 20px 0;
    color: var(--grey-darkest);
    text-align: center;

    &__text {
      margin-left: 10px;
    }

    &:hover {
      background-color: var(--grey-lighter);
    }
  }
}
