.bo-project-modal-settings {
  &__header,
  &__footer {
    position: sticky;
    background-color: var(--white);
  }
  &__header {
    top: 0;
    border-bottom: 1px solid var(--grey);
    z-index: 10;
  }
  &__title {
    margin-bottom: 0;
    color: var(--black);
    font-weight: var(--font-weight-medium);
  }
  &__content {
    width: 100%;
    color: var(--grey-darker);
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-normal);

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: var(--grey-darkest);
      font-size: var(--rem-size-14);
    }
  }
  &__footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--grey);
  }
}
