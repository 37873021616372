














































































































































.forgot-password {
  &__subtitle {
    font-size: var(--rem-size-20);
    font-weight: var(--font-weight-medium);
    text-align: center;
  }

  &__icon {
    margin: 2rem auto;
    text-align: center;
  }

  &__content {
    margin-bottom: 1rem;
    font-size: var(--rem-size-18);
    text-align: center;
  }

  &__input {
    padding-bottom: 1rem;

    ::v-deep .idt-required-field {
      top: 76px;
    }
  }
  &__back {
    position: relative;
    margin-bottom: 1.5rem;
    text-align: right;

    &__button {
      color: var(--main-color);
      font-size: var(--rem-size-14);
      text-decoration: underline;
      z-index: 10;
      cursor: pointer;
    }
  }
}
