



















@import '@/styles/functions';

.idt-form-element-description {
  padding-top: remCalc(8);
  font-size: remCalc(14);
}
