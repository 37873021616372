




















































































@import '@/styles/mixins/index';

.event-projects {
  padding: 0;

  &__container {
    @include respond-to(max-large) {
      padding: 3rem 1rem;
    }
    @include respond-to(large) {
      padding: 5rem 3rem;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    @include respond-to(max-xlarge) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: auto;

      .event-projects__list__item {
        flex-shrink: 0;
      }
    }
    &.first-prize {
      overflow: hidden !important;
    }
    &:after {
      @include respond-to(max-small) {
        content: '';
        min-width: 0.4rem;
      }
    }
    &__item {
      display: flex;
      width: 375px;
      margin: 0.4rem;

      @include respond-to(max-small) {
        width: 300px;
      }
      .idt-project-card {
        max-width: 375px;

        @include respond-to(max-small) {
          max-width: 300px;
        }
      }
    }
    &__first-prize {
      width: 100%;
      max-width: 1170px;
      margin: auto;
      padding-bottom: 1rem;
      border-bottom: 2px solid var(--grey);

      .idt-project-card {
        max-width: 100%;
      }
    }
  }
}
