.idt-ticket-feature {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 20px;
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .idt-ticket-feature {
    padding-bottom: .625rem;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .idt-ticket-feature {
    padding-bottom: .3125rem;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-feature {
    padding: .3125rem 0;
    border-bottom: 1px solid var(--grey-lighter);
  }

  .idt-ticket-feature.last {
    border: none;
  }
}

.idt-ticket-feature__icon {
  color: var(--main-color);
}

.idt-ticket-feature__label {
  width: 100%;
  margin-left: 10px;
  color: var(--grey-darker);
  font-weight: var(--font-weight-normal);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.idt-ticket-feature.disabled .idt-ticket-feature__icon {
  color: var(--grey);
}

.idt-ticket-feature.disabled .idt-ticket-feature__label {
  color: var(--grey);
  font-weight: var(--font-weight-light);
  font-style: italic;
}

