.gamification-menu {
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .gamification-menu .gamification-widget {
    display: none !important;
  }
}

