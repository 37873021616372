







































































@import '@/styles/mixins/index';

.idt-messenger-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  @include respond-to(max-medium) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  @include respond-to(medium) {
    flex: 1 1 auto;
    position: relative;
  }

  &.conference-mode {
    @include respond-to(slarge) {
      max-width: calc(100% - 470px);
      margin-right: auto;
    }
  }
  &__room {
    @include respond-to(max-medium) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
    }
    @include respond-to(medium) {
      flex: 0 0 auto;
      position: relative;
      width: 360px;
      margin-left: 1rem;
    }

    .room {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      box-shadow: 0 1px 8px 0 var(--black-alpha10);
      transition: all 0.3s ease;
      animation: 0.3s ease openChatRoom;

      @include respond-to(medium) {
        height: 565px;
        max-height: calc((var(--vh, 1) * 100) - var(--menu-height));
        border-radius: 5px 5px 0 0;
      }

      &.reduce {
        @include respond-to(max-medium) {
          bottom: calc(44px - (var(--vh, 1) * 100));
        }
        @include respond-to(medium) {
          bottom: calc(44px - 565px);
          max-height: 565px;
        }

        .idea-ht-chevron-bottom::before {
          display: inline-block;
          transform: rotate(180deg);
        }
        .room__header:hover {
          cursor: pointer;
        }
      }
    }
  }

}
@keyframes openChatRoom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
