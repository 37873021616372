.forgot-password__subtitle {
  font-size: var(--rem-size-20);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.forgot-password__icon {
  margin: 2rem auto;
  text-align: center;
}

.forgot-password__content {
  margin-bottom: 1rem;
  font-size: var(--rem-size-18);
  text-align: center;
}

.forgot-password__input {
  padding-bottom: 1rem;
}

.forgot-password__input ::v-deep .idt-required-field {
  top: 76px;
}

.forgot-password__back {
  position: relative;
  margin-bottom: 1.5rem;
  text-align: right;
}

.forgot-password__back__button {
  color: var(--main-color);
  font-size: var(--rem-size-14);
  text-decoration: underline;
  z-index: 10;
  cursor: pointer;
}

