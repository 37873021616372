




























































































.idt-tag-list {
  &__item {
    display: inline-flex;
    margin-right: var(--rem-size-5);
  }
}
