









































































@import '@/styles/mixins/index';

.fo-project-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: 400px;
  max-width: 400px;
  padding: var(--rem-size-12) var(--rem-size-12) 9rem var(--rem-size-12);
  color: var(--white);
  background-color: var(--grey-darkest);
  position: sticky;
  top: var(--menu-height);

  height: 96vh;
  transition: all 0.5s ease, transform .3s ease;
  overflow: auto;
  z-index: 1;

  @include respond-to(max-slarge) {
    position: absolute;
    min-width: 100%;
    height: 100%;
    overflow: visible;
    top: 0;
    z-index: 11;

    &--mobile-hidden {
      transform: translate3d(-100%, 0, 0);
    }
  }

  &__deadline {
    min-height: var(--rem-size-32);
    margin-bottom: var(--rem-size-24);
  }
}
