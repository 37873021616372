.ticketing-upgrade-button {
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .ticketing-upgrade-button {
    width: 160px;
    height: 40px;
    margin-top: 15px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 992px) {
  .ticketing-upgrade-button {
    width: 115px;
    height: 34px;
    font-size: .875rem;
  }
}

