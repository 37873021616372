.idt-survey-question-single .idt-radiobutton {
  margin-bottom: var(--rem-size-8);
  padding: 3px .5rem;
  border: 2px solid rgba(0,0,0,0);
  border-radius: var(--global-radius);
  box-shadow: none;
}

.idt-survey-question-single .idt-radiobutton:hover:not(.idt-radiobutton--checked) {
  border: 2px solid var(--grey-light);
}

.idt-survey-question-single .idt-radiobutton--checked {
  border: 2px solid var(--main-color);
}

.idt-survey-question-single ::v-deep .idt-radiobutton label {
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.idt-survey-question-single ::v-deep .idt-radiobutton .box {
  margin-right: var(--rem-size-18);
  background: var(--grey);
}

