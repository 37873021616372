.live-chat-message {
  position: relative;
  padding: 0 var(--rem-size-10);
  border-radius: var(--global-radius);
  transition: background-color .15s ease-in-out;
  z-index: 1;
}

.live-chat-message:not(:last-child) {
  border-bottom: 1px solid var(--grey-lighter);
}

.live-chat-message:hover {
  background: var(--grey-lightest);
}

.live-chat-message:hover .live-chat-message-actions {
  display: block;
}

.live-chat-message__gif-preview {
  padding-bottom: var(--rem-size-15);
}

.live-chat-message__gif-preview img {
  position: relative;
  max-height: 150px;
  border-radius: var(--global-radius);
}

.live-chat-message--edit {
  background-color: var(--main-color-alpha20) !important;
}

.live-chat-message--edit .live-chat-message-content__flex {
  padding-top: var(--rem-size-16);
}

.live-chat-message--edit .live-chat-form__container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.live-chat-message--edit .live-chat-form__container .live-chat-form__actions {
  background-color: var(--white);
}

.live-chat-message--edit .live-chat-form__actions__submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto !important;
  height: auto !important;
  min-width: 66px;
  min-height: var(--rem-size-27);
  padding: 0 var(--rem-size-15);
  color: var(--white);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-12);
}

.live-chat-message--edit .live-chat-form__actions__submit--cancel {
  background: rgba(0,0,0,0);
  border: 1.5px solid var(--main-color);
  color: var(--main-color);
}

