.lobby-user-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--rem-size-22) var(--rem-size-24);
  border-bottom: 1px solid var(--grey-light);
  font-size: var(--rem-size-18);
  font-weight: bold;
}

.lobby-user-list__header>div {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.lobby-user-list__header .idt-icon {
  cursor: pointer;
}

.lobby-user-list__header__text {
  margin-right: var(--rem-size-4);
  color: var(--grey-darkest);
}

@media only screen and (max-width: 639px) {
  .lobby-user-list__header__left {
    display: flex;
    align-items: center;
    margin-right: var(--rem-size-4);
  }
}

@media only screen and (min-width: 640px) {
  .lobby-user-list__header__left {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  .lobby-user-list__header__close {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .lobby-user-list__header__close {
    display: inline-block;
  }
}

@media only screen and (max-width: 991px) {
  .lobby-user-list {
    box-shadow: none;
  }
}

