

































































.idt-create-survey-like {

  &__title {
    font-size: var(--rem-size-12);
    color: var(--black);
    font-weight: var(--font-weight-bold);
  }

  &__row {
    display: flex;
    align-items: stretch;
    margin-bottom: var(--rem-size-10);

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 96px;
      padding: 0 var(--rem-size-24);
      background: var(--grey);
      border-radius: var(--global-radius) 0 0 var(--global-radius);
    }

    .idt-input-container {
      flex-grow: 1;
      padding-bottom: 0;

      & ::v-deep .idt-input {
        border-radius: 0 var(--global-radius) var(--global-radius) 0;
      }
    }
  }
}
