.idt-user-profile-container {
  position: relative;
  width: 0;
  height: 1rem;
  transition: width .3s ease;
  transform: translate3d(2rem, 0, 0);
}

@media only screen and (min-width: 992px) {
  .idt-user-profile-container {
    position: absolute;
    right: calc(100% - 26px);
  }
}

@media only screen and (max-width: 991px) {
  .idt-user-profile-container {
    flex: 0 0 auto;
  }
}

.idt-user-profile-container.open {
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container.open {
    width: 100%;
  }
}

@media only screen and (min-width: 640px)and (max-width: 991px) {
  .idt-user-profile-container.open {
    width: 564px;
  }
}

@media only screen and (min-width: 992px)and (max-width: 1199px) {
  .idt-user-profile-container.open {
    width: 580px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-user-profile-container.open {
    width: 700px;
  }
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container.open .idt-user-profile {
    transform: translate3d(0, 0, 0);
  }
}

.idt-user-profile-container.open .idt-user-profile__buttons {
  display: flex;
}

.idt-user-profile-container .idt-user-profile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(var(--vh, 1vh)*100 - var(--menu-height));
  padding: 0 1rem;
  padding-top: 51px;
  overflow: auto;
  background: var(--white);
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile {
    width: 100%;
    transform: translate3d(0, 100%, 0);
  }
}

@media only screen and (min-width: 640px)and (max-width: 991px) {
  .idt-user-profile-container .idt-user-profile {
    width: 564px;
  }
}

@media only screen and (min-width: 992px)and (max-width: 1199px) {
  .idt-user-profile-container .idt-user-profile {
    width: 580px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-user-profile-container .idt-user-profile {
    width: 700px;
  }
}

.idt-user-profile-container .idt-user-profile__buttons {
  display: none;
  position: absolute;
  right: 100%;
  top: calc(var(--vh, 1vh)*-100 + 1rem + var(--menu-height));
  flex-direction: column;
  padding: 12px;
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__buttons {
    left: 0;
    z-index: 1;
  }
}

.idt-user-profile-container .idt-user-profile__buttons button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid var(--grey-light) !important;
}

.idt-user-profile-container .idt-user-profile__buttons button:first-child {
  margin-bottom: 15px;
}

.idt-user-profile-container .idt-user-profile__info {
  max-width: 600px;
  margin: auto;
}

.idt-user-profile-container .idt-user-profile__info__summary {
  display: flex;
  align-items: center;
  gap: var(--rem-size-24);
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__info__summary {
    flex-wrap: wrap;
  }
}

.idt-user-profile-container .idt-user-profile__info__summary__avatar {
  min-width: 121px;
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__info__summary__avatar {
    margin: auto;
  }
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__info__summary-container {
    flex-basis: 100%;
    text-align: center;
  }
}

.idt-user-profile-container .idt-user-profile__info__summary__name {
  font-size: var(--rem-size-18);
  color: var(--black);
  font-weight: var(--font-weight-bold);
  margin-bottom: 6px;
}

.idt-user-profile-container .idt-user-profile__info__summary__pro-info {
  color: var(--black);
  font-size: var(--rem-size-14);
  margin-bottom: 9px;
}

.idt-user-profile-container .idt-user-profile__info__summary__tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--rem-size-8);
  margin-bottom: 12px;
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__info__summary__tags {
    justify-content: center;
  }
}

.idt-user-profile-container .idt-user-profile__info__summary__tags__prize {
  max-width: 200px;
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  padding-top: 2px;
  text-align: center;
  border-radius: 5px;
  color: var(--black);
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link:hover {
  color: var(--white);
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link.facebook {
  margin-right: 1px;
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link.facebook:hover {
  background-color: var(--facebook-color);
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link.twitter:hover {
  background-color: var(--twitter-color);
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link.linkedIn:hover {
  background-color: var(--linkedin-color);
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link.youtube {
  display: inline-block;
  width: 22px;
  height: 16px;
  padding-top: 2px;
  margin-left: 2px;
  margin-right: .5rem;
  vertical-align: sub;
  color: var(--white);
  background: var(--black);
  border-radius: 4px;
}

.idt-user-profile-container .idt-user-profile__info__summary__social-link.youtube:hover {
  background-color: var(--youtube-color);
}

.idt-user-profile-container .idt-user-profile__info__summary__website {
  color: var(--black);
  text-decoration: underline;
  font-size: var(--rem-size-14);
}

.idt-user-profile-container .idt-user-profile__info__connect {
  margin-top: 25px;
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__info__connect {
    text-align: center;
  }
}

.idt-user-profile-container .idt-user-profile__info__connect__title {
  margin-bottom: 11px;
  text-transform: uppercase;
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-user-profile-container .idt-user-profile__info__connect__buttons button {
  padding: 0 35px;
  height: 45px;
  line-height: 45px;
}

@media only screen and (min-width: 640px) {
  .idt-user-profile-container .idt-user-profile__info__connect__buttons button:first-child {
    margin-right: 15px;
  }
}

.idt-user-profile-container .idt-user-profile__info__connect__buttons button .ideacon {
  margin-right: 15px;
  margin-left: -6px;
  margin-top: -5px;
}

.idt-user-profile-container .idt-user-profile__info__about {
  margin: 40px 2.2rem 0 2.2rem;
}

.idt-user-profile-container .idt-user-profile__info__about__title {
  margin-bottom: 4px;
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-user-profile-container .idt-user-profile__info__about__date {
  font-size: var(--rem-size-12);
  color: var(--black-alpha70);
}

.idt-user-profile-container .idt-user-profile__info__about__description {
  margin-top: 29px;
  color: var(--black);
}

.idt-user-profile-container .idt-user-profile__activity {
  min-height: 450px;
  margin: 0 -1rem;
  margin-top: 30px;
  padding: 0 1rem;
  background-color: var(--grey-lighter);
}

.idt-user-profile-container .idt-user-profile__activity-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  min-height: 450px;
  margin: auto;
  padding: 30px 0;
}

.idt-user-profile-container .idt-user-profile__activity__tabs {
  display: flex;
  align-items: center;
  margin-left: 0;
}

.idt-user-profile-container .idt-user-profile__activity__tabs__item {
  cursor: pointer;
  padding: 0;
  padding-bottom: 5px;
  margin-right: 30px;
  list-style-type: none;
  font-weight: bold;
}

.idt-user-profile-container .idt-user-profile__activity__tabs__item.selected {
  color: var(--black);
  border-bottom: 2px solid var(--main-color);
}

.idt-user-profile-container .idt-user-profile__activity__list {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  flex-grow: 1;
  margin: 0 -10px;
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__activity__list {
    flex-flow: row nowrap;
    overflow: auto;
  }
}

.idt-user-profile-container .idt-user-profile__activity__list__item {
  display: flex;
  flex-basis: 50%;
  padding: 10px;
}

@media only screen and (max-width: 639px) {
  .idt-user-profile-container .idt-user-profile__activity__list__item {
    min-width: 295px;
  }
}

.idt-user-profile-container .idt-user-profile__activity__list__no-item {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.idt-user-profile-container .idt-user-profile__activity__list__no-item h5 {
  margin-top: 1rem;
}

