.idt-bi-button-enable.button-group {
  margin: 0;
}

.idt-bi-button-enable.button-group .button {
  min-width: 135px;
  height: 48px;
  margin: 0;
}

.idt-bi-button-enable.button-group .button:first-child {
  border-radius: var(--global-radius) 0 0 var(--global-radius);
}

.idt-bi-button-enable.button-group .button:last-child {
  border-radius: 0 var(--global-radius) var(--global-radius) 0;
}

