.idt-survey-conference-modal__title {
  margin-bottom: 1rem;
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-conference-modal__content .idt-input-container ::v-deep label {
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-conference-modal__content__list {
  height: 300px;
  overflow: auto;
}

.idt-survey-conference-modal__content__list__item {
  padding-bottom: var(--rem-size-12);
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--grey);
  cursor: pointer;
}

.idt-survey-conference-modal__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 72px;
  padding: 0 1rem;
  border-top: 2px solid var(--grey);
}

.idt-survey-conference-modal__footer .button {
  min-width: 115px;
  margin-bottom: 0;
  margin-left: 1rem;
}

