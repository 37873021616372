





























































































































































































































.idt-user-avatar {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--grey-light);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;

  &.avatar--invited {
    background-color: var(--grey-light);
  }
  &__initial{
    display: inline-block;
    color: var(--white);
  }
  &__prize {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 50%;
    line-height: 0;

    &__icon {
      border-radius: 50%;
    }
  }
}
