.sso-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--rem-size-8);
}

.sso-buttons.no-separator {
  margin-bottom: 2rem;
}

.sso-buttons:not(.no-separator) {
  margin-bottom: var(--rem-size-8);
}

.sso-buttons__button {
  min-height: 46px;
  background-color: var(--sso-btn-bgcolor);
}

.sso-buttons__button:hover {
  background-color: var(--sso-btn-bgcolor-hover);
}

.sso-buttons__button~.btn-submit {
  margin-top: 1rem;
}

.sso-buttons__button.has-icon {
  display: grid;
  gap: 1rem;
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .sso-buttons__button.has-icon {
    grid-template-columns: 2fr 10fr;
  }
}

@media only screen and (min-width: 992px) {
  .sso-buttons__button.has-icon {
    grid-template-columns: 2.5fr 9.5fr;
  }
}

.sso-buttons__icon-container {
  text-align: right;
}

.sso-buttons__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--white);
  border-radius: 100%;
  color: var(--sso-btn-bgcolor);
}

