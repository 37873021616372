.custom-form-field-list {
  padding-left: 30px;
}

.custom-form-field-list__row {
  margin-bottom: 15px;
}

.custom-form-field-list__row:last-child {
  margin-bottom: 25px;
}

.custom-form-field-list .ghost {
  cursor: grab;
  opacity: .5;
  background: var(--grey-lighter);
  width: 600px;
}

