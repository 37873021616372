.bo-page-statistics {
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .bo-page-statistics {
    height: var(--bo-header-elm-height);
    padding: 0 20px 0 30px;
    background-color: var(--white);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-page-statistics {
    height: var(--bo-header-height);
  }
}

.bo-page-statistics__toggle {
  display: flex;
  align-items: center;
  color: var(--grey-darkest);
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .bo-page-statistics__toggle {
    height: var(--bo-header-elm-height);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-page-statistics__toggle {
    height: var(--bo-header-height);
  }
}

.bo-page-statistics__toggle__label {
  margin-right: .25rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

@media only screen and (max-width: 1199px) {
  .bo-page-statistics__toggle__icon {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-page-statistics__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--rem-size-10) var(--rem-size-16);
  }

  .bo-page-statistics__header__title {
    color: var(--grey-dark);
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }

  .bo-page-statistics__header__close {
    color: var(--grey-dark);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-page-statistics__header {
    display: none;
  }
}

.bo-page-statistics__content {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow: hidden;
  transition: all .3s ease-in-out;
}

@media only screen and (max-width: 639px) {
  .bo-page-statistics__content {
    top: calc(var(--bo-header-height));
    left: 0;
    width: 100%;
    box-shadow: 0 3px 6px var(--black-alpha12);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .bo-page-statistics__content {
    top: calc(var(--menu-height) + var(--bo-header-height));
    left: var(--vertical-menu-width);
    width: calc(100vw - var(--vertical-menu-width));
  }
}

@media only screen and (max-width: 1199px) {
  .bo-page-statistics__content {
    position: fixed;
    height: calc(var(--vh)*100 - (var(--menu-height) + var(--bo-header-height)));
  }
}

@media only screen and (min-width: 1200px) {
  .bo-page-statistics__content {
    position: absolute;
    min-width: 185px;
    top: 100%;
    right: 0;
    box-shadow: 0 3px 6px var(--black-alpha12);
  }
}

.bo-page-statistics__content.slide-content-enter-active {
  animation: slide-down .3s;
}

.bo-page-statistics__content.slide-content-leave-active {
  animation: slide-down .3s reverse;
}

.bo-page-statistics__list {
  margin: 0;
  padding: var(--rem-size-1) var(--rem-size-8);
}

.bo-page-statistics__list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--rem-size-7) var(--rem-size-8) var(--rem-size-3) var(--rem-size-8);
}

.bo-page-statistics__list-item:not(:last-child) {
  border-bottom: 1px dashed var(--grey-light);
}

.bo-page-statistics__list-item__number {
  color: var(--main-color);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-24);
  text-align: right;
}

@media only screen and (max-width: 1199px) {
  .bo-page-statistics__list-item__number {
    min-width: 30%;
  }
}

.bo-page-statistics__list-item__label {
  color: var(--grey-darkest);
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-18);
  white-space: nowrap;
}

@keyframes slide-down {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

