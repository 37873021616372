




























































































































@import '@/styles/mixins/index';

.idt-conference-countdown-updated {
  display: flex;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);

  &__line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__box {
      width: var(--rem-size-40);
      height: var(--rem-size-40);
      padding: var(--rem-size-8);
      background: var(--white);
      border-radius: var(--global-radius);
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-medium);
      text-align: center;
      line-height: var(--rem-size-24);
    }

    &__units {
      margin-left: var(--rem-size-8);
      font-size: var(--rem-size-14);
      line-height: var(--rem-size-20);

      &:not(:last-child) {
        margin-right: var(--rem-size-16);
      }
    }
  }
}
