.conference-controls {
  margin-top: 22px;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
}

.conference-controls__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conference-controls__autostart ::v-deep .idt-checkbox {
  margin: 0;
  background: none;
  border: none;
}

.conference-controls__autostart ::v-deep .idt-checkbox--checked {
  box-shadow: none;
}

.conference-controls__left-panel,
.conference-controls__right-panel {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}

.conference-controls__left-panel {
  border-radius: 23px 0 0 23px;
  background-color: var(--white);
  color: var(--main-color);
}

.conference-controls__left-panel--speaker {
  border-radius: 23px;
}

.conference-controls__left-panel__label {
  padding: 0 var(--rem-size-20);
  white-space: nowrap;
}

.conference-controls__left-panel__started {
  color: var(--black);
}

.conference-controls__right-panel {
  border-radius: 0 23px 23px 0;
  background-color: var(--main-color);
}

.conference-controls__right-panel__button {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: .5rem var(--rem-size-20);
  color: var(--white);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.conference-controls__right-panel__button__label {
  margin-right: 10px;
  white-space: nowrap;
}

.conference-controls__right-panel__started {
  background-color: #ea4335;
}

.conference-controls__loading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0 1rem;
  border-radius: 23px;
  background-color: var(--white);
  color: #ffaf22;
}

.conference-controls__loading__label {
  white-space: nowrap;
}

.conference-controls__loading__spinner {
  margin-left: 15px;
}

.conference-moderator-buttons-container {
  display: flex;
  flex-direction: column;
  height: 90px;
}

.conference-moderator-buttons-container__start-stream {
  width: 180px;
  height: 45px;
  border-radius: 25px;
  background: var(--white);
  color: var(--main-color);
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.conference-moderator-buttons-container__start-stream--dark {
  background: var(--main-color);
  color: var(--white);
}

.conference-moderator-buttons-container__start-stream:hover {
  background: var(--main-color-light);
  color: var(--main-color-darker);
}

.conference-moderator-buttons-container__start-stream ::v-deep .idt-icon {
  margin-left: 7px;
}

.conference-moderator-buttons-container__launching {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 45px;
  border-radius: 25px;
  background: var(--main-color-darker);
}

.conference-moderator-buttons-container__launching__label {
  color: var(--white);
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
}

.conference-moderator-buttons-container__launching__spinner {
  margin-left: 7px;
}

.conference-moderator-buttons-container__live {
  width: 85px;
  height: 35px;
  border: 1px solid var(--white);
  border-radius: var(--global-radius);
  color: var(--white);
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
}

.conference-moderator-buttons-container__live ::v-deep .idt-icon {
  margin-left: 7px;
}

.conference-moderator-buttons-container__stop-stream {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 45px;
  margin-top: 10px;
  border-radius: 25px;
  background: var(--white);
  color: #ff3d30;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.conference-moderator-buttons-container__stop-stream--dark {
  border: 1px solid #ff3d30;
}

.conference-moderator-buttons-container__stop-stream__square {
  width: 12px;
  height: 12px;
  margin-left: 7px;
  border-radius: 2px;
  background: #ff3d30;
}

.conference-moderator-buttons-container__stop-stream:hover {
  background: #ff3d30;
  color: var(--white);
}

.conference-moderator-buttons-container__stop-stream:hover div {
  background: var(--white);
}

