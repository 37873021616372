.idt-profile-system-checking__section {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--rem-size-24);
}

.idt-profile-system-checking__section--inline {
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.idt-profile-system-checking__section--inline>div:first-of-type {
  flex-grow: 1;
}

@media only screen and (max-width: 991px) {
  .idt-profile-system-checking__section--inline>div {
    margin-bottom: var(--rem-size-12);
    flex-grow: 1;
  }
}

.idt-profile-system-checking__section__title {
  margin-bottom: var(--rem-size-8);
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-profile-system-checking__version-info,
.idt-profile-system-checking__bandwidth-info {
  display: flex;
  flex-grow: 1;
  padding: var(--rem-size-24);
  background-color: var(--main-color-lighter);
  border-radius: var(--global-radius);
}

.idt-profile-system-checking__version-info .idt-icon,
.idt-profile-system-checking__bandwidth-info .idt-icon {
  color: var(--main-color);
}

.idt-profile-system-checking__version-info--limited,
.idt-profile-system-checking__bandwidth-info--limited {
  background-color: #fff3dd;
}

.idt-profile-system-checking__version-info--limited .idt-icon,
.idt-profile-system-checking__bandwidth-info--limited .idt-icon {
  color: #f1a627;
}

.idt-profile-system-checking__version-info__content,
.idt-profile-system-checking__bandwidth-info__content {
  margin-left: var(--rem-size-16);
}

.idt-profile-system-checking__bandwidth-info {
  margin-top: var(--rem-size-16);
  background-color: var(--grey-light);
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-profile-system-checking__bandwidth-info .idt-icon {
  color: var(--grey);
}

.idt-profile-system-checking__gauge img {
  width: 4rem;
}

.idt-profile-system-checking__bandwidth-test {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-right: var(--rem-size-16);
  padding: 9px var(--rem-size-24);
  background-color: #d6f4f5;
  font-weight: bold;
  color: #2daeb2;
  font-size: var(--rem-size-14);
  border-radius: 50px;
  cursor: pointer;
}

.idt-profile-system-checking__bandwidth-test--loading {
  background-color: var(--grey-light);
  color: var(--grey-darker);
  cursor: not-allowed;
}

.idt-profile-system-checking__bandwidth-test--loading .idt-round-loader {
  height: var(--rem-size-20);
  width: var(--rem-size-20);
  margin: 0 0 0 var(--rem-size-13);
}

.idt-profile-system-checking .browser-support-card {
  flex-wrap: wrap;
  height: auto;
  margin-top: var(--rem-size-16);
}

.idt-profile-system-checking .browser-support-card__browser {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  margin-bottom: var(--rem-size-12);
}

@media only screen and (max-width: 991px) {
  .idt-profile-system-checking .browser-support-card {
    padding: var(--rem-size-16);
  }

  .idt-profile-system-checking .browser-support-card__browser {
    flex-wrap: wrap;
  }

  .idt-profile-system-checking .browser-support-card__icon {
    margin-bottom: var(--rem-size-12);
  }

  .idt-profile-system-checking .browser-support-card__link {
    flex-grow: 1;
    margin-bottom: var(--rem-size-12);
  }
}

.idt-profile-system-checking .browser-support-card--limited {
  padding: 0;
}

