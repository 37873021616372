.talk-status {
  display: flex;
  align-items: center;
  gap: var(--rem-size-16);
  width: fit-content;
  height: var(--rem-size-24);
  padding: 0 var(--rem-size-16);
  border-radius: var(--global-radius);
}

.talk-status__icon {
  width: 20px;
  height: 24px;
  padding: 3px 0 0 var(--rem-size-10);
}

.talk-status__text {
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-14);
}

.talk-status__text.variant {
  font-family: var(--text-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-16);
}

