.category-list {
  display: flex;
  flex-direction: column;
  gap: var(--rem-size-8);
  height: 350px;
  padding: var(--rem-size-8);
  border: solid 1px var(--grey-light);
  overflow-y: auto;
}

.category-list .ghost {
  width: 600px;
  cursor: grab;
  opacity: .5;
  background: var(--grey-lighter);
}

