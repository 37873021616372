















































































.read-more {
  &__content {
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height .2s ease-in-out;
  }
  &__link {
    padding-top: var(--rem-size-4);
    color: var(--main-color);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
  }
}
