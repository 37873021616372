.user-input-container {
  padding: 20px 40px 20px;
}

.time-picker-section {
  display: flex;
  background: var(--grey-lightest);
}

.time-picker-section__container {
  width: 300px;
  padding: 20px;
}

