.bo-lobby-settings__warning {
  position: relative;
  margin-bottom: 24px;
  padding: var(--rem-size-24) var(--rem-size-24) var(--rem-size-24) 60px;
  color: var(--yellow-dark);
  background-color: var(--yellow-dark-alpha10);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
}

.bo-lobby-settings__warning__icon {
  color: var(--yellow-dark);
}

.bo-lobby-settings__warning__icon .ideacon {
  position: absolute;
  top: 50%;
  left: var(--rem-size-24);
  transform: translateY(-50%);
}

