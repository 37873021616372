.idt-file-upload {
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.idt-file-upload__title {
  margin: var(--rem-size-8) 0;
  font-weight: var(--font-weight-bold);
}

.idt-file-upload__area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  border: 2px dashed var(--grey);
  border-radius: var(--global-radius);
  transition: background-color 200ms ease,border 200ms ease;
}

.idt-file-upload__area.idt-file-upload--targeted {
  background-color: var(--grey-light);
  border: 2px dashed var(--grey-dark);
}

.idt-file-upload__area__content {
  text-align: center;
}

.idt-file-upload__area__content p {
  color: var(--grey-dark);
}

.idt-file-upload__info {
  display: flex;
  justify-content: space-between;
  margin: var(--rem-size-8) 0;
  color: var(--grey-dark);
}

.idt-file-upload__info__left,
.idt-file-upload__info__right {
  margin: 0;
}

.idt-file-upload li+li {
  border-top: 1px solid var(--grey-light);
}

