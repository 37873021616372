
































































































































































































































































































































































@import '@/styles/mixins/index';

.bo-section__title + .bo-project-sections-table {
  margin-top: calc(-1 * var(--rem-size-30));
}
.bo-project-sections {
  &__custom-questions {
    margin-top: var(--rem-size-32);
  }
}
.bo-project-sections-table {
  width: 100%;
  overflow: initial !important;

  @include respond-to(medium) {
    &--disable-move .bo-project-sections-table__actions__handle {
      opacity: .2;
      cursor: not-allowed;
      * {
        pointer-events: none;
      }
    }
  }

  &__content {
    box-shadow: 0 2px 3px var(--grey-darker-alpha15);
    border: 1px solid var(--grey-light);
    border-radius: var(--global-radius);
    position: relative;

    &,
    &__lines {
      width: 100%;
    }
    &__lines {
      .bo-project-sections-table__row {
        border-bottom: 1px solid var(--grey-light);
        left: 0;
      }
    }
  }
  /* Table base structure */
  &__table,
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column;
  }
  &__row {
    width: 100%;
    font-size: var(--rem-size-14);

    @include respond-to(max-medium) {
      display: flex;
      flex-direction: column;
    }
    @include respond-to(medium-large) {
      display: grid;
      grid-template-columns: 8% 23% 56% 13%;
    }
    @include respond-to(large-xlarge) {
      display: grid;
      grid-template-columns: 6% 40% 35% 19%;
    }
    @include respond-to(xlarge) {
      display: grid;
      grid-template-columns: 6% 52% 28% 14%;
    }

    &--header {
      @include respond-to(max-medium) {
        display: none;
      }
      @include respond-to(medium) {
        box-shadow: none;
        height: 50px;

        .bo-project-sections-table__cell {
          color: var(--grey-dark);
          font-weight: var(--font-weight-bold);
          font-size: var(--rem-size-12);
        }
      }
    }
    &--ghost {
      opacity: 0;
    }
    &--fixed {
      background: var(--grey-lighter);
    }
  }
  &__cell {
    padding: var(--rem-size-16);
    vertical-align: top;
    overflow: hidden;

    @include respond-to(max-medium) {
      padding: var(--rem-size-24) var(--rem-size-16);

      &:nth-child(2) {
        padding-bottom: 0;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      white-space: normal;
      word-break: break-word;

      &--no-mandatory {
        width: 64%;
      }
      &__title {
        display: flex;
        justify-content: space-between;

        &__icon {
          @include respond-to(max-medium) {
            color: var(--grey-darker);
          }
          @include respond-to(medium) {
            display: none;
          }
        }
      }
    }
    &__settings {
      justify-content: center;
    }
  }
  a {
    color: var(--grey-dark);
  }
  .bo-project-sections-table__actions {
    @include respond-to(max-medium) {
      display: none;
    }
    @include respond-to(medium) {
      display: flex;
      // fix ... on mobile appearing on the actions' icons
      overflow: initial !important;
      max-width: none !important;
      white-space: nowrap;
      align-items: flex-start;
      transition: padding-top .1s ease-in-out;

      &__settings {
        font-size: var(--rem-size-14);
        line-height: var(--rem-size-20);

        &__label {
          @include respond-to(max-large) {
            display: none;
          }
          @include respond-to(large) {
            margin-right: var(--rem-size-12);
            color: var(--grey-darkest);
          }
        }
        &__icon {
          color: var(--grey-darker);
        }
      }
      &__handle {
        transition: opacity .5s ease-in-out;
      }
      a + a {
        margin-left: var(--rem-size-8);
      }
    }
  }
  .idea-menu {
    cursor: move;
  }
  .idt-radio-button-group {
    &.disabled {
      label {
        pointer-events: none;
        opacity: 0.25;
      }
    }
    label {
      padding: var(--rem-size-9) var(--rem-size-14);
      font-size: var(--rem-size-14);
      line-height: var(--rem-size-18);
    }
  }
  .list-transition-items .list-transition-item {
    // opacity, max-height, transform, padding
    transition: all .35s;
    .bo-project-sections-table__cell {
      transition: all .35s;
    }
  }
  .list-transition-leave-active,
  .list-transition-enter-active {
    overflow: hidden !important;

    .bo-project-sections-table__cell {
      overflow: hidden !important;
    }
  }
  .list-transition-enter {
    max-height: 0;
    opacity: 0;

    .bo-project-sections-table__cell {
      padding-top: 0;
      padding-bottom: 0;
      max-height: 0;
    }
  }
  .list-transition-enter-to,
  .list-transition-leave {
    max-height: 125px;
    opacity: 1;

    .bo-project-sections-table__cell {
      padding-top: var(--rem-size-16);
      padding-bottom: var(--rem-size-16);
      max-height: 125px;
    }
  }
  .list-transition-leave-to {
    max-height: 0;
    opacity: 0;
    border-bottom-color: transparent !important;

    .bo-project-sections-table__cell {
      padding-top: 0;
      padding-bottom: 0;
      max-height: 0;
    }
  }
}
