



































































































































































.fo-project-mode-view {
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;

    &__sections {
      max-width: 1160px;
      // avoid overriding top/bottom margin
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 110px;

      &:first-child {
        margin-top: 64px;
      }
    }
  }
}
