










































































































































































































































@import '@/styles/mixins';

.event-standard-section {
  position: relative;

  @include respond-to(max-large) {
    padding: 3rem 1rem;
  }
  @include respond-to(large) {
    padding: 5rem 3rem;
  }

  &:nth-child(odd) {
    background: var(--grey-lightest);
  }
  &:nth-child(even) {
    background: var(--white);
  }
  &__title {
    font-family: var(--title-font-family);
    font-size: 2rem;
    font-weight: var(--font-weight-light);
    text-align: center;
    text-transform: uppercase;

    @include respond-to(medium-large) {
      margin: 0 0 3rem 0;
    }
    @include respond-to(large) {
      margin: 0 0 4rem 0;
    }

    &.has-intro {
      margin-bottom: 1rem;
    }
  }
  &__intro {
    margin-bottom: 3.75rem;
    color: var(--grey-dark);
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
  }
}
