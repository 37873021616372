.gamification-leaderboard {
  color: var(--black);
}

.gamification-leaderboard__your-position {
  color: var(--grey-dark);
  font-weight: var(--font-weight-bold);
  margin-top: 1rem;
}

