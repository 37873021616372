.idt-event-prize {
  --border-style: 3px solid var(--border-color);
  display: flex;
  flex-direction: column;
  width: 375px;
  border: 1px solid var(--grey);
  border-bottom: var(--border-style);
  border-radius: var(--global-radius);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .idt-event-prize {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-event-prize.inline {
    flex-direction: row;
    width: 100%;
    border-bottom: none;
    border-left: var(--border-style);
  }

  .idt-event-prize.inline .idt-event-prize__image {
    flex: 1 1 20%;
    height: 100%;
    max-width: 205px;
    min-width: 50px;
  }

  .idt-event-prize.inline .idt-event-prize__content {
    flex: 1 1 60%;
    min-height: 0;
    padding: 1rem 2rem;
    text-align: left;
  }

  .idt-event-prize.inline .idt-event-prize__content__title {
    font-size: var(--rem-size-18);
  }

  .idt-event-prize.inline .idt-event-prize__content__ref {
    font-size: var(--rem-size-24);
  }

  .idt-event-prize.inline .idt-event-prize__content__description {
    margin-bottom: 0;
    font-size: var(--rem-size-14);
  }

  .idt-event-prize.inline .idt-event-prize__content__sponsor {
    display: none;
  }

  .idt-event-prize.inline .idt-event-prize__sponsor-inline {
    display: flex;
    flex: 1 1 20%;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 100px;
    padding: 1rem;
    background: var(--white);
    border-left: 1px solid var(--grey-light);
    text-align: center;
  }

  .idt-event-prize.inline .idt-event-prize__sponsor-inline__logo {
    width: 100%;
    max-width: 130px;
    height: auto;
  }
}

.idt-event-prize__image {
  height: 195px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 479px) {
  .idt-event-prize__image {
    height: 150px;
  }
}

.idt-event-prize__image--placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey);
}

.idt-event-prize__content {
  flex-grow: 1;
  min-height: 195px;
  padding: 2rem 2.5rem;
  text-align: center;
  background: #fff;
}

@media only screen and (max-width: 479px) {
  .idt-event-prize__content {
    min-height: 0;
  }
}

@media only screen and (max-width: 639px) {
  .idt-event-prize__content {
    padding: 1rem 1.5rem;
  }
}

.idt-event-prize__content__title {
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-event-prize__content__ref {
  font-weight: var(--font-weight-bold);
}

.idt-event-prize__content__description {
  color: var(--black);
  font-size: var(--rem-size-14);
}

@media only screen and (min-width: 1024px) {
  .idt-event-prize__content__description {
    font-size: var(--rem-size-16);
  }
}

@media only screen and (max-width: 479px) {
  .idt-event-prize__content__description {
    font-size: var(--rem-size-12);
  }
}

.idt-event-prize__content__sponsor__hint {
  margin-bottom: .5rem;
  font-size: var(--rem-size-12);
  color: var(--black);
}

.idt-event-prize__content__sponsor__link.no-link {
  cursor: default;
}

.idt-event-prize__content__sponsor__logo {
  max-width: 100px;
  height: auto;
}

.idt-event-prize__sponsor-inline {
  display: none;
}

