













































.submenu-account {
  position: absolute;
  top: calc(var(--menu-height) * 0.75);
  right: var(--rem-size-12);
  min-width: 200px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px 0px var(--black-alpha12);
  text-align: left;
}
