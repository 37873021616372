

























.idt-status-cell {
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  border-radius: var(--global-radius);
  font-size: 0.75rem;
  text-align: center;
  line-height: 1.2;

  &--default {
    color: var(--grey-darkest);
    background-color: var(--grey-lightest);
  }
  &--success {
    color: var(--success-color-darker);
    background-color: var(--success-color-lightest);
  }
  &--danger {
    color: var(--danger-color-dark);
    background-color: var(--danger-color-lightest);
  }
  &--warning {
    color: var(--warning-color-darker);
    background-color: var(--warning-color-lightest);
  }
  &--primary {
    color: var(--main-color-darker);
    background-color: var(--main-color-lightest);
  }
  &--live {
    color: var(--color-live);
    background-color: var(--color-live-lightest);
  }
  &--info {
    color: var(--info-color-darker);
    background-color: var(--info-color-lightest);
  }
}
