.idt-edit-button {
  display: flex;
  align-items: center;
  color: #707070;
  font-size: .875rem;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
}

.idt-edit-button:hover {
  background: #eeeff2;
  color: var(--black);
  border-radius: var(--global-radius);
}

.idt-edit-button__label {
  margin-left: 10px;
  margin-right: 5px;
}

