
























































































































































































































































































































































































































































































































































































































































.agenda {
  min-height: calc(105vh - 210px);
  background-color: var(--grey-lighter);

  .idt-page-top {
    min-height: 100px;
  }

  &__grid-container {
    display: grid;
    grid-template-columns: 0fr 1.6fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "filter-container main-grid";
    min-height: calc(105vh - 210px);
    gap: 1px 1px;

    &__filter-container {
      grid-area: filter-container;
      width: 60px;
      overflow-y: auto;
      overflow-x: hidden;

      @media only screen and (max-width: 1000px) {
        width : 0px;
      }

      &.active {
        width: 300px;
      }
      &__show-filter {
        height: 35px;
        width: 35px;
        margin-left: 15px;
        margin-right: 20px;
        margin-top: 10px;
        background-color: var(--white);
        border: solid 1px var(--grey-dark);
        border-radius: 100%;
        color: var(--black);
        cursor: pointer;
      }
    }

    &__main-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.1fr 1.9fr;
      grid-template-areas:
        "days"
        "tracks";
      grid-area: main-grid;
      gap: 1px 1px;
      @media only screen and (max-width: 1000px) {
        margin-left: -10px;
      }
      &__days {
        display: grid;
        grid-area: days;
        grid-template-columns: 0.1fr 1.9fr;
        grid-template-rows: 1fr;
        grid-template-areas:
          "days-title days";
        align-items: center;
        height: 100%;
        min-height: 60px;
        border-left: 1px solid var(--grey);
        border-bottom: 1px solid var(--grey);
        gap: 1px 1px;

        &__selector {
          height: 100%;
          max-width: none;
          margin: unset;
          overflow-y: auto;
        }
        &__title {
          grid-area: days-title;
          align-items: center;
          height: 100%;
          min-width: 120px;
          width: 100%;
          border-right: 1px solid var(--grey);

          &__media-show-filter {
            display: none;
            @media only screen and (max-width: 1000px) {
              display: block;
              height: 30px;
              width: 30px;
              margin-left: 10px;
              background-color: var(--white);
              border: solid 1px var(--grey-dark);
              border-radius: 100%;
              color: var(--black);
              cursor: pointer;
            }
          }
          &__text {
            margin-right: 15px;
            color: var(--black);
            font-family: var(--first-font-family);
            font-size: var(--rem-size-12);
            font-weight: var(--font-weight-bold);
            letter-spacing: 0px;
            text-align: center;
            text-transform: uppercase;
            @media only screen and (max-width: 1000px) {
              margin-top: 5px;
            }
          }
        }
        &__selector {
          grid-area: days;
        }
      }

      &__tracks {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.1fr 1.8fr;
        grid-template-areas:
          "tracks-bar"
          "talks";
        grid-area: tracks;
        height: calc(105vh - 275px);
        overflow: auto;
        gap: 1px 1px;
        border-left: 1px solid var(--grey);

        &__bar {
          display: grid;
          align-items: center;
          grid-template-columns: 0.1fr 1.9fr;
          grid-template-rows: 1fr;
          grid-template-areas:
            "tracks-title tracks";
          grid-area: tracks-bar;
          position: sticky;
          top: 0;
          height: 100%;
          min-height: 60px;
          background-color: var(--grey-lighter);
          border-bottom: 1px solid var(--grey);
          gap: 1px 1px;
          z-index: 20;

          &__title {
            grid-area: tracks-title;
            left: 0;
            position: sticky;
            height: 100%;
            min-width: 120px;
            width: 100%;
            background-color: var(--grey-lighter);
            border-right: 1px solid var(--grey);
            z-index: 10;

            &__text {
              margin-top: 20px;
              color: var(--black);
              font-family: var(--first-font-family);
              font-size: var(--rem-size-12);
              font-weight: var(--font-weight-bold);
              letter-spacing: 0px;
              text-align: center;
              text-transform: uppercase;
            }
          }

          &__grid { grid-area: tracks; }
        }

        &__talks {
          display: grid;
          grid-template-columns: 0.1fr 1.9fr;
          grid-template-rows: 1fr;
          grid-template-areas:
            "timeline agenda-grid";
          grid-area: talks;
          gap: 1px 1px;
          border-bottom: 1px solid var(--grey);

          &__timeline {
            grid-area: timeline;
            position: sticky;
            left: 0;
            min-width: 120px;
            width: 100%;
            height: 100%;
            background-color: var(--grey-lighter);
            border-right: 1px solid var(--grey);
            z-index: 10;

            &__timezone {
              position: absolute;
              top: var(--rem-size-8);
              width: 100%;
              background-color: var(--grey-lighter);
              color: var(--grey-darker);
              font-family: var(--first-font-family);
              font-size: var(--rem-size-14);
              font-weight: var(--font-weight-medium);
              text-align: center;
            }
          }

          &__grid {
            grid-area: agenda-grid;
            height: 100%;
          }
        }
      }
    }
  }
}
