.idt-conference-surveys {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  background: var(--white);
}

.idt-conference-surveys__add {
  display: block;
  margin: 1rem auto !important;
}

