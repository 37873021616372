





































































































































.user-input-container {
  padding: 20px 40px 20px;
}

.time-picker-section {
  display: flex;
  background: var(--grey-lightest);
  &__container {
    width: 300px;
    padding: 20px;
  }
}

