



























.menu-mobile-list-item {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  max-height: 64px;
  color: var(--grey-darkest);
  font-size: 0.95rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:not(.state):hover {
    color: var(--white);
    background-color: var(--main-color);
  }

  &.state {
    font-size: 0.75rem;
    border-bottom: 1px solid var(--main-color);
  }
}
