::v-deep .ideacon {
  vertical-align: sub;
}

.track-name {
  display: flex;
  height: 26px;
  width: 290px;
  align-items: center;
  background-color: var(--white);
  border-radius: 50px;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
  letter-spacing: 0px;
  line-height: .8rem;
  padding-left: 15px;
}

.track-name__remove-icon {
  color: var(--red);
  padding-right: 5px;
  padding-left: 10px;
  cursor: pointer;
}

