













































































































  @import '@/styles/mixins/index';

  .meeting-video {
    height: 100%;
    background: var(--grey-darkest);

    @include respond-to(max-slarge) {
      min-height: 500px;
    }

    @include respond-to(max-medium) {
      min-height: 400px;
    }

    &__alert {
      position: absolute;
      width: 100%;

      &__content {
        padding: 1rem;
        margin: 10px;
        top: 0;
        left: 0;
        color: var(--white);
        background-color: var(--red);
        border-radius: var(--global-radius);
        font-weight: var(--font-weight-bold);
      }

      &__close {
        position: absolute;
        top: 10px;
        right: 0;
        padding: 1.2rem;
        border: none;
        color: var(--white);
        cursor: pointer;
      }
    }

    &--loading {
      position: relative;

      .idt-round-loader {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
}
