.bo-section__title {
  margin: 0 0 30px 0;
  color: var(--grey-darker);
  font-size: 1.7rem;
  font-weight: var(--font-weight-light);
}

.bo-section__title__number {
  display: inline-block;
  width: 27px;
  height: 27px;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 100%;
  font-size: 1.1rem;
  text-align: center;
  line-height: 28px;
  vertical-align: text-top;
}

.bo-section__separator {
  max-width: 100%;
  color: var(--grey);
}

@media only screen and (max-width: 639px) {
  .bo-section__separator {
    margin: 1rem 0 1.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .bo-section__separator {
    margin: 1.75rem 0;
  }
}

