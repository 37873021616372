.meeting-cancelled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--grey-darkest);
}

.meeting-cancelled__title {
  margin-top: var(--rem-size-24);
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-30);
}

.meeting-cancelled__subtitle {
  margin-top: var(--rem-size-16);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);
}

