.additional-checkbox {
  margin: 1rem 0 var(--rem-size-10) 0;
  text-align: left;
}

.additional-checkbox__container {
  margin-bottom: 6px;
}

.additional-checkbox__container input {
  display: none;
}

.additional-checkbox__container input.error+label .box {
  border: 1px solid var(--red);
}

.additional-checkbox__container input+label {
  display: block;
  position: relative;
  min-height: 22px;
  min-width: 100px;
  margin: 0;
  color: var(--grey-darker);
  font-weight: normal;
  font-size: .875rem;
  font-weight: 500;
  word-break: break-word;
}

.additional-checkbox__container input+label .box {
  content: "";
  display: inline-block;
  vertical-align: top;
  margin-right: 7px;
  margin-top: 2px;
  height: 20px;
  width: 20px;
  border: 1px solid var(--grey);
  border-radius: 3px;
}

.additional-checkbox__container input+label .content {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 35px);
}

.additional-checkbox__container input+label .content ::v-deep p:last-child {
  margin-bottom: 0;
}

.additional-checkbox__container input+label .idt-icon {
  content: "";
  position: absolute;
  display: block;
  left: 4px;
  top: 5px;
  color: rgba(0,0,0,0);
}

.additional-checkbox__container input:checked+label .box {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}

.additional-checkbox__container input:checked+label .idt-icon {
  color: #fff;
}

