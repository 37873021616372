




























































.bo-form-section-separator {
  display: flex;
  align-items: center;
  width: 100%;

  &__left-side,
  &__right-side {
    flex: 1 1 auto;
  }

  &__section-title {
    padding: 0 30px;
    font-weight: var(--font-weight-medium);
  }
}
