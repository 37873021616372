
























@import '@/styles/mixins/index';

.no-more-timeslot-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 382px;
  height: 56px;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--rem-size-36);
    height: var(--rem-size-36);
    border-radius: 100%;
    color: var(--white);
    background-color: var(--grey-dark);
  }
  &__text {
    color: var(--black);
    font-size: var(--rem-size-14);
    line-height: var(--rem-size-20);
  }
}
