












































































































































































































































































































































































.idt-survey-card {
  position: relative;
  width: 100%;
  max-width: 420px;
  padding: 1rem;
  border: 1px solid #cacaca;
  box-shadow: 0px 3px 6px #00000029;
  background: var(--white);
  border-radius: var(--global-radius);

  &.ended {
    box-shadow: none;
  }

  &.highlighted {
    border-left: 3px solid var(--main-color);
  }

  &.collapsed {
    .idt-survey-card__question {
      height: 0;
    }

    .idt-survey-card__buttons__collapse {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }

  &__duration {
    .progress {
      --progress-color: var(--main-color);

      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      height: 7px;
      margin: 0;
      border-radius: var(--global-radius) var(--global-radius) 0 0;
      overflow: hidden;

      &.danger {
        --progress-color: var(--danger-color);
      }
    }
  }

  &__header {
    position: relative;
    padding-left: 33px;

    &__duration {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      font-size: var(--rem-size-12);
      color: var(--black);
    }

    &__icon ::v-deep .ideacon {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -10px;
      color: var(--main-color);
    }

    &__status {
      font-size: var(--rem-size-12);
    }

    &__question {
      line-height: 1rem;
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }
  }

  &__question {
    height: auto;
    padding-top: .5rem;
    overflow: hidden;
    transition: height .3s ease;

    &__footer {
      margin-top: var(--rem-size-20);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row-reverse;

      &__button {
        flex: 0 0 auto;
        min-width: 120px;
        height: 35px;
        margin-left: auto;
        line-height: 0;
      }
    }
  }

  &__results {
    padding-top: .5rem;
    border-bottom: 1px solid var(--grey-light);

    &:not(.results-mode) {
      pointer-events: none;
    }

    &__title {
      margin-top: var(--rem-size-28);
      margin-bottom: var(--rem-size-18);
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }
  }

  &__thanks,
  &__ended {
    margin-top: 1rem;
    color: var(--black);
  }

  &__footer {
    &__hint {
      font-size: var(--rem-size-12);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    height: 21px;
    right: 1rem;
    bottom: 1rem;

    &__collapse,
    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 21px;
      width: 21px;
      line-height: 0; // To keep the icon aligned in center on Safari
      background: var(--grey-lighter);
      cursor: pointer;
    }

    &__close {
      margin-left: 4px;
    }
  }

}
