.modal-large__close {
  position: absolute;
  top: var(--rem-size-15);
  right: var(--rem-size-15);
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .modal-large__content {
    padding: 45px var(--rem-size-20) var(--rem-size-25);
  }
}

