.idt-survey-result-like__table {
  margin-top: 1rem;
}

.idt-survey-result-like__table__row {
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;
  color: var(--black);
}

.idt-survey-result-like__table__row__column {
  display: flex;
  flex-direction: column;
}

.idt-survey-result-like__table__row__column.progress-column {
  flex-grow: 1;
  justify-content: space-around;
}

.idt-survey-result-like__table__row__column.count-column {
  align-items: flex-end;
}

.idt-survey-result-like__table__row__column__label {
  cursor: pointer;
}

.idt-survey-result-like__table__row .progress {
  height: 7px;
  margin: 0 var(--rem-size-10);
  border-radius: var(--global-radius);
}

.idt-survey-result-like__table__row .progress.like {
  --progress-color: var(--main-color);
}

.idt-survey-result-like__table__row .progress.dislike {
  --progress-color: var(--secondary-color);
}

.idt-survey-result-like__table__row .progress ::v-deep .progress-meter {
  border-radius: var(--global-radius);
}

