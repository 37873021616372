














































































.fo-project-section-media-edit {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);

  &__cards {
    display: flex;
    flex-wrap: wrap;

    &--required {
      flex-direction: column;
    }
    &__upload-file {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15rem;
      height: 10rem;
      margin: var(--rem-size-8);
      padding: var(--rem-size-32);
      border: 2px dashed var(--grey);
      border-radius: var(--global-radius);

      &--required {
        border-color: var(--red);
      }
    }
    &__preview-file {
      width: 15rem;
      height: 10rem;
      margin: var(--rem-size-8);
    }
    .idt-required-field {
      margin-left: var(--rem-size-8);
    }
  }
  ::v-deep .file-preview__figure {
    width: 15rem;
    height: 10rem;
  }
}
