.idt-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
}

.idt-switch-container__label {
  padding: 0;
}

