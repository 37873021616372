


































































































































.idt-card-file-upload-header {

  &__preview {
    position: relative;
    width: 100%;
    padding-top: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-right-radius: var(--global-radius);
    border-top-left-radius: var(--global-radius);
  }

  &__preview:hover &__file.has-file {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    span.white-hovered {
      color: var(--white);
    }
  }

  &__file {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: var(--global-radius) var(--global-radius) 0 0;
    border: solid 2px var(--main-color);
    font-weight: 400;
    cursor: pointer;

    &--error {
      border: solid 2px var(--secondary-color);
    }

    &__button {
      color: var(--main-color);
      background-color: var(--white);
      font-size: 16.8px;
      text-align: center;
    }

    &__label {
      color: var(--main-color);
    }

    &__circle {
      color: var(--main-color);
      font-size: 36px;
      cursor: pointer;
    }

    &__info {
      margin-top: 11px;
      color: #7f7f7f;
      font-size: 12.8px;
      text-align: center;
    }

    &__delete {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 30px;
      height: 30px;
      padding: 0.5rem;
      color: #FFFFFF;
      background-color: #eb4a80;
      border-radius: 0 0 0 var(--global-radius);
      font-size: 1.2rem;
      text-align: center;
      line-height: 14px;
      cursor: pointer;
    }
  }
}
