




























































.fo-project-mode-view-skeleton {
  width: 100%;
  margin-bottom: 3.3125rem;
  margin-top: 64px;
  padding-left: var(--rem-size-32);
  padding-right: var(--rem-size-32);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  &__image {
    height: 372px;
    border: var(--rem-size-6) solid var(--grey-light);
    border-radius: var(--rem-size-12);
  }

  &__content,
  &__details,
  &__props {
    display: flex;
  }

  &__content {
    flex-flow: nowrap;
    margin-top: var(--rem-size-32);

    @media (max-width: 768px) {
      flex-flow: column;
    }
  }

  &__project-info {
    flex: auto;
    overflow: hidden;
  }

  &__props {
    flex: auto;
    > div {
      width: 50%;
      max-width: 300px;
      flex-shrink: 1;

      @media (max-width: 400px) {
        display: block;
        width: 100%;
        max-width: none;
        flex-shrink: 0;
        > div {
          width: 100%;
        }
      }
    }
  }

  &__right-props {
    width: 235px;
    flex-shrink: 0;
    margin-left: auto;

    > div {
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 768px) {
      margin-top: var(--rem-size-24);
      margin-left: 0;

      > div {
        justify-content: flex-start;
      }
    }
  }

  @media (max-width: 768px) {
    &__details {
      flex-flow: column;
    }
  }

  // generic skeleton elements that could be re-used
  // if uniformized
  &__circle-s,
  &__circle-m {
    width: 125px;
    height: 125px;
    flex-shrink: 0;
    border-radius: 100%;
    background-color: var(--grey-light);
    margin-right: var(--rem-size-4);
    margin-bottom: var(--rem-size-4);
  }
  &__circle-s {
    width: 45px;
    height: 45px;
  }
  &__button,
  &__line-xs,
  &__line-s,
  &__line-m,
  &__line-l {
    width: 100%;
    max-width: 244px;
    height: 24px;
    border-radius: var(--global-radius);
    background-color: var(--grey-light);
    margin-right: var(--rem-size-4);
    margin-bottom: var(--rem-size-4);
  }
  &__button {
    width: 185px;
    height: 45px;
    border-radius: 30px;
  }
  &__line-xs {
    max-width: 63px;
  }
  &__line-s {
    max-width: 135px;
  }
  &__line-l {
    max-width: 634px;
  }

  // tailored parameters for this skeleton
  .fo-project-mode-view-skeleton__line-l,
  .fo-project-mode-view-skeleton__button {
    margin-bottom: var(--rem-size-24);
  }
  .fo-project-mode-view-skeleton__line-s {
    margin-bottom: var(--rem-size-16);
  }
  .fo-project-mode-view-skeleton__circle-m {
    margin-right: var(--rem-size-24);
    margin-bottom: var(--rem-size-24);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}
