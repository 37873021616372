.custom-field-form-choice__choices__item {
  margin-bottom: 10px;
}

.custom-field-form-choice__choices__item:last-child {
  margin-bottom: 0;
}

.custom-field-form-choice .btn-add-choice {
  display: flex;
  align-items: center;
  width: 150px;
  margin-top: 10px;
  color: var(--main-color);
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
}

.custom-field-form-choice .btn-add-choice__text {
  margin-left: 5px;
}

.custom-field-form-choice .btn-add-choice.required-field {
  color: var(--secondary-color);
}

