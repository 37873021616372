.activity {
  display: flex;
  align-items: center;
  height: 45px;
  background-color: var(--grey-lighter);
  border-radius: 3px;
  color: var(--black);
  cursor: grab;
}

.activity.selected {
  background-color: var(--white);
  box-shadow: 0 0 10px 0 var(--black-alpha15);
}

.activity__activity-description {
  padding: unset;
}

.activity__activity-description__title {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.activity__activity-description__title__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity__activity-description__title__date {
  min-width: 30%;
  font-style: italic;
}

.activity__edit {
  margin: 5px 5px 0px 0px;
}

.activity__cancel {
  margin-top: 8px;
  color: var(--grey-dark);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.activity button {
  cursor: pointer;
}

