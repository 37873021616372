.idt-expired-token {
  padding-top: 25px;
  padding-bottom: 50px;
  text-align: center;
}

.idt-expired-token__icon {
  color: var(--grey);
  font-size: 80px;
}

.idt-expired-token__text {
  max-width: 450px;
  margin: auto;
}

.idt-expired-token h1 {
  margin-bottom: 25px;
}

