.ticketing-blocking-modal-header {
  flex: 0 0 auto;
  position: relative;
  height: var(--tk-modal-header-height);
  color: var(--white);
  background-color: var(--main-color-darkest);
  overflow: hidden;
}

.ticketing-blocking-modal-header__infos {
  display: flex;
  justify-content: space-between;
  height: var(--tk-modal-header-height);
}

@media only screen and (max-width: 639px) {
  .ticketing-blocking-modal-header__infos {
    padding: 10px 10px 5px;
  }
}

@media only screen and (min-width: 640px) {
  .ticketing-blocking-modal-header__infos {
    padding: 1.25rem 1.5rem;
  }
}

@media only screen and (max-width: 1199px) {
  .ticketing-blocking-modal-header__infos {
    flex-direction: column;
  }
}

.ticketing-blocking-modal-header__infos__user {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 639px) {
  .ticketing-blocking-modal-header__infos__user__avatar {
    width: 35px !important;
    height: 35px !important;
  }
}

@media only screen and (max-width: 639px) {
  .ticketing-blocking-modal-header__infos__user__avatar ::v-deep .idt-user-avatar__initial {
    font-size: 30px !important;
    line-height: 35px !important;
  }
}

.ticketing-blocking-modal-header__infos__user__text {
  max-width: 50vw;
  padding: 0 5px;
}

.ticketing-blocking-modal-header__infos__user__text__name {
  width: 100%;
  font-family: var(--title-font-family);
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .ticketing-blocking-modal-header__infos__user__text__name {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .ticketing-blocking-modal-header__infos__user__text__name {
    font-size: 1.75rem;
  }
}

.ticketing-blocking-modal-header__infos__user__text__role {
  width: 100%;
  font-weight: var(--font-weight-light);
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 639px) {
  .ticketing-blocking-modal-header__infos__user__text__role {
    font-size: .75rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .ticketing-blocking-modal-header__infos__user__text__role {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .ticketing-blocking-modal-header__infos__user__text__role {
    font-size: 1.75rem;
  }
}

.ticketing-blocking-modal-header__infos__availability__title {
  line-height: 30px;
}

.ticketing-blocking-modal-header__infos__availability__number {
  font-size: 2.375rem;
}

.ticketing-blocking-modal-header__infos__availability__number strong {
  font-weight: var(--font-weight-medium);
}

.ticketing-blocking-modal-header__infos__event {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .ticketing-blocking-modal-header__infos__event {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 145px;
    padding-right: 1.875rem;
    border-right: 1px solid var(--white);
  }
}

.ticketing-blocking-modal-header__infos__event__title {
  flex: 1 1 auto;
  padding-right: .5rem;
  font-weight: var(--font-weight-light);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (min-width: 640px) {
  .ticketing-blocking-modal-header__infos__event__title {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 1199px) {
  .ticketing-blocking-modal-header__infos__event__title {
    max-width: 75vw;
  }
}

@media only screen and (min-width: 1200px) {
  .ticketing-blocking-modal-header__infos__event__title {
    max-width: 35vw;
  }
}

.ticketing-blocking-modal-header__infos__event__timer {
  flex: 0 0 auto;
  font-family: var(--title-font-family);
}

