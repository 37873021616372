.idt-span-editable__editable {
  display: block;
  min-height: 2rem;
  padding: .4rem;
  resize: none;
  overflow: hidden;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid var(--grey-light);
  border-radius: 5px;
  background-color: var(--white);
}

.idt-span-editable__editable:focus {
  outline-color: rgba(0,0,0,0);
}

.idt-span-editable__editable.has-margin {
  margin-bottom: .5rem;
}

.idt-span-editable__editable[placeholder]:empty::before {
  content: attr(placeholder);
  color: var(--grey);
  font-size: 14px;
}

.idt-span-editable__editable[placeholder]:empty:focus::before {
  content: "";
}

.idt-span-editable__textarea {
  line-height: 1.2;
  white-space: pre-line;
  word-break: break-word;
}

.idt-span-editable__textarea[contenteditable~=true].overflow {
  overflow-y: auto;
}

.idt-span-editable__show-more {
  color: var(--main-color-dark);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
  cursor: pointer;
}

