




































































































































.btn-add-field {
  display: flex;
  align-items: center;
  width: fit-content;

  &__text {
    margin-left: 12px;
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }
}
