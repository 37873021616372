
















































































































@import '@/styles/mixins/index';

.menu-conference {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--grey-lighter);
  text-align: left;
  overflow-y: auto;

  @include respond-to(max-slarge) {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc((var(--vh, 1vh) * 100) - 70px);
  }

  &__content {
    overflow-y: auto;

    @include respond-to(max-slarge) {
      height: calc(100% - 40px);
    }
    @include respond-to(slarge) {
      max-height: calc(80vh - 40px);
      padding-bottom: 40px;
    }
  }
  &__category {
    background-color: var(--white);

    &__title {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      width: 100%;
      padding: 0.2rem 1.25rem;
      color: var(--black);
      background-color: var(--grey-light);
      border-bottom: 1px solid var(--grey);
      font-size: 0.8rem;
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
      z-index: 1;

      &__label {
        margin-left: var(--rem-size-8);
      }
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: var(--white);
      font-size: 0.75rem;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
    }
  }
}
