.live-chat-message-content {
  display: flex;
  padding: var(--rem-size-10) 0;
  gap: var(--rem-size-12);
}

.live-chat-message-content__user {
  align-self: flex-start;
  cursor: pointer;
}

.live-chat-message-content__wrapper {
  align-self: center;
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
  z-index: 0;
}

.live-chat-message-content__info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--rem-size-8);
}

.live-chat-message-content__text {
  line-height: var(--rem-size-22);
}

.live-chat-message-content__text__message {
  white-space: pre-line;
  word-break: break-word;
}

.live-chat-message-content__text__show-more {
  color: var(--main-color);
  cursor: pointer;
}

.live-chat-message-content__text__reactions--disabled {
  cursor: auto;
  pointer-events: none;
}

.live-chat-message-content__reactions {
  display: flex;
  align-items: center;
  gap: var(--rem-size-4);
  min-height: var(--rem-size-24);
}

@media only screen and (max-width: 479px) {
  .live-chat-message-content__reactions {
    flex-wrap: wrap;
  }
}

.live-chat-message-content__reactions__button {
  flex: 0 0 auto;
  height: 20px;
  padding: 0 6px;
  background: var(--grey-lighter);
  border-radius: 10px;
  cursor: pointer;
}

.live-chat-message-content__reactions__button:hover {
  background: var(--grey-light);
}

.live-chat-message-content__reactions__button.active {
  background: var(--main-color);
}

.live-chat-message-content__reactions__button.active .live-chat-message-content__reactions__button__count {
  color: var(--white);
}

.live-chat-message-content__reactions__button__count {
  color: var(--grey-darker);
  font-size: var(--rem-size-14);
  font-weight: bold;
  vertical-align: top;
}

.live-chat-message-content__reactions__button .emoji-mart-emoji {
  padding: 0;
}

.live-chat-message-content:not(.only-emoji) .emoji {
  font-size: var(--rem-size-16);
}

.live-chat-message-content__add-extra-reaction ::v-deep .idea-ht-emoji {
  --color2: var(--grey-darker);
  --color3: var(--white);
}

.live-chat-message-content__blocked-by,
.live-chat-message-content__hour,
.live-chat-message-content__modified {
  display: inline-flex;
  align-items: center;
  gap: var(--rem-size-8);
  color: var(--grey-darker);
}

.live-chat-message-content.only-emoji .live-chat-message-content__text__message,
.live-chat-message-content.only-emoji .live-chat-message__container__user,
.live-chat-message-content.only-emoji .live-chat-message-username__text,
.live-chat-message-content.only-emoji .live-chat-message-username__container__role-icon {
  line-height: var(--rem-size-32);
}

.live-chat-message-content.only-emoji .live-chat-message-username__container {
  line-height: var(--rem-size-24);
}

.live-chat-message-content.only-emoji .live-chat-message-content__text__message {
  font-size: var(--rem-size-30);
}

.live-chat-message-content.only-emoji .live-chat-message-content__user {
  margin-top: .25rem;
}

.live-chat-message-content.response {
  margin-top: var(--rem-size-10);
  margin-left: var(--rem-size-24);
  padding: var(--rem-size-4) var(--rem-size-12);
  background: var(--grey-lighter);
  border-radius: var(--global-radius);
}

.live-chat-message-content.response .live-chat-message-content__wrapper {
  margin-left: 0;
}

.live-chat-message-content.response.live-chat-message-content--in-lobby .live-chat-message-content__reactions {
  display: none !important;
}

.live-chat-message-content__editor {
  width: 100%;
}

.live-chat-message-content__gif-preview {
  max-width: 200px;
  padding: var(--rem-size-10) 0;
}

.live-chat-message-content__gif-preview img {
  border-radius: var(--global-radius);
}

.live-chat-message-content__image-preview {
  padding-top: var(--rem-size-10);
}

.live-chat-message-content__blocked-line {
  margin-top: var(--rem-size-5);
  padding: var(--rem-size-4) var(--rem-size-12);
  border-radius: var(--global-radius);
  background: var(--grey-lighter);
  color: var(--grey-darkest);
}

.live-chat-message-content--blocked {
  opacity: .5;
}

.live-chat-message-content--in-lobby .live-chat-message-content {
  padding-top: var(--rem-size-4);
  padding-bottom: var(--rem-size-4);
}

.live-chat-message-content--in-lobby .live-chat-message-content__text__message {
  display: block;
  margin-top: var(--rem-size-5);
  margin-left: 0;
  line-height: var(--rem-size-22);
}

.live-chat-message-content--in-lobby .live-chat-message-content__reactions {
  margin-top: var(--rem-size-8);
}

.live-chat-message-content--in-lobby .live-chat-message-content__reactions__button {
  padding: var(--rem-size-4) var(--rem-size-10);
  border: 1px solid var(--grey);
  height: auto;
  transition: background-color .2s ease-in-out,color .2s ease-in-out;
}

.live-chat-message-content--in-lobby .live-chat-message-content__reactions__button.active {
  border-color: var(--main-color-dark);
  background-color: var(--main-color-dark-alpha20);
}

.live-chat-message-content--in-lobby .live-chat-message-content__reactions__button.active .live-chat-message-content__reactions__button__count {
  color: var(--main-color-dark);
}

