



































































































































.base-field {
  width: 100%;
  padding: var(--rem-size-20);

  &__label {
    ::v-deep & > p {
      margin-bottom: var(--rem-size-9);
      font-size: 1rem;
      font-weight: bold;
    }
    ::v-deep &.required {
      &::after {
        display: none;
      }
      & > p:first-child {
        &::after{
          content: ' *';
        }
      }
    }
  }

  .idt-profile-upload-file {
    position: relative;
    max-width: 500px;

    &__hint {
      margin-top: 0.5rem;
      font-size: 0.75rem;
      font-style: italic;
    }

    &__remove {
      position: absolute;
      top: 1px;
      right: 1px;
      height: 38px;
    }

    ::v-deep .idt-fs-upload-file__right-content {
      padding-right: var(--rem-size-48);
    }
  }
}
