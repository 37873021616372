



























@import '@/styles/mixins/index';

.remaining-credit-box {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 2px;
  color: var(--secondary-color);
  background-color: var(--secondary-color-alpha20);
  font-size: 1rem;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
  }

  &__text {
    width: 100%;
    background-color: var(--white);
    font-weight: var(--font-weight-normal);
    text-align: center;
    line-height: 46px;

    @include respond-to(medium-large) {
      padding: 0 10px;
    }

    @include respond-to(large) {
      padding: 0 20px;
    }
  }
}
