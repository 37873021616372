.vue-slider {
  margin: 25px 0;

  &-rail {
    background-color: var(--grey-light);
    border-radius: 4px;
  }

  &-dot {
    top: 50%;
    width: 19px;
    height: 19px;
    border: 1px solid var(--main-color);
    border-radius: 50%;
    background-color: white;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &-process {
    background-color: var(--main-color);
  }
}
