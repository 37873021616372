.meetings-page {
  height: calc(100vh - 70px);
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
  overflow: auto;
}

.meetings-page__sidebar {
  position: sticky;
  top: 0;
  max-width: 280px;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.meetings-page__list {
  display: flex;
  flex-direction: column;
}

.meetings-page__list__header {
  display: flex;
  align-items: center;
  position: sticky;
  padding: 1rem 0 2rem 0;
  top: 0;
  z-index: 2;
  background-color: var(--grey-lighter);
  box-shadow: 0px 0px 2px var(--rem-size-2) var(--grey-lighter);
}

.meetings-page__list__header__titles {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.meetings-page__list__header__titles__title {
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.meetings-page__list__header__titles__subtitle {
  font-size: var(--rem-size-16);
  color: var(--grey-dark);
}

.meetings-page__list__header button {
  margin: 0 0 0 var(--rem-size-8);
}

.meetings-page__list__day-container {
  margin-bottom: var(--rem-size-8);
}

.meetings-page__list__day {
  margin-bottom: 1.2rem;
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-medium);
  color: var(--black);
}

.meetings-page__list ::v-deep .meeting-card {
  margin-bottom: var(--rem-size-10);
}

.meetings-page__no-results {
  display: flex;
  margin-top: 1rem;
  padding: 1.6rem 2rem;
  align-items: center;
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
}

.meetings-page__no-results__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4rem;
  height: 3.4rem;
  margin-right: 1.5rem;
  background-color: var(--main-color-lighter);
  border-radius: 50%;
}

.meetings-page__no-results__labels__title {
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.meetings-page__no-results__labels__description {
  font-size: var(--rem-size-14);
  color: var(--grey-dark);
}

.meetings-page__no-results__labels>* {
  text-align: left;
}

