.sso-provider {
  margin-bottom: .75rem;
  padding-bottom: .75rem;
}

.sso-provider:not(:last-child) {
  border-bottom: 1px solid var(--grey-lighter);
}

.input-custom {
  margin-top: 1.25rem;
}

.sso-callback-url {
  font-size: .75rem;
}

.preview-block {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: var(--grey-lightest);
}

.preview-block__title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.preview-block__container {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

@media only screen and (min-width: 992px) {
  .preview-block__container {
    position: sticky;
    top: calc(var(--menu-height)*2 + 2rem);
  }
}

