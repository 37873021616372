



























































































































































































.idt-survey-modal-results {
  margin-top: 70px;
  padding: 1rem 0;
  background: var(--grey-lighter);

  &__select-talk {
    padding: 8px;

    .idt-select-container ::v-deep {
      label {
        font-weight: var(--font-weight-bold);
        color: var(--black);
      }

      .v-select {
        background-color: var(--white);

        .vs__dropdown-option--highlight {
          background-color: var(--grey-light) !important;
        }

        &:not(.vs--open) {
          .vs__search::placeholder {
            color: var(--black) !important;
          }
        }
      }

      .vs__dropdown-option {
        border-bottom: 1px solid var(--grey-light);
      }
    }
  }
}
