











































































































































































































































@import '@/styles/mixins/index';

.fo-project-section-information-view {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);
  margin-bottom: 3.3125rem;

  &__image {
    height: 372px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--main-color-lighter);
    border-radius: var(--rem-size-12);

    div {
      display: none;
    }

    @include respond-to(max-xlarge) {
      height: 200px;
    }
  }

  &__video {
    height: 372px !important;
    border-radius: var(--rem-size-12);
    overflow: hidden;

    iframe {
      height: 100% !important;
    }
  }

  &__content {
    display: flex;
    margin-top: var(--rem-size-32);
    color: var(--grey-darker);
    font-size: var(--rem-size-16);
    line-height: var(--rem-size-24);

    @include respond-to(max-xlarge) {
      display: block;
      margin-top: var(--rem-size-24);
    }

    strong {
      display: block;
      color: var(--grey-darkest);
      font-weight: var(--font-weight-medium);
    }

    &__avatar {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 125px;
      height: 125px;
      margin-right: var(--rem-size-24);
      color: var(--grey-darkest);
      font-size: var(--rem-size-24);
      font-weight: var(--font-weight-medium);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: var(--grey-lightest);
      border-radius: 999px;

      div {
        display: none;
      }

      @include respond-to(max-xlarge) {
        margin-bottom: var(--rem-size-24);
      }
    }

    &__texts {
      flex: auto;
      word-break: break-word;

      &__blocks {
        display: flex;
        flex: auto;
        margin-top: var(--rem-size-25);

        @include respond-to(max-xlarge) {
          display: block;
        }

        &__categories {
          display: flex;
          flex: auto;
          flex-wrap: wrap;
          align-items: flex-start;
          margin: calc(var(--rem-size-8) * -1);

          > div {
            width: calc(50% - var(--rem-size-16));
            margin: var(--rem-size-8);
            padding-left: var(--rem-size-10);
            border-left: var(--rem-size-1) solid var(--grey-lighter);
            line-height: var(--rem-size-24);
          }

          @include respond-to(max-xlarge) {
            align-items: stretch;
            margin-left: calc(var(--rem-size-18) * -1);

            > div:nth-child(odd) {
              border-left: none;
            }
          }
        }
      }
    }
  }
  &__misc {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 0;
    min-width: 300px;

    @include respond-to(max-xlarge) {
      margin-top: var(--rem-size-24);
    }

    // Position of dropdown on desktop
    @include respond-to(xlarge) {
      .idt-dropdown--pane {
        transform: translateX(calc(45px - 100%));
      }
    }

    &__votes {
      display: inline-flex;
      align-items: center;
      margin-bottom: var(--rem-size-24);
      margin-left: var(--rem-size-4);;
      padding: 0 var(--rem-size-24);
      height: 45px;
      color: var(--white);
      background: var(--main-color);
      border-radius: 999px;
      font-weight: var(--font-weight-medium);
      cursor: pointer;

      .idt-icon {
        display: block;
        margin-right: var(--rem-size-18);
      }
      &:hover {
        background: var(--main-color-dark);
      }
    }
    &__links,
    &__social-links {
      display: flex;

      @include respond-to(max-xlarge) {
        justify-content: flex-start;
      }
      @include respond-to(xlarge) {
        justify-content: flex-end;
      }
    }
    &__links {
      flex-direction: column;
      align-items: flex-end;
      margin-top: var(--rem-size-16);

      a {
        max-width: 250px;
        padding: var(--rem-size-8) var(--rem-size-12);
        color: var(--grey-darkest);
        border-radius: var(--global-radius);
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
          color: var(--grey-darkest);
          background: var(--grey-lighter);
        }
      }
    }
    &__social-links {
      flex-wrap: wrap;

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        color: var(--grey-darkest);
        background-color: var(--grey-light);
        border-radius: 100%;
        transition: color background-color 0.3s ease-in-out;

        @include respond-to(max-xlarge) {
          margin-right: var(--rem-size-8);
        }
        @include respond-to(xlarge) {
          margin-left: var(--rem-size-8);
        }

        &:hover {
          color: var(--grey-light);
          background-color: var(--grey-darkest);
        }
      }
    }
  }
  &__image--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    color: var(--grey-dark);
    font-weight: var(--font-weight-medium);
    border: var(--rem-size-1) var(--grey-dark) dashed;

    .idt-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      margin-bottom: var(--rem-size-16);
      background: var(--grey);
      color: var(--grey-darkest);
      border-radius: 100%;
    }
    div {
      display: block;
    }
  }

  &__content__avatar--empty {
    background: var(--grey-lightest);

    span {
      display: none;
    }
    div {
      display: block;
    }
  }
}
