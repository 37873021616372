

















































































































.custom-form-field-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 600px;
    height: 50px;
    margin-right: var(--rem-size-12);
    padding: 0 12px 0 20px;
    border: 1px solid var(--grey-light);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: grab;

    &__text {
      max-width: 500px;
      height: 22px;
      color: var(--grey-darkest);
      font-weight: var(--font-weight-medium);
      font-size: 0.875rem;
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      ::v-deep p {
        margin-bottom: 0;
        margin-top: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__badge {
      position: absolute;
      left: -30px;
    }

    &__actions {
      display: none;
      cursor: pointer;

      &__update {
        color: var(--main-color);
      }

      &__delete {
        margin-left: 5px;
        color: var(--secondary-color);
      }
    }

    &:hover {
      border-width: 0 1px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

      .custom-form-field-list-item__content__actions {
        display: inherit;
      }
    }
  }
}
