.fo-comment {
  --gap-size: var(--rem-size-16);
  display: flex;
  gap: var(--gap-size);
  margin: var(--rem-size-24) 0;
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
}

.fo-comment__avatar {
  flex: 0 0 auto;
}

.fo-comment__side {
  flex: 1;
}

.fo-comment__content {
  position: relative;
  width: fit-content;
  padding: var(--rem-size-8) var(--rem-size-16);
  color: var(--grey-darkest);
  background: var(--grey-lightest);
  border-radius: var(--global-radius);
}

.fo-comment__content__vote {
  margin-left: calc(var(--rem-size-8)*-1);
  margin-right: var(--rem-size-8);
  padding: var(--rem-size-3) var(--rem-size-8);
  color: var(--white);
  border-radius: var(--global-radius);
  font-weight: var(--font-weight-medium);
}

.fo-comment__content__vote--color0 {
  background: var(--project-vote-0);
}

.fo-comment__content__vote--color1 {
  background: var(--project-vote-1);
}

.fo-comment__content__vote--color2 {
  background: var(--project-vote-2);
}

.fo-comment__content__author {
  margin-right: var(--rem-size-8);
  font-weight: var(--font-weight-medium);
}

.fo-comment__content__likes {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: var(--rem-size-3) var(--rem-size-8);
  color: var(--white);
  background: var(--main-color);
  border: var(--rem-size-2) solid var(--white);
  border-radius: 99px;
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-18);
  transform: translate(50%, 50%);
}

.fo-comment__content__likes .ideacon {
  padding-right: var(--rem-size-2);
}

.fo-comment__toolbar {
  display: inline-flex;
  flex-wrap: wrap;
  margin: var(--rem-size-8) calc(var(--rem-size-4)*-1);
}

.fo-comment__toolbar button {
  cursor: pointer;
}

.fo-comment__toolbar>span {
  margin: var(--rem-size-4);
}

.fo-comment__toolbar>span::after {
  content: "";
  display: inline-block;
  width: var(--rem-size-4);
  height: var(--rem-size-4);
  margin-left: var(--rem-size-8);
  background: var(--grey-light);
  border-radius: 100%;
  vertical-align: middle;
}

.fo-comment__toolbar>span:last-child::after {
  display: none;
}

.fo-comment__toolbar--disabled {
  color: var(--grey-darker);
  cursor: auto;
}

.fo-comment__toolbar--danger {
  color: var(--red);
}

.fo-comment__toolbar--selected button {
  color: var(--main-color);
}

.fo-comment__show-more {
  display: block;
  margin: var(--rem-size-4) 0;
  color: var(--main-color);
  cursor: pointer;
}

.fo-comment__show-more .ideacon {
  margin-right: var(--rem-size-8);
}

.fo-comment .fo-comment-input {
  margin-top: var(--rem-size-16);
}

.fo-comment__editing.fo-comment-input {
  margin: 0 0 0 calc((40px + var(--gap-size))*-1);
}

