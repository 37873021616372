.fo-availabilities-editor {
  display: flex;
}

@media only screen and (max-width: 639px) {
  .fo-availabilities-editor {
    flex-direction: column;
  }
}

.fo-availabilities-editor ::v-deep .selection-container {
  width: calc(100% - 245px);
}

.fo-availabilities-editor ::v-deep .idt-availabilities-slot-list {
  width: 245px;
  max-height: 730px;
}

@media only screen and (max-width: 639px) {
  .fo-availabilities-editor ::v-deep .selection-container,
  .fo-availabilities-editor ::v-deep .idt-availabilities-slot-list {
    width: 100%;
  }
}

