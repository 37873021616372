.idt-survey-result-single .idt-radiobutton {
  margin-bottom: var(--rem-size-8);
  padding: 3px .5rem;
  border: 2px solid rgba(0,0,0,0);
  border-radius: var(--global-radius);
  box-shadow: none;
}

.idt-survey-result-single .idt-radiobutton.poll {
  border-left-color: var(--grey);
  border-right-color: var(--grey-lighter);
}

.idt-survey-result-single .idt-radiobutton--checked {
  border: 2px solid var(--main-color) !important;
}

.idt-survey-result-single .idt-radiobutton--checked.wrong {
  border: 2px solid var(--secondary-color);
}

.idt-survey-result-single ::v-deep .idt-radiobutton.quiz.wrong .box {
  background: var(--secondary-color);
}

.idt-survey-result-single ::v-deep .idt-radiobutton.quiz .box {
  background: var(--main-color);
  border: 1px solid var(--white) !important;
}

.idt-survey-result-single ::v-deep .idt-radiobutton.quiz label .idt-icon {
  color: var(--white) !important;
}

.idt-survey-result-single ::v-deep .idt-radiobutton label {
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.idt-survey-result-single ::v-deep .idt-radiobutton .box {
  margin-right: var(--rem-size-18);
}

