.map-chart__bar text {
  font-size: var(--rem-size-14);
}

.map-chart__bar line:not(.last) {
  stroke: var(--grey);
}

.map-chart__bar.hoverMainG>g:not(.hoverG) {
  opacity: .5;
}

.map-chart__map g:hover>path:not(:hover) {
  opacity: .5;
  stroke-opacity: 0;
}

.map-chart__map g.hoverG>path:not(.hoverPath) {
  opacity: .5;
  stroke-opacity: 0;
}

.map-chart__map g.hoverG>path.hoverPath {
  stroke: var(--main-color-darkest) !important;
  fill: var(--main-color-darkest) !important;
}

.map-chart .column {
  padding: 0;
}

