.progress {
  --progress-color: var(--main-color);
  position: relative;
  background-color: var(--grey-alpha30);
  border-radius: 0;
}

.progress .progress-meter {
  position: static;
  background-color: var(--progress-color);
  border-radius: 0;
}

.progress .progress-meter-text {
  left: auto;
  right: 0;
  color: var(--progress-color);
}

