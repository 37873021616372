































































































































































































































































































@import '@/styles/mixins/index';

.resource-editor {
  width: 100%;

  @include respond-to(max-medium) {
    position: fixed;
    top: var(--menu-height);
    left: 0;
    height: calc(((var(--vh, 1vh) * 100)) - var(--menu-height));
    padding: 1rem;
    background-color: var(--white);
    overflow-y: auto;
    z-index: 10;
  }

  .editor {
    display: flex;
    position: relative;

    @include respond-to(max-large) {
      position: relative;
      flex-direction: column;
    }
    @include respond-to(large) {
      height: 172px;
      margin-bottom: 1rem;
    }

    &__image {
      position: relative;
      flex: 0 0 auto;

      @include respond-to(max-large) {
        width: 100%;
      }
      @include respond-to(large) {
        width: var(--resource-img-width);
      }

      .fs__image-upload {
        height: 144px;
        border-style: dashed;
      }
      &__button {
        width: 100%;
        height: 144px;

        ::v-deep .upload-file__btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 144px;
          margin: 0;
          color: var(--main-color);
          background-color: transparent;
        }
      }
    }
    &__content {
      display: flex;
      align-items: stretch;
      flex: 1 0 auto;

      @include respond-to(max-large) {
        flex-direction: column;
      }
      @include respond-to(xxlarge) {
        width: calc(100% - var(--resource-img-width));
      }

      &__block {
        flex: 0 0 auto;

        @include respond-to(max-large) {
          width: 100%;
          margin-top: 1rem;
        }
        @include respond-to(large) {
          width: calc(50% - 1rem);
          margin-left: 1rem;
        }
      }
    }
    &__attachment {
      --resource-tab-content-border-color: var(--grey);

      &.error {
        --resource-tab-content-border-color: var(--secondary-color);
      }
      &__tabs {
        display: flex;
        align-items: center;
      }
      &__tab {
        flex: 1 1 auto;
        height: 30px;
        color: var(--grey-darker);
        background-color: var(--grey-lighter);
        font-size: var(--rem-size-12);
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:not([disabled]):not(.active) {
          cursor: pointer;
        }
        &:first-child {
          border-radius: var(--global-radius) 0 0 0;
        }
        &:last-child {
          border-radius: 0 var(--global-radius) 0 0;
        }
        &.active {
          color: var(--white);
          background-color: var(--main-color);
        }
      }
      &__content {
        width: 100%;
        height: 42px;
        border-right: 1px solid var(--resource-tab-content-border-color);
        border-bottom: 1px solid var(--resource-tab-content-border-color);
        border-left: 1px solid var(--resource-tab-content-border-color);
        border-radius: 0 0 var(--global-radius) var(--global-radius);
      }
      &__url {
        height: 40px;
        margin: 0;
        border: none;
      }
      &__upload {
        ::v-deep .idt-fs-upload-file__button {
          border: none;
        }
      }
      &__file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        &__name {
          flex: 0 0 auto;
          width: calc(100% - 32px);
          padding: var(--rem-size-8);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &__delete {
          flex: 0 0 auto;
          padding: var(--rem-size-8);
          color: var(--secondary-color);
          cursor: pointer;

          &:hover {
            color: var(--secondary-color-dark);
          }
        }
      }
    }
    &__description {
      display: block;
      position: relative;
    }
    &__delete {
      display: flex;
      align-items: center;
      padding-left: 1rem;

      @include respond-to(max-large) {
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
      }

      &__button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--danger-color);
        cursor: pointer;

        @include respond-to(max-large) {
          height: 40px;
        }
        @include respond-to(large) {
          flex-direction: column;
        }

        &:hover {
          color: var(--danger-color-dark);
        }

        ::v-deep .ideacon {
          @include respond-to(max-large) {
            margin-right: 0.5rem;
          }
          @include respond-to(large) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      margin: 0;
      text-transform: uppercase;
    }
    &__cancel {
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}
