.custom-field-choice-item__container {
  display: flex;
  align-items: center;
}

.custom-field-choice-item__index {
  width: 12px;
  margin-right: 14px;
  padding-bottom: 1.5rem;
  color: var(--main-color);
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.custom-field-choice-item__label {
  flex-grow: 1;
}

.custom-field-choice-item__label ::v-deep .idt-input {
  margin-bottom: 0;
}

.custom-field-choice-item__delete {
  margin-left: 10px;
  padding-bottom: 1.5rem;
  color: var(--secondary-color);
  cursor: pointer;
}

