.idt-profile-privacy p {
  color: var(--black);
}

.idt-profile-privacy label {
  margin-bottom: 10px;
}

.idt-profile-privacy button.button {
  min-width: 220px;
}

.idt-profile-privacy button.button.idt-profile-privacy__button-remove:not(:hover) {
  color: var(--black) !important;
  background-color: var(--grey-light);
}

