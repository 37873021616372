.gamification-dashboard {
  background-color: var(--grey-lighter);
}

.gamification-dashboard ::v-deep button {
  cursor: pointer;
}

.gamification-dashboard ::v-deep .error {
  color: var(--red);
  margin: auto;
  font-size: var(--rem-size-16);
  text-align: center;
}

.gamification-dashboard__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamification-dashboard__content>div {
  width: 100%;
  max-width: 1170px;
  margin: 1rem;
  flex-direction: column;
  margin-top: 2rem;
}

.gamification-dashboard__content ::v-deep .gamification-loader {
  width: 50px;
  height: 50px;
}

.gamification-dashboard__content__info-card {
  display: flex;
  align-items: center;
}

.gamification-dashboard__content__info-card__content {
  width: 90%;
}

