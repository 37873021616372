




































































.users-list-popover {
  &__counter {
    font-weight: var(--font-weight-bold);
    color: var(--black);
    border-bottom: 1px solid var(--grey-light);
  }
  &__item {
    display: flex;
    align-items: center;
    padding: var(--rem-size-4) var(--rem-size-8);
    cursor: pointer;
    transition: background-color var(--default-duration) ease-in-out;

    &:hover {
      background-color: var(--grey-lightest);
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-light);
    }
    &__avatar {
      flex: 0 0 auto;
    }
    &__user {
      padding-left: 0.45rem;
      color: var(--black);

      &__name {
        font-family: var(--title-font-family);
        font-weight: var(--font-weight-bold);
        font-size: var(--rem-size-16);
        line-height: 1.2;
      }
      &__company {
        font-size: var(--rem-size-13);
        line-height: 1;
      }
    }
  }
}
