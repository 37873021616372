.agenda {
  min-height: calc(105vh - 210px);
  background-color: var(--grey-lighter);
}

.agenda .idt-page-top {
  min-height: 100px;
}

.agenda__grid-container {
  display: grid;
  grid-template-columns: 0fr 1.6fr;
  grid-template-rows: 1fr;
  grid-template-areas: "filter-container main-grid";
  min-height: calc(105vh - 210px);
  gap: 1px 1px;
}

.agenda__grid-container__filter-container {
  grid-area: filter-container;
  width: 60px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 1000px) {
  .agenda__grid-container__filter-container {
    width: 0px;
  }
}

.agenda__grid-container__filter-container.active {
  width: 300px;
}

.agenda__grid-container__filter-container__show-filter {
  height: 35px;
  width: 35px;
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 10px;
  background-color: var(--white);
  border: solid 1px var(--grey-dark);
  border-radius: 100%;
  color: var(--black);
  cursor: pointer;
}

.agenda__grid-container__main-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .1fr 1.9fr;
  grid-template-areas: "days" "tracks";
  grid-area: main-grid;
  gap: 1px 1px;
}

@media only screen and (max-width: 1000px) {
  .agenda__grid-container__main-grid {
    margin-left: -10px;
  }
}

.agenda__grid-container__main-grid__days {
  display: grid;
  grid-area: days;
  grid-template-columns: .1fr 1.9fr;
  grid-template-rows: 1fr;
  grid-template-areas: "days-title days";
  align-items: center;
  height: 100%;
  min-height: 60px;
  border-left: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  gap: 1px 1px;
}

.agenda__grid-container__main-grid__days__selector {
  height: 100%;
  max-width: none;
  margin: unset;
  overflow-y: auto;
}

.agenda__grid-container__main-grid__days__title {
  grid-area: days-title;
  align-items: center;
  height: 100%;
  min-width: 120px;
  width: 100%;
  border-right: 1px solid var(--grey);
}

.agenda__grid-container__main-grid__days__title__media-show-filter {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .agenda__grid-container__main-grid__days__title__media-show-filter {
    display: block;
    height: 30px;
    width: 30px;
    margin-left: 10px;
    background-color: var(--white);
    border: solid 1px var(--grey-dark);
    border-radius: 100%;
    color: var(--black);
    cursor: pointer;
  }
}

.agenda__grid-container__main-grid__days__title__text {
  margin-right: 15px;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (max-width: 1000px) {
  .agenda__grid-container__main-grid__days__title__text {
    margin-top: 5px;
  }
}

.agenda__grid-container__main-grid__days__selector {
  grid-area: days;
}

.agenda__grid-container__main-grid__tracks {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .1fr 1.8fr;
  grid-template-areas: "tracks-bar" "talks";
  grid-area: tracks;
  height: calc(105vh - 275px);
  overflow: auto;
  gap: 1px 1px;
  border-left: 1px solid var(--grey);
}

.agenda__grid-container__main-grid__tracks__bar {
  display: grid;
  align-items: center;
  grid-template-columns: .1fr 1.9fr;
  grid-template-rows: 1fr;
  grid-template-areas: "tracks-title tracks";
  grid-area: tracks-bar;
  position: sticky;
  top: 0;
  height: 100%;
  min-height: 60px;
  background-color: var(--grey-lighter);
  border-bottom: 1px solid var(--grey);
  gap: 1px 1px;
  z-index: 20;
}

.agenda__grid-container__main-grid__tracks__bar__title {
  grid-area: tracks-title;
  left: 0;
  position: sticky;
  height: 100%;
  min-width: 120px;
  width: 100%;
  background-color: var(--grey-lighter);
  border-right: 1px solid var(--grey);
  z-index: 10;
}

.agenda__grid-container__main-grid__tracks__bar__title__text {
  margin-top: 20px;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.agenda__grid-container__main-grid__tracks__bar__grid {
  grid-area: tracks;
}

.agenda__grid-container__main-grid__tracks__talks {
  display: grid;
  grid-template-columns: .1fr 1.9fr;
  grid-template-rows: 1fr;
  grid-template-areas: "timeline agenda-grid";
  grid-area: talks;
  gap: 1px 1px;
  border-bottom: 1px solid var(--grey);
}

.agenda__grid-container__main-grid__tracks__talks__timeline {
  grid-area: timeline;
  position: sticky;
  left: 0;
  min-width: 120px;
  width: 100%;
  height: 100%;
  background-color: var(--grey-lighter);
  border-right: 1px solid var(--grey);
  z-index: 10;
}

.agenda__grid-container__main-grid__tracks__talks__timeline__timezone {
  position: absolute;
  top: var(--rem-size-8);
  width: 100%;
  background-color: var(--grey-lighter);
  color: var(--grey-darker);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.agenda__grid-container__main-grid__tracks__talks__grid {
  grid-area: agenda-grid;
  height: 100%;
}

