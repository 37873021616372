
































































.custom-field-choice-item {
  &__container {
    display: flex;
    align-items: center;
  }

  &__index {
    width: 12px;
    margin-right: 14px;
    padding-bottom: 1.5rem;
    color: var(--main-color);
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }

  &__label {
    flex-grow: 1;

    ::v-deep .idt-input {
      margin-bottom: 0;
    }
  }

  &__delete {
    margin-left: 10px;
    padding-bottom: 1.5rem;
    color: var(--secondary-color);
    cursor: pointer;
  }
}
