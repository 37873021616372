









































































































































































































































@import '@/styles/mixins/index';

.answer{
  margin-bottom: 1.25rem;

  &__header {
    display: flex;
    padding-bottom: 1.2rem;

    &__user {
      display: inline-block;
      vertical-align: middle;
      width: 75%;
      cursor: pointer;

      &__avatar {
        display: inline-block;
        margin-right: var(--rem-size-8);
        vertical-align: middle;
      }
      &__information {
        display: inline-block;
        width: calc(100% - 60px);
        vertical-align: middle;

        .idt-user-information{
          &__username{
            @include respond-to(max-medium) {
              display: block;
            }
          }
        }
      }
    }
    &__vote {
      display: inline-block;
      width: 25%;
      vertical-align: middle;
      text-align: right;

      &__button{
        display: inline-block;
        font-size: 1.3rem;
        vertical-align: middle;

        &.button--togglable{
          transition: all .4s ease;
          cursor:pointer;

          &.button--full,
          &:hover{
            color: var(--main-color);
          }
        }
        &.button--disable {
          opacity: 0.4;
        }
      }
      &__number {
        display: inline-block;
        padding: 0 2px;
        font-size: 0.8rem;
        vertical-align: middle;
      }
    }
  }
  &__content {
    padding: 1.25rem 1.5rem;
    background-color: var(--white);
    box-shadow: -1px -1px 2px -2px var(--grey);

    &__text {
      white-space: pre-wrap;
      word-break: break-word;
    }
    &__created {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-weight: 300;

      &__icon{
        cursor: pointer;
      }
    }
    &__delete {
      font-size: 1.1rem;
      vertical-align: middle;
      cursor: pointer;

      &:hover {
        color: var(--danger-color);
      }
    }
    &__preview {
      display: block;
      margin-top: 0.5rem;
    }
  }
}
