

























































































































































































































.menu-conference-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100px;
  margin: 2px 0;
  padding: 1rem 0.75rem 1rem 1.25rem;
  color: var(--main-color);
  background-color: var(--main-color-lighter);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--main-color-alpha40);
  }
  &.is-starting {
    color: var(--color-starting);
    background-color: var(--color-starting-alpha20);

    &:hover {
      background-color: var(--color-starting-alpha30);
    }
  }
  &.is-now {
    color: var(--secondary-color);
    background-color: var(--secondary-color-alpha20);

    &:hover {
      background-color: var(--secondary-color-alpha30);
    }
  }
  &.converting {
    color: var(--main-color-darker);
    background-color: var(--main-color-darker-alpha20);

    &:hover {
      background-color: var(--main-color-darker-alpha30);
    }
  }
  &.replay {
    color: var(--grey-darkest);
    background-color: var(--grey-light);

    &:hover {
      background-color: var(--grey-lighter);
    }
  }
  &.canceled {
    color: var(--grey-dark);
    background-color: var(--grey);
    pointer-events: none;
  }
  &__speaker {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    max-width: calc(100% - 125px);
    color: var(--grey-darkest);
    line-height: 20px;

    &__avatar {
      margin-right: 10px;
    }
    &__info {
      max-width: 95%;

      .conference-track {
        font-size: 0.75rem;
        font-weight: var(--font-weight-medium);
        text-transform: uppercase;
      }
      .conference-title {
        width: 100%;
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .conference-speaker-name {
        font-size: 0.75rem;
      }
    }
  }
  &__state {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: var(--rem-size-16);
    font-weight: var(--font-weight-bold);

    &__info {
      margin-right: var(--rem-size-10);

      &.scheduled,
      &.converting {
        font-size: 0.8rem;
        text-align: right;
        line-height: 1.2;
      }
    }
    .live-dot {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: var(--color-live);
      border: 1px solid var(--white);
      border-radius: 100%;
    }
  }
  &__stared {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }

  &.premium {
    color: var(--grey-dark);
    background-color: var(--grey-lighter);

    &:hover {
      color: var(--grey-darker);
      background-color: var(--grey-lightest);
    }
  }

  .conference-premium-badge {
    top: 0;
    right: 0;
  }
}
