.idt-user-information {
  line-height: 1.2;
}

.idt-user-information__username {
  margin-right: var(--rem-size-4);
  font-weight: var(--font-weight-bold);
}

.idt-user-information__username.username--is-stacked {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.idt-user-information__classification {
  font-weight: var(--font-weight-light);
}

.idt-user-information__classification__tags {
  display: inline;
  display: content;
}

.idt-user-information__classification__tag:not(:last-child):after {
  content: ", ";
}

.idt-user-information__classification__label:not(:last-child):after {
  content: ", ";
}

