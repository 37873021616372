














































.fo-project-section-media-view {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);

  &__title {
    color: var(--grey-darkest);
  }

  &__carousel {
    margin-bottom: var(--rem-size-32);
  }
}
