









































































































































































































































.talk-question-card {
  border-radius: var(--global-radius);
  padding: 8px;
  background-color: var(--grey-lighter);
  color: var(--black);
  font-size: 16px;

  &__text {
    line-height: 1.2;
    white-space: pre-line;
    word-break: break-word;
  }
  &--is-author {
    border: 1px solid var(--main-color);
  }
  &--is-answer {
    border: none;
    background-color: var(--white);
  }
  &--highlighted {
    border-left: 3px solid var(--main-color);
  }
  &__content {
    display: flex;
  }
  &__center {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 7px;
    padding-top: 0.1rem;
    font-size: 16px;
  }
  &__right {
    display: flex;
    flex-direction: column;
    font-weight: var(--font-weight-bold);
    margin-left: 8px;

    .vote-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: var(--grey);
      }
    }
    .votes-number {
      display: flex;
      justify-content: center;
    }
    .upVote:hover,
    .upVote.has-voted {
      color: var(--main-color);
    }
    .downVote:hover,
    .downVote.has-voted {
      color: var(--red);
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--rem-size-8);
    margin-top: var(--rem-size-8);
  }
  &__author-name,
  .idt-user-avatar {
    font-weight: var(--font-weight-bold);
    cursor: pointer;
  }
  &__answers {
    display: flex;
    flex-direction: column;
    margin-top: var(--rem-size-8);

    & > .talk-question-card {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__answer-input {
    & ::v-deep .idt-textarea {
      margin-top: 10px;
    }
  }
  &--no-margin {
    margin: 0;
  }
}
