
































































































































































@import '@/styles/mixins/index';

.modal-question {
  &__step {
    padding: var(--rem-size-16);

    &:not(:last-child) {
      border-bottom: 2px solid var(--grey);
    }
    &__header {
      margin-bottom: var(--rem-size-16);

      &__number {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;
        color: var(--white);
        background-color: var(--main-color);
        border-radius: 100%;
        font-size: 0.9rem;
        text-align: center;
        line-height: 24px;
        vertical-align: middle;
      }
      &__title {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
      }
    }
    &__counter {
      position: relative;
      &__textarea {
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        padding: var(--rem-size-8) var(--rem-size-16) var(--rem-size-8) var(--rem-size-8);
        background-color: var(--white);
        border: 1px solid var(--grey);
        border-radius: var(--global-radius);
        transition: border 0.3s ease-in-out;
        resize: vertical;

        &:focus {
          border: 1px solid var(--main-color);
          outline: 0;
        }
      }
      &__number {
        position: absolute;
        top: 1px;
        right: 1px;
        padding: var(--rem-size-4);
        border-radius: 0 var(--global-radius) 0 var(--global-radius);
        background-color: var(--main-color);
        font-size: 11px;
        color: var(--white);
      }
    }
    &__button {
      &.button--submit {
        display: inline-block;
        width: 100%;
        max-width: 80px;
        padding: var(--rem-size-4) var(--rem-size-12);
        border-radius: var(--global-radius);
        border: none;
        background-color: var(--main-color);
        vertical-align: middle;
        color: var(--white);
        transition: all .4s ease;

        &:hover {
          background-color: var(--main-color-dark);
        }
      }
      &.button--download {
        .upload-file__btn {
          padding: var(--rem-size-4) var(--rem-size-8);
        }
      }
    }
    &__panels {
      flex-flow: row wrap;
      margin: 0;

      .button {
        margin-right: var(--rem-size-4);
      }
    }
    &__separator {
      position: relative;
      margin-top: var(--rem-size-16);
      margin-bottom: var(--rem-size-16);
      height: 1px;
      background-color: var(--grey);
      text-align: center;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -7px;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
      }
      &:before {
        border-top: 14px solid var(--grey);
      }
      &:after {
        top: -1px;
        border-top: 14px solid var(--grey-lighter);
      }
    }
    &__preview {
      display: block;
    }
  }
  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 2px solid var(--grey);

    @include respond-to(max-medium) {
      height: 70px;
    }
    @include respond-to(medium) {
      height: 96px;
    }

    &__button {
      margin: 0;

      &.button--pending {
        background-color: var(--grey-lightest);
        border-color: var(--grey-lightest);
      }
    }
  }
}
