.idt-ticket-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
  padding: 0 15px;
}

.idt-ticket-card-info:not(.modal-mode) {
  position: absolute;
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-card-info:not(.modal-mode) {
    top: -20px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-card-info:not(.modal-mode) {
    top: -28px;
  }
}

.idt-ticket-card-info__name {
  background-color: var(--white);
  font-family: var(--title-font-family);
  font-weight: var(--font-weight-bold);
  text-align: center;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-card-info__name {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-card-info__name {
    font-size: 1.875rem;
  }
}

.idt-ticket-card-info__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  box-shadow: 0 0 0 3px var(--white);
  border-radius: 100%;
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-card-info__icon {
    width: 38px;
    height: 38px;
    min-width: 38px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-card-info__icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }

  .idt-ticket-card-info__icon .ideacons {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 370px) {
  .idt-ticket-card-info.modal-mode .idt-ticket-card-info__name {
    font-size: 1.875rem;
  }
}

@media only screen and (min-width: 370px) {
  .idt-ticket-card-info.modal-mode .idt-ticket-card-info__icon {
    width: 50px;
    height: 50px;
  }

  .idt-ticket-card-info.modal-mode .idt-ticket-card-info__icon .ideacons {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }
}

