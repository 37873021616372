.live-chat-message-actions__reaction-trigger .idea-ht-emoji {
  --color2: var(--grey-darker);
  --color3: var(--white);
}

.live-chat-message-actions__reaction-trigger:disabled .idea-ht-emoji {
  --color2: var(--grey);
}

.live-chat-message-actions {
  display: none;
  position: absolute;
  top: -12px;
  right: 20px;
  border-radius: 4px;
}

.live-chat-message-actions .action {
  width: 26px;
  height: 26px;
  vertical-align: middle;
  color: var(--grey-darker);
  background: var(--grey-lightest);
  border: 1px solid var(--grey-light);
  cursor: pointer;
}

.live-chat-message-actions .action:disabled {
  color: var(--grey);
  opacity: 1;
  cursor: default;
}

.live-chat-message-actions__reaction-trigger {
  border-left: 1px solid var(--grey-light) !important;
  border-radius: var(--global-radius) 0 0 var(--global-radius) !important;
}

