













































































@import '@/styles/mixins/index';

// -----------------------------
// 3. Toggle
// -----------------------------
.idt-sidebars-container-toggle {
  position: absolute;
  bottom: 180px;
  left: 0;
  z-index: 1;
  width: 26px;
  padding: 12px 0 10px 2px;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: var(--global-radius) 0 0 var(--global-radius);
  font-size: var(--rem-size-20);
  text-align: center;
  cursor: pointer;

  @include respond-to(max-medium) {
    display: none;
  }
  &.admin-conference-toggle {
    bottom: 35px;
    background-color: var(--secondary-color);
  }
  &.sponsor-stats-toggle {
    bottom: 350px;
    background-color: var(--secondary-color);
  }
  &__notifications {
    position: absolute;
    top: 0;
    right: 50%;
    width: 22px;
    height: 22px;
    color: var(--white);
    background-color: var(--secondary-color);
    border-radius: 100%;
    font-size: 0.7rem;
    text-align: center;
    line-height: 22px;
    transform: translate(50%, -50%);
  }
  &__icon-chat {
    line-height: 1;
  }
  &__icon-state {
    line-height: 1;
  }
  &__label {
    position: relative;
    width: 26px;
    height: 75px;
    margin: 5px 0;

    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 0.9rem;
      font-weight: var(--font-weight-medium);
      line-height: 1.4;
      white-space: nowrap;
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
}
