.talk {
  height: 128px;
  width: 100%;
}

.vue-grid-item {
  transition: none;
}

.vue-grid-layout {
  transition: none;
}

