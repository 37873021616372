.item {
  display: flex;
  align-items: center;
  height: 45px;
  background-color: var(--grey-lighter);
  border-radius: 3px;
  color: var(--black);
  cursor: grab;
}

.item.selected {
  background-color: var(--white);
  box-shadow: 0 0 10px 0 var(--black-alpha15);
}

.item__item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item__edit {
  margin: 5px 5px 0px 0px;
}

.item__cancel {
  margin-top: 8px;
  color: var(--grey-dark);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.item button {
  cursor: pointer;
}

