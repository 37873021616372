.event-projects {
  padding: 0;
}

@media only screen and (max-width: 1023px) {
  .event-projects__container {
    padding: 3rem 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-projects__container {
    padding: 5rem 3rem;
  }
}

.event-projects__list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .event-projects__list {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
  }

  .event-projects__list .event-projects__list__item {
    flex-shrink: 0;
  }
}

.event-projects__list.first-prize {
  overflow: hidden !important;
}

@media only screen and (max-width: 479px) {
  .event-projects__list:after {
    content: "";
    min-width: .4rem;
  }
}

.event-projects__list__item {
  display: flex;
  width: 375px;
  margin: .4rem;
}

@media only screen and (max-width: 479px) {
  .event-projects__list__item {
    width: 300px;
  }
}

.event-projects__list__item .idt-project-card {
  max-width: 375px;
}

@media only screen and (max-width: 479px) {
  .event-projects__list__item .idt-project-card {
    max-width: 300px;
  }
}

.event-projects__list__first-prize {
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--grey);
}

.event-projects__list__first-prize .idt-project-card {
  max-width: 100%;
}

