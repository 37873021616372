








































.idt-profile-privacy {
  p {
    color: var(--black);
  }

  label{
    margin-bottom: 10px;
  }

  button.button {
    min-width: 220px;

    &.idt-profile-privacy__button-remove:not(:hover) {
      color: var(--black) !important;
      background-color: var(--grey-light);
    }
  }
}
