.timeslot__error {
  color: var(--secondary-color);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-24);
}

.timeslot__day {
  display: flex;
}

.timeslot__day__inputs {
  width: 100%;
}

.timeslot__day__inputs__days {
  width: 100%;
}

.timeslot__day__inputs__edit {
  margin-left: var(--rem-size-25);
}

.timeslot__day__inputs__edit:hover {
  cursor: pointer;
}

.timeslot__day__inputs__delete {
  margin-left: var(--rem-size-15);
}

.timeslot__day__inputs__delete:hover {
  cursor: pointer;
}

.timeslot__day__calendar {
  position: relative;
  top: var(--rem-size-10);
  left: 100px;
  background-color: var(--white);
  border: 1px solid var(--grey-lighter);
  z-index: 10;
}

.timeslot__time {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 639px) {
  .timeslot__time {
    flex-direction: column;
  }
}

::v-deep .timeslot__time .idt-time-picker-input {
  width: 50%;
}

@media only screen and (max-width: 639px) {
  ::v-deep .timeslot__time .idt-time-picker-input {
    width: 100%;
  }
}

::v-deep .timeslot__time .idt-time-picker-input:first-child {
  margin-right: 15px;
}

.timeslot__button {
  display: flex;
  align-items: center;
  justify-content: right;
}

.timeslot__button__cancel {
  width: 100px;
  height: 45px;
  padding: 10px var(--rem-size-25);
  margin-right: 7px;
  color: var(--black);
  border: solid 1px var(--grey);
  border-radius: 30px;
  background-color: var(--white);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-24);
}

.timeslot__button__cancel:hover {
  cursor: pointer;
}

.timeslot__button__save {
  width: fit-content;
  min-width: 100px;
  height: 45px;
  padding: 10px var(--rem-size-25);
  color: var(--white);
  border-radius: 30px;
  background-color: var(--main-color);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-24);
}

.timeslot__button__save.disabled {
  background-color: var(--grey);
}

.timeslot__button__save.disabled:hover {
  cursor: not-allowed;
}

.timeslot__button__save:hover {
  cursor: pointer;
}

