







































































































































































































































@import '@/styles/mixins/index';

.modal-answers {
  &__content {
    position: absolute;
    width: 100%;
    transition: all 0.3s ease;
    overflow-y: scroll;

    @include respond-to(max-medium) {
      height: calc(100% - 70px);
    }
    @include respond-to(medium) {
      height: calc(100% - 96px);
    }

    &.footer-open {
      height: calc(100% - 292px);

      &.has-file {
        height: calc(100% - 398px);
      }
    }
  }
  &__form {
    border-bottom: 6px solid var(--grey-light);
    background-color: var(--grey-lightest);

    &__counter {
      position: relative;

      &__textarea {
        width: 100%;
        max-width: 100%;
        max-height: 250px;
        margin-bottom: 10px;
        padding: var(--rem-size-8) var(--rem-size-30) var(--rem-size-8) var(--rem-size-8);
        border-radius: var(--global-radius);
        border: 1px solid var(--grey-light);
        background-color: var(--white);
        transition: border 0.3s ease;
        resize: vertical;

        &:focus {
          border: 1px solid var(--main-color);
          outline: 0;
        }
      }
      &__number {
        position: absolute;
        top: 1px;
        right: 1px;
        padding: var(--rem-size-3);
        border-radius: 0 var(--global-radius) 0 var(--global-radius);
        background-color: var(--main-color);
        font-size: 11px;
        color: var(--white);
      }
    }
    &__upload-preview {
      &__link {
        display: inline-block;
        position: relative;

        &__figure,
        &__file {
          overflow: hidden;
          width: 100px;
          height: 100px;
          border-radius: var(--global-radius);
        }
        &__figure {
          background-repeat: no-repeat;
          background-position: center center;
        }
        &__file {
          background-color: var(--grey-light);
          text-align: center;

          &__icon {
            position: relative;
            top: -18px;
            margin-top: 50%;
            font-size: 36px;
            color: var(--grey-lightest);
          }
        }
        &__button {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: var(--rem-size-4) var(--rem-size-8);
          border-radius: var(--global-radius);
          border: none;
          background-color: var(--danger-color);
          z-index: 5;
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--main-color);
          }
          &__icon {
            color: var(--white);
          }
        }
        &__overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: var(--global-radius);
          background-color: var(--black-alpha40);
          text-align: center;
          opacity: 0;
          transition: all 0.3s ease;
          z-index: 4;

          &:hover {
            opacity: 1;
          }
          &__icon {
            position: relative;
            top: -12px;
            margin-top: 50%;
            font-size: 24px;
            color: var(--white);
          }
        }
      }
    }
    &__button {
      &.button--submit {
        display: inline-block;
        width: 100%;
        max-width: 80px;
        border-radius: var(--global-radius);
        border: none;
        background-color: var(--main-color);
        vertical-align: middle;
        color: var(--white);
        transition: all 0.3s ease;
      }
    }
  }
  &__list-answers,
  &__no-content {
    padding: 1.25rem 1.5rem;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: var(--grey-lightest);
    border-top: 2px solid var(--grey);

    &__content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s ease;

      &.opened {
        max-height: 500px;
      }
    }
  }
}
