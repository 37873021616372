







































































@import '@/styles/mixins/index';

.idt-bottom-navbar {
  display: none;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: var(--mobile-bottom-navbar-height);
  height: var(--mobile-bottom-navbar-height);
  width: 100%;
  background: var(--white);
  border-top: 1px solid var(--grey-darkest-alpha20);
  padding: var(--rem-size-6) 0 var(--rem-size-4);
  z-index: 1;
  transition: margin-top .15s ease-in-out, transform .15s ease-in-out;

  @include respond-to(max-slarge) {
    display: flex;
    border-radius: 0;
    box-shadow: none;
  }

  &--hide {
    margin-top: calc(-1 * var(--mobile-bottom-navbar-height));
    transform: translateY(100%);
  }

  &--fixed {
    position: fixed;
    bottom: 0;
    z-index: 100;
  }

  &__icon-wrapper {
    display: flex;
    flex-flow: column;
    flex: auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    flex-shrink: 0;
    overflow: hidden;
    color: var(--grey-darkest);
    font-size: var(--rem-size-12);
    line-height: var(--rem-size-18);

    > * {
      width: 100%;
      flex-basis: 100%;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: 32px;
    width: 100%;
    height: 32px;
    max-width: 64px;
    margin-bottom: var(--rem-size-4);
    border-radius: 20px;
    color: var(--grey-darkest);
    transition: background-color .15s ease-in-out;
  }

  &__icon-wrapper.active {
    .idt-bottom-navbar__icon {
      color: var(--grey-darkest);
      background-color: var(--main-color-alpha20);
    }
  }
}
