






































































































































































































@import '@/styles/mixins/index';

.idt-talk-card {
  flex: 0 0 auto;
  width: 100%;
  max-width: 398px;
  padding: 0 12px 24px 12px;

  &__img {
    width: 100%;
    height: 180px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__avatar {
    flex: 0 0 auto;
  }
}
.idt-fo-card {
  width: 100%;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 2px 5px var(--black-alpha20);
  }
  ::v-deep &__header {
    padding: 0;
    padding-bottom: 1rem;
  }
  ::v-deep &__footer {
    height: 66px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    margin-bottom: var(--rem-size-8);
    color: var(--black);

    &__item {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      &__text {
        margin-left: 0.5rem;
        color: var(--black);
        font-family: var(--first-font-family);
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
  &__title {
    min-height: 40px;
    margin-bottom: 5px;
    color: var(--black);
    font-family: var(--title-font-family);
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-bold);
    line-height: 1.25rem;
  }
  &__description {
    min-height: 40px;
    color: var(--grey-darkest);
    font-size: var(--rem-size-14);
    line-height: 1.4;
  }
}
.speaker-info {
  display: flex;
  flex-direction: column;
  margin-left: var(--rem-size-8);

  &__username {
    color: var(--grey-darkest);
    font-family: var(--title-font-family);
    font-weight: var(--font-weight-bold);
    line-height: 1rem;
  }
  &__other {
    color: var(--grey-darker);
    font-size: var(--rem-size-12);
    line-height: 1rem;
  }
}
