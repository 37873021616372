































.idt-sponsor-stats {
  height: 100%;
}
