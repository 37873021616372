




























































@import '@/styles/mixins';

.event-map {
  z-index: 1;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100% !important;
      min-height: 100% !important;
      transform: translate(-50%, -50%);
    }
  }
  &__info {
    max-width: 600px;
    color: var(--white);
    background-color: var(--black-alpha60);

    @include respond-to(max-medium) {
      padding: 2.5rem;
    }
    @include respond-to(medium) {
      padding: 4rem 5rem;
    }

    &__container {
      display: flex;
      justify-content: flex-end;
    }
    &__name {
      font-size: 2rem;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 1;

      @include respond-to(max-medium) {
        font-size: 1.2rem;
      }
      @include respond-to(medium) {
        font-size: 2rem;
      }

      i {
        vertical-align: middle;
      }
    }
    &__dates {
      padding: 1rem 0;
      font-weight: 700;

      @include respond-to(max-medium) {
        font-size: 1rem;
      }
      @include respond-to(medium) {
        font-size: 1.8rem;
      }
    }
    &__address {
      font-size: 1.1rem;
      line-height: 1.2;

      i {
        font-size: 2.5rem;
      }
    }
  }
}
