































































































































































































































































.bo-gamification {
  .gamification_setting {
    padding-bottom: var(--rem-size-16);

    .gamification_title {
      font-weight: var(--font-weight-medium);
    }
    .gamification_label {
      margin: auto;
      padding-left: var(--rem-size-12);
    }
    ::v-deep .container-only {
      display: inherit;
    }
  }
  ::v-deep .idt-input-counter {
    right: var(--rem-size-32);
  }
  ::v-deep .idt-required-field {
    left: unset;
  }
}
