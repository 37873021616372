.meeting-waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--rem-size-20) 0;
  color: var(--grey-darkest);
}

.meeting-waiting__open-button {
  width: 200px;
  height: 50px;
  margin-top: 80px;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  text-align: center;
  background-color: var(--main-color);
  border-radius: var(--global-radius);
}

.meeting-waiting .box {
  padding: var(--rem-size-10) var(--rem-size-12);
  background: var(--white);
  border-radius: var(--global-radius);
}

.meeting-waiting .info-label {
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-medium);
}

.meeting-waiting__participant-carousel {
  width: 100%;
  margin-bottom: var(--rem-size-10);
}

.meeting-waiting__participant-carousel.small {
  width: 600px;
}

.meeting-waiting__participant-carousel__name {
  margin-top: var(--rem-size-8);
  margin-bottom: var(--rem-size-4);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.meeting-waiting__participant-carousel__job {
  margin-bottom: var(--rem-size-5);
  font-size: var(--rem-size-12);
}

.meeting-waiting__participant-carousel ::v-deep .idt-tag {
  display: inline-block;
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider {
  margin: var(--rem-size-10) 0;
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider .slick-list {
  padding: 0px 20%;
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider .slick-list .slick-slide {
  width: 120px;
  margin: 0 var(--rem-size-30);
  text-align: center;
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider .slick-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 120px;
  height: 100%;
  z-index: 1;
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider .slick-arrow:first-child {
  left: 0;
  background: linear-gradient(90deg, #F5F6FA 65.44%, rgba(235, 237, 242, 0) 89.78%, rgba(245, 246, 250, 0) 89.78%);
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider .slick-arrow:last-child {
  right: 0;
  background: linear-gradient(271.15deg, #F5F6FA 65.44%, rgba(235, 237, 242, 0) 89.78%, rgba(245, 246, 250, 0) 89.78%);
}

.meeting-waiting__participant-carousel ::v-deep .slick-slider .slick-arrow:last-child .arrow {
  float: right;
}

.meeting-waiting__title {
  margin-bottom: 62px;
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-30);
}

.meeting-waiting__informations {
  margin-bottom: var(--rem-size-32);
}

.meeting-waiting__informations__date .idt-icon {
  margin-right: var(--rem-size-8);
}

.meeting-waiting__informations span>span {
  line-height: var(--rem-size-24);
}

.meeting-waiting__informations ::v-deep .idt-icon>svg {
  vertical-align: sub;
}

.meeting-waiting__countdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.meeting-waiting__countdown__time-label {
  margin-left: var(--rem-size-8);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
}

.meeting-waiting__countdown__item:not(:last-child) {
  margin-right: var(--rem-size-16);
}

