



































































































































.fo-project-bar-page-mapping {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: var(--rem-size-8);

    &__status {
      display: flex;
      align-items: center;
      font-weight: var(--font-weight-normal) !important;
      font-size: var(--rem-size-14) !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: var(--rem-size-16);

    &__sections {
      display: flex;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 50px;
      background-color: var(--white);
      color: var(--grey-darkest);
      cursor: pointer;

      &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: var(--font-weight-medium);
        font-size: var(--rem-size-12);

        &__name {
          margin-bottom: var(--rem-size-8);
        }
      }

      &__right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--rem-size-12);

        &__button {
          display: flex;
          align-items: center;
          margin-left: var(--rem-size-8);
          padding: var(--rem-size-6);
          border-radius: 50px;
          color: var(--white);

          &.rotate {
            transform: rotate(90deg);
          }

          &.optional {
            background-color: var(--grey-dark);
          }

          &.incomplete {
            background-color: var(--danger-color);
          }

          &.in-progress {
            background-color: var(--yellow);
          }

          &.completed {
            background-color: var(--main-color);
          }
        }
      }
    }
  }
}

::v-deep .idt-accordion__title {
  padding: var(--rem-size-2) var(--rem-size-16);
}

::v-deep .idt-circular-progress {
  margin: 0 var(--rem-size-8);
}
