




































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.fo-project-mode-edit {
  position: relative;
  display: flex;
  min-height: inherit;

  &__content {
    flex-grow: 1;
    width: calc(100% - 400px);
  }
}
