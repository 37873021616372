










































































.resource-container {
  --resource-img-width: 254px;

  width: 100%;
}
