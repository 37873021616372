.idt-survey-pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--rem-size-16);
}

.idt-survey-pagination__chip {
  width: 9px;
  height: 9px;
  margin: 0 6px 6px 0;
  background-color: var(--grey-light);
  border-radius: 50%;
  cursor: pointer;
}

.idt-survey-pagination__chip.active {
  background-color: var(--main-color);
}

