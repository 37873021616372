.modal-side {
  position: absolute;
  width: 100%;
  right: 0;
  height: 100%;
  background-color: var(--grey-lighter);
}

@media only screen and (min-width: 640px) {
  .modal-side {
    width: 640px;
  }
}

.modal-side__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  padding: 1rem 1.5rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-lighter);
}

@media only screen and (max-width: 639px) {
  .modal-side__header {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .modal-side__header {
    width: 640px;
  }
}

.modal-side__header__title {
  flex: 1 1 auto;
  margin: 0;
  font-weight: 300;
}

@media only screen and (max-width: 639px) {
  .modal-side__header__title {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 640px) {
  .modal-side__header__title {
    font-size: 1.5rem;
  }
}

.modal-side__header__actions .button {
  margin: 0;
}

.modal-side__header__cancel {
  color: var(--white) !important;
}

.modal-side__header__close {
  display: block;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  font-size: 25px;
  line-height: 1;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal-side__alerts {
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 10;
}

.modal-side__content {
  position: absolute;
  width: 100%;
  max-height: calc(100% - 70px);
  margin: 70px 0 0 0;
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 639px) {
  .modal-side__content.has-footer {
    max-height: calc(100% - 140px);
    margin: 70px 0;
  }
}

@media only screen and (min-width: 640px) {
  .modal-side__content.has-footer {
    max-height: calc(100% - 166px);
    margin: 70px 0 96px 0;
  }
}

.modal-side__content.is-full-height {
  height: 100%;
}

.modal-side__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: var(--rem-size-16);
  background-color: var(--grey-lightest);
  border-top: 2px solid var(--grey);
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .modal-side__footer {
    width: 580px;
  }
}

.modal-side__footer__validate {
  display: block !important;
  max-width: 250px;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .modal-side__footer__validate {
    margin: 1.0255rem auto !important;
  }
}

@media only screen and (min-width: 640px) {
  .modal-side__footer__validate {
    margin: 1.8385rem auto !important;
  }
}

