





































































































































































































































































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.conference-chat {
  .room {
    height: 100%;

    @include respond-to(medium) {
      border-left: 1px solid var(--grey-lighter);
    }

    .room__title {
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}
.participant-mobile-chat {
  .room {
    @include respond-to(max-slarge) {
      height: calc(
        100vh - (var(--video-height) + var(--menu-and-header-height))
      );
      height: calc(
        (var(--vh, 1vh) * 100) -
          (var(--video-height) + var(--menu-and-header-height))
      );
    }
    height: 100%;
    margin: auto;

    @include respond-to(landscape) {
      display: none;
    }

    &__header {
      display: none;
    }
    &__messages {
      height: calc(100% - 111px);
    }
  }
}
.mentor-mobile-chat {
  .room {
    height: 100%;
    background-color: transparent;

    &__messages {
      height: calc(100% - 111px);
    }
  }
}
.room {
  --chat-message-max-width: 270px;
  --chat-message-radius: 10px;
}
