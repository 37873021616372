






















































































































































































@import '@/styles/mixins/index';

.fo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--menu-height);
  padding: var(--rem-size-12);
  background-color: var(--white);
  border-bottom: 2px solid var(--grey);

  &__left {
    display: flex;
  }
  &__logo {
    padding-right: var(--rem-size-15);

    &__image {
      max-width: 140px;
      max-height: calc(var(--menu-height) - var(--rem-size-24));
    }
  }
  &__right {
    display: flex;
  }

  @include respond-to(max-slarge) {
    padding: 0 var(--rem-size-8);
  }
}

.hide-on-mobile {
  @include respond-to(max-slarge) {
    display: none !important;
  }
}

.show-on-mobile {
  @include respond-to(slarge) {
    display: none !important;
  }
}
