













































































































































.conference-primary-info {
  width: 100%;
  min-height: var(--conference-footer-height);
  background: #EEEFF2;
  border-top: #D0CFD5 solid 3px;

  &--no-separator {
    border-top: none;
  }

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__presentation-image,
  &__avatar {
    flex: 0 0 auto;
    width: 75px;
    height: 75px;
    margin: 1.875rem;
  }

  &__presentation-image {
    border-radius: 50%;
    background-color: var(--grey-darkest-alpha20);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
  }

  &__title {
    flex: 0 1 auto;
    margin-top: 22px;
    color: var(--black);
    font-size: 1.375rem;
    font-weight: var(--font-weight-medium);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--rem-size-3);
    padding-bottom: 1rem;
  }

  &__favorite,
  &__moderator-buttons {
    flex: 0 0 auto;
    margin: 1.25rem 1.25rem 0 auto;
    padding-left: 0.75rem;

    ::v-deep .ideacon {
      vertical-align: unset;
    }
  }
}
