





































































.fs__image-upload--favicon {
  width: 150px;
  height: 150px;
  background-size: contain;
}
