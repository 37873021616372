.password-rules {
  position: relative;
  width: 100%;
  height: 80px;
  text-align: left;
  overflow: hidden;
}

.password-rules.small {
  position: absolute;
  bottom: -80px;
  right: 0;
}

.password-rules.small .password-rules__content {
  margin: 0;
}

.password-rules.is-not-last {
  height: 0;
  margin-bottom: 1rem;
  transition: all .3s ease-in-out;
}

.password-rules.is-not-last.error,
.password-rules.only-error {
  height: 18px;
}

.password-rules.is-not-last.open {
  height: 78px;
}

.password-rules__input {
  position: relative;
  margin: 0 !important;
  z-index: 10;
}

.password-rules__content {
  position: absolute;
  top: -100%;
  left: .5rem;
  width: calc(100% - 1rem);
  background-color: var(--grey-lightest);
  border: 1px solid var(--grey);
  border-top-width: 0;
  opacity: 0;
  transition: all .275s ease-in-out;
  z-index: 1;
}

.password-rules__content.open {
  top: 0;
  opacity: 1;
}

.password-rules__title {
  padding: .5rem .75rem;
  color: var(--grey-dark);
  font-size: .7rem;
}

.password-rules__wraper {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding-bottom: .75rem;
}

.password-rules__wraper.many:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 100%;
  border-left: 1px solid var(--grey);
  transform: translateX(-50%);
}

.password-rules .password-rule {
  flex: 0 0 auto;
  width: 50%;
  font-size: .7rem;
}

@media only screen and (max-width: 639px) {
  .password-rules .password-rule:nth-child(odd) {
    padding: 0 .5rem 0 1rem;
  }
}

@media only screen and (min-width: 640px) {
  .password-rules .password-rule:nth-child(odd) {
    padding: 0 .5rem 0 1.5rem;
  }
}

.password-rules .password-rule:nth-child(even) {
  padding: 0 1rem 0 1rem;
}

.password-rules .password-rule--compliant .ideacons,
.password-rules .password-rule--compliant .idt-icon {
  color: var(--main-color);
}

.password-rules .password-rule--not-compliant {
  color: var(--grey-dark);
  font-weight: 500;
}

