.conference-bo-agenda-info {
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .conference-bo-agenda-info {
    height: var(--bo-header-elm-height);
    padding: 0 var(--rem-size-20);
  }
}

@media only screen and (min-width: 1200px) {
  .conference-bo-agenda-info {
    height: var(--bo-header-height);
  }
}

.conference-bo-agenda-info__toggle {
  display: flex;
  align-items: center;
  color: var(--black);
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .conference-bo-agenda-info__toggle {
    justify-content: flex-end;
    height: var(--bo-header-elm-height);
  }
}

@media only screen and (min-width: 1200px) {
  .conference-bo-agenda-info__toggle {
    height: var(--bo-header-height);
  }
}

@media (hover: hover) {
  .conference-bo-agenda-info__toggle:hover {
    color: var(--main-color-dark);
  }
}

.conference-bo-agenda-info__toggle.open {
  color: var(--main-color-dark);
}

.conference-bo-agenda-info__toggle__label {
  margin-right: .25rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.conference-bo-agenda-info__container {
  color: var(--grey-darkest);
  background-color: var(--main-color-lighter);
  overflow: hidden;
  transition: all .3s ease-in-out;
}

@media only screen and (max-width: 639px) {
  .conference-bo-agenda-info__container {
    left: 0;
    width: 100%;
    box-shadow: -2px 2px 3px var(--black-alpha15);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .conference-bo-agenda-info__container {
    top: calc(var(--menu-height) + var(--bo-header-height) + var(--bo-header-elm-height));
    left: var(--vertical-menu-width);
  }
}

@media only screen and (max-width: 1199px) {
  .conference-bo-agenda-info__container {
    position: fixed;
    height: calc(var(--vh)*100 - (var(--menu-height) + var(--bo-header-height) + var(--bo-header-elm-height)));
    z-index: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .conference-bo-agenda-info__container {
    position: fixed;
    width: 470px;
    box-shadow: -2px 2px 3px var(--black-alpha15);
  }
}

.conference-bo-agenda-info__container__icon {
  position: absolute;
  right: 20px;
  bottom: 1rem;
  color: var(--main-color-light);
  z-index: 0;
}

.conference-bo-agenda-info__container.slide-content-enter-active {
  animation: slide-down .3s;
}

.conference-bo-agenda-info__container.slide-content-leave-active {
  animation: slide-down .3s reverse;
}

.conference-bo-agenda-info__container ::v-deep * {
  line-height: 1.25;
}

.conference-bo-agenda-info__content {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .conference-bo-agenda-info__content {
    height: 100%;
    padding: 50px 45px 30px 30px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .conference-bo-agenda-info__content {
    padding: 30px 30px 15px;
  }
}

.conference-bo-agenda-info__close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--main-color-dark);
  z-index: 2;
}

@media only screen and (min-width: 1200px) {
  .conference-bo-agenda-info__close {
    display: none;
  }
}

.conference-bo-agenda-info ::v-deep ul {
  margin-left: 20px;
  list-style-position: inside;
}

.conference-bo-agenda-info ::v-deep ul li {
  margin-bottom: .25rem;
  color: var(--main-color);
}

.conference-bo-agenda-info ::v-deep ul li span {
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
}

@keyframes slide-down {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

