













































































































































































































































































































@import '@/styles/mixins/index';

.idt-user-booking {
  position: relative;
  margin: 0 -1rem;
  border-bottom: 1px solid var(--grey-lighter);

  &__header {
    padding: 1rem 3rem;

    &__title {
      margin-bottom: .5rem;
      color: var(--black);
      font-weight: var(--font-weight-bold);
    }

    &__subtitle {
      margin-bottom: .5rem;
    }
  }

  &__next-timeslot-card, &__no-timeslot-card {
    position: absolute;
    top: 228px;
    left: 19%;
  }

  &__calendar {
    display: flex;
    user-select: none;

    &-col {
      flex: 1;
      background-color: var(--grey-lightest);

      &--navigation {
        flex: 0;
        min-width: 4.5rem;
      }

      &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 5rem;
        padding: 1rem;
        background-color: var(--white);
        border-top: 1px solid var(--grey-lighter);
        border-bottom: 1px solid var(--grey-lighter);

        &--nav {
          cursor: pointer;
        }
        &__weekday {
          font-size: var(--rem-size-14);
        }
        &__day {
          font-size: var(--rem-size-16);
          font-weight: var(--font-weight-bold);
        }
        &.first-page {
          cursor: default;
        }
      }

      &__slots {
        padding: .5rem .25rem;

        & > * + * {
          margin-top: .5rem;
        }
      }

      &__slot-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 2.2rem;
      }

      &__slot, &__empty-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      &__slot {
        background-color: var(--white);
        border: 1px solid var(--grey-light);
        border-radius: 0.5rem;
        color: var(--black);
        font-size: var(--rem-size-14);
        cursor: pointer;
      }

      &__empty-slot {
        width: 1.5rem;
        height: 2px;
        border: 1px solid var(--grey-light);
      }
    }

    &__view-more {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: .5rem 1rem 1rem 1rem;
      background-color: var(--grey-lightest);

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 34rem;
        height: 2.2rem;
        background-color: var(--white);
        border: 1px solid var(--grey-light);
        border-radius: 0.5rem;
        color: var(--black);
        font-size: var(--rem-size-14);
        cursor: pointer;
      }
    }
  }

  &__attendees {
    padding: 2rem 3rem;

    &__remove-button {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0;
    }
  }

  .slot-button:hover, .slot-button.selected {
    background-color: var(--main-color-lighter);
    border: 1px solid var(--main-color);
    color: var(--main-color);
  }
}
