
























































.menu-mobile-admin-chat {
  color: var(--secondary-color);
  margin-right: 20px;

  label {
    color: var(--secondary-color);
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
  }
}
