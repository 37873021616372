


































































































@import '@/styles/mixins/index';

.modal-answers {
  &__question {
    padding: 1.2rem 1.5rem;
    color: var(--white);
    background-color: var(--main-color-dark);

    &__header {
      display: flex;
      margin-bottom: 1.2rem;
      padding-bottom: 1.2rem;
      border-bottom: 1px solid var(--white);

      &__user {
        display: inline-block;
        vertical-align: middle;
        width: 75%;
        cursor: pointer;

        &__avatar {
          display: inline-block;
          margin-right: 0.85rem;
          background-color: var(--white);
          border-radius: 100%;
          box-shadow: 2px 2px 5px -1px var(--black);
          vertical-align: middle;
        }
        &__information {
          display: inline-block;
          width: calc(100% - 60px);
          vertical-align: middle;

          .idt-user-information{
            &__username{
              @include respond-to(max-medium) {
                display: block;
              }
            }
          }
        }
      }
      &__votes {
        display: inline-block;
        width: 25%;
        font-size: 1.05rem;
        vertical-align: middle;
        text-align: right;

        &__number {
          display: inline-block;
          vertical-align: middle;
        }
        &__label {
          display: inline-block;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        &__icon {
          display: inline-block;
          font-size: 1.6rem;
          vertical-align: middle;
        }
      }
    }
    &__content {
      &__text {
        margin-bottom: var(--rem-size-8);
        white-space: pre-wrap;
        word-break: break-word;
      }
      &__created {
        color: var(--grey-light);
        font-size: 0.9rem;
        font-weight: 700;
      }
    }
  }
}
