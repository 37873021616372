.event-sponsors {
  padding: 0;
}

.event-sponsors__subtitle {
  margin-bottom: 30px;
}

.event-sponsors__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .event-sponsors__container {
    padding: 3rem 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-sponsors__container {
    padding: 5rem 3rem;
  }
}

.event-sponsors__container--top-level {
  background: var(--grey-lighter);
}

.event-sponsors__container--level {
  padding: 25px 1rem;
}

.event-sponsors__section-ultimate {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  max-width: 1272px;
}

.event-sponsors__carousel-item ::v-deep .idt-simple-card {
  width: 100% !important;
  margin: 0;
}

.event-sponsors .idt-page-section__title {
  margin-bottom: 10px;
}

.event-sponsors ::v-deep .idt-carousel {
  margin-top: 0;
}

.event-sponsors ::v-deep .idt-carousel .slick-slide {
  padding: 0 10px 10px 10px;
}

