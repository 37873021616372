































































































































































































































































































@import '@/styles/mixins/index';

.conference-modal {
  display: flex;
  justify-items: center;
  flex-flow: column;
  width: 100%;

  &__content {
    display: flex;
    justify-content: center;
    width: 100%;

    &__block {
      flex: 1 1 auto;
      position: relative;
      width: 50%;

      @include respond-to(medium) {
        padding: 0 35px;
      }
      @include respond-to(large) {
        max-width: 600px;
      }

      &.has-separator:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        height: 100%;
        border-right: 1px solid var(--grey);
        transform: translateY(-50%);
      }
    }
  }
  &__footer {
    margin-top: 50px;
    text-align: center;
  }
  &__save-btn {
    min-width: 235px;
    height: 55px;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }
}
