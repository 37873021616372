

































































































































































































































































































































.idt-file-upload-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--rem-size-14) 0;
  color: var(--black);
  text-align: left;

  &__icon {

    &__preview {
      position: relative; // Used to overlay the progreess bar
      width: 2.5rem;
      height: 2.5rem;
      background-color: var(--grey-light);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: background-color 200ms ease, transform 200ms ease;
      will-change: transform; // Avoiding glitch effect on transited transform

      &:hover {
        transform: scale(5);
        z-index: 1; // Used to overlay the progreess bar
      }
    }
  }

  &__progress {
    flex-grow: 1;
    min-width: 0; // More info: https://stackoverflow.com/a/66689926
    margin: 0 var(--rem-size-22) 0 var(--rem-size-10);
    padding-bottom: 5px;
    font-weight: var(--font-weight-medium);

    &__label {
      display: flex;
      flex-grow: 2;
      font-weight: var(--font-weight-bold);
      line-height: var(--rem-size-32);
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
      transition: transform 200ms ease;
      will-change: transform; // Avoiding glitch effect on transited transform

      &.no-progress {
        transform: translateY(.3rem)
      }

      &.read-only {
        color: var(--grey-dark);
      }

      &__error {
        position: absolute;
        width: 100%;
        color: var(--secondary-color);
        font-weight: var(--font-weight-normal);
      }

      &__text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &__size {
        margin-left: auto;
        color: var(--grey-dark);
        font-weight: var(--font-weight-normal);
        white-space: nowrap;
      }

      button {
        margin: 0 var(--rem-size-12);
        color: var(--grey-dark);
        text-decoration: underline;
        transition: color 200ms ease;
        cursor: pointer;

        &:hover {
          color: var(--black);
        }
      }
    }

    ::v-deep [role='progressbar'] {
      height: .56rem;
      margin: 0;
      background-color: var(--grey-light);
      border-radius: 50px;

      .progress-meter {
        border-radius: 50px;
      }
    }
  }

  &__actions {
    flex-shrink: 0;
    width: 5.725rem;
    margin-left: auto;
    text-align: right;

    &__action {
      width: 2.5rem;
      background-color: var(--grey-light);
      border-radius: 50%;
      text-align: center;
      line-height: 2.5rem;
      transition: background-color 200ms ease;

      &:not([disabled]):hover {
        background-color: var(--grey);
        cursor: pointer;
      }

      &.idt-file-upload-item--hidden {
        display: none;
      }

      & + & {
        margin-left: .375rem;
      }

      ::v-deep .idea-ht-out {
        margin-top: 0.15rem;
      }
    }
  }
}
