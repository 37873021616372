































































































































::v-deep .switch-talk {
  margin-bottom: var(--rem-size-20);
}

.bo-vertical__title {
  margin-bottom: var(--rem-size-30);
  padding: 0 0.9375rem;
}
