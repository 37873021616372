.idt-live-preview {
  display: flex;
  width: 100%;
  height: 100%;
}

.idt-live-preview__video-container {
  width: 100%;
  height: 100%;
}

.idt-live-preview__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10%;
  border-radius: var(--global-radius);
  background-color: var(--grey-darker);
}

.idt-live-preview__overlay__message {
  color: var(--white);
  font-weight: var(--font-weight-medium);
  text-overflow: ellipsis;
  text-align: center;
}

.idt-live-preview ::v-deep .video-js {
  border-radius: var(--global-radius);
}

.idt-live-preview ::v-deep .video-js .vjs-control-bar {
  border-bottom-left-radius: var(--global-radius);
  border-bottom-right-radius: var(--global-radius);
}

