


















































@import '@/styles/mixins/index';

.idt-preview-modal {
  .modal-fullscreen {
    height: calc(var(--vh, 1vh) * 100);
    background: transparent;

    &__content {
      height: calc(var(--vh, 1vh) * 100);
      padding: 0;
      overflow: hidden;
    }
  }
  &__wrapper {
    width: 1000px;
    max-width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
  }
  &__header,
  &__footer {
    display: flex;
    align-items: center;
    height: var(--menu-height);
    padding: 0 var(--rem-size-32);
    background-color: var(--white);
  }
  &__header {
    justify-content: space-between;
    border-bottom: 1px solid var(--grey);

    &__close {
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    overflow: hidden;
    background: var(--grey-light);
  }
  &__preview,
  &__form {
    padding: var(--rem-size-16) var(--rem-size-32);
  }
  &__preview {
    @include respond-to(max-medium) {
      display: none;
    }
    @include respond-to(medium) {
      flex: 0 0 50%;
      overflow: auto;
    }
  }
  &__form {
    height: calc((var(--vh, 1vh) * 100) - (var(--menu-height) * 2));
    background: var(--white);
    overflow-y: auto;

    @include respond-to(max-medium) {
      width: 100%;
    }
    @include respond-to(medium) {
      flex: 0 0 50%;
    }
  }
  &__footer {
    justify-content: flex-end;
    gap: var(--rem-size-8);
    border-top: 1px solid var(--grey);
  }
  .idt-select-container {
    width: 100%;
  }
  .vs__dropdown-option--disabled {
    display: none !important;
  }
  // animation
  .fade-height-enter,
  .fade-height-leave-to {
    max-height: 0;
  }
  .fade-height-leave,
  .fade-height-enter-to {
    max-height: 100px;
  }
  .fade-height-enter-active,
  .fade-height-leave-active {
    transition: max-height .25s ease;
    overflow: hidden;
  }
}
