












































.conference-player-container {
  width: 100%;
  height: 100%;
}
