.skeleton {
  display: flex;
  align-items: center;
  padding: 1.6rem 2rem;
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
}

.skeleton__participants {
  display: flex;
  max-width: 85px;
}

.skeleton__participants__avatar {
  width: 37px;
  height: 37px;
  background-color: var(--grey);
  border-radius: 50%;
  animation: glowing 1300ms infinite;
}

.skeleton__details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 15px;
}

.skeleton__details__title {
  max-width: 250px;
  height: 15px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__details__description {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.skeleton__details__description__date {
  width: 155px;
  height: 15px;
  margin-bottom: 5px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__details__description__tag {
  width: 50px;
  height: 15px;
  margin-left: 1rem;
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__duration {
  width: 12rem;
  padding-left: 2rem;
  border-left: 2px solid var(--grey-lighter);
}

.skeleton__duration__label {
  width: 50px;
  height: 15px;
  margin-bottom: 5px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__duration__value {
  width: 60px;
  height: 15px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }

  25% {
    background-color: var(--grey);
  }

  50% {
    background-color: var(--grey-light);
  }

  75% {
    background-color: var(--grey-lighter);
  }

  100% {
    background-color: var(--grey-light);
  }
}

