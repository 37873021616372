.idt-register .register__page {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 150px);
}

@media only screen and (min-width: 640px) {
  .idt-register .register__page {
    padding: 40px 0;
  }
}

@media only screen and (min-width: 992px) {
  .idt-register .register__page {
    align-items: center;
  }
}

.idt-register .register__container {
  flex: 1 1 auto;
  position: relative;
  text-align: center;
  max-width: 100vw;
}

@media only screen and (max-width: 639px) {
  .idt-register .register__container {
    padding: 1.5rem 0 4.25rem;
  }
}

@media only screen and (min-width: 640px) {
  .idt-register .register__container {
    padding: 2rem 0 4.75rem;
  }
}

.idt-register .register__container .register__title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.5em;
  text-align: left;
}

.idt-register .register__container .account__basic .account__agreements__container {
  display: flex;
  padding: .25rem;
}

.idt-register .register__container .account__basic .account__agreements__container.required-field {
  border: 1px solid var(--secondary-color);
  border-radius: var(--global-radius);
}

.idt-register .register__container .account__basic .account__agreements label {
  flex: 1 1 auto;
  text-align: left;
}

.idt-register .register__container .account__basic .account__agreements input[type=checkbox] {
  flex: 0 0 auto;
  margin: .25rem .5rem 0 0;
}

.idt-register .register__container .account__complete textarea {
  height: 50px;
  resize: none;
}

.idt-register .register__container .account__preferences .intl-tel-input {
  display: block;
  flex: 1 1 auto;
  width: 100%;
  margin-top: 1rem;
}

.idt-register .register__container .account__preferences .intl-tel-input .country-list {
  z-index: 200;
}

.idt-register .register__container .account__availabilities-expanded .idt-da__block {
  border: 1px solid var(--grey-light);
}

.idt-register .register__container .account__availabilities-expanded .idt-da__block__content {
  max-height: 400px;
  overflow-y: auto;
}

.idt-register .register__container .account__availabilities-expanded .idt-da__block .select-block {
  width: 42%;
}

.idt-register .ticket-slide {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

@media only screen and (min-width: 640px) {
  .idt-register .ticket-slide {
    padding: 0 2rem;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1441px) {
  .idt-register .ticket-slide {
    margin: -6rem auto;
  }
}

@media only screen and (min-width: 1442px) {
  .idt-register .ticket-slide {
    flex-direction: column;
  }
}

.idt-register .ticket-slide .register__slide__title {
  margin-bottom: 0;
}

@media only screen and (max-width: 1441px) {
  .idt-register .ticket-slide .register__slide__title {
    display: none;
  }
}

.idt-register .register__footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: var(--white);
  border-top: 1px solid var(--grey);
  z-index: 10;
}

@media only screen and (min-width: 640px) {
  .idt-register .register__footer {
    padding: 0 1.15rem;
  }
}

@media only screen and (max-width: 639px) {
  .idt-register .register__footer__left,
  .idt-register .register__footer__right {
    flex: 1 1 auto;
    margin: 0 1rem;
  }
}

.idt-register .register__footer .register__next,
.idt-register .register__footer .register__previous {
  margin-right: auto;
  border: 2px solid var(--main-color);
  text-transform: uppercase;
  transition: all var(--default-duration) ease-in-out;
}

@media only screen and (max-width: 639px) {
  .idt-register .register__footer .register__next,
  .idt-register .register__footer .register__previous {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .idt-register .register__footer .register__next,
  .idt-register .register__footer .register__previous {
    width: 40%;
  }
}

.idt-register .register__footer .register__next.hidden,
.idt-register .register__footer .register__previous.hidden {
  pointer-events: none;
  visibility: hidden;
}

.idt-register .register__footer .register__next:focus,
.idt-register .register__footer .register__previous:focus {
  outline: 0;
}

.idt-register .register__footer .register__next {
  color: var(--white);
  background-color: var(--main-color);
}

.idt-register .register__footer .register__next:hover {
  color: var(--main-color);
  background-color: rgba(0,0,0,0);
}

.idt-register .register__footer .register__previous {
  padding: 0;
  color: var(--grey-darker);
  background-color: rgba(0,0,0,0);
  border: solid 2px var(--grey-darker);
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .idt-register .register__footer .register__previous {
    margin-bottom: 10px;
  }
}

.idt-register .register__footer .register__previous:hover {
  color: var(--white);
  background-color: var(--grey-darker);
}

@media only screen and (min-width: 480px) {
  .idt-register .register__footer .register__previous+.register__next {
    margin-left: 20px;
  }
}

.idt-register .register__footer .button {
  min-width: 134px;
  height: 42px;
  margin: 0;
  font-weight: var(--font-weight-bold);
}

@media only screen and (max-width: 479px) {
  .idt-register .ticket-payment-success {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 479px) {
  .idt-register .ticket-payment-success {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 479px) {
  .idt-register .payment-loading {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 639px) {
  .step-viewer {
    display: none;
  }
}

.step-viewer__step {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  font-size: 0;
  vertical-align: middle;
}

.step-viewer__step.done .number {
  background-color: var(--main-color-lighter);
  color: var(--main-color);
}

.step-viewer__step:last-child {
  margin-right: 20px;
}

.step-viewer__step .number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: #fff;
  background-color: var(--grey);
  border-radius: 50%;
  font-size: 15px;
  font-weight: var(--font-weight-medium);
  line-height: 15px;
  vertical-align: middle;
}

.step-viewer__step .name {
  font-weight: var(--font-weight-medium);
  vertical-align: middle;
}

@media only screen and (max-width: 991px) {
  .step-viewer__step .name {
    display: none;
  }
}

@media only screen and (min-width: 992px)and (max-width: 1199px) {
  .step-viewer__step .name {
    font-size: var(--rem-size-14);
  }
}

@media only screen and (min-width: 1200px) {
  .step-viewer__step .name {
    font-size: var(--rem-size-16);
  }
}

.step-viewer__step ::v-deep .ideacon {
  margin-left: 24px;
  color: var(--grey);
}

