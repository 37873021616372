





































































































































































































































































































































@import '@/styles/mixins/index';

::v-deep .idt-dropdown {
  width: 100%;
  &--pane {
    width: 100%;
    padding: 0;
  }
}

.input-dropdown {
  position: relative;

  &__input {
    &__container {
      position: relative;
      cursor: pointer;
    }
    &.div {
      min-height: 53px;
      pointer-events: auto;

      .tag-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .tag-item {
          height: 29px;
          color: var(--white);
          background: var(--main-color);
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          font-weight: 500;
          line-height: 28px;
          margin: 2px;
          padding: 0 5px;
          display: inline-block;
          border: 1px solid var(--main-color);
          border-radius: 3px;

          .remove-button {
            margin: 0 0 0 5px;
            padding: 0;
            border: none;
            background: 0 0;
            cursor: pointer;
            vertical-align: middle;
          }
        }
      }
    }
  }
  &__button {
    margin: 0;
    color: var(--main-color);
    background-color: var(--white);
    border-color: var(--main-color);
    text-transform: uppercase;

    &.dropdown:after {
      border-color: var(--main-color) transparent transparent;
    }
    &:hover {
      color: var(--main-color);
      background-color: var(--white);
    }
  }
  .idt-dropdown--pane {
    max-height: 50vh;
    margin: -2.75rem 0 0 -0.25rem;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 5px var(--black-alpha20);
    overflow-y: auto;

    &__header {
      position: relative;
      min-height: 40px;
      color: var(--main-color);
      background-color: var(--main-color-alpha10);
      font-size: 0.8rem;
      font-weight: 500;
      text-transform: uppercase;

      &:not(.counter):not(.checkbox) {
        padding: 0.7rem 1rem;
      }
      &.counter {
        padding: 0.7rem 2.5rem 0.7rem 1rem;
      }
      .count {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 1rem;
        color: var(--main-color);
        transform: translateY(-50%);
      }
      &.checkbox {
        padding: 0.7rem 3rem 0.7rem 1rem;
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 1.5rem;
          width: 16px;
          height: 16px;
          border: 2px solid var(--white);
          border-radius: 100%;
          box-shadow: 0 0 0 2px var(--main-color);
          transform: translateY(-50%);
        }

        &.checked {
          &:before {
            background-color: var(--main-color);
          }
        }
      }
    }
    button {
      width: 100%;
      height: 40px;
      padding: 0.7rem 1rem;
      border-radius: 0;
      font-family: var(--text-font-family);
      text-align: left;
      cursor: pointer;

      &.add {
        position: relative;
        color: var(--main-color);
        background-color: var(--main-color-alpha10);
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: uppercase;

        i {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
        }
      }
    }
    &__checkbox {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0.7rem 3rem 0.7rem 1rem;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        width: 16px;
        height: 16px;
        border: 2px solid var(--white);
        border-radius: 100%;
        box-shadow: 0 0 0 2px var(--main-color);
        transform: translateY(-50%);
      }
      input[type=radio]:checked + &:before,
      input[type=checkbox]:checked + &:before {
        background-color: var(--main-color);
      }
    }
    input[type=radio],
    input[type=checkbox] {
      display: none;
    }
  }
  &__title {
    background-color: var(--main-color-alpha20);
    color: var(--main-color);
  }
  .menu {
    max-width: 100%;
    font-size: 0.8rem;
    overflow-x: hidden;

    &:not(.multiple) {
      max-height: 200px;
      overflow-y: auto;
    }
    li {
      &:not(:last-child) {
        a,
        button {
          border-bottom: 1px solid var(--grey-lighter);
        }
      }
      a,
      button {
        color: var(--grey-darkest);

        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
}

input[type=checkbox].checked + .dropdown-pane__checkbox:before {
  background-color: var(--main-color);
}

.button-dropdown {
  display: inline-block;
}

.modal-account {
  max-width: 500px;

  &__back.hollow {
    position: fixed;
    margin: 0;
    padding: 0.6rem 1rem;
    color: var(--grey-darker);
    border-color: var(--grey-darker);
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;

    @include respond-to(max-medium) {
      top: 0.5rem;
      left: 0.5rem;
      z-index: 100;
    }
    @include respond-to(medium) {
      top: 1.875rem;
      left: 1.875rem;
    }

    &:hover {
      color: var(--grey-darkest);
      border-color: var(--grey-darkest);
    }
  }
  &__textarea {
    width: 100%;
  }
  &__input {
    padding-bottom: 2rem;
    margin-bottom: 0px;
  }
  .tags {
    padding: 0.625rem;
    height: 150px;
    background-color: var(--white);
    border-color: var(--grey);
    border-radius: var(--global-radius);
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &.focused {
      box-shadow: none;
    }
    .input {
      height: 29px;
      color: var(--main-color);

      &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: var(--main-color);
      }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: var(--main-color);
        opacity: 1;
      }
      &::placeholder {
        color: var(--main-color);
      }
    }
  }
}

.custom-mail {
  &__textarea {
    margin-bottom: 0px;
  }
}

