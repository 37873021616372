.items {
  display: flex;
  justify-items: center;
  flex-flow: column;
  width: 100%;
}

.items__input {
  padding-right: 0px;
}

.items__label {
  font-weight: var(--font-weight-medium);
}

.items__inputs {
  height: 50px;
  margin-bottom: 10px;
}

.items__inputs__item-button .button {
  width: 122px;
  height: 48px;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
}

.items__inputs__text {
  padding-right: 0px;
}

.items__inputs__text__area {
  overflow: auto;
}

.items__inputs__content {
  height: 20px;
}

.items__inputs__content__delete {
  margin-right: 15px;
  color: var(--red);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
}

.items__inputs__content__delete:hover {
  cursor: pointer;
  filter: brightness(75%);
}

.items__inputs__content__delete__icon {
  color: var(--red);
}

.items__inputs__content .button {
  margin: 0 0 1.75rem 0;
}

.items__empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-height: 500px;
  margin: 40px 0px 0px 0px;
  border: solid 1px var(--grey-light);
}

.items__empty-list__text {
  font-family: var(--text-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-light);
  line-height: 22px;
  text-align: center;
}

