










































































































































































































































































































@import '@/styles/mixins/index';

.fo-project-section-edit {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  max-width: 1160px;
  min-height: 390px;
  margin: auto;
  padding: 0;

  &:not(:last-child) {
    border-bottom: 2px dashed var(--grey);
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    background-color: var(--white-alpha80);
    z-index: 7;

    &__message-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      min-width: 33%;
      padding: 4%;
      background-color: var(--white);
      border-radius: var(--global-radius);
      box-shadow: 0px 2px 9px 0px var(--black-alpha50);

      &__text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &__icon {
          color: var(--yellow);
        }

        &__text {
          margin-left: var(--rem-size-10);
          span {
            font-weight: var(--font-weight-bold);
          }
        }
      }

      &__cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--rem-size-5);
        padding-left: var(--rem-size-10);
        color: var(--white);
        background-color: var(--grey-dark);
        border-radius: 30px;
        transform: translateY(40%);
        cursor: pointer;

        &__icon ::v-deep .ideacon {
          margin-left: 7px;
        }
      }
    }
  }

  &__actions {
    position: sticky;
    user-select: none;
    z-index: 1;

    @include respond-to(max-medium) {
      display: block;
      top: var(--menu-height);
      margin-bottom: var(--rem-size-16);
    }
    @include respond-to(medium) {
      display: flex;
      justify-content: flex-end;
      top: calc(var(--menu-height) + 1rem);
      margin: var(--rem-size-16) 0;
      padding: 0 var(--rem-size-48);
    }

    &__status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background-color: var(--white);
      border: 1px solid var(--grey);
      padding: var(--rem-size-5) var(--rem-size-9) var(--rem-size-5) var(--rem-size-10);
      @include respond-to(max-medium) {
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      &__avatar {
        position: absolute;
        left: -60px;
        @include respond-to(small-medium) {
          display: none;
        }
      }

      &__title {
        margin-right: var(--rem-size-24);
        font-weight: var(--font-weight-bold);
        white-space: nowrap;
        @include respond-to(medium) {
          margin-right: var(--rem-size-18);
        }
        @include respond-to(max-medium) {
          display: none;
        }
      }

      &__wording {
        @include respond-to(max-medium) {
          display: none;
        }
      }

      &__icon {
        width: 19px;
        height: 19px;
        margin-left: var(--rem-size-10);
        color: var(--white);
        border-radius: 50%;
        text-align: center;
        line-height: 19px;
        @include respond-to(max-medium) {
          margin-left: 0;
        }
      }
    }

    &__widget {
      display: flex;
      justify-content: space-between;
      gap: var(--rem-size-8);
      padding: var(--rem-size-7) var(--rem-size-9) var(--rem-size-7) var(--rem-size-14);
      color: var(--white);
      background-color: var(--grey-darkest);

      @include respond-to(max-medium) {
        padding: var(--rem-size-8) var(--rem-size-12);
      }
      @include respond-to(medium) {
        border-radius: var(--rem-size-24);
      }
      &__update {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--white);
        font-size: var(--rem-size-13);

        &__wording {
          font-weight: var(--font-weight-bold);
          line-height: var(--rem-size-13);
        }
        @include respond-to(max-medium) {
          margin-bottom: 5px;
        }
      }

      &__buttons {
        display: flex;
        justify-content: end;
        gap: var(--rem-size-8);
      }
    }
  }
}
