.fo-project-bar-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fo-project-bar-actions__switch ::v-deep .idt-switch-small__label {
  color: var(--white);
  font-weight: var(--font-weight-bold);
}

