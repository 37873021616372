


































































@import '@/styles/mixins/index';

.mentor {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: var(--white);
  border-radius: var(--global-radius);

  &:hover {
    cursor: pointer;
  }
  &__left {
    display: flex;
    width: 50%;

    &__image {
      margin: 0 10px 0 20px;
    }
    &__infos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 80%;

      &__name {
        max-width: 100%;
        color: var(--black);
        font-family: var(--title-font-family);
        font-size: var(--rem-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--rem-size-20);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__job-company {
        color: var(--black);
        font-family: var(--text-font-family);
        font-size: var(--rem-size-12);
      }
    }
  }

  &__separator {
    position: absolute;
    right: 50%;
    height: 100%;
    color: var(--grey-lighter);
    border: 1px solid;
  }

  &__right {
    display: flex;

    &__message {
      margin-right: var(--rem-size-10);
    }
    &__book {
      margin-right: var(--rem-size-10);
    }
  }
}
