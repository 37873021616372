









































































































.idt-create-survey-multiple {

  .idt-checkbox {
    margin-bottom: var(--rem-size-8);
    padding: 3px .5rem;
    border: 2px solid var(--grey-light);
    border-radius: var(--global-radius);
    box-shadow: none;
  }

  & ::v-deep .idt-checkbox {

    label {
      padding: var(--rem-size-8);
      color: var(--black);
      font-weight: var(--font-weight-medium);
    }

    .box {
      margin-right: var(--rem-size-18);
      background: var(--grey);
    }
  }

  &__title {
    font-size: var(--rem-size-12);
    font-weight: var(--font-weight-bold);
    color: var(--black);
  }

  &__answers {
    margin-bottom: 1rem;

    &__item {

      &.ghost {
        cursor: grab;
        opacity: 0.5;
        background: var(--grey-lighter);
      }

      &__remove {
        position: relative;
        cursor: pointer;

        & ::v-deep .ideacon {
          position: relative !important;
          color: var(--grey-dark) !important;
        }
      }
    }
  }
}
