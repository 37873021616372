.idt-fo-card__header__title {
  display: flex;
  align-items: center;
  margin-right: .5rem;
}

.idt-fo-card__header__title i {
  margin-right: .5rem;
  color: var(--main-color);
  font-size: var(--rem-size-20);
}

.idt-fo-card__header .gamification-points {
  margin: auto 0;
}

.idt-fo-card__content {
  font-size: var(--rem-size-14);
}

.idt-fo-card__footer>div,
.idt-fo-card__footer>button {
  min-width: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.idt-fo-card__footer__number {
  color: var(--grey-dark);
  font-style: italic;
  flex-grow: 2;
}

.idt-fo-card__footer__view-more {
  color: var(--secondary-color);
  font-weight: var(--font-weight-bold);
  text-align: end;
}

