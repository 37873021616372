.idt-emoji-picker__emoji-button:hover {
  --color2: var(--emoji-color);
}

.idt-emoji-picker__picker-container {
  position: relative;
}

.idt-emoji-picker__picker-container .emoji-mart {
  position: absolute;
  bottom: 40px;
  right: 0;
  z-index: 2;
}

