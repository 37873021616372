













































































































































































































































.idt-create-survey-question {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--grey-light);
  border-left: 4px solid var(--main-color);
  border-radius: var(--global-radius);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      cursor: pointer;

      &.idt-create-survey-question__header__duplicate {
        margin-left: auto;
      }

      &.idt-create-survey-question__header__delete {
        margin-left: 1rem;
      }
    }

    & ::v-deep .ideacon {
      color: var(--grey-dark);
    }
  }

  &__content {
    padding-top: 1rem;

    .idt-select-container {
      display: inline-block;
      min-width: 300px;
      padding-bottom: 0;

      & ::v-deep label {
        margin-bottom: .25rem;
        font-weight: var(--font-weight-bold);
        color: var(--black);
      }
    }
  }
}
