


















































@import '@/styles/mixins/index';

.bo-page {
  // Mobile button to display vertical left menu
  &__side-button {
    @include respond-to(max-medium) {
      display: block;
      position: fixed;
      top: 1.15rem;
      left: 1rem;
      width: 44px;
      height: 34px;
      margin: 0;
      color: var(--main-color-light);
      text-align: center;
      z-index: 1010;
      transition: all 0.3s ease;

      &.open {
        transform: rotate(180deg);
      }
      i {
        font-size: 2rem;
      }
    }
    @include respond-to(medium) {
      display: none;
    }
  }
  &__container {
    @include respond-to(max-medium) {
      transform: translateX(0);
      transition: all 0.3s ease;

      &.open {
        transform: translateX(90px);
      }
    }
    @include respond-to(medium) {
      margin-left: 90px;
    }
  }
  &__content {
    min-height: calc(100vh - (70px + 120px));

    @include respond-to(max-medium) {
      padding: 2rem 0.5rem;
    }
    @include respond-to(medium-large) {
      padding: 1rem;
    }
    @include respond-to(large-xlarge) {
      padding: 2rem 2rem 6rem;
    }
    @include respond-to(xlarge) {
      padding: 2rem 3rem 6rem;
    }
  }
  hr {
    max-width: 100%;
    border-color: var(--grey);
  }
}
