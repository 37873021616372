.talk-question-card {
  border-radius: var(--global-radius);
  padding: 8px;
  background-color: var(--grey-lighter);
  color: var(--black);
  font-size: 16px;
}

.talk-question-card__text {
  line-height: 1.2;
  white-space: pre-line;
  word-break: break-word;
}

.talk-question-card--is-author {
  border: 1px solid var(--main-color);
}

.talk-question-card--is-answer {
  border: none;
  background-color: var(--white);
}

.talk-question-card--highlighted {
  border-left: 3px solid var(--main-color);
}

.talk-question-card__content {
  display: flex;
}

.talk-question-card__center {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 7px;
  padding-top: .1rem;
  font-size: 16px;
}

.talk-question-card__right {
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-bold);
  margin-left: 8px;
}

.talk-question-card__right .vote-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 3px;
  cursor: pointer;
}

.talk-question-card__right .vote-button:hover {
  background-color: var(--grey);
}

.talk-question-card__right .votes-number {
  display: flex;
  justify-content: center;
}

.talk-question-card__right .upVote:hover,
.talk-question-card__right .upVote.has-voted {
  color: var(--main-color);
}

.talk-question-card__right .downVote:hover,
.talk-question-card__right .downVote.has-voted {
  color: var(--red);
}

.talk-question-card__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--rem-size-8);
  margin-top: var(--rem-size-8);
}

.talk-question-card__author-name,
.talk-question-card .idt-user-avatar {
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.talk-question-card__answers {
  display: flex;
  flex-direction: column;
  margin-top: var(--rem-size-8);
}

.talk-question-card__answers>.talk-question-card {
  margin-bottom: 10px;
}

.talk-question-card__answers>.talk-question-card:last-of-type {
  margin-bottom: 0;
}

.talk-question-card__answer-input ::v-deep .idt-textarea {
  margin-top: 10px;
}

.talk-question-card--no-margin {
  margin: 0;
}

