.event-baseline {
  padding: 2.5rem 50px;
}

.event-baseline__container {
  margin: auto;
}

.event-baseline__baseline {
  margin-bottom: var(--rem-size-16);
  font-size: 1.4rem;
  text-transform: uppercase;
}

.event-baseline__over {
  font-size: 1.25rem;
  text-align: center;
}

.event-baseline__over.after-btn {
  margin-top: 1rem;
}

.event-baseline__over__text {
  color: var(--main-color);
}

.event-baseline__button {
  width: 100%;
  max-width: 430px;
  margin: auto;
}

