.idt-survey-conference-item {
  display: flex;
  align-items: center;
}

.idt-survey-conference-item__img {
  height: 50px;
  width: 75px;
  margin-right: .5rem;
}

.idt-survey-conference-item__start {
  margin-bottom: .25rem;
  font-size: var(--rem-size-14);
  color: var(--black);
}

.idt-survey-conference-item__title {
  margin-bottom: .25rem;
  font-size: 1rem;
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-survey-conference-item__slot-end {
  margin-left: auto;
}

