.idt-panel-filter {
  --background-color: var(--grey-lighter);
}

@media only screen and (max-width: 1023px) {
  .idt-panel-filter {
    --background-color: var(--white);
    background-color: var(--background-color);
  }
}

.idt-panel-filter .open-close-enter-active,
.idt-panel-filter .open-close-leave-active {
  transition: opacity .5s;
}

.idt-panel-filter .open-close-enter,
.idt-panel-filter .open-close-leave-to {
  opacity: 0;
}

.idt-panel-filter__update-button {
  position: fixed;
  bottom: 20px;
  left: 15%;
  width: 290px;
  height: 45px;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 5em/5em;
  box-shadow: 0px 3px 6px var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  visibility: initial;
  z-index: 20;
}

.idt-panel-filter__advanced-filter {
  height: 0;
  margin: var(--rem-size-15) var(--rem-size-10) 0 0;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-28);
  font-weight: var(--font-weight-bold);
  visibility: hidden;
}

@media only screen and (max-width: 1023px) {
  .idt-panel-filter__advanced-filter {
    height: 100%;
    visibility: initial;
  }
}

.idt-panel-filter__search {
  height: 40px;
  margin-bottom: var(--rem-size-10);
  background-color: var(--white);
  border: solid 1px var(--grey-light);
  border-radius: var(--global-radius);
}

@media only screen and (max-width: 1023px) {
  .idt-panel-filter__search {
    height: 0;
    visibility: hidden;
  }
}

.idt-panel-filter__search:before {
  content: "";
  display: block;
  position: absolute;
  top: var(--rem-size-18);
  left: 10px;
  color: var(--black);
  font-family: "ideation-icons";
  font-size: 1rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.idt-panel-filter__search__input {
  width: 90%;
  height: 38px;
  margin: 0 0 var(--rem-size-10) var(--rem-size-15);
  color: var(--black);
  border: none;
  font-size: var(--rem-size-16);
}

.idt-panel-filter__search__input::placeholder {
  color: var(--black);
}

.idt-panel-filter__search__input:focus {
  border: none;
}

::v-deep .idt-panel-filter__select {
  display: flex;
  align-items: center;
  margin-bottom: var(--rem-size-10);
}

::v-deep .idt-panel-filter__select label {
  margin-right: var(--rem-size-16);
  margin-left: var(--rem-size-10);
  color: var(--black);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

::v-deep .idt-panel-filter__select .v-select {
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

::v-deep .idt-panel-filter__select .v-select .vs__dropdown-toggle {
  background: var(--white);
}

::v-deep .idt-panel-filter__select .v-select .vs__search {
  display: none !important;
}

.idt-panel-filter__buttons {
  position: sticky;
  top: 0;
  background-color: var(--background-color);
  z-index: 20;
}

.idt-panel-filter__buttons__button {
  width: 100%;
  height: var(--rem-size-35);
  margin-bottom: 3px;
  border-radius: var(--global-radius);
  cursor: pointer;
  text-align: left;
}

.idt-panel-filter__buttons__button.active {
  background-color: var(--main-color);
}

.idt-panel-filter__buttons__button.active:hover {
  background-color: var(--main-color);
}

.idt-panel-filter__buttons__button.reset {
  margin: var(--rem-size-16) 0;
  border: var(--rem-size-1) var(--main-color) solid;
}

.idt-panel-filter__buttons__button.reset .idt-panel-filter__buttons__button__title {
  color: var(--main-color);
  text-align: center;
}

.idt-panel-filter__buttons__button:hover {
  background-color: var(--grey-light);
}

.idt-panel-filter__buttons__button__title {
  margin-left: var(--rem-size-10);
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
}

.idt-panel-filter__buttons__button__title.active {
  color: var(--white);
}

.idt-panel-filter__buttons__selected {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--rem-size-4);
  width: 100%;
  margin: 0 0 var(--rem-size-8) var(--rem-size-4);
}

@media only screen and (max-width: 1023px) {
  .idt-panel-filter__buttons__selected {
    display: none;
  }
}

.idt-panel-filter__filters {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.idt-panel-filter__filters__filter {
  margin-right: var(--rem-size-15);
  margin-bottom: var(--rem-size-15);
}

.idt-panel-filter__filters__filter__empty {
  margin: -5px 0 var(--rem-size-10) var(--rem-size-10);
  color: var(--grey-dark);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.idt-panel-filter__filters__filter__values {
  margin-left: var(--rem-size-10);
  padding-top: var(--rem-size-15);
}

.idt-panel-filter__filters__filter__title {
  position: sticky;
  top: 0;
  z-index: 10;
  margin-left: var(--rem-size-10);
  color: var(--black);
  background-color: var(--background-color);
  border-bottom: solid 1px var(--grey-dark);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.idt-panel-filter__filters__filter__category {
  display: flex;
  margin-bottom: var(--rem-size-10);
  color: var(--black);
  cursor: pointer;
}

.idt-panel-filter__filters__filter__category__label {
  flex: 0 0 auto;
  width: 210px;
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-normal);
  overflow-wrap: break-word;
}

@media only screen and (max-width: 1023px) {
  .idt-panel-filter__filters__filter__category__label {
    padding-left: var(--rem-size-12);
  }
}

@media only screen and (max-width: 1023px) {
  .idt-panel-filter__filters__filter__category__icon {
    padding-right: var(--rem-size-18);
  }
}

.idt-panel-filter__filters__filter__value {
  display: flex;
  margin-bottom: var(--rem-size-10);
  margin-left: 0;
}

.idt-panel-filter__filters__filter__value:hover {
  cursor: pointer;
}

.idt-panel-filter__filters__filter__showmore {
  margin-left: var(--rem-size-10);
  color: var(--main-color);
  cursor: pointer;
  text-decoration: underline;
}

.idt-panel-filter__filters__filter__showmore:hover {
  color: var(--main-color-dark);
}

.idt-panel-filter__filters__label {
  flex: 0 0 auto;
  width: 180px;
  padding-left: var(--rem-size-8);
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-normal);
  overflow-wrap: break-word;
}

.idt-panel-filter__filters__nb {
  flex: 0 0 auto;
  padding-left: var(--rem-size-16);
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-normal);
}

.idt-panel-filter__filters__container {
  flex: 0 0 auto;
  height: var(--rem-size-28);
  padding-left: 0;
  padding-right: 0;
}

.idt-panel-filter__filters__container__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--rem-size-20);
  height: var(--rem-size-20);
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 3px;
}

.idt-panel-filter__filters__container__checkbox:after {
  content: "";
  display: none;
  content: "";
  color: var(--white);
  font-family: "ideation-icons";
  font-size: .85rem;
}

input.checked~.idt-panel-filter__filters__container__checkbox {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}

input.checked~.idt-panel-filter__filters__container__checkbox:after {
  display: block;
}

.idt-panel-filter__filters__container input[type=checkbox] {
  display: none;
}

::v-deep .vs__search {
  display: inline !important;
}

