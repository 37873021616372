.one-choice-field {
  max-width: 500px;
  padding: .2rem 0;
  background: none;
  box-shadow: none;
  border: none;
  text-align: left;
}

.one-choice-field ::v-deep .box {
  background: var(--white);
}

.one-choice-field__container {
  width: 100%;
}

.one-choice-field__separator {
  position: relative;
  right: 10px;
  border-left: 1px solid var(--secondary-color);
}

.one-choice-field__separator .separator-error {
  margin-left: 10px;
}

.one-choice-field__conditional-wrapper {
  margin-bottom: 20px;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
}

