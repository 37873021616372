






















































































.talk {
  height: 128px;
  width: 100%;
}
.vue-grid-item {
  //TODO modifier l'animation d'apparition de talk de manière pertinente
  // transition: all 200ms ease;
  transition: none;
}

.vue-grid-layout {
  transition: none;
}
