.idt-survey-result-nps__progressbar {
  display: flex;
  align-items: center;
  height: 25px;
  width: 100%;
  margin-top: var(--rem-size-10);
  overflow: hidden;
  border-radius: var(--global-radius);
}

.idt-survey-result-nps__progressbar__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-12);
  color: var(--white);
}

.idt-survey-result-nps__progressbar__item.detractors {
  background-color: var(--secondary-color);
}

.idt-survey-result-nps__progressbar__item.passives {
  background-color: var(--yellow);
}

.idt-survey-result-nps__progressbar__item.promoters {
  background-color: var(--main-color);
}

.idt-survey-result-nps__labels {
  margin-top: var(--rem-size-10);
}

.idt-survey-result-nps__labels__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.idt-survey-result-nps__labels__item.detractors {
  color: var(--secondary-color);
}

.idt-survey-result-nps__labels__item.passives {
  color: var(--yellow);
}

.idt-survey-result-nps__labels__item.promoters {
  color: var(--main-color);
}

