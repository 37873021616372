







































































.fo-project-bar-votes {
  &__content {
    padding: var(--rem-size-16);

    &__no-votes {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--rem-size-16);

      &__text {
        margin-top: var(--rem-size-8);
        font-size: var(--rem-size-12);

      }
    }

    &__votes {
      &__vote {
        margin-bottom: var(--rem-size-8);
        padding: var(--rem-size-8);
        border-radius: 23px;
        text-align: center;
        font-weight: var(--font-weight-bold);
        font-size: var(--rem-size-18);

        @for $i from 0 through 2 {
          &--color#{$i} {
            border: 1px solid var(--project-vote-#{$i});
            color: var(--project-vote-#{$i});
          }
        }
      }
    }
  }
}
