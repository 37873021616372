













































































































@import '@/styles/mixins/index';

.page {
  &__header {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: var(--menu-height);
    background-color: var(--white);

    @include respond-to(max-slarge) {
      z-index: 1000;
    }
    @include respond-to(slarge) {
      z-index: 10001;
    }
  }
  &__alerts {
    position: fixed;
    right: 0;
    top: var(--menu-height);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 564px;
    max-height: calc(100vh - var(--menu-height));
    z-index: 10000;
    pointer-events: none;

    .idt-alerts__list {
      pointer-events: auto;
    }
  }
  &__content {
    margin-top: var(--menu-height);

    &:not(.no-footer) {
      @include respond-to(max-medium) {
        min-height: calc((var(--vh, 1vh) * 100) - var(--menu-height) - 250px);
      }
      @include respond-to(medium) {
        min-height: calc((var(--vh, 1vh) * 100) - var(--menu-height) - 192px);
      }
    }
    &.no-footer {
      min-height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
    }
  }
}
