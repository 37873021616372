


















































































































.display-field {
  padding: 20px;

  &__label {
    font-weight: bold;
  }
  .project-display-field-btn {
    display: flex;
    align-items: center;
    height: 60px;
    max-width: 300px;
    margin-top: 0.5em;
    border-radius: var(--global-radius);
    text-align: left;
    cursor: pointer;

    &--download {
      color: var(--white);
      background-color: var(--main-color);
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--main-color-dark);
      }
    }
    &__icon {
      flex: 0 0 auto;
      padding: 0 1.25rem;
    }
    &__separator {
      flex: 0 0 auto;
      width: 1px;
      height: 100%;
      margin-right: 1.25rem;
      background-color: var(--black-alpha10);
    }
    &__info {
      flex: 1 1 auto;
      max-width: calc(100% - 60px);
      padding: 0 1.25rem 0 0;
    }
    &--download &__info {
      max-width: calc(100% - 85px);
    }
    &__label {
      display: block;
      font-weight: var(--font-weight-medium);
      line-height: 1.2;
      word-break: break-word;
    }
    &--download &__label {
      font-size: 1.125rem;
    }
    &__file-name {
      display: block;
      font-size: 0.75rem;
      font-style: italic;
      font-weight: var(--font-weight-light);
      line-height: 1.2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
