

















































































































@import '@/styles/mixins/index';

.embedded-solution-player {
  height: 100%;

  &__video-container {
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - (var(--conference-footer-height) + var(--menu-height))) !important;

    & ::v-deep .video-js {
      width: 100%;
      height: 100%;
    }
  }
}
