.idt-text-copy {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: var(--grey-darkest);
  background: var(--grey-lightest);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-style: italic;
  text-align: left;
  text-overflow: ellipsis;
}

.idt-text-copy__text {
  margin-right: 10px;
}

.idt-text-copy__action {
  align-self: flex-start;
  cursor: pointer;
}

