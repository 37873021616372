
























































.custom-field-form-text {
  ::v-deep .idt-input-number {
    margin-bottom: 0;
  }
}
