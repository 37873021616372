.idt-prize-layout {
  --layout-color: var(--grey-light);
  display: flex;
  width: 91px;
  height: 58px;
  padding: 6px;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white);
  border: 2px solid var(--layout-color);
  border-radius: var(--global-radius);
}

.idt-prize-layout.selected {
  --layout-color: var(--main-color);
}

.idt-prize-layout__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.idt-prize-layout__column {
  height: 19px;
  width: 22px;
  background: var(--layout-color);
}

.idt-prize-layout__column:only-child {
  height: 9px;
  width: 100%;
}

