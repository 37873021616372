.idt-side-menu {
  min-width: 245px;
  height: 100%;
  margin-right: 15px;
  flex-shrink: 0;
}

.idt-side-menu__title {
  margin-bottom: 22px;
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-side-menu__list {
  margin-left: 0;
}

.idt-side-menu__list__item {
  position: relative;
  height: 41px;
  padding-left: 52px;
  padding-right: 2rem;
  list-style-type: none;
  margin-bottom: 10px;
  line-height: 41px;
  border-radius: 21px;
  cursor: pointer;
  color: var(--black-alpha-50);
  font-weight: var(--font-weight-bold);
}

.idt-side-menu__list__item::before {
  content: " ";
  width: 14px;
  height: 14px;
  position: absolute;
  margin-top: -7px;
  left: 23px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--before-color, var(--grey-light));
}

.idt-side-menu__list__item::after {
  content: var(--after-content, "");
  position: absolute;
  right: 14px;
  color: var(--danger-color);
}

.idt-side-menu__list__item.selected,
.idt-side-menu__list__item:hover {
  background-color: var(--main-color-lighter);
  color: var(--black);
}

.idt-side-menu__list__item.selected::before,
.idt-side-menu__list__item:hover::before {
  background-color: var(--main-color);
}

.idt-side-menu__progress {
  display: flex;
  align-items: center;
  margin-left: 23px;
}

.idt-side-menu__progress label {
  margin-right: 1rem;
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

