







































































































































































































@import '@/styles/mixins/index';
.fo-lobby-categories {
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;

  > * {
    width: 100%;
  }

  .lobby-category {
    display: flex;
    flex-flow: row wrap;

    &__view-more {
      display: flex;
      align-items: center;
      min-height: var(--rem-size-36);
      padding: 0 var(--rem-size-16);
      color: var(--black);
      font-size: var(--rem-size-14);

      svg {
        transition: transform .2s ease-in-out;
      }

      &--viewing-more {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-flow: column;
      padding-right: var(--rem-size-16);
      height: 100%;
      overflow-y: auto;

      @include respond-to(max-slarge) {
        padding-left: var(--rem-size-16);
      }
    }

    > * {
      width: 100%;
    }

    &__title {
      margin-bottom: var(--rem-size-16);
      padding: var(--rem-size-9) 0;
      border-bottom: 1px solid var(--grey-dark);
      color: var(--grey-darkest);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-bold);
      line-height: var(--rem-size-18);
      color: var(--grey-darkest);
    }

    &__sponsor {
      margin-bottom: var(--rem-size-8);
    }

    &__regular-rooms {
      .lobby-room {
        margin-bottom: var(--rem-size-4);
      }
    }

    &__regular-rooms,
    &__sponsor-rooms {
      > .lobby-room:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  // collapse animation
  .height-animation-enter-active,
  .height-animation-leave-active {
    transition: max-height .3s ease-out;
    overflow: hidden;
    max-height: 125px;
  }
  .height-animation-enter,
  .height-animation-leave-to {
    max-height: 0;
  }

  // animation
  .list-transition-items .list-transition-item {
    // opacity, max-height, margin-bottom
    transform: translate3d(0, 0, 0);
    transition: all .33s;
  }
  .list-transition-leave-active,
  .list-transition-enter-active {
    overflow: hidden !important;
  }
  .list-transition-enter {
    max-height: 0;
    margin-bottom: 0;
    opacity: 0;
  }
  .list-transition-enter-to,
  .list-transition-leave {
    max-height: 7rem;
    // opacity: 1;
  }
  .list-transition-leave-to {
    max-height: 0;
    margin-bottom: 0;
    opacity: 0;
  }

  &--remove-animations {
    .height-animation-enter-active,
    .height-animation-leave-active {
      transition: none;
    }
    .list-transition-items .list-transition-item {
      transition: none;
    }
    .lobby-sponsor-line__chevron {
      transition: none;
    }
  }
}
