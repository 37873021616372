





































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.bo-section__title + .bo-lobby-table {
  margin-top: calc(-1 * var(--rem-size-30));
}
.bo-lobby-table {
  width: 100%;
  overflow: initial !important;

  @include respond-to(max-medium) {
    overflow: auto !important;
  }

  &__add-row {
    .idt-icon {
      color: var(--main-color);
    }
    > :first-child,
    > :nth-child(2) {
      cursor: pointer;
    }
    > :nth-child(2) {
      transform: translateX(-41px);
    }
    .idt-icon {
      display: inline-flex;
      transform: translateY(1px) translateX(3px);
    }
    svg {
      transform: rotate(-45deg);
    }
  }
  &__row--header {
    height: 50px;
    box-shadow: none;

    .bo-lobby-table__cell {
      color: var(--grey-dark);
      font-size: var(--rem-size-12);
      font-weight: var(--font-weight-bold);
    }
  }
  // prevent ugly behavior when editing a line content on mobile & desktop
  .bo-lobby-table__cell {
    .idt-select-container:not(.idt-select-container--multiple) .v-select .vs__selected .column {
      max-width: 141px;
      padding-right: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    input[type="number"] {
      appearance: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }
  .bo-lobby-table__cell--editing {
    max-width: none !important;
    overflow: initial !important;
  }
  .bo-lobby-table__cell--placeholder {
    color: var(--grey-dark);
    white-space: nowrap;
    overflow: visible !important;
  }
  &__content {
    position: relative;
    border: 1px solid var(--grey-light);
    border-radius: var(--global-radius);
    box-shadow: 0 2px 3px var(--grey-darker-alpha15);

    &,
    &__lines {
      width: 100%;
    }
    &__lines {
      .bo-lobby-table__row {
        border-bottom: 1px solid var(--grey-light);
        left: 0;
      }
    }
  }
  .bo-lobby-table__row {
    background: var(--white) !important;

    &--ghost {
      opacity: 0;
    }
  }
  .bo-lobby-table__cell {
    padding-top: var(--rem-size-16);
    padding-bottom: var(--rem-size-16);
    vertical-align: top;
    overflow: hidden;

    &__content {
      display: flex;
      flex: auto;
      white-space: normal;
      word-break: break-word;
    }
  }
  a {
    color: var(--grey-dark);
  }
  .bo-lobby-table__actions {
    display: flex;
    align-items: flex-start;
    // fix ... on mobile appearing on the actions' icons
    max-width: none !important;
    white-space: nowrap;
    overflow: initial !important;
    transition: padding-top .1s ease-in-out;

    a + a {
      margin-left: var(--rem-size-8);
    }
  }
  .bo-lobby-table__actions.bo-lobby-table__cell--editing {
    padding-top: var(--rem-size-22);
  }
  .idea-menu {
    cursor: move;
  }
  // overwrite behaviors so it's usable in a table
  .idt-input-container {
    input {
      font-size: var(--rem-size-14);
      padding: var(--rem-size-6) var(--rem-size-16);
    }
    // fix bad alignment of the counter
    .idt-input-counter {
      top: .5rem;
    }
  }
  // error handling in table/only show red border
  .idt-required-field {
    position: relative;
  }
  .idt-select-container {
    flex: auto;
    padding-bottom: 0;
  }
  .vs__dropdown-option--disabled {
    display: none !important;
  }
  .vs__dropdown-toggle {
    height: 34px;
    padding-bottom: 0;

    input {
      margin-top: 0;
    }
  }
  .vs__selected {
    padding-left: 7px !important;
  }
  .vs__selected-options {
    flex-flow: nowrap;
    white-space: nowrap;
  }
  .idt-select-container--multiple {
    div[role="combobox"] {
      height: auto;
      min-height: 34px;
      padding: 2px 0;

      .vs__selected-options {
        flex-flow: wrap;
      }
    }
  }
  .v-select {
    min-height: 35px;
    margin-bottom: 0 !important;
    padding: 0;
  }
  .bo-lobby-table__actions__handle {
    transition: opacity .5s ease-in-out;
  }
  &--disable-move .bo-lobby-table__actions__handle {
    opacity: .2;
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }
  /* Table base structure */
  &__table,
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    width: 100%;
    min-width: 1100px;
  }
  &__row {
    display: grid;
    width: 100%;
    font-size: var(--rem-size-14);
  }
  &__cell {
    padding: var(--rem-size-16);
  }
  .list-transition-items .list-transition-item {
    // opacity, max-height, transform, padding
    transition: all .35s;

    .bo-lobby-table__cell {
      transition: all .35s;
    }
  }
  .list-transition-leave-active,
  .list-transition-enter-active {
    overflow: hidden !important;

    .bo-lobby-table__cell {
      overflow: hidden !important;
    }
  }
  .list-transition-enter {
    max-height: 0;
    opacity: 0;

    .bo-lobby-table__cell {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .list-transition-enter-to,
  .list-transition-leave {
    max-height: 125px;
    opacity: 1;

    .bo-lobby-table__cell {
      max-height: 125px;
      padding-top: var(--rem-size-16);
      padding-bottom: var(--rem-size-16);
    }
  }
  .list-transition-leave-to {
    max-height: 0;
    border-bottom-color: transparent !important;
    opacity: 0;

    .bo-lobby-table__cell {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
// align "add a room" button artificially
.bo-lobby-category--sponsor-cat .bo-lobby-table__add-row > :nth-child(2) {
  transform: translateX(-18px);
}
