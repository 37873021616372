.idt-account-availabilities__define-screen {
  max-width: 500px;
  margin: 0 auto;
}

@media only screen and (max-width: 639px) {
  .idt-account-availabilities__define-screen {
    padding: var(--rem-size-24) 0;
  }
}

@media only screen and (min-width: 640px) {
  .idt-account-availabilities__define-screen {
    padding: var(--rem-size-24);
  }
}

@media only screen and (max-width: 639px) {
  .idt-account-availabilities__description,
  .idt-account-availabilities__question,
  .idt-account-availabilities__choise {
    margin-bottom: var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .idt-account-availabilities__description,
  .idt-account-availabilities__question,
  .idt-account-availabilities__choise {
    margin-bottom: var(--rem-size-24);
  }
}

.idt-account-availabilities__choise {
  display: flex;
  justify-content: center;
  align-items: center;
}

.idt-account-availabilities__choise .button {
  margin: 0 var(--rem-size-16);
}

