.idt-availabilities-slot-list {
  padding: var(--rem-size-20);
  background: var(--grey-lightest);
  overflow: auto;
}

@media only screen and (max-width: 639px) {
  .idt-availabilities-slot-list {
    width: 100%;
    height: auto;
    max-height: 400px;
    margin-bottom: var(--rem-size-15);
  }
}

.idt-availabilities-slot-list__slot-item {
  padding: var(--rem-size-5) var(--rem-size-15);
  background: var(--main-color-dark);
  border-radius: var(--global-radius);
  text-align: left;
}

.idt-availabilities-slot-list__slot-item:not(:last-child) {
  margin-bottom: var(--rem-size-5);
}

.idt-availabilities-slot-list__slot-item.empty {
  padding: var(--rem-size-10) var(--rem-size-15);
  color: var(--grey-darker);
  background: var(--grey);
  font-size: var(--rem-size-16);
  text-align: center;
  line-height: var(--rem-size-24);
}

.idt-availabilities-slot-list__slot-item__date,
.idt-availabilities-slot-list__slot-item__range {
  color: var(--white);
  line-height: var(--rem-size-18);
}

.idt-availabilities-slot-list__slot-item__date {
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
}

.idt-availabilities-slot-list__slot-item__range {
  font-size: var(--rem-size-10);
  font-weight: var(--font-weight-medium);
}

