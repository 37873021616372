




























































































.menu-mobile {
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  top: var(--menu-height);
  left: 0;
  width: 100vw;
  height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
  padding: 0 var(--rem-size-8);
  background-color: var(--white);
  text-align: left;
  overflow-y: auto;
  z-index: 10001;

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    flex: 0 0 auto;
    width: 100%;
    height: 64px;
    padding: 0 1rem;
    font-weight: var(--font-weight-light);

    button {
      color: var(--grey-darker);
    }
  }
  &__gamification-widget {
    margin: 20px;
  }
}
