.gamification-points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--grey-light);
  border-radius: 50px;
  color: var(--black);
  font-weight: var(--font-weight-bold);
  padding: .25rem 1rem;
  background-color: var(--white);
}

@media only screen and (max-width: 991px) {
  .gamification-points {
    font-size: var(--rem-size-14);
  }

  .gamification-points img {
    height: 12px;
    width: 12px;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-points {
    font-size: var(--rem-size-18);
  }

  .gamification-points img {
    height: 24px;
    width: 24px;
  }
}

.gamification-points__value {
  margin-left: 1rem;
}

.gamification-points.active {
  color: var(--main-color);
}

