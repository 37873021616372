

































































.idt-conference-surveys {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  background: var(--white);

  &__add {
    display: block;
    margin: 1rem auto !important;
  }
}
