.news-list {
  padding-bottom: var(--rem-size-32);
}

.news-list__header {
  align-items: flex-start;
  min-height: 120px;
  padding: var(--rem-size-16) 0;
  background-color: var(--grey-lightest);
}

.news-list__tags__label {
  margin-bottom: var(--rem-size-8);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-light);
}

.news-list__tags__selector {
  margin-left: var(--rem-size-8) !important;
}

.news-list__tags-list {
  padding: 0 var(--rem-size-8) 0 var(--rem-size-4);
  text-align: left;
}

.news-list__tag {
  cursor: pointer;
}

.news-list__tag:hover {
  color: var(--main-color);
}

.news-list__selected-tags {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--rem-size-8);
}

.news-list__selected-tags .idt-tag {
  cursor: pointer;
}

