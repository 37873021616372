





































































































.idt-description {
  .froala-container {
    &--sticky .fr-toolbar.fr-sticky-on {
      top: 140px !important;
    }
  }
}
