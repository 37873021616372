













































































.idt-live-preview {
  display: flex;
  width: 100%;
  height: 100%;

  &__video-container {
    width: 100%;
    height: 100%;
  }

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10%;
    border-radius: var(--global-radius);
    background-color: var(--grey-darker);

    &__message {
      color: var(--white);
      font-weight: var(--font-weight-medium);
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  ::v-deep .video-js {
    border-radius: var(--global-radius);
  }

  /* This appears to be needed because the control bar (or its contents) will otherwise break out of the player. */
  ::v-deep .video-js .vjs-control-bar {
    border-bottom-left-radius: var(--global-radius);
    border-bottom-right-radius: var(--global-radius);
  }
}
