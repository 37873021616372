





























































.idt-switch-small {
  display: flex;
  align-items: center;
  gap: var(--rem-size-8);

  &.disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  &__switch {
    display: inline-block;
    position: relative;
    width: 45px;
    min-width: 45px;
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;

    &__inner {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      transition: background-color 0.3s ease;

      & ::v-deep .ideacon {
        color: white;
        opacity: 0.5;
      }
      &.off {
        background-color: var(--grey);

        & ::v-deep .ideacon {
          display: none;
        }
      }
      &.on {
        background-color: var(--success-color);
      }
    }
    &__btn {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      transition: all 0.3s ease;

      &.off {
        left: 10%;
        background-color: var(--grey-dark);
        transform: translate3d(0, -50%, 0);
      }
      &.on {
        left: 90%;
        background-color: var(--white);
        transform: translate3d(-100%, -50%, 0);
        box-shadow: 0px 0px 2px #00000040;
      }
    }
  }
  &__label {
    color: var(--grey-darkest);
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-medium);
    line-height: var(--rem-size-18);
  }
}
