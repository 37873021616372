










































































































































































@import '@/styles/mixins/index';
.meeting-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--grey-light);
  ::v-deep .idt-sidebar__content__header {
    display: flex;
    justify-content: space-between;
    padding: var(--rem-size-6);
    background-color: var(--white);
    border-bottom: 1px solid var(--grey);
    &__title {
      text-transform: initial;
    }
  }
  ::v-deep .idt-sidebar__content__slot {
    display: flex;
    width: 100%;
    overflow-y: auto;
  }
  ::v-deep .live-chat {
    width: 100%;
  }
  ::v-deep .idt-sidebar__content__header__title {
    width: 100%;
    &__slot {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__main-info {
      display: flex;
      align-items: center;
      &__title {
        padding: var(--rem-size-2) var(--rem-size-10);
        background-color: var(--blue);
        color: var(--white);
        border-radius: var(--global-radius);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
      }
      &__elapsed-time {
        margin-left: var(--rem-size-8);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
        line-height: var(--rem-size-18);
      }
    }

    &__participants-count {
      display: flex;
      align-items: center;
      padding: var(--rem-size-2) var(--rem-size-10);
      background-color: var(--main-color);
      color: var(--white);
      border-radius: var(--global-radius);
      font-size: var(--rem-size-14);
      gap: var(--rem-size-5);
      cursor: pointer;
      &.active {
        background-color: var(--grey-lighter);
        color: var(--black);
      }
      &.not-clickable {
        cursor: default;
      }
    }
  }
}
