.idt-ticket-features {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 370px) {
  .idt-ticket-features {
    height: 100%;
  }
}

@media only screen and (max-width: 639px) {
  .idt-ticket-features {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-features__section {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-features__section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    color: var(--grey-darker);
    background-color: var(--grey-lighter);
    font-size: .875rem;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }
}

