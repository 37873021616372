





















































.event-partners {
  &__separator {
    margin: 0.5rem 0 3.5rem;
    border-color: var(--main-color);
  }
}
