















































































.custom-field-form-choice {
  &__choices {
    &__item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-add-choice {
    display: flex;
    align-items: center;
    width: 150px;
    margin-top: 10px;
    color: var(--main-color);
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    cursor: pointer;

    &__text {
      margin-left: 5px;
    }

    &.required-field {
      color: var(--secondary-color);
    }
  }
}
