::v-deep .idt-conference-table .idt-table-cell--camera {
  max-width: 7vw;
  text-align: center;
}

::v-deep .idt-conference-table .idt-table-cell--camera .ideacons {
  font-size: 2rem;
}

::v-deep .idt-conference-table .idt-table-cell--camera .idea-ht-circle-checked {
  color: var(--main-color);
}

::v-deep .idt-conference-table .idt-table-cell--camera .idea-ht-circle-info {
  color: var(--yellow);
}

::v-deep .idt-conference-table .idt-table-cell--camera .idea-ht-circle-close {
  color: var(--red);
}

::v-deep .idt-conference-table .idt-table-header-cell--camera {
  max-width: 7vw;
  text-align: center;
}

::v-deep .idt-conference-table .idt-table-header-cell--free {
  max-width: 7vw;
  text-align: center;
}

::v-deep .idt-conference-table .idt-table-cell--free {
  max-width: 7vw;
  text-align: center;
}

::v-deep .idt-conference-table .idt-table-cell--free .free {
  display: block;
  width: 78px;
  margin: 3px 0;
  border: solid 1px;
  border-radius: 3px;
  font-size: .55rem;
  text-align: center;
  text-transform: uppercase;
}

::v-deep .idt-conference-table .idt-table-cell--free .live {
  color: var(--secondary-color);
}

::v-deep .idt-conference-table .idt-table-cell--free .replay {
  color: #484848;
}

