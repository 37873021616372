.bo-topic {
  padding: .25rem;
}

@media only screen and (max-width: 479px) {
  .bo-topic {
    width: 50%;
  }
}

@media only screen and (min-width: 480px)and (max-width: 639px) {
  .bo-topic {
    width: 33.3333333333%;
  }
}

@media only screen and (min-width: 640px) {
  .bo-topic {
    width: 25%;
  }
}

.bo-topic__status {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right;
}

.bo-topic__list {
  display: flex;
  flex-flow: row wrap;
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.bo-topic__label {
  display: flex !important;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0 !important;
  color: var(--white);
  background-color: var(--black-alpha70);
  border-radius: var(--global-radius);
  font-size: .9rem;
  text-align: center;
  overflow: hidden;
  transition: all .3s ease;
  cursor: pointer;
}

.bo-topic__label:hover {
  background-color: var(--black-alpha80);
}

.bo-topic__label__name {
  flex: 1 0 auto;
  font-weight: 700;
}

.bo-topic__label__check {
  position: absolute;
  top: .35rem;
  right: .35rem;
  color: var(--main-color-dark);
  font-size: 1rem;
  line-height: 1;
  opacity: 0;
  transition: all .3s ease;
}

.bo-topic__label.checked {
  color: var(--main-color-dark);
  background-color: var(--main-color-alpha20);
}

.bo-topic__label.checked .bo-topic__label__check {
  opacity: 1;
}

