.display-field {
  padding: 20px;
}

.display-field__label {
  font-weight: bold;
}

.display-field .project-display-field-btn {
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 300px;
  margin-top: .5em;
  border-radius: var(--global-radius);
  text-align: left;
  cursor: pointer;
}

.display-field .project-display-field-btn--download {
  color: var(--white);
  background-color: var(--main-color);
  transition: all .3s ease-in-out;
}

.display-field .project-display-field-btn--download:hover {
  background-color: var(--main-color-dark);
}

.display-field .project-display-field-btn__icon {
  flex: 0 0 auto;
  padding: 0 1.25rem;
}

.display-field .project-display-field-btn__separator {
  flex: 0 0 auto;
  width: 1px;
  height: 100%;
  margin-right: 1.25rem;
  background-color: var(--black-alpha10);
}

.display-field .project-display-field-btn__info {
  flex: 1 1 auto;
  max-width: calc(100% - 60px);
  padding: 0 1.25rem 0 0;
}

.display-field .project-display-field-btn--download .display-field .project-display-field-btn__info {
  max-width: calc(100% - 85px);
}

.display-field .project-display-field-btn__label {
  display: block;
  font-weight: var(--font-weight-medium);
  line-height: 1.2;
  word-break: break-word;
}

.display-field .project-display-field-btn--download .display-field .project-display-field-btn__label {
  font-size: 1.125rem;
}

.display-field .project-display-field-btn__file-name {
  display: block;
  font-size: .75rem;
  font-style: italic;
  font-weight: var(--font-weight-light);
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

