






















.idt-admin-navbar {
  display: flex;
  justify-content: right;
  position: sticky;
  top: 70px;
  padding: var(--rem-size-12) var(--rem-size-32);
  color: var(--white);
  background-color: var(--grey-darkest);
  z-index: 1;

  & > * {
    display: flex;
    align-items: center;
    margin-left:  var(--rem-size-16);

    & > *:first-child {
      margin-right: var(--rem-size-8);
    }
  }
}
