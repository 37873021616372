.fo-project-section-presentation-edit {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);
}

.fo-project-section-presentation-edit__title--required {
  margin-bottom: var(--rem-size-8);
}

.fo-project-section-presentation-edit__upload-file {
  border: none;
}

.fo-project-section-presentation-edit__upload-file--required ::v-deep .idt-fs-upload-file__button {
  width: 100%;
  border: 1px dashed var(--red);
}

.fo-project-section-presentation-edit__upload-file:not(.fo-project-section-presentation-edit__upload-file--required) ::v-deep .idt-fs-upload-file__button {
  width: 100%;
  border: 2px dashed var(--grey-light);
}

.fo-project-section-presentation-edit__upload-file__content {
  min-height: 630px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fo-project-section-presentation-edit__upload-file__content__label {
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  color: var(--grey-dark);
}

.fo-project-section-presentation-edit__upload-file__content__button {
  position: relative;
  padding: var(--rem-size-10) var(--rem-size-40);
  border-radius: var(--rem-size-25) !important;
}

.fo-project-section-presentation-edit__upload-file__content__button .idt-spinner {
  position: absolute;
}

.fo-project-section-presentation-edit__preview {
  position: relative;
}

.fo-project-section-presentation-edit__preview:hover .fo-project-section-presentation-edit__preview__container {
  display: flex;
}

.fo-project-section-presentation-edit__preview__container {
  position: absolute;
  inset: 0;
  bottom: 38px;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.fo-project-section-presentation-edit__preview__container__buttons {
  display: flex;
  align-items: center;
  padding: var(--rem-size-10);
  background: var(--black-alpha80);
}

.fo-project-section-presentation-edit__preview__container__buttons .button:first-child {
  margin-right: var(--rem-size-8);
}

.fo-project-section-presentation-edit__url {
  margin-top: var(--rem-size-40);
  margin-bottom: var(--rem-size-40);
}

.fo-project-section-presentation-edit__slideshare {
  position: relative;
  width: 100%;
}

.fo-project-section-presentation-edit__slideshare:hover .fo-project-section-presentation-edit__slideshare__container {
  display: flex;
}

.fo-project-section-presentation-edit__slideshare__container {
  position: absolute;
  inset: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.fo-project-section-presentation-edit__slideshare__container__buttons {
  display: flex;
  align-items: center;
  padding: var(--rem-size-10);
  background: rgba(56,58,64,.8);
}

.fo-project-section-presentation-edit__slideshare__container__buttons .button {
  margin-bottom: 0;
  border-radius: var(--rem-size-23);
  padding: var(--rem-size-5) var(--rem-size-24);
}

.fo-project-section-presentation-edit__slideshare .embed-responsive-item {
  width: 100% !important;
  height: 70vh !important;
}

.fo-project-section-presentation-edit .idt-required-field {
  margin-top: var(--rem-size-8);
}

