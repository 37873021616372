.agenda-activities {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.agenda-activities #list-mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .agenda-activities #list {
    display: none;
  }

  .agenda-activities #list-mobile {
    display: flex;
  }
}

.agenda-activities__label {
  font-weight: var(--font-weight-medium);
}

.agenda-activities__empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 550px;
  min-height: 250px;
  margin: 40px 0px 0px 0px;
  border: solid 1px var(--grey-light);
}

.agenda-activities__empty-list__text {
  font-family: var(--text-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-light);
  line-height: 22px;
  text-align: center;
}

.agenda-activities__delete__icon {
  margin-left: var(--rem-size-8);
}

