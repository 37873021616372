





























































































































































































































































@import '@/styles/mixins/index';

.meetings-page {
  height: calc(100vh - 70px);
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
  overflow: auto;

  &__sidebar {
    position: sticky;
    top: 0;
    max-width: 280px;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;
      position: sticky;
      padding: 1rem 0 2rem 0;
      top: 0;
      z-index: 2;
      background-color: var(--grey-lighter);
      box-shadow: 0px 0px 2px var(--rem-size-2) var(--grey-lighter);

      &__titles {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &__title {
          font-size: var(--rem-size-24);
          font-weight: var(--font-weight-bold);
          color: var(--black);
        }
        &__subtitle {
          font-size: var(--rem-size-16);
          color: var(--grey-dark);
        }
      }

      button {
        margin: 0 0 0 var(--rem-size-8);
      }
    }

    &__day-container {
      margin-bottom: var(--rem-size-8);
    }

    &__day {
      margin-bottom: 1.2rem;
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-medium);
      color: var(--black);
    }

    & ::v-deep .meeting-card {
      margin-bottom: var(--rem-size-10);
    }
  }

  &__no-results {
    display: flex;
    margin-top: 1rem;
    padding: 1.6rem 2rem;
    align-items: center;
    background-color: var(--white);
    border-radius: var(--global-radius);
    box-shadow: 0px 2px 3px var(--grey);

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.4rem;
      height: 3.4rem;
      margin-right: 1.5rem;
      background-color: var(--main-color-lighter);
      border-radius: 50%;
    }

    &__labels {
      &__title {
        font-size: var(--rem-size-18);
        font-weight: var(--font-weight-bold);
        color: var(--black);
      }
      &__description {
        font-size: var(--rem-size-14);
        color: var(--grey-dark);
      }
      & > * {
        text-align: left;
      }
    }
  }
}
