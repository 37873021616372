































































































.modal-body--lobby-delete-message {
  padding-top: var(--rem-size-32) !important;

  h3 {
    color: var(--grey-darkest);
    font-size: var(--rem-size-32);
  }

  .live-chat-message {
    margin-top: var(--rem-size-28);
    margin-bottom: var(--rem-size-12);
    background-color: var(--white) !important;
    text-align: left;
  }

  .live-chat-message-content__flex__content__text__reactions {
    display: none !important;
  }

  .button {
    font-weight: var(--font-weight-bold);
  }
}
