.bo-menu-item {
  font-weight: var(--font-weight-medium);
}

@media only screen and (max-width: 1199px) {
  .bo-menu-item {
    display: flex;
    align-items: center;
    margin: .5rem 1rem;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-menu-item {
    display: block;
    position: relative;
    margin: .5rem;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1441px) {
  .bo-menu-item {
    font-size: var(--rem-size-12);
  }
}

@media only screen and (min-width: 1442px) {
  .bo-menu-item {
    font-size: var(--rem-size-14);
  }
}

.bo-menu-item:not(.feature--disabled) {
  color: var(--grey);
}

.bo-menu-item:hover,
.bo-menu-item.active {
  color: var(--main-color-light);
}

.bo-menu-item__indicator {
  display: block;
  width: 10px;
  height: 5px;
  border-radius: var(--global-radius);
}

@media only screen and (max-width: 1199px) {
  .bo-menu-item__indicator {
    margin-right: .5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-menu-item__indicator {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.bo-menu-item__indicator:not(.active) {
  background-color: var(--danger-color);
}

.bo-menu-item__indicator.active {
  background-color: var(--success-color);
}

