




































@import '@/styles/mixins/index';

.skeleton {
  width: 280px;
  padding: var(--rem-size-16);

  @include respond-to(max-large) {
    display: none;
  }

  &__search {
    height: var(--rem-size-40);
    margin-bottom: var(--rem-size-8);
  }

  &__reset-button {
    height: var(--rem-size-32);
    margin-bottom: 52px;
  }

  &__title {
    height: var(--rem-size-32);
    margin-bottom: var(--rem-size-16);
  }

  &__sort-by {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--rem-size-22);
    &__label {
      width: 59px;
      height: var(--rem-size-24);
      margin-right: var(--rem-size-16);
    }

    &__field {
      width: 100%;
      height: var(--rem-size-40);
    }
  }

  &__category {
    width: 87px;
    height: var(--rem-size-24);
    margin-bottom: var(--rem-size-20);
  }

  &__sub-category {
    width: 63px;
    height: var(--rem-size-24);
    margin-bottom: var(--rem-size-20);
  }

  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--rem-size-12);

    &__label {
      width: 130px;
      height: var(--rem-size-24);
    }

    &__checkbox {
      width: var(--rem-size-24);
      height: var(--rem-size-24);
    }
  }
}

.bone {
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }
  25% {
    background-color: var(--grey);
  }
  50% {
    background-color: var(--grey-light);
  }
  75% {
    background-color: var(--grey-lighter);
  }
  100% {
    background-color: var(--grey-light);
  }
}
