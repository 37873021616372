



















































































@import '@/styles/mixins';

.event-prizes {
  position: relative;
  overflow: hidden;

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    @include respond-to(max-xlarge) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: auto;

      .idt-event-prize {
        flex-shrink: 0;
      }
    }

    &:after {
      @include respond-to(max-small) {
        content: '';
        min-width: 0.4rem;
      }
    }
    .idt-event-prize {
      margin: 0.4rem;
      max-width: 1170px; // Align inline with the 3 prizes
    }
  }
}
