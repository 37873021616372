









































































































@import '@/styles/mixins/index';

.bo-topic {
  padding: 0.25rem;

  @include respond-to(max-small) {
    width: calc(100% / 2);
  }
  @include respond-to(small-medium) {
    width: calc(100% / 3);
  }
  @include respond-to(medium) {
    width: calc(100% / 4);
  }

  &__status {
    margin-bottom: 5px;
    font-weight: 500;
    text-align: right;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  &__label {
    display: flex !important;
    align-items: center;
    position: relative;
    width: 100%;
    height: 60px;
    margin: 0 !important;
    color: var(--white);
    background-color: var(--black-alpha70);
    border-radius: var(--global-radius);
    font-size: 0.9rem;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: var(--black-alpha80);
    }
    &__name {
      flex: 1 0 auto;
      font-weight: 700;
    }
    &__check {
      position: absolute;
      top: 0.35rem;
      right: 0.35rem;
      color: var(--main-color-dark);
      font-size: 1rem;
      line-height: 1;
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.checked {
      color: var(--main-color-dark);
      background-color: var(--main-color-alpha20);
    }
    &.checked &__check {
      opacity: 1;
    }
  }
}
