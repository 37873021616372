





















































.idt-survey-card-admin-menu {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-width: 205px;
  padding: 1rem var(--rem-size-28);
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
  background: var(--white);

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    color: var(--black);
    cursor: pointer;

    &__icon {
      margin-right: var(--rem-size-15);
    }

    &.disabled {
      opacity: .35;
      pointer-events: none;
    }
  }
}
