










































































































































































































































































































@import '@/styles/functions';
@import '@/styles/mixins';

.update-role-modal {
  &__content {
    height: calc((var(--vh, 1vh) * 100) - (var(--menu-height) + remCalc(64)));
    margin-top: var(--menu-height);
    overflow-y: auto;

    @include respond-to(max-medium) {
      padding: remCalc(16) remCalc(24);
    }
    @include respond-to(medium) {
      padding: remCalc(32) remCalc(24);
    }
  }
  &__current-role {
    font-size: remCalc(14);
  }
  &__access-list {
    display: flex;
    flex-direction: column;
    gap: remCalc(24);
    margin: remCalc(16) 0 remCalc(32) 0;
  }
  &__access-group {
    &__title {
      margin-bottom: remCalc(8);
      color: var(--grey-darkest);
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
    }
    &__item {
      display: flex;
      align-items: center;
      gap: remCalc(16);
      font-size: remCalc(14);
    }
    &__item-label {
      display: flex;
      align-items: center;
      gap: var(--rem-size-4);
      line-height: 0;
    }
    &__item-icon {
      &:not(.checked) {
        color: var(--danger-color);
      }
      &.checked {
        color: var(--success-color);
      }
    }
  }
  &__info {
    margin-bottom: remCalc(16);
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--rem-size-8);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--rem-size-64);
    padding: var(--rem-size-16);
    border-top: 1px solid var(--grey-lighter);
    background-color: var(--white);
  }
}
