.fo-project-section-issue-and-solution-view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 2rem 3rem;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-issue-and-solution-view {
    flex-direction: column;
    margin: 1rem 1.5rem;
  }
}

.fo-project-section-issue-and-solution-view__part {
  flex: 1;
  flex-basis: 50%;
  max-width: calc(50% - var(--rem-size-12));
  padding-left: 24px;
  border-left: 2px solid var(--main-color);
}

@media only screen and (max-width: 639px) {
  .fo-project-section-issue-and-solution-view__part {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}

.fo-project-section-issue-and-solution-view__part:first-of-type {
  margin-right: var(--rem-size-32);
}

.fo-project-section-issue-and-solution-view__part__title-small {
  font-size: var(--rem-size-24);
  font-weight: bold;
}

.fo-project-section-issue-and-solution-view__part__title-big {
  margin-bottom: var(--rem-size-16);
  font-size: var(--rem-size-36);
  font-weight: bold;
}

.fo-project-section-issue-and-solution-view__part__content {
  font-size: var(--rem-size-16);
}

.fo-project-section-issue-and-solution-view__part__files {
  margin-top: 1rem;
  width: 100%;
}

