.meeting-room-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  padding: var(--rem-size-15) var(--rem-size-20);
  background: var(--white);
}

@media only screen and (max-width: 991px) {
  .meeting-room-title {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
  }
}

.meeting-room-title__title {
  max-width: calc(100% - 320px);
  color: var(--grey-darkest);
  font-size: var(--rem-size-20);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-26);
}

@media only screen and (max-width: 991px) {
  .meeting-room-title__title {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .meeting-room-title__right {
    margin-top: var(--rem-size-10);
  }
}

.meeting-room-title__right__action,
.meeting-room-title__right__countdown {
  margin: 0;
}

.meeting-room-title__right__action {
  background: var(--red);
  color: var(--white);
}

@media only screen and (max-width: 369px) {
  .meeting-room-title__right__action {
    width: 100%;
  }
}

.meeting-room-title__right__countdown {
  margin-left: var(--rem-size-10);
  background: var(--grey-lightest);
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
}

@media only screen and (max-width: 369px) {
  .meeting-room-title__right__countdown {
    width: 100%;
    margin-top: var(--rem-size-10);
    margin-left: 0;
  }
}

