.bo-challenge-button {
  display: inline-flex;
  align-items: center;
  color: var(--white) !important;
  background-color: var(--main-color) !important;
  border: 0 !important;
  font-weight: var(--font-weight-bold);
}

.bo-project-challenges .vue-slider {
  height: var(--rem-size-8);
  margin: var(--rem-size-8) var(--rem-size-9) var(--rem-size-40);
}

.bo-project-challenges .vue-slider .vue-slider-dot {
  width: var(--rem-size-24);
  height: var(--rem-size-24);
  border-width: 2px;
}

.bo-project-challenges .vue-slider .vue-slider-dot-tooltip-bottom {
  bottom: calc(-1*var(--rem-size-8));
}

.bo-project-challenges .vue-slider .vue-slider-dot-tooltip-text {
  color: var(--main-color);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.bo-project-challenges__slider {
  margin-left: var(--rem-size-32);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.bo-project-challenges__delete-line {
  display: flex;
  justify-content: flex-end;
}

.bo-project-challenges__add-challenge {
  display: flex;
  align-items: center;
  min-height: 43.58px;
  margin: var(--rem-size-8) 0 var(--rem-size-16) var(--rem-size-32);
  padding: var(--rem-size-4) var(--rem-size-16);
  color: var(--grey-darker);
  border: 2px dashed var(--grey);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  transition: color .15s ease-in-out,border-color .15s ease-in-out;
}

.bo-project-challenges__add-challenge:hover {
  border-color: var(--main-color);
}

.bo-project-challenges__input {
  flex: auto;
  position: relative;
  color: var(--grey-darkest);
}

.bo-project-challenges__input--invalid input {
  border-color: var(--red);
}

.bo-project-challenges__input__wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__handle,
.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__delete {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__handle,
.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__delete {
  color: var(--grey-darker);
}

.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__handle:hover,
.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__delete:hover {
  color: var(--danger-color);
  cursor: pointer;
}

.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__handle {
  left: var(--rem-size-16);
}

.bo-project-challenges__input__wrapper .bo-project-challenges__challenge__delete {
  right: var(--rem-size-16);
}

.bo-project-challenges__input input {
  width: 100%;
  margin-bottom: 0;
  padding: var(--rem-size-8) var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-14);
}

.bo-project-challenges__challenge {
  position: relative;
  margin-left: var(--rem-size-32);
  margin-top: var(--rem-size-8);
}

.bo-project-challenges__challenge .bo-project-challenges__input input {
  padding: var(--rem-size-8) var(--rem-size-42);
}

.bo-project-challenges__challenge--ghost {
  opacity: 0;
}

.bo-project-challenges__themes {
  width: 100%;
  padding-left: var(--rem-size-15);
}

.bo-project-challenges__theme {
  max-width: 620px;
  margin-bottom: var(--rem-size-12);
  padding: var(--rem-size-8) var(--rem-size-24) var(--rem-size-16) var(--rem-size-16);
  background: var(--white);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.bo-project-challenges__theme--ghost {
  opacity: 0;
}

.bo-project-challenges__theme__handle {
  margin-right: var(--rem-size-16);
}

.bo-project-challenges__theme__handle,
.bo-project-challenges__theme__handle:hover {
  color: var(--grey-darker);
}

.bo-project-challenges .idea-menu {
  cursor: move;
}

.bo-project-challenges .list-transition-items>.list-transition-item {
  transition: all .7s;
}

.bo-project-challenges .list-transition-leave-active,
.bo-project-challenges .list-transition-enter-active {
  overflow: hidden !important;
}

.bo-project-challenges .list-transition-enter {
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}

.bo-project-challenges .list-transition-enter-to,
.bo-project-challenges .list-transition-leave {
  max-height: 250px;
  opacity: 1;
}

.bo-project-challenges .list-transition-items--sub-list>.list-transition-item {
  transition: all .25s;
}

.bo-project-challenges .list-transition-items--sub-list .list-transition-enter-to,
.bo-project-challenges .list-transition-items--sub-list .list-transition-leave {
  max-height: 60px;
}

.bo-project-challenges .list-transition-leave-to {
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}

.bo-project-challenges-dialog-modal p {
  white-space: pre;
}

