
















































































.idt-project-participant-card {
  width: 300px;
  height: 325px;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
  cursor: pointer;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  ::v-deep .idt-tag-list {
    margin-top: 10px;
  }
}
