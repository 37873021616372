













































































































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.bo-page__content {
  .dashboard {
    @include respond-to(slarge) {
      .header {
        .idt-select-container {
          height: 100%;

          .v-select {
            height: 100%;

            .vs__dropdown-toggle {
              height: 100%;
            }
          }
        }
      }
    }
    .idt-select-container {
      padding-bottom: 0;
      font-size: var(--rem-size-14);

      .v-select {
        margin-bottom: 0;

        .vs__selected-options {
          flex-wrap: nowrap;
          max-width: calc(100% - 30px);

          .vs__selected {
            display: block;
            margin: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        }
        .vs__dropdown-toggle {
          padding: 0;
          background: var(--white);
          border: 1px solid var(--black);
        }
      }
      svg {
        fill: var(--black);
      }
    }
    .idt-date-time-container {
      .mx-input {
        border: 1px solid var(--black);
      }
      i {
        color: var(--black);
      }
    }
  }
}
