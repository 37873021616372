































































































.idt-duration-picker {
  position: relative;

  &__input {
    position: relative;

    &__text {
      background: var(--white);
      cursor: pointer;
    }

    &__icon ::v-deep .ideacon {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }
}
