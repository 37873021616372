.mentor-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--rem-size-16);
}

.mentor-list-container__card {
  flex: 0 0 auto;
  max-width: 280px;
  width: 100%;
}

.mentor-list-container__card.type-list {
  max-width: unset;
}

