
























@import '@/styles/mixins/index';

.meeting-finished {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--grey-darkest);

  &__title {
    margin-top: var(--rem-size-24);
    font-size: var(--rem-size-24);
    font-weight: var(--font-weight-medium);
    line-height: var(--rem-size-30);
  }
}
