.gamification-widget-point-progress {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}

.gamification-widget-point-progress__level {
  display: flex;
  justify-content: space-between;
}

.gamification-widget-point-progress__level--center {
  margin: auto;
}

.gamification-widget-point-progress__level__points {
  color: var(--secondary-color);
  font-weight: var(--font-weight-bold);
  margin-left: 1rem;
}

.gamification-widget-point-progress__level__points sup {
  font-size: var(--rem-size-8);
}

.gamification-widget-point-progress.primary .gamification-widget-point-progress__level__points {
  color: var(--main-color);
}

