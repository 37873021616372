





















































































































@import '@/styles/mixins/index';

.project-card-hire {
  --hire-radius: 8px;
  position: absolute;
  right: 1.25rem;
  max-width: 400px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.1));

  @include respond-to(max-medium) {
    top: -6.65rem;
  }
  @include respond-to(medium) {
    top: 1.25rem;
  }

  &__my-profile {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0.65rem 0 1.65rem;
    background-color: var(--main-color);
    color: var(--white);
    font-family: var(--title-font-family);
    font-size: 0.8rem;
    font-weight: var(--font-weight-bold);
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--main-color-dark);
    }
    &:not(.many) {
      height: 46px;
      border-radius: var(--hire-radius);
    }
    &.many {
      height: 40px;
      border-radius: var(--hire-radius) var(--hire-radius) 0 0;
    }
  }

  &__info {
    min-width: 5.5rem;
    margin-right: 0.25rem;
  }

  &__tag {
    display: inline-block;
    flex: 50%; /* this manages the clamping of the left part */
    margin-right: 0.25rem;
    text-align: right;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__others {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 22px;
    padding: 0 0.8rem 0 1.8rem;
    background-color: var(--white);
    border-radius: 0 0 var(--hire-radius) var(--hire-radius);
    font-size: 0.625rem;
    font-weight: var(--font-weight-bold);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 40px;
    color: var(--main-color);
    background-color: var(--white);
    border-radius: 50%;
    box-shadow: 0 0 3px var(--black-alpha20);
    font-size: 1.1rem;
    transform: translate(-50%, -50%);
  }
}
