.idt-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.idt-overlay__image {
  display: block;
  backface-visibility: hidden;
}

.idt-overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  height: 114px;
  color: var(--white);
  background: var(--black-alpha60);
  text-align: center;
  transition: .3s ease-in-out;
  opacity: 0;
}

.idt-overlay:hover .idt-overlay__content {
  opacity: 1;
}

