@mixin colors() {
  // -----------------------------
  // Primary color palette
  // -----------------------------
  // Primary color
  --main-color-hue: 182;
  --main-color-saturation: 60%;
  --main-color-lightness: 50%;
  --main-color: hsl(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness)); // #33c7cc
  --main-color-alpha10: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.1);
  --main-color-alpha20: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.2);
  --main-color-alpha30: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.3);
  --main-color-alpha40: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.4);
  --main-color-alpha60: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.6);
  --main-color-alpha70: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.7);
  --main-color-alpha80: hsla(var(--main-color-hue), var(--main-color-saturation), var(--main-color-lightness), 0.8);
  --main-color-lightest: hsl(var(--main-color-hue), var(--main-color-saturation), 95%);
  --main-color-lighter: hsl(var(--main-color-hue), var(--main-color-saturation), 90%);
  --main-color-light: hsl(var(--main-color-hue), var(--main-color-saturation), 65%);
  --main-color-light: hsl(var(--main-color-hue), var(--main-color-saturation), 65%);
  --main-color-dark: hsl(var(--main-color-hue), var(--main-color-saturation), 40%); // #299fa3
  --main-color-dark-alpha20: hsla(var(--main-color-hue), var(--main-color-saturation), 40%, 0.2);
  --main-color-dark-alpha30: hsla(var(--main-color-hue), var(--main-color-saturation), 40%, 0.3);
  --main-color-dark-alpha40: hsla(var(--main-color-hue), var(--main-color-saturation), 40%, 0.4);
  --main-color-darker: hsl(var(--main-color-hue), var(--main-color-saturation), 27%); // #1b6a6d
  --main-color-darker-alpha20: hsla(var(--main-color-hue), var(--main-color-saturation), 27%, 0.2);
  --main-color-darker-alpha30: hsla(var(--main-color-hue), var(--main-color-saturation), 27%, 0.3);
  --main-color-darker-alpha50: hsla(var(--main-color-hue), var(--main-color-saturation), 27%, 0.5);
  --main-color-darkest: hsl(var(--main-color-hue), var(--main-color-saturation), 20%); // #144D52
  --main-color-darkest-alpha20: hsla(var(--main-color-hue), var(--main-color-saturation), 20%, 0.2);
  --main-color-darkest-alpha40: hsla(var(--main-color-hue), var(--main-color-saturation), 20%, 0.4);
  --main-color-gradient: linear-gradient(to left,var(--main-color-alpha40) , var(--main-color));
  // Secondary color
  --secondary-color-hue: 340;
  --secondary-color-saturation: 80%;
  --secondary-color-lightness: 60%;
  --secondary-color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness)); // rgb(232, 51, 112), hsl(340, 80%, 60%)
  --secondary-color-alpha15: hsla(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness), 0.15);
  --secondary-color-alpha20: #ffdbe8;
  --secondary-color-alpha30: hsla(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness), 0.3);
  --secondary-color-alpha40: hsla(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness), 0.4);
  --secondary-color-alpha50: hsla(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness), 0.5);
  --secondary-color-alpha70: hsla(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness), 0.6);
  --secondary-color-alpha80: hsla(var(--secondary-color-hue), var(--secondary-color-saturation), var(--secondary-color-lightness), 0.8);
  --secondary-color-lightest: #ffdbe8;
  --secondary-color-darken5: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), 55%);
  --secondary-color-dark: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), 50%);

  // Dark-color
  --dark-color-hue: 220;
  --dark-color-saturation: 20%;
  --dark-color-lightness: 20%;
  --dark-color-light-lightness: 30%;
  --dark-color-dark-lightness: 14%;
  --dark-color: hsl(var(--dark-color-hue), var(--dark-color-saturation), var(--dark-color-lightness)); // #29303d
  --dark-color-light: hsl(var(--dark-color-hue), var(--dark-color-saturation), var(--dark-color-light-lightness));
  --dark-color-dark: hsl(var(--dark-color-hue), var(--dark-color-saturation), var(--dark-color-dark-lightness));

  // -----------------------------
  // Secondary color palette
  // -----------------------------
  --blue: #278ACD;
  --green: hsl(134, 53%, 57%); //#57cb72
  --green-alpha15: rgba(87, 203, 114, 0.15);
  --green-dark: #38b756;
  --orange: #fc744f;
  --purple: #8c009a;
  --red: #FF0000;
  --red-alpha70: rgba(255, 82, 82, 0.7);
  --red-alpha15: rgba(255, 82, 82, 0.15);
  --yellow-hue: 38;
  --yellow-saturation: 100%;
  --yellow-lightness: 57%;
  --yellow: hsl(var(--yellow-hue), var(--yellow-saturation), var(--yellow-lightness)); // #FFAF22
  --yellow-alpha30: hsla(var(--yellow-hue), var(--yellow-saturation), var(--yellow-lightness), 0.3);
  --yellow-dark: hsl(var(--yellow-hue), var(--yellow-saturation), 50%);
  --yellow-dark-alpha30: hsla(var(--yellow-hue), var(--yellow-saturation), 50%, 0.3);
  --yellow-dark-alpha10: hsla(var(--yellow-hue), var(--yellow-saturation), 50%, 0.1);
  --yellow-lightest: #ffeccb;

  // Priority palette  
  --danger-hue: 0;
  --danger-saturation: 100%;
  --danger-lightness: 50%;
  --info-hue: 204;
  --info-saturation: 68%;
  --info-lightness: 48%;
  --warning-hue: 38;
  --warning-saturation: 100%;
  --warning-lightness: 57%;
  --success-hue: 134;
  --success-saturation: 53%;
  --success-lightness: 57%;
  --danger-color: hsl(var(--danger-hue), var(--danger-saturation), var(--danger-lightness)); //#FF0000
  --danger-color-alpha20: hsla(var(--danger-hue), var(--danger-saturation), var(--danger-lightness), 0.2);
  --danger-color-lightest: hsl(var(--danger-hue), var(--danger-saturation), 95%);
  --danger-color-dark: hsl(var(--danger-hue), var(--danger-saturation), 40%);
  --idt-info-color: var(--main-color-dark);
  --idt-info-color-alpha20: var(--main-color-dark-alpha20);
  --info-color: var(--blue);
  --info-color-alpha20: hsla(var(--info-hue), var(--info-saturation), var(--info-lightness), 0.2);
  --info-color-lightest: hsl(var(--info-hue), var(--info-saturation), 90%);
  --info-color-dark: hsl(var(--info-hue), var(--info-saturation), 40%);
  --info-color-darker: hsl(var(--info-hue), var(--info-saturation), 30%);
  --success-color: hsl(var(--success-hue), var(--success-saturation), var(--success-lightness)); //#57cb72
  --success-color-lightest: hsl(var(--success-hue), var(--success-saturation), 95%);
  --success-color-lighter: hsl(var(--success-hue), var(--success-saturation), 80%);
  --success-color-light: hsl(var(--success-hue), var(--success-saturation), 70%);
  --success-color-dark: hsl(var(--success-hue), var(--success-saturation), 40%);
  --success-color-darker: hsl(var(--success-hue), var(--success-saturation), 30%);
  --success-color-darkest: hsl(var(--success-hue), var(--success-saturation), 25%);
  --success-color-alpha10: hsla(var(--success-hue), var(--success-saturation), var(--success-lightness), 0.1);
  --success-color-alpha20: hsla(var(--success-hue), var(--success-saturation), var(--success-lightness), 0.2);
  --warning-color: hsl(var(--warning-hue), var(--warning-saturation), var(--warning-lightness));
  --warning-color-alpha20: hsla(var(--warning-hue), var(--warning-saturation), var(--warning-lightness), 0.2);
  --warning-color-lightest: hsl(var(--warning-hue), var(--warning-saturation), 95%);
  --warning-color-dark: hsl(var(--warning-hue), var(--warning-saturation), 40%);
  --warning-color-darker: hsl(var(--warning-hue), var(--warning-saturation), 30%);
  --warning-color-darkest: hsl(var(--warning-hue), var(--warning-saturation), 25%);

  // -----------------------------
  // Monochrome palette
  // -----------------------------
  // Black
  --black-hue: 0;
  --black-saturation: 0%;
  --black: hsl(var(--black-hue), var(--black-saturation), 0%);
  --black-alpha10: hsla(var(--black-hue), var(--black-saturation), 0%, 0.1);
  --black-alpha12: hsla(var(--black-hue), var(--black-saturation), 0%, 0.12);
  --black-alpha14: hsla(var(--black-hue), var(--black-saturation), 0%, 0.14);
  --black-alpha15: hsla(var(--black-hue), var(--black-saturation), 0%, 0.15);
  --black-alpha20: hsla(var(--black-hue), var(--black-saturation), 0%, 0.2);
  --black-alpha30: hsla(var(--black-hue), var(--black-saturation), 0%, 0.3);
  --black-alpha40: hsla(var(--black-hue), var(--black-saturation), 0%, 0.4);
  --black-alpha50: hsla(var(--black-hue), var(--black-saturation), 0%, 0.5);
  --black-alpha60: hsla(var(--black-hue), var(--black-saturation), 0%, 0.6);
  --black-alpha70: hsla(var(--black-hue), var(--black-saturation), 0%, 0.7);
  --black-alpha80: hsla(var(--black-hue), var(--black-saturation), 0%, 0.8);
  --black-alpha90: hsla(var(--black-hue), var(--black-saturation), 0%, 0.9);

  // White
  --white-hue: 0;
  --white-saturation: 0%;
  --white: hsl(var(--white-hue), var(--white-saturation), 100%);
  --white-alpha10: hsla(var(--white-hue), var(--white-saturation), 100%, 0.1);
  --white-alpha20: hsla(var(--white-hue), var(--white-saturation), 100%, 0.2);
  --white-alpha30: hsla(var(--white-hue), var(--white-saturation), 100%, 0.3);
  --white-alpha40: hsla(var(--white-hue), var(--white-saturation), 100%, 0.4);
  --white-alpha50: hsla(var(--white-hue), var(--white-saturation), 100%, 0.5);
  --white-alpha60: hsla(var(--white-hue), var(--white-saturation), 100%, 0.6);
  --white-alpha70: hsla(var(--white-hue), var(--white-saturation), 100%, 0.7);
  --white-alpha80: hsla(var(--white-hue), var(--white-saturation), 100%, 0.8);
  --white-alpha90: hsla(var(--white-hue), var(--white-saturation), 100%, 0.9);

  // Grey palette
  --grey-lightest: #F5F6FA;
  --grey-lighter: #EBEDF2;
  --grey-light: #DCDFE6;
  --grey: #CED1D9;
  --grey-alpha20: rgba(206, 209, 217, 0.2);
  --grey-alpha30: rgba(206, 209, 217, 0.3);
  --grey-dark: #9C9EA6;
  --grey-dark-alpha-20: rgba(156, 158, 166, 0.2);
  --grey-dark-alpha-50: rgba(156, 158, 166, 0.5);
  --grey-dark-alpha-75: rgba(156, 158, 166, 0.75);
  --grey-darker: #777980;
  --grey-darker-alpha15: rgba(119, 121, 128, 0.15);
  --grey-darkest: #383A40;
  --grey-darkest-alpha8: rgba(56, 58, 64, 0.08);
  --grey-darkest-alpha20: rgba(56, 58, 64, 0.2);
  --grey-darkest-alpha30: rgba(56, 58, 64, 0.3);
  --grey-darkest-alpha50: rgba(56, 58, 64, 0.5);
  --grey-darkest-alpha70: rgba(56, 58, 64, 0.7);
  --grey-darkest-alpha75: rgba(56, 58, 64, 0.75);

  // Red palette
  --red: #FF0000;
  --red-alpha15: rgba(255, 82, 82, 0.15);
  --red-alpha20: rgba(255, 82, 82, 0.2);
  --red-alpha70: rgba(255, 82, 82, 0.7);


  // -----------------------------
  // Video status palette
  // -----------------------------
  // Live
  --color-live: var(--red);
  --color-live-alpha20: var(--red-alpha20);
  --color-live-lightest: var(--secondary-color-lightest);

  // Replay
  --color-replay: var(--grey-darkest);
  --color-replay-alpha20: var(--grey-darkest-alpha20);
  --color-replay-alpha30: var(--grey-darkest-alpha30);
  --color-replay-alpha70: var(--grey-darkest-alpha70);
  --color-replay-lightest: var(--grey-light);

  // Scheduled
  --color-scheduled: var(--main-color);

  // Starting
  --color-starting: var(--yellow); // 255, 185, 74
  --color-starting-alpha20: rgba(255, 185, 74, 0.2);
  --color-starting-alpha30: rgba(255, 185, 74, 0.3);
  --color-starting-alpha40: rgba(255, 185, 74, 0.4);
  --color-starting-alpha50: rgba(255, 185, 74, 0.5);
  --color-starting-alpha70: rgba(255, 185, 74, 0.7);
  --color-starting-lightest: var(--yellow-lightest);

  // -----------------------------
  // Ticket color palette
  // -----------------------------
  --tck-color-1: #34495E;
  --tck-color-2: #5ECE8D;
  --tck-color-3: #1ABC9C;
  --tck-color-4: #16A085;
  --tck-color-5: #6C8687;
  --tck-color-6: #F1C40F;
  --tck-color-7: #E67E22;
  --tck-color-8: #CF4B04;
  --tck-color-9: #6CC0D3;
  --tck-color-10: #6FABD3;
  --tck-color-11: #278ACD;
  --tck-color-12: #0C639E;

  // -----------------------------
  // SSO Buttons palette
  // -----------------------------

  --facebook-sso-btn-bgcolor: #1A77F2;
  --facebook-sso-btn-bgcolor-hover: #679FF7;
  --facebook-sso-btn-color: var(--white);

  --ibm-sso-btn-bgcolor: #0E62FE;
  --ibm-sso-btn-bgcolor-hover: #0253E9;
  --ibm-sso-btn-color: var(--white);

  --okta-sso-btn-bgcolor: var(--main-color-light);
  --okta-sso-btn-bgcolor-hover: var(--main-color);
  --okta-sso-btn-color: var(--white);

  --redhat-sso-btn-bgcolor: #EE0D00;
  --redhat-sso-btn-bgcolor-hover: #CC0A00;
  --redhat-sso-btn-color: var(--white);

  --microsoft-entra-sso-btn-bgcolor: var(--main-color-light);
  --microsoft-entra-sso-btn-bgcolor-hover: var(--main-color);
  --microsoft-entra-sso-btn-color: var(--white);

  --linkedin-sso-btn-bgcolor: #0077B5;
  --linkedin-sso-btn-bgcolor-hover: #005E93;
  --linkedin-sso-btn-color: var(--white);

  --google-sso-btn-bgcolor: #4285f4;
  --google-sso-btn-bgcolor-hover: #1669F2;
  --google-sso-btn-color: var(--white);

  // -----------------------------
  // Social media color palette
  // -----------------------------
  --facebook-color: #1A77F2;
  --youtube-color: #FF0000;
  --linkedin-color: #0077B5;
  --twitter-color: #1DA1F2;

  // -----------------------------
  // Lobby colors
  // -----------------------------
  --lobby-danger-background: #FFDAD8;

  // -----------------------------
  // Project colors
  // -----------------------------
  --project-vote-0: var(--success-color);
  --project-vote-1: var(--warning-color);
  --project-vote-2: var(--danger-color);
}
