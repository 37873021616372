





















































































.idt-color-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--rem-size-10);
  margin-left: var(--rem-size-10);

  label {
    font-size: var(--rem-size-13);
    font-weight: var(--font-weight-medium);
  }

  &__tooltip {
    position: relative;
    width: var(--rem-size-28);
    height: var(--rem-size-28);

    .selection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: solid 2px var(--black);
      border-radius: 50%;
      cursor: pointer;
    }
    &__content {
      display: none;
      position: absolute;
      top: -100vh;
      right: -35px;
      width: 214px;
      padding: var(--rem-size-15);
      background-color: var(--white);
      box-shadow: 0 8px 10px -5px var(--black-alpha20),
                  0 8px 24px 2px var(--black-alpha14),
                  0 6px 30px 5px var(--black-alpha12);
      transition: top 0s 0.5s, opacity 0.5s;
      z-index: 9999;

      &:before {
        content: '';
        position: absolute;
        top: calc(-1 * var(--rem-size-10));
        right: 2.625rem; // 42px
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent var(--white) transparent;
      }
      &.show {
        display: block;
        top: 2.625rem; // 42px
      }
      label {
        display: block;
        color: var(--black);
        font-size: var(--rem-size-13);
        font-weight: 500;
        opacity: 0.4;
      }
      .items {
        line-height: 0;

        .item {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 40px;
          border-radius: 50%;
          vertical-align: middle;
          cursor: pointer;

          &--icon {
            height: 46px;
          }
          &.selected {
            border: solid 2px var(--main-color);
          }
          &__color {
            width: var(--rem-size-30);
            height: var(--rem-size-30);
            border-radius: var(--global-radius);
          }
        }
      }
    }
  }

  // inline tooltip
  &--inline {
    flex-flow: row;
    margin-left: 0;

    > label {
      padding-bottom: 0 !important;
    }

    .idt-color-tooltip__tooltip {
      margin-left: auto;
    }
  }

  // above position instead of bottom
  &--above {
    .idt-color-tooltip__tooltip {
      &__content {
        left: var(--rem-size-30);
        transform: translateX(-100%) translateY(calc(-60px - 100%));

        &:before {
          top: auto;
          right: 0.5625rem;
          bottom: -0.5625rem;
          left: auto;
          border-width: 10px 7.5px 0 7.5px;
          border-color: var(--white) transparent transparent transparent;
        }
      }
    }
  }
}
