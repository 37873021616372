.fo-lobby-messages {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.fo-lobby-messages__content-wrapper {
  display: flex;
  flex-flow: column;
  position: relative;
  flex: auto;
  overflow: hidden;
}

.fo-lobby-messages__content-wrapper--loading {
  padding-top: var(--rem-size-10);
}

.fo-lobby-messages__messages {
  display: flex;
  flex: auto;
  height: 100%;
}

.fo-lobby-messages__messages__scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-top: var(--rem-size-12);
  transform: translate3d(0, 0, 0);
}

.fo-lobby-messages__messages>* {
  width: 100%;
}

.fo-lobby-messages__messages>* .live-chat-message {
  padding: 0 var(--rem-size-24);
}

.fo-lobby-messages__unread-button {
  display: inline-flex;
  width: auto;
  position: absolute;
  padding: var(--rem-size-9) var(--rem-size-24);
  left: 50%;
  top: var(--rem-size-12);
  background: var(--lobby-danger-background);
  color: var(--danger-color);
  font-weight: var(--font-weight-bold);
  z-index: 10;
  transform: translateX(-50%);
  border-radius: 5rem;
}

.fo-lobby-messages__unread-button--bottom {
  top: auto;
  bottom: var(--rem-size-12);
}

.fo-lobby-messages__unread-button:hover {
  color: var(--danger-color);
  background: var(--lobby-danger-background);
}

.live-chat-message--in-lobby .live-chat-message-actions {
  padding-right: var(--rem-size-20);
}

.live-chat-message--in-lobby .live-chat-message-actions .idt-button-group>span>.action {
  border-radius: 100% !important;
  border: 1px solid var(--grey-light) !important;
  background: var(--white) !important;
  margin-right: 5px;
}

.live-chat-message--in-lobby .live-chat-message-actions .idt-button-group>span>.action svg:not(.idea-ht-emoji) {
  width: var(--rem-size-10) !important;
}

