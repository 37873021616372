@mixin remSize() {
  --rem-size-1: 0.0625rem;
  --rem-size-2: 0.125rem;
  --rem-size-3: 0.1875rem;
  --rem-size-4: 0.25rem;
  --rem-size-5: 0.315rem;
  --rem-size-6: 0.375rem;
  --rem-size-7: 0.438rem;
  --rem-size-8: 0.5rem;
  --rem-size-9: 0.5625rem;
  --rem-size-10: 0.625rem;
  --rem-size-11: 0.685rem;
  --rem-size-12: 0.75rem;
  --rem-size-13: 0.8125rem;
  --rem-size-14: 0.875rem;
  --rem-size-15: 0.9375rem;
  --rem-size-16: 1rem;
  --rem-size-17: 1.063rem;
  --rem-size-18: 1.125rem;
  --rem-size-19: 1.1875rem;
  --rem-size-20: 1.25rem;
  --rem-size-21: 1.315rem;
  --rem-size-22: 1.375rem;
  --rem-size-23: 1.4375rem;
  --rem-size-24: 1.5rem;
  --rem-size-25: 1.5625rem;
  --rem-size-26: 1.625rem;
  --rem-size-27: 1.6875rem;
  --rem-size-28: 1.75rem;
  --rem-size-29: 1.8125rem;
  --rem-size-30: 1.875rem;
  --rem-size-31: 1.9375rem;
  --rem-size-32: 2rem;
  --rem-size-35: 2.1875rem;
  --rem-size-36: 2.25rem;
  --rem-size-40: 2.5rem;
  --rem-size-42: 2.625rem;
  --rem-size-48: 3rem;
  --rem-size-64: 4rem;
}
