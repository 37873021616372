















































































































































.change-password {
  &__description {
    margin: var(--rem-size-25) 0px;
    padding: var(--rem-size-8);
    border-left: 4px solid var(--main-color);
  }
}

.link__forgot {
  position: relative;
  text-align: right;

  &__button {
    color: var(--main-color);
    font-size: var(--rem-size-14);
    text-decoration: underline;
    cursor: pointer;
  }
}
