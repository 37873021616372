




















































.idt-sponsor-stats-next-refresh {
  display: flex;
  font-size: var(--rem-size-12);
  color: var(--grey-dark);
}
