





























































































.card-state {
  display: flex;
  flex-wrap: wrap;
  gap: var(--rem-size-4);
  margin: var(--rem-size-4);

  &__badge {
    display: inline-block;
    max-width: 98%;
    padding: var(--rem-size-3) var(--rem-size-8);
    border-radius: var(--rem-size-5);
    font-size: var(--rem-size-14);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--white);
    border: 1px solid var(--white);
  }

  &:first-child {
    margin-right: 0;
  }

  &__draft {
    background-color: var(--main-color);
  }

  &__hires {
    background-color: #fc744f;
  }

  &__place {
    color: var(--main-color);
    background-color: var(--white);
    border: 1px solid var(--main-color);
  }

}
