.idt-simple-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 12px 24px 12px;
  color: var(--grey-darker);
  background-color: var(--white);
  box-shadow: 0px 2px 3px var(--black-alpha20);
  border-bottom: 2px solid rgba(0,0,0,0);
  transition: border .25s ease-in-out;
}

.idt-simple-card:hover {
  border-bottom-color: var(--main-color);
}

.idt-simple-card.no-link {
  cursor: default;
  pointer-events: none;
}

.idt-simple-card__image-container {
  display: flex;
  justify-content: center;
  padding-top: 8%;
  padding-left: 12.5%;
  padding-right: 12.5%;
  overflow: hidden;
}

.idt-simple-card__image {
  max-width: 300px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.idt-simple-card__name {
  margin: 0 10% 0 10%;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

