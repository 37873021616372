.idt-code-block {
  position: relative;
}

.idt-code-block__code {
  display: block;
  padding: .5rem 3rem .5rem .5rem;
  border: 1px solid var(--grey);
  background-color: var(--grey-lightest);
  font-size: .875rem;
  line-height: 1.6;
  word-break: break-all;
}

.idt-code-block__copy {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 2.5rem;
}

