.fo-lobby-team-member {
  display: flex;
  flex-flow: column;
  background: var(--white);
  border-radius: var(--global-radius);
  padding: var(--rem-size-24);
  border: 1px solid var(--grey-dark-alpha-50);
  margin-bottom: var(--rem-size-8);
}

.fo-lobby-team-member__infos {
  display: flex;
  gap: var(--rem-size-16);
}

.fo-lobby-team-member__infos :last-child {
  flex: auto;
}

.fo-lobby-team-member__name {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--rem-size-4);
}

.fo-lobby-team-member__buttons {
  display: flex;
  gap: var(--rem-size-8);
  margin-top: var(--rem-size-25);
}

.fo-lobby-team-member__buttons>* {
  width: 50%;
}

.fo-lobby-team-member__buttons :first-child:last-child {
  width: 100%;
}

