.idt-survey-card-admin-menu {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-width: 205px;
  padding: 1rem var(--rem-size-28);
  z-index: 1;
  box-shadow: 0px 3px 6px rgba(0,0,0,.1607843137);
  background: var(--white);
}

.idt-survey-card-admin-menu__item {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
  color: var(--black);
  cursor: pointer;
}

.idt-survey-card-admin-menu__item__icon {
  margin-right: var(--rem-size-15);
}

.idt-survey-card-admin-menu__item.disabled {
  opacity: .35;
  pointer-events: none;
}

