



















































































.conference-publisher-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(
    (var(--vh, 1vh) * 100) - var(--conference-footer-height)
  ) !important;
  overflow: hidden;
}
