























































































































.additional-checkbox {
  margin: 1rem 0 var(--rem-size-10) 0;
  text-align: left;

  &__container {
    margin-bottom: 6px;

    input {
      display: none;

      &.error {
        & + label .box {
          border: 1px solid var(--red);
        }
      }
      & + label {
        display: block;
        position: relative;
        min-height: 22px;
        min-width: 100px;
        margin: 0;
        color: var(--grey-darker);
        font-weight: normal;
        font-size: 0.875rem;
        font-weight: 500;
        word-break: break-word;

        .box {
          content: '';
          display: inline-block;
          vertical-align: top;
          margin-right: 7px;
          margin-top: 2px;
          height: 20px;
          width: 20px;
          border: 1px solid var(--grey);
          border-radius: 3px;
        }
        .content {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 35px);

          ::v-deep p:last-child {
            margin-bottom: 0;
          }
        }
        .idt-icon {
          content: '';
          position: absolute;
          display: block;
          left: 4px;
          top: 5px;
          color: transparent;
        }
      }
      &:checked + label .box {
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
      }
      &:checked + label .idt-icon {
        color: white;
      }
    }
  }
}
