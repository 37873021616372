.conference-modal {
  display: flex;
  justify-items: center;
  flex-flow: column;
  width: 100%;
}

.conference-modal__content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.conference-modal__content__block {
  flex: 1 1 auto;
  position: relative;
  width: 50%;
}

@media only screen and (min-width: 640px) {
  .conference-modal__content__block {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 1024px) {
  .conference-modal__content__block {
    max-width: 600px;
  }
}

.conference-modal__content__block.has-separator:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  border-right: 1px solid var(--grey);
  transform: translateY(-50%);
}

.conference-modal__footer {
  margin-top: 50px;
  text-align: center;
}

.conference-modal__save-btn {
  min-width: 235px;
  height: 55px;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

