






























































.idt-chip {
  display: flex;
  align-items: center;
  padding: 5px 14px;
  background: var(--white);
  border-width: 2px;
  border-style: solid;
  border-radius: 14px;
  line-height: 1;
  font-size: var(--rem-size-13);
  font-weight: var(--font-weight-bold);
  text-align: center;
  text-transform: uppercase;

  &__image-icon {
    margin-right: 8px;
    border-radius: 50%;
  }
}
