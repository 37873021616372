




































































.multi-speaker-list {

  &__counter {
    font-weight: var(--font-weight-bold);
    color: var(--black);
    border-bottom: 1px solid #CACACA;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.3rem 0;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #CACACA;
    }
    &__avatar {
      flex: 0 0 auto;
    }
    &__speaker {
      padding-left: 0.45rem;
      color: var(--black);

      &__name {
        font-family: var(--title-font-family);
        font-weight: var(--font-weight-bold);
        font-size: var(--rem-size-16);
        line-height: 1.2;
      }
      &__company {
        font-size: var(--rem-size-13);
        line-height: 1;
      }
    }
  }
}
