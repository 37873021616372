











































































@import '@/styles/mixins';

.event-topics {
  &__list {
    @include respond-to(max-medium) {
      row-gap: var(--rem-size-24);
    }
    @include respond-to(medium) {
      row-gap: var(--rem-size-48);
    }
  }
  &__join {
    margin-top: 20px;
    text-align: center;
  }
}
