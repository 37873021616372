.gamification-history-row {
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-bottom: 10px;
  background-color: var(--white);
  border-radius: 5px;
  overflow: hidden;
}

.gamification-history-row--reward {
  background-color: var(--main-color-lighter);
}

.gamification-history-row--targetable:hover {
  cursor: pointer;
}

.gamification-history-row__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  background-color: var(--grey-lighter);
  border-radius: 50%;
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__icon {
    width: 30px;
    height: 30px;
    margin: auto .5rem;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__icon {
    width: 40px;
    height: 40px;
    margin: auto 1rem;
  }
}

.gamification-history-row__icon--reward {
  color: var(--white);
  background-color: var(--main-color);
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__icon ::v-deep .ideacon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__icon ::v-deep .ideacon.idea-ht-bubbles {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__icon ::v-deep .ideacon.idea-ht-bubbles {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.gamification-history-row__content {
  width: 0;
  padding: .75rem 0;
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__content {
    flex-grow: 8;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__content {
    flex-grow: 6;
  }
}

.gamification-history-row__content .content__title {
  width: 100%;
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__content .content__title {
    font-size: var(--rem-size-16);
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__content .content__title {
    font-size: var(--rem-size-18);
  }
}

.gamification-history-row__content .content__title--reward {
  color: var(--main-color);
}

.gamification-history-row__content .content__description {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__content .content__description {
    font-size: var(--rem-size-12);
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__content .content__description {
    font-size: var(--rem-size-14);
  }
}

.gamification-history-row__content .content__description--reward {
  color: var(--black);
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__content .content__description__type {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__flag {
    flex-grow: 1;
    margin: auto .5rem;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__flag {
    margin: auto 1rem;
  }
}

.gamification-history-row__flag .flag__reward {
  vertical-align: middle;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 50px;
  font-size: var(--rem-size-18);
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__flag .flag__reward {
    padding: .25rem .5rem;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-history-row__flag .flag__reward {
    padding: .25rem 1rem;
  }
}

@media only screen and (max-width: 991px) {
  .gamification-history-row__flag .gamification-points {
    padding: .25rem .5rem;
  }
}

@media only screen and (max-width: 991px) {
  ::v-deep .gamification-history-row__flag .gamification-points__value {
    margin-left: .5rem;
  }
}

