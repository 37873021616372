.idt-duration-picker {
  position: relative;
}

.idt-duration-picker__input {
  position: relative;
}

.idt-duration-picker__input__text {
  background: var(--white);
  cursor: pointer;
}

.idt-duration-picker__input__icon ::v-deep .ideacon {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px;
}

