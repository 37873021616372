





















































































































































































@import '@/styles/mixins/index';

.modal {
  --modal-background: var(--white);

  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--black-alpha50);
  z-index: 99999;
  overflow-y: auto;
}

.modal-dialog,
.modal-confirm-message-delete,
.modal-save-before-leave {
  position: relative;
  top: 30%;
  max-width: 600px;
  margin: auto;
  background-color: var(--modal-background);
  border: 0;
  z-index: 100000;
  // Workaround android browser z-index bug
  backface-visibility: hidden;

  @include respond-to(max-small) {
    width: 90%;
  }
  @include respond-to(small) {
    width: 50%;
  }

  &__remove-message {
    white-space: pre-line;
  }
  // Make sure rows don't have a min-width on them
  .column,
  .columns {
    min-width: 0;
  }
  // Strip margins from the last item in the modal
  > :last-child {
    margin-bottom: 0;
  }
}
.modal-body {
  padding: 1rem;
}
.modal-footer {
  padding: 1rem;
  border-top: 1px solid var(--grey-light);

  .button {
    margin-bottom: 0;
  }
}
