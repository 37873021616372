





































































.idt-table-cell--array-preview {
  margin-right: var(--rem-size-5);
}
