.fo-lobby-info {
  height: 100%;
  padding: 0 var(--rem-size-20);
  color: var(--black);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
  overflow-x: hidden;
  overflow-y: auto;
}

.fo-lobby-info__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
  padding-top: var(--rem-size-16);
  margin-bottom: var(--rem-size-16);
}

.fo-lobby-info__load-more {
  width: 100%;
  font-weight: var(--font-weight-bold);
  text-align: center;
  padding: var(--rem-size-9) var(--rem-size-20);
  color: var(--black);
  cursor: pointer;
}

.fo-lobby-info__edit-btn {
  width: var(--rem-size-14);
  height: var(--rem-size-14);
  color: var(--black);
  cursor: pointer;
}

.fo-lobby-info__edit-btn:hover {
  color: var(--grey-darker);
}

.fo-lobby-info__desc {
  margin-bottom: var(--rem-size-30);
}

.fo-lobby-info__desc img {
  display: block;
  width: var(--rem-size-24);
  height: var(--rem-size-24);
  object-fit: cover;
  margin-bottom: var(--rem-size-12);
  border-radius: 100%;
}

.fo-lobby-info__resources {
  margin-bottom: var(--rem-size-30);
}

.fo-lobby-resource {
  display: flex;
  align-items: center;
  margin-bottom: var(--rem-size-8);
  padding: var(--rem-size-14) var(--rem-size-16);
  background: var(--white);
  border: 1px solid var(--grey-dark-alpha-50);
  border-radius: var(--global-radius);
  font-weight: var(--font-weight-bold);
  transition: background-color var(--default-duration) ease-in-out;
}

.fo-lobby-resource:hover {
  background-color: var(--grey-lightest);
}

.fo-lobby-resource :first-child {
  flex: auto;
}

.fo-lobby-resource__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--rem-size-36);
  height: var(--rem-size-36);
  border-radius: 100%;
  background: var(--main-color-dark-alpha20);
  color: var(--main-color-dark);
}

