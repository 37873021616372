.idt-profile-ticket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--global-border-radius);
  padding: 0 26px;
  height: 79px;
  width: 100%;
  background: var(--background-color);
  margin-bottom: 11px;
}

.idt-profile-ticket.inactive {
  background: var(--grey-lighter);
}

.idt-profile-ticket.inactive .idt-profile-ticket__title {
  color: var(--black-alpha-50);
}

.idt-profile-ticket.inactive .idt-profile-ticket__icon-container {
  color: var(--grey);
}

.idt-profile-ticket.inactive .ticketing-upgrade-button {
  background-color: var(--grey);
}

.idt-profile-ticket.selected {
  background: var(--white);
  border: 2px solid var(--ticket-color);
}

.idt-profile-ticket.selected .idt-profile-ticket__icon-container {
  background-color: var(--ticket-color);
  color: #fff;
}

.idt-profile-ticket__left {
  display: flex;
  align-items: center;
}

.idt-profile-ticket__icon-container {
  display: flex;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin-right: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--ticket-color);
}

.idt-profile-ticket__title {
  margin: 0;
  color: var(--ticket-color);
  font-weight: var(--font-weight-bold);
}

.idt-profile-ticket .ticketing-upgrade-button {
  width: 134px !important;
  height: 30px !important;
  margin-top: 0 !important;
  flex-shrink: 0;
  font-size: 14px !important;
  background-color: var(--ticket-color);
}

