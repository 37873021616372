
































































































































































































@import '@/styles/mixins/index';

.idt-profile-security {
  .vue-tel-input {
    display: flex !important;
  }
  &__password {
    align-items: flex-end !important;

    &__change-button {
      margin-bottom: 0;
    }
    &__cancel-button {
      margin-bottom: 0;
      color: var(--black-alpha70) !important;
      border: none !important;
    }
    &__input {
      position: relative;

      &__toggle {
        position: absolute;
        right: 1px;
        top: 1px;
        bottom: 1px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        background: var(--white);
      }
      .password-rules {
        position: absolute;
        overflow: visible;

        &__content {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
  .idt-city-input {
    &__input-container {
        display: flex;
        align-items: center;
    }
    &__search {
      flex-basis: 75%;
      min-width: 50%;
    }
    &__timezone {
      flex-basis: 25%;
      min-width: 110px;
    }
  }
}
