.custom-rtmp-publisher-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--grey-darkest);
  background-color: var(--grey-lighter);
}

.crpc__admin-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 75%;
}

@media only screen and (min-width: 480px)and (max-width: 1199px) {
  .crpc__admin-panel {
    flex-direction: column;
    height: 100%;
  }

  .crpc__admin-panel .crpc__admin-panel__settings {
    overflow-y: auto;
  }

  .crpc__admin-panel .crpc__admin-panel__settings,
  .crpc__admin-panel .crpc__admin-panel__live-preview {
    width: 100%;
  }
}

.crpc__admin-panel__settings {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  margin: 10px;
  background-color: var(--white);
  border-radius: var(--global-radius);
  color: var(--grey-darkest);
}

.crpc__admin-panel__settings__form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.crpc__admin-panel__settings__form__title {
  margin: 20px 0;
  color: var(--black);
  font-size: var(--rem-size-22);
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-overflow: ellipsis;
}

.crpc__admin-panel__settings__form__label,
::v-deep .crpc__admin-panel__settings__form__one-link .content {
  color: var(--black);
  font-size: var(--rem-size-14);
}

.crpc__admin-panel__settings__form__label {
  font-weight: var(--font-weight-medium);
}

::v-deep .crpc__admin-panel__settings__form__one-link .content {
  font-weight: var(--font-weight-normal);
}

.crpc__admin-panel__settings__form .idt-input-container {
  padding-bottom: 10px;
}

.crpc__admin-panel__settings__form__field__link {
  margin: 0 5px;
  color: var(--main-color);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.crpc__admin-panel__settings__form__field__textarea ::v-deep textarea {
  border-radius: var(--global-radius);
}

.crpc__admin-panel__settings__form__field__access-button {
  height: 35px;
  margin-top: 1rem;
  padding: .7rem 1rem;
}

.crpc__admin-panel__settings__form__field__update-button {
  margin-top: var(--rem-size-20);
  margin-bottom: var(--rem-size-10);
  text-align: center;
}

.crpc__admin-panel__settings__form__field__update-button button {
  width: 90px;
  height: 35px;
  margin-left: .5rem;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 23px;
  font-weight: var(--font-weight-bold);
  text-align: center;
  cursor: pointer;
}

.crpc__admin-panel__settings__form ::v-deep .idt-text-copy.one-link {
  height: 100px;
}

.crpc__admin-panel__live-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  margin: 5px;
}

.crpc__admin-panel__live-preview__title {
  margin-bottom: 10px;
  color: var(--grey-darkest);
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
}

.crpc__admin-panel__live-preview__container {
  width: 100%;
}

