











































.idt-sponsor-stats-metrics-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: var(--rem-size-10);
  padding: var(--rem-size-14) var(--rem-size-15);
  background-color: var(--white-alpha10);
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--main-color-alpha30);
    cursor: pointer;
  }
  &--selected {
    margin-top: var(--rem-size-8);
    margin-bottom: var(--rem-size-20);
    border: 2px solid var(--main-color);

    .idt-sponsor-stats-metrics-row__label {
      display: flex;
      align-items: center;

      span {
        margin-left: var(--rem-size-15);
      }
    }
  }
  &__value {
    font-size: var(--rem-size-18);
  }
}
