.idt-account-tags {
  &__title {
    margin-top: 1.8rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: var(--font-weight-medium);
    text-align: center;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;

    &.required-field {
      box-shadow: 0 0 0 1px var(--secondary-color);
    }

    &__item {
      display: inline-block;
      flex: 1 1 auto;
      min-width: calc((100% - 2rem) / 4);
      height: 30px;
      margin: 0.25rem;
      padding: 0.5rem;
      color: var(--grey);
      background-color: var(--grey-alpha20);
      border: 0;
      border-radius: 0;
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.1;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover,
      &.selected {
        color: var(--main-color-dark);
        background-color: var(--main-color-alpha20);
      }
    }
  }
}
