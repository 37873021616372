






















































































































































































@import '@/styles/mixins/index';
::v-deep .idt-dropdown--pane {
  padding: 0;
}
.idt-time-picker-input {
  user-select: none;

  &__dropdown {
    width: 100%;
  }
  &__input {
    position: relative;

    & ::v-deep .idt-input-container {

      label {
        display: block;
        line-height: var(--rem-size-30);
      }

      input {
        width: 100%;
        height: 40px;
        padding: var(--rem-size-10) var(--rem-size-16);
        color: var(--grey-darker);
        border: 1px solid var(--grey-light);
        border-radius: var(--global-radius);
        font-size: var(--rem-size-14);
        box-sizing: border-box;
        outline: none;

        &:focus {
          border-color: var(--main-color);
        }

        &[readonly] {
          background: var(--white);
          cursor: text;
        }
      }

      .idt-icon {
        color: var(--grey-darker);
        font-weight: var(--font-weight-medium);
        line-height: 24px;
      }
    }
  }
  &__container {
    padding: var(--rem-size-16) 38px;
    color: var(--black);
    background: var(--white);
    border-radius: var(--global-radius);
    box-shadow: 0px 2px 3px var(--black-alpha15);
    z-index: 2;

    &__title {
      margin-bottom: var(--rem-size-6);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-medium);
      line-height: var(--rem-size-24);
    }

    &__picker {
      display: flex;

      &__separator {
        width: var(--rem-size-10);
        align-self: center;
        margin: 0 var(--rem-size-1);
        color: var(--black);
        font-size: var(--rem-size-30);
      }

      &__meridiem {
        display: flex;
        flex-direction: column;
        margin-left: var(--rem-size-9);

        & > span {
          @include respond-to(max-medium) {
            height: 57px;
            line-height: 57px;
          }
          width: 54px;
          height: 34px;
          line-height: 34px;
          color: var(--black);
          background: var(--grey-lighter);
          border-radius: var(--global-radius);
          font-size: var(--rem-size-20);
          text-align: center;
          cursor: default;

          &[disabled] {
            color: var(--grey-light);
            background: var(--white);
            border: 1px solid var(--grey-light);
            cursor: pointer;

            &:hover {
              color: var(--grey-darker);
              border-color: var(--grey-darker);
            }
          }

          &:not(:last-child) {
            margin-bottom: var(--rem-size-8);
          }
        }
      }
    }
  }

  ::v-deep .idt-input-container label {
    color: var(--grey-darkest);
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-medium);
  }
}
