






















































































.submenu-account-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--rem-size-18);
  padding: 0 var(--rem-size-4);
  color: var(--grey-darker);
  border-left: var(--rem-size-2) solid transparent;
  border-radius: 0;
  font-family: var(--text-font-family);
  font-size: var(--rem-size-14);
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &.active {
    border-color: var(--main-color);
  }
  &:hover &__icon,
  &.active &__icon {
    color: var(--main-color);
  }
  &__wrapper {
    margin: var(--rem-size-4) 0;
    padding: var(--rem-size-4);
  }
  &__icon {
    margin-left: var(--rem-size-8);
  }
}
