





































































@import '@/styles/mixins/index';

.event-edit-section {
  display: flex;
  align-items: center;
  gap: var(--rem-size-16);
  position: absolute;
  padding: 0.5rem 1rem;
  background-color: var(--white);

  @include respond-to(max-medium) {
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--main-color-alpha70);
    text-align: right;
  }
  @include respond-to(medium) {
    top: 30px;
    right: 30px;
    border-radius: var(--global-radius);
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: var(--rem-size-4);
    font-size: 1.2rem;
  }
}
