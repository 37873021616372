.idt-history {
  height: 100%;
}

.idt-history__toolbar {
  display: flex;
  flex-direction: column;
  padding: .5rem 1rem;
}

.idt-history__toolbar__open-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 4px;
  font-size: .875rem;
  cursor: pointer;
  transition: all .3s ease;
}

.idt-history__toolbar__open-btn__arrow {
  display: none;
  position: absolute;
  left: 20px;
}

.idt-history__toolbar__open-btn__text {
  margin-right: 8px;
  font-weight: var(--font-weight-medium);
}

.idt-history__toolbar__open-btn:hover {
  background-color: var(--main-color-dark);
}

.idt-history__toolbar__open-btn:hover .idt-history__toolbar__open-btn__arrow {
  display: block;
}

.idt-history__toolbar__searchbar input {
  height: 40px;
  margin: 0;
  padding: .6566rem 1.5rem .6566rem 1rem;
  border-color: var(--grey-lightest);
  border-radius: 4px;
  font-size: .9rem;
}

.idt-history__list {
  padding: 0 1rem 1rem 1rem;
  overflow-y: auto;
}

@media only screen and (max-width: 639px) {
  .idt-history__list {
    max-height: calc(100vh - 150px);
  }
}

@media only screen and (min-width: 640px) {
  .idt-history__list {
    max-height: calc(100% - 150px);
  }
}

.idt-history__empty-list {
  color: var(--grey-dark);
  font-size: .7rem;
}

.idt-history__empty-list .ideacons {
  padding: 0 1.25rem 0 .75rem;
  font-size: 1.1rem;
  vertical-align: sub;
}

.idt-history .idt-history-card {
  position: relative;
  min-height: 115px;
  margin-bottom: .5rem;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 var(--black-alpha10);
  cursor: pointer;
  transition: all .3s ease;
}

.idt-history .idt-history-card--hiring {
  border-bottom: 2px solid var(--main-color);
}

.idt-history .idt-history-card:hover {
  box-shadow: 0 2px 4px 0 var(--black-alpha20);
}

.idt-history .idt-history-card__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  min-height: 115px;
  padding: 25px 15px;
}

.idt-history .idt-history-card__avatar {
  flex: 0 0 auto;
  position: relative;
}

.idt-history .idt-history-card__avatar__project {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.idt-history .idt-history-card__avatar__project--no-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--grey-darkest-alpha20);
  font-size: 2.25rem;
  text-transform: uppercase;
}

.idt-history .idt-history-card__unread {
  width: 21px;
  height: 21px;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 50%;
  font-size: .7rem;
  text-align: center;
  line-height: 21px;
  position: absolute;
  top: 0;
  transform: translate(-4px, -10px);
  left: 0;
}

.idt-history .idt-history-card__content {
  flex: 1 1 auto;
  padding: 0 .75rem;
}

.idt-history .idt-history-card__title {
  margin-bottom: .25rem;
  color: var(--grey-darkest);
  line-height: 1;
}

.idt-history .idt-history-card__title .user-tag {
  color: var(--grey-dark);
  text-transform: uppercase;
}

.idt-history .idt-history-card__title .user-tag--mentor {
  color: var(--main-color);
}

.idt-history .idt-history-card__title .idt-history .idt-history-card__sub-title {
  display: block;
  margin-top: .25rem;
  color: var(--grey-darkest);
  font-size: .9rem;
  font-weight: var(--font-weight-bold);
}

.idt-history .idt-history-card__last-msg {
  color: var(--grey-dark);
  font-size: .75rem;
  word-break: break-all;
  hyphens: auto;
}

.idt-history .idt-history-card__last-msg.unread {
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.idt-history .idt-history-card__icon {
  color: var(--main-color);
}

.idt-history .idt-history-card__status {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 64px;
  height: 18px;
  padding: 2px 5px;
  color: var(--white);
  background-color: var(--grey);
  font-size: .7rem;
  text-transform: uppercase;
  line-height: 1;
}

.idt-history .idt-history-card__status--conf {
  background-color: var(--warning-color);
}

.idt-history .idt-history-card__status--accepted {
  color: var(--success-color);
  background-color: var(--success-color-alpha20);
}

.idt-history .idt-history-card__status--declined {
  color: var(--secondary-color);
  background-color: var(--secondary-color-alpha20);
}

.idt-history .idt-history-card__status-project-logo {
  width: 14px;
  height: 14px;
  display: inline-block;
}

