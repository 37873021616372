.login-modal__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  margin-bottom: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--main-color);
}

.login-modal__logo {
  max-width: 140px;
  max-height: 55px;
}

.login-modal__title {
  margin-bottom: var(--rem-size-16);
  color: var(--main-color);
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
}

.login-modal__subtitle {
  font-size: var(--rem-size-20);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.login-modal__icon {
  margin: 2rem auto;
  text-align: center;
}

.login-modal__content {
  margin-bottom: 1rem;
  font-size: var(--rem-size-18);
  text-align: center;
}

.login-modal__submit {
  text-transform: uppercase;
}

