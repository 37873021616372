

















































































@import '@/styles/mixins/index';

.ticketing-upgrade-modal {
  width: 100%;
  padding: 2.5rem 1rem 4.25rem;

  &__title {
    color: var(--grey-darkest);
    font-weight: var(--font-weight-light);
    text-align: center;
    text-transform: uppercase;

    @include respond-to(max-medium) {
      font-size: 1.125rem;
    }
    @include respond-to(medium) {
      font-size: 2.375rem;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 83px;
      left: 50%;
      margin-top: 28px;
      border-bottom: solid var(--main-color);
      transform: translateX(-50%);

      @include respond-to(max-medium) {
        margin-top: 9px;
        border-bottom-width: 1px;
      }
      @include respond-to(medium) {
        margin-top: 28px;
        border-bottom-width: 3px;
      }
    }
  }
  &__tickets {
    @include respond-to(max-medium) {
      margin-top: 20px;
    }
    @include respond-to(medium) {
      margin-top: 40px;
    }
  }
}
