















































































@import '@/styles/mixins/index';

.gamification-reward-row {
  display: flex;
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;

  @include respond-to(max-slarge) {
    flex-direction: column;
  }
  @include respond-to(slarge) {
    flex-direction: row;
    height: 90px;
  }

  &__image {

    &.no-image{
      filter: grayscale(100%);
      border-right: 1px solid var(--grey-light);
    }
    img {
      height: 90px;
      object-fit: cover;

      @include respond-to(max-slarge) {
        width: 100%;
      }
      @include respond-to(slarge) {
        width: 160px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 6;

    @include respond-to(max-slarge) {
      padding: 1rem 1.5rem;
    }
    @include respond-to(slarge) {
      width: 0;
      padding: 0.5rem 0 0.5rem 1rem;
    }

    &__title {
      width: 100%;
      color: var(--black);
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-bold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__description {
      width: 100%;
      font-size: var(--rem-size-14);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__progress {
    flex-grow: 3;

    @include respond-to(max-slarge) {
      padding: 1rem 1.5rem;
      border-top: 1px solid var(--grey);
    }
    @include respond-to(slarge) {
      width: 0;
      margin: auto;
      padding: 1rem;
    }

    .glyphicons {
      vertical-align: middle;

      &.glyphicons-unlock {
        color: var(--main-color);
      }
    }
    &__locked {
      color: var(--black);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-bold);
      vertical-align: middle;
      padding: 6px 0;
    }
  }
}
