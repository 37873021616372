






































































@import '@/styles/mixins/index';

.gamification-history {
  &__no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__label {
      color: var(--grey-dark);
      font-size: var(--rem-size-20);
    }
    &__icon {
      color: var(--grey-dark);
    }
  }
}
