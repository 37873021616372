.loader-agenda {
  display: inline-block;
  position: absolute;
  width: 100%;
  background-color: var(--grey-lighter);
  z-index: 100;
}

.skeleton {
  height: 127px;
}

.vue-grid-item {
  transition: none;
}

.vue-grid-layout {
  transition: none;
}

