











































































































@import '@/styles/mixins';

.bo-vertical-menu {
  position: fixed;
  width: 90px;
  height: 100%;
  background-color: var(--main-color);

  @include respond-to(max-medium) {
    transform: translateX(-100%);
    transition: all 0.3s ease;

    &.open {
      transform: translateX(0);
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: center;
    list-style-type: none;
  }
  &__link {
    display: block;
    position: relative;
    height: 70px;
    padding: 1rem 0.5rem 0.5rem;
    color: var(--white);
    font-size: 0.8rem;
    transition: all 0.3s ease;

    &--too-long {
      padding: 0.7rem 0.5rem 0.5rem;
      line-height: 14px;

      .idt-icon ::v-deep .ideacon {
        margin-bottom: 0.2rem;
      }
    }
    &:hover,
    &.active {
      color: var(--main-color);
      background-color: var(--main-color-lighter);
    }
  }
}
