





















































































.fo-project-section-how-it-works-view {
  text-align: center;
  padding: var(--rem-size-20);

  &__title {
    margin-bottom: var(--rem-size-16);
    color: black;
  }

  &__description {
    margin-bottom: var(--rem-size-16);
  }

  &__cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &__card {
      margin: var(--rem-size-16);
    }
  }
}
