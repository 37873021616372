.idt-button-group>* {
  float: left;
}

.idt-button-group>*:not([type=button]):only-child button,
.idt-button-group>button {
  border-radius: 5px;
}

.idt-button-group>*:not(:first-child):not(:last-child):not(:only-child) button,
.idt-button-group>button:not(:first-child):not(:last-child):not(:only-child) {
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0;
}

.idt-button-group>*:first-child:not(:only-child) button,
.idt-button-group>button:first-child:not(:only-child) {
  border-right: 0 !important;
  border-radius: 5px 0 0 5px;
}

.idt-button-group>*:last-child:not(:only-child) button,
.idt-button-group>button:last-child:not(:only-child) {
  border-left: 0 !important;
  border-radius: 0 5px 5px 0;
}

