









































































.idt-favorite-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0.70rem;
  color: var(--main-color);
  border: 1.5px solid var(--main-color);
  border-radius: 23px;
  font-weight: var(--font-weight-bold);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .idt-icon {
    margin-top: -3px;
    color: var(--main-color);
  }
  &.stared {
    color: var(--white);
    background: var(--main-color);

    .idt-icon {
      color: var(--white);
    }
  }

  &__label {
    margin-left: 10px;
  }
}
