






































































































.gamification-dashboard {
  background-color: var(--grey-lighter);
  ::v-deep button {
    cursor: pointer;
  }
  ::v-deep .error {
    color: var(--red);
    margin: auto;
    font-size: var(--rem-size-16);
    text-align: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      width: 100%;
      max-width: 1170px;
      margin: 1rem;
      flex-direction: column;
      margin-top: 2rem;
    }
    ::v-deep .gamification-loader {
      width: 50px;
      height: 50px;
    }
    &__info-card {
      display: flex;
      align-items: center;
      &__content {
        width: 90%;
      }
    }
  }
}
