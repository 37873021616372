









































































































































































































































































































































































































































































































































































































































































































































@import '@/styles/mixins';

.idt-register {
  .register__page {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: calc(100vh - 150px);
    @include respond-to(medium) {
      padding: 40px 0;
    }
    @include respond-to(slarge) {
      align-items: center;
    }
  }

  .register__container {
    flex: 1 1 auto;
    position: relative;
    text-align: center;
    max-width: 100vw;
    @include respond-to(max-medium) {
      padding: 1.5rem 0 4.25rem;
    }
    @include respond-to(medium) {
      padding: 2rem 0 4.75rem;
    }
    /************************************
          Forms
      ************************************/
    .register__title {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 1.5em;
      text-align: left;
    }

    /*** Account Blocks ***/
    .account__basic {
      .account__agreements {
        &__container {
          display: flex;
          padding: 0.25rem;

          &.required-field {
            border: 1px solid var(--secondary-color);
            border-radius: var(--global-radius);
          }
        }
        label {
          flex: 1 1 auto;
          text-align: left;
        }
        input[type='checkbox'] {
          flex: 0 0 auto;
          margin: 0.25rem 0.5rem 0 0;
        }
      }
    }
    .account__complete {
      textarea {
        height: 50px;
        resize: none;
      }
    }
    .account__preferences {
      .intl-tel-input {
        display: block;
        flex: 1 1 auto;
        width: 100%;
        margin-top: 1rem;

        .country-list {
          z-index: 200;
        }
      }
    }
    .account__availabilities-expanded {
      .idt-da__block {
        border: 1px solid var(--grey-light);

        &__content {
          max-height: 400px;
          overflow-y: auto;
        }
        .select-block {
          width: 42%;
        }
      }
    }
  }
  .ticket-slide {
    display: flex;
    justify-content: center;
    align-items: stretch;

    @include respond-to(medium) {
      padding: 0 2rem;
    }
    @include respond-to(xlarge-xxlarge) {
      margin: -6rem auto;
    }
    @include respond-to(xxlarge) {
      flex-direction: column;
    }

    .register__slide__title {
      margin-bottom: 0;

      @include respond-to(max-xxlarge) {
        display: none;
      }
    }
  }
  // -----------------------------
  // 1. Footer
  // -----------------------------
  .register__footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: var(--white);
    border-top: 1px solid var(--grey);
    z-index: 10;

    @include respond-to(medium) {
      padding: 0 1.15rem;
    }

    &__left,
    &__right {
      @include respond-to(max-medium) {
        flex: 1 1 auto;
        margin: 0 1rem;
      }
    }

    /************************************
          Bouttons
      ************************************/
    .register__next,
    .register__previous {
      margin-right: auto;
      border: 2px solid var(--main-color);
      text-transform: uppercase;
      transition: all var(--default-duration) ease-in-out;

      @include respond-to(max-medium) {
        width: 100%;
      }
      @include respond-to(medium) {
        width: 40%;
      }
      &.hidden {
        pointer-events: none;
        visibility: hidden;
      }
      &:focus {
        outline: 0;
      }
    }
    .register__next {
      color: var(--white);
      background-color: var(--main-color);

      &:hover {
        color: var(--main-color);
        background-color: transparent;
      }
    }
    .register__previous {
      padding: 0;
      color: var(--grey-darker);
      background-color: transparent;
      border: solid 2px var(--grey-darker);
      font-size: 16px;
      font-weight: 500;

      @include respond-to(max-small) {
        margin-bottom: 10px;
      }

      &:hover {
        color: var(--white);
        background-color: var(--grey-darker);
      }
    }

    .register__previous + .register__next {
      @include respond-to(small) {
        margin-left: 20px;
      }
    }
    .button {
      min-width: 134px;
      height: 42px;
      margin: 0;
      font-weight: var(--font-weight-bold);
    }
  }

  .ticket-payment-success {
    @include respond-to(max-small) {
      padding: 0 20px;
    }
    @include respond-to(max-small) {
      padding: 0 40px;
    }
  }

  .payment-loading {
    @include respond-to(max-small) {
      padding: 0 20px;
    }
  }
}

.step-viewer {
  @include respond-to(max-medium) {
    display: none;
  }

  &__step {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
    font-size: 0;
    vertical-align: middle;

    &.done {
      .number {
        background-color: var(--main-color-lighter);
        color: var(--main-color);
      }
    }
    &:last-child {
      margin-right: 20px;
    }
    .number {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      color: white;
      background-color: var(--grey);
      border-radius: 50%;
      font-size: 15px;
      font-weight: var(--font-weight-medium);
      line-height: 15px;
      vertical-align: middle;
    }
    .name {
      font-weight: var(--font-weight-medium);
      vertical-align: middle;

      @include respond-to(max-slarge) {
        display: none;
      }
      @include respond-to(slarge-xlarge) {
        font-size: var(--rem-size-14);
      }
      @include respond-to(xlarge) {
        font-size: var(--rem-size-16);
      }
    }
    & ::v-deep .ideacon {
      margin-left: 24px;
      color: var(--grey);
    }
  }
}
