.page__header {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: var(--menu-height);
  background-color: var(--white);
}

@media only screen and (max-width: 991px) {
  .page__header {
    z-index: 1000;
  }
}

@media only screen and (min-width: 992px) {
  .page__header {
    z-index: 10001;
  }
}

.page__alerts {
  position: fixed;
  right: 0;
  top: var(--menu-height);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 564px;
  max-height: calc(100vh - var(--menu-height));
  z-index: 10000;
  pointer-events: none;
}

.page__alerts .idt-alerts__list {
  pointer-events: auto;
}

.page__content {
  margin-top: var(--menu-height);
}

@media only screen and (max-width: 639px) {
  .page__content:not(.no-footer) {
    min-height: calc(var(--vh, 1vh)*100 - var(--menu-height) - 250px);
  }
}

@media only screen and (min-width: 640px) {
  .page__content:not(.no-footer) {
    min-height: calc(var(--vh, 1vh)*100 - var(--menu-height) - 192px);
  }
}

.page__content.no-footer {
  min-height: calc(var(--vh, 1vh)*100 - var(--menu-height));
}

