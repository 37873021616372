// IDT Messenger component styles
//
//  Table of Contents:
//
//  1. Common styles
//  2. Sidebar
//  3. Utilities
//
// -----------------------------

// -----------------------------
// 1. Common styles
// -----------------------------
.idt-sidebars-container {
  --chat-min-with: 320px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1000;
}

// -----------------------------
// 2. Sidebar
// -----------------------------
.idt-sidebars-container-sidebar {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  position: relative;
  height: 1rem;
  filter: drop-shadow(2px 2px 3px rgba(0,0,0,0.2));
  transform: translate3d(0,0,0);
  z-index: 1;

  @include respond-to(max-medium) {
    width: 100%;
  }
  @include respond-to(medium) {
    margin-left: var(--rem-size-28);
    padding-left: var(--rem-size-26);
  }
  @include respond-to(medium-xxlarge) {
    max-width: 390px;
  }

  &.userOpen {
    @include respond-to(medium-slarge) {
      max-width: 590px;

      .idt-sidebars-container-toggle {
        display: none;
      }
    }
  }
  .header__input-dropdown {
    position: relative;
    width: 100%;

    &__input {
      position: relative;
      cursor: pointer;

      &__container {
        display: flex;
        align-items: center;
        
        &__title {
          margin-right: var(--rem-size-5);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    &__pane-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--rem-size-8) var(--rem-size-20);
      font-size: var(--rem-size-14);
      border-bottom: 1px solid var(--grey);

      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: var(--grey-light);
        cursor: pointer;
      }
      .pane-row__label {
        width: 90%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .idt-dropdown--pane {
      width: 236px;
      max-height: 65vh;
      margin-top: var(--rem-size-5);
      padding: 0;
      color: var(--black);
      border: none;
      box-shadow: 2px 2px 5px var(--black-alpha20);
      overflow-y: auto;
    }
    .idt-dropdown {
      width: 100%;
    }
  }
}

// -----------------------------
// 3. Utilities
// -----------------------------
.idt-sidebars-container-close {
  color: var(--main-color);
  font-size: 1.1rem;
  cursor: pointer;
}

.idt-sidebars-container-backdrop {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - var(--menu-height));
  background: var(--black);
  opacity: .35;
}

// Transition styles
.user-profile-enter-active,
.user-profile-leave-active {
  transition: width 0.3s ease;
}
.user-profile-enter,
.user-profile-leave-to {
  width: 0;
}
