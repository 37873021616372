












































































@import '@/styles/mixins/index';

.idt-history {
  height: 100%;

  &__toolbar {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;

    &__open-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin-bottom: 10px;
      color: var(--white);
      background-color: var(--main-color);
      border-radius: 4px;
      font-size: 0.875rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &__arrow {
        display: none;
        position: absolute;
        left: 20px;
      }
      &__text {
        margin-right: 8px;
        font-weight: var(--font-weight-medium);
      }
      &:hover {
        background-color: var(--main-color-dark);

        .idt-history__toolbar__open-btn__arrow {
          display: block;
        }
      }
    }
    &__searchbar {
      input {
        height: 40px;
        margin: 0;
        padding: 0.6566rem 1.5rem 0.6566rem 1rem;
        border-color: var(--grey-lightest);
        border-radius: 4px;
        font-size: 0.9rem;
      }
    }
  }
  &__list {
    padding: 0 1rem 1rem 1rem;
    overflow-y: auto;

    @include respond-to(max-medium) {
      max-height: calc(100vh - 150px);
    }
    @include respond-to(medium) {
      max-height: calc(100% - 150px);
    }
  }
  &__empty-list {
    color: var(--grey-dark);
    font-size: 0.7rem;

    .ideacons {
      padding: 0 1.25rem 0 0.75rem;
      font-size: 1.1rem;
      vertical-align: sub;
    }
  }
  .idt-history-card {
    position: relative;
    min-height: 115px;
    margin-bottom: 0.5rem;
    background-color: var(--white);
    box-shadow: 0 2px 4px 0 var(--black-alpha10);
    cursor: pointer;
    transition: all 0.3s ease;

    &--hiring {
      border-bottom: 2px solid var(--main-color);
    }
    &:hover {
      box-shadow: 0 2px 4px 0 var(--black-alpha20);
    }
    &__container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;
      min-height: 115px;
      padding: 25px 15px;
    }
    &__avatar {
      flex: 0 0 auto;
      position: relative;

      &__project {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        &--no-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--white);
          background-color: var(--grey-darkest-alpha20);
          font-size: 2.25rem;
          text-transform: uppercase;
        }
      }
    }
    &__unread {
      @include notificationPoint(var(--white), var(--secondary-color), "absolute", "left", -4px, -10px);
    }
    &__content {
      flex: 1 1 auto;
      padding: 0 0.75rem;
    }
    &__title {
      margin-bottom: 0.25rem;
      color: var(--grey-darkest);
      line-height: 1;

      .user-tag {
        color: var(--grey-dark);
        text-transform: uppercase;

        &--mentor {
          color: var(--main-color);
        }
      }
    }
    &__title &__sub-title {
      display: block;
      margin-top: 0.25rem;
      color: var(--grey-darkest);
      font-size: 0.9rem;
      font-weight: var(--font-weight-bold);
    }
    &__last-msg {
      color: var(--grey-dark);
      font-size: 0.75rem;
      word-break: break-all;
      hyphens: auto;

      &.unread {
        color: var(--black);
        font-weight: var(--font-weight-medium);
      }
    }
    &__icon {
      color: var(--main-color);
    }
    &__status {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      min-width: 64px;
      height: 18px;
      padding: 2px 5px;
      color: var(--white);
      background-color: var(--grey);
      font-size: 0.7rem;
      text-transform: uppercase;
      line-height: 1;

      &--conf {
        background-color: var(--warning-color);
      }
      &--accepted {
        color: var(--success-color);
        background-color: var(--success-color-alpha20);
      }
      &--declined {
        color: var(--secondary-color);
        background-color: var(--secondary-color-alpha20);
      }
    }
    &__status-project-logo {
      width: 14px;
      height: 14px;
      display: inline-block;
    }
  }
}
