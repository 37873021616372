.custom-field-form {
  width: 100%;
}

.custom-field-form .column-editor {
  position: relative;
  margin-bottom: 1rem;
}

.custom-field-form__badge {
  margin-bottom: 5px;
  max-width: 45px;
}

@media only screen and (min-width: 992px) {
  .custom-field-form__badge {
    position: absolute;
    left: -35px;
  }
}

@media only screen and (min-width: 640px)and (max-width: 991px) {
  .custom-field-form__options {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 992px) {
  .custom-field-form__options {
    position: absolute;
    top: 0;
    right: -240px;
    width: 240px;
  }
}

.custom-field-form__options__mandatory {
  margin-bottom: 12px;
}

.custom-field-form__options__attachments {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.custom-field-form__options__attachments__text {
  margin-left: 4px;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.custom-field-form__options--bottom {
  position: inherit;
  right: 0;
}

.custom-field-form .btn-add-field {
  width: 200px;
  height: 50px;
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.custom-field-form .froala-container.required-field ::v-deep .fr-box {
  margin: 0;
  border: 1px solid var(--secondary-color);
}

