












































































































@import '@/styles/functions';
@import '@/styles/mixins';

.sso-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--rem-size-8);

  &.no-separator {
    margin-bottom: 2rem;
  }
  &:not(.no-separator) {
    margin-bottom: var(--rem-size-8);
  }
  &__button {
    min-height: 46px;
    background-color: var(--sso-btn-bgcolor);

    &:hover {
      background-color: var(--sso-btn-bgcolor-hover);
    }
    ~ .btn-submit {
      margin-top: 1rem;
    }
    &.has-icon {
      display: grid;
      gap: remCalc(16);
      text-align: left;

      @include respond-to(max-slarge) {
        grid-template-columns: 2fr 10fr;
      }
      @include respond-to(slarge) {
        grid-template-columns: 2.5fr 9.5fr;
      }
    }
  }
  &__icon-container {
    text-align: right;
  }
  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: var(--white);
    border-radius: 100%;
    color: var(--sso-btn-bgcolor);
  }
}

