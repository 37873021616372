




















































































































































.project-publish-modal {
  &__title {
    color: var(--grey-darkest);
    font-size: var(--rem-size-32);
    font-weight: var(--font-weight-medium);
  }
  &__content {
    color: var(--grey-darker);
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-normal);
  }
  .unpublished {
    background-color: red;
  }
  .button {
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-medium);
  }
}
