





























































.replay-player {
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    background-color: var(--black);
  }
}
