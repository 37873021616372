.idt-user-search-input {
  position: relative;
}

.idt-user-search-input.disabled {
  opacity: .5;
  pointer-events: none;
}

.idt-user-search-input__searchbar {
  position: relative;
}

.idt-user-search-input__searchbar ::v-deep .searchbar-icon {
  position: absolute;
  top: 50%;
  left: var(--rem-size-10);
  color: var(--grey-darkest);
  font-size: var(--rem-size-20);
  transform: translateY(-50%);
}

.idt-user-search-input__searchbar input {
  margin: 0 0 .5rem;
  padding: var(--rem-size-8) var(--rem-size-20) var(--rem-size-8) var(--rem-size-40);
  border: 1px solid var(--grey-darkest);
  border-radius: var(--rem-size-5);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-22);
}

.idt-user-search-input__user-list {
  position: absolute;
  width: 100%;
  max-height: 300px;
  background: #fff;
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  box-shadow: 0px 3px 6px var(--black-alpha20);
  overflow-y: auto;
  z-index: 1;
}

.idt-user-search-input__user-list__not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--rem-size-20) var(--rem-size-10);
}

::v-deep .idt-user-search-input__user-list__not-found__icon .ideacon {
  stroke: var(--grey);
  fill: var(--grey);
}

.idt-user-search-input__user-list__not-found__text {
  margin-top: var(--rem-size-10);
  color: var(--grey-dark);
  font-size: var(--rem-size-14);
  font-weight: 500;
}

.idt-user-search-input .idt-user-search-list-item__button {
  position: absolute;
  right: var(--rem-size-10);
  padding: var(--rem-size-5) var(--rem-size-16);
  border-radius: var(--rem-size-30);
  background: var(--main-color);
  font-size: var(--rem-size-14);
  color: var(--white);
  cursor: pointer;
}

.idt-user-search-input .idt-user-search-list-item__button:hover {
  color: var(--white);
  background: var(--main-color-dark);
}

.idt-user-search-input .idt-user-search-list-item__button.selected {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background: var(--white);
  cursor: default;
}

