





















































































































































































































































































@import '@/styles/mixins';

.add-participant-modal {
  width: 100%;
  max-width: 500px;
  padding: 1rem;

  &__link {
    text-decoration: underline;
  }
  &__tickets {
    &__container {
      .button {
        max-width: 120px;
        margin-right: 3px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:last-child {
          margin-right: 0px;
        }

        &.selected {
          color: var(--white);
          background-color: var(--main-color);
          border-color: var(--main-color);
        }
      }
    }
  }
  &__report {
    max-height: 500px;
    overflow-y: auto;

    &__table {
      min-width: 500px;
      table-layout: fixed;
    }
  }
  &__loader {
    label {
      color: var(--black-alpha70);
      font-size: 18px;
      font-weight: normal;
      text-align: center;

      i {
        color: var(--main-color);
        font-size: 16px;
      }
    }
  }
  &__progress {
    position: relative;
    width: 100%;
    min-width: 300px;
    height: 22px;
    margin: 20px auto;
    background-color: var(--grey-light);
    border-radius: 0;
    overflow: hidden;

    &__number {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      right: 10px;
      height: 100%;
      color: var(--main-color);
      font-size: 13px;
      font-weight: bold;
      line-height: 13px;
    }
    &__bar {
      height: 100%;
      background-color: var(--main-color);
      opacity: .8;
      transition: width .2s;
    }
  }
}
