




































































































































































































































































































































@import '@/styles/mixins/index';

.payment-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__tooltip {
    .selection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &__content {
      display: none;
      position: absolute;
      bottom: 27px;
      right: 0px;
      width: 215px;
      padding: 0.75rem;
      color: var(--grey-darker);
      background-color: var(--white);
      border-color: transparent transparent var(--white) transparent;
      box-shadow: 0 2px 8px 0 var(--black-alpha10);
      font-size: 0.75rem;
      font-style: italic;
      transition: top 0s 0.5s, opacity 0.5s;
      z-index: 9999;

      &:before {
        position: absolute;
        top: -10px;
        right: 42px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
      }
      &.show {
        display: block;
      }
    }
  }
  &__container {
    padding: 0 20px;
    border-right: 3px solid var(--grey-lighter);
    border-left: 3px solid var(--grey-lighter);

    input {
      margin-bottom: 0;
    }
    label {
      color: var(--grey-darker);
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      text-align: left;
    }
  }
  &__logos-container {
    margin-bottom: 20px;
  }
  &__logos {
    display: inline-block;
    width: calc(90% / 4);
    color: var(--grey-lighter);
  }
  &__secure-label {
    font-weight: var(--font-weight-medium);
    font-size: 0.81rem;
  }
  &__field {
    width: 100%;
    height: 36px;
    padding: 8px 8px;
    border: solid 1px var(--grey-dark);
    border-radius: 3px;

    &.invalid {
      border: solid 1px var(--secondary-color);
    }
    &::placeholder {
      color: var(--grey-darker);
      font-style: italic;
      line-height: 20px;
    }
    &--error {
      height: 20px;
      color: var(--secondary-color);
      font-size: 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
  &__card-name {
    margin-bottom: 20px;
    font-weight: var(--font-weight-medium);
  }
  &__card-expiry-cvc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__card-expiry {
    flex: 0 0 auto;
    width: 65%;
    margin-right: 10px;
  }
  &__card-cvc {
    width: 35%;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__button--pay {
    flex: 0 0 auto;
    width: 100%;
    color: var(--white);
    background-color: var(--main-color);
    font-size: 1.125rem;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    @include respond-to(max-medium) {
      height: 60px;
      border-radius: 0;
    }
    @include respond-to(medium) {
      height: 50px;
      border-radius: 0px 0px var(--global-radius) var(--global-radius);
    }
  }
}
