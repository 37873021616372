


















































































































































































.live-chat-message {
  position: relative;
  padding: 0 var(--rem-size-10);
  border-radius: var(--global-radius);
  transition: background-color 0.15s ease-in-out;
  z-index: 1;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lighter);
  }
  &:hover {
    background: var(--grey-lightest);

    .live-chat-message-actions {
      display: block;
    }
  }
  &__gif-preview {
    padding-bottom: var(--rem-size-15);

    img {
      position: relative;
      max-height: 150px;
      border-radius: var(--global-radius);
    }
  }

  &--edit {
    background-color: var(--main-color-alpha20) !important;

    .live-chat-message-content__flex {
      padding-top: var(--rem-size-16);
    }

    .live-chat-form__container {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 0 !important;

      .live-chat-form__actions {
        background-color: var(--white);
      }

      // TODO: should uncomment and deal with the smiley/gif popup going negatively on the left
      // of the chat and thus being inaccessible most of the time
      // (also uncomment the part below in &--cancel)
      /* .live-chat-form__actions :nth-child(2) {
        margin-left: 0 !important;
      } */
    }

    .live-chat-form__actions__submit {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: auto !important;
      height: auto !important;
      min-width: 66px;
      min-height: var(--rem-size-27);
      padding: 0 var(--rem-size-15);
      color: var(--white);
      font-weight: var(--font-weight-bold);
      font-size: var(--rem-size-12);

      &--cancel {
        // see comment above to understand why this is commented
        // margin-left: auto !important;
        background: transparent;
        border: 1.5px solid var(--main-color);
        color: var(--main-color);
      }
    }
  }
}
