.modal-small__close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 479px) {
  .modal-small__content {
    padding: 25px 20px;
  }
}

@media only screen and (min-width: 480px) {
  .modal-small__content {
    padding: 45px 40px 25px;
  }
}

