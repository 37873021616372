





























































































































































































































































































@import '@/styles/mixins';

.idt-profile-info {
  textarea {
    height: 157px;
    margin-bottom: 0;

    &.has-error {
      border-color: var(--secondary-color);
    }
  }
  &__section {
    margin-bottom: 25px;
  }
  &__avatar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__label {
      font-weight: var(--font-weight-bold);
    }
    &__image {
      margin-right: 12px;
    }
    &__actions {

      &__label {
        line-height: 1.2rem;
        font-weight: var(--font-weight-bold);
      }
      &__buttons {
        display: flex;
        align-items: center;
        gap: var(--rem-size-8);

        &__remove:not(:hover).button {
          color: var(--black) !important;
          background-color: var(--grey-light);
        }
      }
    }
  }
  &__tags {
    display:flex;
    flex-flow: row wrap;
    align-items:center;
    gap: var(--rem-size-8);

    &__label {
      margin-bottom: 5px;
    }
  }
  &__select-status {
    margin: 0;
  }
}
