.update-role-modal__content {
  height: calc(var(--vh, 1vh)*100 - (var(--menu-height) + 4rem));
  margin-top: var(--menu-height);
  overflow-y: auto;
}

@media only screen and (max-width: 639px) {
  .update-role-modal__content {
    padding: 1rem 1.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .update-role-modal__content {
    padding: 2rem 1.5rem;
  }
}

.update-role-modal__current-role {
  font-size: .875rem;
}

.update-role-modal__access-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0 2rem 0;
}

.update-role-modal__access-group__title {
  margin-bottom: .5rem;
  color: var(--grey-darkest);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.update-role-modal__access-group__item {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: .875rem;
}

.update-role-modal__access-group__item-label {
  display: flex;
  align-items: center;
  gap: var(--rem-size-4);
  line-height: 0;
}

.update-role-modal__access-group__item-icon:not(.checked) {
  color: var(--danger-color);
}

.update-role-modal__access-group__item-icon.checked {
  color: var(--success-color);
}

.update-role-modal__info {
  margin-bottom: 1rem;
}

.update-role-modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--rem-size-8);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--rem-size-64);
  padding: var(--rem-size-16);
  border-top: 1px solid var(--grey-lighter);
  background-color: var(--white);
}

