.conference-chat .room {
  height: 100%;
}

@media only screen and (min-width: 640px) {
  .conference-chat .room {
    border-left: 1px solid var(--grey-lighter);
  }
}

.conference-chat .room .room__title {
  font-size: 18px;
  text-transform: uppercase;
}

.participant-mobile-chat .room {
  height: 100%;
  margin: auto;
}

@media only screen and (max-width: 991px) {
  .participant-mobile-chat .room {
    height: calc(100vh - (var(--video-height) + var(--menu-and-header-height)));
    height: calc(var(--vh, 1vh)*100 - (var(--video-height) + var(--menu-and-header-height)));
  }
}

@media only screen and (min-aspect-ratio: 13/9) {
  .participant-mobile-chat .room {
    display: none;
  }
}

.participant-mobile-chat .room__header {
  display: none;
}

.participant-mobile-chat .room__messages {
  height: calc(100% - 111px);
}

.mentor-mobile-chat .room {
  height: 100%;
  background-color: rgba(0,0,0,0);
}

.mentor-mobile-chat .room__messages {
  height: calc(100% - 111px);
}

.room {
  --chat-message-max-width: 270px;
  --chat-message-radius: 10px;
}

