.conference-form {
  width: 100vw;
}

@media only screen and (max-width: 639px) {
  .conference-form {
    padding-top: var(--rem-size-32);
  }
}

@media only screen and (min-width: 1024px) {
  .conference-form__subtitle {
    max-width: 550px;
    margin: 0 auto 2rem auto;
  }
}

.conference-form__content {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .conference-form__content {
    flex-direction: column;
  }
}

.conference-form__content__block {
  flex: 1 1 auto;
  position: relative;
}

@media only screen and (max-width: 639px) {
  .conference-form__content__block {
    padding: 0 var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .conference-form__content__block {
    padding: 0 var(--rem-size-32);
  }
}

@media only screen and (min-width: 992px) {
  .conference-form__content__block {
    width: 50%;
    max-width: 600px;
  }
}

.conference-form__content__block.has-separator:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  border-right: 1px solid var(--grey);
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .conference-form__content__block.has-separator:after {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .conference-form__content__block.has-separator:after {
    display: block;
  }
}

.conference-form__upload {
  padding-left: 0;
}

.conference-form__upload--pre-recorded-input {
  display: contents;
}

.conference-form ::v-deep .conference-form__upload-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  text-transform: uppercase;
}

.conference-form ::v-deep .conference-form__upload-btn--pre-recorded {
  margin: 0;
}

.conference-form__switch-title {
  margin-bottom: .5rem;
}

.conference-form__switch-desc {
  margin-bottom: .5rem;
  min-height: 50px;
}

.conference-form__button {
  min-width: 235px;
  height: 55px;
  font-family: var(--text-font-family);
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.conference-form__footer {
  margin-top: 50px;
  text-align: center;
}

.conference-form__back.hollow {
  position: fixed;
  margin: 0;
  padding: .6rem 1rem;
  color: var(--grey-darker);
  border-color: var(--grey-darker);
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .conference-form__back.hollow {
    top: .5rem;
    left: .5rem;
    z-index: 100;
  }
}

@media only screen and (min-width: 640px) {
  .conference-form__back.hollow {
    top: 1.875rem;
    left: 1.875rem;
  }
}

.conference-form__back.hollow:hover {
  color: var(--grey-darkest);
  border-color: var(--grey-darkest);
}

.conference-form ::v-deep .upload_input input {
  background-color: var(--white);
}

.conference-form__attachment {
  width: 100%;
  height: 45px;
  max-width: 600px;
  border: 2px dashed var(--main-color);
  border-radius: var(--global-radius);
}

.conference-form__attachment--filled {
  max-width: 600px;
  margin-top: 3px;
  border: none;
}

.conference-form__attachment__preview {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--global-radius);
}

.conference-form__attachment__no-preview {
  position: relative;
  border-radius: var(--global-radius);
}

.conference-form__attachment__preview .conference-form__attachment__file.has-file {
  border: 2px dashed var(--main-color);
}

.conference-form__attachment__preview:hover .conference-form__attachment__file.has-file {
  background-color: rgba(0,0,0,.7);
  border: 2px solid var(--main-color);
  border-radius: var(--global-radius);
}

.conference-form__attachment__preview:hover .conference-form__attachment__file.has-file span.white-hovered {
  color: var(--white);
  font-size: 1.0625rem;
  font-weight: var(--font-weight-medium);
}

.conference-form__attachment__file {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conference-form__attachment__file ::v-deep button {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.conference-form__attachment__file__label span {
  color: var(--main-color);
  font-size: .875rem;
}

.conference-form__attachment__file__delete {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  padding: .5rem;
  color: var(--white);
  background-color: var(--secondary-color);
  font-size: 1.2rem;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}

.conference-form__attachment:hover {
  border: 2px solid var(--main-color);
}

.conference-form__attachment:hover span.white-hovered {
  font-weight: var(--font-weight-medium);
}

.conference-form__upload-infos {
  display: flex;
  align-items: flex-start;
  color: var(--grey-darker);
  font-size: .75rem;
  font-weight: var(--font-weight-light);
  font-style: italic;
}

.conference-form__upload-infos--filled {
  font-weight: var(--font-weight-medium);
  line-height: 1.125rem;
}

.conference-form__stream-types {
  padding-bottom: 0;
}

.conference-form__stream-types ::v-deep .v-select {
  margin-bottom: 0;
}

.conference-form__url-container-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  color: var(--main-color);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.conference-form__url-container-button__link {
  width: 95%;
  flex: 0 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conference-form__infos {
  margin-bottom: 1rem;
  color: var(--grey-darker);
  font-size: .75rem;
  font-weight: var(--font-weight-light);
  font-style: italic;
}

.upload-replay {
  display: flex;
  gap: var(--rem-size-8);
  margin-bottom: var(--rem-size-24);
}

.upload-replay ::v-deep .idt-fs-upload-file {
  flex: 0 0 auto;
}

