.event-admin-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .75rem;
  color: var(--white);
  background-color: var(--main-color);
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .event-admin-section {
    padding: 3rem 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-admin-section {
    padding: 5rem 3rem;
  }
}

.event-admin-section__content {
  flex: 1 1 auto;
}

.event-admin-section__title {
  margin: 0 0 1rem 0;
  font-family: var(--title-font-family);
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1;
}

.event-admin-section__desc {
  margin-bottom: 1.5rem;
}

.event-admin-section .button {
  color: var(--main-color);
  background-color: var(--white);
  border: 2px solid var(--white);
  border-radius: 0;
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (min-width: 640px) {
  .event-admin-section .button {
    padding: .5rem 1rem;
    font-size: .9rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-admin-section .button {
    width: 420px;
    padding: 1rem;
    font-size: 1.1rem;
  }
}

.event-admin-section .button:hover {
  color: var(--white);
  background-color: rgba(0,0,0,0);
}

