


























@import '@/styles/mixins/index';

.conference-canceled {
  width: 100%;
  height: 100%;

  @include respond-to(max-slarge) {
    height: var(--video-height);
  }
}
