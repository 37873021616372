.resource-editor {
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .resource-editor {
    position: fixed;
    top: var(--menu-height);
    left: 0;
    height: calc(var(--vh, 1vh)*100 - var(--menu-height));
    padding: 1rem;
    background-color: var(--white);
    overflow-y: auto;
    z-index: 10;
  }
}

.resource-editor .editor {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor {
    position: relative;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1024px) {
  .resource-editor .editor {
    height: 172px;
    margin-bottom: 1rem;
  }
}

.resource-editor .editor__image {
  position: relative;
  flex: 0 0 auto;
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor__image {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .resource-editor .editor__image {
    width: var(--resource-img-width);
  }
}

.resource-editor .editor__image .fs__image-upload {
  height: 144px;
  border-style: dashed;
}

.resource-editor .editor__image__button {
  width: 100%;
  height: 144px;
}

.resource-editor .editor__image__button ::v-deep .upload-file__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 144px;
  margin: 0;
  color: var(--main-color);
  background-color: rgba(0,0,0,0);
}

.resource-editor .editor__content {
  display: flex;
  align-items: stretch;
  flex: 1 0 auto;
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor__content {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1442px) {
  .resource-editor .editor__content {
    width: calc(100% - var(--resource-img-width));
  }
}

.resource-editor .editor__content__block {
  flex: 0 0 auto;
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor__content__block {
    width: 100%;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .resource-editor .editor__content__block {
    width: calc(50% - 1rem);
    margin-left: 1rem;
  }
}

.resource-editor .editor__attachment {
  --resource-tab-content-border-color: var(--grey);
}

.resource-editor .editor__attachment.error {
  --resource-tab-content-border-color: var(--secondary-color);
}

.resource-editor .editor__attachment__tabs {
  display: flex;
  align-items: center;
}

.resource-editor .editor__attachment__tab {
  flex: 1 1 auto;
  height: 30px;
  color: var(--grey-darker);
  background-color: var(--grey-lighter);
  font-size: var(--rem-size-12);
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}

.resource-editor .editor__attachment__tab:not([disabled]):not(.active) {
  cursor: pointer;
}

.resource-editor .editor__attachment__tab:first-child {
  border-radius: var(--global-radius) 0 0 0;
}

.resource-editor .editor__attachment__tab:last-child {
  border-radius: 0 var(--global-radius) 0 0;
}

.resource-editor .editor__attachment__tab.active {
  color: var(--white);
  background-color: var(--main-color);
}

.resource-editor .editor__attachment__content {
  width: 100%;
  height: 42px;
  border-right: 1px solid var(--resource-tab-content-border-color);
  border-bottom: 1px solid var(--resource-tab-content-border-color);
  border-left: 1px solid var(--resource-tab-content-border-color);
  border-radius: 0 0 var(--global-radius) var(--global-radius);
}

.resource-editor .editor__attachment__url {
  height: 40px;
  margin: 0;
  border: none;
}

.resource-editor .editor__attachment__upload ::v-deep .idt-fs-upload-file__button {
  border: none;
}

.resource-editor .editor__attachment__file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.resource-editor .editor__attachment__file__name {
  flex: 0 0 auto;
  width: calc(100% - 32px);
  padding: var(--rem-size-8);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.resource-editor .editor__attachment__file__delete {
  flex: 0 0 auto;
  padding: var(--rem-size-8);
  color: var(--secondary-color);
  cursor: pointer;
}

.resource-editor .editor__attachment__file__delete:hover {
  color: var(--secondary-color-dark);
}

.resource-editor .editor__description {
  display: block;
  position: relative;
}

.resource-editor .editor__delete {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor__delete {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }
}

.resource-editor .editor__delete__button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--danger-color);
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor__delete__button {
    height: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .resource-editor .editor__delete__button {
    flex-direction: column;
  }
}

.resource-editor .editor__delete__button:hover {
  color: var(--danger-color-dark);
}

@media only screen and (max-width: 1023px) {
  .resource-editor .editor__delete__button ::v-deep .ideacon {
    margin-right: .5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .resource-editor .editor__delete__button ::v-deep .ideacon {
    margin-bottom: .5rem;
  }
}

.resource-editor .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.resource-editor .actions .button {
  margin: 0;
  text-transform: uppercase;
}

.resource-editor .actions__cancel {
  margin-right: 1rem;
  cursor: pointer;
}

