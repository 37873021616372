




















































































@import '@/styles/functions';
@import './fo-project-list-card';

.project-grid-card {
  padding: remCalc(10);
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px 0px var(--grey);

  &__preview {
    position: relative;
  }
  &__members {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: remCalc(-20);
    margin-right: remCalc(2);
  }
  &__texts {
    margin-top: remCalc(4);
  }
  .project-card__texts {
    &__title {
      height: remCalc(24);
      text-overflow: ellipsis;
      white-space: pre;
      overflow: hidden;
    }
  }
  .project-card__footer {
    border-top: 1px solid var(--grey-light);
  }
}

::v-deep .idt-tag {
  height: 20px;
  max-width: 205px;
}
