
















































































































































































@import '@/styles/mixins/index';
.meeting-room {
  --menu-height: 70px;
  --video-height: 48vw;
  --menu-and-footer-height: calc(
    var(--menu-height) + var(--conference-footer-height)
  );
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
  background: var(--grey-lightest);

  &.cancelled {
    justify-content: center;
  }

  &:not(.cancelled) {
    @include respond-to(max-slarge) {
      flex-flow: column nowrap;
    }
  }

  @include respond-to(slarge) {
    flex-flow: row;
  }

  &__main {
    display: flex;
    flex-direction: column;
    position: relative;
    @include respond-to(max-slarge) {
      flex: 0 0 auto;
    }
    @include respond-to(slarge) {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &__container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  &__sidebar {
    height: calc(100vh - 70px);
    background: var(--white);
    @include respond-to(max-slarge) {
      flex: 1 1 auto;
      @include respond-to(landscape) {
        display: none;
      }
    }
    @include respond-to(slarge) {
      flex: 0 0 auto;
      width: 440px;
    }
    ::v-deep .meeting-sidebar {
      @include respond-to(medium) {
        border-left: 1px solid var(--grey-lighter);
      }
      @include respond-to(slarge) {
        flex-grow: 1;
      }
    }
  }
}
