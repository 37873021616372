
































@import '@/styles/mixins';

.fo-lobby-error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: var(--rem-size-18);
  color: var(--grey-darkest);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column;

    > * {
      margin-bottom: var(--rem-size-24);
    }
    strong + span {
      margin-top: calc(-1 * var(--rem-size-8));
      color: var(--grey-darker);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: var(--grey-lightest);
    color: var(--grey-darker);
  }
}
