.button {
  margin-top: 5px;
}

.availabilities {
  background-color: var(--grey-light);
  height: 1100px;
  width: 100%;
  padding: 20px 0 0 500px;
}

.availabilities__input {
  width: 150px;
  border: solid 1px var(--black);
}

.availabilities__params {
  display: flex;
  align-items: center;
}

.availabilities__params__input {
  flex-direction: column;
  width: 250px;
  margin-right: 10px;
}

.availabilities__params__value {
  flex-direction: column;
  width: 150px;
  border: solid 1px var(--black);
}

.availabilities__table {
  height: 400px;
  overflow: auto;
}

