.vue-tel-input {
  width: 100%;
  background-color: white;
  border-color: var(--grey);
  border-radius: var(--global-radius);

  &:focus-within {
    box-shadow: none;
    border-color: var(--grey-darker);
  }
  * {
    outline: none;
  }

  .vti {
    /*&__dropdown {}*/
    &__input {
      border: none;
      margin-bottom: 0;
      padding-left: 0.5rem;
    }
  }
}
