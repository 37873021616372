.idt-stacked-user-avatars {
  display: flex;
  border-radius: var(--global-radius);
  transition: all .2s ease-in-out;
}

.idt-stacked-user-avatars:hover {
  background-color: var(--grey-lighter);
  box-shadow: 0 0 0 var(--rem-size-4) var(--grey-lighter);
  cursor: pointer;
}

.idt-stacked-user-avatars .idt-user-avatar:not(:first-child),
.idt-stacked-user-avatars__has-more {
  margin-left: -16px;
}

.idt-stacked-user-avatars .idt-user-avatar {
  border: 2px solid var(--white);
}

.idt-stacked-user-avatars .idt-users-popover ::v-deep .idt-dropdown--toggle {
  display: flex;
}

.idt-stacked-user-avatars__has-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  font-size: var(--rem-size-14);
  background-color: var(--white);
  border: 1px solid var(--main-color);
  border-radius: 50%;
  color: var(--main-color);
  font-weight: var(--font-weight-bold);
  z-index: 1;
}

