



































































































































































































































































.idt-file-upload {
  color: var(--black);
  font-weight: var(--font-weight-medium);

  &__title {
    margin: var(--rem-size-8) 0;
    font-weight: var(--font-weight-bold);
  }

  &__area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 2px dashed var(--grey);
    border-radius: var(--global-radius);
    transition: background-color 200ms ease, border 200ms ease;

    &.idt-file-upload--targeted {
      background-color: var(--grey-light);
      border: 2px dashed var(--grey-dark);
    }

    &__content {
      text-align: center;

      p {
        color: var(--grey-dark);
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin: var(--rem-size-8) 0;
    color: var(--grey-dark);

    &__left,
    &__right {
      margin: 0;
    }
  }

  li + li {
    border-top: 1px solid var(--grey-light);
  }
}
