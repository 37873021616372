












































































@import '@/styles/mixins/index';

.idt-ticket-payment-card {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include respond-to(max-medium) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc((var(--vh, 1vh) * 100));
    background-color: var(--white);
    overflow-y: auto;
    z-index: 1010;
  }
  @include respond-to(medium) {
    height: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    position: relative;
    backface-visibility: hidden;

    @include respond-to(max-medium) {
      padding: 20px 1.5rem 0;
    }
    @include respond-to(medium) {
      border: 3px solid var(--grey-lighter);
      border-radius: var(--global-radius) var(--global-radius) 0 0;
    }

    &:not(.no-button) {
      border-bottom: none;
    }
    * {
      backface-visibility: hidden;
    }
    &__close {
      position: absolute;
      right: 1rem;
      cursor: pointer;
      z-index: 10;

      @include respond-to(max-medium) {
        top: 0.5rem;
      }
      @include respond-to(medium) {
        top: 2rem;
      }
    }
  }
  &__button {
    flex: 0 0 auto;
    width: 100%;
    color: var(--white);
    background-color: var(--main-color);
    font-size: 1.125rem;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    @include respond-to(max-medium) {
      height: 60px;
      border-radius: 0;
    }
    @include respond-to(medium) {
      height: 50px;
      border-radius: 0px 0px var(--global-radius) var(--global-radius);
    }
  }

  & ::v-deep .payment-form__container {
    flex: 1 1 auto;
    padding-top: 1.5rem;
  }
}
