





























































































@import '@/styles/mixins/index';

.gamification-menu {
  display: inline-block;
  position: relative;

  .gamification-widget {
    @include respond-to(max-slarge) {
      display: none !important;
    }
  }
}
