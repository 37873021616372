.conference-alert {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 40vh;
  height: 380px;
  margin: 5px;
  padding: 1.5rem;
  color: var(--white);
  background-color: var(--grey-darkest-alpha75);
  text-align: center;
  z-index: 5;
}

.conference-alert.conf-advice {
  flex-basis: 60%;
}

.conference-alert .idt-conference-time-schedule {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.conference-alert__time {
  margin-bottom: 5px;
  font-size: 2.125rem;
  font-weight: var(--font-weight-bold);
}

.conference-alert__time ::v-deep .idt-conference-duration {
  color: var(--white);
}

.conference-alert__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.conference-alert__content__title {
  color: var(--white);
  font-family: var(--title-font-family);
  font-size: 1.75rem;
  text-transform: uppercase;
}

.conference-alert__content__separator {
  width: 100px;
  margin-top: .5rem;
  border: 1px solid var(--main-color);
}

.conference-alert__content__text--primary {
  margin-bottom: 1rem;
  color: var(--white);
  font-size: 1rem;
}

.conference-alert__content__text--secondary {
  margin-bottom: .5rem;
  color: var(--white);
  font-size: .625rem;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.conference-alert__content__button-group {
  margin-bottom: 20px;
}

.conference-alert__footer {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.conference-alert__footer__relaunch-test {
  color: var(--white);
  border-bottom: 1px solid var(--white);
  font-size: .6rem;
  font-weight: var(--font-weight-medium);
}

.conference-alert__footer__relaunch-test:hover {
  color: var(--main-color);
  border-bottom: 1px solid var(--main-color);
}

.conference-alert button,
.conference-alert a.button {
  min-width: 130px;
  margin-bottom: .625rem;
  border-color: var(--white);
  color: var(--main-color-dark);
  background-color: var(--white);
  border-radius: 3px;
  font-size: .8rem;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.conference-alert button.postpone-button,
.conference-alert a.button.postpone-button {
  margin: 0 auto;
  display: block;
  padding: .5rem;
  border-color: var(--white);
  color: var(--color);
  font-size: .75rem;
  text-transform: none;
}

.conference-alert button.postpone-button:hover,
.conference-alert button.postpone-button:focus,
.conference-alert a.button.postpone-button:hover,
.conference-alert a.button.postpone-button:focus {
  color: var(--color);
  background-color: var(--black-alpha30);
  border-color: var(--white);
}

.conference-alert button.postpone-button.disabled,
.conference-alert a.button.postpone-button.disabled {
  background-color: var(--grey-darkest);
  border-color: var(--grey-darkest);
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.25);
  opacity: 1;
  pointer-events: none;
}

.conference-alert button.postpone-button svg,
.conference-alert a.button.postpone-button svg {
  --spinner-stroke-color: white;
  --spiner-radius: 10px;
}

.conference-alert a {
  color: var(--white);
}

.conference-alert a:hover {
  color: var(--white);
  background-color: var(--main-color);
}

.conf-live {
  color: var(--color-live);
}

.conf-replay {
  color: var(--color-replay);
  border: none;
}

.conf-scheduled {
  color: var(--color-scheduled);
}

.conf-standby {
  color: var(--color-starting);
}

.conf-starting {
  color: var(--color-starting);
}

