
















































.bo-project-confirm-modal {
  color: var(--grey-darkest);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);

  &__title {
    margin-bottom: var(--rem-size-8);
    font-weight: var(--font-weight-medium);
    font-size: var(--rem-size-18);
    line-height: var(--rem-size-24);
  }

  &__msg {
    display: flex;
    align-items: center;
    margin: var(--rem-size-16) 0;
    padding: var(--rem-size-12) var(--rem-size-20);
    border-radius: var(--rem-size-5);
    background: var(--grey-light);
    font-weight: var(--font-weight-medium);

    .ideacon {
      color: var(--grey-dark);
      margin-right: var(--rem-size-20);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: calc(0 - var(--rem-size-4));
    gap: var(--rem-size-8);

    .button {
      margin: var(--rem-size-4);
    }
  }
}
