






























@import '@/styles/mixins/index';

.payment-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  &__title {
    color: var(--grey-darkest);
    font-weight: var(--font-weight-medium);
    line-height: normal;
    letter-spacing: 0.5px;

    @include respond-to(max-medium) {
      margin-bottom: 20px;
      font-size: 1.75rem;
    }
    @include respond-to(medium-large) {
      margin-bottom: 25px;
      font-size: 2.5rem;
    }
    @include respond-to(large) {
      margin-bottom: 30px;
      font-size: 2.5rem;
    }
  }
  &__text {
    color: var(--grey-darker);
    font-weight: var(--font-weight-light);
    line-height: 1.25;

    @include respond-to(max-medium) {
      font-size: 1rem;
    }
    @include respond-to(medium-large) {
      font-size: 1.25rem;
    }
    @include respond-to(large) {
      font-size: 1.375rem;
    }
  }
  &__spinner {
    color: var(--secondary-color);
  }

  & ::v-deep .idt-spinner {
    @include respond-to(max-medium) {
      --spinner-radius: 25px;
      margin-bottom: 20px;
    }
    @include respond-to(medium-large) {
      --spinner-radius: 35px;
      margin-bottom: 25px;
    }
    @include respond-to(large) {
      --spinner-radius: 40px;
      margin-bottom: 30px;
    }
  }
}
