.bo-header {
  position: sticky;
  top: var(--menu-height);
  width: 100%;
  height: var(--bo-header-height);
  background-color: var(--white);
  z-index: 10;
}

@media only screen and (min-width: 1200px) {
  .bo-header {
    display: flex;
    align-items: center;
    padding: 0 24px 0 60px;
    box-shadow: 0 2px 3px var(--black-alpha15);
  }
}

.bo-header__mobile-container {
  display: flex;
  align-items: center;
  height: var(--bo-header-height);
}

@media only screen and (max-width: 1199px) {
  .bo-header__mobile-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 20px 0 30px;
    background-color: var(--white);
    box-shadow: 0 2px 3px var(--black-alpha15);
    z-index: 10;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__mobile-container {
    flex: 0 0 auto;
  }
}

.bo-header__page-title {
  margin: 0;
  color: var(--grey-darker);
  font-weight: var(--font-weight-light);
  line-height: 1.6;
}

@media only screen and (max-width: 1199px) {
  .bo-header__page-title {
    font-size: var(--rem-size-22);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__page-title {
    font-size: var(--rem-size-28);
  }
}

.bo-header__page-title__container {
  flex: 0 0 auto;
  margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .bo-header__toggle-content {
    flex: 0 0 auto;
    position: relative;
    width: 36px;
    height: 36px;
    border: 1px solid var(--main-color);
    border-radius: 100%;
    margin-left: auto;
    transition: all .3s ease-in-out;
  }

  .bo-header__toggle-content span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 1px;
    background-color: var(--main-color);
    transition: all .3s ease-in-out;
  }

  .bo-header__toggle-content span:first-child {
    transform: translate(-50%, -50%);
  }

  .bo-header__toggle-content span:last-child {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .bo-header__toggle-content.open {
    border-color: var(--secondary-color);
  }

  .bo-header__toggle-content.open span {
    background-color: var(--secondary-color);
  }

  .bo-header__toggle-content.open span:first-child {
    transform: translate(-50%, -50%) rotate(135deg);
  }

  .bo-header__toggle-content.open span:last-child {
    transform: translate(-50%, -50%) rotate(225deg);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__toggle-content {
    display: none;
  }
}

.bo-header__content {
  display: flex;
}

@media only screen and (max-width: 639px) {
  .bo-header__content {
    flex-direction: column;
    position: fixed;
    top: calc(var(--vh)*100*-1);
    height: calc(var(--vh)*100 - (var(--menu-height) + var(--bo-header-height)));
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .bo-header__content {
    flex-direction: column;
    position: absolute;
    top: calc(var(--vh)*100*-1);
    height: calc(var(--vh)*100 - (var(--menu-height) + var(--bo-header-height)));
  }
}

@media only screen and (max-width: 1199px) {
  .bo-header__content {
    left: 0;
    width: 100%;
    background-color: var(--grey-lighter);
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  .bo-header__content.open {
    top: var(--bo-header-height);
    opacity: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__content {
    align-items: center;
    flex: 1 1 auto;
  }
}

.bo-header__content .button {
  margin: 0;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

@media only screen and (max-width: 1199px) {
  .bo-header__content .button {
    width: 100%;
    height: 60px;
    padding-left: 30px;
    font-size: 1rem;
    border-radius: 0;
    text-align: left;
  }

  .bo-header__content .button:first-child {
    border-top: 1px solid var(--grey-light);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__section:not(:last-child) {
    padding-right: var(--rem-size-20);
  }
}

@media only screen and (max-width: 1199px) {
  .bo-header__activation {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    order: 1;
    height: var(--bo-header-elm-height);
    padding-left: 30px;
    background-color: var(--white);
  }

  .bo-header__activation:not(.full-width) {
    width: 50%;
  }

  .bo-header__activation.full-width {
    width: 100%;
  }

  .bo-header__activation.hide-section,
  .bo-header__activation .bo-section-activation {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__activation {
    padding-right: 10px;
  }

  .bo-header__activation.no-activation {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-header__actions {
    flex: 0 0 auto;
    order: 3;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__actions {
    display: flex;
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-header__actions .button {
    background-color: var(--white);
    border: none;
    border-bottom: 1px solid var(--grey-light);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-header__actions .button {
    margin-right: 10px;
  }
}

@media (hover: hover) {
  .bo-header__actions .button:hover {
    color: var(--white);
    background-color: var(--main-color);
    border-color: var(--main-color);
  }
}

.bo-header__actions a.button {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .bo-header__info {
    flex: 0 0 auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    order: 2;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-header__statistics {
    order: 4;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-header__save {
    order: 5;
  }
}

.bo-header__save .button {
  text-transform: uppercase;
}

