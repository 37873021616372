.idt-carousel__container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.idt-carousel__container__button {
  width: 55px;
  height: 55px;
  margin: var(--rem-size-16);
  background-color: var(--grey-lighter);
  color: var(--grey-darker);
  font-size: var(--rem-size-20);
  border-radius: 50px;
}

.idt-carousel__container__slider {
  position: relative;
  border-radius: var(--global-radius);
  overflow: hidden;
}

.idt-carousel__container__slider img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.idt-carousel__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--rem-size-16);
}

.idt-carousel__footer__dot-list {
  display: flex;
}

.idt-carousel__footer__dot-list__dot {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: var(--global-radius);
  background-color: var(--grey-light);
  cursor: pointer;
}

.idt-carousel__footer__dot-list__dot--active {
  background-color: var(--main-color);
}

::v-deep .disabled:hover,
::v-deep .disabled:focus {
  background-color: var(--grey-lighter);
  color: var(--grey-darker);
}

.slide-leave-active,
.slide-enter-active {
  transition: .8s;
}

.slide-enter {
  transform: translate(100%, 0);
  opacity: .8;
}

.slide-leave-to {
  transform: translate(-100%, 0);
  opacity: 0;
}

.slideback-leave-active,
.slideback-enter-active {
  transition: .8s;
}

.slideback-enter {
  transform: translate(-100%, 0);
  opacity: .8;
}

.slideback-leave-to {
  transform: translate(100%, 0);
  opacity: 0;
}

