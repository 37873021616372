






























































































































@import '@/styles/mixins/index';

.fo-availabilities-editor {
  display: flex;

  @include respond-to(max-medium) {
    flex-direction: column;
  }

  & ::v-deep {
    .selection-container {
      width: calc(100% - 245px)
    }

    .idt-availabilities-slot-list {
      width: 245px;
      max-height: 730px;
    }

    @include respond-to(max-medium) {
      .selection-container, .idt-availabilities-slot-list {
        width: 100%;
      }
    }
  }
}
