















































.idt-survey-result-rating {
  &__table {
    margin-top: 1rem;

    &__row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      color: var(--black);
      cursor: pointer;

      .progress {
        --progress-color: var(--yellow);

        flex-grow: 1;
        height: 7px;
        margin: 0 var(--rem-size-10);
        border-radius: var(--global-radius);

        & ::v-deep .progress-meter {
          border-radius: var(--global-radius);
        }
      }
    }
  }
}
