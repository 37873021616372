.conference-page {
  --menu-height: 70px;
  --video-height: 48vw;
  --menu-and-footer-height: calc( var(--menu-height) + var(--conference-footer-height) );
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100vh - var(--menu-height));
  height: calc(var(--vh, 1vh)*100 - var(--menu-height));
}

@media only screen and (max-width: 991px) {
  .conference-page {
    flex-flow: column nowrap;
  }
}

@media only screen and (min-width: 992px) {
  .conference-page {
    flex-flow: row;
  }
}

.conference-page__video {
  position: relative;
}

@media only screen and (max-width: 991px) {
  .conference-page__video {
    flex: 0 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .conference-page__video {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.conference-page__video__container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(var(--vh, 1vh)*100);
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .conference-page__video__container {
    max-height: calc(100% - var(--conference-footer-height)) !important;
  }
}

.conference-page__chat {
  height: calc(100vh - 70px);
}

@media only screen and (max-width: 991px) {
  .conference-page__chat {
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 991px)and (min-aspect-ratio: 13/9) {
  .conference-page__chat {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .conference-page__chat {
    flex: 0 0 auto;
    width: 440px;
  }
}

@media only screen and (min-width: 640px) {
  .conference-page__chat ::v-deep .conference-sidebar {
    border-left: 1px solid var(--grey-lighter);
  }
}

@media only screen and (min-width: 992px) {
  .conference-page__chat ::v-deep .conference-sidebar {
    flex-grow: 1;
  }
}

.conference-page__chat ::v-deep .conference-sidebar__title {
  font-size: 18px;
  text-transform: uppercase;
}

.conference-page__chat ::v-deep .room {
  flex-grow: 1;
}

@media only screen and (max-width: 1023px) {
  .conference-page .mentor-mobile-chat:not(.pending),
  .conference-page .mentor-mobile-chat:not(.replay) {
    height: calc(100vh - var(--menu-and-header-height));
    height: calc(var(--vh, 1vh)*100 - var(--menu-and-header-height));
  }

  .conference-page .mentor-mobile-chat.pending,
  .conference-page .mentor-mobile-chat.replay {
    height: calc(100vh - (var(--video-height) + 110px));
    height: calc(var(--vh, 1vh)*100 - (var(--video-height) + 110px));
  }
}

@media only screen and (max-width: 1023px)and (min-aspect-ratio: 13/9) {
  .conference-page .participant-mobile-chat {
    display: none;
  }
}

