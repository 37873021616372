





























































































































@import '@/styles/mixins/index';

.account-menu {
  display: inline-block;
  position: relative;

  &__toggle {
    padding: 0 var(--rem-size-12);
    cursor: pointer;
  }
}
.hide-on-mobile {
  @include respond-to(max-slarge) {
    display: none !important;
  }
}

.show-on-mobile {
  @include respond-to(slarge) {
    display: none !important;
  }
}
