






































































































































































@import '@/styles/mixins/index';

.bo-header {
  position: sticky;
  top: var(--menu-height);
  width: 100%;
  height: var(--bo-header-height);
  background-color: var(--white);
  z-index: 10;

  @include respond-to(xlarge) {
    display: flex;
    align-items: center;
    padding: 0 24px 0 60px;
    box-shadow: 0 2px 3px var(--black-alpha15);
  }

  &__mobile-container {
    display: flex;
    align-items: center;
    height: var(--bo-header-height);

    @include respond-to(max-xlarge) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 20px 0 30px;
      background-color: var(--white);
      box-shadow: 0 2px 3px var(--black-alpha15);
      z-index: 10;
    }
    @include respond-to(xlarge) {
      flex: 0 0 auto;
    }
  }
  &__page-title {
    margin: 0;
    color: var(--grey-darker);
    font-weight: var(--font-weight-light);
    line-height: 1.6;

    @include respond-to(max-xlarge) {
      font-size: var(--rem-size-22);
    }
    @include respond-to(xlarge) {
      font-size: var(--rem-size-28);
    }

    &__container {
      flex: 0 0 auto;
      margin-right: 30px;
    }
  }
  &__toggle-content {
    @include respond-to(max-xlarge) {
      flex: 0 0 auto;
      position: relative;
      width: 36px;
      height: 36px;
      border: 1px solid var(--main-color);
      border-radius: 100%;
      margin-left: auto;
      transition: all 0.3s ease-in-out;

      span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 1px;
        background-color: var(--main-color);
        transition: all 0.3s ease-in-out;

        &:first-child {
          transform: translate(-50%, -50%);
        }
        &:last-child {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      &.open {
        border-color: var(--secondary-color);

        span {
          background-color: var(--secondary-color);

          &:first-child {
            transform: translate(-50%, -50%) rotate(135deg);
          }
          &:last-child {
            transform: translate(-50%, -50%) rotate(225deg);
          }
        }
      }
    }
    @include respond-to(xlarge) {
      display: none;
    }
  }
  &__content {
    display: flex;

    @include respond-to(max-medium) {
      flex-direction: column;
      position: fixed;
      top: calc((var(--vh) * 100) * -1);
      height: calc((var(--vh) * 100) - (var(--menu-height) + var(--bo-header-height)));
    }
    @include respond-to(medium-xlarge) {
      flex-direction: column;
      position: absolute;
      top: calc((var(--vh) * 100) * -1);
      height: calc((var(--vh) * 100) - (var(--menu-height) + var(--bo-header-height)));
    }
    @include respond-to(max-xlarge) {
      left: 0;
      width: 100%;
      background-color: var(--grey-lighter);
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &.open {
        top: var(--bo-header-height);
        opacity: 1;
      }
    }
    @include respond-to(xlarge) {
      align-items: center;
      flex: 1 1 auto;
    }

    .button {
      margin: 0;
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-medium);

      @include respond-to(max-xlarge) {
        width: 100%;
        height: 60px;
        padding-left: 30px;
        font-size: 1rem;
        border-radius: 0;
        text-align: left;

        &:first-child {
          border-top: 1px solid var(--grey-light);
        }
      }
    }
  }
  &__section {
    @include respond-to(xlarge) {
      &:not(:last-child) {
        padding-right: var(--rem-size-20);
      }
    }
  }
  &__activation {
    @include respond-to(max-xlarge) {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      order: 1;
      height: var(--bo-header-elm-height);
      padding-left: 30px;
      background-color: var(--white);

      &:not(.full-width) {
        width: 50%;
      }
      &.full-width {
        width: 100%;
      }
      &.hide-section,
      .bo-section-activation {
        display: none;
      }
    }
    @include respond-to(xlarge) {
      padding-right: 10px;

      &.no-activation {
        display: none;
      }
    }
  }
  &__actions {
    @include respond-to(max-xlarge) {
      flex: 0 0 auto;
      order: 3;
    }
    @include respond-to(xlarge) {
      display: flex;
      flex: 1 1 auto;
    }

    .button {
      @include respond-to(max-xlarge) {
        background-color: var(--white);
        border: none;
        border-bottom: 1px solid var(--grey-light);
      }
      @include respond-to(xlarge) {
        margin-right: 10px;
      }

      @media (hover: hover) {
        &:hover {
          color: var(--white);
          background-color: var(--main-color);
          border-color: var(--main-color);
        }
      }
    }
    a.button {
      display: flex;
      align-items: center;
    }
  }
  &__info {
    @include respond-to(max-xlarge) {
      flex: 0 0 auto;
      position: absolute;
      top: 0;
      right: 0;
      width: 50vw;
      order: 2;
    }
  }
  &__statistics {
    @include respond-to(max-xlarge) {
      order: 4;
    }
  }
  &__save {
    @include respond-to(max-xlarge) {
      order: 5;
    }

    .button {
      text-transform: uppercase;
    }
  }
}
