














































































































































































































































































.idt-ticketing-bo-general {
  .legend {
    width: 220px;
    text-align: center;

    hr {
      height: 1px;
      background-color: var(--grey);
      border: none;
      margin: 15px auto 20px;

      &:first-child {
        margin: 62px auto 0 auto;
      }
      &.bottom-name {
        margin: 0 auto 20px auto;
      }
    }
    &-section {
      padding: 20px 0;
      border-bottom: 1px solid var(--grey);

      &:first-child {
        border-top: 1px solid var(--grey);
      }
      &--name {
        height: 53px;
        margin-top: 62px;
        padding: 0;
      }
      &--price {
        height: 98px;
      }
      &--live {
        height: 264px;
      }
      &--replay {
        height: 176px;
      }
      &--interview {
        height: 210px;
      }
      .title {
        margin-bottom: 15px;
        color: var(--grey-darkest);
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;

        &--name {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin-bottom: 0;
        }
        &--price {
          margin-bottom: 25px;
        }
      }
      .description {
        color: var(--black);
        font-size: 13px;
        font-weight: 300;
        font-style: italic;
        opacity: 0.5;

        &--live {
          height: 52px;
          margin-bottom: 42px;
        }
      }
      label {
        margin-bottom: 20px;
        color: var(--grey-darkest);
        font-size: 15px;
        font-weight: 500;

        &.live-chat {
          margin-bottom: 28px;
        }
        &.download {
          margin-bottom: 22px;
        }
      }
    }
  }
  .bo-ticketing {
    &__block__add {
      position: relative;
      height: calc(100% - 64px);
      min-height: 750px;
      margin-top: 62px;
      margin-left: 20px;
      color: var(--main-color);
      border: 2px dashed var(--main-color);
      border-radius: 6px;
      font-size: 1.1rem;
      text-transform: uppercase;
      cursor: pointer;

      &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);

        i {
          display: inline-block;
          margin-bottom: 0.75rem;
          font-size: 5rem;
          vertical-align: middle;
        }
      }
    }
  }
  .tickets {
    width: calc(100% - 220px);
    height: 100%;
  }
}
