





































































































































































































































































































































@import '@/styles/mixins/index';

:root {
  @include respond-to(max-medium) {
    --tk-modal-header-height: 80px;
  }
  @include respond-to(medium-xlarge) {
    --tk-modal-header-height: 140px;
  }
  @include respond-to(xlarge) {
    --tk-modal-header-height: 108px;
  }
}

.ticketing-modal {
  --outer-elements-height-small: 166px;
  --outer-elements-height-large: 180px;

  height: calc((var(--vh, 1vh) * 100));

  .ticketing-blocking-modal {
    display: flex;
    flex-direction: column;
    height: calc((var(--vh, 1vh) * 100));

    &__container {
      display: flex;
      flex: 1 1 auto;
      max-height: calc((var(--vh, 1vh) * 100) - var(--tk-modal-header-height));
      overflow: hidden;

      @include respond-to(max-large) {
        flex-direction: column;

        @include respond-to(landscape) {
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
      @include respond-to(large-xlarge) {
        padding-top: 50px;
      }
      @include respond-to(xlarge) {
        align-items: center;
        padding-top: 2rem;
      }

      &__infos {
        color: var(--black);
        font-weight: var(--font-weight-light);
        line-height: normal;

        @include respond-to(max-xsmall) {
          font-size: 0.875rem;
        }
        @include respond-to(max-medium) {
          padding: 8px 10px;
        }
        @include respond-to(medium-large) {
          margin-bottom: 60px;
          padding: 1.25rem 1.5rem;
        }
        @include respond-to(medium-xlarge) {
          font-size: 1.875rem;
        }
        @include respond-to(max-large) {
          flex: 0 0 auto;
        }
        @include respond-to(large) {
          flex: 1 1 auto;
        }
        @include respond-to(large-xlarge) {
          padding: 1.25rem 1.5rem 1.25rem 2rem;
        }
        @include respond-to(large-xxlarge) {
          max-width: 456px;
        }
        @include respond-to(xlarge) {
          font-size: 2.1875rem;
        }
        @include respond-to(xlarge-xxlarge) {
          padding: 1.25rem 4rem 1.25rem 2rem;
        }
        @include respond-to(xxlarge) {
          max-width: 700px;
          padding: 1.25rem 4rem 1.25rem 6rem;
        }

        strong {
          font-weight: var(--font-weight-medium);
        }
      }
      &__tickets {
        flex: 1 1 auto;

        @include respond-to(max-slarge) {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        @include respond-to(medium-large) {
          padding: 0 10px;
        }
        @include respond-to(large) {
          padding-right: 35px;
        }
        @include respond-to(xlarge-xxlarge) {
          margin-top: -3rem;
        }
      }
      &__no-ticket {
        width: 100%;
        text-align: center;

        &__text {
          font-size: 3.125rem;
          font-weight: var(--font-weight-light);
          color: var(--black);
          line-height: normal;
        }

        .contact-admin {
          width: 200px;
          height: 50px;
          margin-top: 30px;
          font-size: 18px;
          font-weight: var(--font-weight-medium);
          text-align: center;
          text-transform: uppercase;
        }
      }

      .ticketing-payment-success {
        margin-top: 10px;
      }
    }
    &__contact-btn {
      padding: 0.625rem 1.5rem;
      font-size: 0.875rem;
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;

      @include respond-to(medium-slarge) {
        margin: 1rem 0 0 0;
      }
      @include respond-to(slarge) {
        margin: 2rem 0 0 0;
      }

      &.desktop {
        @include respond-to(max-slarge) {
          display: none;
        }
      }
      &.mobile {
        @include respond-to(slarge) {
          display: none;
        }
      }
    }
  }
  .modal-fullscreen__close {
    color: white;

    &__container {
      background-color: inherit;

      @include respond-to(xlarge) {
        top: 54px;
        transform: translateY(-50%);
      }
    }
  }
}

.modal-fullscreen {
  padding: 0 !important;
}

