















































































































.idt-checkbox {
  margin-bottom: 7px;
  padding: 0.5rem;
  background: var(--white);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);

  &--no-border {
    box-shadow: none !important;
    border: 0 !important;
  }

  &--no-padding {
    padding: 0;
  }

  &--checked {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    border-color: transparent;

    .content {
      font-weight: var(--font-weight-medium);
    }
  }

  input {
    display: none;

    &.error {
      & + label .box {
        border: 1px solid var(--red);
      }
    }
    & + label {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 22px;
      min-width: 100px;
      margin: 0;
      padding: 0;
      color: var(--text-color, var(--grey-darker));
      font-weight: var(--font-weight-normal);

      .box {
        content: '';
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        line-height: 0; // This is necessary to keep the icon aligned in the middle on Safari
        border: 1px solid var(--text-color, var(--grey));
        border-radius: 3px;
      }
      .content {
        flex-grow: 1;

        ::v-deep p:last-child {
          margin-bottom: 0;
        }
      }
      .idt-icon {
        color: transparent;
      }
    }
    &:checked + label .box {
      background-color: var(--main-color);
      border: 1px solid var(--main-color);
    }
    &:checked + label .idt-icon {
      color: white;
    }
  }

  &__slot-end {
    flex-grow: 0;
  }
}
