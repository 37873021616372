.conference-presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 100px 0 30px 0;
  background-color: var(--grey-light);
  color: var(--grey-darkest);
  overflow: auto;
}

.conference-presentation__date-container {
  display: flex;
  flex-direction: row;
  vertical-align: center;
  padding: .625rem;
  background-color: var(--white);
  font-weight: var(--font-weight-bold);
  border-radius: var(--global-radius);
}

.conference-presentation__date-container ::v-deep .idt-icon {
  margin-top: -5px;
}

.conference-presentation__date-container__date {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: .625rem;
  border-right: 1px solid #3a3c43;
}

.conference-presentation__date-container__date__text {
  margin-left: .625rem;
}

.conference-presentation__date-container__hour {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: .625rem;
}

.conference-presentation__date-container__hour__text {
  margin-left: .625rem;
}

.conference-presentation__speakers {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 var(--rem-size-16);
}

.conference-presentation__speakers ::v-deep .idt-user-avatar {
  margin: 10px 0;
  border: 5px solid var(--white);
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
}

.conference-presentation__speakers__item {
  flex: 0 0 auto;
  max-width: 200px;
  padding: var(--rem-size-20) var(--rem-size-16);
  text-align: center;
}

.conference-presentation__speakers__item__name {
  font-size: .875rem;
  font-weight: var(--font-weight-medium);
}

.conference-presentation__speakers__item__job {
  font-size: .75rem;
  color: var(--grey-darker);
}

.conference-presentation__separator {
  width: 50%;
  margin: 2rem 0;
}

.conference-presentation__external-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 2.5rem;
}

.conference-presentation__external-link__input {
  margin: 0 var(--rem-size-16) 0 0;
}

.conference-presentation__title {
  max-width: 1000px;
  padding: 1.5rem;
  font-family: var(--title-font-family);
  line-height: 1.1;
}

@media only screen and (max-width: 1023px) {
  .conference-presentation__title {
    font-size: var(--rem-size-24);
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1441px) {
  .conference-presentation__title {
    font-size: var(--rem-size-30);
  }
}

@media only screen and (min-width: 1442px) {
  .conference-presentation__title {
    font-size: var(--rem-size-35);
  }
}

