.mentor {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: var(--white);
  border-radius: var(--global-radius);
}

.mentor:hover {
  cursor: pointer;
}

.mentor__left {
  display: flex;
  width: 50%;
}

.mentor__left__image {
  margin: 0 10px 0 20px;
}

.mentor__left__infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
}

.mentor__left__infos__name {
  max-width: 100%;
  color: var(--black);
  font-family: var(--title-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-20);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mentor__left__infos__job-company {
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
}

.mentor__separator {
  position: absolute;
  right: 50%;
  height: 100%;
  color: var(--grey-lighter);
  border: 1px solid;
}

.mentor__right {
  display: flex;
}

.mentor__right__message {
  margin-right: var(--rem-size-10);
}

.mentor__right__book {
  margin-right: var(--rem-size-10);
}

