label {
  display: block;
  margin: 0;
  padding-bottom: var(--rem-size-8);
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-18);
}
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  height: auto;
  padding: 0.6566rem 1rem;
  background-color: $white;
  border: 1px solid $input-border;
  line-height: 1.6;

  box-shadow: 0 0 0 rgba($black, 0);

  &:focus{
    border-color: var(--grey-darker);

    box-shadow: 0 0 0 rgba($black, 0);
  }
}
input[type=range] {
  margin-bottom: 1rem;

  @include respond-to(max-medium) {
    width: 100%;
  }
  @include respond-to(medium) {
    width: 300px;
  }
}
select {
  position: relative;
  padding-right: 2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  background-position: 98% center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }
}

// dropdown with checkboxs
.menuitem-checkbox {
  padding: 3px 20px;
  display: block;
  font-weight: normal;
}

.menuitem-checkbox:hover,
.menuitem-checkbox:active {
  background-color: var(--grey-lightest);
}

// Input group with button
.input-group {
  input {
    margin: 0;
  }
  button {
    height: 100%;
  }
}
.ui.fluid.search.selection.dropdown {
  border-color: var(--grey);
  &.active {
    border-color: var(--grey-darker);
    &:hover {
      border-color: var(--grey-darker);
      .menu {
        border-color: var(--grey-darker);
      }
    }
    .menu {
      border-color: var(--grey-darker);
    }
  }
}

// Form group
.form-group {
  position: relative;
  margin-bottom: var(--rem-size-24);

  &--small {
    margin-bottom: var(--rem-size-8);
  }
  &__title {
    margin-bottom: 1.25rem;
    color: var(--grey-darker);
    font-weight: 500;
  }
}
.idt-input-group {
  margin-bottom: 1rem;
  background-color: $white;
  box-shadow: 2px 2px 2px -1px var(--grey);

  &__item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    padding: 1.5rem 0.5625rem;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey);
    }
    input,
    select,
    textarea {
      margin: 0;
      padding: 0.5rem 0;
      border: 0;
      border-bottom: 1px solid var(--grey);
      border-radius: 0;
      box-shadow: none;

      &:focus {
        border: 0;
        border-bottom: 1px solid var(--grey-darker);
        box-shadow: none;
      }
    }
    .select-container {
      position: relative;

      &:before,
      &:after {
        display: block;
        position: absolute;
        font-family: 'ideation-icons' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
      }
      &:before {
        content: '\e954';
        top: 0.30rem;
        right: 0;
        z-index: 1;
      }
      &:after {
        content: '\e951';
        bottom: 0.30rem;
        right: 0;
      }
    }
  }
}

// Error styles
.error,
.required-field {
  position: relative;
  margin-bottom: 0;
  border-color: var(--danger-color) !important;
}


.idt-required-field,
required-field,
.required-field-text {
  color: var(--danger-color) !important;
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);

  div {
    margin-bottom: 0.25rem;
    text-align: left;
  }

  &--center {
    div {
      text-align: center;
    }
  }
}

.required-field-text {
  margin-bottom: 0.25rem;
  text-align: left;

  &--center {
    div {
      text-align: center;
    }
  }
}
