$unregisteredFooter: 60px;

// -----------------------------
// 2. Page top background
// -----------------------------
.idt-page-top {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 380px;
  background-color: var(--main-color-darkest);
  overflow: hidden;

  @include respond-to(max-medium) {
    flex-flow: column;
  }

  &--small {
    @include respond-to(max-medium) {
      min-height: auto;
    }
    @include respond-to(medium) {
      min-height: 100px;
    }
  }
  &__header {
    flex: 1 0 auto;
    width: 100%;
    z-index: 1;

    .row {
      justify-content: center;
    }
  }
  &__search {
    margin: 0;
    padding-right: 2.2rem;
    color: var(--white);
    background-color: var(--white-alpha20);
    border: none;

    &::placeholder {
      color: var(--white);
    }
    &:focus {
      border: none;
      background-color: var(--white-alpha20);
    }
    &__container {
      position: relative;

      @include respond-to(max-medium) {
        flex: 0 0 100%;
        margin-bottom: 1rem;
      }
      @include respond-to(medium-large) {
        flex: 0 0 50%;
      }
      @include respond-to(max-large) {
        order: 2;
      }
      @include respond-to(large) {
        flex: 0 0 33%;
      }

      &:after {
        content: '\e96e';
        display: block;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        color: var(--white);
        font-family: var(--ideacons-font);
        font-size: 1.2rem;
        transform: translateY(-50%);
      }
    }
  }
  &__title {
    margin: 0;
    color: var(--white);
    font-family: var(--title-font-family);
    font-weight: 300;
    text-align: center;

    @include respond-to(max-medium) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    @include respond-to(medium-large) {
      font-size: 1.75rem;
    }
    @include respond-to(max-large) {
      margin-bottom: 1rem;
    }
    @include respond-to(large) {
      font-size: 2.125rem;
    }

    &__container {
      @include respond-to(max-large) {
        flex: 0 0 100%;
        order: 1;
      }
    }
  }
  &__actions {
    display: flex;
    position: relative;

    @include respond-to(max-large) {
      order: 3;
    }
    @include respond-to(large) {
      flex: 0 0 33%;
    }

    &:not(.is-column) {
      align-items: center;

      @include respond-to(max-medium) {
        justify-content: center;
        margin-bottom: 1rem;
      }
      @include respond-to(medium) {
        justify-content: flex-end;
      }
    }
    &.is-column {
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-end;
    }
    .switch-view {
      margin: 0;
      padding: 0.6rem 1.1rem;
      color: var(--main-color-dark);
      background: var(--main-color-lighter);
      border-radius: var(--global-radius);
      font-size: var(--rem-size-14);
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1.4;
      transition: all 0.3s ease-in-out;

      span {
        margin: 0 0.3rem;
        vertical-align: text-top;
      }
      &:hover {
        color: var(--white);
        background: var(--main-color);
      }
      *:last-child {
        margin-left: 0.4rem;
      }
    }

    .button {
      margin: 0;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.4;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      &.has-separator {
        position: relative;
        margin-left: 3rem;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: -1.5rem;
          height: 1.5rem;
          border-right: 1px solid var(--grey);
          transform: translate(-50%, -50%);
        }
      }
    }
    .link {
      font-size: 1.1rem;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}
.idt-page-top-mobile {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 35px;
  background-color: var(--main-color);
  overflow: hidden;
  z-index: 10;

  &.conference-top {
    @include respond-to(landscape) {
      display: none;
    }
  }
  .idt-page-top {
    color: $white;

    &__blur {
      display: none;
    }
  }
}

// -----------------------------
// 3. Pages Section
// -----------------------------
.idt-page-section {
  display: flex;
  align-items: center;
  min-height: 122px;

  &__content {
    flex: 1 1 auto;

    &:not(.align-middle) {
      align-items: flex-end;
    }
  }
  &__left {
    @include respond-to(max-medium) {
      margin-bottom: 1.5rem;
    }
  }
  &__right {
    text-align: right;
  }
  &__title {
    margin: 0;
    font-family: var(--title-font-family);
    font-weight: 300;

    @include respond-to(max-medium) {
      font-size: 1.35rem;
    }
    @include respond-to(medium-large) {
      font-size: 1.8rem;
    }
    @include respond-to(large) {
      font-size: 2.1875rem; // 35px
      line-height: 1;
    }
  }
  &__filters {
    margin-top: -30px;
    text-align: right;

    &__label {
      font-size: 0.9rem;
      font-weight: 300;
    }
    &__button {
      min-width: 100px;
      padding: var(--rem-size-4) var(--rem-size-8);
      border: none;
      background-color: $white;
      font-size: 13px;

      &:hover,
      &:active,
      &.active {
        background-color: var(--main-color);
        color: $white;
      }
    }
    .button-group {
      justify-content: flex-end;
      margin: 0;

      .button {
        &:not(:first-child):not(:last-child) {
          border-radius: 0;
        }
        &:first-child {
          border-radius: var(--global-radius) 0 0 var(--global-radius);
        }
        &:last-child {
          border-radius: 0 var(--global-radius) var(--global-radius) 0;
        }
        &:first-child:last-child {
          border-radius: var(--global-radius);
        }
      }
    }
  }
  &__tags {
    text-align: right;

    &__label {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }
  &__tag-list {
    margin-top: 0.5rem;

    &__tag {
      display: inline-block;
      height: 30px;
      margin-left: 0.5rem;
      padding: 0.5rem;
      color: var(--main-color-dark);
      background-color: var(--main-color-alpha20);
      border-radius: 0;
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.1;
      cursor: pointer;

      &.hollow {
        color: $white;
        background-color: var(--main-color);
      }
    }
  }
  .button {
    &--back {
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;

      i {
        display: inline-block;
        margin-right: 0.5rem;
        font-size: 1.2rem;
        vertical-align: text-bottom;
      }
    }
  }
}

.idt-page {
  &__no-item {
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
  }
}

.idt-page.idt-page-profile {
  .page__alerts__container {
    --top: calc(var(--menu-height) + 55px);
    top: var(--top);
    max-height: calc(100vh - var(--top));
  }
}
