






















































































































@import '@/styles/mixins/index';

.idt-ticket-features {
  display: flex;
  flex-direction: column;

  @include respond-to(xsmall) {
    height: 100%;
  }
  @include respond-to(max-medium) {
    justify-content: space-around;
  }

  &__section {
    @include respond-to(max-xlarge) {
      display: none;
    }
    @include respond-to(xlarge) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      color: var(--grey-darker);
      background-color: var(--grey-lighter);
      font-size: 0.875rem;
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
    }
  }
}
