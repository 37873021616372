.next-timeslot-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 427px;
  height: 56px;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.next-timeslot-card__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--rem-size-36);
  height: var(--rem-size-36);
  border-radius: 100%;
  color: var(--white);
  background-color: var(--grey-dark);
}

.next-timeslot-card__text {
  color: var(--black);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
}

.next-timeslot-card__link {
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
  cursor: pointer;
}

.next-timeslot-card__link__description {
  margin-right: 1rem;
}

.next-timeslot-card__link__date {
  margin-right: 1rem;
}

.next-timeslot-card__link__icon {
  margin-right: 1rem;
}

