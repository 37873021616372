.idt-ticket-card {
  position: relative;
}

@media only screen and (min-width: 640px) {
  .idt-ticket-card {
    min-height: 500px;
  }
}

.idt-ticket-card__ticket {
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 3px solid var(--grey-lighter);
  border-left: 3px solid var(--grey-lighter);
  border-right: 3px solid var(--grey-lighter);
  border-bottom: none;
  border-top-right-radius: var(--global-radius);
  border-top-left-radius: var(--global-radius);
}

@media only screen and (max-width: 369px)and (max-aspect-ratio: 13/9) {
  .idt-ticket-card__ticket {
    height: calc(var(--vh, 1vh)*100 - (var(--tk-modal-header-height) + var(--outer-elements-height-small)));
  }
}

@media only screen and (max-width: 369px)and (min-aspect-ratio: 13/9) {
  .idt-ticket-card__ticket {
    height: auto;
  }
}

@media only screen and (min-width: 370px)and (max-width: 639px)and (max-aspect-ratio: 13/9) {
  .idt-ticket-card__ticket {
    height: calc(var(--vh, 1vh)*100 - (var(--tk-modal-header-height) + var(--outer-elements-height-large)));
  }
}

@media only screen and (min-width: 370px)and (max-width: 639px)and (min-aspect-ratio: 13/9) {
  .idt-ticket-card__ticket {
    height: auto;
  }
}

.idt-ticket-card__ticket__no-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  padding: 1rem;
}

.idt-ticket-card__ticket.no-button {
  border-bottom: 3px solid var(--grey-lighter);
  border-bottom-left-radius: var(--global-radius);
  border-bottom-right-radius: var(--global-radius);
}

.idt-ticket-card__ticket__content {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(var(--white) 30%, rgba(255, 255, 255, 0)),linear-gradient(rgba(255, 255, 255, 0), var(--white) 70%) 0 100%,radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: var(--white);
  background-size: 100% 40px,100% 40px,100% 14px,100% 14px;
  background-attachment: local,local,scroll,scroll;
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-card__ticket__content {
    border-top: 2px solid var(--grey-lighter);
  }
}

.idt-ticket-card__button {
  width: 100%;
  height: 50px;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 0px 0px var(--global-radius) var(--global-radius);
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.idt-ticket-card__button.invoice {
  background-color: var(--grey-darkest);
}

