.idt-project-participant-card {
  width: 300px;
  height: 325px;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
  cursor: pointer;
}

.idt-project-participant-card__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.idt-project-participant-card ::v-deep .idt-tag-list {
  margin-top: 10px;
}

