




































































































































































































































































































































.idt-ticket-template {
  width: 100%;
  margin-left: 20px;

  .ticket-cosmetics {
    display: flex;
    justify-content: flex-end;
    height: 62px;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      margin-left: 10px;

      label {
        font-size: 13px;
        font-weight: 500;
      }
    }
    &__tooltip {
      position: relative;
      width: 28px;
      height: 28px;

      .selection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: solid 2px var(--black);
        border-radius: 50%;
        cursor: pointer;
      }
      &__content {
        display: none;
        position: absolute;
        top: -100vh;
        right: -35px;
        width: 214px;
        padding: 15px;
        background-color: var(--white);
        box-shadow: 0 8px 10px -5px var(--black-alpha20),
                    0 8px 24px 2px var(--black-alpha14),
                    0 6px 30px 5px var(--black-alpha12);
        transition: top 0s 0.5s, opacity 0.5s;
        z-index: 9999;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          right: 42px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent var(--white) transparent;
        }
        &.show {
          display: block;
          top: 42px;
        }
        label {
          display: block;
          color: var(--black);
          font-size: 13px;
          font-weight: 500;
          opacity: 0.4;
        }
        .items {
          line-height: 0;

          .item {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 40px;
            border-radius: 50%;
            vertical-align: middle;
            cursor: pointer;

            &--icon {
              height: 46px;
            }
            &.selected {
              border: solid 2px var(--main-color);
            }
            &__color {
              width: 30px;
              height: 30px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  .ticket {
    &--content {
      background-color: var(--white);
      border: 1px solid var(--grey);
      border-radius: var(--global-radius);
    }
    &-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid var(--grey);

      .row {
        margin: 0 0 20px 0;

        &:last-child {
          margin-bottom: 0;
        }
        &.time-choice {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 24px 0;

          label {
            display: inline-block;
            margin: 0 8px;
            color: var(--grey-darkest);
            font-size: 13px;
            font-weight: 500;
            opacity: 0.3;
          }
        }

        &.price {
          display: flex;
          justify-content: center;
        }
      }

      .input-container {
        flex: 1 1 auto;
        position: relative;
        width: calc((100% - 40px) / 2);

        input {
          &.live {
            padding-right: 30px;
          }
          &.replay {
            padding-right: 42px;
          }
          &.interview {
            padding-right: 57px;
          }
        }
        .suffix {
          display: block;
          position: absolute;
          right: 10px;
          top: 12px;
          color: black;
          font-size: 11px;
          font-weight: 500;
          font-style: normal;
          z-index: 99;
          pointer-events: none;
        }
      }

      .input {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 38px;
        padding: 8px;
        margin-bottom: 0;
        border: solid 1px var(--grey);
        font-size: 13px;
        text-align: right;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }
      }
      &--name {
        padding: 10px 20px;
        border-bottom: 1px solid var(--grey);

        .input {
          display: inline-block;
          width: 100%;
          height: 30px;
          padding: 0 0 4px 0;
          margin-bottom: 0;
          color: black;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid var(--main-color);
          border-radius: 0px;
          font-size: 18px;
          font-weight: bold;
          line-height: 30px;
        }
      }
      &--price {
        height: var(--bo-tk-price-height);
      }
      &--replay {
        height: var(--bo-tk-replay-height);
      }
      &--interview {
        height: var(--bo-tk-interview-height);

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        .ui.fluid.search {
          width: 100%;
          height: 38px;
          min-height: 38px;
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 13px;

          &.disabled {
            background-color: #d1d3d4;

            input {
              display: none
            }
          }

          input {
            height: 38px;
            min-height: 38px;
            font-size: 13px;
            text-align: left;
          }
          .item {
            font-size: 13px;
          }
        }
        .mx-datepicker {
          width: 48%;

          &.disabled {
            .mx-input-append {
              display: none;
            }
          }
          .mx-input-wrapper {
            input {
              width: 100%;
              text-align: left;
            }
          }
        }
        .time {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;
        }
      }
      &--action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          width: 100%;

          button {
            display: block;
            width: 100%;
            padding: 20px;
            color: var(--white);
            background-color: var(--secondary-color);
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-size: 17px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .input-limit-desc {
        color: var(--black);
        font-size: 13px;
        font-weight: 300;
        font-style: italic;
        opacity: 0.5;
      }
    }
  }
}

