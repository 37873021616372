.live-chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  background-color: var(--white);
  z-index: 2;
}

.live-chat.only-tab {
  height: 100%;
}

.live-chat.viewer-count {
  height: calc(100% - 113px);
}

.live-chat.only-tab.viewer-count {
  height: calc(100% - 38px);
}

.live-chat__pinned-message {
  border-left: 3px solid var(--main-color);
  box-shadow: 0px 3px 6px var(--grey);
  z-index: 2;
}

.live-chat__pinned-message .live-chat-message {
  height: auto;
  max-height: 300px;
  padding: 10px 17px;
  overflow-y: auto;
}

.live-chat__messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px 10px 0px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.live-chat__messages>:first-child {
  margin-top: auto;
}

.live-chat__notification-new-message {
  position: relative;
}

.live-chat__notification-new-message__button {
  position: absolute;
  height: 13px;
  bottom: 10px;
  left: 10px;
  padding: 0 8px;
  background: var(--main-color);
  color: var(--white);
  font-weight: bold;
  font-size: 10px;
  border-radius: 23px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
}

