.modal-fullscreen {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh)*100);
  padding: 2rem 1rem;
  background-color: var(--white);
  position: fixed;
  overflow: auto;
}

.modal-fullscreen__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 1.5rem 0;
  margin: auto;
}

.modal-fullscreen__title {
  color: var(--grey-darkest);
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .modal-fullscreen__title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .modal-fullscreen__title {
    margin-bottom: 1.5rem;
    font-size: 1.875rem;
  }
}

.modal-fullscreen__subtitle {
  color: var(--main-color);
  font-size: .9rem;
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .modal-fullscreen__subtitle {
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 640px) {
  .modal-fullscreen__subtitle {
    margin-bottom: 4rem;
  }
}

.modal-fullscreen__button__container {
  margin-top: 2rem;
  text-align: center;
}

.modal-fullscreen__close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .modal-fullscreen__close__label {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .modal-fullscreen__close__label {
    display: block;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .modal-fullscreen__close__label {
    margin-right: .5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .modal-fullscreen__close__label {
    margin-right: .75rem;
  }
}

@media only screen and (max-width: 1199px) {
  .modal-fullscreen__close__icon.idt-icon ::v-deep .ideacon {
    width: 1.875rem;
    height: 1.875rem;
  }
}

@media only screen and (min-width: 1200px) {
  .modal-fullscreen__close__icon.idt-icon ::v-deep .ideacon {
    width: 2.8125rem;
    height: 2.8125rem;
  }
}

.modal-fullscreen__close__container {
  position: fixed;
}

@media only screen and (max-width: 639px) {
  .modal-fullscreen__close__container {
    top: 0;
    right: 0;
    padding: .5rem;
    background-color: var(--white);
    text-align: right;
  }
}

@media only screen and (min-width: 640px) {
  .modal-fullscreen__close__container {
    top: 1.875rem;
    right: 1.875rem;
  }
}

