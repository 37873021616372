.menu-credit-item {
  flex: 1 1 100%;
  padding: 0 .15rem;
  text-align: center;
}

.menu-credit-item__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: auto;
  color: var(--white);
  border-radius: 100%;
  font-weight: var(--font-weight-medium);
}

.menu-credit-item__badge.disabled {
  color: var(--grey-dark);
  background-color: var(--grey-lighter);
  border: 1px solid var(--grey-light);
}

.menu-credit-item__label {
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
  line-height: normal;
}

