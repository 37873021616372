













































































































































































































































































































































































































































































































































































































































































































































































































































































.bo-accounts {
  &__password-rule {
    margin-left: 0 !important;
    margin-right: 0 !important;

    &__label {
      width: 210px;
    }
    &__field {
      width: 100px;
      margin-left: 1rem;
    }
    &__length {
      width: 60px;
      height: 34px;
      margin: 0 0 0 0.75rem;
      padding: 0 0 0 0.75rem;
      text-align: center;
      line-height: 34px;
    }
    &__warning {
      display: flex;
      padding-top: 5px;
      color: var(--secondary-color);
      font-size: 0.9rem;

      .ideacons {
          padding-top: 3px;
          padding-right: 5px;
      }
    }
  }
}
