

























































































































































































// Override default Froala toolbar CSS to prevent display issue at loading
.froala-container {
  .fr-toolbar {
    &:not(.fr-sticky-on) {
      top: 0px !important;
    }
    // Ugly hack that removes the video autoplay checkbox, since it's not configurable
    .fr-autoplay-margin {
      display: none;
    }
  }
  &:not(.froala-container--sticky) .fr-toolbar.fr-sticky-on {
    z-index: 0;
  }
  &--sticky .fr-toolbar.fr-sticky-on {
    top: 70px !important;
  }
  [id*=paragraphFormat] {
    h1 {
      font-family: var(--title-font-family);
      font-size: 1.2rem;
    }
    p {
      font-family: var(--text-font-family);
      font-size: 1rem;
    }
  }
}
.fr-view {
  color: var(--grey-darker);
  font-weight: var(--font-weight-normal);
  h1 {
    font-family: var(--title-font-family);
    font-size: 2.25rem;
    font-weight: var(--font-weight-medium);
    strong {
      font-weight: var(--font-weight-bold);
    }
  }
  hr {
    margin: 0.625rem auto;
  }
  p {
    margin: 0.625rem auto;
    font-family: var(--text-font-family);
    strong {
      font-weight: var(--font-weight-medium);
    }
  }
}
// Templates
@import './templates/sponsor-template-with-ressource';
