











































































































































.event-baseline {
  padding: 2.5rem 50px;

  &__container {
    margin: auto;
  }
  &__baseline {
    margin-bottom: var(--rem-size-16);
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  &__over {
    font-size: 1.25rem;
    text-align: center;

    &.after-btn {
      margin-top: 1rem;
    }
    &__text {
      color: var(--main-color);
    }
  }
  &__button {
    width: 100%;
    max-width: 430px;
    margin: auto;
  }
}
