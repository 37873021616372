.talk-links-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 50px;
  padding: 0 .5rem;
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  color: var(--black);
  transition: border-color var(--default-duration) ease;
}

.talk-links-item__label {
  flex: 1 0;
}

.talk-links-item__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: var(--grey-light);
  transition: background-color var(--default-duration) ease;
}

.talk-links-item:hover {
  border-color: var(--grey-dark);
}

.talk-links-item:hover .talk-links-item__action {
  background-color: var(--grey);
}

