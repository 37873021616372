.idt-user-search-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--rem-size-10);
}

.idt-user-search-list-item:not(:last-child) {
  border-bottom: 1px solid var(--grey);
}

.idt-user-search-list-item:not(:last-child).dark {
  border-bottom: 1px solid var(--grey-darkest);
}

.idt-user-search-list-item:hover {
  background: var(--grey-lightest);
}

.idt-user-search-list-item:hover.dark {
  background: 0;
}

.idt-user-search-list-item__left {
  display: flex;
  align-items: center;
  width: 100%;
}

.idt-user-search-list-item__left__avatar {
  margin-right: var(--rem-size-8);
  cursor: pointer;
}

.idt-user-search-list-item__left__content {
  width: 100%;
  margin-right: 50px;
}

.idt-user-search-list-item__left__content__name {
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-20);
}

.idt-user-search-list-item__left__content__name.dark {
  color: var(--white);
}

.idt-user-search-list-item__left__content__tags {
  height: 30px;
  overflow: hidden;
}

.idt-user-search-list-item__left__content__tags ::v-deep .idt-tag-list__item {
  max-width: 75%;
}

.idt-user-search-list-item__left__content__job {
  margin-top: var(--rem-size-5);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
}

.idt-user-search-list-item__right {
  display: flex;
  align-items: center;
}

