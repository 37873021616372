





























































































































































































































































































@import '@/styles/mixins/index';

.fo-project-section-votes-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: calc(var(--rem-size-4) * -1) 0 var(--rem-size-40);
  color: var(--grey-darkest);

  &--wrapper {
    margin: 2rem 3rem;

    @include respond-to(max-medium) {
      margin: 1rem 1.5rem;
    }
  }
  &__title {
    margin-bottom: var(--rem-size-24);
    color: var(--grey-darkest);
    text-align: center;
    font-weight: var(--font-weight-medium);
    font-size: var(--rem-size-36);
    line-height: var(--rem-size-40);
  }
  &__total-votes {
    display: flex;
    margin: var(--rem-size-4) var(--rem-size-24) var(--rem-size-4) 0;

    @include respond-to(max-medium) {
      order: -3;
      margin: 0 0 var(--rem-size-12);
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      margin-right: var(--rem-size-16);
      border: 1.5px solid;
      border-radius: 100%;
    }
    &__bold {
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-medium);
    }
    &__small {
      font-size: var(--rem-size-14);
    }
  }
  &__total-comments {
    margin: var(--rem-size-4) 0 var(--rem-size-4) var(--rem-size-24);
    color: var(--main-color);
    font-size: var(--rem-size-14);
    cursor: pointer;

    @include respond-to(max-medium) {
      order: -2;
      margin-left: 0;
    }

    .ideacon {
      transition: transform .25s;
    }
    &--open .ideacon {
      transform: rotate(180deg);
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--rem-size-4) var(--rem-size-8);
    padding: var(--rem-size-10) var(--rem-size-24);
    border: 1px solid;
    border-radius: 99px;
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    @include respond-to(max-medium) {
      width: 100%;
      margin: var(--rem-size-4) 0;
    }

    &--disabled {
      opacity: .4;
      cursor: auto;
    }
    transition: color .25s, background-color .25s;

    @for $i from 0 through 2 {
      &--color#{$i} {
        color: var(--project-vote-#{$i});
      }
      &--color#{$i}#{&}--selected {
        color: var(--white);
        background: var(--project-vote-#{$i});
      }
    }
  }
  &__filter {
    margin: var(--rem-size-4) 0;

    @include respond-to(max-medium) {
      width: 100%;
      margin: var(--rem-size-20) 0;
    }

    .vs__selected .column,
    .idt-select-container {
      padding: 0;
    }
    .v-select {
      margin: 0 !important;
    }
  }
  &__spacer {
    flex: 1;

    @include respond-to(max-slarge) {
      display: none;
    }
  }
  &__input {
    padding: var(--rem-size-24);
    border: var(--rem-size-1) solid var(--grey-lighter);
    border-radius: var(--global-radius);

    &__title {
      margin-bottom: var(--rem-size-8);
      color: var(--grey-darkest);
      font-weight: var(--font-weight-medium);
      font-size: var(--rem-size-24);
      line-height: var(--rem-size-30);
    }
    &__subtitle {
      margin-bottom: var(--rem-size-32);
      color: var(--grey-darkest);
      font-size: var(--rem-size-14);
      line-height: var(--rem-size-20);
    }
    .fo-comment-input {
      border-radius: var(--global-radius);
    }

    @for $i from 0 through 2 {
      &--color#{$i} &__title__color {
        color: var(--project-vote-#{$i});
      }
      &--color#{$i} .fo-comment-input {
        background: var(--project-vote-light-#{$i});
      }
    }
  }
}
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all .25s;
}
.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
