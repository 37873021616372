.add-mentor-modal {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
}

.add-mentor-modal__highlight {
  margin-bottom: var(--rem-size-20);
}

.add-mentor-modal__textarea {
  width: 100%;
}

.add-mentor-modal__report {
  max-height: 500px;
  overflow-y: auto;
}

.add-mentor-modal__report__table {
  min-width: 500px;
  table-layout: fixed;
}

.add-mentor-modal__loader label {
  color: rgba(0,0,0,.65);
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}

.add-mentor-modal__loader label i {
  color: var(--main-color);
  font-size: 16px;
}

.add-mentor-modal__progress {
  position: relative;
  width: 100%;
  min-width: 300px;
  height: 22px;
  margin: 20px auto;
  background-color: var(--grey-light);
  border-radius: 0;
  overflow: hidden;
}

.add-mentor-modal__progress__number {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  color: var(--main-color);
  font-size: 13px;
  font-weight: bold;
  line-height: 13px;
}

.add-mentor-modal__progress__bar {
  height: 100%;
  background-color: var(--main-color);
  opacity: .8;
  transition: width .2s;
}

