




















































































































@import '@/styles/mixins/index';

.idt-profile-ticket {
  $ticket: &;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--global-border-radius);
  padding: 0 26px;
  height: 79px;
  width: 100%;
  background: var(--background-color);
  margin-bottom: 11px;

  &.inactive {
    background: var(--grey-lighter);

    #{$ticket}__title {
      color: var(--black-alpha-50);
    }
    #{$ticket}__icon-container {
      color: var(--grey);
    }
    .ticketing-upgrade-button {
      background-color: var(--grey);
    }
  }
  &.selected {
    background: var(--white);
    border: 2px solid var(--ticket-color);

    #{$ticket}__icon-container {
      background-color: var(--ticket-color);
      color: white;
    }
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__icon-container {
    display: flex;
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--ticket-color);
  }
  &__title {
    margin: 0;
    color: var(--ticket-color);
    font-weight: var(--font-weight-bold);
  }
  .ticketing-upgrade-button {
    width: 134px !important;
    height: 30px !important;
    margin-top: 0 !important;
    flex-shrink: 0;
    font-size: 14px !important;
    background-color: var(--ticket-color);
  }
}
