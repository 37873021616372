
































































































































































































@import '@/styles/mixins/index';
.timeslot {
  &__error {
    color: var(--secondary-color);
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-medium);
    line-height: var(--rem-size-24);
  }
  &__day {
    display: flex;
    &__inputs {
      width: 100%;

      &__days {
        width: 100%;
      }
      &__edit {
        margin-left: var(--rem-size-25);
        &:hover {
          cursor: pointer;
        }
      }
      &__delete {
        margin-left: var(--rem-size-15);

        &:hover {
          cursor: pointer;
        }
      }
    }
    &__calendar {
      position: relative;
      top: var(--rem-size-10);
      left: 100px;
      background-color: var(--white);
      border: 1px solid var(--grey-lighter);
      z-index: 10;
    }
  }
  &__time {
    display: flex;
    justify-content: space-between;

    @include respond-to(max-medium) {
      flex-direction: column;
    }

    ::v-deep & .idt-time-picker-input {
      width: 50%;

      @include respond-to(max-medium) {
        width: 100%;
      }

      &:first-child {
        margin-right: 15px;
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: right;

    &__cancel {
      width: 100px;
      height: 45px;
      padding: 10px var(--rem-size-25);
      margin-right: 7px;
      color: var(--black);
      border: solid 1px var(--grey);
      border-radius: 30px;
      background-color: var(--white);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-medium);
      line-height: var(--rem-size-24);

      &:hover {
        cursor: pointer;
      }
    }
    &__save {
      width: fit-content;
      min-width: 100px;
      height: 45px;
      padding: 10px var(--rem-size-25);
      color: var(--white);
      border-radius: 30px;
      background-color: var(--main-color);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-bold);
      line-height: var(--rem-size-24);

      &.disabled {
        background-color: var(--grey);
        &:hover {
          cursor: not-allowed;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
