.bo-lobby-category {
  display: flex;
  flex-flow: column;
  margin-bottom: var(--rem-size-23);
  padding-bottom: var(--rem-size-12);
  background: var(--white);
  border: 1px solid rgba(203,203,209,0);
  transition: all .2s ease-in-out;
}

.bo-lobby-category--ghost {
  opacity: 0;
}

.bo-lobby-category .bo-lobby-category__icon-collapse {
  display: flex;
  padding: var(--rem-size-4);
  border-radius: var(--global-radius);
  transition: transform .25s ease-in-out,background-color .15s ease-in-out;
}

.bo-lobby-category .bo-lobby-category__icon-collapse:hover {
  background-color: var(--grey-light);
}

.bo-lobby-category__textarea-autosize {
  position: relative;
}

.bo-lobby-category__textarea-autosize textarea {
  margin-bottom: 0;
  padding: var(--rem-size-6) var(--rem-size-12) var(--rem-size-24);
  font-size: var(--rem-size-14);
}

.bo-lobby-category__textarea-autosize__counter {
  position: absolute;
  right: var(--rem-size-12);
  bottom: var(--rem-size-6);
  color: var(--grey-dark);
}

.bo-lobby-category__title-right-side {
  margin-left: auto;
}

.bo-lobby-category__title-right-side .idt-search input {
  height: 36px;
}

.bo-lobby-category__title-right-side .idt-search::after {
  color: var(--grey-darker);
  font-size: var(--rem-size-14);
}

.bo-lobby-category--collapsed {
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.bo-lobby-category--collapsed input {
  cursor: auto;
}

.bo-lobby-category--collapsed .bo-vertical__section__title {
  margin-bottom: 0 !important;
}

.bo-lobby-category--collapsed .bo-lobby-category__icon-collapse {
  transform: rotate(180deg);
}

.bo-lobby-category--draggable {
  transition: none;
  transition-delay: 0;
}

.bo-lobby-category--sponsor-cat.bo-lobby-category--collapsed {
  box-shadow: none;
}

.bo-lobby-category .row.expanded {
  width: 100%;
}

.bo-lobby-category h2.bo-vertical__section__title {
  display: flex;
  margin-bottom: var(--rem-size-8);
  color: var(--grey-darkest);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  transition: margin-bottom .2s ease-in-out;
}

.bo-lobby-category h2.bo-vertical__section__title>* {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--rem-size-12);
}

.bo-lobby-category h2.bo-vertical__section__title a {
  color: var(--grey-darkest-alpha75);
}

.bo-lobby-category .bo-table-actions {
  margin-bottom: 0;
}

.bo-lobby-category .bo-table-actions .idt-input-container {
  max-width: 300px;
}

.bo-lobby-category .bo-table-actions .idt-input-container:not(.no-margin) {
  padding-bottom: var(--rem-size-8);
}

.bo-lobby-category .bo-table-actions .idt-input-container .idt-input-counter {
  bottom: .7rem;
}

.bo-lobby-category .bo-table-actions .idt-required-field {
  bottom: calc(-1*var(--rem-size-25));
  left: 0;
  padding: 0 var(--rem-size-16);
  background: var(--white-alpha80);
}

.bo-lobby-category .tooltip-info {
  position: relative;
}

.bo-lobby-category .tooltip-info__content {
  display: none;
  position: absolute;
  right: calc(-1*var(--rem-size-16));
  top: 50%;
  padding: calc(var(--rem-size-8)/2) var(--rem-size-8);
  background-color: var(--grey-dark);
  border-radius: var(--global-radius);
  color: var(--white);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-normal);
  white-space: nowrap;
  transform: translateX(100%) translateY(-50%);
  z-index: 1;
}

.bo-lobby-category .tooltip-info__content::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  border: 4px solid rgba(0,0,0,0);
  border-right-color: var(--grey-dark);
  transform: translateX(-100%) translateY(-50%);
  vertical-align: middle;
}

.bo-lobby-category .tooltip-info a {
  display: inline-flex;
}

.bo-lobby-category .tooltip-info a:hover+.tooltip-info__content,
.bo-lobby-category .tooltip-info a:focus+.tooltip-info__content {
  display: block;
}

.bo-lobby-category .width-animation-enter-active,
.bo-lobby-category .width-animation-leave-active {
  max-width: 35px;
  transition: max-width .2s ease-in-out,margin-right .2s ease-in-out;
  overflow: hidden;
}

.bo-lobby-category .width-animation-enter,
.bo-lobby-category .width-animation-leave-to {
  max-width: 0;
  margin-right: 0;
}

.bo-lobby-category .opacity-animation-enter-active,
.bo-lobby-category .opacity-animation-leave-active {
  transition: opacity .2s ease-in-out;
}

.bo-lobby-category .opacity-animation-enter,
.bo-lobby-category .opacity-animation-leave-to {
  opacity: 0;
}

.bo-lobby-category .height-animation-enter-active,
.bo-lobby-category .height-animation-leave-active {
  max-height: 1200px;
  overflow: hidden;
  transition: max-height .4s ease-out;
}

.bo-lobby-category .height-animation-enter,
.bo-lobby-category .height-animation-leave-to {
  max-height: 0;
}

.bo-lobby-category__yes {
  color: var(--green-dark);
}

.bo-lobby-category__no {
  color: var(--danger-color);
}

.bo-lobby-category .text-ellipsis {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

