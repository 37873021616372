.conference-questions {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  background-color: var(--white);
}

.conference-questions .question-input ::v-deep .idt-textarea {
  display: block;
  min-height: 4rem;
  padding: .4rem;
  resize: none;
  overflow-y: auto;
  line-height: 16px;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.conference-questions .question-input ::v-deep .idt-textarea:focus {
  border-color: var(--main-color);
}

.conference-questions__ask-button {
  min-height: 28px;
  margin-top: .5rem;
  padding: .4rem;
}

.conference-questions__sort {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-height: 30px;
}

.conference-questions__sort span {
  margin-right: .5rem;
}

.conference-questions__sort-selector {
  position: relative;
  width: 10rem;
}

.conference-questions__sort-selector select {
  margin: 0;
  padding: 4px 6px;
  background: var(--grey-light);
  border-radius: 4px;
  color: var(--black);
  border: none;
  outline: 0;
  cursor: pointer;
  appearance: none;
}

.conference-questions__sort-selector .select_arrow {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 6px;
  height: 6px;
  border: solid var(--black);
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(45deg);
}

.conference-questions__label,
.conference-questions__sort-selector select,
.conference-questions__ask-button {
  font-size: 14px;
}

.conference-questions__separator {
  display: flex;
  justify-content: center;
  height: 1px;
  margin-bottom: 10px;
  border-top: 1px solid var(--main-color);
}

.conference-questions__new-tag {
  position: sticky;
  display: flex;
  bottom: 0;
  left: 1rem;
}

.conference-questions__new-tag--tag {
  height: 1rem;
  transform: translate(0, -0.5rem);
  padding: 0 1rem 0 .4rem;
  background-color: var(--main-color);
  color: var(--white);
  border-radius: 3rem;
  font-size: 10px;
  font-weight: var(--font-weight-bold);
}

::v-deep .talk-question-card {
  margin-bottom: 10px;
}

::v-deep .talk-question-card,
.conference-questions__new-tag {
  transition: transform .7s;
}

.slide-leave-active,
.slide-enter-active,
.slide-top-leave-active,
.slide-top-enter-active {
  transition: .5s;
}

.slide-leave-active.talk-question-card,
.slide-enter-active.talk-question-card,
.slide-top-leave-active.talk-question-card,
.slide-top-enter-active.talk-question-card {
  width: 100%;
}

.slide-leave-active {
  position: absolute;
}

.slide-enter {
  transform: translate(-20%);
  opacity: 0;
}

.slide-leave-to {
  transform: translate(20%);
  opacity: 0;
}

.slide-top-enter {
  transform: translate(0, -20%);
  opacity: 0;
}

.slide-top-leave-to {
  transform: translate(0, 20%);
  opacity: 0;
}

