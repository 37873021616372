




















@import '@/styles/mixins/index';

.fo-lobby-message-skeleton {
  position: relative;
  padding: var(--rem-size-15) 1.5rem;
  padding-left: 4rem;

  &__line {
    display: flex;
    padding-bottom: var(--rem-size-8);
    > * {
      height: var(--rem-size-24);
      background: var(--grey-alpha30);
      margin-right: var(--rem-size-8);
      border-radius: var(--global-radius);
    }
  }

  &__avatar {
    width: var(--rem-size-24);
    height: var(--rem-size-24);
    border-radius: 100%;
    background: var(--grey-alpha30);
    position: absolute;
    top: var(--rem-size-10);
    left: var(--rem-size-24);
  }

  &__cell-info {
    width: 4rem;
  }
  &__cell-pseudo {
    width: 7rem;
  }
  &__cell-long {
    height: var(--rem-size-16);
    width: 60%;

    @include respond-to(max-slarge) {
      width: 70%;
    }
  }
  &__cell-short {
    height: var(--rem-size-16);
    width: 16%;

    @include respond-to(max-slarge) {
      width: 20%;
    }
  }

  &__shine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // BM-715: we should use rgba(255, 255, 255, 0) instead of "transparent" because Safari is somehow not able to understand it
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, var(--white-alpha60) 60px, rgba(255, 255, 255, 0) 120px);
    background-repeat: no-repeat;
    animation: shine 4s infinite;

    @include respond-to(max-slarge) {
      animation: shine-mobile 3s infinite;
    }
  }

  @keyframes shine {
    0% {
      background-position: -100px;
    }
    100% {
      background-position: calc(65vw + 100px);
    }
  }
  @keyframes shine-mobile {
    0% {
      background-position: -100px;
    }
    100% {
      background-position: calc(100vw + 100px);
    }
  }
}
