.payment-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.payment-form__tooltip .selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.payment-form__tooltip__content {
  display: none;
  position: absolute;
  bottom: 27px;
  right: 0px;
  width: 215px;
  padding: .75rem;
  color: var(--grey-darker);
  background-color: var(--white);
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) var(--white) rgba(0,0,0,0);
  box-shadow: 0 2px 8px 0 var(--black-alpha10);
  font-size: .75rem;
  font-style: italic;
  transition: top 0s .5s,opacity .5s;
  z-index: 9999;
}

.payment-form__tooltip__content:before {
  position: absolute;
  top: -10px;
  right: 42px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 10px 7.5px;
}

.payment-form__tooltip__content.show {
  display: block;
}

.payment-form__container {
  padding: 0 20px;
  border-right: 3px solid var(--grey-lighter);
  border-left: 3px solid var(--grey-lighter);
}

.payment-form__container input {
  margin-bottom: 0;
}

.payment-form__container label {
  color: var(--grey-darker);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  text-align: left;
}

.payment-form__logos-container {
  margin-bottom: 20px;
}

.payment-form__logos {
  display: inline-block;
  width: 22.5%;
  color: var(--grey-lighter);
}

.payment-form__secure-label {
  font-weight: var(--font-weight-medium);
  font-size: .81rem;
}

.payment-form__field {
  width: 100%;
  height: 36px;
  padding: 8px 8px;
  border: solid 1px var(--grey-dark);
  border-radius: 3px;
}

.payment-form__field.invalid {
  border: solid 1px var(--secondary-color);
}

.payment-form__field::placeholder {
  color: var(--grey-darker);
  font-style: italic;
  line-height: 20px;
}

.payment-form__field--error {
  height: 20px;
  color: var(--secondary-color);
  font-size: .75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.payment-form__card-name {
  margin-bottom: 20px;
  font-weight: var(--font-weight-medium);
}

.payment-form__card-expiry-cvc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-form__card-expiry {
  flex: 0 0 auto;
  width: 65%;
  margin-right: 10px;
}

.payment-form__card-cvc {
  width: 35%;
}

.payment-form__card-cvc__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-form__button--pay {
  flex: 0 0 auto;
  width: 100%;
  color: var(--white);
  background-color: var(--main-color);
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .payment-form__button--pay {
    height: 60px;
    border-radius: 0;
  }
}

@media only screen and (min-width: 640px) {
  .payment-form__button--pay {
    height: 50px;
    border-radius: 0px 0px var(--global-radius) var(--global-radius);
  }
}

