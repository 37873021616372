.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 210px);

  &__content {
    width: 100%;

    @include respond-to(max-medium) {
      padding: 1rem;
    }
    @include respond-to(medium-slarge) {
      padding: 2rem;
    }
    @include respond-to(slarge) {
      max-width: 650px;
    }
  }
  &__title {
    margin: 0.5rem 0;
    color: var(--black);
    font-weight: var(--font-weight-bold);

    @include respond-to(max-slarge) {
      font-size: 2rem;
    }
    @include respond-to(slarge) {
      font-size: 2.9rem;
    }
  }

  &__text {
    color: var(--main-color-darkest);
    font-weight: var(--font-weight-medium);

    @include respond-to(max-slarge) {
      font-size: 1rem;
    }
    @include respond-to(slarge) {
      font-size: 1.25rem;
    }
  }

  .no-entry-sign {
    position: relative;
    background-image: radial-gradient(circle at 50% 50%, #ff4242, #d91919);
    border: 0.5vw solid var(--white);
    border-radius: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    @include respond-to(max-medium) {
      width: 20vw;
      height: 20vw;
      border: 1vw solid var(--white);
    }
    @include respond-to(medium) {
      width: 10vw;
      height: 10vw;
      border: 0.5vw solid var(--white);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--white);
      transform: translate(-50%, -50%);

      @include respond-to(max-medium) {
        width: 13vw;
        height: 3.5vw;
      }
      @include respond-to(medium) {
        width: 6.5vw;
        height: 1.75vw;
      }
    }
  }
}
