



























































































































































.idt-account-secondary-informations {
  margin-top: 14px;

  &__phone,
  &__timezone {
    margin: 0 0 1rem;
  }

  &__description {
    flex: 1 0 auto;
    width: 100%;

    textarea {
      height: 160px;
      resize: none;
    }
  }
}
