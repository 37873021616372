












































































































































































































































































































































































































































@import '@/styles/mixins';

.live-chat-message-content {
  display: flex;
  padding: var(--rem-size-10) 0;
  gap: var(--rem-size-12);

  &__user {
    align-self: flex-start;
    cursor: pointer;
  }
  &__wrapper {
    align-self: center;
    color: var(--grey-darkest);
    font-size: var(--rem-size-14);
    z-index: 0;
  }
  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--rem-size-8);
  }
  &__text {
    line-height: var(--rem-size-22);

    &__message {
      white-space: pre-line;
      word-break: break-word;
    }
    &__show-more {
      color: var(--main-color);
      cursor: pointer;
    }
    &__reactions--disabled {
      cursor: auto;
      pointer-events: none;
    }
  }
  &__reactions {
    display: flex;
    align-items: center;
    gap: var(--rem-size-4);
    min-height: var(--rem-size-24);

    @include respond-to(max-small) {
      flex-wrap: wrap;
    }

    &__button {
      flex: 0 0 auto;
      height: 20px;
      padding: 0 6px;
      background: var(--grey-lighter);
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: var(--grey-light);
      }
      &.active {
        background: var(--main-color);

        & .live-chat-message-content__reactions__button__count {
          color: var(--white);
        }
      }
      &__count {
        color: var(--grey-darker);
        font-size: var(--rem-size-14);
        font-weight: bold;
        vertical-align: top;
      }
      .emoji-mart-emoji {
        padding: 0;
      }
    }
  }
  &:not(.only-emoji) {
    .emoji {
      font-size: var(--rem-size-16);
    }
  }
  &__add-extra-reaction {
    ::v-deep .idea-ht-emoji {
      --color2: var(--grey-darker);
      --color3: var(--white);
    }
  }
  &__blocked-by,
  &__hour,
  &__modified {
    display: inline-flex;
    align-items: center;
    gap: var(--rem-size-8);
    color: var(--grey-darker);
  }
  &.only-emoji {
    .live-chat-message-content__text__message,
    .live-chat-message__container__user,
    .live-chat-message-username__text,
    .live-chat-message-username__container__role-icon {
      line-height: var(--rem-size-32);
    }

    .live-chat-message-username__container {
      line-height: var(--rem-size-24);
    }

    .live-chat-message-content__text__message {
      font-size: var(--rem-size-30);
    }
    .live-chat-message-content__user {
      margin-top: 0.25rem;
    }
  }
  &.response {
    margin-top: var(--rem-size-10);
    margin-left: var(--rem-size-24);
    padding: var(--rem-size-4) var(--rem-size-12);
    background: var(--grey-lighter);
    border-radius: var(--global-radius);

    .live-chat-message-content__wrapper {
      margin-left: 0;
    }
  }

  // don't display reactions of the message it's answering to
  &.response.live-chat-message-content--in-lobby {
    .live-chat-message-content__reactions {
      display: none !important;
    }
  }
  &__editor {
    width: 100%;
  }
  &__gif-preview {
    max-width: 200px;
    padding: var(--rem-size-10) 0;

    img {
      border-radius: var(--global-radius);
    }
  }
  &__image-preview {
    padding-top: var(--rem-size-10);
  }
  &__blocked-line {
    margin-top: var(--rem-size-5);
    padding: var(--rem-size-4) var(--rem-size-12);
    border-radius: var(--global-radius);
    background: var(--grey-lighter);
    color: var(--grey-darkest);
  }
  &--blocked {
    opacity: .5;
  }

  // MODIFICATIONS FOR LOBBY'S ROOMS
  &--in-lobby {
    .live-chat-message-content {
      padding-top: var(--rem-size-4);
      padding-bottom: var(--rem-size-4);

      &__text__message {
        display: block;
        margin-top: var(--rem-size-5);
        margin-left: 0;
        line-height: var(--rem-size-22);
      }
    }
    // Better UI for reactions
    .live-chat-message-content__reactions {
      margin-top: var(--rem-size-8);
    }
    .live-chat-message-content__reactions__button {
      padding: var(--rem-size-4) var(--rem-size-10);
      border: 1px solid var(--grey);
      height: auto;
      transition: background-color .2s ease-in-out, color .2s ease-in-out;

      &.active {
        border-color: var(--main-color-dark);
        background-color: var(--main-color-dark-alpha20);

        // count
        .live-chat-message-content__reactions__button__count {
          color: var(--main-color-dark);
        }
      }
    }
  }
}
