


































































































.additional-checkbox-editor {
  &__delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    font-size: var(--rem-size-14);
    cursor: pointer;

    &:hover {
      color: var(--secondary-color-dark);
    }
    .idt-icon {
      display: inline-block;
      margin-top: 7px;
      margin-left: 10px;
    }
  }

  .froala-container.required-field {
    ::v-deep .fr-box {
      border: 1px solid var(--secondary-color);
    }
  }
}
