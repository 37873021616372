.external-api-keys__list {
  margin-bottom: 1rem;
  box-shadow: 0 2px 3px var(--grey-darker-alpha15);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.external-api-keys__head,
.external-api-keys__item {
  display: grid;
  gap: .5rem;
}

@media only screen and (max-width: 991px) {
  .external-api-keys__head,
  .external-api-keys__item {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .external-api-keys__head,
  .external-api-keys__item {
    grid-template-columns: 30% 20% 20% 10% calc(20% - 2rem);
  }
}

@media only screen and (max-width: 991px) {
  .external-api-keys__head {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .external-api-keys__head {
    padding: 1rem;
  }
}

.external-api-keys__item {
  font-size: .875rem;
}

@media only screen and (max-width: 991px) {
  .external-api-keys__item {
    padding: .5rem 1rem;
  }
}

@media only screen and (min-width: 992px) {
  .external-api-keys__item {
    padding: 0 1rem;
    line-height: 3rem;
  }
}

.external-api-keys__item:not(:last-child) {
  border-bottom: 1px solid var(--grey-light);
}

@media only screen and (max-width: 991px) {
  .external-api-keys__name {
    grid-area: 1/1/2/2;
  }
}

@media only screen and (max-width: 991px) {
  .external-api-keys__date {
    grid-area: 1/2/2/3;
  }
}

@media only screen and (max-width: 991px) {
  .external-api-keys__key {
    grid-area: 2/1/3/3;
  }
}

@media only screen and (max-width: 991px) {
  .external-api-keys__usage {
    grid-area: 3/1/4/2;
  }
}

@media only screen and (min-width: 992px) {
  .external-api-keys__usage {
    text-align: right;
  }
}

.external-api-keys__actions,
.external-api-keys__add {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .external-api-keys__actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    grid-area: 3/2/4/3;
  }
}

.external-api-keys__actions button {
  padding: .75rem;
  color: inherit;
  cursor: pointer;
}

.external-api-keys__delete:hover {
  color: var(--danger-color);
}

.external-api-keys__input ::v-deep .idt-input {
  height: 46px;
  margin-top: 2px;
  padding-left: 0;
  border-radius: 0;
  border-width: 0 0 2px 0;
  font-size: .875rem;
}

