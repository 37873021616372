






















































@import '@/styles/mixins/index';
.idt-confirm-email {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--main-color-darkest);

  @include respond-to(max-slarge) {
    margin: auto 10vw auto 15vw;
  }
  @include respond-to(slarge) {
    margin: auto 20vw auto 30vw;
  }

  &__content {
    position: relative;
    flex: 1 1 auto;
    margin-top: 5vw;
  }
  &__svg {
    position: absolute;
    top: -9vw;
    left: -17vw;
    width: 28vw;
  }
  &__title {
    font-size: 2.2rem;
    font-weight: var(--font-weight-bold);
  }
  &__text {
    font-size: 1.1rem;
  }
  &__email,
  &__link {
    color: var(--main-color);
  }
  &__link {
    text-decoration: underline;
    cursor: pointer;
  }
}
