.project-publish-modal__title {
  color: var(--grey-darkest);
  font-size: var(--rem-size-32);
  font-weight: var(--font-weight-medium);
}

.project-publish-modal__content {
  color: var(--grey-darker);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-normal);
}

.project-publish-modal .unpublished {
  background-color: red;
}

.project-publish-modal .button {
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
}

