


























































































































































































































































































































































































































































































.news-list {
  padding-bottom: var(--rem-size-32);

  &__header {
    align-items: flex-start;
    min-height: 120px;
    padding: var(--rem-size-16) 0;
    background-color: var(--grey-lightest);
  }
  &__tags {
    &__label {
      margin-bottom: var(--rem-size-8);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-light);
    }
    &__selector {
      margin-left: var(--rem-size-8) !important;
    }
  }
  &__tags-list {
    padding: 0 var(--rem-size-8) 0 var(--rem-size-4);
    text-align: left;
  }
  &__tag {
    cursor: pointer;

    &:hover {
      color: var(--main-color);
    }
  }
  &__selected-tags {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: var(--rem-size-8);

    .idt-tag {
      cursor: pointer;
    }
  }
}
