


















































@import '@/styles/functions';

.fo-project-section-team-view {
  margin: 0 remCalc(32) remCalc(32) remCalc(32);

  &__title {
    margin-bottom: remCalc(40);
    text-align: center;
    color: var(--grey-darkest);
  }

  &__participants {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: remCalc(16);
  }

  &__no-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: remCalc(36);
    border: 1px dashed var(--grey);
    border-radius: var(--global-radius);

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      margin-bottom: remCalc(16);
      color: var(--grey-darkest);
      background-color: var(--grey);
      border-radius: 50%;
    }

    &__text {
      font-weight: var(--font-weight-medium);
      color: var(--grey);
    }
  }
}
