.idt-file-download {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 300px;
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  color: var(--black);
  transition: border-color var(--default-duration) ease;
}

.idt-file-download:hover {
  border-color: var(--grey-dark);
}

.idt-file-download__all {
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
}

.idt-file-download__all__action {
  flex-grow: 1;
  margin: var(--rem-size-8);
  background-color: var(--grey-light);
  border-radius: 50px;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  line-height: var(--rem-size-32);
  transition: background-color 200ms ease;
  cursor: pointer;
}

.idt-file-download__all__action:hover {
  background-color: var(--grey);
}

.idt-file-download__all__action__icon {
  margin-right: .25rem;
}

.idt-file-download ::v-deep .idt-dropdown {
  display: block;
  width: 100%;
  overflow: visible;
}

.idt-file-download ::v-deep .idt-dropdown .idt-dropdown--pane {
  top: 105%;
  width: 100%;
  margin-top: var(--rem-size-10);
  padding: 0;
  border-radius: var(--global-radius);
  font-size: inherit;
}

.idt-file-download ::v-deep .idt-dropdown .idt-dropdown--pane .download-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 0;
  list-style-type: none;
}

.idt-file-download ::v-deep .idt-dropdown .idt-dropdown--pane .download-menu li {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 1px 0;
}

.idt-file-download ::v-deep .idt-dropdown .idt-dropdown--pane .download-menu li+li {
  border-top: 1px solid var(--grey-light);
}

