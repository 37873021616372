.idt-user-list {
  height: 100%;
  padding: 0 .625rem;
}

.idt-user-list__toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: var(--chat-min-with);
  padding: 1rem 0;
}

.idt-user-list__toolbar__searchbar {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

.idt-user-list__toolbar__searchbar input {
  height: 40px;
  margin: 0;
  padding: .6566rem 1.5rem .6566rem 1rem;
  color: var(--white);
  background-color: var(--white-alpha10);
  border: 0;
  border-radius: 4px;
  font-size: .9rem;
  transition: background-color .3s ease-in-out;
}

.idt-user-list__toolbar__searchbar input:focus {
  background-color: var(--dark-color-dark);
}

.idt-user-list__toolbar__searchbar ::v-deep .searchbar-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  color: var(--white);
  font-size: 1.1rem;
  transform: translateY(-50%);
}

.idt-user-list__toolbar__filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  font-size: 16px;
  line-height: 26px;
}

.idt-user-list__toolbar__filter .focus {
  background-color: var(--dark-color-dark) !important;
}

.idt-user-list__toolbar__filter__title {
  font-family: var(--title-font-family);
}

.idt-user-list__list {
  height: calc(100% - 161px);
}

::v-deep .idt-dropdown {
  width: 100%;
}

::v-deep .idt-dropdown--pane {
  width: 100%;
  padding: 0;
}

.input-dropdown {
  position: relative;
  width: 100%;
}

.input-dropdown__input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  font-size: 14px;
}

.input-dropdown__input__container {
  position: relative;
  cursor: pointer;
}

.input-dropdown__input.div {
  min-height: 53px;
  color: var(--grey-light);
  background-image: none;
  background-color: var(--white-alpha10);
  border: none;
  font-family: var(--text-font-family);
  transition: background-color .3s ease-in-out;
  pointer-events: auto;
}

.input-dropdown__input.div ::v-deep .idea-arrow-nav-down {
  color: var(--white);
}

.input-dropdown__input.div .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.input-dropdown__input.div .tag-list .tag-item {
  display: inline-block;
  margin: 2px;
  padding: 0 5px;
  height: 29px;
  color: var(--white);
  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
}

.input-dropdown__input.div .tag-list .tag-item .remove-button {
  margin: 0 0 0 5px;
  padding: 0;
  background: 0 0;
  border: none;
  vertical-align: middle;
  cursor: pointer;
}

.input-dropdown__button {
  margin: 0;
  color: var(--main-color);
  background-color: var(--white);
  border-color: var(--main-color);
  text-transform: uppercase;
}

.input-dropdown__button.dropdown:after {
  border-color: var(--main-color) rgba(0,0,0,0) rgba(0,0,0,0);
}

.input-dropdown__button:hover {
  color: var(--main-color);
  background-color: var(--white);
}

.input-dropdown .idt-dropdown--pane {
  width: 236px;
  max-height: 50vh;
  margin: -2.75rem 0 0 -0.25rem;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 5px var(--black-alpha20);
  overflow-y: auto;
}

.input-dropdown .idt-dropdown--pane__header {
  position: relative;
  min-height: 40px;
  color: var(--main-color);
  background-color: var(--main-color-alpha10);
  font-size: .8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.input-dropdown .idt-dropdown--pane__header:not(.counter):not(.checkbox) {
  padding: .7rem 1rem;
}

.input-dropdown .idt-dropdown--pane__header.counter {
  padding: .7rem 2.5rem .7rem 1rem;
}

.input-dropdown .idt-dropdown--pane__header .count {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1rem;
  color: var(--main-color);
  transform: translateY(-50%);
}

.input-dropdown .idt-dropdown--pane__header.checkbox {
  padding: .7rem 3rem .7rem 1rem;
  cursor: pointer;
}

.input-dropdown .idt-dropdown--pane__header.checkbox:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 16px;
  height: 16px;
  border: 2px solid var(--white);
  border-radius: 100%;
  box-shadow: 0 0 0 2px var(--main-color);
  transform: translateY(-50%);
}

.input-dropdown .idt-dropdown--pane__header.checkbox.checked:before {
  background-color: var(--main-color);
}

.input-dropdown .idt-dropdown--pane button {
  width: 100%;
  height: 40px;
  padding: .7rem 1rem;
  border-radius: 0;
  text-align: left;
  cursor: pointer;
}

.input-dropdown .idt-dropdown--pane button.add {
  position: relative;
  color: var(--main-color);
  background-color: var(--main-color-alpha10);
  font-size: .8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.input-dropdown .idt-dropdown--pane button.add i {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.input-dropdown .idt-dropdown--pane__checkbox {
  position: relative;
  width: 100%;
  margin: 0;
  padding: .7rem 3rem .7rem 1rem;
  cursor: pointer;
}

.input-dropdown .idt-dropdown--pane__checkbox:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 16px;
  height: 16px;
  border: 2px solid var(--white);
  border-radius: 100%;
  box-shadow: 0 0 0 2px var(--main-color);
  transform: translateY(-50%);
}

input[type=radio]:checked+.input-dropdown .idt-dropdown--pane__checkbox:before,
input[type=checkbox]:checked+.input-dropdown .idt-dropdown--pane__checkbox:before {
  background-color: var(--main-color);
}

.input-dropdown .idt-dropdown--pane input[type=radio],
.input-dropdown .idt-dropdown--pane input[type=checkbox] {
  display: none;
}

.input-dropdown__title {
  background-color: var(--main-color-alpha20);
  color: var(--main-color);
}

.input-dropdown .menu {
  max-width: 100%;
  font-size: .8rem;
  overflow-x: hidden;
}

.input-dropdown .menu:not(.multiple) {
  max-height: 200px;
  overflow-y: auto;
}

.input-dropdown .menu li:not(:last-child) a,
.input-dropdown .menu li:not(:last-child) button {
  border-bottom: 1px solid var(--grey-lighter);
}

.input-dropdown .menu li a,
.input-dropdown .menu li button {
  color: var(--grey-darkest);
}

.input-dropdown .menu li a:hover,
.input-dropdown .menu li button:hover {
  color: var(--main-color);
}

input[type=checkbox].checked+.dropdown-pane__checkbox:before {
  background-color: var(--main-color);
}

.button-dropdown {
  display: inline-block;
}

