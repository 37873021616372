































































.event-mentor {
  text-align: center;
  margin-bottom: 2.5rem;
  cursor: pointer;

  &__avatar {
    width: 125px;
    height: 125px;
    margin: 0 auto 8px;
    border: 10px solid var(--grey-lighter);
    border-radius: 100%;

    .idt-user-avatar {
      background-color: var(--grey-lighter);
      span {
        color: var(--grey);
      }
    }
    > img {
      border-radius: 100%;
    }
  }
  &__name {
    margin-bottom: 10px;
    color: var(--grey-darkest);
    line-height: 1.3125rem;
    font-size: 1.125rem;
    font-weight: 500;
  }
  &__job {
    color: var(--grey-darker);
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 500;
  }
  &__company {
    color: var(--grey-darker);
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 300;
  }
  &__see-more {
    margin: 0.625rem 0 4.375rem 0;

    .btn {
      display: inline-block;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      border: 1px solid var(--main-color);
      padding: 10px 18px;
      border-radius: 3px;
    }
  }
  &__jury-title {
    margin-top: 4rem;
  }
  &__jurys {
    pointer-events: none;
  }
}
