.fo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--menu-height);
  padding: var(--rem-size-12);
  background-color: var(--white);
  border-bottom: 2px solid var(--grey);
}

.fo-header__left {
  display: flex;
}

.fo-header__logo {
  padding-right: var(--rem-size-15);
}

.fo-header__logo__image {
  max-width: 140px;
  max-height: calc(var(--menu-height) - var(--rem-size-24));
}

.fo-header__right {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .fo-header {
    padding: 0 var(--rem-size-8);
  }
}

@media only screen and (max-width: 991px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .show-on-mobile {
    display: none !important;
  }
}

