@import '@/styles/mixins/index';

.tpl-sponsors {
  &__title {
    color: var(--black);
    font-size: var(--rem-size-35);
    font-family: var(--title-font-family);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    text-align: left;
  }
  &__subtitle {
    font-size: var(--rem-size-24);
    font-family: var(--title-font-family);
  }
  &__description {
    font-size: var(--rem-size-16);
    font-family: var(--text-font-family);
  }
  &__image {
    width: 100%;
  }
  &-ressources {
    display: flex;
    align-items: center;
    margin-bottom: var(--rem-size-16);
    background-color: var(--white);

    @include respond-to(max-medium) {
      flex-flow: column wrap;
    }

    &__img {
      display: flex;
      flex: 0 1 auto;
      align-items: center;
      justify-content: center;

      @include respond-to(max-medium) {
        width: 100%;
        padding: 0 var(--rem-size-24);
      }

      img {
        width: 200px;
        border-radius: var(--global-radius) 0 0 var(--global-radius);

        @include respond-to(max-medium) {
          width: 100%;
          border-radius: var(--global-radius) var(--global-radius) 0 0;
        }
      }
    }
    &__info {
      flex: 1 1 0;
      padding: 0 var(--rem-size-24);
    }
    &__title {
      color: var(--black);
      font-size: var(--rem-size-24);
      font-family: var(--title-font-family);
      font-weight: var(--font-weight-bold);
    }
    &__desc p {
      margin-bottom: 0;
      color: var(--black);
      font-size: var(--rem-size-16);
      font-family: var(--text-font-family);
      font-weight: var(--font-weight-normal);
      line-height: var(--rem-size-20);
    }
    &__link {
      flex: 0 1 auto;
      color: var(--main-color);
      font-size: var(--rem-size-16);
      font-family: var(--text-font-family);
      font-weight: var(--font-weight-bold);
      text-decoration: underline;
    }
  }
}
