.conference-canceled {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .conference-canceled {
    height: var(--video-height);
  }
}

