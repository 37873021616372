.fo-project-section-how-it-works-edit {
  text-align: center;
}

.fo-project-section-how-it-works-edit__title {
  margin-bottom: var(--rem-size-16);
  color: #000;
}

.fo-project-section-how-it-works-edit__cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--rem-size-20);
}

.fo-project-section-how-it-works-edit__cards__card {
  margin: var(--rem-size-5);
}

