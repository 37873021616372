.base-field {
  width: 100%;
  padding: var(--rem-size-20);
}

::v-deep .base-field__label>p {
  margin-bottom: var(--rem-size-9);
  font-size: 1rem;
  font-weight: bold;
}

::v-deep .base-field__label.required::after {
  display: none;
}

::v-deep .base-field__label.required>p:first-child::after {
  content: " *";
}

.base-field .idt-profile-upload-file {
  position: relative;
  max-width: 500px;
}

.base-field .idt-profile-upload-file__hint {
  margin-top: .5rem;
  font-size: .75rem;
  font-style: italic;
}

.base-field .idt-profile-upload-file__remove {
  position: absolute;
  top: 1px;
  right: 1px;
  height: 38px;
}

.base-field .idt-profile-upload-file ::v-deep .idt-fs-upload-file__right-content {
  padding-right: var(--rem-size-48);
}

