
































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.bo-challenge-button {
  display: inline-flex;
  align-items: center;
  color: var(--white) !important;
  background-color: var(--main-color) !important;
  border: 0 !important;
  font-weight: var(--font-weight-bold);
}
.bo-project-challenges {
  .vue-slider {
    height: var(--rem-size-8);
    margin: var(--rem-size-8) var(--rem-size-9) var(--rem-size-40);

    .vue-slider-dot {
      width: var(--rem-size-24);
      height: var(--rem-size-24);
      border-width: 2px;
    }
    .vue-slider-dot-tooltip-bottom {
      bottom: calc(-1 * var(--rem-size-8));
    }
    .vue-slider-dot-tooltip-text {
      color: var(--main-color);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-medium);
    }
  }
  &__slider {
    margin-left: var(--rem-size-32);
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-medium);
  }
  &__delete-line {
    display: flex;
    justify-content: flex-end;
  }
  &__add-challenge {
    display: flex;
    align-items: center;
    // mimic perfect input height :D
    min-height: 43.58px;
    margin: var(--rem-size-8) 0 var(--rem-size-16) var(--rem-size-32);
    padding: var(--rem-size-4) var(--rem-size-16);
    color: var(--grey-darker);
    border: 2px dashed var(--grey);
    border-radius: var(--global-radius);
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-medium);
    transition: color .15s ease-in-out, border-color .15s ease-in-out;

    &:hover {
      border-color: var(--main-color);
    }
  }

  &__input {
    flex: auto;
    position: relative;
    color: var(--grey-darkest);

    &--invalid input {
      border-color: var(--red);
    }
    &__wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .bo-project-challenges__challenge__handle,
      .bo-project-challenges__challenge__delete {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        & {
          color: var(--grey-darker);
        }
        &:hover {
          color: var(--danger-color);
          cursor: pointer;
        }
      }
      .bo-project-challenges__challenge__handle {
        left: var(--rem-size-16);
      }
      .bo-project-challenges__challenge__delete {
        right: var(--rem-size-16);
      }
    }
    input {
      width: 100%;
      margin-bottom: 0;
      padding: var(--rem-size-8) var(--rem-size-16);
      font-weight: var(--font-weight-medium);
      font-size: var(--rem-size-14);
    }
  }
  &__challenge {
    position: relative;
    margin-left: var(--rem-size-32);
    margin-top: var(--rem-size-8);

    .bo-project-challenges__input {
      input {
        padding: var(--rem-size-8) var(--rem-size-42);
      }
    }
    &--ghost {
      opacity: 0;
    }
  }
  &__themes {
    width: 100%;
    padding-left: var(--rem-size-15);
  }
  &__theme {
    max-width: 620px;
    margin-bottom: var(--rem-size-12);
    padding: var(--rem-size-8) var(--rem-size-24) var(--rem-size-16) var(--rem-size-16);
    background: var(--white);
    border: 1px solid var(--grey-light);
    border-radius: var(--global-radius);

    &--ghost {
      opacity: 0;
    }
    &__handle {
      margin-right: var(--rem-size-16);

      &,
      &:hover {
        color: var(--grey-darker);
      }
    }
  }
  .idea-menu {
    cursor: move;
  }
  .list-transition-items > .list-transition-item {
    // opacity, max-height, transform, padding
    transition: all .7s;
  }
  .list-transition-leave-active,
  .list-transition-enter-active {
    overflow: hidden !important;
  }
  .list-transition-enter {
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
  .list-transition-enter-to,
  .list-transition-leave {
    max-height: 250px;
    opacity: 1;
  }
  // better animation for sub-lines (less height so should be faster)
  .list-transition-items--sub-list {
    > .list-transition-item {
      transition: all .25s;
    }
    .list-transition-enter-to,
    .list-transition-leave {
      max-height: 60px;
    }
  }
  .list-transition-leave-to {
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
  &-dialog-modal p {
    white-space: pre;
  }
}
