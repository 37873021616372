




























































































.idt-users-popover {
  &__see-more {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--grey-light);
    color: var(--main-color);
    transition: all var(--default-duration) ease;

    &:hover {
      background-color: var(--grey-lightest);
      color: var(--main-color-dark);
    }
  }
}
