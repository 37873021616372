.idt-survey-card {
  position: relative;
  width: 100%;
  max-width: 420px;
  padding: 1rem;
  border: 1px solid #cacaca;
  box-shadow: 0px 3px 6px rgba(0,0,0,.1607843137);
  background: var(--white);
  border-radius: var(--global-radius);
}

.idt-survey-card.ended {
  box-shadow: none;
}

.idt-survey-card.highlighted {
  border-left: 3px solid var(--main-color);
}

.idt-survey-card.collapsed .idt-survey-card__question {
  height: 0;
}

.idt-survey-card.collapsed .idt-survey-card__buttons__collapse {
  transform: rotate3d(0, 0, 1, 180deg);
}

.idt-survey-card__duration .progress {
  --progress-color: var(--main-color);
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 7px;
  margin: 0;
  border-radius: var(--global-radius) var(--global-radius) 0 0;
  overflow: hidden;
}

.idt-survey-card__duration .progress.danger {
  --progress-color: var(--danger-color);
}

.idt-survey-card__header {
  position: relative;
  padding-left: 33px;
}

.idt-survey-card__header__duration {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  font-size: var(--rem-size-12);
  color: var(--black);
}

.idt-survey-card__header__icon ::v-deep .ideacon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  color: var(--main-color);
}

.idt-survey-card__header__status {
  font-size: var(--rem-size-12);
}

.idt-survey-card__header__question {
  line-height: 1rem;
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-card__question {
  height: auto;
  padding-top: .5rem;
  overflow: hidden;
  transition: height .3s ease;
}

.idt-survey-card__question__footer {
  margin-top: var(--rem-size-20);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.idt-survey-card__question__footer__button {
  flex: 0 0 auto;
  min-width: 120px;
  height: 35px;
  margin-left: auto;
  line-height: 0;
}

.idt-survey-card__results {
  padding-top: .5rem;
  border-bottom: 1px solid var(--grey-light);
}

.idt-survey-card__results:not(.results-mode) {
  pointer-events: none;
}

.idt-survey-card__results__title {
  margin-top: var(--rem-size-28);
  margin-bottom: var(--rem-size-18);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-card__thanks,
.idt-survey-card__ended {
  margin-top: 1rem;
  color: var(--black);
}

.idt-survey-card__footer__hint {
  font-size: var(--rem-size-12);
}

.idt-survey-card__buttons {
  display: flex;
  align-items: center;
  position: absolute;
  height: 21px;
  right: 1rem;
  bottom: 1rem;
}

.idt-survey-card__buttons__collapse,
.idt-survey-card__buttons__close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  line-height: 0;
  background: var(--grey-lighter);
  cursor: pointer;
}

.idt-survey-card__buttons__close {
  margin-left: 4px;
}

