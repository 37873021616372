






































































.fo-lobby-message {
  &__last-unread {
    display: flex;
    position: relative;

    &::before {
      content: '';
      display: flex;
      position: absolute;
      left: 50%;
      top: 50%;
      width: calc(100% - 3rem);
      height: 2px;
      background-color: var(--danger-color-alpha20);
      transform: translate(-50%, -50%);
    }
    > div {
      display: inline-flex;
      position: relative;
      width: auto;
      margin: auto;
      padding: var(--rem-size-3) var(--rem-size-24);
      color: var(--danger-color);
      background: var(--white);
      font-weight: var(--font-weight-bold);
    }
  }
}
