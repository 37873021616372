































.idt-breadcrumb {
  display: flex;
  align-items: center;
  margin: 0;

  &__item {
    list-style-type: none;
    margin-right: 10px;
    color: var(--black-alpha-50);
    font-weight: var(--font-weight-bold);

    &:first-child {
      color: var(--black);
    }

    .ideacon.idea-ht-chevron-right {
      position: relative;
      top: 2px;
      margin-right: 6px;
      stroke-width: 1;
    }
  }
}
