.modal-upload-preview {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100));
  padding: var(--rem-size-32) 1.5rem;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;

  &__dialog {
    padding-top: 2rem;

    &.dialog--iframe {
      height: 100%;
    }
    &__content{
      position: relative;

      &.content--image {
        display: inline-block;
      }
      &.content--iframe {
        height: 100%;
      }
      &__close {
        position: absolute;
        top: -40px;
        right: 0;
        font-size: 30px;
        color: $white;
        cursor: pointer;
      }
      &__iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__close {
    color: $white;
    font-size: 1.2rem;
    cursor: pointer;
  }
}
