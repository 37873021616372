.event-talk__avatar {
  height: 200px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 3px 3px 0 0;
}

.event-talk__speaker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 54px;
  padding: 5px;
  background-color: var(--main-color);
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
}

.event-talk__speaker__name {
  width: 100%;
  color: var(--white);
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.event-talk__speaker__info {
  width: 100%;
  color: var(--white);
  font-size: .875rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.event-talk__title {
  margin-top: 7px;
  color: var(--grey-darker);
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: .5px;
}

