.idt-footer {
  color: var(--white);
  background-color: var(--grey-darkest);
}

.idt-footer__content {
  display: flex;
  flex-flow: row wrap;
  min-height: 120px;
}

@media only screen and (max-width: 639px) {
  .idt-footer__content {
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 640px) {
  .idt-footer__content {
    padding: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .idt-footer__content {
    flex-flow: column wrap;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .idt-footer__content {
    justify-content: space-between;
  }
}

.idt-footer__info {
  background-color: var(--black-alpha30);
}

@media only screen and (max-width: 639px) {
  .idt-footer__info {
    padding: .5rem 2rem;
  }
}

@media only screen and (min-width: 640px) {
  .idt-footer__info {
    padding: 1.5rem 4rem;
  }
}

@media only screen and (max-width: 639px) {
  .idt-footer__copy {
    font-size: var(--rem-size-14);
  }
}

.idt-footer__container {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.idt-footer__container--right {
  justify-content: space-between;
}

@media only screen and (max-width: 639px) {
  .idt-footer__container--right {
    margin-bottom: 2rem;
  }
}

.idt-footer__link-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.idt-footer__link-list__item {
  flex: 0 0 auto;
  margin-bottom: .5rem;
  padding-right: 1rem;
  line-height: 1.2;
}

@media only screen and (max-width: 639px) {
  .idt-footer__link-list__item {
    width: 50%;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .idt-footer__link-list__item {
    width: 33.33333%;
  }
}

@media only screen and (min-width: 1024px) {
  .idt-footer__link-list__item {
    width: 25%;
  }
}

.idt-footer__link-list__item a {
  color: var(--main-color-light);
}

.idt-footer__link-list__item a:hover {
  color: var(--main-color-lighter);
}

::v-deep .idt-footer__logo__svg {
  fill: var(--white);
}

@media only screen and (max-width: 639px) {
  ::v-deep .idt-footer__logo__svg {
    width: 200px !important;
    height: 38px !important;
  }
}

@media only screen and (min-width: 640px) {
  ::v-deep .idt-footer__logo__svg {
    width: 242px !important;
    height: 55px !important;
  }
}

.idt-footer__logo__img {
  max-width: 200px;
  max-height: 44px;
}

.idt-footer__back-to-top {
  display: inline-block;
  flex: 0 0 auto;
  width: 44px;
  height: 44px;
  margin-left: 2rem;
  color: var(--white-alpha50);
  border: 2px solid var(--white-alpha50);
  border-radius: 5px;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}

