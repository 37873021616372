.meeting-participants-list {
  width: 100%;
  padding: var(--rem-size-10);
  background-color: var(--white);
}

.meeting-participants-list__filters {
  display: flex;
}

.meeting-participants-list__filters .idt-select-container {
  width: 120px;
}

.meeting-participants-list__filters .idt-user-search-input {
  width: 100%;
  margin-right: 8px;
}

.meeting-participants-list__uninvite {
  width: var(--rem-size-28);
  height: var(--rem-size-28);
  color: var(--white);
  background: var(--grey);
  border-radius: 50%;
  line-height: 36px;
  cursor: pointer;
}

.meeting-participants-list__uninvite:hover {
  background: var(--grey-dark);
}

