.section-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  border: 2px dashed var(--grey-light);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-16);
}

.section-empty-state__label {
  margin-top: var(--rem-size-16);
}

