































.idt-bi-button-enable.button-group {
  margin: 0;

  .button {
    min-width: 135px;
    height: 48px;
    margin: 0;

    &:first-child {
      border-radius: var(--global-radius) 0 0 var(--global-radius);
    }
    &:last-child {
      border-radius: 0 var(--global-radius) var(--global-radius) 0;
    }
  }
}
