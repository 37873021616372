.idt-profile-security .vue-tel-input {
  display: flex !important;
}

.idt-profile-security__password {
  align-items: flex-end !important;
}

.idt-profile-security__password__change-button {
  margin-bottom: 0;
}

.idt-profile-security__password__cancel-button {
  margin-bottom: 0;
  color: var(--black-alpha70) !important;
  border: none !important;
}

.idt-profile-security__password__input {
  position: relative;
}

.idt-profile-security__password__input__toggle {
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background: var(--white);
}

.idt-profile-security__password__input .password-rules {
  position: absolute;
  overflow: visible;
}

.idt-profile-security__password__input .password-rules__content {
  margin: 0;
  width: 100%;
}

.idt-profile-security .idt-city-input__input-container {
  display: flex;
  align-items: center;
}

.idt-profile-security .idt-city-input__search {
  flex-basis: 75%;
  min-width: 50%;
}

.idt-profile-security .idt-city-input__timezone {
  flex-basis: 25%;
  min-width: 110px;
}

