.answer {
  margin-bottom: 1.25rem;
}

.answer__header {
  display: flex;
  padding-bottom: 1.2rem;
}

.answer__header__user {
  display: inline-block;
  vertical-align: middle;
  width: 75%;
  cursor: pointer;
}

.answer__header__user__avatar {
  display: inline-block;
  margin-right: var(--rem-size-8);
  vertical-align: middle;
}

.answer__header__user__information {
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: middle;
}

@media only screen and (max-width: 639px) {
  .answer__header__user__information .idt-user-information__username {
    display: block;
  }
}

.answer__header__vote {
  display: inline-block;
  width: 25%;
  vertical-align: middle;
  text-align: right;
}

.answer__header__vote__button {
  display: inline-block;
  font-size: 1.3rem;
  vertical-align: middle;
}

.answer__header__vote__button.button--togglable {
  transition: all .4s ease;
  cursor: pointer;
}

.answer__header__vote__button.button--togglable.button--full,
.answer__header__vote__button.button--togglable:hover {
  color: var(--main-color);
}

.answer__header__vote__button.button--disable {
  opacity: .4;
}

.answer__header__vote__number {
  display: inline-block;
  padding: 0 2px;
  font-size: .8rem;
  vertical-align: middle;
}

.answer__content {
  padding: 1.25rem 1.5rem;
  background-color: var(--white);
  box-shadow: -1px -1px 2px -2px var(--grey);
}

.answer__content__text {
  white-space: pre-wrap;
  word-break: break-word;
}

.answer__content__created {
  margin-bottom: .5rem;
  font-size: .9rem;
  font-weight: 300;
}

.answer__content__created__icon {
  cursor: pointer;
}

.answer__content__delete {
  font-size: 1.1rem;
  vertical-align: middle;
  cursor: pointer;
}

.answer__content__delete:hover {
  color: var(--danger-color);
}

.answer__content__preview {
  display: block;
  margin-top: .5rem;
}

