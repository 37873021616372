.idt-time-picker {
  user-select: none;
}

.idt-time-picker__step {
  width: 55px;
  height: 17px;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
  text-align: center;
  line-height: var(--rem-size-22);
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .idt-time-picker__step {
    height: 40px;
    line-height: 48px;
  }
}

.idt-time-picker__step:hover {
  border-color: var(--grey-darker);
}

.idt-time-picker__step .idt-icon {
  color: var(--grey-darkest);
}

.idt-time-picker__time__input {
  padding-bottom: 0;
}

.idt-time-picker__time__input ::v-deep input {
  width: 55px;
  height: 34px;
  margin: var(--rem-size-4) 0;
  padding: 0;
  border: 1px solid var(--main-color);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-30);
  text-align: center;
  box-sizing: border-box;
  outline: none;
}

.idt-time-picker__time__display {
  width: 55px;
  height: 34px;
  line-height: 34px;
  margin: var(--rem-size-4) 0;
  color: var(--black);
  background: var(--grey-lighter);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-30);
  text-align: center;
}

.idt-time-picker__time__display.editable {
  cursor: text;
}

