.multi-speaker-list__counter {
  font-weight: var(--font-weight-bold);
  color: var(--black);
  border-bottom: 1px solid #cacaca;
}

.multi-speaker-list__item {
  display: flex;
  align-items: center;
  padding: .3rem 0;
  cursor: pointer;
}

.multi-speaker-list__item:not(:last-child) {
  border-bottom: 1px solid #cacaca;
}

.multi-speaker-list__item__avatar {
  flex: 0 0 auto;
}

.multi-speaker-list__item__speaker {
  padding-left: .45rem;
  color: var(--black);
}

.multi-speaker-list__item__speaker__name {
  font-family: var(--title-font-family);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-16);
  line-height: 1.2;
}

.multi-speaker-list__item__speaker__company {
  font-size: var(--rem-size-13);
  line-height: 1;
}

