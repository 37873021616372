


























































































































































































































































































































































































































.agenda-activities {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;

  #list-mobile {
    display: none;
  }

  // TODO - Rework to use platform breakpoints
  @media only screen and (max-width: 1000px) {
    #list {
      display: none;
    }
    #list-mobile {
      display: flex;
    }
  }

  &__label {
    font-weight: var(--font-weight-medium);
  }

  &__empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 550px;
    min-height: 250px;
    margin: 40px 0px 0px 0px;
    border: solid 1px var(--grey-light);

    &__text {
      font-family: var(--text-font-family);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-light);
      line-height: 22px;
      text-align: center;
    }
  }
  &__delete {
    &__icon {
      margin-left: var(--rem-size-8);
    }
  }
}
