


















































































.item-list {
  display: flex;
  flex-direction: column;
  gap: var(--rem-size-8);
  height: 350px;
  padding: var(--rem-size-8);
  border: solid 1px var(--grey-light);
  overflow-y: auto;

  .ghost {
    width: 600px;
    background: var(--grey-lighter);
    cursor: grab;
    opacity: 0.5;
  }
}
