








































































@import '@/styles/mixins/index';

.bo-menu-item {
  font-weight: var(--font-weight-medium);

  @include respond-to(max-xlarge) {
    display: flex;
    align-items: center;
    margin: 0.5rem 1rem;
  }
  @include respond-to(xlarge) {
    display: block;
    position: relative;
    margin: 0.5rem;
  }
  @include respond-to(xlarge-xxlarge) {
    font-size: var(--rem-size-12);
  }
  @include respond-to(xxlarge) {
    font-size: var(--rem-size-14);
  }

  &:not(.feature--disabled) {
    color: var(--grey);
  }
  &:hover,
  &.active {
    color: var(--main-color-light)
  }
  &__indicator {
    display: block;
    width: 10px;
    height: 5px;
    border-radius: var(--global-radius);

    @include respond-to(max-xlarge) {
      margin-right: 0.5rem;
    }
    @include respond-to(xlarge) {
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &:not(.active) {
      background-color: var(--danger-color);
    }
    &.active {
      background-color: var(--success-color);
    }
  }
}
