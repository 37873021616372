.idt-survey-question-rating {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 0 1rem;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.idt-survey-question-rating__stars {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.idt-survey-question-rating__stars__item {
  padding: 0 1px;
  color: var(--grey);
  cursor: pointer;
}

.idt-survey-question-rating__stars__item.active {
  color: var(--yellow);
}

.idt-survey-question-rating__label {
  line-height: 1.2rem;
}

