




























































































































































@import '@/styles/mixins/index';

.question {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  cursor: pointer;

  &__part {
    flex: 0 0 auto;
  }
  .author {
    display: flex;
    align-items: center;
    order: 1;
    color: var(--white);
    background-color: var(--main-color);

    @include respond-to(max-medium) {
      width: 100%;
    }
    @include respond-to(medium-large) {
      width: 50%;
      height: 70px;
    }
    @include respond-to(max-large) {
      flex-direction: row;
      padding: 1rem;
    }
    @include respond-to(large) {
      flex-direction: column;
      justify-content: center;
      width: 274px;
      height: 170px;
      padding: 1.25rem 0;
      text-align: center;
    }

    .author {
      &__avatar {
        @include respond-to(max-large) {
          width: 40px !important;
          height: 40px !important;
          margin-right: 1rem;
        }
        @include respond-to(large) {
          margin-bottom: 0.85rem;
        }

        .idt-user-avatar {
          filter: drop-shadow(2px 2px 3px var(--black-alpha50));
        }
      }
      &__information {
        @include respond-to(max-medium) {
          flex: 1 1 auto;
        }
      }
    }
  }
  .content {
    @include respond-to(max-large) {
      order: 3;
      width: 100%;
      padding: 1rem 1.5rem;
    }
    @include respond-to(large) {
      order: 2;
      width: calc(100% - (274px + 160px));
      padding: 0 2.2rem;
    }

    &__actions {
      display: flex;
      margin-bottom: 0.5rem;

      &__delete {
        position: relative;
        margin-left: 2rem;
        color: var(--main-color);
        vertical-align: middle;

        @include respond-to(medium) {
          font-size: 1.25rem;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: -1rem;
          height: 100%;
          border-left: 1px solid var(--grey);
          transform: translateY(-50%);
        }
      }
    }
    &__created {
      color: var(--grey-darker);
      font-weight: 300;
      line-height: 1;

      @include respond-to(medium) {
        font-size: 1.25rem;
      }
    }
    &__text {
      color: var(--grey-dark);
      font-weight: 500;
      line-height: 1.15;
      overflow: hidden;

      @include respond-to(max-medium) {
        height: 74px;
      }
      @include respond-to(medium) {
        height: 64px;
        font-size: 1.15rem;
      }
    }
  }
  .info {
    @include respond-to(max-medium) {
      width: 100%;
      padding-bottom: 1rem;
    }
    @include respond-to(medium-large) {
      justify-content: flex-end;
      width: 50%;
      height: 70px;
      padding: 1rem 0;
    }
    @include respond-to(max-large) {
      display: flex;
      align-items: center;
      order: 2;
      color: var(--white);
      background-color: var(--main-color);
    }
    @include respond-to(large) {
      order: 3;
      width: 160px;
      color: var(--grey-darker);
      padding-right: 2.5rem;
    }

    &__block {
      display: flex;

      @include respond-to(max-medium) {
        justify-content: center;
        align-items: flex-end;
        width: 50%;
        text-align: center;
      }
      @include respond-to(medium) {
        justify-content: flex-end;
        align-items: center;
      }
      @include respond-to(medium-large) {
        text-align: right;
      }
      @include respond-to(max-large) {
        padding: 0 1rem;
      }
      @include respond-to(large) {
        text-align: center;
      }

      &:first-child {
        @include respond-to(medium-large) {
          margin-right: 1.5rem;
          padding-right: 1.5rem;
        }
        @include respond-to(max-large) {
          border-right: 1px solid var(--white);
        }
        @include respond-to(large) {
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--grey);
        }
      }
      &__text {
        @include respond-to(medium) {
          flex: 1 0 auto;
        }
      }
      &__number {
        font-size: 1.5rem;
        font-weight: 100;
        line-height: 1;

        @include respond-to(max-large) {
          display: inline-block;
        }
      }
      &__label {
        font-weight: 500;

        @include respond-to(max-large) {
          display: inline-block;
          font-size: 0.9rem;
          line-height: 1;
        }
      }
      &__icon {
        padding-left: 1rem;
        line-height: 1;
      }
    }
  }
}
