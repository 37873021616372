



















































.idt-card-add-button {
  position: relative;
  margin: 0 0.9375rem;
  color: var(--main-color);
  border: 2px dashed var(--main-color);
  border-radius: var(--global-radius);
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
  }
}
