


















.talk-premium-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 30px;
  padding: 5px 10px;
  color: var(--secondary-color);
  background-color: var(--secondary-color-alpha20);
  border-top-left-radius: 3px;
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;

  &__text {
    margin-left: 5px;
  }
}
