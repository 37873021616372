

















































































































































































@import '@/styles/mixins/index';

.sponsor-showroom {
  &__wrapper:first-child {
    padding-top: 25px;
  }
  &__wrapper:last-child {
    padding-bottom: 25px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--top-level {
      background: var(--grey-lighter);
      padding: 50px 1rem;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
    &--level {
      padding: 25px 1rem;
    }
  }
  &__row {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;
    max-width: calc(424px * 3);
  }
}
.idt-page-section {
  &__title {
    margin-bottom: 10px;
  }
  &__subtitle {
    margin-bottom: 30px;
  }
}
