














































.idt-radio-button-group {
  display: flex;
  align-items: center;
  border-radius: var(--global-radius);

  label {
    display:flex;
    justify-content:center;
    align-items:center;
    flex-grow: 1;
    margin: 0;
    padding: var(--rem-size-12);
    border: 1px solid var(--main-color);
    color: var(--main-color);
    text-transform: capitalize;

    transition: all 0.25s ease-out, color 0.25s ease-out;
  }
  label:first-of-type {
    border-radius: var(--global-radius) 0 0 var(--global-radius);
    border-right: none;
  }
  label:last-of-type {
    border-radius: 0 var(--global-radius) var(--global-radius) 0;
    border-left: none;
  }
  label:hover {
    border-color: var(--main-color-light);
    color: var(--main-color-light);
  }

  input[type="radio"]:checked + label {
    background-color: var(--main-color);
    color: var(--white);

    &:hover {
      background-color: var(--main-color-light);
    }
  }
  input[type="radio"] {
    display: none;
  }
}

.simple {
  .idt-radio-button-group__container {
    display: none;
  }
  label {
    justify-content: flex-start;
    margin-left: var(--rem-size-8);
    padding: 0;
    color: var(--grey-darkest);
    border: 0;
    font-weight: 400;
    font-size: var(--rem-size-14);
  }
  label:hover {
    color: var(--grey-darkest);
  }
  input[type="radio"]::before {
    content: '';
    width: var(--rem-size-8);
    height: var(--rem-size-8);
    border-radius: 50%;
    box-shadow: inset var(--rem-size-8) var(--rem-size-8) var(--idt-info-color);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  input[type="radio"]:checked + label {
    color: var(--grey-darkest);
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
  input[type="radio"] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--rem-size-18);
    height: var(--rem-size-18);
    margin: 0;
    color: var(--idt-info-color);
    border: var(--rem-size-2) solid var(--idt-info-color);
    border-radius: 50%;
    appearance: none;
    font: inherit;
  }
}
