
























































































































@import '@/styles/mixins/index';

.resource {
  display: flex;
  align-items: stretch;
  min-height: 150px;
  background-color: var(--main-color-alpha10);
  border-radius: var(--global-radius);
  overflow: hidden;

  @include respond-to(max-large) {
    flex-direction: column;
  }

  &__img-container {
    flex: 0 0 auto;
    width: var(--resource-img-width);
    background-position: center;
    background-size: cover;

    @include respond-to(max-large) {
      width: 100%;
      height: 150px;
    }
  }
  &__content {
    flex: 1 1 auto;
    position: relative;

    @include respond-to(max-large) {
      padding: 20px 2rem;
    }
    @include respond-to(large) {
      padding: 30px 0 30px 3rem;
    }
  }
  &__edit {
    position: absolute;
    left: 0.75rem;
    color: var(--main-color);
    font-size: var(--rem-size-14);
    cursor: pointer;

    @include respond-to(max-large) {
      top: 0.5rem;
    }
    @include respond-to(large) {
      top: 0.75rem;
    }

    &:hover {
      color: var(--main-color-dark);
    }
  }
  &__name,
  &__description {
    color: var(--black);
  }
  &__name {
    font-family: var(--title-font-family);

    @include respond-to(max-large) {
      font-size: var(--rem-size-20);
    }
    @include respond-to(large) {
      font-size: var(--rem-size-24);
    }
  }
  &__description {
    @include respond-to(max-large) {
      font-size: var(--rem-size-14);
    }
  }
  &__cta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    @include respond-to(max-large) {
      width: 100%;
      padding: 1rem 3rem;
    }
    @include respond-to(large) {
      padding: 0 3rem;
    }

    .button {
      min-width: 132px;
      margin: 0;
      text-transform: uppercase;
    }
  }
}
