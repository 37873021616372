























































































































































































































































































































































































































































@import '@/styles/mixins/index';

.fo-lobby-messages {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;

  &__content-wrapper {
    display: flex;
    flex-flow: column;
    position: relative;
    flex: auto;
    overflow: hidden;

    &--loading {
      padding-top: var(--rem-size-10);
    }
  }

  &__messages {
    display: flex;
    flex: auto;
    height: 100%;

    &__scroll {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      // be careful when adjusting that value because it
      // allows to view the chat message actions on hover
      // when it's the first message
      padding-top: var(--rem-size-12);
      // reduce lag
      transform: translate3d(0, 0, 0);
    }

    > * {
      width: 100%;
      .live-chat-message {
        padding: 0 var(--rem-size-24);
      }
    }
  }

  &__unread-button {
    display: inline-flex;
    width: auto;
    position: absolute;
    padding: var(--rem-size-9) var(--rem-size-24);
    left: 50%;
    top: var(--rem-size-12);
    // needs to put the color directly like this since it's floating above content :/
    background: var(--lobby-danger-background);
    color: var(--danger-color);
    font-weight: var(--font-weight-bold);
    z-index: 10;
    transform: translateX(-50%);
    border-radius: 5rem;

    &--bottom {
      top: auto;
      bottom: var(--rem-size-12);
    }

    &:hover {
      color: var(--danger-color);
      background: var(--lobby-danger-background);
    }
  }
}

/* override UI for actions */
.live-chat-message--in-lobby {
  .live-chat-message-actions {
    padding-right: var(--rem-size-20);

    .idt-button-group > span > .action {
      border-radius: 100% !important;
      border: 1px solid var(--grey-light) !important;
      background: var(--white) !important;
      margin-right: 5px;

      svg:not(.idea-ht-emoji) {
        width: var(--rem-size-10) !important;
      }
    }
  }
}
