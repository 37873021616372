.bo-tips {
  position: relative;
  margin-bottom: var(--rem-size-32);
}

.bo-tips:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(var(--rem-size-8)*-1);
  height: 100%;
  border-left-width: 1px;
  border-left-style: solid;
}

.bo-tips:not(.danger):after {
  border-color: var(--info-color);
}

.bo-tips:not(.danger) .bo-tips__title {
  color: var(--info-color);
}

.bo-tips.danger:after {
  border-color: var(--danger-color);
}

.bo-tips.danger .bo-tips__title {
  color: var(--danger-color);
}

.bo-tips__title {
  padding-bottom: var(--rem-size-8);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-light);
  line-height: var(--rem-size-18);
  text-transform: uppercase;
}

.bo-tips__text {
  color: var(--grey-darkest);
}

