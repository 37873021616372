

























































.selection-container {
  &__block {

    &__text {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 100px;
      padding-left: 40px;
      font-weight: 500;

      &__title {
        color: var(--black);
        font-size: var(--rem-size-18);
        line-height: var(--rem-size-24);
      }
    }
    &__timeslots {
      min-height: 200px;
      max-height: calc(100vh - 260px);
      overflow-x: auto;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey);
    }
  }
}
