














































































.idt-switch {
  display: inline-block;
  position: relative;
  min-width: 80px;
  height: 34px;
  border-radius: 50px;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &.disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  &__inner {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;

    .idt-switch__label {
      position: absolute;
      top: 50%;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      transition: all 0.3s ease;

      &--off {
        right: -10px;
        color: var(--secondary-color);
        transform: translate3d(100%, -50%, 0);
      }
      &--on {
        left: -10px;
        color: var(--main-color-dark);
        transform: translate3d(-100%, -50%, 0);
      }
    }
    &.off {
      background-color: var(--secondary-color-alpha20);

      .idt-switch__label--off {
        right: 10px;
        transform: translate3d(0, -50%, 0);
      }
    }
    &.on {
      background-color: var(--main-color-lighter);

      .idt-switch__label--on {
        left: 10px;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.3s ease;

    &.off {
      left: 10%;
      background-color: var(--secondary-color);
      transform: translate3d(0, -50%, 0);
    }
    &.on {
      left: 90%;
      background-color: var(--main-color-dark);
      transform: translate3d(-100%, -50%, 0);
    }
  }
}
.idt-switch-label {
  margin-left: 15px;
  vertical-align: middle;
}
