.simple-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  background-color: var(--modal-background);
  transform: translate(-50%, -50%);
  animation: slideDown .3s ease-in-out;
  overflow-y: auto;
}

.simple-modal__close {
  position: absolute;
  top: var(--rem-size-15);
  right: var(--rem-size-15);
  cursor: pointer;
}

.simple-modal.closing {
  animation: slideUp .3s ease-in-out;
}

@media only screen and (max-width: 639px) {
  .simple-modal-medium {
    width: 100%;
    height: calc(var(--vh, 1vh)*100);
  }
}

@media only screen and (min-width: 640px) {
  .simple-modal-medium {
    width: 600px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .simple-modal-large {
    width: 100%;
    height: calc(var(--vh, 1vh)*100);
  }
}

@media only screen and (min-width: 1024px) {
  .simple-modal-large {
    width: 1000px;
    max-width: 100%;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
}

