.idt-select-list {
  width: 100%;
}

.idt-select-list__title {
  color: var(--grey-darkest);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-28);
}

.idt-select-list--darkmode .idt-select-list__title {
  color: var(--white);
  font-weight: var(--font-weight-normal);
}

.idt-select-list .input-dropdown {
  width: 100%;
  margin: 0 0 var(--rem-size-16);
  padding: 0;
  background: var(--white);
  border: var(--rem-size-1) solid var(--grey);
  border-radius: var(--rem-size-3);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-normal);
  line-height: var(--rem-size-16);
}

.idt-select-list .input-dropdown .idt-dropdown {
  display: block;
}

.idt-select-list .input-dropdown .input-dropdown__input.div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--rem-size-42);
  color: var(--grey);
}

.idt-select-list .input-dropdown .input-dropdown__input.div span {
  padding-left: var(--rem-size-14);
}

.idt-select-list .input-dropdown .input-dropdown__input.div .ideacon {
  margin: 0 var(--rem-size-12);
  color: var(--grey-darkest);
}

.idt-select-list .input-dropdown .input-dropdown__input.div.focus .ideacon {
  transform: rotate(180deg);
}

.idt-select-list .input-dropdown .input-dropdown__input.div .tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: var(--rem-size-2);
}

.idt-select-list .input-dropdown .input-dropdown__input.div .tag-list .tag-item {
  max-width: 150px;
  height: auto;
  padding: var(--rem-size-7) var(--rem-size-8);
  border-radius: var(--rem-size-5);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-18);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.idt-select-list--darkmode .input-dropdown {
  background: var(--white-alpha10);
  border: none;
}

.idt-select-list--darkmode .input-dropdown .input-dropdown__input.div {
  font-family: var(--text-font-family);
}

.idt-select-list--darkmode .input-dropdown .input-dropdown__input.div span {
  font-size: var(--rem-size-14);
}

.idt-select-list--darkmode .input-dropdown .input-dropdown__input.div .ideacon {
  color: var(--white);
}

.idt-select-list--invalid .input-dropdown {
  border-color: var(--secondary-color);
}

.idt-select-list .idt-dropdown--pane {
  width: 100%;
  padding: 0;
  box-shadow: 0 var(--rem-size-3) var(--rem-size-6) rgba(0,0,0,.161);
  transform: translateY(var(--rem-size-10));
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__header {
  display: flex;
  justify-content: space-between;
  min-height: auto;
  padding: var(--rem-size-9) var(--rem-size-8) var(--rem-size-9) var(--rem-size-16) !important;
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-18);
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__header::before {
  display: none !important;
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__header__limit {
  padding-right: var(--rem-size-2);
  color: var(--grey-dark);
  font-size: var(--rem-size-10);
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox {
  display: flex;
  justify-content: space-between;
  width: calc(100% - var(--rem-size-8));
  margin: var(--rem-size-4);
  padding: var(--rem-size-4);
  padding-left: var(--rem-size-12);
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox span {
  color: var(--grey-darkest);
  font-weight: var(--font-weight-normal);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox::before {
  display: none;
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox:not(.disabled):hover {
  background: var(--grey-lighter);
  border-radius: var(--rem-size-5);
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox:not(.disabled):hover .idt-icon {
  color: var(--grey-dark);
  background: var(--grey-lighter);
  border-color: var(--grey-lighter);
}

.idt-select-list .idt-dropdown--pane .idt-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--rem-size-20);
  height: var(--rem-size-20);
  margin-left: var(--rem-size-8);
  color: var(--white);
  background: var(--white);
  border: var(--rem-size-1) solid var(--grey);
  border-radius: var(--rem-size-2);
}

.idt-select-list .idt-dropdown--pane input:checked~.dropdown-pane__checkbox .idt-icon,
.idt-select-list .idt-dropdown--pane .dropdown-pane__header.checked .idt-icon {
  color: var(--white);
  background: var(--main-color);
  border-color: var(--main-color);
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__header.disabled,
.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox.disabled {
  cursor: auto;
}

.idt-select-list .idt-dropdown--pane .dropdown-pane__header.disabled .idt-icon,
.idt-select-list .idt-dropdown--pane .dropdown-pane__checkbox.disabled .idt-icon {
  display: none;
}

.idt-select-list .idt-dropdown--pane input {
  display: none;
}

