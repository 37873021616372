
.modal-ip-filter {
  &__content {
    min-width: 720px;
  }

  .required-field-text {
    color:#F14040;
    font-size: 9pt;
  }
}