



































































@import '@/styles/mixins/index';

.bo-page-info {
  position: relative;

  @include respond-to(max-xlarge) {
    height: var(--bo-header-elm-height);
    padding: 0 var(--rem-size-20);
    background-color: var(--white);
  }
  @include respond-to(xlarge) {
    height: var(--bo-header-height);
  }

  &__toggle {
    display: flex;
    align-items: center;
    color: var(--main-color);
    cursor: pointer;

    @include respond-to(max-xlarge) {
      justify-content: flex-end;
      height: var(--bo-header-elm-height);
    }
    @include respond-to(xlarge) {
      height: var(--bo-header-height);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--main-color-dark);
      }
    }
    &.open {
      color: var(--main-color-dark);
    }
    &__label {
      margin-right: 0.25rem;
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
    }
  }
  &__container {
    color: var(--grey-darkest);
    background-color: var(--main-color-lightest);
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    @include respond-to(max-medium) {
      top: calc(var(--bo-header-height) + var(--bo-header-elm-height));
      left: 0;
      width: 100%;
      box-shadow: -2px 2px 3px var(--black-alpha15);
    }
    @include respond-to(medium-xlarge) {
      top: calc(var(--menu-height) + var(--bo-header-height) + var(--bo-header-elm-height));
      left: var(--vertical-menu-width);
      width: calc(100vw - var(--vertical-menu-width));
    }
    @include respond-to(max-xlarge) {
      position: fixed;
      height: calc((var(--vh) * 100) - (var(--menu-height) + var(--bo-header-height) + var(--bo-header-elm-height)));
      z-index: 1;
    }
    @include respond-to(xlarge) {
      position: absolute;
      top: 100%;
      right: 0;
      width: 470px;
      box-shadow: -2px 2px 3px var(--black-alpha15);
    }

    &__icon {
      position: absolute;
      right: 20px;
      bottom: 1rem;
      color: var(--main-color-lighter);
      z-index: 0;
    }
    &.slide-content-enter-active {
      animation: slide-down 0.3s;
    }
    &.slide-content-leave-active {
      animation: slide-down 0.3s reverse;
    }
    ::v-deep * {
      line-height: 1.25;
    }
  }
  &__content {
    position: relative;
    z-index: 1;

    @include respond-to(max-xlarge) {
      height: 100%;
      padding: 50px 45px 30px 30px;
      overflow-y: auto;
    }
    @include respond-to(xlarge) {
      padding: 30px 30px 15px;
    }
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--main-color-dark);
    z-index: 2;

    @include respond-to(xlarge) {
      display: none;
    }
  }
  ::v-deep ul {
    margin-left: 20px;
    list-style-position: inside;

    li {
      margin-bottom: 0.25rem;
      color: var(--main-color);

      span {
        color: var(--grey-darkest);
        font-weight: var(--font-weight-medium);
      }
    }
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
