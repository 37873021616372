.questions__section {
  padding-bottom: 2rem;
}

.questions__section__list__actions {
  text-align: center;
}

.questions__section__list__actions .button {
  margin: 0;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
}

.idt-table-pagination.idt-question-pagination {
  margin-bottom: 20px;
}

