














































































































































































































@import '@/styles/mixins/index';

.meeting-waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--rem-size-20) 0;
  color: var(--grey-darkest);

  &__open-button {
    width: 200px;
    height: 50px;
    margin-top: 80px;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    text-align: center;
    background-color: var(--main-color);
    border-radius: var(--global-radius)
  }

  & .box {
    padding: var(--rem-size-10) var(--rem-size-12);
    background: var(--white);
    border-radius: var(--global-radius);
  }

  & .info-label {
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-medium);
  }

  &__participant-carousel {
    width: 100%;
    margin-bottom: var(--rem-size-10);

    &.small {
      width: 600px;
    }

    &__name {
      margin-top: var(--rem-size-8);
      margin-bottom: var(--rem-size-4);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-medium);
    }

    &__job {
      margin-bottom: var(--rem-size-5);
      font-size: var(--rem-size-12);
    }

    ::v-deep .idt-tag {
      display: inline-block;
    }

    ::v-deep .slick-slider {
      margin: var(--rem-size-10) 0;

      & .slick-list {
        padding: 0px 20%;

        & .slick-slide {
          width: 120px;
          margin: 0 var(--rem-size-30);
          text-align: center;
        }
      }

      & .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 120px;
        height: 100%;
        z-index: 1;

        &:first-child {
          left: 0;
          background: linear-gradient(90deg, #F5F6FA 65.44%, rgba(235, 237, 242, 0) 89.78%, rgba(245, 246, 250, 0) 89.78%)
        }

        &:last-child {
          right: 0;
          background: linear-gradient(271.15deg, #F5F6FA 65.44%, rgba(235, 237, 242, 0) 89.78%, rgba(245, 246, 250, 0) 89.78%);

          & .arrow {
            float: right;
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: 62px;
    font-size: var(--rem-size-24);
    font-weight: var(--font-weight-medium);
    line-height: var(--rem-size-30);
  }

  &__informations {
    margin-bottom: var(--rem-size-32);

    &__date .idt-icon {
      margin-right: var(--rem-size-8)
    }

    span > span {
      line-height: var(--rem-size-24);
    }

    ::v-deep .idt-icon > svg {
      vertical-align: sub;
    }
  }

  &__countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__time-label {
      margin-left: var(--rem-size-8);
      font-size: var(--rem-size-14);
      line-height: var(--rem-size-20);
    }

    &__item:not(:last-child) {
      margin-right: var(--rem-size-16);
    }
  }
}
