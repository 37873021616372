















































































































































































@import '@/styles/mixins/index';
.password-rules {
  position: relative;
  width: 100%;
  height: 80px;
  text-align: left;
  overflow: hidden;

  &.small {
    position: absolute;
    bottom: -80px;
    right: 0;
  }
  &.small &__content {
    margin: 0;
  }
  &.is-not-last {
    height: 0;
    margin-bottom: 1rem;
    transition: all 0.3s ease-in-out;
  }
  &.is-not-last.error,
  &.only-error {
    height: 18px;
  }
  &.is-not-last.open {
    height: 78px;
  }
  &__input {
    position: relative;
    margin: 0 !important;
    z-index: 10;
  }
  &__content {
    position: absolute;
    top: -100%;
    left: 0.5rem;
    width: calc(100% - 1rem);
    background-color: var(--grey-lightest);
    border: 1px solid var(--grey);
    border-top-width: 0;
    opacity: 0;
    transition: all 0.275s ease-in-out;
    z-index: 1;

    &.open {
      top: 0;
      opacity: 1;
    }
  }
  &__title {
    padding: 0.5rem 0.75rem;
    color: var(--grey-dark);
    font-size: 0.7rem;
  }
  &__wraper {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    padding-bottom: 0.75rem;

    &.many:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 100%;
      border-left: 1px solid var(--grey);
      transform: translateX(-50%);
    }
  }
  .password-rule {
    flex: 0 0 auto;
    width: 50%;
    font-size: 0.7rem;

    &:nth-child(odd) {
      @include respond-to(max-medium) {
        padding: 0 0.5rem 0 1rem;
      }
      @include respond-to(medium) {
        padding: 0 0.5rem 0 1.5rem;
      }
    }
    &:nth-child(even) {
      padding: 0 1rem 0 1rem;
    }
    &--compliant {
      .ideacons,
      .idt-icon {
        color: var(--main-color);
      }
    }
    &--not-compliant {
      color: var(--grey-dark);
      font-weight: 500;
    }
  }
}
