.export-dropdown .idt-dropdown--pane {
  padding: 0;
  border-radius: 0;
}

.idt-export-list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.idt-export-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--rem-size-8);
  gap: var(--rem-size-16);
  color: var(--grey-darker);
  border-radius: 0;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-normal);
  line-height: 1;
  cursor: pointer;
}

.idt-export-list-item:not(:last-child) {
  border-bottom: 1px solid var(--grey-lightest);
}

.idt-export-list-item:hover {
  background-color: var(--grey-lightest);
}

.idt-export-list-item__label {
  color: var(--grey-darker);
}

.idt-export-list-item__icon {
  flex: 0 0 auto;
}

.idt-export-list-item__input-file {
  position: absolute;
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

