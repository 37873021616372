.gamification-leaderboard-row {
  display: flex;
  flex-direction: row;
  height: 70px;
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.gamification-leaderboard-row__position {
  color: var(--grey);
  font-size: var(--rem-size-16);
  font-weight: bold;
  margin: auto 1rem;
  text-align: center;
  width: 2rem;
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row__position {
    margin: auto 0;
  }
}

.gamification-leaderboard-row__avatar {
  margin: auto;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row__avatar.idt-user-avatar {
    width: 30px !important;
    height: 30px !important;
  }
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row__avatar ::v-deep .idt-user-avatar__initial {
    font-size: 20px !important;
    line-height: 30px !important;
  }
}

.gamification-leaderboard-row__content {
  display: flex;
  flex-direction: column;
  flex-grow: 6;
  width: 0;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row__content {
    margin: auto 0;
    padding-left: .5rem;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-leaderboard-row__content {
    padding: .5rem 0 .5rem 1rem;
  }
}

.gamification-leaderboard-row__content__title {
  width: 100%;
  color: var(--black);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row__content__title {
    font-size: var(--rem-size-16);
  }
}

@media only screen and (min-width: 992px) {
  .gamification-leaderboard-row__content__title {
    font-size: var(--rem-size-18);
  }
}

.gamification-leaderboard-row__content__description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row__content__description {
    font-size: var(--rem-size-12);
  }
}

@media only screen and (min-width: 992px) {
  .gamification-leaderboard-row__content__description {
    font-size: var(--rem-size-14);
  }
}

.gamification-leaderboard-row .gamification-points {
  margin: auto 1rem;
}

@media only screen and (max-width: 991px) {
  .gamification-leaderboard-row .gamification-points ::v-deep sup {
    display: none;
  }
}

.gamification-leaderboard-row.active {
  background: var(--main-color-gradient);
  color: var(--white);
}

.gamification-leaderboard-row.active .gamification-leaderboard-row__position,
.gamification-leaderboard-row.active .gamification-leaderboard-row__content__title {
  color: var(--white);
}

