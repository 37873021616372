.modal-medium__close {
  position: fixed;
  top: var(--rem-size-24);
  right: var(--rem-size-24);
  z-index: 20;
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .modal-medium__content {
    padding: 45px 20px 25px;
  }
}

