



















































@import '@/styles/mixins/index';

.event-partner {
  margin-bottom: 1rem;
  text-align: center;

  @include respond-to(max-small) {
    flex: 0 0 100%;
  }
  @include respond-to(small-medium) {
    flex: 0 0 50%;
  }
  @include respond-to(medium-large) {
    flex: 0 0 33.33333%;
  }
  @include respond-to(large-xlarge) {
    flex: 0 0 25%;
  }
  @include respond-to(xlarge) {
    flex: 0 0 20%;
  }
}
