














































@import '@/styles/mixins/index';

.payment-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: left;

  &__container {
    display: flex;

    @include respond-to(max-medium) {
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
    }
    @include respond-to(medium) {
      flex-direction: row;
      align-items: baseline;
      justify-content: center;
    }
  }
  &__icon {
    color: var(--secondary-color);

    &.idt-icon ::v-deep .ideacon {
      width: 2.75rem;
      height: 2.75rem;

      @include respond-to(max-medium) {
        margin-bottom: 15px;
      }
      @include respond-to(medium) {
        margin-right: 25px;
      }
    }
  }
  &__message {
    &__title {
      color: var(--grey-darkest);
      font-size: 1.75rem;
      font-weight: var(--font-weight-medium);
      line-height: normal;

      @include respond-to(max-xsmall) {
        margin-bottom: 5px;
        font-size: 1.75rem;
      }
      @include respond-to(xsmall-medium) {
        margin-bottom: 5px;
        font-size: 2rem;
      }
      @include respond-to(medium-large) {
        margin-bottom: 15px;
        font-size: 2.5rem;
      }
      @include respond-to(large) {
        margin-bottom: 20px;
        font-size: 2.5rem;
      }
    }
    &__text {
      color: var(--grey-darker);
      font-weight: var(--font-weight-light);
      line-height: 1.25;

      @include respond-to(x-small) {
        font-size: 1rem;
      }
      @include respond-to(xsmall-medium) {
        font-size: 1.125rem;
      }
      @include respond-to(medium-large) {
        font-size: 1.25rem;
      }
      @include respond-to(large) {
        font-size: 1.5rem;
      }
    }
  }
  &__retry {
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;

    @include respond-to(max-medium) {
      width: 152px;
      height: 36px;
      margin-top: 20px;
      font-size: 1rem;
      line-height: 0.5;
    }
    @include respond-to(medium-large) {
      width: 200px;
      height: 46px;
      margin-top: 20px;
      font-size: 1.125rem;
    }
    @include respond-to(large) {
      width: 200px;
      height: 46px;
      margin-top: 30px;
      font-size: 1.125rem;
    }
  }
}
