



























































































































































































@import '@/styles/functions';
@import '@/styles/mixins';

.external-api-keys {
  &__list {
    margin-bottom: remCalc(16);
    box-shadow: 0 2px 3px var(--grey-darker-alpha15);
    border: 1px solid var(--grey-light);
    border-radius: var(--global-radius);
  }
  &__head,
  &__item {
    display: grid;
    gap: remCalc(8);

    @include respond-to(max-slarge) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to(slarge) {
      grid-template-columns: 30% 20% 20% 10% calc(20% - remCalc(32));
    }
  }
  &__head {
    @include respond-to(max-slarge) {
      display: none;
    }
    @include respond-to(slarge) {
      padding: remCalc(16);
    }
  }
  &__item {
    font-size: remCalc(14);

    @include respond-to(max-slarge) {
      padding: remCalc(8) remCalc(16);
    }
    @include respond-to(slarge) {
      padding: 0 remCalc(16);
      line-height: remCalc(48);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-light);
    }
  }
  &__name {
    @include respond-to(max-slarge) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  &__date {
    @include respond-to(max-slarge) {
      grid-area: 1 / 2 / 2 / 3;
    }
  }
  &__key {
    @include respond-to(max-slarge) {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
  &__usage {
    @include respond-to(max-slarge) {
      grid-area: 3 / 1 / 4 / 2;
    }
    @include respond-to(slarge) {
      text-align: right;
    }
  }
  &__actions,
  &__add {
    text-align: right;
  }
  &__actions {
    @include respond-to(max-slarge) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      grid-area: 3 / 2 / 4 / 3;
    }

    button {
      padding: remCalc(12);
      color: inherit;
      cursor: pointer;
    }
  }
  &__delete {
    &:hover {
      color: var(--danger-color);
    }
  }
  &__input {
    ::v-deep .idt-input {
      height: 46px;
      margin-top: 2px;
      padding-left: 0;
      border-radius: 0;
      border-width: 0 0 2px 0;
      font-size: remCalc(14);
    }
  }
}
