.idt-city-input {
  display: inline-block;
  width: 100%;
}

.idt-city-input__input-container {
  display: flex;
}

.idt-city-input__search {
  width: 75%;
  margin: 0;
  border-radius: var(--global-radius);
  outline: none;
}

@media only screen and (max-width: 479px) {
  .idt-city-input__search {
    width: 65%;
  }
}

.idt-city-input__search--with-timezone {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.idt-city-input__search:focus {
  border-color: var(--grey-darker);
}

.idt-city-input__search.has-error {
  border-color: var(--secondary-color);
}

.idt-city-input__search input:-webkit-autofill,
.idt-city-input__search input:-webkit-autofill:hover,
.idt-city-input__search input:-webkit-autofill:focus textarea:-webkit-autofill,
.idt-city-input__search textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
.idt-city-input__search select:-webkit-autofill,
.idt-city-input__search select:-webkit-autofill:hover,
.idt-city-input__search select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

.idt-city-input__timezone {
  flex: 1;
  margin: 0;
  background-color: #fff;
  border-left: none;
  border-radius: var(--global-radius);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

