.idt-profile-upload-file {
  position: relative;
}

.idt-profile-upload-file__hint {
  font-size: .75rem;
  font-style: italic;
  margin-top: .5rem;
}

.idt-profile-upload-file__remove {
  position: absolute;
  top: 1px;
  right: 1px;
  height: 38px;
}

