




































































































@import '@/styles/mixins/index';

.ticketing-blocking-modal-header {
  flex: 0 0 auto;
  position: relative;
  height: var(--tk-modal-header-height);
  color: var(--white);
  background-color: var(--main-color-darkest);
  overflow: hidden;

  &__infos {
    display: flex;
    justify-content: space-between;
    height: var(--tk-modal-header-height);

    @include respond-to(max-medium) {
      padding: 10px 10px 5px;
    }
    @include respond-to(medium) {
      padding: 1.25rem 1.5rem;
    }
    @include respond-to(max-xlarge) {
      flex-direction: column;
    }

    &__user {
      display: flex;
      align-items: center;

      &__avatar {
        @include respond-to(max-medium) {
          width: 35px !important;
          height: 35px !important;
        }
        & ::v-deep .idt-user-avatar__initial {
          @include respond-to(max-medium) {
            font-size: 30px !important;
            line-height: 35px !important;
          }
        }
      }
      &__text {
        max-width: 50vw;
        padding: 0 5px;

        &__name {
          width: 100%;
          font-family: var(--title-font-family);
          line-height: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @include respond-to(medium-xlarge) {
            font-size: 1.25rem;
          }
          @include respond-to(xlarge) {
            font-size: 1.75rem;
          }
        }
        &__role {
          width: 100%;
          font-weight: var(--font-weight-light);
          line-height: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @include respond-to(max-medium) {
            font-size: 0.75rem;
          }
          @include respond-to(medium-xlarge) {
            font-size: 1.25rem;
          }
          @include respond-to(xlarge) {
            font-size: 1.75rem;
          }
        }
      }
    }
    &__availability {
      &__title {
        line-height: 30px;
      }
      &__number {
        font-size: 2.375rem;

        strong {
          font-weight: var(--font-weight-medium);
        }
      }
    }
    &__event {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to(xlarge) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 145px;
        padding-right: 1.875rem;
        border-right: 1px solid var(--white);
      }

      &__title {
        flex: 1 1 auto;
        padding-right: 0.5rem;
        font-weight: var(--font-weight-light);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include respond-to(medium) {
          font-size: 1.25rem;
        }
        @include respond-to(max-xlarge) {
          max-width: 75vw;
        }
        @include respond-to(xlarge) {
          max-width: 35vw;
        }
      }
      &__timer {
        flex: 0 0 auto;
        font-family: var(--title-font-family);
      }
    }
  }
}
