



























.idt-survey-pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--rem-size-16);

  &__chip {
    width: 9px;
    height: 9px;
    margin: 0 6px 6px 0;
    background-color: var(--grey-light);
    border-radius: 50%;
    cursor: pointer;

    &.active {
      background-color: var(--main-color);
    }
  }
}
