.idt-code-editor {
  height: 300px;
  background-color: var(--grey-lightest);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
}

.idt-code-editor ::v-deep .prism-editor__container,
.idt-code-editor ::v-deep .prism-editor__editor {
  min-height: 298px;
}

