.ticketing-upgrade-modal {
  width: 100%;
  padding: 2.5rem 1rem 4.25rem;
}

.ticketing-upgrade-modal__title {
  color: var(--grey-darkest);
  font-weight: var(--font-weight-light);
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .ticketing-upgrade-modal__title {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 640px) {
  .ticketing-upgrade-modal__title {
    font-size: 2.375rem;
  }
}

.ticketing-upgrade-modal__title:after {
  content: "";
  display: block;
  position: absolute;
  width: 83px;
  left: 50%;
  margin-top: 28px;
  border-bottom: solid var(--main-color);
  transform: translateX(-50%);
}

@media only screen and (max-width: 639px) {
  .ticketing-upgrade-modal__title:after {
    margin-top: 9px;
    border-bottom-width: 1px;
  }
}

@media only screen and (min-width: 640px) {
  .ticketing-upgrade-modal__title:after {
    margin-top: 28px;
    border-bottom-width: 3px;
  }
}

@media only screen and (max-width: 639px) {
  .ticketing-upgrade-modal__tickets {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 640px) {
  .ticketing-upgrade-modal__tickets {
    margin-top: 40px;
  }
}

