.idt-tag {
  padding: 0 10px;
  margin-bottom: 5px;
  color: var(--main-color-dark);
  background: var(--main-color-lighter);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.idt-tag.clickable {
  cursor: pointer;
}

.idt-tag.clickable:hover,
.idt-tag.selected {
  color: var(--main-color-lighter);
  background-color: var(--main-color-darker);
}

.idt-tag ::v-deep .ideacon {
  margin-left: var(--rem-size-2);
  vertical-align: middle;
}

