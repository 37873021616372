.froala-container .fr-toolbar:not(.fr-sticky-on) {
  top: 0px !important;
}

.froala-container .fr-toolbar .fr-autoplay-margin {
  display: none;
}

.froala-container:not(.froala-container--sticky) .fr-toolbar.fr-sticky-on {
  z-index: 0;
}

.froala-container--sticky .fr-toolbar.fr-sticky-on {
  top: 70px !important;
}

.froala-container [id*=paragraphFormat] h1 {
  font-family: var(--title-font-family);
  font-size: 1.2rem;
}

.froala-container [id*=paragraphFormat] p {
  font-family: var(--text-font-family);
  font-size: 1rem;
}

.fr-view {
  color: var(--grey-darker);
  font-weight: var(--font-weight-normal);
}

.fr-view h1 {
  font-family: var(--title-font-family);
  font-size: 2.25rem;
  font-weight: var(--font-weight-medium);
}

.fr-view h1 strong {
  font-weight: var(--font-weight-bold);
}

.fr-view hr {
  margin: .625rem auto;
}

.fr-view p {
  margin: .625rem auto;
  font-family: var(--text-font-family);
}

.fr-view p strong {
  font-weight: var(--font-weight-medium);
}

.tpl-sponsors__title {
  color: var(--black);
  font-size: var(--rem-size-35);
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  text-align: left;
}

.tpl-sponsors__subtitle {
  font-size: var(--rem-size-24);
  font-family: var(--title-font-family);
}

.tpl-sponsors__description {
  font-size: var(--rem-size-16);
  font-family: var(--text-font-family);
}

.tpl-sponsors__image {
  width: 100%;
}

.tpl-sponsors-ressources {
  display: flex;
  align-items: center;
  margin-bottom: var(--rem-size-16);
  background-color: var(--white);
}

@media only screen and (max-width: 639px) {
  .tpl-sponsors-ressources {
    flex-flow: column wrap;
  }
}

.tpl-sponsors-ressources__img {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 639px) {
  .tpl-sponsors-ressources__img {
    width: 100%;
    padding: 0 var(--rem-size-24);
  }
}

.tpl-sponsors-ressources__img img {
  width: 200px;
  border-radius: var(--global-radius) 0 0 var(--global-radius);
}

@media only screen and (max-width: 639px) {
  .tpl-sponsors-ressources__img img {
    width: 100%;
    border-radius: var(--global-radius) var(--global-radius) 0 0;
  }
}

.tpl-sponsors-ressources__info {
  flex: 1 1 0;
  padding: 0 var(--rem-size-24);
}

.tpl-sponsors-ressources__title {
  color: var(--black);
  font-size: var(--rem-size-24);
  font-family: var(--title-font-family);
  font-weight: var(--font-weight-bold);
}

.tpl-sponsors-ressources__desc p {
  margin-bottom: 0;
  color: var(--black);
  font-size: var(--rem-size-16);
  font-family: var(--text-font-family);
  font-weight: var(--font-weight-normal);
  line-height: var(--rem-size-20);
}

.tpl-sponsors-ressources__link {
  flex: 0 1 auto;
  color: var(--main-color);
  font-size: var(--rem-size-16);
  font-family: var(--text-font-family);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

