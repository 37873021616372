















































































































































































































































































































.bo-lobby-crud {
  .bo-page__content {
    overflow-x: auto;
  }
  .bo-lobby-button {
    background-color: var(--main-color) !important;
    color: var(--white) !important;
    border: 0 !important;
    font-weight: var(--font-weight-bold);

    .idt-icon {
      display: inline-flex;
      opacity: .6;
      margin-right: var(--rem-size-8);
    }
    svg {
      transform: rotate(-45deg);
    }
  }
}
