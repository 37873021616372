





























































































































































































































































































.update-user-modal {
  height: calc((var(--vh, 1vh) * 100) - (var(--menu-height) + var(--rem-size-64)));
  margin-top: var(--menu-height);
  padding: var(--rem-size-16);
  overflow-y: auto;

  &__password-generation {
    display: flex;
    align-items: stretch;
    height: 48px;
    margin-bottom: var(--rem-size-4);

    &__help {
      margin-bottom: var(--rem-size-24);
      font-size: var(--rem-size-12);
      font-style: italic;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--rem-size-8);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--rem-size-64);
    padding: var(--rem-size-16);
    border-top: 1px solid var(--grey-lighter);
    background-color: var(--white);
  }
  ::v-deep .bo-form-section-separator {
    margin-bottom: var(--rem-size-16);
  }
}
