.idt-fo-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white);
  border-radius: var(--global-radius);
  overflow: hidden;
}

.idt-fo-card.has-radius {
  border-radius: var(--global-radius);
}

.idt-fo-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
}

.idt-fo-card__content {
  padding: 0 1rem;
}

.idt-fo-card__footer {
  display: flex;
  align-items: center;
  font-size: var(--rem-size-16);
  border-top: 1px solid var(--grey);
  padding: 0 1rem;
  margin-top: 1rem;
  height: 50px;
}

