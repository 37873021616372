























































































































.idt-profile-custom {
  display: flex;
  flex-flow: row wrap;

  .idt-profile__input-section {
    flex: 1 1 100%;
    margin: 10px 0;
    padding: 20px;
    background-color: var(--main-color-lightest);
    border-radius: 15px;

    &__input {
      margin-bottom: 0;
    }
  }

  &__choice-list {
    margin-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      list-style-type: none;

      input[type="checkbox"], input[type="radio"] {
        height: 20px;
        width: 20px;
      }
    }
  }

  .idt-profile__input-section__input > label {

    & > p {
      margin-bottom: 10px;
      word-break: break-all;
    }

    &.required {
      &::after {
        display: none;
      }

      & > p:first-child {
        &::after{
          content: ' *';
        }
      }
    }
  }

  .idt-radiobutton, .idt-checkbox {
    --text-color: var(--black);
    background: none;

    input + label .box {
      background-color: var(--white);
      border-color: var(--grey-dark);
    }
  }

  .idt-textarea-container {
    padding-bottom: .5rem !important;
  }

  .idt-select-container {
    padding-bottom: 0;

    .v-select {
      background-color: var(--white);
    }
  }
  .idt-profile-upload-file {
    margin-top: 1rem;
  }
}
