.faq {
  &__title {
    margin: 0;
    padding: 40px 20px;
    color: $white;
    background-color: var(--main-color);
    font-weight: 300;
    text-align: center;
  }
  &__section {
    &--cat {
      padding: 40px 0 0 0;
      background-color: var(--grey-lighter);
    }
    &--answers {
      background-color: $white;
    }
  }

  &__cat {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 54px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 2px 2px 0 var(--main-color-alpha10),0 3px 1px -2px var(--main-color-alpha20),0 1px 5px 0 var(--main-color-alpha10), 0 0 0 2px var(--main-color) inset;
    }

    &__title {
      margin: 0;
      padding: 20px 20px 10px 20px;
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
    }
    &__list {
      margin: 0 20px;
      padding: 10px 0 20px 20px;
      border-top: 1px solid var(--grey-light);
    }
    li {
      font-size: 16px;
      line-height: 18px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:hover {
        color: var(--main-color);
      }

      a {
        display: block;
        cursor: pointer;

        &:hover {
          color: var(--main-color);
        }
      }
    }
    &__link {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% - 80px);
      padding: 5px 10px;
      color: $white;
      background-color: var(--main-color);
      border: 2px solid var(--main-color);
      border-radius: 3px;
      text-align: center;
      cursor: pointer;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;

      &:hover {
        color: var(--main-color);
        background-color: transparent;
      }
    }
  }
  &__answers {
    margin-bottom: 20px;
    padding-top: 20px;

    &__title {
      margin: 0 0 20px 0;
      padding-top: 20px;
      color: var(--main-color);
      font-size: 24px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  &__answer {
    &__question {
      margin: 0 0 5px 0;
      padding: 22px 0  5px 0;
      border-bottom: 1px solid var(--grey-light);
      font-size: 18px;
    }
  }
}
