.tooltip {
  display: block !important;
  max-width: 300px;
  padding: 0;
  background: transparent;
  z-index: 1000001;
}

.tooltip::before {
  border: none;
}

.tooltip .tooltip-inner {
  padding: var(--rem-size-5) var(--rem-size-10) var(--rem-size-4);
  color: white;
  background: var(--grey-darkest);
  border-radius: var(--global-radius);
}

.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  margin: var(--rem-size-5);
  border-style: solid;
  border-color: var(--grey-darkest);
}

.tooltip[x-placement^="top"] {
  margin-bottom: var(--rem-size-5);
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  bottom: -5px;
  right: calc(50% - var(--rem-size-10));
  margin-top: 0;
  margin-bottom: 0;
  border-width: var(--rem-size-5) var(--rem-size-5) 0 var(--rem-size-5);
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.tooltip[x-placement^="bottom"] {
  margin-top: var(--rem-size-5);
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  top: -5px;
  right: calc(50% - var(--rem-size-10));
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0 var(--rem-size-5) var(--rem-size-5) var(--rem-size-5);
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

.tooltip[x-placement^="right"] {
  margin-left: var(--rem-size-5);
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  left: -5px;
  top: calc(50% - var(--rem-size-10));
  margin-left: 0;
  margin-right: 0;
  border-width: var(--rem-size-5) var(--rem-size-5) var(--rem-size-5) 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.tooltip[x-placement^="left"] {
  margin-right: var(--rem-size-5);
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  right: -5px;
  top: calc(50% - var(--rem-size-10));
  margin-left: 0;
  margin-right: 0;
  border-width: var(--rem-size-5) 0 var(--rem-size-5) var(--rem-size-5);
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.arrow-right .tooltip-arrow {
  left: unset !important;
  right: var(--rem-size-10) !important;
}

.arrow-left .tooltip-arrow {
  left:  var(--rem-size-10) !important;
  right: unset !important;
}
