.idt-ticket-payment-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-payment-card {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh)*100);
    background-color: var(--white);
    overflow-y: auto;
    z-index: 1010;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-payment-card {
    height: 100%;
  }
}

.idt-ticket-payment-card__form {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  position: relative;
  backface-visibility: hidden;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-payment-card__form {
    padding: 20px 1.5rem 0;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-payment-card__form {
    border: 3px solid var(--grey-lighter);
    border-radius: var(--global-radius) var(--global-radius) 0 0;
  }
}

.idt-ticket-payment-card__form:not(.no-button) {
  border-bottom: none;
}

.idt-ticket-payment-card__form * {
  backface-visibility: hidden;
}

.idt-ticket-payment-card__form__close {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  z-index: 10;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-payment-card__form__close {
    top: .5rem;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-payment-card__form__close {
    top: 2rem;
  }
}

.idt-ticket-payment-card__button {
  flex: 0 0 auto;
  width: 100%;
  color: var(--white);
  background-color: var(--main-color);
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-payment-card__button {
    height: 60px;
    border-radius: 0;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-payment-card__button {
    height: 50px;
    border-radius: 0px 0px var(--global-radius) var(--global-radius);
  }
}

.idt-ticket-payment-card ::v-deep .payment-form__container {
  flex: 1 1 auto;
  padding-top: 1.5rem;
}

