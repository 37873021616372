






































































.menu-ticket-info {
  padding: var(--rem-size-5) 1rem;
  background-color: var(--grey-lightest);
  text-align: center;

  &__title {
    margin-bottom: 0.625rem;
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-bold);
    text-align: center;
    text-transform: uppercase;
  }
  &__credit-list {
    display: flex;
    justify-content: space-around;
  }
}
