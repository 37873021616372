























































































































































































































































































































@import '@/styles/mixins/index';

.map-chart {
  &__bar {
    text {
      font-size: var(--rem-size-14);
    }
    line:not(.last) {
      stroke: var(--grey);
    }
    &.hoverMainG > g:not(.hoverG) {
      opacity: 0.5;
    }
  }
  &__map {
    g:hover > path:not(:hover) {
      opacity: 0.5;
      stroke-opacity: 0;
    }
    g.hoverG > path:not(.hoverPath) {
      opacity: 0.5;
      stroke-opacity: 0;
    }
    g.hoverG > path.hoverPath {
      stroke: var(--main-color-darkest) !important;
      fill: var(--main-color-darkest) !important;
    }
  }
  .column {
    padding: 0;
  }
}
