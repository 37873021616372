.idt-breadcrumb {
  display: flex;
  align-items: center;
  margin: 0;
}

.idt-breadcrumb__item {
  list-style-type: none;
  margin-right: 10px;
  color: var(--black-alpha-50);
  font-weight: var(--font-weight-bold);
}

.idt-breadcrumb__item:first-child {
  color: var(--black);
}

.idt-breadcrumb__item .ideacon.idea-ht-chevron-right {
  position: relative;
  top: 2px;
  margin-right: 6px;
  stroke-width: 1;
}

