








































@import '@/styles/mixins/index';

.event-description {
  @include respond-to(max-medium) {
    padding: 2rem 1rem;
  }
  @include respond-to(medium-large) {
    padding: 4rem 1rem;
  }
  @include respond-to(large) {
    padding: 5rem 3rem;
  }

  &__title {
    margin-top: 0;
    font-size: 2.1875rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  &__text {
    margin: 0.625rem 0.625rem;
    text-align: justify;

    blockquote {
      margin: 0.625rem 1.25rem;
      padding: 0 0.625rem;
      border-left: 1px solid var(--grey);
      p {
        margin: 0.3125px auto;
        color: var(--grey-darker);
        font-weight: var(--font-weight-light);
      }
    }

    @include respond-to(max-large) {
      margin-bottom: 2rem
    }
  }
}
