.idt-table-cell-link {
  display: block;
}

.idt-table-cell-link>a {
  display: inline-block;
  max-width: 100%;
  height: 2rem;
  line-height: 2.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

