




















.idt-badge {
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  top: -0.5rem;
  right: -0.5rem;
  z-index: 1;
  min-width: 1rem;
  height: 1rem;
  padding: 1px 2px;
  border-radius: 50px;
  background-color: var(--main-color);

  &__text {
    position: relative;
    color: var(--white);
    font-family: var(--title-font-family);
    font-size: 10px;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }
}
