

































.event-hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;

  img {
    width: 100%;
    max-width: none;
  }
}
