.idt-ticket-carousel {
  width: 100%;
}

.idt-ticket-carousel ::v-deep .slick-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-prev {
  flex: 1 1 auto;
  margin-right: 25px;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-next {
  flex: 1 1 auto;
  margin-left: 25px;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-disabled {
  opacity: .3;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-list {
  flex: 1 1 auto;
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-carousel ::v-deep .slick-slider .slick-list .slick-slide {
    padding: 0 5px;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-carousel ::v-deep .slick-slider .slick-list .slick-slide {
    padding: 0 10px;
  }
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  list-style-type: none;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-carousel ::v-deep .slick-slider .slick-dots {
    margin: .5rem 0 0 0;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-carousel ::v-deep .slick-slider .slick-dots {
    margin: 0;
  }
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-dots li {
  position: relative;
  width: 30px;
  height: 20px;
  padding: 0;
  cursor: pointer;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-dots li button {
  display: block;
  width: 30px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.idt-ticket-carousel ::v-deep .slick-slider .slick-dots li button:before {
  content: "•";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  color: var(--main-color);
  font-family: "slick";
  font-size: 2rem;
  line-height: 1;
  transform: translate(-50%, -50%);
  opacity: .5;
}

.idt-ticket-carousel ::v-deep .slick-slider .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border: solid 1px var(--main-color);
  border-radius: 50%;
}

.idt-ticket-carousel ::v-deep .slick-slider .arrow .ideacons {
  color: var(--main-color);
  font-size: 1.25rem;
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .idt-ticket-carousel.register ::v-deep .slick-slider .slick-list {
    max-width: 560px;
  }
}

@media only screen and (min-width: 480px)and (max-width: 1199px) {
  .idt-ticket-carousel.register ::v-deep .slick-slider .slick-list {
    width: calc(100vw - 242px);
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-carousel.register ::v-deep .slick-slider .slick-list {
    max-width: 1120px;
  }
}

.idt-ticket-carousel.blocking ::v-deep .slick-slider {
  flex-flow: column wrap;
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .idt-ticket-carousel.blocking ::v-deep .slick-slider .slick-list {
    flex: 0 0 auto;
    max-width: 600px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-carousel.blocking ::v-deep .slick-slider .slick-list {
    flex: 1 1 auto;
    max-width: 950px;
  }
}

.show-payment-enter-active,
.show-payment-leave-active {
  transition: all .3s ease;
}

.show-payment-enter,
.show-payment-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

