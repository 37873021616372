

































































































































































































































.idt-survey-card-admin {
  position: relative;
  width: 100%;
  max-width: 420px;
  padding: 1rem;
  border: 1px solid #cacaca;
  background: var(--white);
  border-radius: var(--global-radius);

  &__duration {
    .progress {
      --progress-color: var(--main-color);

      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      height: 7px;
      margin: 0;
      border-radius: var(--global-radius) var(--global-radius) 0 0;
      overflow: hidden;

      &.danger {
        --progress-color: var(--danger-color);
      }
    }
  }

  &__header {
    position: relative;
    padding-left: 33px;

    &__icon ::v-deep .ideacon {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -10px;
      color: var(--main-color);
    }

    &__status {
      font-size: var(--rem-size-12);
    }

    &__question {
      line-height: 1rem;
      font-size: var(--rem-size-18);
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }

    &__button-menu {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .idt-survey-card-admin-menu {
      position: absolute;
      right: 0;
      top: 1rem;
    }
  }

  &__content {
    &__results {
      padding-top: .5rem;
      border-bottom: 1px solid var(--grey-light);

      &:not(.results-mode) {
        pointer-events: none;
      }

      &__title {
        margin-top: var(--rem-size-28);
        margin-bottom: var(--rem-size-18);
        font-size: var(--rem-size-18);
        font-weight: var(--font-weight-bold);
        color: var(--black);
      }
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;

      &--actions {
        flex-direction: row-reverse;
      }
      &__duration {
        margin: 0;
        font-size: var(--rem-size-12);
        color: var(--black);
      }

      &__votes {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: .5rem;
        font-size: var(--rem-size-12);
        color: var(--black);
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    height: 21px;
    left: 1rem;
    bottom: 1rem;

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 21px;
      width: 21px;
      background: var(--grey-lighter);
      cursor: pointer;
    }
  }
}
