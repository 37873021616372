.lobby-info__title {
  margin-bottom: var(--rem-size-16);
  padding-top: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
}

.lobby-info__load-more {
  width: 100%;
  padding: var(--rem-size-9) var(--rem-size-20);
  color: var(--black);
  font-weight: var(--font-weight-bold);
  text-align: center;
  cursor: pointer;
}

