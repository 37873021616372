























































































@import '@/styles/mixins/index';

.bo-page-statistics {
  position: relative;

  @include respond-to(max-xlarge) {
    height: var(--bo-header-elm-height);
    padding: 0 20px 0 30px;
    background-color: var(--white);
  }
  @include respond-to(xlarge) {
    height: var(--bo-header-height);
  }
  &__toggle {
    display: flex;
    align-items: center;
    color: var(--grey-darkest);
    cursor: pointer;

    @include respond-to(max-xlarge) {
      height: var(--bo-header-elm-height);
    }
    @include respond-to(xlarge) {
      height: var(--bo-header-height);
    }
    &__label {
      margin-right: 0.25rem;
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
    }
    &__icon {
      @include respond-to(max-xlarge) {
        display: none;
      }
    }
  }
  &__header {
    @include respond-to(max-xlarge) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--rem-size-10) var(--rem-size-16);

      &__title {
        color: var(--grey-dark);
        font-size: var(--rem-size-18);
        font-weight: var(--font-weight-medium);
        text-transform: uppercase;
      }
      &__close {
        color: var(--grey-dark);
      }
    }
    @include respond-to(xlarge) {
      display: none;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    @include respond-to(max-medium) {
      top: calc(var(--bo-header-height));
      left: 0;
      width: 100%;
      box-shadow: 0 3px 6px var(--black-alpha12);
    }
    @include respond-to(medium-xlarge) {
      top: calc(var(--menu-height) + var(--bo-header-height));
      left: var(--vertical-menu-width);
      width: calc(100vw - var(--vertical-menu-width));
    }
    @include respond-to(max-xlarge) {
      position: fixed;
      height: calc((var(--vh) * 100) - (var(--menu-height) + var(--bo-header-height)));
    }
    @include respond-to(xlarge) {
      position: absolute;
      min-width: 185px;
      top: 100%;
      right: 0;
      box-shadow: 0 3px 6px var(--black-alpha12);
    }
    &.slide-content-enter-active {
      animation: slide-down 0.3s;
    }
    &.slide-content-leave-active {
      animation: slide-down 0.3s reverse;
    }
  }
  &__list {
    margin: 0;
    padding: var(--rem-size-1) var(--rem-size-8);
  }
  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--rem-size-7) var(--rem-size-8) var(--rem-size-3) var(--rem-size-8);

    &:not(:last-child) {
      border-bottom: 1px dashed var(--grey-light);
    }
    &__number {
      color: var(--main-color);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-medium);
      line-height: var(--rem-size-24);
      text-align: right;

      @include respond-to(max-xlarge) {
        min-width: 30%;
      }
    }
    &__label {
      color: var(--grey-darkest);
      font-size: var(--rem-size-12);
      line-height: var(--rem-size-18);
      white-space: nowrap;
    }
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
