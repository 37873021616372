.fo-lobby-header {
  display: flex;
  align-items: flex-start;
  flex-flow: nowrap;
  gap: var(--rem-size-16);
  width: 100%;
  padding: var(--rem-size-16) var(--rem-size-24);
  border-bottom: 1px solid var(--grey-dark-alpha-50);
}

@media only screen and (max-width: 991px) {
  .fo-lobby-header {
    flex-flow: wrap;
  }
}

.fo-lobby-header__buttons {
  display: flex;
  gap: var(--rem-size-8);
  flex-shrink: 0;
}

.fo-lobby-header__buttons .fo-lobby-btn {
  height: 36px;
  font-size: var(--rem-size-14);
}

@media only screen and (max-width: 991px) {
  .fo-lobby-header__buttons {
    width: 100%;
    justify-content: space-between;
  }

  .fo-lobby-header__buttons .fo-lobby-header__more-menu {
    display: none;
  }
}

.fo-lobby-header__title-wrapper {
  display: flex;
  flex-flow: column wrap;
  flex: auto;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .fo-lobby-header__title-wrapper {
    padding-top: var(--rem-size-8);
  }
}

.fo-lobby-header__title-wrapper>* {
  width: 100%;
}

.fo-lobby-header__title {
  display: flex;
  align-items: center;
  gap: var(--rem-size-8);
  position: relative;
  margin-bottom: 0;
  color: var(--grey-darkest);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-24);
}

.fo-lobby-header__title__text {
  flex: auto;
}

.fo-lobby-header__title .lobby-icon {
  line-height: var(--rem-size-16);
}

@media only screen and (max-width: 991px) {
  .fo-lobby-header__title .lobby-icon,
  .fo-lobby-header__title .lobby-icon .idt-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
  }
}

.fo-lobby-header__title .lobby-icon .ideacon {
  color: var(--grey-darker);
  line-height: var(--rem-size-10);
}

@media only screen and (max-width: 991px) {
  .fo-lobby-header__title .lobby-icon .ideacon {
    width: var(--rem-size-12) !important;
    height: var(--rem-size-12) !important;
  }
}

.fo-lobby-header__show-left-panel,
.fo-lobby-header__show-right-panel {
  display: none;
  width: var(--rem-size-20);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: var(--rem-size-4) 0;
}

@media only screen and (max-width: 991px) {
  .fo-lobby-header__show-left-panel,
  .fo-lobby-header__show-right-panel {
    display: flex;
  }
}

.fo-lobby-header__show-left-panel {
  transform: translateX(-5px);
}

.fo-lobby-header__show-right-panel {
  transform: translateX(5px);
}

.fo-lobby-header .read-more {
  margin: var(--rem-size-4) 0;
}

.fo-lobby-header__description {
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
  color: var(--grey-darkest-alpha75);
}

.fo-lobby-header__more-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  flex-shrink: 0;
  position: relative;
  width: var(--rem-size-36);
  height: var(--rem-size-36);
  color: var(--main-color-dark);
  background-color: var(--main-color-dark-alpha20);
  border-radius: 100%;
  transition: background-color .1s ease;
  cursor: pointer;
  z-index: 2;
}

.fo-lobby-header__more-menu:hover {
  background-color: var(--main-color-dark-alpha40);
}

.fo-lobby-header__more-menu .ideacon {
  transform: rotate(-90deg);
}

.fo-lobby-header__more-menu__menu {
  position: absolute;
  bottom: calc(-1*var(--rem-size-8));
  right: 0;
  background: var(--white);
  display: flex;
  flex-flow: column wrap;
  transform: translateY(100%);
  border: 1px solid var(--grey-dark-alpha-50);
  border-radius: var(--global-radius);
}

.fo-lobby-header__more-menu__menu>* {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--black);
  width: 100%;
  min-height: var(--rem-size-36);
  white-space: nowrap;
  padding: var(--rem-size-8) var(--rem-size-12);
  border-bottom: 1px solid var(--black-alpha10);
  transition: background-color .1s ease-in-out;
}

.fo-lobby-header__more-menu__menu>*:hover {
  color: var(--black);
  background-color: var(--grey);
}

.fo-lobby-header__more-menu__menu>:last-child {
  border-bottom: 0;
}

.fo-lobby-header .contextual-menu-fade-enter,
.fo-lobby-header .contextual-menu-fade-leave-to {
  opacity: 0;
  bottom: 0;
}

.fo-lobby-header .contextual-menu-fade-enter-to,
.fo-lobby-header .contextual-menu-fade-leave {
  opacity: 1;
  bottom: calc(-1*var(--rem-size-8));
}

.fo-lobby-header .contextual-menu-fade-enter-active,
.fo-lobby-header .contextual-menu-fade-leave-active {
  transition: opacity .15s ease,bottom .15s ease;
}

