












































@import '@/styles/mixins/index';

.bo-tips {
  position: relative;
  margin-bottom: var(--rem-size-32);

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(var(--rem-size-8) * -1);
    height: 100%;
    border-left-width: 1px;
    border-left-style: solid;
  }
  &:not(.danger):after {
    border-color: var(--info-color);
  }
  &:not(.danger) &__title {
    color: var(--info-color);
  }
  &.danger:after {
    border-color: var(--danger-color);
  }
  &.danger &__title {
    color: var(--danger-color);
  }
  &__title {
    padding-bottom: var(--rem-size-8);
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-light);
    line-height: var(--rem-size-18);
    text-transform: uppercase;
  }
  &__text {
    color: var(--grey-darkest);
  }
}
