

























































































.fo-project-section-hiring-edit {
  text-align: center;

  &__title {
    margin-bottom: var(--rem-size-16);
    color: black;
  }

  &__no-hiring {
    margin-bottom: var(--rem-size-16);
    color: black;
    text-align: center;
  }

  &__not-hiring {
    display: flex;
    justify-content: center;
    font-size: var(--rem-size-36);
    margin: var(--rem-size-40) 0;
  }
}
