


























































































































































































.live-chat-message-username {
  --admin-color: var(--purple);
  --mentor-color: var(--orange);
  --moderator-color: var(--green-dark);
  --winner-color: var(--yellow);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--rem-size-8);
  font-weight: bold;

  &__respond-icon {
    color: var(--grey-darker);
  }
  &__text {
    &.admin {
      color: var(--admin-color)
    };
    &.moderator {
      color: var(--moderator-color);
    }
    &.winner {
      color: var(--winner-color);
    }
    &.mentor {
      color: var(--mentor-color);
    }
  }
  &__container {
    display: flex;
    align-items: center;
    gap: var(--rem-size-4);
    line-height: 16px;

    &__role-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      color: var(--white);
      border-radius: 2px;
      text-align: center;

      &.admin {
        background-color: var(--admin-color);
      }
      &.mentor {
        background: var(--mentor-color);
      }
      &.moderator {
        background: var(--moderator-color);
        font-size: 10px;
      }
      &.winner {
        background: var(--winner-color);
        font-size: 10px;
      }
    }
  }
  .role-tag {
    padding: 0 12px;
    background-color: var(--main-color-dark-alpha20);
    color: var(--main-color-dark);
    border-radius: var(--global-radius);
  }
  .clickable {
    cursor: pointer;
  }
}
