::v-deep .idt-dropdown--pane {
  padding: 0;
}

.days-container__header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: var(--rem-size-10);
}

.days-container__header__buttons__button {
  margin: 7px;
  color: var(--grey-darker);
}

.days-container__header__buttons__button:hover {
  cursor: pointer;
}

.days-container__header__label {
  color: var(--black);
  font-size: var(--rem-size-15);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-24);
}

.days-container__calendar {
  background-color: var(--white);
  border: 1px solid var(--grey-lighter);
  z-index: 100;
}

.days-container__dropdown {
  width: 100%;
}

.days-container__input {
  display: flex;
  flex-flow: wrap;
  max-width: 100%;
  margin-bottom: var(--rem-size-25);
}

.days-container__input:hover {
  cursor: pointer;
}

.days-container__input__days {
  display: flex;
  flex-flow: wrap;
}

.days-container__input.on-edition {
  flex-flow: row;
  align-items: baseline;
  justify-content: space-between;
  max-width: 100%;
  min-height: 38px;
  padding-left: 5px;
  border: solid 1px var(--grey);
  border-radius: var(--global-radius);
}

