





















































































































































































.live-chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  background-color: var(--white);
  z-index: 2;

  &.only-tab {
    height: 100%;
  }

  &.viewer-count {
    height: calc(100% - 113px);
  }

  &.only-tab.viewer-count {
    height: calc(100% - 38px);
  }

  // Pinned message
  &__pinned-message {
    border-left: 3px solid var(--main-color);
    box-shadow: 0px 3px 6px var(--grey);
    z-index: 2;

    & .live-chat-message {
      height: auto;
      max-height: 300px;
      padding: 10px 17px;
      overflow-y: auto;
    }
  }

  // Messages
  &__messages {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px 10px 0px 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__messages > :first-child {
    margin-top: auto;
  }

  &__notification-new-message {
    position: relative;

    &__button {
      position: absolute;
      height: 13px;
      bottom: 10px;
      left: 10px;
      padding: 0 8px;
      background: var(--main-color);
      color: var(--white);
      font-weight: bold;
      font-size: 10px;
      border-radius: 23px;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 1;
    }
  }
}
