










































































@import '@/styles/mixins/index';

.gamification-leaderboard-row {
  display: flex;
  flex-direction: row;
  height: 70px;
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  &__position{
    color: var(--grey);
    font-size: var(--rem-size-16);
    font-weight: bold;
    margin: auto 1rem;
    text-align: center;
    width: 2rem;

    @include respond-to(max-slarge) {
      margin: auto 0;
    }
  }
  &__avatar {
    margin: auto;
    cursor: pointer;

    &.idt-user-avatar {
      @include respond-to(max-slarge) {
        width: 30px !important;
        height: 30px !important;
      }
    }
    & ::v-deep .idt-user-avatar__initial {
      @include respond-to(max-slarge) {
        font-size: 20px !important;
        line-height: 30px !important;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 6;
    width: 0;
    cursor: pointer;

    @include respond-to(max-slarge) {
      margin: auto 0;
      padding-left: 0.5rem;
    }
    @include respond-to(slarge) {
      padding: 0.5rem 0 0.5rem 1rem;
    }
    &__title {
      width: 100%;
      color: var(--black);
      font-weight: var(--font-weight-bold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include respond-to(max-slarge) {
        font-size: var(--rem-size-16);
      }
      @include respond-to(slarge) {
        font-size: var(--rem-size-18);
      }
    }
    &__description {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include respond-to(max-slarge) {
        font-size: var(--rem-size-12);
      }
      @include respond-to(slarge) {
        font-size: var(--rem-size-14);
      }
    }
  }
  .gamification-points {
    margin: auto 1rem;

    @include respond-to(max-slarge) {
      & ::v-deep sup {
        display: none;
      }
    }
  }
  &.active {
    background: var(--main-color-gradient);
    color: var(--white);
    .gamification-leaderboard-row {
      &__position,
      &__content__title {
        color: var(--white);
      }
    }
  }
}
