










































































.gamification-notifications {
  position: absolute;
  right: 0;
  top: 50px;
  transition: display 3s ease-in-out;
  &.hide {
    display: none;
  }
  .notification {
    display: flex;
    margin-top: 1rem;
    text-align: center;
    vertical-align: middle;
    color: var(--white);
    font-size: var(--rem-size-16);
    transform: translateX(1000px);
    transition: 1s;

    &.slideIn {
      transform: translateX(0px);
    }
    &__label {
      width: 200px;
      padding: 1rem 0.5rem;
      font-weight: var(--font-weight-bold);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &.reward {
      .notification__label {
        margin-left: auto;
        background-color: var(--main-color);
        border-radius: 50px;
      }
      .notification__points {
        display: none;
      }
    }
    &.setting {
      .notification__label {
        background-color: var(--yellow);
        border-radius: 50px 0 0 50px;
      }
      .notification__points {
        display: flex;
        width: 100px;
        padding: 1rem 0.5rem;
        color: var(--yellow);
        background-color: var(--white);
        border-radius: 0 50px 50px 0;
        font-weight: var(--font-weight-bold);

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        img {
          height: 14px;
          width: 17px;
          margin-right: 0.25rem;
        }
      }
    }
  }
}
