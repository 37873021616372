.fo-project-section-edit {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  max-width: 1160px;
  min-height: 390px;
  margin: auto;
  padding: 0;
}

.fo-project-section-edit:not(:last-child) {
  border-bottom: 2px dashed var(--grey);
}

.fo-project-section-edit__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  background-color: var(--white-alpha80);
  z-index: 7;
}

.fo-project-section-edit__block__message-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 33%;
  padding: 4%;
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 9px 0px var(--black-alpha50);
}

.fo-project-section-edit__block__message-box__text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fo-project-section-edit__block__message-box__text-container__icon {
  color: var(--yellow);
}

.fo-project-section-edit__block__message-box__text-container__text {
  margin-left: var(--rem-size-10);
}

.fo-project-section-edit__block__message-box__text-container__text span {
  font-weight: var(--font-weight-bold);
}

.fo-project-section-edit__block__message-box__cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--rem-size-5);
  padding-left: var(--rem-size-10);
  color: var(--white);
  background-color: var(--grey-dark);
  border-radius: 30px;
  transform: translateY(40%);
  cursor: pointer;
}

.fo-project-section-edit__block__message-box__cancel__icon ::v-deep .ideacon {
  margin-left: 7px;
}

.fo-project-section-edit__actions {
  position: sticky;
  user-select: none;
  z-index: 1;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions {
    display: block;
    top: var(--menu-height);
    margin-bottom: var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .fo-project-section-edit__actions {
    display: flex;
    justify-content: flex-end;
    top: calc(var(--menu-height) + 1rem);
    margin: var(--rem-size-16) 0;
    padding: 0 var(--rem-size-48);
  }
}

.fo-project-section-edit__actions__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--grey);
  padding: var(--rem-size-5) var(--rem-size-9) var(--rem-size-5) var(--rem-size-10);
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions__status {
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.fo-project-section-edit__actions__status__avatar {
  position: absolute;
  left: -60px;
}

@media only screen and (min-width: 480px)and (max-width: 639px) {
  .fo-project-section-edit__actions__status__avatar {
    display: none;
  }
}

.fo-project-section-edit__actions__status__title {
  margin-right: var(--rem-size-24);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
}

@media only screen and (min-width: 640px) {
  .fo-project-section-edit__actions__status__title {
    margin-right: var(--rem-size-18);
  }
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions__status__title {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions__status__wording {
    display: none;
  }
}

.fo-project-section-edit__actions__status__icon {
  width: 19px;
  height: 19px;
  margin-left: var(--rem-size-10);
  color: var(--white);
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions__status__icon {
    margin-left: 0;
  }
}

.fo-project-section-edit__actions__widget {
  display: flex;
  justify-content: space-between;
  gap: var(--rem-size-8);
  padding: var(--rem-size-7) var(--rem-size-9) var(--rem-size-7) var(--rem-size-14);
  color: var(--white);
  background-color: var(--grey-darkest);
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions__widget {
    padding: var(--rem-size-8) var(--rem-size-12);
  }
}

@media only screen and (min-width: 640px) {
  .fo-project-section-edit__actions__widget {
    border-radius: var(--rem-size-24);
  }
}

.fo-project-section-edit__actions__widget__update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  font-size: var(--rem-size-13);
}

.fo-project-section-edit__actions__widget__update__wording {
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-13);
}

@media only screen and (max-width: 639px) {
  .fo-project-section-edit__actions__widget__update {
    margin-bottom: 5px;
  }
}

.fo-project-section-edit__actions__widget__buttons {
  display: flex;
  justify-content: end;
  gap: var(--rem-size-8);
}

