.speaker-card {
  width: 100%;
  height: 150px;
  padding: var(--rem-size-20);
  background: var(--white);
  border-radius: var(--global-radius);
  transition: box-shadow .3s ease-in-out;
  overflow: hidden;
}

.speaker-card:hover {
  box-shadow: 0px 2px 5px var(--black-alpha20);
}

.speaker-card .card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
  margin-bottom: var(--rem-size-18);
}

.speaker-card .card-content .speaker-avatar {
  flex: 0 0 auto;
}

.speaker-card .card-content .speaker-info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.speaker-card .card-content .speaker-info__username {
  color: var(--grey-darkest);
  font-family: var(--title-font-family);
  font-weight: var(--font-weight-bold);
  line-height: 1.25rem;
}

.speaker-card .card-content .speaker-info__other {
  font-size: var(--rem-size-12);
  line-height: 1rem;
}

.speaker-card .card-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 35px;
}

.speaker-card .card-footer .button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  border-radius: 0;
  font-weight: bold;
}

.speaker-card .card-footer__button-book {
  flex: 1 0 auto;
  margin-left: var(--rem-size-10);
  text-transform: uppercase;
}

