.idt-messenger-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .idt-messenger-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
}

@media only screen and (min-width: 640px) {
  .idt-messenger-container {
    flex: 1 1 auto;
    position: relative;
  }
}

@media only screen and (min-width: 992px) {
  .idt-messenger-container.conference-mode {
    max-width: calc(100% - 470px);
    margin-right: auto;
  }
}

@media only screen and (max-width: 639px) {
  .idt-messenger-container__room {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
  }
}

@media only screen and (min-width: 640px) {
  .idt-messenger-container__room {
    flex: 0 0 auto;
    position: relative;
    width: 360px;
    margin-left: 1rem;
  }
}

.idt-messenger-container__room .room {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 1px 8px 0 var(--black-alpha10);
  transition: all .3s ease;
  animation: .3s ease openChatRoom;
}

@media only screen and (min-width: 640px) {
  .idt-messenger-container__room .room {
    height: 565px;
    max-height: calc(var(--vh, 1)*100 - var(--menu-height));
    border-radius: 5px 5px 0 0;
  }
}

@media only screen and (max-width: 639px) {
  .idt-messenger-container__room .room.reduce {
    bottom: calc(44px - var(--vh, 1)*100);
  }
}

@media only screen and (min-width: 640px) {
  .idt-messenger-container__room .room.reduce {
    bottom: -521px;
    max-height: 565px;
  }
}

.idt-messenger-container__room .room.reduce .idea-ht-chevron-bottom::before {
  display: inline-block;
  transform: rotate(180deg);
}

.idt-messenger-container__room .room.reduce .room__header:hover {
  cursor: pointer;
}

@keyframes openChatRoom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

