.signin__no-account {
  margin-bottom: var(--rem-size-16);
  text-align: center;
}

.signin__error {
  position: relative;
  top: -15px;
  height: 0;
}

.signin__forgot {
  position: relative;
  text-align: right;
  margin-bottom: 1.5rem;
}

.signin__forgot__button {
  color: var(--main-color);
  font-size: var(--rem-size-14);
  text-decoration: underline;
  cursor: pointer;
}

.signin__mentor {
  text-align: center;
}

.signin__mentor__button {
  cursor: pointer;
}

.signin__no-account__link,
.signin__mentor__button {
  color: var(--grey-darker);
  font-size: var(--rem-size-14);
  text-decoration: underline;
}

