





































































































































.custom-form {
  width: 100%;

  &__message-container {
    width: 100%;
    padding-bottom: 1.875rem;
  }

  &__start-message {
    padding: 1rem;
    background-color: var(--grey-lighter);
    color: var(--grey-darker);
    font-weight: var(--font-weight-medium);
  }

  &__end-message {
    padding: 1rem;
    color: var(--grey-dark);
    font-size: 0.875rem;
  }

  &__field {
    width: 100%;
    margin-bottom: 20px;
    background-color: var(--main-color-lightest);
    border-radius: 15px;

    ::v-deep label {
      color: var(--grey-darker);
      font-size: 0.875rem;
      text-align: left;
      word-break: break-word;
    }
  }
}
