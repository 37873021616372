






































































































































































































































































.fo-comment {
  --gap-size: var(--rem-size-16);
  display: flex;
  gap: var(--gap-size);
  margin: var(--rem-size-24) 0;
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);

  &__avatar {
    flex: 0 0 auto;
  }
  &__side {
    flex: 1;
  }

  &__content {
    position: relative;
    width: fit-content;
    padding: var(--rem-size-8) var(--rem-size-16);
    color: var(--grey-darkest);
    background: var(--grey-lightest);
    border-radius: var(--global-radius);

    &__vote {
      margin-left: calc(var(--rem-size-8) * -1);
      margin-right: var(--rem-size-8);
      padding: var(--rem-size-3) var(--rem-size-8);
      color: var(--white);
      border-radius: var(--global-radius);
      font-weight: var(--font-weight-medium);

      @for $i from 0 through 2 {
        &--color#{$i} {
          background: var(--project-vote-#{$i});
        }
      }
    }

    &__author {
      margin-right: var(--rem-size-8);
      font-weight: var(--font-weight-medium);
    }

    &__likes {
      display: inline-flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: var(--rem-size-3) var(--rem-size-8);
      color: var(--white);
      background: var(--main-color);
      border: var(--rem-size-2) solid var(--white);
      border-radius: 99px;
      font-weight: var(--font-weight-bold);
      line-height: var(--rem-size-18);
      transform: translate(50%, 50%);

      .ideacon {
        padding-right: var(--rem-size-2);
      }
    }
  }

  &__toolbar {
    display: inline-flex;
    flex-wrap: wrap;
    margin: var(--rem-size-8) calc(var(--rem-size-4) * -1);

    button {
      cursor: pointer;
    }

    > span {
      margin: var(--rem-size-4);

      &::after {
        content: '';
        display: inline-block;
        width: var(--rem-size-4);
        height: var(--rem-size-4);
        margin-left: var(--rem-size-8);
        background: var(--grey-light);
        border-radius: 100%;
        vertical-align: middle;
      }

      &:last-child::after {
        display: none;
      }
    }

    &--disabled {
      color: var(--grey-darker);
      cursor: auto;
    }

    &--danger {
      color: var(--red);
    }
    &--selected button {
      color: var(--main-color);
    }
  }

  &__show-more {
    display: block;
    margin: var(--rem-size-4) 0;
    color: var(--main-color);
    cursor: pointer;

    .ideacon {
      margin-right: var(--rem-size-8);
    }
  }

  .fo-comment-input {
    margin-top: var(--rem-size-16);
  }
  &__editing.fo-comment-input {
    // Avatar + gap
    margin: 0 0 0 calc((40px + var(--gap-size)) * -1);
  }
}
