




















































































































































































































.idt-select-container {
  position: relative;
  padding-bottom: 1rem;
  &__add-text {
    margin-right: -10px;
  }
  &__inline-label {
    opacity: 0.5;
  }
  ::v-deep .v-select {
    margin-bottom: 10px;
    &.has-error {
      .vs__dropdown-toggle {
        border: 1px solid var(--secondary-color);
      }
    }
    .vs__search {
      padding: 0 var(--rem-size-8) 0 var(--rem-size-16);
      background-color: transparent;
      border-color: transparent;
      line-height: 2.3;

      &::placeholder {
        color:var(--grey);
      }
    }
    .vs__actions {
      padding-right: 15px;
    }
    &.vs--open {
      .vs__dropdown-toggle {
        border-bottom: 1px solid rgba(60, 60, 60, 0.26);
      }
      .vs__selected {
        position: relative;
      }
      .vs__dropdown-option--highlight:not(.vs__dropdown-option--disabled) {
        background-color: var(--main-color);
      }
    }
    .vs__selected-options {
      .vs__selected {
        padding-left: 15px;
      }
    }
  }
}
::v-deep .idt-required-field {
  position: absolute;
  bottom: 0;
  left: 0;
}
