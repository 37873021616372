





































































































































































.bo-jury-modal {
  padding: var(--rem-size-32) var(--rem-size-16) 0 var(--rem-size-16);

  h4 {
    margin-bottom: var(--rem-size-24);
  }

  &__enabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image-import{
    margin-bottom: var(--rem-size-24);

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6rem;
      height: 6rem;
      background-color: var(--color-white);
      border: 1px dashed var(--main-color);
      border-radius: 50%;
      cursor: pointer;

      &.error {
        border-color: var(--secondary-color);
      }
    }

    &__image {
      border-radius: 50%;

      &:hover {
        opacity: 0.4;
        cursor: pointer;
      }
    }

    &__error {
      color: var(--secondary-color);
      font-size: var(--rem-size-12);
      font-weight: 500;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--rem-size-16);

    button {
      width: 6rem;
      margin-left: var(--rem-size-16);
    }
  }
}
