



























.gamification-section {
  &__title {
    width: 90%;
    margin-bottom: 1rem;
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    color: var(--black);
    overflow: hidden;
    &::after{
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      margin-right: -100%;
      margin-left: 0.5rem;
      border-top: 2px solid var(--grey);
    }
  }
  &__content {
    width: 90%;
  }
}
