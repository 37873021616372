
















































.event-contact {
  position: relative;
  padding: 0;
  background: transparent;
  overflow: hidden;
  z-index: 10;

  &__background {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    -webkit-filter: blur(28px);
      -moz-filter: blur(28px);
        -o-filter: blur(28px);
        -ms-filter: blur(28px);
            filter: blur(28px);
  }
  &__container {
    padding: 5rem 3rem;
    background-color: var(--white-alpha80);
  }
  &__title {
    margin-bottom: 1rem;
    line-height: 1;
  }
  &__email {
    color: var(--grey-darkest);
    font-size: 1.35rem;

    &:hover {
      color: var(--black);
    }
  }
}
