





































.custom-field-badge {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  color: var(--grey-darker);
  background-color: var(--main-color-lighter);
  border-radius: 3px;

  span {
    margin-left: 6px;
  }
}
