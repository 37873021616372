
































































































































































































































































































































































































































































@import '@/styles/mixins/index';

// preview
.lobby-sponsor {
  display: flex;
  flex-flow: column;
  background-color: var(--white);
  font-size: var(--rem-size-14);
  color: var(--black);
  margin: auto;
  margin-bottom: var(--rem-size-16);
  overflow: hidden;
  border-radius: var(--global-radius);
  box-shadow: 0 2px 3px var(--black-alpha10);
  transition: max-width .15s ease-in-out;
  max-width: 400px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    padding-top: 8%;
    padding-left: 12.5%;
    padding-right: 12.5%;
    overflow: hidden;
    transition: height .15s ease-in-out;

    img {
      object-fit: contain;
      height: 100%;
      opacity: 0;
      transition: opacity .15s ease-in-out;
    }

    &--loaded {
      img {
        opacity: 1;
      }
    }
  }

  &--2 {
    max-width: 350px;

    .lobby-sponsor__img {
      height: 210px;
    }
  }
  &--3 {
    max-width: 300px;

    .lobby-sponsor__img {
      height: 180px;
    }
  }
  &--4 {
    max-width: 250px;

    .lobby-sponsor__img {
      height: 150px;
    }
  }
  &--5 {
    max-width: 200px;

    .lobby-sponsor__img {
      height: 120px;
    }
  }

  &__name {
    padding: var(--rem-size-16) var(--rem-size-40);
    color: var(--grey-darker);
  }
}

.lobby-sponsor {
  // simple fade in/out animation
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s ease;
  }
}

// tooltip info
.idt-sponsor-info {
  position: relative;
  margin-bottom: 24px;
  padding: var(--rem-size-24);
  color: var(--grey-darkest-alpha70);
  background-color: var(--grey-lightest);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);

  &__icon {
    color: var(--main-color);

    .ideacon {
      position: absolute;
      left: var(--rem-size-24);
      top: var(--rem-size-28);
    }
  }
  &--warn {
    color: var(--yellow-dark);
    background-color: var(--yellow-dark-alpha10);
  }
  &--warn &__icon {
    color: var(--yellow-dark);
  }
}

.single-page-sponsor-switch {
  label {
    padding-bottom: 0;
    font-size: var(--rem-size-14);
  }
  .idt-switch {
    width: 45px !important;
    min-width: 0;
    height: 24px;
  }
  .idt-switch__btn {
    width: 16px;
    height: 16px;
    &.off {
      background-color: var(--white);
    }
  }
  .idt-switch__inner.off {
    background-color: var(--grey-light);
  }
}

.bo-tips {
  &__title {
    display: flex;
    align-items: center;
    align-self: stretch;
    text-transform: none;
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-bold);
  }
}

.slug-url {
  margin: calc(-1 * var(--rem-size-18)) 0 var(--rem-size-24) 0;
  font-size: var(--rem-size-12);
}

.idt-card-file-upload-header__preview {
    margin-bottom: 1rem;
}
.sponsor-subtitle {
  margin-bottom: 1rem;
}
.single-page-sponsor-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem 0;

  label {
    font-weight: var(--font-weight-normal);
  }
}

.vs__dropdown-option--disabled {
  display: none !important;
}

// animation
.fade-height-enter,
.fade-height-leave-to {
  max-height: 0;
}
.fade-height-leave,
.fade-height-enter-to {
  max-height: 100px;
}
.fade-height-enter-active,
.fade-height-leave-active {
  transition: max-height .25s ease;
  overflow: hidden;
}
