

































































@import '@/styles/mixins/index';

.fo-lobby-chat {
  display: flex;
  flex: auto;
  flex-flow: column;
  height: 100%;
  background: var(--white);

  &__content {
    flex: auto;
  }
}
