































@import '@/styles/functions';

.idt-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: remCalc(16);

  &__label {
    padding: 0;
  }
}
