




















































@import '@/styles/mixins/index';

.event-admin-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;
  color: var(--white);
  background-color: var(--main-color);
  text-align: center;

  @include respond-to(max-large) {
    padding: 3rem 1rem;
  }
  @include respond-to(large) {
    padding: 5rem 3rem;
  }
  &__content {
    flex: 1 1 auto;
  }
  &__title {
    margin: 0 0 1rem 0;
    font-family: var(--title-font-family);
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1;
  }
  &__desc {
    margin-bottom: 1.5rem;
  }
  .button {
    color: var(--main-color);
    background-color: var(--white);
    border: 2px solid var(--white);
    border-radius: 0;
    font-weight: 500;
    text-transform: uppercase;

    @include respond-to(medium) {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
    @include respond-to(medium) {
      width: 420px;
      padding: 1rem;
      font-size: 1.1rem;
    }
    &:hover {
      color: var(--white);
      background-color: transparent;
    }
  }
}
