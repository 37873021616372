






















































































































































































































































































































































































































































































































































































@import '@/styles/mixins/index';
.idt-panel-filter {
  --background-color: var(--grey-lighter);

  @include respond-to(max-large) {
    --background-color: var(--white);
    background-color: var(--background-color);
  }

  .open-close-enter-active,
  .open-close-leave-active {
    transition: opacity .5s;
  }
  .open-close-enter,
  .open-close-leave-to {
    opacity: 0;
  }
  &__update-button {
    position: fixed;
    bottom: 20px;
    left: 15%;
    width: 290px;
    height: 45px;
    color: var(--white);
    background-color: var(--main-color);
    border-radius: 5em / 5em;
    box-shadow: 0px 3px 6px var(--black);
    font-family: var(--first-font-family);
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-bold);
    visibility: initial;
    z-index: 20;
  }
  &__advanced-filter {
    height: 0;
    margin: var(--rem-size-15) var(--rem-size-10) 0 0;
    color: var(--black);
    font-family: var(--first-font-family);
    font-size: var(--rem-size-28);
    font-weight: var(--font-weight-bold);
    visibility: hidden;

    @include respond-to(max-large) {
      height: 100%;
      visibility: initial;
    }
  }
  &__search {
    height: 40px;
    margin-bottom: var(--rem-size-10);
    background-color: var(--white);
    border: solid 1px var(--grey-light);
    border-radius: var(--global-radius);

    @include respond-to(max-large) {
      height: 0;
      visibility: hidden;
    }

    &:before {
      content: '\E96E';
      display: block;
      position: absolute;
      top: var(--rem-size-18);
      left: 10px;
      color: var(--black);
      font-family: "ideation-icons";
      font-size: 1rem;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }
    &__input {
      width: 90%;
      height: 38px;
      margin: 0 0 var(--rem-size-10) var(--rem-size-15);
      color: var(--black);
      border: none;
      font-size: var(--rem-size-16);

      &::placeholder {
        color:var(--black);
      }
      &:focus {
        border: none;
      }
    }
  }
  ::v-deep &__select {
    display: flex;
    align-items: center;
    margin-bottom: var(--rem-size-10);

    label {
      margin-right: var(--rem-size-16);
      margin-left: var(--rem-size-10);
      color: var(--black);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-medium);
      white-space: nowrap;
    }

    .v-select {
      margin: 0;
      padding: 0;
      flex-grow: 1;

      .vs__dropdown-toggle {
        background: var(--white);
      }
      .vs__search {
        display: none !important;
      }
    }
  }
  &__buttons {
    position: sticky;
    top: 0;
    background-color: var(--background-color);
    z-index: 20;

    &__button {
      width: 100% ;
      height: var(--rem-size-35);
      margin-bottom: 3px;
      border-radius: var(--global-radius);
      cursor: pointer;
      text-align: left;

      &.active {
        background-color: var(--main-color);
        &:hover {
          background-color: var(--main-color)
        }
      }
      &.reset {
        margin: var(--rem-size-16) 0;
        border: var(--rem-size-1) var(--main-color) solid;
      }
      &.reset &__title {
        color: var(--main-color);
        text-align: center;
      }
      &:hover {
        background-color: var(--grey-light);
      }
      &__title {
        margin-left: var(--rem-size-10);
        color: var(--black);
        font-family: var(--first-font-family);
        font-size: var(--rem-size-16);
        font-weight: var(--font-weight-medium);

        &.active {
          color: var(--white);
        }
      }
    }
    &__selected {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: var(--rem-size-4);
      width: 100%;
      margin: 0 0 var(--rem-size-8) var(--rem-size-4);

      @include respond-to(max-large) {
        display: none;
      }
    }
  }
  &__filters {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &__filter {
      margin-right: var(--rem-size-15);
      margin-bottom: var(--rem-size-15);

      &__empty {
        margin: -5px 0 var(--rem-size-10) var(--rem-size-10);
        color: var(--grey-dark);
        font-family: var(--first-font-family);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
      }
      &__values {
        margin-left: var(--rem-size-10);
        padding-top: var(--rem-size-15);
      }
      &__title {
        position: sticky;
        top: 0;
        z-index: 10;
        margin-left: var(--rem-size-10);
        color: var(--black);
        background-color: var(--background-color);
        border-bottom: solid 1px var(--grey-dark);
        font-family: var(--first-font-family);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
      }
      &__category {
        display: flex;
        margin-bottom: var(--rem-size-10);
        color: var(--black);
        cursor: pointer;

        &__label {
          flex: 0 0 auto;
          width: 210px;
          font-family: var(--first-font-family);
          font-size: var(--rem-size-14);
          font-weight: var(--font-weight-normal);
          overflow-wrap: break-word;

          @include respond-to(max-large) {
            padding-left: var(--rem-size-12);
          }
        }
        &__icon {
          @include respond-to(max-large) {
            padding-right: var(--rem-size-18);
          }
        }
      }
      &__value {
        display: flex;
        margin-bottom: var(--rem-size-10);
        margin-left: 0;

        &:hover {
          cursor: pointer;
        }
      }
      &__showmore {
        margin-left: var(--rem-size-10);;
        color: var(--main-color);
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: var(--main-color-dark);
        }
      }
    }
    &__label {
      flex: 0 0 auto;
      width: 180px;
      padding-left: var(--rem-size-8);
      color: var(--black);
      font-family: var(--first-font-family);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-normal);
      overflow-wrap: break-word
    }
    &__nb {
      flex: 0 0 auto;
      padding-left: var(--rem-size-16);
      color: var(--black);
      font-family: var(--first-font-family);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-normal);
    }
    &__container {
      flex: 0 0 auto;
      height: var(--rem-size-28);
      padding-left: 0;
      padding-right: 0;

      &__checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--rem-size-20);
        height: var(--rem-size-20);
        margin-right: 0;
        margin-left: 0;
        padding: 0;
        background-color: var(--white);
        border: 1px solid var(--grey);
        border-radius: 3px;
      }
      &__checkbox:after {
        content: '';
        display: none;
        content: '\e996';
        color: var(--white);
        font-family: "ideation-icons";
        font-size: 0.85rem;
      }
      input.checked ~ &__checkbox {
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
      }
      input.checked ~ &__checkbox:after {
        display: block;
      }
      input[type=checkbox] {
        display: none;
      }
    }
  }
}

::v-deep .vs__search {
  display: inline !important;
}
