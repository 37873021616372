



















































































.bo-dashboard-tooltip {
  rect {
    background-color: var(--black);
    stroke: transparent;
  }
  polyline {
    stroke: transparent;
  }
  text {
    color: var(--white);
    fill: var(--white);
    font-family: var(--text-font-family);
  }
  circle {
    &.inner {
      fill: var(--white);
    }
    &.outer {
      fill: var(--main-color);
    }
  }
  &__value {
    font-weight: var(--font-weight-bold);
  }
}
