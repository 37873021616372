




































































































































































@import '@/styles/mixins/index';

.lobby-video {
  background: var(--grey-darkest);

  &--full {
    background: transparent;
  }
  &--loading {
    position: relative;

    .idt-round-loader {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__footer {
    display: flex;
    flex-flow: wrap;

    a {
      padding: var(--rem-size-5) var(--rem-size-24);
      border: 1px solid var(--main-color-dark);
      border-radius: 30px;
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-bold);

      & + a {
        margin-left: var(--rem-size-10);
      }
    }
  }
}
