.projects-card__container {
  display: flex;
  flex-direction: initial;
  flex-wrap: wrap;
  gap: var(--rem-size-14) var(--rem-size-14);
  padding: var(--rem-size-5);
}

.projects-card__container__no-more {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: var(--rem-size-15);
}

@media only screen and (max-width: 479px) {
  .projects-card__container.grid {
    flex-direction: column;
    align-items: center;
  }
}

.projects-card__container.grid ::v-deep .project-grid-card {
  width: calc(33% - var(--rem-size-8));
  min-width: 280px;
}

.projects-card__container.list {
  gap: var(--rem-size-4);
}

.projects-card__container.list__card {
  width: 100%;
}

.projects-page {
  height: calc(var(--vh, 1vh)*100 - 70px);
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
  overflow: auto;
}

@media only screen and (max-width: 1023px) {
  .projects-page {
    height: fit-content;
    overflow: unset;
    background-color: var(--white);
  }
}

.projects-page__projects {
  position: relative;
}

.projects-page__projects .side-open-enter-active {
  transition: opacity 1s;
}

.projects-page__projects .side-open-enter {
  opacity: 0;
}

.projects-page__projects__filter-panel {
  position: sticky;
  top: 0;
  max-width: 280px;
  height: calc(100vh - 70px);
  padding: var(--rem-size-16);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (max-width: 1023px) {
  .projects-page__projects__filter-panel {
    background-color: var(--white);
    max-width: none;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    transition: transform .3s ease;
    z-index: 11;
  }

  .projects-page__projects__filter-panel.mobile-hidden {
    transform: translate3d(-100%, 0, 0);
  }
}

.projects-page__projects__filter-panel::-webkit-scrollbar {
  display: none;
}

.projects-page__projects__projects-list__header {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: var(--rem-size-15) 0 var(--rem-size-25) 0;
  background-color: var(--grey-lighter);
  z-index: 10;
}

@media only screen and (max-width: 1023px) {
  .projects-page__projects__projects-list__header {
    top: 70px;
    background-color: var(--white);
    padding: var(--rem-size-15) 0 var(--rem-size-10) 0;
  }
}

.projects-page__projects__projects-list__header__left {
  flex-shrink: 0;
  margin-right: var(--rem-size-16);
}

.projects-page__projects__projects-list__header__left__title {
  margin-bottom: var(--rem-size-16);
  font-size: var(--rem-size-24);
  line-height: var(--rem-size-30);
  font-weight: var(--font-weight-medium);
  color: var(--grey-darkest);
}

.projects-page__projects__projects-list__header__left__results {
  font-weight: var(--rem-size-16);
  line-height: var(--rem-size-24);
  color: var(--grey-darker);
}

.projects-page__projects__projects-list__header__right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--rem-size-16);
}

.projects-page__projects__projects-list__header__right__list-type {
  display: flex;
  align-items: center;
  margin-left: var(--rem-size-24);
}

@media only screen and (max-width: 1023px) {
  .projects-page__projects__projects-list__header__right__list-type {
    display: none;
  }
}

.projects-page__projects__projects-list__header__right__list-type__button {
  color: var(--grey-darkest);
  cursor: pointer;
}

.projects-page__projects__projects-list__header__right__list-type__button:first-child {
  margin-right: var(--rem-size-16);
}

.projects-page__projects__projects-list__header__right__list-type__button.active {
  color: var(--main-color);
}

.projects-page__projects__projects-list__header__infos {
  align-content: center;
  height: 64px;
  background: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
}

@media only screen and (max-width: 1023px) {
  .projects-page__projects__projects-list__header__infos {
    height: 0;
    visibility: hidden;
  }
}

.projects-page__projects__projects-list__header__infos__sort__label {
  margin: var(--rem-size-5) var(--rem-size-10) 0 0;
  color: var(--black);
}

.projects-page__projects__projects-list__header__infos__sort__select {
  width: 170px;
  height: 41px;
  margin: 0 var(--rem-size-10) 0 0;
}

.projects-page__projects__projects-list__header__infos__list-mode {
  color: var(--grey);
}

.projects-page__projects__projects-list__header__infos__list-mode__button {
  margin-right: var(--rem-size-10);
}

.projects-page__projects__projects-list__header__infos__list-mode__button:hover {
  cursor: pointer;
}

.projects-page__projects__projects-list__header__infos__list-mode__button.active {
  color: var(--black);
}

.projects-page__projects__projects-list__list__mine__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--rem-size-24);
}

.projects-page__projects__projects-list__list__mine__header__title {
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  color: var(--grey-darkest);
}

.projects-page__projects__projects-list__list__mine__empty-dialog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 128px;
  margin: 0 var(--rem-size-5);
  padding: var(--rem-size-16) var(--rem-size-32);
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 3px 6px rgba(0,0,0,.12);
}

@media only screen and (max-width: 639px) {
  .projects-page__projects__projects-list__list__mine__empty-dialog {
    padding: var(--rem-size-20);
  }
}

.projects-page__projects__projects-list__list__mine__empty-dialog__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-right: var(--rem-size-24);
  background-color: var(--main-color-lighter);
  color: var(--main-color);
  border-radius: 50%;
}

.projects-page__projects__projects-list__list__mine__empty-dialog__icon .idt-icon ::v-deep .ideacon {
  transform: rotate(45deg);
}

.projects-page__projects__projects-list__list__mine__empty-dialog__text {
  margin: var(--rem-size-16) 0;
  margin-right: auto;
}

.projects-page__projects__projects-list__list__mine__empty-dialog__text__headline {
  margin-bottom: var(--rem-size-4);
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  color: var(--grey-darkest);
}

.projects-page__projects__projects-list__list__mine__empty-dialog__text__subtitle {
  max-width: 450px;
  margin-bottom: 0;
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
  color: var(--grey-darker);
}

.projects-page__projects__projects-list__list__mine__empty-dialog__button {
  margin-bottom: 0;
}

.projects-page__projects__projects-list__list__mine__empty-dialog__button .idt-icon ::v-deep .ideacon {
  transform: rotate(45deg);
}

.projects-page__projects__projects-list__list__mine__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.projects-page__projects__projects-list__list__mine__empty__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-bottom: var(--rem-size-16);
  color: var(--grey-darker);
  background: var(--grey-light);
  border-radius: 50%;
}

.projects-page__projects__projects-list__list__mine__empty__text {
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);
  color: var(--grey-darkest);
}

@media only screen and (max-width: 991px) {
  .projects-page__projects__projects-list__list__all {
    margin: 2.5rem 0 6rem;
  }
}

@media only screen and (min-width: 992px) {
  .projects-page__projects__projects-list__list__all {
    margin: 2.5rem 0;
  }
}

.projects-page__projects__projects-list__list__all__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--rem-size-24);
}

.projects-page__projects__projects-list__list__all__header__title {
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  color: var(--grey-darkest);
}

.projects-page__projects__projects-list__list__all__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.projects-page__projects__projects-list__list__all__empty__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-bottom: var(--rem-size-16);
  color: var(--grey-darker);
  background: var(--grey-light);
  border-radius: 50%;
}

.projects-page__projects__projects-list__list__all__empty__text {
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);
  color: var(--grey-darkest);
}

.projects-page__projects__projects-list__no-item-icon {
  padding-bottom: var(--rem-size-15);
}

.projects-page__projects ::v-deep .idt-panel-filter__select label,
.projects-page__projects ::v-deep .idt-panel-filter__filters__filter__title {
  margin-left: 0;
  text-transform: uppercase;
}

.projects-page__projects ::v-deep .idt-panel-filter__filters__filter {
  margin-right: 0;
}

.projects-page__projects ::v-deep .idt-panel-filter__filters__filter__values {
  margin-left: 0;
}

.projects-page__projects ::v-deep .idt-panel-filter__filters__filter__showmore {
  margin-bottom: var(--rem-size-10);
  text-decoration: none;
}

.projects-page__projects ::v-deep .idt-panel-filter__buttons__button.active {
  background-color: var(--main-color-dark);
}

.projects-page__projects ::v-deep .idt-panel-filter__buttons__button.reset {
  border-color: var(--main-color-dark);
}

.projects-page__projects ::v-deep .idt-panel-filter__buttons__button.reset.idt-panel-filter__buttons__button__title {
  color: var(--main-color-dark);
}

.projects-page .add-project-button {
  display: flex;
  align-items: center;
  padding: var(--rem-size-8) var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-16);
}

.projects-page .add-project-button .idt-icon ::v-deep .ideacon {
  margin-left: var(--rem-size-16);
  color: var(--white);
  transform: rotate(45deg);
}

