












































































@import '@/styles/mixins/index';

.ticketing-payment-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @include respond-to(max-slarge) {
    flex-direction: column;
  }
  @include respond-to(slarge) {
    flex-direction: row;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-to(max-small) {
      margin-bottom: 20px;
      font-size: 1.5rem;
    }
    @include respond-to(small-medium) {
      margin-bottom: 50px;
      font-size: 1.5rem;
    }
    @include respond-to(medium-large) {
      margin-bottom: 60px;
      font-size: 2rem;
    }
    @include respond-to(large) {
      font-size: 3.125rem;
    }
    &__message {
      display: flex;
      align-items: baseline;
      color: var(--grey-darkest);
      font-weight: var(--font-weight-medium);

      @include respond-to(max-large) {
        margin-bottom: 10px;
      }
      @include respond-to(large) {
        margin-bottom: 50px;
      }

      &__icon {
        &.idt-icon ::v-deep .ideacon {
          color: var(--main-color);

          @include respond-to(max-large) {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
          }
          @include respond-to(large) {
            width: 2.75rem;
            height: 2.75rem;
            margin-right: 1rem;
          }
        }
      }
    }

    &__continue {
      margin: 0;
      font-size: 1rem;
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
      line-height: 0.75;

      @include respond-to(max-medium) {
        width: 180px;
        height: 36px;
        margin-top: 5px;
        font-size: 1rem;
        line-height: 0.5;
      }
      @include respond-to(medium) {
        width: 200px;
        height: 50px;
        font-size: 1.125rem;
      }
    }
  }

  &__ticket {
    width: 280px;

    @include respond-to(large) {
      margin-left: 50px;
    }
  }
}
