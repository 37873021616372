
























































































































































@import '@/styles/mixins/index';

.custom-field-form {
  width: 100%;

  .column-editor {
    position: relative;
    margin-bottom: 1rem;
  }
  &__badge {
    margin-bottom: 5px;
    max-width: 45px;

    @include respond-to(slarge) {
      position: absolute;
      left: -35px;
    }
  }
  &__options {
    @include respond-to(medium-slarge) {
      display: flex;
      justify-content: space-between;
    }

    @include respond-to(slarge) {
      position: absolute;
      top: 0;
      right: -240px;
      width: 240px;
    }

    &__mandatory {
      margin-bottom: 12px;
    }
    &__attachments {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;

      &__text {
        margin-left: 4px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &--bottom {
      position: inherit;
      right: 0;
    }
  }
  .btn-add-field {
    width: 200px;
    height: 50px;
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }
  .froala-container.required-field {
    ::v-deep .fr-box {
      margin: 0;
      border: 1px solid var(--secondary-color);
    }
  }
}
