.idt-input-price {
  flex: 1 1 auto;
  position: relative;

  input {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 38px;
    padding: 8px;
    margin-bottom: 0;
    border: solid 1px var(--grey);
    font-size: 13px;
    text-align: right;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    &.price {
      padding-right: 38px;
    }
  }

  .currency {
    position: absolute;
    right: 10px;
    top: 12px;
    color: var(--black);
    font-size: 11px;
    font-weight: var(--font-weight-medium);
    font-style: normal;
    line-height: 17px;
  }
}
