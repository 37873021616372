.login-box {
  position: fixed;
  width: 305px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
  overflow: hidden;
  transition: all .2s;
  opacity: 0;
  z-index: 100;
}

.login-box__content {
  padding: 30px 35px 25px 35px;
}

.login-box__title {
  color: var(--grey-darkest);
  font-size: 1rem;
}

.login-box__message {
  color: var(--grey-darker);
  font-size: 1rem;
}

.login-box__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
  text-align: center;
}

.login-box__sign-in {
  width: 100px;
  margin-right: 15px;
  padding: 10px 18px;
  color: var(--grey-darker);
  border-radius: 3px;
  font-size: .875rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
  cursor: pointer;
}

.login-box__sign-in:hover {
  color: var(--grey-darkest);
  background-color: rgba(0,0,0,.05);
}

.login-box__register {
  width: 100px;
  margin-bottom: 0;
  padding: 10px 18px;
  border-radius: 3px;
  font-size: .875rem;
  text-transform: uppercase;
  border: none;
}

