.empty-section-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: var(--grey-darkest);
  background-color: var(--grey-light);
  border-radius: 50%;
  font-size: var(--rem-size-2);
}

