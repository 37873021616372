





































































































































@import '@/styles/functions';
@import '@/styles/mixins';

.sso-provider {
  margin-bottom: remCalc(12);
  padding-bottom: remCalc(12);

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lighter);
  }
}
.input-custom {
  margin-top: remCalc(20);
}
.sso-callback-url {
  font-size: remCalc(12);
}
.preview-block {
  width: 100%;
  height: 100%;
  padding: remCalc(16);
  background-color: var(--grey-lightest);

  &__title {
    margin-bottom: remCalc(16);
    font-size: remCalc(20);
  }
  &__container {
    width: 100%;
    max-width: 400px;
    margin: auto;

    @include respond-to(slarge) {
      position: sticky;
      top: calc((var(--menu-height) * 2) + remCalc(32));
    }
  }
}
