





















































































































































@import '@/styles/mixins/index';

.tab {
  height: 100%;
  margin-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-size: var(--rem-size-14);

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .content__container {
      &__data {
        border-top: 1px solid var(--grey-lighter);
      }
      &__row {
        display: flex;
        justify-content: space-between;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        font-size: var(--rem-size-14);

        &.border {
          padding-top: 0.3125rem;
          padding-bottom: 0.3125rem;
          border-bottom: 1px solid var(--grey);

          &:last-child {
            border-bottom: 1px solid var(--grey-lighter);
          }
        }
        &.header {
          margin-bottom: 0.625rem;
          font-weight: var(--font-weight-bold);
        }
        .row__title {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .row__value {
          margin-left: 1.5rem;
        }
      }
    }
    .idt-table-pagination li a {
      @include respond-to(max-xlarge) {
        width: 25px;
      }
      @include respond-to(max-slarge) {
        width: 35px;
      }
    }
  }
}
