























































































@import '@/styles/mixins';

.modal-medium {
  &__close {
    position: fixed;
    top: var(--rem-size-24);
    right: var(--rem-size-24);
    z-index: 20;
    cursor: pointer;
  }
  &__content {
    @include respond-to(max-medium) {
      padding: 45px 20px 25px;
    }
    @include respond-to(mdeium) {
      padding: 45px 40px 25px;
    }
  }
}
