.idt-table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.idt-table-actions__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
}

.idt-table {
  display: table;
  width: 100%;
  color: var(--grey-darkest);
}

.idt-table__container {
  max-width: 100%;
  overflow: auto;
}

.idt-table__header {
  box-shadow: 0 0 0 1px var(--grey) inset;
}

.idt-table__header button {
  font-size: .9rem;
  font-weight: 300;
}

.idt-table__content {
  display: table-row-group;
}

.idt-table__content .idt-table-row:nth-child(even) {
  background-color: var(--grey-lightest);
}

.idt-table__content .idt-table-row:nth-child(odd) {
  background-color: var(--grey-lighter);
}

.idt-table button {
  white-space: nowrap;
}

.idt-table input,
.idt-table select {
  margin: 0;
}

.idt-table select {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  color: var(--grey-darkest);
  font-family: var(--text-font-family) !important;
  font-size: .8rem;
  font-weight: 500;
  text-align: left;
}

.idt-table-row {
  display: table-row;
  height: 60px;
}

.idt-table-row i {
  vertical-align: middle;
}

.idt-table-header-cell {
  display: table-cell;
  padding: 0 1rem;
  font-size: .8rem;
  font-weight: 300;
  vertical-align: middle;
  white-space: nowrap;
}

.idt-table-header-cell--checkbox,
.idt-table-header-cell--action {
  position: sticky;
  border: 1px solid var(--grey);
  background-color: var(--white);
  z-index: 1;
}

.idt-table-header-cell--checkbox {
  width: 54px;
  left: 0;
}

.idt-table-header-cell--action,
.idt-table-header-cell--source,
.idt-table-header-cell--status,
.idt-table-header-cell--votes {
  text-align: center;
}

.idt-table-header-cell--action {
  width: 78px;
  right: 0;
}

@media only screen and (max-width: 639px) {
  .idt-table-header-cell--action {
    padding: 0 .5rem;
  }
}

.idt-table-header-cell--votes {
  max-width: 4vw;
}

.idt-table-header-cell--answers {
  max-width: 5vw;
}

.idt-table-header-cell--like {
  max-width: 4vw;
}

.idt-table-header-cell button {
  color: var(--grey-darkest);
}

.idt-table-cell {
  display: table-cell;
  padding: 0 1rem;
  font-size: .8rem;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;
}

.idt-table-cell--checkbox,
.idt-table-cell--action {
  position: sticky;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 0;
  border-left-width: 1px;
  border-style: solid;
  border-color: var(--grey);
  background-color: inherit;
  z-index: 1;
}

.idt-table-cell--checkbox {
  width: 54px;
  left: 0;
}

.idt-table-cell--type,
.idt-table-cell--targetedgroups {
  text-transform: capitalize;
}

.idt-table-cell--questions {
  max-width: 15vw;
  white-space: pre-wrap;
}

.idt-table-cell--answers {
  max-width: 5vw;
}

.idt-table-cell--like {
  max-width: 4vw;
}

.idt-table-cell--tag select {
  margin-left: -8px;
}

.idt-table-cell--action,
.idt-table-cell--source,
.idt-table-cell--status,
.idt-table-cell--votes {
  text-align: center;
}

.idt-table-cell--action {
  width: 78px;
  right: 0;
}

@media only screen and (max-width: 639px) {
  .idt-table-cell--action {
    padding: 0 .5rem;
  }
}

.idt-table-cell--action i {
  font-size: 1.75rem;
}

.idt-table-cell--title a {
  color: var(--grey-darkest);
}

.idt-table-cell--title a:hover {
  color: var(--main-color);
  transition: color .3s;
}

.idt-table-cell--array-preview {
  display: inline-block;
  width: 30%;
  padding: .5rem;
  color: var(--grey-darkest);
  background-color: var(--grey-darkest-alpha20);
  border-radius: var(--global-radius);
  overflow: hidden;
  font-size: .75rem;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 1.2;
  white-space: nowrap;
}

.idt-table-cell--show-ellipsis {
  overflow: inherit;
  white-space: inherit;
}

.idt-table-cell--hide-ellipsis {
  overflow: hidden;
  white-space: nowrap;
}

.idt-table-cell .idt-table-dropdown {
  display: inline-block;
  vertical-align: middle;
}

.idt-table-cell .idt-table-dropdown__toggle {
  padding: var(--rem-size-8);
  color: var(--main-color);
  cursor: pointer;
}

.idt-table-cell idt-link-cell>span {
  display: flex;
}

.idt-table-cell idt-link-cell>span>a {
  display: inline-block;
  max-width: 100%;
  height: 2rem;
  line-height: 2.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.idt-table-checkbox {
  position: absolute;
  left: -9999px;
}

.idt-table-checkbox+label {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 1px solid var(--grey);
  border-radius: calc(var(--global-radius)/2);
  vertical-align: middle;
  cursor: pointer;
}

.idt-table-checkbox+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: rgba(0,0,0,0);
  border-radius: calc(var(--global-radius)/3);
  transform: translate(-50%, -50%);
  transition: all .3s ease;
}

.idt-table-checkbox:not([checked=checked]):checked+label:after {
  background-color: var(--grey);
}

.idt-table-checkbox:not([checked=checked]):not(:checked)+label:after {
  background-color: rgba(0,0,0,0);
}

.idt-table-checkbox[checked=checked]+label:after {
  background-color: var(--grey);
}

.idt-table-checkbox.semi-selected+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(0,0,0,0);
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #bcbec0;
  box-sizing: border-box;
  border-radius: 0;
  transition: none;
}

.idt-table-dropdown .dropdown-pane {
  width: auto;
  min-width: 150px;
  margin-top: -1.5rem;
  padding: .65rem;
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 5px var(--black-alpha20);
}

.idt-table-dropdown .menu {
  font-size: .8rem;
}

.idt-table-dropdown .menu li:not(:last-child).idt-table-cell--array-preview--more {
  border-bottom: 1px solid var(--grey-lightest);
}

.idt-table-dropdown .menu li:not(:last-child) a,
.idt-table-dropdown .menu li:not(:last-child) label {
  border-bottom: 1px solid var(--grey-lightest);
}

.idt-table-dropdown .menu li a,
.idt-table-dropdown .menu li label {
  color: var(--grey-darkest);
}

.idt-table-dropdown .menu li a:hover,
.idt-table-dropdown .menu li label:hover {
  color: var(--main-color);
}

.idt-table-dropdown .menu li .idt-table-cell--array-preview--more {
  font-size: .8rem;
  font-weight: 500;
}

.idt-table-participants {
  display: inline-block;
  margin-right: .15rem;
}

.idt-table-participants .dropdown-pane {
  width: auto;
  margin-top: .5rem;
  padding: .65rem;
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 5px var(--black-alpha20);
}

.idt-table-participants .menu li:not(:last-child) {
  border-bottom: 1px solid var(--grey-lightest);
}

.idt-table-participants__name {
  font-size: .8rem;
  font-weight: 500;
}

.idt-table-participants__tag {
  font-size: .75rem;
  font-weight: 300;
}

.idt-table .idt-table__header-cell {
  font-size: var(--rem-size-14);
}

.idt-table .idt-table-cell--action ::v-deep .idt-dropdown--pane {
  max-width: 180px;
}

.idt-table-fake-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 1px solid var(--grey);
  border-radius: calc(var(--global-radius)/2);
  vertical-align: middle;
  cursor: pointer;
}

.idt-table-fake-checkbox:after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(0,0,0,0);
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: rgba(0,0,0,0);
  transform: translate(-50%, -50%);
  transition: all .15s ease;
}

.idt-table-fake-checkbox.checked:after {
  border-color: var(--grey-dark);
}

.idt-table-fake-checkbox.semi-checked:after {
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) var(--grey-dark);
}

