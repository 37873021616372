.idt-select-container {
  position: relative;
  padding-bottom: 1rem;
}

.idt-select-container__add-text {
  margin-right: -10px;
}

.idt-select-container__inline-label {
  opacity: .5;
}

.idt-select-container ::v-deep .v-select {
  margin-bottom: 10px;
}

.idt-select-container ::v-deep .v-select.has-error .vs__dropdown-toggle {
  border: 1px solid var(--secondary-color);
}

.idt-select-container ::v-deep .v-select .vs__search {
  padding: 0 var(--rem-size-8) 0 var(--rem-size-16);
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
  line-height: 2.3;
}

.idt-select-container ::v-deep .v-select .vs__search::placeholder {
  color: var(--grey);
}

.idt-select-container ::v-deep .v-select .vs__actions {
  padding-right: 15px;
}

.idt-select-container ::v-deep .v-select.vs--open .vs__dropdown-toggle {
  border-bottom: 1px solid rgba(60,60,60,.26);
}

.idt-select-container ::v-deep .v-select.vs--open .vs__selected {
  position: relative;
}

.idt-select-container ::v-deep .v-select.vs--open .vs__dropdown-option--highlight:not(.vs__dropdown-option--disabled) {
  background-color: var(--main-color);
}

.idt-select-container ::v-deep .v-select .vs__selected-options .vs__selected {
  padding-left: 15px;
}

::v-deep .idt-required-field {
  position: absolute;
  bottom: 0;
  left: 0;
}

