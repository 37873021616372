.idt-ticket-card-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-card-price:not(.free) {
    padding: 1rem 0 .3125rem;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1441px) {
  .idt-ticket-card-price:not(.free) {
    padding: 1.5rem 0 .625rem;
  }
}

@media only screen and (min-width: 1442px) {
  .idt-ticket-card-price:not(.free) {
    padding: 2rem 0 1.25rem;
  }
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-card-price.free {
    padding: 1rem 0 1.25rem;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1441px) {
  .idt-ticket-card-price.free {
    padding: 1.5rem 0 1.5625rem;
  }
}

@media only screen and (min-width: 1442px) {
  .idt-ticket-card-price.free {
    padding: 2rem 0 2.1876rem;
  }
}

.idt-ticket-card-price__current {
  font-weight: var(--font-weight-bold);
  line-height: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-card-price__current {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-card-price__current {
    font-size: 2.625rem;
  }
}

.idt-ticket-card-price__currency {
  font-size: .625rem;
}

@media only screen and (min-width: 370px) {
  .idt-ticket-card-price.modal-mode .idt-ticket-card-price__current {
    font-size: 2.625rem;
  }
}

