

























































@import '@/styles/mixins/index';

.pre-conference-canceled-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--grey-light);

  &__content {
    color: var(--grey-dark);
    text-align: center;

    @include respond-to(max-medium) {
      transform: scale(0.6);
    }
    @include respond-to(medium-large) {
      transform: scale(0.9);
    }
    @include respond-to(large-xlarge) {
      transform: scale(0.7);
    }
  }
  .date {
    &__part {
      display: inline-block;
      position: relative;
      vertical-align: top;
    }
    &__calendar {
      margin-right: 1rem;
      padding: 0 1.5rem;

      .idt-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__day {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -45%);
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1;
    }
    &__month {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 55%);
      font-size: 0.8rem;
      font-weight: 300;
      text-transform: uppercase;
    }
    &__text {
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 1.4;
    }
    &__hours {
      font-family: var(--title-font-family);
      font-size: 2.6rem;
      font-weight: 300;
      line-height: 1;
    }
  }
  .message {
    font-family: var(--title-font-family);
    font-size: 2.6rem;
    font-weight: 300;

    &__container {
      position: relative;
      width: 600px;
      margin-top: 4.5rem;
      padding-top: 1rem;
      border-top: 1px solid var(--grey-dark);
      font-size: 2rem;
    }
    &__text {
      position: absolute;
      top: 0;
      left: 50%;
      padding: 0 1.25rem;
      background-color: var(--grey-light);
      font-size: 1.4rem;
      font-weight: 300;
      transform: translate(-50%, -50%);
    }
  }
}
