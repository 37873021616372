.idt-alerts__list {
  position: fixed;
  right: 0;
  width: 100%;
  max-width: 564px;
  flex-shrink: 0;
  z-index: 1000;
}

.idt-alerts__list.has-container {
  position: relative;
  top: 0 !important;
}

.idt-alerts__list>div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 0 22px;
  background-color: rgba(0,0,0,0);
}

.idt-alerts__list:not(.top) {
  top: var(--menu-height);
}

.idt-alerts__list.top {
  top: 0;
}

.idt-alert {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 520px;
  min-height: 100px;
  padding: 1rem 1.5rem;
  margin-top: 20px;
  background-color: #fff;
  border-left: 5px solid var(--alert-color);
  border-radius: var(--global-radius);
  box-shadow: 0px 3px 30px rgba(0,0,0,.1607843137);
  cursor: pointer;
  transition: box-shadow .2s ease;
}

.idt-alert:hover {
  box-shadow: 0px 2px 5px rgba(0,0,0,.1607843137);
}

.idt-alert:hover .idt-alert__close {
  display: inline-block;
}

.idt-alert__close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  border: none;
  color: var(--grey-dark);
  cursor: pointer;
}

.idt-alert__icon .idt-icon {
  color: var(--alert-color);
}

.idt-alert__content {
  display: flex;
  flex-direction: column;
  padding: .1rem 3rem 0 1rem;
}

.idt-alert__content__title {
  font-weight: var(--font-weight-medium);
  color: var(--alert-color);
}

.idt-alert__content__text {
  margin: 0;
}

.idt-alert__content__link,
.idt-alert__content__link:hover {
  margin-top: .8rem;
  color: var(--grey-darker);
  text-decoration: underline;
}

.idt-alert__content__link:hover,
.idt-alert__content__link:hover:hover {
  color: var(--grey-darkest);
}

.idt-alert--success {
  --alert-color: var(--success-color);
}

.idt-alert--danger {
  --alert-color: var(--danger-color);
}

.idt-alert--info {
  --alert-color: var(--info-color);
}

.idt-alert--warning {
  --alert-color: var(--warning-color);
}

.alert-transition-enter-active,
.alert-transition-leave-active {
  transition: all .3s ease;
}

.alert-transition-enter,
.alert-transition-leave-to {
  opacity: 0;
  transform: translate3d(calc(100% + 22px), 0, 0);
}

