












































































































































































































































































































































































































.insight-editor {
  required-field {
    color: #F14040;
    font-size: 9pt;
  }
  .insight-card__banner {
    position: relative;
    text-align: center;
  }
  .upload-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 236px;
    height: 110px;
    margin: 0;
    padding: 1rem;
    background-color: var(--black-alpha60);
    transform: translate(-50%, -50%);

    &__title {
      margin-bottom: 0.5rem;
      color: var(--white);
      font-size: 1.2rem;
      font-weight: 300;
      text-align: center;
    }
    .upload-insight {
      display: block;
    }
    .upload-file__btn {
      width: 100%;
      padding: 0.85em 1em;
    }
  }
  &__switch {
    margin-right: 0.5rem;
  }
  &__title {
    margin: 0;
  }
  &__tags-selector {
    align-items: flex-end;

    ::v-deep .idt-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .idt-input-container {
      flex: 1;
    }
    button {
      flex: 0 0 auto;
      height: 48.6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &__tags {
    margin-bottom: var(--rem-size-16);

    ::v-deep .idt-tag {
      display: inline-block;
      margin-left: var(--rem-size-5);
      cursor: pointer;

      &.selected {
        color: var(--main-color-lighter);
        background-color: var(--main-color);
      }
    }
  }
}
