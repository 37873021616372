.modal-body--lobby-delete-message {
  padding-top: var(--rem-size-32) !important;
}

.modal-body--lobby-delete-message h3 {
  color: var(--grey-darkest);
  font-size: var(--rem-size-32);
}

.modal-body--lobby-delete-message .live-chat-message {
  margin-top: var(--rem-size-28);
  margin-bottom: var(--rem-size-12);
  background-color: var(--white) !important;
  text-align: left;
}

.modal-body--lobby-delete-message .live-chat-message-content__flex__content__text__reactions {
  display: none !important;
}

.modal-body--lobby-delete-message .button {
  font-weight: var(--font-weight-bold);
}

