.idt-survey-add-answer {
  --height: 38px;
}

.idt-survey-add-answer__button {
  width: 100%;
  height: var(--height);
  padding: 3px .5rem;
  text-align: left;
  border: 2px dashed var(--grey);
  color: var(--grey);
  cursor: pointer;
}

.idt-survey-add-answer__button ::v-deep .ideacon {
  margin-right: var(--rem-size-18);
}

.idt-survey-add-answer__input {
  position: relative;
}

.idt-survey-add-answer__input ::v-deep .idt-input {
  height: var(--height);
}

.idt-survey-add-answer__input__button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 var(--rem-size-10);
  height: var(--height);
  color: var(--main-color);
  cursor: pointer;
}

