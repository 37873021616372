



























































































.fo-project-section-how-it-works-edit {
  text-align: center;

  &__title {
    margin-bottom: var(--rem-size-16);
    color: black;
  }

  &__cards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: var(--rem-size-20);

    &__card {
      margin: var(--rem-size-5);
    }
  }
}
