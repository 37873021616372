
















































































@import '@/styles/mixins/index';

.modal-small {
  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  &__content {
    @include respond-to(max-small) {
      padding: 25px 20px;
    }
    @include respond-to(small) {
      padding: 45px 40px 25px;
    }
  }
}
