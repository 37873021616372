.bo-account-shortlist-home .btn-add-field {
  display: flex;
  align-items: center;
  max-width: 380px;
}

.bo-account-shortlist-home .btn-add-field__text {
  margin-left: 12px;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

