



















































































































.agenda-categories {
  &__content {
    &__cat-button {
        .button {
          height: 48px;
          width: 122px;
          font-size: var(--rem-size-16);
          font-weight: var(--font-weight-medium);
          margin: 0 0 1.75rem 0;
        }
      }
  }
  .button {
    margin: 0 0 1.75rem 0;
  }
}
