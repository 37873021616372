.idt-confirm-email {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--main-color-darkest);
}

@media only screen and (max-width: 991px) {
  .idt-confirm-email {
    margin: auto 10vw auto 15vw;
  }
}

@media only screen and (min-width: 992px) {
  .idt-confirm-email {
    margin: auto 20vw auto 30vw;
  }
}

.idt-confirm-email__content {
  position: relative;
  flex: 1 1 auto;
  margin-top: 5vw;
}

.idt-confirm-email__svg {
  position: absolute;
  top: -9vw;
  left: -17vw;
  width: 28vw;
}

.idt-confirm-email__title {
  font-size: 2.2rem;
  font-weight: var(--font-weight-bold);
}

.idt-confirm-email__text {
  font-size: 1.1rem;
}

.idt-confirm-email__email,
.idt-confirm-email__link {
  color: var(--main-color);
}

.idt-confirm-email__link {
  text-decoration: underline;
  cursor: pointer;
}

