.multiple-choices-field {
  max-width: 500px;
  padding: .2rem 0;
  background: none;
  border: none;
  box-shadow: none;
  text-align: left;
}

.multiple-choices-field__separator {
  position: relative;
  right: 10px;
  border-left: 1px solid var(--secondary-color);
}

.multiple-choices-field__separator .separator-error {
  margin-left: 10px;
}

.multiple-choices-field ::v-deep .box {
  background: var(--white);
}

