.idt-surveys {
  align-self: flex-end;
  width: 100%;
  max-width: calc(420px + 2rem);
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: auto;
}

.idt-surveys__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  padding: 1rem;
}

.idt-surveys__container .idt-survey-card {
  margin-bottom: 1rem;
}

