.fo-project-section-how-it-works-view {
  text-align: center;
  padding: var(--rem-size-20);
}

.fo-project-section-how-it-works-view__title {
  margin-bottom: var(--rem-size-16);
  color: #000;
}

.fo-project-section-how-it-works-view__description {
  margin-bottom: var(--rem-size-16);
}

.fo-project-section-how-it-works-view__cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.fo-project-section-how-it-works-view__cards__card {
  margin: var(--rem-size-16);
}

