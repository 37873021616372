.meeting-video {
  height: 100%;
  background: var(--grey-darkest);
}

@media only screen and (max-width: 991px) {
  .meeting-video {
    min-height: 500px;
  }
}

@media only screen and (max-width: 639px) {
  .meeting-video {
    min-height: 400px;
  }
}

.meeting-video__alert {
  position: absolute;
  width: 100%;
}

.meeting-video__alert__content {
  padding: 1rem;
  margin: 10px;
  top: 0;
  left: 0;
  color: var(--white);
  background-color: var(--red);
  border-radius: var(--global-radius);
  font-weight: var(--font-weight-bold);
}

.meeting-video__alert__close {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 1.2rem;
  border: none;
  color: var(--white);
  cursor: pointer;
}

.meeting-video--loading {
  position: relative;
}

.meeting-video--loading .idt-round-loader {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

