.fo-project-section-media-edit {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);
}

.fo-project-section-media-edit__cards {
  display: flex;
  flex-wrap: wrap;
}

.fo-project-section-media-edit__cards--required {
  flex-direction: column;
}

.fo-project-section-media-edit__cards__upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 10rem;
  margin: var(--rem-size-8);
  padding: var(--rem-size-32);
  border: 2px dashed var(--grey);
  border-radius: var(--global-radius);
}

.fo-project-section-media-edit__cards__upload-file--required {
  border-color: var(--red);
}

.fo-project-section-media-edit__cards__preview-file {
  width: 15rem;
  height: 10rem;
  margin: var(--rem-size-8);
}

.fo-project-section-media-edit__cards .idt-required-field {
  margin-left: var(--rem-size-8);
}

.fo-project-section-media-edit ::v-deep .file-preview__figure {
  width: 15rem;
  height: 10rem;
}

