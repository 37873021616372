.menu-item {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

@media only screen and (max-width: 991px) {
  .menu-item {
    padding: 0 .4rem;
  }
}

@media only screen and (min-width: 992px) {
  .menu-item {
    padding: 0 .75rem;
  }
}

.menu-item__link {
  display: block;
  position: relative;
  color: var(--grey-darker);
  border-radius: var(--global-radius);
  font-size: .75rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  line-height: 44px;
  text-decoration: none;
  cursor: pointer;
}

.menu-item.active .menu-item__link {
  color: var(--main-color);
}

@media only screen and (min-width: 992px) {
  .menu-item:not(.cta):hover:not(.account):after,
  .menu-item:not(.cta).active:not(.account):after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 100%;
    border-bottom: 3px solid var(--main-color);
  }
}

