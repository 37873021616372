.add-participant-modal {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
}

.add-participant-modal__link {
  text-decoration: underline;
}

.add-participant-modal__tickets__container .button {
  max-width: 120px;
  margin-right: 3px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.add-participant-modal__tickets__container .button:last-child {
  margin-right: 0px;
}

.add-participant-modal__tickets__container .button.selected {
  color: var(--white);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.add-participant-modal__report {
  max-height: 500px;
  overflow-y: auto;
}

.add-participant-modal__report__table {
  min-width: 500px;
  table-layout: fixed;
}

.add-participant-modal__loader label {
  color: var(--black-alpha70);
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}

.add-participant-modal__loader label i {
  color: var(--main-color);
  font-size: 16px;
}

.add-participant-modal__progress {
  position: relative;
  width: 100%;
  min-width: 300px;
  height: 22px;
  margin: 20px auto;
  background-color: var(--grey-light);
  border-radius: 0;
  overflow: hidden;
}

.add-participant-modal__progress__number {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  color: var(--main-color);
  font-size: 13px;
  font-weight: bold;
  line-height: 13px;
}

.add-participant-modal__progress__bar {
  height: 100%;
  background-color: var(--main-color);
  opacity: .8;
  transition: width .2s;
}

