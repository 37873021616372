



















































































































































@import '@/styles/mixins/index';

.idt-input-container {
  position: relative;

  &:not(.no-margin) {
    @include respond-to(max-medium) {
      padding-bottom: var(--rem-size-16);
    }
    @include respond-to(medium) {
      padding-bottom: var(--rem-size-24);
    }
  }
  &.limited {
    .idt-input {
      padding-right: 4rem;
    }
  }
  &.container-only {
    display: contents;
  }

  &__icon {
    position: absolute;
    bottom: var(--rem-size-30);
    right: var(--rem-size-15);
  }
}
.idt-input {
  margin-bottom: 0rem;

  &[disabled],
  &[readonly] {
    background-color: var(--grey-lighter);
    user-select: none;
  }
  &.has-error {
    border-color: var(--danger-color) !important;
  }
}
.idt-input-counter {
  position: absolute;
  right: 1rem;
  bottom: 2.1875rem;
  color: var(--grey-dark);
}
::v-deep .idt-required-field {
  position: absolute;
  bottom: 0;
  left: 0;
}
