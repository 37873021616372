









































































































.submenu-account-list {
  margin-top: var(--rem-size-12);

  &__labeled-separator,
  &__separator {
    width: calc(100% - var(--rem-size-20));
  }
  &__labeled-separator {
    margin-left: var(--rem-size-10);
  }
  &__separator {
    margin: var(--rem-size-10);
    border-color: var(--grey-light);
  }
}
