




























































































































@import '@/styles/mixins';

.fo-lobby-header {
  display: flex;
  align-items: flex-start;
  flex-flow: nowrap;
  gap: var(--rem-size-16);
  width: 100%;
  padding: var(--rem-size-16) var(--rem-size-24);
  border-bottom: 1px solid var(--grey-dark-alpha-50);

  @include respond-to(max-slarge) {
    flex-flow: wrap;
  }

  &__buttons {
    display: flex;
    gap: var(--rem-size-8);
    flex-shrink: 0;

    .fo-lobby-btn {
      height: 36px;
      font-size: var(--rem-size-14);
    }

    @include respond-to(max-slarge) {
      width: 100%;
      justify-content: space-between;

      .fo-lobby-header__more-menu {
        display: none;
      }
    }
  }
  &__title-wrapper {
    display: flex;
    flex-flow: column wrap;
    flex: auto;
    overflow: hidden;

    @include respond-to(slarge) {
      padding-top: var(--rem-size-8);
    }

    > * {
      width: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: var(--rem-size-8);
    position: relative;
    margin-bottom: 0;
    color: var(--grey-darkest);
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-bold);
    line-height: var(--rem-size-24);

    &__text {
      flex: auto;
    }
    .lobby-icon {
      line-height: var(--rem-size-16);

      @include respond-to(max-slarge) {
        &,
        .idt-icon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          height: 100%;
        }
      }

      .ideacon {
        color: var(--grey-darker);
        line-height: var(--rem-size-10);

        @include respond-to(max-slarge) {
          width: var(--rem-size-12) !important;
          height: var(--rem-size-12) !important;
        }
      }
    }
  }

  &__show-left-panel,
  &__show-right-panel {
    display: none;
    width: var(--rem-size-20);
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: var(--rem-size-4) 0;

    @include respond-to(max-slarge) {
      display: flex;
    }
  }

  &__show-left-panel {
    transform: translateX(-5px);
  }
  &__show-right-panel {
    transform: translateX(5px);
  }

  .read-more {
    margin: var(--rem-size-4) 0;
  }

  &__description {
    font-size: var(--rem-size-14);
    line-height: var(--rem-size-20);
    color: var(--grey-darkest-alpha75);
  }

  /* three dots menu */
  &__more-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    flex-shrink: 0;
    position: relative;
    width: var(--rem-size-36);
    height: var(--rem-size-36);
    color: var(--main-color-dark);
    background-color: var(--main-color-dark-alpha20);
    border-radius: 100%;
    transition: background-color .1s ease;
    cursor: pointer;
    z-index: 2;

    &:hover {
      background-color: var(--main-color-dark-alpha40);
    }
    .ideacon {
      transform: rotate(-90deg);
    }
    &__menu {
      position: absolute;
      bottom: calc(-1 * var(--rem-size-8));
      right: 0;
      background: var(--white);
      display: flex;
      flex-flow: column wrap;
      transform: translateY(100%);
      border: 1px solid var(--grey-dark-alpha-50);
      border-radius: var(--global-radius);

      > * {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--black);
        width: 100%;
        min-height: var(--rem-size-36);
        white-space: nowrap;
        padding: var(--rem-size-8) var(--rem-size-12);
        border-bottom: 1px solid var(--black-alpha10);
        transition: background-color .1s ease-in-out;

        &:hover {
          color: var(--black);
          background-color: var(--grey);
        }
      }
      > :last-child {
        border-bottom: 0;
      }
    }
  }
  // Just contextual-menu-fade in/out
  .contextual-menu-fade-enter,
  .contextual-menu-fade-leave-to {
    opacity: 0;
    bottom: 0;
  }
  .contextual-menu-fade-enter-to,
  .contextual-menu-fade-leave {
    opacity: 1;
    bottom: calc(-1 * var(--rem-size-8));
  }
  .contextual-menu-fade-enter-active,
  .contextual-menu-fade-leave-active {
    transition: opacity .15s ease, bottom .15s ease;
  }
}
