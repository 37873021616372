
























































































.idt-profile-upload-file {
  position: relative;

  &__hint {
    font-size: 0.75rem;
    font-style: italic;
    margin-top: .5rem;
  }

  &__remove {
    position: absolute;
    top: 1px;
    right: 1px;
    height: 38px;
  }
}
