




















































































































































































































.idt-dropdown {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  &--pane {
    display: block;
    position: fixed;
    width: auto;
    min-width: 150px;
    max-height: 400px;
    overflow: auto;
    background-color: #FFFFFF;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    z-index: 100;
    visibility: hidden;
    &.show {
      visibility: visible;
    }
    .menu {
      a {
        padding: 0.7rem 0.65rem;
      }
    }
  }
}
