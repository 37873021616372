



















































.modal-file-preview {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100));
  padding: 2rem 1.5rem;
  text-align: center;
  overflow: hidden;

  &__dialog {
    overflow-y: auto;

    &.dialog--iframe {
      height: 100%;
    }
    &__content{
      position: relative;

      &.content--image {
        display: inline-block;
      }
      &.content--iframe {
        height: 100%;
      }
      &__close {
        position: absolute;
        top: -40px;
        right: 0;
        font-size: 30px;
        color: var(--white);
        cursor: pointer;
      }
      &__iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__close {
    margin-bottom: 2rem;
    color: var(--white);
    font-size: 1.2rem;
    cursor: pointer;
  }
}
