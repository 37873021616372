























































































































































.bo-notification {
  .bo-tips {
    margin-bottom: 2rem;
  }
}
