.modal-conference {
  width: 100%;
  padding: 1rem;
}

@media only screen and (max-width: 1023px) {
  .modal-conference {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .modal-conference__subtitle {
    max-width: 550px;
    margin: auto;
  }
}

.modal-conference__content {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .modal-conference__content {
    flex-flow: column wrap;
  }
}

.modal-conference__content-left,
.modal-conference__content-right {
  flex-flow: column wrap;
  flex: 1 1 auto;
}

.modal-conference__content-left.choose,
.modal-conference__content-right.choose {
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .modal-conference__content-left.choose,
  .modal-conference__content-right.choose {
    padding: 0 90px;
  }
}

@media only screen and (min-width: 1024px) {
  .modal-conference__content-left.choose,
  .modal-conference__content-right.choose {
    max-width: 450px;
  }
}

@media only screen and (min-width: 640px) {
  .modal-conference__content-left:not(.choose),
  .modal-conference__content-right:not(.choose) {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 1024px) {
  .modal-conference__content-left:not(.choose),
  .modal-conference__content-right:not(.choose) {
    max-width: 600px;
  }
}

.modal-conference__content-left {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .modal-conference__content-left {
    margin-bottom: var(--rem-size-16);
  }
}

@media only screen and (min-width: 1024px) {
  .modal-conference__content-left.choose:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 70%;
    border-right: 1px solid var(--grey);
    transform: translateY(-50%);
  }
}

.modal-conference__content__icon {
  color: var(--grey-900);
  font-size: 7rem;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 1px;
}

.modal-conference__content__title {
  margin-bottom: var(--rem-size-16);
  color: var(--grey-darker);
  font-family: var(--title-font-family);
  font-size: var(--rem-size-20);
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (min-width: 1024px) {
  .modal-conference__content__text {
    min-height: 125px;
  }
}

.modal-conference__content__description {
  height: 140px;
  resize: none;
}

.choise-disabled {
  position: relative;
  pointer-events: none;
}

.choise-disabled:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white-alpha90);
  z-index: 1;
}

.choise-disabled__content {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: var(--main-color);
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.choise-disabled__content.white {
  color: #fff;
}

