.event-talks .slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
}

.event-talks .slick-slider.slick-dotted {
  margin-bottom: 37px;
}

.event-talks .slick-slider .slick-prev {
  margin-right: 25px;
}

.event-talks .slick-slider .slick-next {
  margin-left: 25px;
}

.event-talks .slick-slider .slick-disabled {
  opacity: .3;
}

.event-talks .slick-slider .slick-list {
  max-width: 350px;
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .event-talks .slick-slider .slick-list {
    max-width: 715px;
  }
}

@media only screen and (min-width: 1200px) {
  .event-talks .slick-slider .slick-list {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 1442px) {
  .event-talks .slick-slider .slick-list {
    max-width: 1200px;
  }
}

.event-talks .slick-slider .slick-list .slick-slide {
  padding: 0 5px;
}

@media only screen and (min-width: 640px) {
  .event-talks .slick-slider .slick-list .slick-slide {
    padding: 0 10px;
  }
}

.event-talks .slick-slider .slick-dots {
  display: block;
  position: absolute;
  bottom: -30px;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.event-talks .slick-slider .slick-dots li {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
}

.event-talks .slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
}

.event-talks .slick-slider .slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.event-talks .slick-slider .slick-dots li button:before {
  content: "•";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: var(--main-color);
  font-family: "slick";
  font-size: 1.5625rem;
  text-align: center;
  line-height: 20px;
  opacity: .5;
}

.event-talks .slick-slider .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border: solid 1px var(--main-color);
  border-radius: 50%;
}

.event-talks .slick-slider .arrow .ideacons {
  color: var(--main-color);
  font-size: 1.25rem;
}

.event-talks__see-more {
  margin-top: 40px;
  text-align: center;
}

