.conference-secondary-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--grey-lightest);
}

.conference-secondary-info__section-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.conference-secondary-info__speakers {
  width: 30%;
  margin: .9375rem 1.25rem;
}

.conference-secondary-info__description-container {
  width: 70%;
  margin: .9375rem 1.25rem;
}

.conference-secondary-info__description {
  margin-top: .9375rem;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  color: var(--black);
  text-align: justify;
}

