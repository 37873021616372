.idt-duration-picker__dialog {
  position: absolute;
  width: 220px;
  padding: var(--rem-size-14);
  background: var(--white);
  box-shadow: 0px 3px 6px rgba(0,0,0,.1607843137);
  z-index: 1;
}

.idt-duration-picker__dialog.top {
  bottom: 55px;
}

.idt-duration-picker__dialog.bottom {
  top: 100%;
}

.idt-duration-picker__dialog__label {
  text-align: center;
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-duration-picker__dialog__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.idt-duration-picker__dialog__buttons__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 8px 20px;
  border-radius: 50%;
  color: var(--black);
  background: var(--grey-light);
  cursor: pointer;
}

.idt-duration-picker__dialog__picker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  height: 48px;
  background: var(--grey-light);
  border-radius: var(--global-radius);
  color: var(--black);
}

.idt-duration-picker__dialog__picker__input {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-size: var(--rem-size-36);
  -moz-appearance: textfield;
  text-align: right;
}

.idt-duration-picker__dialog__picker__input:focus {
  border: none;
  background: none;
}

.idt-duration-picker__dialog__picker__input.hours {
  width: 80px;
}

.idt-duration-picker__dialog__picker__input.minutes {
  width: 40px;
}

.idt-duration-picker__dialog__picker__input::-webkit-outer-spin-button,
.idt-duration-picker__dialog__picker__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.idt-duration-picker__dialog__picker__span {
  font-size: var(--rem-size-24);
}

