



















































































@import '@/styles/mixins/index';

.idt-account-availabilities {
  &__define-screen {
    max-width: 500px;
    margin: 0 auto;

    @include respond-to(max-medium) {
      padding: var(--rem-size-24) 0;
    }
    @include respond-to(medium) {
      padding: var(--rem-size-24);
    }
  }
  &__description,
  &__question,
  &__choise {
    @include respond-to(max-medium) {
      margin-bottom: var(--rem-size-16);
    }
    @include respond-to(medium) {
      margin-bottom: var(--rem-size-24);
    }
  }
  &__choise {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      margin: 0 var(--rem-size-16);
    }
  }
}
