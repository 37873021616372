




















































.gamification-rewards {
  width: 100%;
}
