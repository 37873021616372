.fo-project-section-free-edit {
  text-align: center;
  margin: 2rem 3rem;
}

.fo-project-section-free-edit__title {
  color: var(--grey-darkest);
}

.fo-project-section-free-edit__content {
  margin-top: 2rem;
}

.fo-project-section-free-edit__content--required .froala-container {
  border: 1px solid var(--red);
  border-radius: 10px;
}

.fo-project-section-free-edit__content__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.fo-project-section-free-edit__add-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  margin-top: 2rem;
  border: 2px dashed var(--grey);
  border-radius: var(--global-radius);
}

.fo-project-section-free-edit__add-content--required {
  border-color: var(--red);
}

.fo-project-section-free-edit .idt-required-field {
  margin-top: var(--rem-size-8);
}

