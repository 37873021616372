.idt-account-secondary-informations {
  margin-top: 14px;
}

.idt-account-secondary-informations__phone,
.idt-account-secondary-informations__timezone {
  margin: 0 0 1rem;
}

.idt-account-secondary-informations__description {
  flex: 1 0 auto;
  width: 100%;
}

.idt-account-secondary-informations__description textarea {
  height: 160px;
  resize: none;
}

