.idt-survey-card-admin {
  position: relative;
  width: 100%;
  max-width: 420px;
  padding: 1rem;
  border: 1px solid #cacaca;
  background: var(--white);
  border-radius: var(--global-radius);
}

.idt-survey-card-admin__duration .progress {
  --progress-color: var(--main-color);
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 7px;
  margin: 0;
  border-radius: var(--global-radius) var(--global-radius) 0 0;
  overflow: hidden;
}

.idt-survey-card-admin__duration .progress.danger {
  --progress-color: var(--danger-color);
}

.idt-survey-card-admin__header {
  position: relative;
  padding-left: 33px;
}

.idt-survey-card-admin__header__icon ::v-deep .ideacon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  color: var(--main-color);
}

.idt-survey-card-admin__header__status {
  font-size: var(--rem-size-12);
}

.idt-survey-card-admin__header__question {
  line-height: 1rem;
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-card-admin__header__button-menu {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.idt-survey-card-admin__header .idt-survey-card-admin-menu {
  position: absolute;
  right: 0;
  top: 1rem;
}

.idt-survey-card-admin__content__results {
  padding-top: .5rem;
  border-bottom: 1px solid var(--grey-light);
}

.idt-survey-card-admin__content__results:not(.results-mode) {
  pointer-events: none;
}

.idt-survey-card-admin__content__results__title {
  margin-top: var(--rem-size-28);
  margin-bottom: var(--rem-size-18);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-card-admin__content__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.idt-survey-card-admin__content__row--actions {
  flex-direction: row-reverse;
}

.idt-survey-card-admin__content__row__duration {
  margin: 0;
  font-size: var(--rem-size-12);
  color: var(--black);
}

.idt-survey-card-admin__content__row__votes {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: .5rem;
  font-size: var(--rem-size-12);
  color: var(--black);
}

.idt-survey-card-admin__buttons {
  display: flex;
  align-items: center;
  position: absolute;
  height: 21px;
  left: 1rem;
  bottom: 1rem;
}

.idt-survey-card-admin__buttons__close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  background: var(--grey-lighter);
  cursor: pointer;
}

