




























































































@import '@/styles/mixins/index';

.idt-ticketing-blocking-modal-time {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;

  @include respond-to(medium) {
    font-size: 1.5rem;
  }

  .ideacon {
    margin-right: 5px;

    @include respond-to(medium) {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
    }

    &.idea-ht-scheduled {
      color: var(--main-color);
    }

    &.idea-ht-starting-colored {
      color: var(--yellow);
    }

    &.idea-ht-live-plain {
      color: var(--red);
    }

    &.idea-ht-warning {
      color: #f9662e;
    }

    &.idea-ht-play-disc {
      color: var(--white);
    }
  }
}
