

































































































































































@import '@/styles/mixins/index';

.meeting-card {
  display: flex;
  align-items: center;
  gap: var(--rem-size-16);
  position: relative;
  padding: 1.6rem 2rem;
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
  cursor: pointer;

  &__details {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &__title {
      font-size: 1rem;
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }
    &__description {
      display: flex;
      align-items: center;
      font-size: var(--rem-size-14);
      color: var(--grey-dark);

      & .idt-live-tag {
        text-transform: initial;
      }
      &__tag {
        margin-left: 1rem;

        & .idt-tag {
          height: var(--rem-size-24);
          padding: 2px var(--rem-size-24);
        }
        & .idt-tag.invited {
          background-color: var(--grey-light);
          color: var(--grey-dark);
        }
      }
    }
  }
  &__duration {
    width: 12rem;
    padding-left: 2rem;
    border-left: 2px solid var(--grey-lighter);

    &__label {
      font-size: var(--rem-size-12);
      font-weight: var(--font-weight-bold);
      color: var(--grey-dark);
    }
    &__value {
      font-size: var(--rem-size-14);
      color: var(--black);
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__action {
      width: var(--rem-size-26);
      height: var(--rem-size-26);
      border-radius: 50%;
      text-align: center;
      line-height: var(--rem-size-26);
      cursor: pointer;
      &:hover {
        background: var(--grey-lighter);
      }
    }
    & ::v-deep .idt-dropdown--pane {
      width: 100px;
      padding: var(--rem-size-10);
      cursor: pointer;
    }
    &__join-now {
      .button {
        margin-bottom: 0;
        border-radius: 1.5rem;
      }
    }
  }
}
