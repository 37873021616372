.fo-lobby-chat {
  display: flex;
  flex: auto;
  flex-flow: column;
  height: 100%;
  background: var(--white);
}

.fo-lobby-chat__content {
  flex: auto;
}

