.fo-project-section-custom-questions-view {
  margin: var(--rem-size-28);
}

.fo-project-section-custom-questions-view__title {
  margin-bottom: var(--rem-size-16);
  text-align: center;
  color: var(--grey-darkest);
}

.fo-project-section-custom-questions-view__card {
  margin: var(--rem-size-4) 0;
  padding: var(--rem-size-32);
  padding-bottom: var(--rem-size-16);
  background: var(--main-color-lighter);
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);
  border-radius: var(--rem-size-10);
}

