.idt-ticket-template {
  width: 100%;
  margin-left: 20px;
}

.idt-ticket-template .ticket-cosmetics {
  display: flex;
  justify-content: flex-end;
  height: 62px;
}

.idt-ticket-template .ticket-cosmetics__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
}

.idt-ticket-template .ticket-cosmetics__item label {
  font-size: 13px;
  font-weight: 500;
}

.idt-ticket-template .ticket-cosmetics__tooltip {
  position: relative;
  width: 28px;
  height: 28px;
}

.idt-ticket-template .ticket-cosmetics__tooltip .selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: solid 2px var(--black);
  border-radius: 50%;
  cursor: pointer;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content {
  display: none;
  position: absolute;
  top: -100vh;
  right: -35px;
  width: 214px;
  padding: 15px;
  background-color: var(--white);
  box-shadow: 0 8px 10px -5px var(--black-alpha20),0 8px 24px 2px var(--black-alpha14),0 6px 30px 5px var(--black-alpha12);
  transition: top 0s .5s,opacity .5s;
  z-index: 9999;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content:before {
  content: "";
  position: absolute;
  top: -10px;
  right: 42px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 10px 7.5px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) var(--white) rgba(0,0,0,0);
}

.idt-ticket-template .ticket-cosmetics__tooltip__content.show {
  display: block;
  top: 42px;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content label {
  display: block;
  color: var(--black);
  font-size: 13px;
  font-weight: 500;
  opacity: .4;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content .items {
  line-height: 0;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content .items .item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content .items .item--icon {
  height: 46px;
}

.idt-ticket-template .ticket-cosmetics__tooltip__content .items .item.selected {
  border: solid 2px var(--main-color);
}

.idt-ticket-template .ticket-cosmetics__tooltip__content .items .item__color {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.idt-ticket-template .ticket--content {
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
}

.idt-ticket-template .ticket-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--grey);
}

.idt-ticket-template .ticket-section .row {
  margin: 0 0 20px 0;
}

.idt-ticket-template .ticket-section .row:last-child {
  margin-bottom: 0;
}

.idt-ticket-template .ticket-section .row.time-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px 0;
}

.idt-ticket-template .ticket-section .row.time-choice label {
  display: inline-block;
  margin: 0 8px;
  color: var(--grey-darkest);
  font-size: 13px;
  font-weight: 500;
  opacity: .3;
}

.idt-ticket-template .ticket-section .row.price {
  display: flex;
  justify-content: center;
}

.idt-ticket-template .ticket-section .input-container {
  flex: 1 1 auto;
  position: relative;
  width: calc((100% - 40px)/2);
}

.idt-ticket-template .ticket-section .input-container input.live {
  padding-right: 30px;
}

.idt-ticket-template .ticket-section .input-container input.replay {
  padding-right: 42px;
}

.idt-ticket-template .ticket-section .input-container input.interview {
  padding-right: 57px;
}

.idt-ticket-template .ticket-section .input-container .suffix {
  display: block;
  position: absolute;
  right: 10px;
  top: 12px;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  z-index: 99;
  pointer-events: none;
}

.idt-ticket-template .ticket-section .input {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 38px;
  padding: 8px;
  margin-bottom: 0;
  border: solid 1px var(--grey);
  font-size: 13px;
  text-align: right;
}

.idt-ticket-template .ticket-section .input::-webkit-inner-spin-button,
.idt-ticket-template .ticket-section .input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.idt-ticket-template .ticket-section--name {
  padding: 10px 20px;
  border-bottom: 1px solid var(--grey);
}

.idt-ticket-template .ticket-section--name .input {
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 0 0 4px 0;
  margin-bottom: 0;
  color: #000;
  background-color: rgba(0,0,0,0);
  border: none;
  border-bottom: 1px solid var(--main-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
}

.idt-ticket-template .ticket-section--price {
  height: var(--bo-tk-price-height);
}

.idt-ticket-template .ticket-section--replay {
  height: var(--bo-tk-replay-height);
}

.idt-ticket-template .ticket-section--interview {
  height: var(--bo-tk-interview-height);
}

.idt-ticket-template .ticket-section--interview .row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.idt-ticket-template .ticket-section--interview .ui.fluid.search {
  width: 100%;
  height: 38px;
  min-height: 38px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 13px;
}

.idt-ticket-template .ticket-section--interview .ui.fluid.search.disabled {
  background-color: #d1d3d4;
}

.idt-ticket-template .ticket-section--interview .ui.fluid.search.disabled input {
  display: none;
}

.idt-ticket-template .ticket-section--interview .ui.fluid.search input {
  height: 38px;
  min-height: 38px;
  font-size: 13px;
  text-align: left;
}

.idt-ticket-template .ticket-section--interview .ui.fluid.search .item {
  font-size: 13px;
}

.idt-ticket-template .ticket-section--interview .mx-datepicker {
  width: 48%;
}

.idt-ticket-template .ticket-section--interview .mx-datepicker.disabled .mx-input-append {
  display: none;
}

.idt-ticket-template .ticket-section--interview .mx-datepicker .mx-input-wrapper input {
  width: 100%;
  text-align: left;
}

.idt-ticket-template .ticket-section--interview .time {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.idt-ticket-template .ticket-section--action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.idt-ticket-template .ticket-section--action .row {
  width: 100%;
}

.idt-ticket-template .ticket-section--action .row button {
  display: block;
  width: 100%;
  padding: 20px;
  color: var(--white);
  background-color: var(--secondary-color);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.idt-ticket-template .ticket-section .input-limit-desc {
  color: var(--black);
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  opacity: .5;
}

