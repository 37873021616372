


















































































































































































































































































































.statistics-chart {
  &--loading {
    display: flex;
    flex-direction: column;
  }
  &__status {
    margin-top: auto;
    margin-bottom: auto;

    .status__loader {
      width: 50px;
      height: 50px;
      margin: 1.5rem auto !important;
    }
  }
  .content__svg {
    .overlay {
      fill: none;
      pointer-events: all;
    }
  }
  .content__tab {
    flex-grow: 100;
  }
}
