.fo-project-section-votes-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: calc(var(--rem-size-4)*-1) 0 var(--rem-size-40);
  color: var(--grey-darkest);
}

.fo-project-section-votes-view--wrapper {
  margin: 2rem 3rem;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-votes-view--wrapper {
    margin: 1rem 1.5rem;
  }
}

.fo-project-section-votes-view__title {
  margin-bottom: var(--rem-size-24);
  color: var(--grey-darkest);
  text-align: center;
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-36);
  line-height: var(--rem-size-40);
}

.fo-project-section-votes-view__total-votes {
  display: flex;
  margin: var(--rem-size-4) var(--rem-size-24) var(--rem-size-4) 0;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-votes-view__total-votes {
    order: -3;
    margin: 0 0 var(--rem-size-12);
  }
}

.fo-project-section-votes-view__total-votes__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: var(--rem-size-16);
  border: 1.5px solid;
  border-radius: 100%;
}

.fo-project-section-votes-view__total-votes__bold {
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-medium);
}

.fo-project-section-votes-view__total-votes__small {
  font-size: var(--rem-size-14);
}

.fo-project-section-votes-view__total-comments {
  margin: var(--rem-size-4) 0 var(--rem-size-4) var(--rem-size-24);
  color: var(--main-color);
  font-size: var(--rem-size-14);
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-votes-view__total-comments {
    order: -2;
    margin-left: 0;
  }
}

.fo-project-section-votes-view__total-comments .ideacon {
  transition: transform .25s;
}

.fo-project-section-votes-view__total-comments--open .ideacon {
  transform: rotate(180deg);
}

.fo-project-section-votes-view__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--rem-size-4) var(--rem-size-8);
  padding: var(--rem-size-10) var(--rem-size-24);
  border: 1px solid;
  border-radius: 99px;
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  transition: color .25s,background-color .25s;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-votes-view__btn {
    width: 100%;
    margin: var(--rem-size-4) 0;
  }
}

.fo-project-section-votes-view__btn--disabled {
  opacity: .4;
  cursor: auto;
}

.fo-project-section-votes-view__btn--color0 {
  color: var(--project-vote-0);
}

.fo-project-section-votes-view__btn--color0.fo-project-section-votes-view__btn--selected {
  color: var(--white);
  background: var(--project-vote-0);
}

.fo-project-section-votes-view__btn--color1 {
  color: var(--project-vote-1);
}

.fo-project-section-votes-view__btn--color1.fo-project-section-votes-view__btn--selected {
  color: var(--white);
  background: var(--project-vote-1);
}

.fo-project-section-votes-view__btn--color2 {
  color: var(--project-vote-2);
}

.fo-project-section-votes-view__btn--color2.fo-project-section-votes-view__btn--selected {
  color: var(--white);
  background: var(--project-vote-2);
}

.fo-project-section-votes-view__filter {
  margin: var(--rem-size-4) 0;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-votes-view__filter {
    width: 100%;
    margin: var(--rem-size-20) 0;
  }
}

.fo-project-section-votes-view__filter .vs__selected .column,
.fo-project-section-votes-view__filter .idt-select-container {
  padding: 0;
}

.fo-project-section-votes-view__filter .v-select {
  margin: 0 !important;
}

.fo-project-section-votes-view__spacer {
  flex: 1;
}

@media only screen and (max-width: 991px) {
  .fo-project-section-votes-view__spacer {
    display: none;
  }
}

.fo-project-section-votes-view__input {
  padding: var(--rem-size-24);
  border: var(--rem-size-1) solid var(--grey-lighter);
  border-radius: var(--global-radius);
}

.fo-project-section-votes-view__input__title {
  margin-bottom: var(--rem-size-8);
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-24);
  line-height: var(--rem-size-30);
}

.fo-project-section-votes-view__input__subtitle {
  margin-bottom: var(--rem-size-32);
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
}

.fo-project-section-votes-view__input .fo-comment-input {
  border-radius: var(--global-radius);
}

.fo-project-section-votes-view__input--color0 .fo-project-section-votes-view__input__title__color {
  color: var(--project-vote-0);
}

.fo-project-section-votes-view__input--color0 .fo-comment-input {
  background: var(--project-vote-light-0);
}

.fo-project-section-votes-view__input--color1 .fo-project-section-votes-view__input__title__color {
  color: var(--project-vote-1);
}

.fo-project-section-votes-view__input--color1 .fo-comment-input {
  background: var(--project-vote-light-1);
}

.fo-project-section-votes-view__input--color2 .fo-project-section-votes-view__input__title__color {
  color: var(--project-vote-2);
}

.fo-project-section-votes-view__input--color2 .fo-comment-input {
  background: var(--project-vote-light-2);
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all .25s;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

