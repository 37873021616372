






































.idt-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &__image {
    display: block;
    backface-visibility: hidden;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 230px;
    height: 114px;
    color: var(--white);
    background: var(--black-alpha60);
    text-align: center;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }
  &:hover &__content {
    opacity: 1;
  }
}
