.lobby-user-list {
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey-darkest-alpha20);
  overflow-y: auto;
}

.lobby-user-list ::v-deep .idt-input-container {
  padding: var(--rem-size-10) var(--rem-size-24);
}

.lobby-user-list .vs__dropdown-toggle {
  height: 40px;
}

.lobby-user-list .vs__search {
  margin: 0;
  padding-left: var(--rem-size-8) !important;
}

.lobby-user-list .idt-select-container {
  height: 50px;
}

.lobby-user-list__container {
  border-top: 1px solid var(--grey-light);
  overflow: auto;
}

@media only screen and (max-width: 991px) {
  .lobby-user-list__container {
    height: calc(var(--vh, 1vh)*100 - var(--menu-height)*3 - 66px);
  }
}

@media only screen and (min-width: 992px) {
  .lobby-user-list__container {
    height: calc(var(--vh, 1vh)*100 - var(--menu-height)*3 - var(--rem-size-48));
  }
}

.lobby-user-list__container div[role=group] :last-child .lobby-user-list-item {
  border: none;
}

.lobby-user-list ::v-deep .idt-user-list-item {
  padding-right: var(--rem-size-24);
  padding-left: var(--rem-size-24);
}

