.idt-account-professional-informations {
  &__title {
    width: 100%;
    margin-top: 1.8rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: var(--font-weight-medium);
    text-align: center;
    text-transform: uppercase;

    @include respond-to(slarge) {
      margin-top: 0;
      margin-bottom: 1.8rem;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
  }
  &__company, &__website, &__job, &__status {
    flex-grow: 1;
    width: 100%;
    margin: 0 10px;

    @include respond-to(slarge) {
      flex-basis: calc(50% - 20px);
    }
  }
}
