



















































.idt-profile-invoices {
  border-top: 2px solid var(--grey);
  margin: 0 -30px;
  padding: 0 30px;
  padding-top: 24px;
  margin-top: 25px;

  &__title {
    color: var(--black);
    font-weight: var(--font-weight-bold);
    margin-bottom: 23px;
  }

  &__no-content {
    margin: auto;
    text-align: center;

    h5 {
      margin-top: 15px;
    }
  }

  &__invoice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    padding: 0 25px;
    border: 1px solid var(--grey-lighter);
    border-radius: var(--global-radius);
    margin-bottom: 10px;

    &__title {
      color: var(--black);
      font-weight: var(--font-weight-bold);
      margin: 0;
    }

    &__download-button {
      min-width: 134px;
      height: 30px;
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 15px;
      font-weight: var(--font-weight-bold);
      padding: 0 14px;
      margin: 0;
    }
  }
}
