.menu-conference-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100px;
  margin: 2px 0;
  padding: 1rem .75rem 1rem 1.25rem;
  color: var(--main-color);
  background-color: var(--main-color-lighter);
  transition: background-color .3s ease-in-out;
}

.menu-conference-item:hover {
  background-color: var(--main-color-alpha40);
}

.menu-conference-item.is-starting {
  color: var(--color-starting);
  background-color: var(--color-starting-alpha20);
}

.menu-conference-item.is-starting:hover {
  background-color: var(--color-starting-alpha30);
}

.menu-conference-item.is-now {
  color: var(--secondary-color);
  background-color: var(--secondary-color-alpha20);
}

.menu-conference-item.is-now:hover {
  background-color: var(--secondary-color-alpha30);
}

.menu-conference-item.converting {
  color: var(--main-color-darker);
  background-color: var(--main-color-darker-alpha20);
}

.menu-conference-item.converting:hover {
  background-color: var(--main-color-darker-alpha30);
}

.menu-conference-item.replay {
  color: var(--grey-darkest);
  background-color: var(--grey-light);
}

.menu-conference-item.replay:hover {
  background-color: var(--grey-lighter);
}

.menu-conference-item.canceled {
  color: var(--grey-dark);
  background-color: var(--grey);
  pointer-events: none;
}

.menu-conference-item__speaker {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  max-width: calc(100% - 125px);
  color: var(--grey-darkest);
  line-height: 20px;
}

.menu-conference-item__speaker__avatar {
  margin-right: 10px;
}

.menu-conference-item__speaker__info {
  max-width: 95%;
}

.menu-conference-item__speaker__info .conference-track {
  font-size: .75rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.menu-conference-item__speaker__info .conference-title {
  width: 100%;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.menu-conference-item__speaker__info .conference-speaker-name {
  font-size: .75rem;
}

.menu-conference-item__state {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
}

.menu-conference-item__state__info {
  margin-right: var(--rem-size-10);
}

.menu-conference-item__state__info.scheduled,
.menu-conference-item__state__info.converting {
  font-size: .8rem;
  text-align: right;
  line-height: 1.2;
}

.menu-conference-item__state .live-dot {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: var(--color-live);
  border: 1px solid var(--white);
  border-radius: 100%;
}

.menu-conference-item__stared {
  position: absolute;
  top: .5rem;
  left: .5rem;
}

.menu-conference-item.premium {
  color: var(--grey-dark);
  background-color: var(--grey-lighter);
}

.menu-conference-item.premium:hover {
  color: var(--grey-darker);
  background-color: var(--grey-lightest);
}

.menu-conference-item .conference-premium-badge {
  top: 0;
  right: 0;
}

