.mx-datepicker {
  width: 100%;
}

.mx-datepicker ::v-deep .mx-input {
  height: auto;
  padding: .6566rem 1rem;
  margin: 0;
  color: var(--black);
}

.bo-date-location__separator {
  color: var(--grey);
  font-size: 1.5625rem;
  font-weight: 300;
  text-align: center;
  line-height: 48px;
}

@media only screen and (min-width: 1024px) {
  .bo-date-location__separator {
    padding-top: 1.875rem;
  }
}

.bo-date-location__select {
  height: 48px;
}

