.mentor {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  width: 100%;
  height: 300px;
  background-color: var(--white);
  border-radius: var(--global-radius);
  cursor: pointer;
}

.mentor__extra {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.mentor__extra__book {
  display: block;
}

.mentor__extra__message {
  margin-bottom: var(--rem-size-8);
  z-index: 10;
}

.mentor ::v-deep .idt-tag-list {
  margin-top: 5px;
}

.non-clickable {
  cursor: default;
}

