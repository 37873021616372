























































































































































































































.idt-survey-modal-results {

  &__card {
    margin-bottom: 1.5rem;
  }

  &__user-list {
    max-height: 400px;
    padding-bottom: 10px;
    overflow-y: auto;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__hint {
        color: var(--black);

        .color {
          color: var(--main-color);
        }
      }

      .idt-select-container {
        padding: 0;
      }
    }

    &__item {
      padding: 1rem .5rem;
      border-top: 1px solid var(--grey);

      & > div {
          display: flex;
          align-items: center;
      }

      &__avatar {
        margin-right: var(--rem-size-10);
        cursor: pointer;
      }

      &__name {
        margin: 0;
        color: var(--black);
        font-weight: var(--font-weight-bold);
        font-size: var(--rem-size-14);
      }

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        padding: 0;
        margin: 0;
        margin-left: auto;
        border-radius: 50%;
      }

      &__value {
        margin-top: .6rem;
        padding: .8rem;
        background-color: var(--white);
        border-radius: var(--global-radius);
      }
    }
  }
}
