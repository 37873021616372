.filters {
  height: 100%;
  width: 300px;
  background-color: var(--white);
  margin-right: var(--rem-size-16);
  overflow-y: auto;
  padding-top: var(--rem-size-16);
}

.filters__title {
  margin-left: 20px;
  margin-top: 18px;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  letter-spacing: .7px;
  text-align: center;
  text-transform: uppercase;
}

.filters__close-btn {
  margin-right: 20px;
  cursor: pointer;
}

.filters__close-btn:hover {
  color: var(--red);
}

.filters__categories {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 18px;
}

.filters__categories__category {
  padding-bottom: var(--rem-size-8);
}

.filters__categories__category__container {
  align-items: center;
  min-height: 25px;
  border-radius: 25px;
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  word-break: break-word;
}

.filters__categories__category__container.opened {
  background-color: var(--main-color);
  color: var(--white);
}

.filters__categories__category__container.closed {
  background-color: var(--grey-lighter);
  color: var(--black);
}

.filters__categories__category__container__selected-number {
  height: 15px;
  width: 15px;
  background-color: var(--main-color);
  border-radius: 100%;
  color: var(--white);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-10);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.filters__categories__category__container:hover {
  cursor: pointer;
}

.filters__categories__category__tracks {
  margin-top: 10px;
}

.filters__categories__category__tracks__container {
  align-items: center;
  margin-bottom: 10px;
  margin-right: 14px;
  cursor: pointer;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0px;
  line-height: 16px;
  text-align: left;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.filters__categories__category__tracks__container:hover {
  color: var(--grey-darker);
}

.filters__categories__category__tracks__container__customCheckMark {
  height: 18px;
  width: 18px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
}

.filters__categories__category__tracks__container__customCheckMark:after {
  content: "";
  display: none;
  position: relative;
}

.filters__categories__category__tracks__container input {
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
}

.filters__categories__category__tracks__container input:checked~.filters__categories__category__tracks__container__customCheckMark {
  background-color: var(--main-color);
}

.filters__categories__category__tracks__container input:checked~.filters__categories__category__tracks__container__customCheckMark:after {
  display: flex;
}

.filters__categories__category__tracks__container .filters__categories__category__tracks__container__customCheckMark:after {
  left: 6px;
  height: 14px;
  width: 7px;
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.filters__categories__category__tracks__separation {
  margin: 0;
  margin-bottom: var(--rem-size-8);
}

