.unlock {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: var(--rem-size-32);
  height: var(--rem-size-32);
  margin: 0;
  background-color: var(--red);
  border-radius: var(--rem-size-16);
  color: var(--white);
}

.unlock__icon {
  display: inline-block;
  margin-left: 7px;
}

.unlock__text {
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-14);
  overflow: hidden;
  text-align: left;
  transition: max-width 300ms,padding-left 300ms,padding-right 300ms;
  white-space: nowrap;
}

.unlock:hover .unlock__text {
  display: inline-block;
  max-width: 400px;
  padding-left: 15px;
  padding-right: 15px;
  transition-delay: .1s;
}

