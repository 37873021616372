


















































































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.facebook {
  background-color: var(--facebook-color);
}
.youtube {
  background-color: var(--youtube-color);
}
.linkedin {
  background-color: var(--linkedin-color);
}
.twitter {
  background-color: var(--twitter-color);
}
.hide {
  display: none;
}
.sub-block {
  padding-top: var(--rem-size-40);
}
.social-media {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--rem-size-8);
    margin-bottom: var(--rem-size-8);

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
      color: var(--white);
      border-radius: 50%;
    }
    &__input {
      width: calc(100% - (40px + 12px));
      padding-bottom: 0 !important;
      &.error {
        padding-bottom: var(--rem-size-24) !important;
      }

      ::v-deep .idt-required-field {
        position: initial;
      }
    }
  }
}
.fo-project-section-information-edit {
  @include respond-to(max-medium) {
    --image-preview-height: 10rem;
    padding: var(--rem-size-20) var(--rem-size-20) var(--rem-size-40) var(--rem-size-20);
  }
  @include respond-to(medium) {
    --image-preview-height: 372px;
    padding: 48px;
  }

  h2 {
    margin-bottom: var(--rem-size-24);
    font-size: var(--rem-size-24);
  }
  &__medias {
    .tabs-selection {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      background-color: var(--grey-light);

      &__button {
        width: 50%;
        margin: var(--rem-size-4);
        color: var(--grey-darkest);
        background-color: transparent;
        border-radius: 0;
        font-weight: bold;

        &:first-child {
          margin-right: var(--rem-size-2);
        }
        &:last-child {
          margin-left: var(--rem-size-2);
        }
        &.selected {
          color: var(--main-color);
          background-color: var(--white);
          box-shadow: 0px 1px 1px var(--black-alpha15);
        }
      }
    }
    .tab {
      padding-top: var(--rem-size-16);

      &__image {
        &__container {
          display: flex;
          flex-wrap: wrap;

          &__preview-file {
            position: relative;
            width: 100%;

            &__component {
              width: 100%;
              height: var(--image-preview-height);
              min-height: 200px;
            }
            &__actions {
              display: inline-grid;
              gap: var(--rem-size-8);
              grid-template-columns: 1fr 1fr;
              position: absolute;
              top: 50%;
              left: 50%;
              padding: var(--rem-size-10);
              background-color: var(--grey-darkest-alpha75);
              border-radius: var(--rem-size-5);
              transform: translate(-50%, -50%);
              z-index: 5;
            }
          }
          &__upload-file {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: var(--image-preview-height);
            min-height: 200px;
            padding: var(--rem-size-32);
            border: 1px dashed var(--grey);
            border-radius: var(--global-radius);

            &.error {
              border-color: var(--red);
            }
          }
          .upload-infos {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: var(--rem-size-8);
            font-size: var(--rem-size-14);
          }
        }

        ::v-deep .file-preview__figure {
          width: 100%;
          height: var(--image-preview-height);
          min-height: 200px;
          border-radius: var(--rem-size-12);
        }
      }
    }
  }
  &__logo {
    display: flex;
    justify-content: space-between;

    .project-logo {
      width: 96px;
      height: 96px;

      &__letter,
      &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: var(--grey-darkest);
        background-color: var(--grey-lighter);
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        font-size: var(--rem-size-24);
      }
    }
    .project-logo-actions {
      width: calc(100% - (96px + 24px));

      &__text {
        &__title {
          color: var(--grey-darkest);
        }
        &__resolution {
          color: var(--grey-darker);
        }
      }
      &__buttons {
        margin-top: var(--rem-size-4);

        @include respond-to(medium) {
          display: flex;
          justify-content: flex-start;
          gap: var(--rem-size-8);
        }
      }
    }
  }
  &__ordered {
    display: flex;
    flex-direction: column;

    &.no-social-links {
      .fo-project-section-information-edit__ordered__about,
      .fo-project-section-information-edit__ordered__links {
        width: 100% !important;
        padding-right: 0;
      }
    }

    @include respond-to(medium) {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: var(--rem-size-64);
    }

    &--description {
      margin-bottom: var(--rem-size-4);
      padding: 0;
      border: none;

      ::v-deep .idt-textarea-counter {
        bottom: var(--rem-size-8);
      }
    }
    &__about {
      order: 1;
      flex: 1 1 auto;
      min-width: 50%;
    }
    &__social-medias {
      @include respond-to(max-slarge) {
        order: 3;
      }
      @include respond-to(slarge) {
        order: 2;
      }
      @include respond-to(max-slarge) {
        width: 100%;
      }
      @include respond-to(slarge-large) {
        width: 33%;
      }
      @include respond-to(large-xlarge) {
        width: 75%;
      }
      @include respond-to(xlarge) {
        width: 33%;
      }
    }
    &__links {
      @include respond-to(max-slarge) {
        order: 2;
      }
      @include respond-to(slarge) {
        order: 3;
      }
      @include respond-to(max-slarge) {
        width: 100%;
      }
      @include respond-to(large-xlarge) {
        width: 75%;
      }
      @include respond-to(xlarge) {
        width: calc(100% - (33% + var(--rem-size-64)));
      }
    }
  }
}
