.single-sponsor-page {
  padding-top: 75px;
}

.header {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 639px) {
  .header {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 640px) {
  .header {
    flex-flow: row wrap;
    margin-bottom: 60px;
  }
}

.header__image {
  flex: 0 0 auto;
  position: relative;
  width: 230px;
  height: 114px;
  transition: .3s ease-in-out;
  opacity: 1;
  backface-visibility: hidden;
  object-fit: contain;
}

@media only screen and (max-width: 639px) {
  .header__image {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 640px)and (max-width: 991px) {
  .header__image {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .header__image {
    margin-right: 40px;
  }
}

.header__image .idt-card-file-upload-header__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: none !important;
  z-index: 10;
}

.header__connect {
  flex: 1 0 auto;
}

@media only screen and (max-width: 991px) {
  .header__connect {
    width: 100%;
  }
}

.header__connect__title {
  color: var(--black);
  font-family: var(--title-font-family);
  line-height: 1.875rem;
}

@media only screen and (max-width: 639px) {
  .header__connect__title {
    margin-bottom: var(--rem-size-16);
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 640px) {
  .header__connect__title {
    margin-bottom: var(--rem-size-8);
    font-size: 1.5rem;
  }
}

.header__connect__cards {
  display: flex;
}

@media only screen and (max-width: 639px) {
  .header__connect__cards {
    flex-direction: column;
  }
}

.header__toggle-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.intro__media {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  object-fit: contain;
}

.intro__media .idt-card-file-upload-header__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: none !important;
  z-index: 10;
}

.intro__media.no-media {
  height: 400px;
}

.intro__media.no-media .idt-card-file-upload-header__preview {
  height: 400px;
}

.intro__navigation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 400px;
}

.intro__froala {
  width: 100%;
  margin-top: 20px;
}

.talks-speakers {
  width: 100%;
  background: var(--grey-lighter);
}

.talks-speakers__row:last-child {
  padding-bottom: 50px;
}

.talks-speakers__headers {
  margin: 50px 0;
  color: var(--black);
  font-family: var(--title-font-family);
  font-size: 2rem;
  text-align: center;
  line-height: 2.5rem;
}

.talks-speakers__column {
  flex: 0 0 auto;
  width: 100%;
  max-width: 398px;
  padding: 0 12px 24px 12px;
}

.idt-card-file-upload-header__preview {
  padding-top: 0;
  height: 100%;
}

