




































































.bo-custom-questions-add {
   margin: var(--rem-size-16);

   &__title {
     margin-bottom: 15px;
     color: var(--grey-darkest);
     font-family: var(--text-font-family);
     font-weight: var(--font-weight-light);
     font-size: 1.875rem;
     text-align: center;
     text-transform: uppercase;
   }

   &__description {
     margin-bottom: 25px;
     color: var(--main-color);
     font-family: var(--text-font-family);
     font-weight: var(--font-weight-normal);
     font-size: 1rem;
     text-align: center;
   }
}
