.idt-messenger-invitation-modal {
  display: block;
  position: absolute;
  top: 44px;
  width: 100%;
  height: calc(100% - 44px);
  background-color: var(--grey-lightest);
  border-top: solid 1px var(--grey-light);
  z-index: 999;

  .idt-invitation__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 12px;
    background-color: var(--white);
    border-bottom: solid 1px var(--grey-light);

    .idt-invitation__back-btn {
      width: 20px;
      height: 20px;
      cursor: pointer;

      i {
        color: var(--main-color);
        font-size: 1.4rem;
      }
    }
    .idt-invitation__header-title {
      width: calc(100% - 20px);
      padding-right: 20px;
      text-align: center;
    }
  }
  .idt-invitation__container {
    width: 100%;
    height: calc(100% - 44px - 66px);

    .idt-invitation__toolbar {
      margin: 0 15px;
      border-bottom: solid 1px var(--grey-light);

      .idt-history__toolbar__searchbar {
        margin-left: 0;
        padding: 15px 0;

        input {
          margin-bottom: 0;
        }
      }
    }
    .idt-invitation__user__container {
      height: calc(100% - 80px);
      padding: 0 15px;
      overflow: auto;

      .idt-invitation__user__list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          display: flex;
          border-bottom: solid 1px var(--grey-light);

          &:last-child {
            border-bottom: none;
          }
        }
        .idt-invitation__user {
          &__card {
            align-items: center;
            padding: 10px 0;
            cursor: pointer;
          }
          &__avatar {
            flex: 0 0 auto;
            position: relative;
            width: 41px;
            height: 41px;
            background-color: var(--white);
            border: 2px solid var(--white);
            border-radius: 100%;
          }
          &__content {
            flex: 1 1 auto;
            padding: 0 0.75rem;
          }
          &__name {
            color: var(--black);
            font-size: 1.05rem;
          }
          &__tag {
            color: var(--black-aplha70);
            font-size: 0.75rem;
            text-transform: uppercase;

            .mentor {
              color: var(--main-color);

              &__line {
                display: inline-block;
                width: 1px;
                height: 11px;
                margin: 0 0.5rem;
                background-color: var(--grey-dark);
                vertical-align: middle;
              }
            }
          }
          &__actions {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex: 0 0 auto;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .idt-invitation__validation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 66px;
    background-color: var(--main-color);
    border-radius: 0;
    cursor: pointer;

    .idea-ht-checked {
      margin-left: 0.25rem;
      font-size: 0.8rem;
    }
  }
}
