.alert-card {
  display: flex;
  width: 90%;
  padding: var(--rem-size-16);
  border-radius: var(--global-radius);
  background-color: var(--grey-light);
}

.alert-card__icon {
  margin-right: 1rem;
}

.alert-card__content__header {
  margin-top: .2rem;
  color: var(--black);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
}

@media only screen and (max-width: 479px) {
  .alert-card__content__header {
    font-size: var(--rem-size-12);
  }
}

.alert-card__content__text {
  font-size: 14px;
  font-weight: var(--font-weight-normal);
  line-height: var(--rem-size-24);
}

@media only screen and (max-width: 479px) {
  .alert-card__content__text {
    font-size: var(--rem-size-12);
  }
}

