.how-it-works-card-add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 530px;
  padding: var(--rem-size-16);
  border-radius: var(--global-radius);
  border: 1px dashed var(--grey-light);
}

.how-it-works-card-add__button:hover {
  background-color: var(--grey-dark);
  color: var(--black) !important;
}

