



























































































































@import '@/styles/mixins/index';

.fo-project-section-issue-and-solution-edit {
  display: flex;
  flex-direction: row;
  margin: 2rem 3rem;

  @include respond-to(max-medium) {
    flex-direction: column;
    margin: 1rem 1.5rem;
  }

  &__part {
    flex: 1;
    max-width: 50%;

    @include respond-to(max-medium) {
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }

    &:first-of-type {
      margin-right: var(--rem-size-24);
    }
    &__title {
      margin-bottom: 1rem;
      font-size: var(--rem-size-24);
      font-weight: bold;
    }
  }
  &__subpart {
    margin-bottom: var(--rem-size-32);

    &--required {
      .froala-container {
        border: 1px solid var(--red);
        border-radius: 10px;
      }
    }
  }
  .idt-required-field {
    margin-top: var(--rem-size-8);
  }
}
