.idt-checkbox {
  margin-bottom: 7px;
  padding: .5rem;
  background: var(--white);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
}

.idt-checkbox--no-border {
  box-shadow: none !important;
  border: 0 !important;
}

.idt-checkbox--no-padding {
  padding: 0;
}

.idt-checkbox--checked {
  box-shadow: 0px 2px 3px rgba(0,0,0,.15);
  border-color: rgba(0,0,0,0);
}

.idt-checkbox--checked .content {
  font-weight: var(--font-weight-medium);
}

.idt-checkbox input {
  display: none;
}

.idt-checkbox input.error+label .box {
  border: 1px solid var(--red);
}

.idt-checkbox input+label {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 22px;
  min-width: 100px;
  margin: 0;
  padding: 0;
  color: var(--text-color, var(--grey-darker));
  font-weight: var(--font-weight-normal);
}

.idt-checkbox input+label .box {
  content: "";
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  line-height: 0;
  border: 1px solid var(--text-color, var(--grey));
  border-radius: 3px;
}

.idt-checkbox input+label .content {
  flex-grow: 1;
}

.idt-checkbox input+label .content ::v-deep p:last-child {
  margin-bottom: 0;
}

.idt-checkbox input+label .idt-icon {
  color: rgba(0,0,0,0);
}

.idt-checkbox input:checked+label .box {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}

.idt-checkbox input:checked+label .idt-icon {
  color: #fff;
}

.idt-checkbox__slot-end {
  flex-grow: 0;
}

