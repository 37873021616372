












































































































.idt-stacked-user-avatars {
  display: flex;
  border-radius: var(--global-radius);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--grey-lighter);
    box-shadow: 0 0 0 var(--rem-size-4) var(--grey-lighter);
    cursor: pointer;
  }

  .idt-user-avatar:not(:first-child),
  &__has-more {
    margin-left: -16px;
  }

  .idt-user-avatar {
    border: 2px solid var(--white);
  }
  .idt-users-popover {
    ::v-deep .idt-dropdown {
      &--toggle {
        display: flex;
      }
    }
  }

  &__has-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    font-size: var(--rem-size-14);
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-radius: 50%;
    color: var(--main-color);
    font-weight: var(--font-weight-bold);
    z-index: 1;
  }
}
