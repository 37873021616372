





















































@import '@/styles/mixins/index';

.mentor {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  width: 100%;
  height: 300px;
  background-color: var(--white);
  border-radius: var(--global-radius);
  cursor: pointer;

  &__extra {
    display: flex;
    flex-flow: column;
    align-items: flex-end;

    &__book {
      display: block;
    }

    &__message {
      margin-bottom: var(--rem-size-8);
      z-index: 10;
    }
  }

  ::v-deep .idt-tag-list {
    margin-top: 5px;
  }
}

.non-clickable {
  cursor: default;
}
