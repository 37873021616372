.sponsor-edit-modal {
  margin: -45px -40px -25px;
}

.sponsor-edit-modal__content,
.sponsor-edit-modal__buttons,
.sponsor-edit-modal__title {
  padding: var(--rem-size-24) var(--rem-size-40);
  border-bottom: 1px solid var(--grey);
}

.sponsor-edit-modal__title {
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-18);
  line-height: var(--rem-size-24);
  color: var(--black);
}

.sponsor-edit-modal__buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: none;
}

.sponsor-edit-modal__buttons .button {
  margin: 0;
}

.sponsor-edit-modal .idt-textarea-container {
  padding-bottom: 0;
}

.sponsor-edit-modal .idt-textarea-container label {
  margin-bottom: var(--rem-size-8);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-18);
  color: var(--black);
}

.sponsor-edit-modal .idt-textarea-container .idt-textarea-counter {
  bottom: 0;
  font-size: var(--rem-size-10);
  line-height: var(--rem-size-12);
}

