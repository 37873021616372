.idt-favorite-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: .7rem;
  color: var(--main-color);
  border: 1.5px solid var(--main-color);
  border-radius: 23px;
  font-weight: var(--font-weight-bold);
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.idt-favorite-button .idt-icon {
  margin-top: -3px;
  color: var(--main-color);
}

.idt-favorite-button.stared {
  color: var(--white);
  background: var(--main-color);
}

.idt-favorite-button.stared .idt-icon {
  color: var(--white);
}

.idt-favorite-button__label {
  margin-left: 10px;
}

