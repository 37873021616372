// -----------------------------
// 1. Notification point
// -----------------------------
@mixin notificationPoint($txt-color, $bg-color, $position: 'inline', $side: 'left', $x-pos: 0, $y-pos: 0) {
  width: 21px;
  height: 21px;
  color: $txt-color;
  background-color: $bg-color;
  border-radius: 50%;
  font-size: 0.70rem;
  text-align: center;
  line-height: 21px;

  @if ($position == 'absolute') {
    @include absoluteNotificationPoint($side, $x-pos, $y-pos);
  }
  @else if ($position == 'inline') {
    @include inlineNotificationPoint();
  }
}



@mixin absoluteNotificationPoint($side, $x-pos, $y-pos) {
  position: absolute;
  top: 0;
  transform: translate($x-pos, $y-pos);

  @if($side == 'left') {
    left: 0;
  }
  @else if ($side == 'right') {
    right: 0;
  }
}

@mixin inlineNotificationPoint() {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: text-top;
}
