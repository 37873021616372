// Media Queries
$break-xsmall: 370px;
$break-small: 480px;
$break-medium: 640px;
$break-slarge: 992px;
$break-large: 1024px;
$break-xlarge: 1200px;
$break-xxlarge: 1442px;

// Media queries system
@mixin respond-to($media) {
  @if $media == xsmall {
    @media only screen and (min-width: $break-xsmall) { @content; }
  }
  @else if $media == max-xsmall {
    @media only screen and (max-width: $break-xsmall - 1) { @content; }
  }
  @else if $media == xsmall-small {
    @media only screen and (min-width: $break-xsmall) and (max-width: $break-small - 1) { @content; }
  }
  @else if $media == xsmall-medium {
    @media only screen and (min-width: $break-xsmall) and (max-width: $break-medium - 1) { @content; }
  }
  @else if $media == small {
    @media only screen and (min-width: $break-small) { @content; }
  }
  @else if $media == max-small {
    @media only screen and (max-width: $break-small - 1) { @content; }
  }
  @else if $media == small-medium {
    @media only screen and (min-width: $break-small) and (max-width: $break-medium - 1) { @content; }
  }
  @else if $media == small-xlarge {
    @media only screen and (min-width: $break-small) and (max-width: $break-xlarge - 1) { @content; }
  }
  @else if $media == max-medium {
    @media only screen and (max-width: $break-medium - 1) { @content; }
  }
  @else if $media == medium {
    @media only screen and (min-width: $break-medium) { @content; }
  }
  @else if $media == medium-slarge {
    @media only screen and (min-width: $break-medium) and (max-width: $break-slarge - 1) { @content; }
  }
  @else if $media == medium-large {
    @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == medium-xlarge {
    @media only screen and (min-width: $break-medium) and (max-width: $break-xlarge - 1) { @content; }
  }
  @else if $media == medium-xxlarge {
    @media only screen and (min-width: $break-medium) and (max-width: $break-xxlarge - 1) { @content; }
  }
  @else if $media == max-slarge {
    @media only screen and (max-width: $break-slarge - 1) { @content; }
  }
  @else if $media == slarge {
    @media only screen and (min-width: $break-slarge) { @content; }
  }
  @else if $media == slarge-large {
    @media only screen and (min-width: $break-slarge) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == slarge-xlarge {
    @media only screen and (min-width: $break-slarge) and (max-width: $break-xlarge - 1) { @content; }
  }
  @else if $media == slarge-xxlarge {
    @media only screen and (min-width: $break-slarge) and (max-width: $break-xxlarge - 1) { @content; }
  }
  @else if $media == max-large {
    @media only screen and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == large {
    @media only screen and (min-width: $break-large) { @content; }
  }
  @else if $media == large-xlarge {
    @media only screen and (min-width: $break-large) and (max-width: $break-xlarge - 1) { @content; }
  }
  @else if $media == large-xxlarge {
    @media only screen and (min-width: $break-large) and (max-width: $break-xxlarge - 1) { @content; }
  }
  @else if $media == max-xlarge {
    @media only screen and (max-width: $break-xlarge - 1) { @content; }
  }
  @else if $media == xlarge {
    @media only screen and (min-width: $break-xlarge) { @content; }
  }
  @else if $media == xlarge-xxlarge {
    @media only screen and (min-width: $break-xlarge) and (max-width: $break-xxlarge - 1) { @content; }
  }
  @else if $media == max-xxlarge {
    @media only screen and (max-width: $break-xxlarge - 1) { @content; }
  }
  @else if $media == xxlarge {
    @media only screen and (min-width: $break-xxlarge) { @content; }
  }
  @else if $media == portrait {
    @media only screen and (max-aspect-ratio: 13/9) { @content; }
  }
  @else if $media == landscape {
    @media only screen and (min-aspect-ratio: 13/9) { @content; }
  }
  @else {}
}
