













































@import '@/styles/mixins/index';

.youtube-player {
  height: 100%;

  &__video-container {
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - (var(--conference-footer-height) + var(--menu-height)));
  }
}
