





















































































































































.idt-icon {
  display: inline-block;
  display: contents;
}
.ideacon {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  // Color changes
  &.color-white {
    stroke: var(--white);
    fill: var(--white);
  }
  &.color-main {
    stroke: var(--main-color);
    fill: var(--main-color);
  }
  &.color-secondary {
    stroke: var(--secondary-color);
    fill: var(--secondary-color);
  }
  .file-label {
    display: flex;
    justify-content: center;
    align-items: center;
    // 'fixed' instead of 'absolute' for Safari
    // More info: https://stackoverflow.com/questions/51313873/svg-foreignobject-not-working-properly-on-safari
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--white);
    font-family: sans-serif;
    font-weight: var(--font-weight-bold);
  }
}
.ideacons {
  position: relative;
  .file-label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 49%;
    left: 0;
    width: 81%;
    height: 30%;
    color: var(--white);
    background-color: var(--main-color);
    font-family: sans-serif;
    font-weight: var(--font-weight-bold);
  }
}
