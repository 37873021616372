.activity-list {
  display: flex;
  flex-direction: column;
  gap: var(--rem-size-8);
  height: 550px;
  padding: var(--rem-size-8);
  border: solid 1px var(--grey-light);
  overflow-y: auto;
}

