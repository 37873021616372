







































































































































































































































.fo-project-bar-team-members {
  &__button {
    display: flex;
    align-items: center;
    padding: var(--rem-size-8);
    background-color: transparent;
    color: var(--white);
    cursor: pointer;
    transform: rotate(90deg);
  }
  &__item {
    padding: var(--rem-size-8);
    color: var(--grey-darkest);
    cursor: pointer;

    &:hover {
      background-color: var(--grey-dark);
    }
  }
  &__loading {
    width: var(--rem-size-20);
    height: var(--rem-size-20);
    margin-right: var(--rem-size-6);
  }
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: var(--rem-size-8);
  }

}
::v-deep .idt-dropdown--pane {
  padding: 0;
}
