.card-state {
  display: flex;
  flex-wrap: wrap;
  gap: var(--rem-size-4);
  margin: var(--rem-size-4);
}

.card-state__badge {
  display: inline-block;
  max-width: 98%;
  padding: var(--rem-size-3) var(--rem-size-8);
  border-radius: var(--rem-size-5);
  font-size: var(--rem-size-14);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--white);
  border: 1px solid var(--white);
}

.card-state:first-child {
  margin-right: 0;
}

.card-state__draft {
  background-color: var(--main-color);
}

.card-state__hires {
  background-color: #fc744f;
}

.card-state__place {
  color: var(--main-color);
  background-color: var(--white);
  border: 1px solid var(--main-color);
}

