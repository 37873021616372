




















































































.gamification-leaderboard {
  color: var(--black);
  &__your-position {
    color: var(--grey-dark);
    font-weight: var(--font-weight-bold);
    margin-top: 1rem;
  }
}
