.event-partner {
  margin-bottom: 1rem;
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .event-partner {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 480px)and (max-width: 639px) {
  .event-partner {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .event-partner {
    flex: 0 0 33.33333%;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .event-partner {
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 1200px) {
  .event-partner {
    flex: 0 0 20%;
  }
}

