@mixin typo() {
  // Fonts family
  --title-font-family: "Kreon", serif;
  --text-font-family: "Roboto", sans-serif;
  --quote-font-family: Arial, sans-serif;
  --ideacons-font: "ideation-icons";

  // Font Weights
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}
