.skeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 275px;
  width: 100%;
  height: 300px;
  margin: 0 0 var(--rem-size-15) 0;
  background-color: var(--white);
  border-radius: var(--global-radius);
}

.skeleton.type-list {
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  height: 60px;
}

.skeleton.type-list .skeleton__mentor {
  flex-direction: row;
  margin-left: var(--rem-size-20);
}

.skeleton.type-list .skeleton__mentor__image {
  width: 40px;
  height: 40px;
  margin: 0 var(--rem-size-15) 0 0;
}

.skeleton.type-list .skeleton__mentor__infos {
  align-items: normal;
}

.skeleton__mentor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton__mentor__image {
  width: 150px;
  height: 150px;
  margin: var(--rem-size-22) 0px var(--rem-size-22) 0px;
  border-radius: 100%;
  background-color: var(--grey);
  animation: glowing 1300ms infinite;
}

.skeleton__mentor__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton__mentor__infos__name {
  width: 65px;
  height: var(--rem-size-12);
  margin-bottom: var(--rem-size-12);
  border-radius: var(--global-radius);
  background-color: var(--grey);
  animation: glowing 1300ms infinite;
}

.skeleton__mentor__infos__job-company {
  width: 200px;
  height: var(--rem-size-8);
  margin-bottom: 4px;
  border-radius: var(--global-radius);
  background-color: var(--grey);
  animation: glowing 1300ms infinite;
}

.skeleton__mentor__infos__tags {
  width: 120px;
  height: var(--rem-size-8);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__button {
  width: 180px;
  height: var(--rem-size-32);
  margin-right: var(--rem-size-20);
  background-color: var(--grey);
  border-radius: var(--rem-size-24);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }

  25% {
    background-color: var(--grey);
  }

  50% {
    background-color: var(--grey-light);
  }

  75% {
    background-color: var(--grey-lighter);
  }

  100% {
    background-color: var(--grey-light);
  }
}

