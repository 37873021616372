.fo-project-bar-page-mapping__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: var(--rem-size-8);
}

.fo-project-bar-page-mapping__title__status {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-normal) !important;
  font-size: var(--rem-size-14) !important;
}

.fo-project-bar-page-mapping__content {
  display: flex;
  flex-direction: column;
  padding: var(--rem-size-16);
}

.fo-project-bar-page-mapping__content__sections {
  display: flex;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  border-radius: 50px;
  background-color: var(--white);
  color: var(--grey-darkest);
  cursor: pointer;
}

.fo-project-bar-page-mapping__content__sections__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: var(--font-weight-medium);
  font-size: var(--rem-size-12);
}

.fo-project-bar-page-mapping__content__sections__left__name {
  margin-bottom: var(--rem-size-8);
}

.fo-project-bar-page-mapping__content__sections__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--rem-size-12);
}

.fo-project-bar-page-mapping__content__sections__right__button {
  display: flex;
  align-items: center;
  margin-left: var(--rem-size-8);
  padding: var(--rem-size-6);
  border-radius: 50px;
  color: var(--white);
}

.fo-project-bar-page-mapping__content__sections__right__button.rotate {
  transform: rotate(90deg);
}

.fo-project-bar-page-mapping__content__sections__right__button.optional {
  background-color: var(--grey-dark);
}

.fo-project-bar-page-mapping__content__sections__right__button.incomplete {
  background-color: var(--danger-color);
}

.fo-project-bar-page-mapping__content__sections__right__button.in-progress {
  background-color: var(--yellow);
}

.fo-project-bar-page-mapping__content__sections__right__button.completed {
  background-color: var(--main-color);
}

::v-deep .idt-accordion__title {
  padding: var(--rem-size-2) var(--rem-size-16);
}

::v-deep .idt-circular-progress {
  margin: 0 var(--rem-size-8);
}

