.idt-user-list-item {
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: var(--rem-size-9);
  border-bottom: 1px solid var(--grey-lighter);
  cursor: pointer;
}

.idt-user-list-item--highlight {
  background-color: var(--grey-lighter);
  border-bottom: 1px solid var(--grey);
}

.idt-user-list-item:hover {
  background-color: var(--grey-lightest);
}

.idt-user-list-item__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.idt-user-list-item__content__description {
  font-size: var(--rem-size-14);
}

.idt-user-list-item .idt-user-avatar {
  margin-right: var(--rem-size-10);
}

