.days-buttons {
  display: inline-flex;
  align-items: center;
}

.days-buttons__day-button {
  flex-direction: column;
  margin-right: var(--rem-size-16);
  margin-left: var(--rem-size-16);
}

.days-buttons__day-button__day-clickable {
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  letter-spacing: .7px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.days-buttons__day-button__day-clickable:hover {
  color: var(--grey-dark);
  cursor: pointer;
}

.days-buttons__day-button__day-clickable:active {
  top: 1px;
  color: var(--white);
}

.days-buttons__day-button__mark-selected {
  width: 100%;
  margin: auto;
  border: 2px solid var(--main-color);
  border-radius: 5px;
}

.days-buttons__day-button__mark-unselected {
  width: 100%;
  margin: auto;
  border-color: var(--grey-lighter);
}

