




































































































.menu-mobile-shortcut-list-item {
  flex: 1 0 auto;
  max-width: 50%;
  padding: var(--rem-size-8) 0;

  &.vertical-separator {
    border-right: 1px solid var(--grey);
  }
  a,
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 var(--rem-size-16);
    color: var(--grey-darkest);
    font-family: var(--text-font-family);
    line-height: 1;
  }
  &__icon {
    position: relative;
  }
  &__label {
    margin-top: var(--rem-size-8);
    font-size: var(--rem-size-14);
  }
  .idt-notif-pill {
    top: -15%;
    right: -10%;
  }
}
