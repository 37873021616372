.submenu-account-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--rem-size-18);
  padding: 0 var(--rem-size-4);
  color: var(--grey-darker);
  border-left: var(--rem-size-2) solid rgba(0,0,0,0);
  border-radius: 0;
  font-family: var(--text-font-family);
  font-size: var(--rem-size-14);
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
}

.submenu-account-list-item:hover,
.submenu-account-list-item.active {
  border-color: var(--main-color);
}

.submenu-account-list-item:hover .submenu-account-list-item__icon,
.submenu-account-list-item.active .submenu-account-list-item__icon {
  color: var(--main-color);
}

.submenu-account-list-item__wrapper {
  margin: var(--rem-size-4) 0;
  padding: var(--rem-size-4);
}

.submenu-account-list-item__icon {
  margin-left: var(--rem-size-8);
}

