



















































































































































































































































































































































































@import '@/styles/mixins';

.fo-lobby {
  flex: auto;
  align-items: flex-start;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  position: relative;

  @include respond-to(max-slarge) {
    padding: 0;
  }

  &__wrapper {
    display: flex;
    flex-flow: column;
    height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
    background-color: var(--grey-lighter);
  }
  &__mobile-close-header {
    padding: var(--rem-size-16);
    display: flex;

    a {
      display: inline-flex;
      margin-left: auto;
    }
  }
  // align the close button on the left for the right panel
  &--sponsor {
    .fo-lobby__mobile-close-header a {
      margin-left: 0;
    }
  }
  .fo-lobby-categories,
  &__right-panel {
    flex-shrink: 0;
    width: 26.2376%;
    max-width: 348px;
    height: 100%;
    transition: width 0.3s ease-in-out;

    &--hidden {
      width: 0;
      overflow-x: hidden;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    flex: auto;
    align-self: stretch;
    align-items: stretch;
    background: var(--white);
    border-radius: var(--global-radius);
    box-shadow: 0px 2px 3px var(--grey-darkest-alpha20);
    font-size: var(--rem-size-14);
    overflow: hidden;
    transition: width 0.3s ease-in-out;

    @include respond-to(max-slarge) {
      border-radius: 0;
      box-shadow: none;
    }
  }
  &__mobile-only {
    display: none;
  }
  // there's a mobile behavior on desktop for the right user panel
  &__right-panel--users {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(348px + 40px);
    // the 40px is the margin around the main container for the lobby
    max-width: calc(348px + 40px);
    height: 100%;
    overflow: hidden;
    z-index: 10;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);

    @include respond-to(max-slarge) {
      padding: 0 !important;
    }
    @include respond-to(slarge) {
      padding: var(--rem-size-24);
    }
  }

  @include respond-to(max-slarge) {
    &__mobile-only {
      display: flex;
    }

    .fo-lobby-categories,
    &__right-panel {
      left: 0;
      width: 100%;
      max-width: none;
      padding-bottom: var(--mobile-bottom-navbar-height);
      background: var(--white);
      overflow: auto;
      transition: transform 0.3s ease-in-out;

      &:not(.fo-lobby__right-panel--users) {
        position: fixed;
        top: var(--menu-height);
        height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
        z-index: 1001;
      }
    }
    .fo-lobby-categories {
      transform: translateX(-100%);
    }
    &__right-panel {
      transform: translateX(100%);
    }
  }

  &--categories {
    .fo-lobby-categories {
      transform: translateX(0);
    }
  }
  &--sponsor {
    .fo-lobby__right-panel--sponsor {
      transform: translateX(0);
    }
  }
  &--users {
    .fo-lobby__right-panel--users {
      transform: translateX(0);
    }
  }
}
