.idt-switch-small {
  display: flex;
  align-items: center;
  gap: var(--rem-size-8);
}

.idt-switch-small.disabled,
.idt-switch-small[disabled] {
  opacity: .5;
  pointer-events: none;
}

.idt-switch-small__switch {
  display: inline-block;
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.idt-switch-small__switch__inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: background-color .3s ease;
}

.idt-switch-small__switch__inner ::v-deep .ideacon {
  color: #fff;
  opacity: .5;
}

.idt-switch-small__switch__inner.off {
  background-color: var(--grey);
}

.idt-switch-small__switch__inner.off ::v-deep .ideacon {
  display: none;
}

.idt-switch-small__switch__inner.on {
  background-color: var(--success-color);
}

.idt-switch-small__switch__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  transition: all .3s ease;
}

.idt-switch-small__switch__btn.off {
  left: 10%;
  background-color: var(--grey-dark);
  transform: translate3d(0, -50%, 0);
}

.idt-switch-small__switch__btn.on {
  left: 90%;
  background-color: var(--white);
  transform: translate3d(-100%, -50%, 0);
  box-shadow: 0px 0px 2px rgba(0,0,0,.2509803922);
}

.idt-switch-small__label {
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-18);
}

