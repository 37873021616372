.signup-private__input {
  padding-bottom: 3rem !important;
}

.signup-private__input ::v-deep .idt-required-field {
  top: 76px;
}

.signup-private__subtitle {
  color: var(--main-color);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-medium);
}

.signup-private__desc {
  font-weight: var(--font-weight-light);
}

.signup-private__subtitle,
.signup-private__desc {
  margin-bottom: 1rem;
  text-align: center;
}

