.idt-conference-tab-surveys {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  background-color: var(--white);
}

.idt-conference-tab-surveys .idt-survey-card,
.idt-conference-tab-surveys .idt-survey-card-admin {
  margin-bottom: 1rem;
}

