









































































































































































































































































































































@import '@/styles/mixins/index';
  .idt-define-availabilities {
    &__container {
      width: 325px;
      background-color: var(--white);
      border: 1px solid var(--grey-light);
      border-radius: var(--global-radius);

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 0 var(--rem-size-25) 0 var(--rem-size-25);
        border-bottom: solid 1px var(--grey-light);

        &__selected {
          color: var(--black);
          font-size: var(--rem-size-14);
          font-weight: var(--font-weight-bold);
        }
        &__delete {
          &:hover {
            cursor: pointer;
          }
        }
      }
      &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: var(--rem-size-15);

        &__button {
          width: 280px;
          height: 35px;
          color: var(--white);
          background-color: var(--main-color);
          border: 1.5px solid var(--main-color);
          border-radius: 25px;
          font-size: var(--rem-size-16);
          font-weight: var(--font-weight-medium);
          line-height: var(--rem-size-24);
          cursor: pointer;
        }
      }
    }
    &__select-block {
      @include respond-to(max-medium) {
        flex: 1 1 auto;
      }
      @include respond-to(medium) {
        flex: 0 0 auto;
      }

      &__header {
        height: 30px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 53px);
        padding: 0 0.5rem;
      }
    }

    &__calendar {
      padding: var(--rem-size-15) 5px var(--rem-size-15) 5px;

      &__table {
        width: 100%;
        padding: 0 var(--rem-size-25) 0 var(--rem-size-25);
        margin: 0;

        &.idt-define-availabilities__calendar--no-select {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
      &__month {
        min-height: 15px;
        cursor: pointer;

        &.month--next {
          text-align: right;
        }
        .ideacons {
          vertical-align: middle;
        }
      }
      &__body {
        border: 1px solid var(--main-color);
      }
      &__current-date {
        color: var(--black);
        font-size: var(--rem-size-18);
        font-weight: var(--font-weight-bold);
      }
      &__week {
        text-transform: uppercase;
      }
      &__week-day {
        height: 31px;
        padding: 0;
        color: var(--black);
        background-color: var(--white);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
        text-align: center;

        @include respond-to(max-medium) {
          width: 14.28%;
        }
        @include respond-to(medium) {
          width: 31px;
        }
      }
      &__week {
        background-color: var(--white);
      }
      &__day {
        height: 36px;
        padding: 0;
        color: var(--black);
        background-color: var(--white);
        font-size: 0.9rem;
        font-weight: var(--font-weight-light);
        cursor: pointer;

        &:hover {
          color: var(--white);
          &__cell {
            background-color: var(--main-color-lighter);
            border-radius: 100%;
          }
        }
        &:not(.day--past):hover {
          background-color: var(--main-color-light);
          border-radius: 100%;
          font-weight: var(--font-weight-bold);
        }
        &.day--past {
          color: var(--grey-light);
          background-color: var(--white);
          cursor: default;
        }
        &.day--off {
          color: var(--grey-dark);
          background-color: var(--white);
        }
        &.day--off:not(.day--past) {
          &:hover {
            color: var(--main-color);
          }
        }
        &.day--today {
          color: var(--black);
          background-color: var(--white);
          border: 2px solid var(--main-color);
          border-radius: 100%;
        }
        &.day--selected {
          color: var(--white);
          background-color: var(--main-color);
          border-radius: 100%;
          font-weight: var(--font-weight-bold);
        }
        &.day--selecting {
          color: var(--white);
          background-color: var(--main-color-lighter);
          border-radius: 100%;
          font-weight: var(--font-weight-bold);
        }
      }
      &__day-cell{
        display: flex;
        justify-content: center;
        align-items: center;

        &__first-range {
          position: absolute;
          top: 5px;
          left: -6px;
          width: 2px;
          height: 2px;
          color: var(--black);
          z-index: 1000;
        }
        &__last-range {
          position: absolute;
          top: 5px;
          right: 6px;
          width: 2px;
          height: 2px;
          z-index: 1000;
          color: var(--black);
        }
      }
    }
  }
