::v-deep .ideacon {
  stroke-width: .6px;
}

.idt-tile {
  display: flex;
  align-items: center;
  height: 75px;
  background-color: var(--grey-lighter);
  border-radius: var(--global-radius);
  transition: box-shadow .3s ease-in-out;
}

@media only screen and (max-width: 639px) {
  .idt-tile {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 640px) {
  .idt-tile {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .idt-tile {
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 1024px) {
  .idt-tile {
    flex: 0 0 auto;
    width: 300px;
  }
}

.idt-tile:hover {
  box-shadow: 0px 2px 5px var(--black-alpha20);
}

.idt-tile.has-event {
  cursor: pointer;
}

.idt-tile:not(.has-event) {
  pointer-events: none;
}

.idt-tile__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  margin-left: 5px;
  background-color: var(--white);
  border-radius: 100%;
}

.idt-tile__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.idt-tile__content__title {
  margin-bottom: .625rem;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: 1rem;
  line-height: 1.1875rem;
}

.idt-tile__content__subtitle {
  font-family: var(--first-font-family);
  font-size: .875rem;
  line-height: 1rem;
}

