






























































































@import '@/styles/mixins/index';

.idt-page.confirmemail {
  .idt-fo-fieldset {
    --sticky-margin: 70px;
  }
}

.idt-fo-fieldset {
  width: 100%;
  margin-bottom: 40px;

  @include respond-to(slarge) {
    border-radius: var(--global-radius);
  }

  &__header {
    height: 60px;
    background: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    @include respond-to(max-slarge) {
      position: var(--mobile-position, initial);
      top: var(--mobile-top, initial);
      padding: 0 var(--rem-size-30) 0 var(--rem-size-15);
    }
    @include respond-to(slarge) {
      padding: 0 var(--rem-size-30);
      border-radius: var(--global-radius) var(--global-radius) 0 0;
    }

    &__title-container {
      display: flex;
      align-items: center;
    }

    &__title {
      text-transform: uppercase;
      color: var(--white);
      font-weight: var(--font-weight-bold);
      margin: 0;
    }

    &__privacy {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 92px;
      padding: 2px 16px;
      padding-right: 8px;
      border-radius: var(--global-radius);
      background-color: var(--main-color-lightest);
      color: var(--main-color);
      font-size: var(--rem-size-14);

      &.private {
        color: var(--secondary-color);
        background-color: var(--secondary-color-lightest);
      }

      .ideacon {
        margin-left: 8px;
      }
    }
  }

  &__content {
    background: white;
    padding: 30px;
    border-radius: 0px 0px var(--global-radius) var(--global-radius);
  }

  &#section-availabilities {
      .idt-fo-fieldset__content {
      padding: 0;
    }
  }

  label {
    color: var(--black);

    &.required {
      &::after {
        content: ' \002A' // Star character `*`
      }
    }
  }

  input, select {
    height: 40px;
    margin-bottom: 0;
  }

  select {
    padding: 0 2rem 0 1rem;
  }
}
