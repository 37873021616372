




























































































































































































































































































































































































































































































@import '@/styles/mixins';

.conference-list-item {
  display: flex;
  max-width: 890px;
  width: 100%;
  height: 202px;
  margin: 0 0 var(--rem-size-20) 0;
  background-color: var(--white);
  border-radius: var(--global-radius);
  overflow: hidden;
  cursor: pointer;

  --flow-mobile: column nowrap;
  --height-mobile: 470px;
  --right-padding-mobile: var(--rem-size-30) var(--rem-size-15) 55px var(--rem-size-25);
  --right-border-top-mobile: 0;
  --badge-bottom-mobile: 0;
  --badge-left-mobile: 0;
  --badge-radius-mobile: 3px;
  --track--text--color: var(--grey-darker);
  --track--text--title: var(--main-color);
  --track--text--desc: var(--grey-darker);

  &:hover {
    box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
  }
  &:hover &__image__favorite {
      display: block;
  }

  &.mobile {
    display: inline-block;
    max-width: 275px;
    height: 382px;
  }
  &.mobile &__image__favorite {
    display: block;
  }
  &.canceled {
    background-color: var(--grey-light);
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    pointer-events: none;
    cursor: auto;
  }
  &.canceled &__image {
    opacity: 0.5;
  }
  &.no-access {
    --track--text--color: var(--grey-dark);
    --track--text--title: var(--grey-dark);
    --track--text--desc: var(--grey-dark);

    background-color: var(--grey-lighter);
    border-color: var(--grey-lighter);
    transition: all 0.3s ease-in-out;

    @media (hover: hover) {
      &:hover {
        --track--text--color: var(--grey-darker);
        --track--text--title: var(--grey-darker);
        --track--text--desc: var(--grey-darker);
      }
    }
  }
  &.restricted {
    &:hover {
      cursor: not-allowed;
    }
  }
  // Left side
  &__image {
    flex: 0 0 auto;
    position: relative;
    width: 275px;
    height: 202px;
    max-width: 275px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--global-radius) 0 0 var(--global-radius);
    overflow: hidden;

    &.mobile {
      height: 144px;
      border-radius: var(--global-radius) var(--global-radius) 0 0;
    }
    &__unlock {
      position: absolute;
      top: var(--rem-size-10);
      left: var(--rem-size-10);
    }
    &__free {
      position: absolute;
      top: var(--rem-size-10);
      left: var(--rem-size-10);
      background-color: var(--main-color);
      border-radius: var(--global-radius);
      color: var(--white);
      text-transform: uppercase;
    }
    &__favorite {
      display: none;
      position: absolute;
      top: var(--rem-size-10);
      right: var(--rem-size-10);
      &.active {
        display: block;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &__reward {
      position: absolute;
      bottom: var(--rem-size-10);
      left: var(--rem-size-10);
      width: 91px;
      height: var(--rem-size-24);
      background-color: var(--white);
      border-radius: var(--rem-size-15);

      &__icon {
        display: inline-block;
        margin-left: var(--rem-size-10);
        color: var(--yellow);
      }
      &__text {
        display: inline-block;
        margin-left: 5px;
        color: var(--black);
        font-family: var(--text-font-family);
        font-size: var(--rem-size-12);
        font-weight: var(--font-weight-bold);
        line-height: var(--rem-size-14);
      }
    }
    &__duration {
      position: absolute;
      right: var(--rem-size-10);
      bottom: var(--rem-size-10);
      width: fit-content;
      padding: var(--rem-size-2) var(--rem-size-8);
      border-radius: var(--global-radius);
      background-color: var(--black-alpha50);
      color: var(--white);
      font-family: var(--text-font-family);
      font-size: var(--rem-size-12);
      line-height: var(--rem-size-14);
      text-align: center;
      vertical-align: middle;
    }
  }
  &.no-access &__image {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  // Right side
  &__information {
    padding: var(--rem-size-10) var(--rem-size-20);

    &__status-info {
      display: flex;
      gap: var(--rem-size-16);

      &.mobile {
          margin-bottom: var(--rem-size-10);
      }
      &__status {
        border-radius: var(--global-radius);

        &.live {
          background-color: var(--red);
          color: var(--white);
          text-transform: uppercase;
        }
        &.canceled {
          background-color: var(--grey-dark);
          color: var(--white);
        }
        &.replay {
          background-color: var(--black);
          color: var(--white);
        }
        &.onDemand {
          background-color: var(--main-color);
          color: var(--white);
        }
      }

      &__counter {
        background-color: var(--grey-light);
        border-radius: var(--global-radius);
        color: var(--black);
      }
    }
    &__date {
      align-items: center;
      min-height: var(--rem-size-24);

      &.mobile {
        margin-bottom: var(--rem-size-10);
      }
      &__content {
        width: 100%;
        color: var(--black);
        font-family: var(--text-font-family);
        font-size: var(--rem-size-14);
        line-height: var(--rem-size-16);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      min-height: 40px;

      &.mobile {
        margin-bottom: var(--rem-size-10);
      }
      &__content {
        color: var(--black);
        font-family: var(--text-font-family);
        font-size: var(--rem-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--rem-size-20);
      }
    }
    &__description {
      display: flex;
      min-height: 48px;

      &.mobile {
        margin-bottom: var(--rem-size-10);
      }
      &__content {
        color: var(--black);
        font-family: var(--text-font-family);
        font-size: var(--rem-size-12);
        line-height: var(--rem-size-16);
      }
    }
    &__separator {
      width: 275px;
      margin: var(--rem-size-8) 0 0 -20px;
      border-top: solid 1px var(--grey-light);
    }
    &__speaker-info {
      display: flex;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      margin: 0;
      padding: 5px 0 5px 0;
      border-radius: var(--global-radius);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        margin-left: -5px;
        padding-left: 5px;
        background-color: var(--grey-lighter);
        cursor: pointer;
      }
      &.mobile:hover {
        width: 275px;
      }
      &.mobile {
        margin: 0;
        padding: var(--rem-size-8) 0 var(--rem-size-10) 0;
        border-radius: 0 0 5px 5px;
      }
      &.mobile &__mentor__username {
        font-family: var(--title-font-family);
        font-size: var(--rem-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--rem-size-20);
      }
      &__mentor {
        display: flex;
        flex-direction: column;
        max-width: 80%;
        margin-left: var(--rem-size-10);

        &__username {
          max-width: 100%;
          color: var(--black);
          font-family: var(--text-font-family);
          font-size: var(--rem-size-14);
          font-weight: var(--font-weight-bold);
          line-height: var(--rem-size-16);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &__job-company {
          max-width: 100%;
          color: var(--black);
          font-family: var(--text-font-family);
          font-size: var(--rem-size-12);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &__track-tags {
      display: flex;
    }
    & ::v-deep .idt-tag-list {
      width: 100%;
      height: var(--rem-size-24);
      overflow: hidden;

      &__item {
        max-width: 75%;
      }
    }
  }
  &__speakers {
    width: fit-content;
    margin: var(--rem-size-4) 0;
  }

  @media (hover: hover) {
    &:hover &__information {
      border-color: transparent;
    }
  }

  // Status
  &.no-access .conference-list-item-status {
    opacity: 0.5;
  }
}
