.bo-section__title+.bo-project-sections-table {
  margin-top: calc(-1*var(--rem-size-30));
}

.bo-project-sections__custom-questions {
  margin-top: var(--rem-size-32);
}

.bo-project-sections-table {
  width: 100%;
  overflow: initial !important;
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table--disable-move .bo-project-sections-table__actions__handle {
    opacity: .2;
    cursor: not-allowed;
  }

  .bo-project-sections-table--disable-move .bo-project-sections-table__actions__handle * {
    pointer-events: none;
  }
}

.bo-project-sections-table__content {
  box-shadow: 0 2px 3px var(--grey-darker-alpha15);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
  position: relative;
}

.bo-project-sections-table__content,
.bo-project-sections-table__content__lines {
  width: 100%;
}

.bo-project-sections-table__content__lines .bo-project-sections-table__row {
  border-bottom: 1px solid var(--grey-light);
  left: 0;
}

.bo-project-sections-table__table,
.bo-project-sections-table__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-flow: column;
}

.bo-project-sections-table__row {
  width: 100%;
  font-size: var(--rem-size-14);
}

@media only screen and (max-width: 639px) {
  .bo-project-sections-table__row {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .bo-project-sections-table__row {
    display: grid;
    grid-template-columns: 8% 23% 56% 13%;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .bo-project-sections-table__row {
    display: grid;
    grid-template-columns: 6% 40% 35% 19%;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-project-sections-table__row {
    display: grid;
    grid-template-columns: 6% 52% 28% 14%;
  }
}

@media only screen and (max-width: 639px) {
  .bo-project-sections-table__row--header {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table__row--header {
    box-shadow: none;
    height: 50px;
  }

  .bo-project-sections-table__row--header .bo-project-sections-table__cell {
    color: var(--grey-dark);
    font-weight: var(--font-weight-bold);
    font-size: var(--rem-size-12);
  }
}

.bo-project-sections-table__row--ghost {
  opacity: 0;
}

.bo-project-sections-table__row--fixed {
  background: var(--grey-lighter);
}

.bo-project-sections-table__cell {
  padding: var(--rem-size-16);
  vertical-align: top;
  overflow: hidden;
}

@media only screen and (max-width: 639px) {
  .bo-project-sections-table__cell {
    padding: var(--rem-size-24) var(--rem-size-16);
  }

  .bo-project-sections-table__cell:nth-child(2) {
    padding-bottom: 0;
  }
}

.bo-project-sections-table__cell__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  white-space: normal;
  word-break: break-word;
}

.bo-project-sections-table__cell__content--no-mandatory {
  width: 64%;
}

.bo-project-sections-table__cell__content__title {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 639px) {
  .bo-project-sections-table__cell__content__title__icon {
    color: var(--grey-darker);
  }
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table__cell__content__title__icon {
    display: none;
  }
}

.bo-project-sections-table__cell__settings {
  justify-content: center;
}

.bo-project-sections-table a {
  color: var(--grey-dark);
}

@media only screen and (max-width: 639px) {
  .bo-project-sections-table .bo-project-sections-table__actions {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table .bo-project-sections-table__actions {
    display: flex;
    overflow: initial !important;
    max-width: none !important;
    white-space: nowrap;
    align-items: flex-start;
    transition: padding-top .1s ease-in-out;
  }

  .bo-project-sections-table .bo-project-sections-table__actions__settings {
    font-size: var(--rem-size-14);
    line-height: var(--rem-size-20);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .bo-project-sections-table .bo-project-sections-table__actions__settings__label {
    display: none;
  }
}

@media only screen and (min-width: 640px)and (min-width: 1024px) {
  .bo-project-sections-table .bo-project-sections-table__actions__settings__label {
    margin-right: var(--rem-size-12);
    color: var(--grey-darkest);
  }
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table .bo-project-sections-table__actions__settings__icon {
    color: var(--grey-darker);
  }
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table .bo-project-sections-table__actions__handle {
    transition: opacity .5s ease-in-out;
  }
}

@media only screen and (min-width: 640px) {
  .bo-project-sections-table .bo-project-sections-table__actions a+a {
    margin-left: var(--rem-size-8);
  }
}

.bo-project-sections-table .idea-menu {
  cursor: move;
}

.bo-project-sections-table .idt-radio-button-group.disabled label {
  pointer-events: none;
  opacity: .25;
}

.bo-project-sections-table .idt-radio-button-group label {
  padding: var(--rem-size-9) var(--rem-size-14);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-18);
}

.bo-project-sections-table .list-transition-items .list-transition-item {
  transition: all .35s;
}

.bo-project-sections-table .list-transition-items .list-transition-item .bo-project-sections-table__cell {
  transition: all .35s;
}

.bo-project-sections-table .list-transition-leave-active,
.bo-project-sections-table .list-transition-enter-active {
  overflow: hidden !important;
}

.bo-project-sections-table .list-transition-leave-active .bo-project-sections-table__cell,
.bo-project-sections-table .list-transition-enter-active .bo-project-sections-table__cell {
  overflow: hidden !important;
}

.bo-project-sections-table .list-transition-enter {
  max-height: 0;
  opacity: 0;
}

.bo-project-sections-table .list-transition-enter .bo-project-sections-table__cell {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.bo-project-sections-table .list-transition-enter-to,
.bo-project-sections-table .list-transition-leave {
  max-height: 125px;
  opacity: 1;
}

.bo-project-sections-table .list-transition-enter-to .bo-project-sections-table__cell,
.bo-project-sections-table .list-transition-leave .bo-project-sections-table__cell {
  padding-top: var(--rem-size-16);
  padding-bottom: var(--rem-size-16);
  max-height: 125px;
}

.bo-project-sections-table .list-transition-leave-to {
  max-height: 0;
  opacity: 0;
  border-bottom-color: rgba(0,0,0,0) !important;
}

.bo-project-sections-table .list-transition-leave-to .bo-project-sections-table__cell {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

