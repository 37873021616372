.idt-notif-pill {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  color: var(--white);
  background-color: var(--main-color);
  border: 2px solid var(--white);
  border-radius: 100%;
  font-size: .8rem;
  transform: translate(65%, -25%);
}

