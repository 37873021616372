.idt-page.confirmemail .idt-fo-fieldset {
  --sticky-margin: 70px;
}

.idt-fo-fieldset {
  width: 100%;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) {
  .idt-fo-fieldset {
    border-radius: var(--global-radius);
  }
}

.idt-fo-fieldset__header {
  height: 60px;
  background: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .idt-fo-fieldset__header {
    position: var(--mobile-position, initial);
    top: var(--mobile-top, initial);
    padding: 0 var(--rem-size-30) 0 var(--rem-size-15);
  }
}

@media only screen and (min-width: 992px) {
  .idt-fo-fieldset__header {
    padding: 0 var(--rem-size-30);
    border-radius: var(--global-radius) var(--global-radius) 0 0;
  }
}

.idt-fo-fieldset__header__title-container {
  display: flex;
  align-items: center;
}

.idt-fo-fieldset__header__title {
  text-transform: uppercase;
  color: var(--white);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.idt-fo-fieldset__header__privacy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 92px;
  padding: 2px 16px;
  padding-right: 8px;
  border-radius: var(--global-radius);
  background-color: var(--main-color-lightest);
  color: var(--main-color);
  font-size: var(--rem-size-14);
}

.idt-fo-fieldset__header__privacy.private {
  color: var(--secondary-color);
  background-color: var(--secondary-color-lightest);
}

.idt-fo-fieldset__header__privacy .ideacon {
  margin-left: 8px;
}

.idt-fo-fieldset__content {
  background: #fff;
  padding: 30px;
  border-radius: 0px 0px var(--global-radius) var(--global-radius);
}

.idt-fo-fieldset#section-availabilities .idt-fo-fieldset__content {
  padding: 0;
}

.idt-fo-fieldset label {
  color: var(--black);
}

.idt-fo-fieldset label.required::after {
  content: " *";
}

.idt-fo-fieldset input,
.idt-fo-fieldset select {
  height: 40px;
  margin-bottom: 0;
}

.idt-fo-fieldset select {
  padding: 0 2rem 0 1rem;
}

