





































































































@import '@/styles/mixins/index';

.idt-carousel {
  width: 100%;
  margin-top:30px;

  .slick-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 17px;

    &.slick-dotted {
      margin-bottom: 37px;
    }
    .slick-prev {
      margin-right: 25px;
    }
    .slick-next {
      margin-left: 25px;
    }
    .slick-disabled {
      opacity: .3;
    }
    .slick-list {
      width: 100%;

      @include respond-to(medium) {
        max-width: 1000px;
      }
      @include respond-to(xlarge) {
        max-width: 1200px;
      }
      @include respond-to(xxlarge) {
        max-width: 1400px;
      }

      .slick-slide {
        padding: 0 5px;

        @include respond-to(medium) {
          padding: 0 10px;
        }
      }
    }
    .slick-dots {
      display: block;
      position: absolute;
      bottom: -30px;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        padding: 0;
        cursor: pointer;

        &.slick-active {
          button {
            &:before {
              opacity: 1;
            }
          }
        }
        button {
          display: block;
          width: 20px;
          height: 20px;
          padding: 5px;
          font-size: 0;
          line-height: 0;
          cursor: pointer;

          &:before {
            content: '•';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            color: var(--main-color);
            font-family: 'slick';
            font-size: 1.5625rem;
            text-align: center;
            line-height: 20px;
            opacity: .5;
          }
        }
      }
    }
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: var(--grey-darkest);
      border-radius: 50%;
      cursor: pointer;

      .ideacons {
        color: var(--white);
        font-size: 1.25rem;
      }
    }
  }
}
