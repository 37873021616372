

































































.fo-project-section-presentation-view {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);
  margin-bottom: var(--rem-size-20);

  &__title {
    margin-bottom: var(--rem-size-24);
    font-weight: var(--font-weight-medium);
    font-size: var(--rem-size-36);
    color: var(--grey-darkest);
  }

  &__slideshare {
    .embed-responsive-item {
      width: 100% !important;
      height: 70vh !important;
    }
  }

  &__empty-state {
    margin: auto auto var(--rem-size-32);
    width: 600px;
    aspect-ratio: 600 / 400;
  }
}
