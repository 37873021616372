






























































































































































































































@import '@/styles/mixins/index';

.jitsi-publisher-container {
  height: 100%;

  &__video-container {
    display: block;
    width: 100%;
    max-width: 177.78vh;
    /* 16/9 = 1.778 */
    height: 56.25vw;
    /* height:width ratio = 9/16 = .5625  */
    max-height: 100%;
    background: var(--grey-darkest);

    &--loading {
      position: relative;

      .idt-round-loader {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__pr-warning {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 2vh;
    left: 50%;
    width: 55vw;
    max-width: 90%;
    transform: translateX(-50%);
    cursor: pointer;

    &__close {
      position: absolute;
      top: 1rem;
      right: 0.75rem;
      color: var(--black);
      font-size: var(--rem-size-20);
      line-height: 0;
    }
    & ::v-deep .alert-card {
      width: 100%;
    }
  }

  &__launch-stream-button {
    margin-right: 1rem;
  }

  &__ingestor-input {
    padding-bottom: 0;
    width: 30%;

    & ::v-deep .idt-input {
      height: 50px;
      margin: 0;
      border: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      input[disabled] {
        background-color: var(--white);
      }
    }
  }

  &__clipboard-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
