

























































































































































@import '@/styles/mixins';

.lobby-user-list {
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey-darkest-alpha20);
  overflow-y: auto;

  ::v-deep .idt-input-container {
    padding: var(--rem-size-10) var(--rem-size-24);
  }
  .vs__dropdown-toggle {
    height: 40px;
  }
  .vs__search {
    margin: 0;
    padding-left: var(--rem-size-8) !important;
  }
  .idt-select-container {
    height: 50px;
  }
  &__container {
    border-top: 1px solid var(--grey-light);
    overflow: auto;

    @include respond-to(max-slarge) {
      height: calc(var(--vh, 1vh) * 100 - (var(--menu-height) * 3) - 66px);
    }
    @include respond-to(slarge) {
      height: calc(var(--vh, 1vh) * 100 - (var(--menu-height) * 3) - var(--rem-size-48));
    }

    div[role="group"] :last-child .lobby-user-list-item {
      border: none;
    }
  }
  ::v-deep .idt-user-list-item {
    padding-right: var(--rem-size-24);
    padding-left: var(--rem-size-24);
  }
}
