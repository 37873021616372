
















































































































































@import '@/styles/functions';
@import '@/styles/mixins/index';
@import './fo-project-list-card';

.project-list-card {
  width: 100%;
  margin-bottom: 0;
  padding: var(--rem-size-10);
  background-color: var(--white);
  border-radius: var(--rem-size-5);
  box-shadow: 0px 2px 3px 0px var(--grey);

  &__row {
    display: flex;
  }
  &__left-column {
    flex: 0 0 30%;
    max-width: 30%;
    min-width: 235px;
  }
  &__middle-column {
    @include respond-to(max-large) {
      flex: 0 0 42%;
      max-width: 42%;
    }
    @include respond-to(large) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @include respond-to(xlarge) {
      flex: 0 0 42%;
      max-width: 42%;
    }
  }
  &__right-column {
    flex: 0 0 28%;
    max-width: 28%;
  }
  &__image {
    height: 180px;
    background-color: var(--main-color-lighter);
    background-size: cover;
    border-radius: var(--rem-size-5);
  }
  &__texts {
    border-right: 1px solid var(--grey-lighter);

    @include respond-to(max-large) {
      margin: var(--rem-size-30) 0 0 var(--rem-size-30);
      padding-right: var(--rem-size-30);
    }
    @include respond-to(large) {
      margin: var(--rem-size-30) 0 0 var(--rem-size-10);
      padding-right: var(--rem-size-10);
    }
    @include respond-to(xlarge) {
      margin: var(--rem-size-30) 0 0 var(--rem-size-30);
      padding-right: var(--rem-size-30);
    }
  }
  .project-list-card-authors {
    margin-top: var(--rem-size-30);

    @include respond-to(max-large) {
      width: calc(100% - var(--rem-size-30));
      padding-left: var(--rem-size-30);
    }
    @include respond-to(large) {
      width: calc(100% - var(--rem-size-10));
      padding-left: var(--rem-size-10);
    }
    @include respond-to(xlarge) {
      width: calc(100% - var(--rem-size-30));
      padding-left: var(--rem-size-30);
    }
    &--no-votes {
      margin-top: var(--rem-size-15);
    }
    &__title {
      margin-bottom: var(--rem-size-8);
      font-size: var(--rem-size-12);
    }
    &__list {
      margin-top: var(--rem-size-6);

      &__avatars {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--rem-size-8);

        &__avatar {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &__icon {
            background-color: var(--grey);
          }
          &__name {
            font-size: var(--rem-size-14);
          }
        }
        &__more {
          &__indicator {
            display: inline-block;
            position: relative;
            width: 24px;
            height: 24px;
            font-size: var(--rem-size-14);
            z-index: 1;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 24px;
              height: 24px;
              background-color: var(--main-color-lighter);
              border-radius: 50%;
              opacity: 0.9;
              z-index: -1;
            }
          }
          &__wording {
            font-size: var(--rem-size-14);
          }
          &__label {
            font-size: var(--rem-size-14);
          }
        }
      }
      ::v-deep .users-list-popover__item__user__name {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 25ch;
        white-space: nowrap;
      }
      ::v-deep .idt-dropdown {
        display: block;
      }
    }
  }
}

::v-deep .idt-tag {
  height: 20px;
  max-width: 205px;
}
