



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.conferences-page {
  height: calc((var(--vh, 1vh) * 100) - 70px);
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
  overflow: auto;

  @include respond-to(max-large) {
    height: fit-content;
    overflow: unset;
  }

  &__conferences {
    .side-open-enter-active {
      transition: opacity 1s;
    }
    .side-open-enter {
      opacity: 0;
    }

    &__filter-panel {
      position: sticky;
      top: 0;
      max-width: 280px;
      height: calc(100vh - 70px);
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &.hidden {
        position: absolute;
      }
      @include respond-to(max-large) {
        background-color: var(--white);
        max-width: none;
      }
    }
    &__filter-panel::-webkit-scrollbar {
      display: none;
    }
    &__conferences-list {
      &__no-more {
          position: relative;
          display: flex;
          justify-content: center;
          margin-bottom: var(--rem-size-15);
      }
      &__spinner {
        display: flex;
        align-content: left;
      }
      &__header {
        position: sticky;
        top: 0;
        padding: var(--rem-size-15) 0 var(--rem-size-25) 0;
        background-color: var(--grey-lighter);
        // To Hide box shadow on the talk card when hover and scrolled behind
        box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
        clip-path: inset(0px -10px 0px -10px);
        z-index: 10;

        @include respond-to(max-large) {
          padding: var(--rem-size-15) 0 var(--rem-size-10) 0;
        }
        &__search {
          height: 0;
          visibility: hidden;

          @include respond-to(max-large) {
            height : 100%;
            visibility: initial;
          }
          &__bar {
            height: 46px;
            margin-right: var(--rem-size-10);
            background-color: var(--white);
            border: solid 1px var(--grey-light);
            border-radius: var(--global-radius);
            box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);

            &__input {
              height: 44px;
              margin: 0;
              color: var(--black);
              background-color: var(--white);
              border: none;
              font-size: var(--rem-size-16);
            }
            &:before {
              content: '\E96E';
              display: block;
              top: 84px;
              left: 0px;
              position: absolute;
              color: var(--black);
              font-family: "ideation-icons";
              font-size: 1rem;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
            }
          }
          &__filter {
            height: 46px;
            background-color: var(--white);
            box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
          }
        }
        &__title-results {
          top: 0;
          position: sticky;
          height: var(--rem-size-32);
          margin-bottom: var(--rem-size-15);
          color: var(--black);

          &__title {
            max-height: 48px;
            font-size: var(--rem-size-24);
            font-weight: var(--font-weight-bold);
            text-align: left;

            @include respond-to(max-large) {
              text-align: center;
            }

            &.search {
              font-size: var(--rem-size-16);
            }
          }
          &__result {
            align-self: flex-end;
            font-size: var(--rem-size-15);
            text-align: right;

            @include respond-to(max-large) {
              display: none;
            }
          }
          &__mobile-result {
            display: none;

            @include respond-to(max-large) {
              display: flex;
              height: var(--rem-size-22);
              margin: 0;
              font-size: var(--rem-size-14);
              text-align: right;
            }
          }
        }
        &__infos {
          align-content: center;
          height: 64px;
          background: var(--white);
          border-radius: var(--global-radius);
          box-shadow: 0px 2px 3px var(--grey);

          @include respond-to(max-large) {
            height: 0;
            visibility: hidden;
          }

          &__live {
            margin-left: var(--rem-size-25);

            &__button {
              align-items: center;
              width: fit-content;
              height: var(--rem-size-32);
              border: 2px solid var(--red);
              border-radius: 5em / 5em;
              color: var(--red);

              &.active {
                color: var(--white);
                background-color: var(--red);
              }
              &:hover {
                cursor: pointer;
              }
              &__label {
                padding: 0 var(--rem-size-15) 0 7px;
                font-size: var(--rem-size-14);
                font-weight: var(--font-weight-bold);
              }
              &__blob {
                width: var(--rem-size-10);
                height: var(--rem-size-10);
                margin-left: var(--rem-size-10);
                background: var(--red);
                border-radius: 50%;
                box-shadow: 0 0 0 0 var(--red);
                animation: pulse-red 2s infinite;
                transform: scale(1);
                &.active {
                  animation: pulse-white 2s infinite;
                  background: var(--white);
                  box-shadow: 0 0 0 0 var(--white);
                }
              }
              @keyframes pulse-red {
                0% {
                  box-shadow: 0 0 0 0 var(--red-alpha70);
                  transform: scale(0.95);
                }
                70% {
                  box-shadow: 0 0 0 var(--rem-size-10) transparent;
                  transform: scale(1);
                }
                100% {
                  box-shadow: 0 0 0 0 transparent;
                  transform: scale(0.95);
                }
              }
              @keyframes pulse-white {
                0% {
                  box-shadow: 0 0 0 0 var(--white-alpha70);
                  transform: scale(0.95);
                }
                70% {
                  box-shadow: 0 0 0 var(--rem-size-10) var(--white-alpha10);
                  transform: scale(1);
                }
                100% {
                  box-shadow: 0 0 0 0 var(--white-alpha10);
                  transform: scale(0.95);
                }
              }
            }

            & .row {
              align-items: center;
            }
          }

          &__sort {
            &__label {
              margin: var(--rem-size-5) var(--rem-size-10) 0 0;
              color: var(--black);
            }

            &__select {
              width: 170px;
              height: 41px;
              margin: 0 var(--rem-size-10) 0 0;
              padding: 0 var(--rem-size-15);
            }
          }

          &__list-mode {
            color: var(--grey);

            &__button {
              margin-right: var(--rem-size-10);
              &:hover {
                cursor: pointer;
              }
              &.active {
                color: var(--black);
              }
            }
          }
        }
      }
      &__list {
        &__group {
          top: 150px;
          height: 100%;
          position: sticky;
          // To Hide box shadow on the talk card when hover and scrolled behind
          box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
          clip-path: inset(0px -10px 0px -10px);
          color: var(--black);
          z-index: 5;

          @include respond-to(max-large) {
            top: 140px;
          }

          &__text {
            background-color: var(--grey-lighter);
            border-bottom: 1px solid var(--grey-dark);
            font-family: var(--text-font-family);
            font-size: var(--rem-size-22);
            font-weight: var(--font-weight-bold);
            text-align: left;
          }
        }
        &__release-date {
          top: 184px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          position: sticky;
          color: var(--black);
          background-color: var(--grey-lighter);
          // To Hide box shadow on the talk card when hover and scrolled behind
          box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
          clip-path: inset(0px -10px 0px -10px);
          z-index: 5;

          &__text {
            margin-left: 10px;
            font-family: var(--text-font-family);
            font-size: var(--rem-size-18);
            text-align: left;
          }
        }
        &__container {
          padding: var(--rem-size-15) 0 var(--rem-size-15) 0;
        }
      }
      &__no-item-icon {
        padding-bottom: var(--rem-size-15);
      }
    }
  }
}
