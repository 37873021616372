



























































































































.idt-user-search-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--rem-size-10);
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey);
    &.dark {
      border-bottom: 1px solid var(--grey-darkest);
    }
  }
  &:hover {
    background: var(--grey-lightest);
    &.dark {
      background: 0;
    }
  }
  &__left {
    display: flex;
    align-items: center;
    width: 100%;

    &__avatar {
      margin-right: var(--rem-size-8);
      cursor: pointer;
    }
    &__content {
      width: 100%;
      margin-right: 50px;
      &__name {
        color: var(--grey-darkest);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-medium);
        line-height: var(--rem-size-20);
        &.dark {
          color: var(--white);
        }
      }
      &__tags {
        height: 30px;
        overflow: hidden;

        ::v-deep .idt-tag-list__item {
          max-width: 75%;
        }
      }
      &__job {
        margin-top: var(--rem-size-5);
        font-size: var(--rem-size-14);
        line-height: var(--rem-size-20);
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
}
