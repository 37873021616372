


























































































































































































































































































.questions {
  &__section {
    padding-bottom: 2rem;

    &__list {
      &__actions {
        text-align: center;

        .button {
          margin: 0;
          font-size: 0.75rem;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1.4;
        }
      }
    }
  }
}
.idt-table-pagination.idt-question-pagination {
  margin-bottom: 20px;
}
