



































.idt-table-cell-tags {
  display: flex;
  gap: var(--rem-size-4);
}
