

































































































































































.button {
  margin-top: 5px;
}
.availabilities {
  background-color: var(--grey-light);
  height: 1100px;
  width: 100%;
  padding: 20px 0 0 500px;

  &__input {
    width: 150px;
    border: solid 1px var(--black);
  }

  &__params {
    display: flex;
    align-items: center;

    &__input {
      flex-direction: column;
      width: 250px;
      margin-right: 10px;
    }
    &__value {
      flex-direction: column;
      width: 150px;
      border: solid 1px var(--black);
    }
  }

  &__table {
    height:400px;
    overflow:auto;
  }
}
