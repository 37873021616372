































































































































































































































































































































.export-viewer-stats-modal {
  padding: var(--rem-size-8);

  &__title {
    margin-bottom: var(--rem-size-8);
    font-size: 18px;
    font-weight: 500;
    color: var(--grey-darkest);
  }

  &__content {
    margin-top: var(--rem-size-8);

    &__info {
      margin-bottom: var(--rem-size-16);
    }

    &__label {
      margin-bottom: var(--rem-size-8);
      font-size: var(--rem-size-16);
      font-weight: 500;
      color: var(--grey-darkest);
    }

    &__radio {
      margin: var(--rem-size-16);
      max-width: 250px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    &__button {
      &__cancel {
        margin-right: var(--rem-size-8);
      }
    }
  }
}
