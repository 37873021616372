















































































@import '@/styles/mixins/index';

.statistics-number {
  font-size: var(--rem-size-14);

  @include respond-to(max-small) {
    padding: 0.75rem 1rem;
  }
  @include respond-to(small) {
    padding: 1.5rem 2rem;
  }

  &__title {
    font-weight: var(--font-weight-bold);
  }
  &__data {
    font-size: var(--rem-size-40);
  }
  &__status {
    display: flex;
    height: 100%;

    .status__loader {
      width: 50px;
      height: 50px;
      margin: auto;
    }
    span {
      margin: auto;
    }
  }
  &__evolution {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 5px;

      &--decreased {
        transform: rotate(90deg);
      }
    }
  }
}
