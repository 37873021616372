.skeleton {
  width: 280px;
  height: 340px;
  margin: var(--rem-size-20) var(--rem-size-20) var(--rem-size-20) 0;
  background-color: rgba(0,0,0,0);
  border: var(--rem-size-6) solid var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    border-color: var(--grey-light);
  }

  25% {
    border-color: var(--grey);
  }

  50% {
    border-color: var(--grey-light);
  }

  75% {
    border-color: var(--grey-lighter);
  }

  100% {
    border-color: var(--grey-light);
  }
}

