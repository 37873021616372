.event-resource {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  background-color: var(--white);
  border: 1px solid var(--grey);
  transition: box-shadow var(--default-duration) ease-in-out;
}

@media only screen and (max-width: 639px) {
  .event-resource {
    flex-flow: column wrap;
    padding-bottom: 1rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-resource {
    flex-flow: row wrap;
  }
}

.event-resource:hover {
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

.event-resource__img {
  flex: 0 1 auto;
  align-self: stretch;
  min-height: 146px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 640px) {
  .event-resource__img {
    width: 23.5%;
  }
}

.event-resource__info {
  flex: 1 1 0;
}

@media only screen and (max-width: 1023px) {
  .event-resource__info {
    padding: 1rem 1.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-resource__info {
    padding: 1rem 2.5rem;
  }
}

.event-resource__title {
  color: var(--grey-darkest);
  font-size: 1.8rem;
  font-weight: 300;
}

.event-resource__desc {
  color: var(--grey-dark);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.2;
}

.event-resource__link {
  flex: 0 1 auto;
}

@media only screen and (max-width: 1023px) {
  .event-resource__link {
    padding: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-resource__link {
    padding: 1rem 1.5rem;
  }
}

.event-resource__link .btn {
  padding: .75rem 1rem;
  color: var(--main-color-dark);
  background-color: var(--main-color-alpha20);
  font-weight: 500;
  text-transform: uppercase;
}

.event-resource__link .btn:hover {
  background-color: var(--main-color-dark-alpha20);
}

