





























































.idt-profile-notifications {
  &__switch {
    margin-bottom: 5px;
  }
}
