.idt-survey-question-like {
  display: flex;
  align-items: center;
  height: 60px;
}

.idt-survey-question-like__button {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: 0 var(--rem-size-20);
  background-color: var(--grey-lighter);
  border: 2px solid rgba(0,0,0,0);
  cursor: pointer;
}

.idt-survey-question-like__button.selected {
  font-weight: var(--font-weight-bold);
}

.idt-survey-question-like__button:hover {
  border-color: var(--grey-light);
}

.idt-survey-question-like__button.dislike {
  justify-content: flex-start;
  color: var(--secondary-color);
  border-radius: var(--rem-size-35) 0px 0px var(--rem-size-35);
}

.idt-survey-question-like__button.dislike.selected {
  border-color: var(--secondary-color);
}

.idt-survey-question-like__button.dislike ::v-deep .ideacon {
  margin-right: .5rem;
}

.idt-survey-question-like__button.like {
  justify-content: flex-end;
  color: var(--main-color);
  border-radius: 0px var(--rem-size-35) var(--rem-size-35) 0px;
}

.idt-survey-question-like__button.like.selected {
  border-color: var(--main-color);
}

.idt-survey-question-like__button.like ::v-deep .ideacon {
  margin-left: .5rem;
}

