.idt-project-card {
  position: relative;
  max-width: 870px;
  width: 100%;
  height: auto;
  margin: 0 0 var(--rem-size-20) 0;
  overflow: hidden;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  cursor: pointer;
  transition: box-shadow var(--default-duration) ease-in-out;
}

.idt-project-card:hover {
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

@media only screen and (max-width: 479px) {
  .idt-project-card {
    display: inline-block;
    max-width: 275px;
    height: auto;
  }
}

.idt-project-card.mobile {
  display: inline-block;
  max-width: 275px;
  height: auto;
}

.idt-project-card .project-card-hire {
  top: .375rem;
  right: .375rem;
  z-index: 1;
}

.idt-project-card__image {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 275px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: var(--global-radius) 0 0 var(--global-radius);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .idt-project-card__image {
    height: 144px;
    max-width: 100%;
    border-radius: var(--global-radius) var(--global-radius) 0 0;
  }
}

.idt-project-card__image.mobile {
  height: 144px;
  max-width: 100%;
  border-radius: var(--global-radius) var(--global-radius) 0 0;
}

.idt-project-card__image__prizes {
  padding: 0;
  padding-top: 1rem;
}

.idt-project-card__image__prizes__item {
  width: fit-content;
  margin-bottom: .25rem;
}

.idt-project-card.no-access .idt-project-card__image {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.idt-project-card__information {
  position: relative;
  margin: 7px 0 0 var(--rem-size-20);
  padding-left: 0;
}

.idt-project-card__information--with-footer {
  padding-bottom: 38px;
}

.idt-project-card__information__title {
  align-items: center;
  min-height: 40px;
  margin: 0 !important;
}

@media only screen and (max-width: 479px) {
  .idt-project-card__information__title {
    margin-bottom: var(--rem-size-10);
  }
}

.idt-project-card__information__title.mobile {
  margin-bottom: var(--rem-size-10);
}

.idt-project-card__information__title__content {
  padding: 0;
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-20);
}

.idt-project-card__information__description {
  min-height: 48px;
  margin: 0 !important;
}

@media only screen and (max-width: 479px) {
  .idt-project-card__information__description {
    margin-bottom: var(--rem-size-10);
  }
}

.idt-project-card__information__description.mobile {
  margin-bottom: var(--rem-size-10);
}

.idt-project-card__information__description__content {
  padding: 0;
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-16);
}

.idt-project-card__information__separator {
  position: absolute;
  bottom: 38px;
  right: 0;
  left: 0;
  min-width: 375px;
  margin: var(--rem-size-8) 0 0 -20px;
  margin-left: -20px !important;
  border-top: solid 1px var(--grey-light);
}

.idt-project-card__information__tags {
  margin: 0 !important;
}

.idt-project-card__information__tags__item {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 25px;
  margin: 0 var(--rem-size-3);
  margin-bottom: var(--rem-size-10);
  padding: 0 var(--rem-size-5);
  text-align: center;
  line-height: 25px;
  color: var(--main-color);
  background-color: var(--main-color-lightest);
  border-radius: var(--global-radius);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-12);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.idt-project-card__information__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: var(--rem-size-10) 0;
  padding-right: 1rem;
  margin: 0 !important;
}

.idt-project-card__information__footer__hiring {
  padding: 0;
  text-align: left;
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
}

.idt-project-card__information__footer__votes {
  padding: 0;
  text-align: right;
  font-size: var(--rem-size-12);
  color: var(--grey-darker);
}

@media (hover: hover) {
  .idt-project-card:hover .idt-project-card__information {
    border-color: rgba(0,0,0,0);
  }
}

