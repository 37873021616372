.gamification-reward-row {
  display: flex;
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .gamification-reward-row {
    flex-direction: column;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-reward-row {
    flex-direction: row;
    height: 90px;
  }
}

.gamification-reward-row__image.no-image {
  filter: grayscale(100%);
  border-right: 1px solid var(--grey-light);
}

.gamification-reward-row__image img {
  height: 90px;
  object-fit: cover;
}

@media only screen and (max-width: 991px) {
  .gamification-reward-row__image img {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-reward-row__image img {
    width: 160px;
  }
}

.gamification-reward-row__content {
  display: flex;
  flex-direction: column;
  flex-grow: 6;
}

@media only screen and (max-width: 991px) {
  .gamification-reward-row__content {
    padding: 1rem 1.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .gamification-reward-row__content {
    width: 0;
    padding: .5rem 0 .5rem 1rem;
  }
}

.gamification-reward-row__content__title {
  width: 100%;
  color: var(--black);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gamification-reward-row__content__description {
  width: 100%;
  font-size: var(--rem-size-14);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gamification-reward-row__progress {
  flex-grow: 3;
}

@media only screen and (max-width: 991px) {
  .gamification-reward-row__progress {
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--grey);
  }
}

@media only screen and (min-width: 992px) {
  .gamification-reward-row__progress {
    width: 0;
    margin: auto;
    padding: 1rem;
  }
}

.gamification-reward-row__progress .glyphicons {
  vertical-align: middle;
}

.gamification-reward-row__progress .glyphicons.glyphicons-unlock {
  color: var(--main-color);
}

.gamification-reward-row__progress__locked {
  color: var(--black);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  vertical-align: middle;
  padding: 6px 0;
}

