










































































































































.idt-carousel {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__button {
      width: 55px;
      height: 55px;
      margin: var(--rem-size-16);
      background-color: var(--grey-lighter);
      color: var(--grey-darker);
      font-size: var(--rem-size-20);
      border-radius: 50px;
    }

    &__slider {
      position: relative;
      border-radius: var(--global-radius);
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right:0;
        margin: auto;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--rem-size-16);

    &__dot-list {
      display: flex;

      &__dot {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: var(--global-radius);
        background-color: var(--grey-light);
        cursor: pointer;
        &--active {
          background-color: var(--main-color);
        }
      }
    }
  }
}

::v-deep .disabled {
  &:hover,
  &:focus {
    background-color: var(--grey-lighter);
    color: var(--grey-darker);
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: .8s;
}
.slide-enter {
  transform: translate(100%, 0);
  opacity: 0.8;
}
.slide-leave-to {
  transform: translate(-100%, 0);
  opacity: 0;
}

.slideback-leave-active,
.slideback-enter-active {
  transition: .8s;
}
.slideback-enter {
  transform: translate(-100%, 0);
  opacity: 0.8;
}
.slideback-leave-to {
  transform: translate(100%, 0);
  opacity: 0;
}
