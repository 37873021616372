



























































@import '@/styles/mixins/index';

.shortlist-tab {
  position: relative;
  padding-top: 6.5rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-to(max-medium) {
      padding: 0 20px;
    }

    @include respond-to(medium) {
      padding: 0;
    }

    &__title {
      max-width: 600px;
      margin-bottom: 15px;
      color: var(--grey-darkest);
      font-family: var(--text-font-family);
      font-weight: var(--font-weight-light);
      font-size: 1.875rem;
      text-align: center;
      text-transform: uppercase;
    }

    &__description {
      max-width: 600px;
      margin-bottom: 25px;
      color: var(--main-color);
      font-family: var(--text-font-family);
      font-weight: var(--font-weight-normal);
      font-size: 1rem;
      text-align: center;
    }
  }

  &__close, &__back {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-darker);
    font-size: 1.125rem;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }

  &__close {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;

    span {
      margin-right: 10px;
    }
  }

  &__back {
    position: absolute;
    top: 1.875rem;
    left: 1.875rem;

    span {
      margin-left: 10px;
    }
  }
}
