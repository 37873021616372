



























.idt-search {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  max-width: 290px;
  min-width: 220px;
  vertical-align: middle;
  margin-right: 10px;
  &:after {
    content: '\E96E';
    display: block;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    font-family: "ideation-icons";
    font-size: 1rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  input {
    height: 40px;
    width: 100%;
    margin: 0;
    padding: 12px;
    background-color: var(--white);
    border: 1px solid var(--grey);
    line-height: 1.6;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    display: block;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--black);
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;

    &:focus {
      border-color: var(--grey-darker);
      box-shadow: 0 0 0 var(--black);
    }
  }
}
