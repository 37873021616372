








































@import '@/styles/mixins/index';

.idt-ticket-feature {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 20px;

  @include respond-to(medium-large) {
    padding-bottom: 0.625rem;
  }
  @include respond-to(large-xlarge) {
    padding-bottom: 0.3125rem;
  }
  @include respond-to(xlarge) {
    padding: 0.3125rem 0;
    border-bottom: 1px solid var(--grey-lighter);

    &.last {
      border: none;
    }
  }
  &__icon {
    color: var(--main-color);
  }
  &__label {
    width: 100%;
    margin-left: 10px;
    color: var(--grey-darker);
    font-weight: var(--font-weight-normal);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.disabled &__icon {
    color: var(--grey);
  }
  &.disabled &__label {
    color: var(--grey);
    font-weight: var(--font-weight-light);
    font-style: italic;
  }
}
