.modal {
  --modal-background: var(--white);
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--black-alpha50);
  z-index: 99999;
  overflow-y: auto;
}

.modal-dialog,
.modal-confirm-message-delete,
.modal-save-before-leave {
  position: relative;
  top: 30%;
  max-width: 600px;
  margin: auto;
  background-color: var(--modal-background);
  border: 0;
  z-index: 100000;
  backface-visibility: hidden;
}

@media only screen and (max-width: 479px) {
  .modal-dialog,
  .modal-confirm-message-delete,
  .modal-save-before-leave {
    width: 90%;
  }
}

@media only screen and (min-width: 480px) {
  .modal-dialog,
  .modal-confirm-message-delete,
  .modal-save-before-leave {
    width: 50%;
  }
}

.modal-dialog__remove-message,
.modal-confirm-message-delete__remove-message,
.modal-save-before-leave__remove-message {
  white-space: pre-line;
}

.modal-dialog .column,
.modal-dialog .columns,
.modal-confirm-message-delete .column,
.modal-confirm-message-delete .columns,
.modal-save-before-leave .column,
.modal-save-before-leave .columns {
  min-width: 0;
}

.modal-dialog>:last-child,
.modal-confirm-message-delete>:last-child,
.modal-save-before-leave>:last-child {
  margin-bottom: 0;
}

.modal-body {
  padding: 1rem;
}

.modal-footer {
  padding: 1rem;
  border-top: 1px solid var(--grey-light);
}

.modal-footer .button {
  margin-bottom: 0;
}

