






































































































































































.idt-gif-picker {
  position: relative;

  &__gif-button {
    &:hover {
      --color2: purple;
      --color3: var(--white);
    }
  }
  &__picker-container {
    position: absolute;
    bottom: calc(100% + var(--rem-size-13));
    right: 0;
    width: 393px;
    padding: var(--rem-size-10);
    background: var(--white);
    box-shadow: 0px 3px 6px var(--black-alpha20);
    z-index: 5;

    &__preview {
      max-height: 350px;
      margin-bottom: var(--rem-size-10);
      overflow-y: auto;
    }
    &__loader {
      width: var(--rem-size-40);
      height: var(--rem-size-40);
      margin: 0 auto var(--rem-size-10);
    }
    &__search-bar {
      input {
        margin: 0;
        color: var(--black);
        background: var(--grey-lighter);
        border: 0;
        border-radius: var(--rem-size-20);
      }
    }
    &__powered-by-giphy {
      max-height: var(--rem-size-15);
      margin-top: var(--rem-size-10);
    }
  }
}
