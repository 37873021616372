



































@import '@/styles/mixins/index';

.post-conference-card {
  height: 100%;
  width: 100%;
  background-color: var(--main-color-lighter);

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--main-color);
    text-align: center;

    @include respond-to(max-medium) {
      transform: translate(-50%, -50%) scale(0.5);
    }
    @include respond-to(medium-large) {
      transform: translate(-50%, -50%) scale(0.9);
    }
    @include respond-to(large-xlarge) {
      transform: translate(-50%, -50%) scale(0.7);
    }
    @include respond-to(xlarge) {
      transform: translate(-50%, -50%);
    }
  }
}
