


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '@/styles/mixins';

.conference-form {
  width: 100vw;

  @include respond-to(max-medium) {
    padding-top: var(--rem-size-32);
  }

  &__subtitle {
    @include respond-to(large) {
      max-width: 550px;
      margin: 0 auto 2rem auto;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    width: 100%;

    @include respond-to(max-slarge) {
      flex-direction: column;
    }

    &__block {
      flex: 1 1 auto;
      position: relative;

      @include respond-to(max-medium) {
        padding: 0 var(--rem-size-16)
      }
      @include respond-to(medium) {
        padding: 0 var(--rem-size-32);
      }
      @include respond-to(slarge) {
        width: 50%;
        max-width: 600px;
      }

      &.has-separator:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        height: 100%;
        border-right: 1px solid var(--grey);
        transform: translateY(-50%);

        @include respond-to(max-slarge) {
          display: none;
        }
        @include respond-to(slarge) {
          display: block;
        }
      }
    }
  }
  &__upload {
    padding-left: 0;

    &--pre-recorded-input {
      display: contents;
    }
  }
  & ::v-deep &__upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    text-transform: uppercase;

    &--pre-recorded {
      margin: 0;
    }
  }
  &__switch-title {
    margin-bottom: 0.5rem;
  }
  &__switch-desc {
    margin-bottom: 0.5rem;
    min-height: 50px;
  }
  &__button {
    min-width: 235px;
    height: 55px;
    font-family: var(--text-font-family);
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__footer {
    margin-top: 50px;
    text-align: center;
  }
  &__back.hollow {
    position: fixed;
    margin: 0;
    padding: 0.6rem 1rem;
    color: var(--grey-darker);
    border-color: var(--grey-darker);
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;

    @include respond-to(max-medium) {
      top: 0.5rem;
      left: 0.5rem;
      z-index: 100;
    }
    @include respond-to(medium) {
      top: 1.875rem;
      left: 1.875rem;
    }

    &:hover {
      color: var(--grey-darkest);
      border-color: var(--grey-darkest);
    }
  }
  ::v-deep .upload_input input {
    background-color: var(--white);
  }
  &__attachment {
    width: 100%;
    height: 45px;
    max-width: 600px;
    border: 2px dashed var(--main-color);
    border-radius: var(--global-radius);

    &--filled {
      max-width: 600px;
      margin-top: 3px;
      border: none;
    }
    &__preview {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: var(--global-radius);
    }
    &__no-preview {
      position: relative;
      border-radius: var(--global-radius);
    }
    &__preview &__file.has-file {
      border: 2px dashed var(--main-color);
    }
    &__preview:hover &__file.has-file {
      background-color: rgba(0, 0, 0, 0.7);
      border: 2px solid var(--main-color);
      border-radius: var(--global-radius);

      span.white-hovered {
        color: var(--white);
        font-size: 1.0625rem;
        font-weight: var(--font-weight-medium);
      }
    }
    &__file {
      display: flex;
      align-items: center;
      justify-content: center;

      ::v-deep button {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      &__label {
        span {
          color: var(--main-color);
          font-size: 0.875rem;
        }
      }
      &__delete {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 30px;
        height: 30px;
        padding: 0.5rem;
        color: var(--white);
        background-color: var(--secondary-color);
        font-size: 1.2rem;
        text-align: center;
        line-height: 14px;
        cursor: pointer;
      }
    }
  }
  &__attachment:hover {
    border: 2px solid var(--main-color);

    span.white-hovered {
      font-weight: var(--font-weight-medium);
    }
  }
  &__upload-infos {
    display: flex;
    align-items: flex-start;
    color: var(--grey-darker);
    font-size: 0.75rem;
    font-weight: var(--font-weight-light);
    font-style: italic;

    &--filled {
      font-weight: var(--font-weight-medium);
      line-height: 1.125rem;
    }
  }
  &__stream-types {
    padding-bottom: 0;

    ::v-deep .v-select {
      margin-bottom: 0;
    }
  }
  &__url-container-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    color: var(--main-color);
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    &__link {
      width: 95%;
      flex: 0 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__infos {
    margin-bottom: 1rem;
    color: var(--grey-darker);
    font-size: 0.75rem;
    font-weight: var(--font-weight-light);
    font-style: italic;
  }
}
.upload-replay {
  display: flex;
  gap: var(--rem-size-8);
  margin-bottom: var(--rem-size-24);

  ::v-deep .idt-fs-upload-file {
    flex: 0 0 auto;
  }
}
