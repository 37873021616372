.idt-sponsor-stats-metrics {
  max-height: 100%;
  padding-right: var(--rem-size-10);
  padding-left: var(--rem-size-10);
  overflow-y: auto;
}

.idt-sponsor-stats-metrics__container {
  margin-top: var(--rem-size-18);
}

.idt-sponsor-stats-metrics__container__label {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--rem-size-6);
  font-size: var(--rem-size-14);
}

.idt-sponsor-stats-metrics__container__no-data {
  display: flex;
  justify-content: center;
}

.idt-sponsor-stats-metrics__container__loader {
  width: 50px;
  height: 50px;
  margin: var(--rem-size-24) auto;
}

.idt-sponsor-stats-metrics__container+.idt-sponsor-stats-metrics__container {
  margin-top: var(--rem-size-21);
}

