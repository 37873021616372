.idt-tabs {
  display: flex;
  align-items: center;
  height: 2.8125rem;
  max-width: 100%;
  margin: 1rem 0;
  background: var(--grey-light);
  border: 4px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.idt-tabs+div {
  position: relative;
}

.idt-tabs__button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  padding: 0 var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-14);
  background: var(--grey-light);
  color: var(--black);
  border-radius: var(--global-radius);
  cursor: pointer;
  transition: background-color .15s ease-in-out,color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.idt-tabs__button.selected {
  background: var(--white);
  color: var(--main-color);
  box-shadow: 0 2px 3px var(--black-alpha10);
}

.idt-tabs-switch-enter-active,
.idt-tabs-switch-leave-active {
  transition: all .2s ease-in-out;
}

.idt-tabs-switch-leave-active {
  top: 0;
  position: absolute;
}

.idt-tabs-switch-leave-to {
  opacity: 0;
  transform: translateY(15px);
}

.idt-tabs-switch-enter-active {
  opacity: 0;
  transition-delay: .2s;
}

.idt-tabs-switch-enter-to {
  opacity: 1;
}

