




































@import '@/styles/mixins/index';

.simple-modal {
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    background-color: var(--modal-background);
    transform: translate(-50%, -50%);
    animation: slideDown 0.3s ease-in-out;
    overflow-y: auto;
  }

  &__close {
    position: absolute;
    top: var(--rem-size-15);
    right: var(--rem-size-15);
    cursor: pointer;
  }

  &.closing {
    animation: slideUp 0.3s ease-in-out;
  }
}

.simple-modal-medium {
  @include respond-to(max-medium) {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
  }
  @include respond-to(medium) {
    width: 600px;
    max-width: 100%;
  }
}

.simple-modal-large {
  @include respond-to(max-large) {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
  }
  @include respond-to(large) {
    width: 1000px;
    max-width: 100%;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
}
