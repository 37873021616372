







































































































































.signup-private {
  &__input {
    padding-bottom: 3rem !important;

    ::v-deep .idt-required-field {
      top: 76px;
    }
  }
  &__subtitle {
    color: var(--main-color);
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-medium);
  }
  &__desc {
    font-weight: var(--font-weight-light);
  }
  &__subtitle,
  &__desc {
    margin-bottom: 1rem;
    text-align: center;
  }
}
