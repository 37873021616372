@media only screen and (max-width: 639px) {
  :root {
    --tk-modal-header-height: 80px;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  :root {
    --tk-modal-header-height: 140px;
  }
}

@media only screen and (min-width: 1200px) {
  :root {
    --tk-modal-header-height: 108px;
  }
}

.ticketing-modal {
  --outer-elements-height-small: 166px;
  --outer-elements-height-large: 180px;
  height: calc(var(--vh, 1vh)*100);
}

.ticketing-modal .ticketing-blocking-modal {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh)*100);
}

.ticketing-modal .ticketing-blocking-modal__container {
  display: flex;
  flex: 1 1 auto;
  max-height: calc(var(--vh, 1vh)*100 - var(--tk-modal-header-height));
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .ticketing-modal .ticketing-blocking-modal__container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px)and (min-aspect-ratio: 13/9) {
  .ticketing-modal .ticketing-blocking-modal__container {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .ticketing-modal .ticketing-blocking-modal__container {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .ticketing-modal .ticketing-blocking-modal__container {
    align-items: center;
    padding-top: 2rem;
  }
}

.ticketing-modal .ticketing-blocking-modal__container__infos {
  color: var(--black);
  font-weight: var(--font-weight-light);
  line-height: normal;
}

@media only screen and (max-width: 369px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    font-size: .875rem;
  }
}

@media only screen and (max-width: 639px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    padding: 8px 10px;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    margin-bottom: 60px;
    padding: 1.25rem 1.5rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 1023px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    flex: 0 0 auto;
  }
}

@media only screen and (min-width: 1024px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    flex: 1 1 auto;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    padding: 1.25rem 1.5rem 1.25rem 2rem;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1441px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    max-width: 456px;
  }
}

@media only screen and (min-width: 1200px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    font-size: 2.1875rem;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1441px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    padding: 1.25rem 4rem 1.25rem 2rem;
  }
}

@media only screen and (min-width: 1442px) {
  .ticketing-modal .ticketing-blocking-modal__container__infos {
    max-width: 700px;
    padding: 1.25rem 4rem 1.25rem 6rem;
  }
}

.ticketing-modal .ticketing-blocking-modal__container__infos strong {
  font-weight: var(--font-weight-medium);
}

.ticketing-modal .ticketing-blocking-modal__container__tickets {
  flex: 1 1 auto;
}

@media only screen and (max-width: 991px) {
  .ticketing-modal .ticketing-blocking-modal__container__tickets {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .ticketing-modal .ticketing-blocking-modal__container__tickets {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .ticketing-modal .ticketing-blocking-modal__container__tickets {
    padding-right: 35px;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1441px) {
  .ticketing-modal .ticketing-blocking-modal__container__tickets {
    margin-top: -3rem;
  }
}

.ticketing-modal .ticketing-blocking-modal__container__no-ticket {
  width: 100%;
  text-align: center;
}

.ticketing-modal .ticketing-blocking-modal__container__no-ticket__text {
  font-size: 3.125rem;
  font-weight: var(--font-weight-light);
  color: var(--black);
  line-height: normal;
}

.ticketing-modal .ticketing-blocking-modal__container__no-ticket .contact-admin {
  width: 200px;
  height: 50px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-transform: uppercase;
}

.ticketing-modal .ticketing-blocking-modal__container .ticketing-payment-success {
  margin-top: 10px;
}

.ticketing-modal .ticketing-blocking-modal__contact-btn {
  padding: .625rem 1.5rem;
  font-size: .875rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

@media only screen and (min-width: 640px)and (max-width: 991px) {
  .ticketing-modal .ticketing-blocking-modal__contact-btn {
    margin: 1rem 0 0 0;
  }
}

@media only screen and (min-width: 992px) {
  .ticketing-modal .ticketing-blocking-modal__contact-btn {
    margin: 2rem 0 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .ticketing-modal .ticketing-blocking-modal__contact-btn.desktop {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .ticketing-modal .ticketing-blocking-modal__contact-btn.mobile {
    display: none;
  }
}

.ticketing-modal .modal-fullscreen__close {
  color: #fff;
}

.ticketing-modal .modal-fullscreen__close__container {
  background-color: inherit;
}

@media only screen and (min-width: 1200px) {
  .ticketing-modal .modal-fullscreen__close__container {
    top: 54px;
    transform: translateY(-50%);
  }
}

.modal-fullscreen {
  padding: 0 !important;
}

