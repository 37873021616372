






























































.days-buttons {
  display: inline-flex;
  align-items: center;
  &__day-button {
    flex-direction: column;
    margin-right: var(--rem-size-16);
    margin-left: var(--rem-size-16);
    &__day-clickable {
      font-family: var(--first-font-family);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.7px;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
    }
    &__day-clickable:hover {
      color: var(--grey-dark);
      cursor: pointer;
    }
    &__day-clickable:active {
      top: 1px;
      color: var(--white);
    }
    &__mark-selected {
      width: 100%;
      margin: auto;
      border: 2px solid var(--main-color);
      border-radius: 5px;
    }
    &__mark-unselected {
      width: 100%;
      margin: auto;
      border-color: var(--grey-lighter);
    }
  }
}
