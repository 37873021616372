.bo-custom-questions-menu__item {
  width: 100%;
  padding: 20px 0;
  color: var(--grey-darkest);
  text-align: center;
}

.bo-custom-questions-menu__item__text {
  margin-left: 10px;
}

.bo-custom-questions-menu__item:hover {
  background-color: var(--grey-lighter);
}

