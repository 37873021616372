.idt-sidebar-user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--grey-alpha20);
  transition: all .3s ease;
}

.idt-sidebar-user-row:hover {
  background-color: var(--white-alpha10);
}

.idt-sidebar-user-row__content {
  display: flex;
  align-items: center;
  width: calc(100% - 3.75rem);
  padding: var(--rem-size-14) 0;
  cursor: pointer;
}

.idt-sidebar-user-row__content .content__avatar {
  flex: 0 0 auto;
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  background-color: var(--white);
  border-radius: 100%;
}

.idt-sidebar-user-row__content .content__text {
  width: calc(100% - 2.625rem);
  padding: 0 var(--rem-size-11);
}

.idt-sidebar-user-row__content .content__text__name {
  color: var(--white);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.idt-sidebar-user-row__content .content__text__description {
  color: var(--white-alpha70);
  font-size: var(--rem-size-12);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.idt-sidebar-user-row__action {
  position: relative;
}

.idt-sidebar-user-row__action__button {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin: 0 var(--rem-size-14);
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 50%;
  cursor: pointer;
}

.idt-sidebar-user-row__action__pane-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--rem-size-8) var(--rem-size-20);
  font-size: var(--rem-size-14);
  border-bottom: 1px solid var(--grey);
}

.idt-sidebar-user-row__action__pane-row:last-child {
  border-bottom: none;
}

.idt-sidebar-user-row__action__pane-row:hover {
  background-color: var(--grey-light);
  cursor: pointer;
}

.idt-sidebar-user-row__action__pane-row__label {
  white-space: nowrap;
}

.idt-sidebar-user-row ::v-deep .idt-dropdown {
  width: unset;
}

.idt-sidebar-user-row ::v-deep .idt-dropdown--pane {
  width: unset;
  left: unset !important;
  right: 5px;
}

