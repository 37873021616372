.menu-mobile-list-item {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  max-height: 64px;
  color: var(--grey-darkest);
  font-size: .95rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  transition: all .3s ease;
}

.menu-mobile-list-item:not(.state):hover {
  color: var(--white);
  background-color: var(--main-color);
}

.menu-mobile-list-item.state {
  font-size: .75rem;
  border-bottom: 1px solid var(--main-color);
}

