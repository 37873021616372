























































































































































































































































































































































































.bo-lobby-settings {
  &__warning {
    position: relative;
    margin-bottom: 24px;
    padding: var(--rem-size-24) var(--rem-size-24) var(--rem-size-24) 60px;
    color: var(--yellow-dark);
    background-color: var(--yellow-dark-alpha10);
    border-radius: var(--global-radius);
    font-size: var(--rem-size-14);

    &__icon {
      color: var(--yellow-dark);

      .ideacon {
        position: absolute;
        top: 50%;
        left: var(--rem-size-24);
        transform: translateY(-50%);
      }
    }
  }
}
