.project-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  background: var(--white);
  overflow: hidden;
  color: var(--grey-darkest);
}

.project-card__preview {
  width: 100%;
  height: 180px;
  background-color: var(--main-color-lighter);
  background-size: cover;
  border-radius: var(--rem-size-5);
  font-size: var(--rem-size-16);
}

.project-card__texts {
  margin-top: var(--rem-size-22);
}

.project-card__texts__title {
  font-weight: var(--font-weight-bold);
}

.project-card__texts__description {
  height: var(--rem-size-48);
}

.project-card__texts__challenges {
  height: var(--rem-size-24);
  margin-top: var(--rem-size-6);
  margin-bottom: var(--rem-size-10);
  overflow: hidden;
}

.project-card__footer {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  padding-top: .625rem;
}

.project-card__footer__vote {
  display: inline-block;
  flex: 0 0 auto;
  padding: var(--rem-size-3) var(--rem-size-14);
  border: 2px solid var(--white);
  border-radius: var(--rem-size-30);
  font-size: var(--rem-size-14);
}

.project-card__footer__vote.allin {
  color: var(--project-vote-0);
  border-color: var(--project-vote-0);
}

.project-card__footer__vote.pivot {
  color: var(--project-vote-1);
  border-color: var(--project-vote-1);
}

.project-card__footer__vote.kill {
  color: var(--project-vote-2);
  border-color: var(--project-vote-2);
}

.project-list-card {
  width: 100%;
  margin-bottom: 0;
  padding: var(--rem-size-10);
  background-color: var(--white);
  border-radius: var(--rem-size-5);
  box-shadow: 0px 2px 3px 0px var(--grey);
}

.project-list-card__row {
  display: flex;
}

.project-list-card__left-column {
  flex: 0 0 30%;
  max-width: 30%;
  min-width: 235px;
}

@media only screen and (max-width: 1023px) {
  .project-list-card__middle-column {
    flex: 0 0 42%;
    max-width: 42%;
  }
}

@media only screen and (min-width: 1024px) {
  .project-list-card__middle-column {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 1200px) {
  .project-list-card__middle-column {
    flex: 0 0 42%;
    max-width: 42%;
  }
}

.project-list-card__right-column {
  flex: 0 0 28%;
  max-width: 28%;
}

.project-list-card__image {
  height: 180px;
  background-color: var(--main-color-lighter);
  background-size: cover;
  border-radius: var(--rem-size-5);
}

.project-list-card__texts {
  border-right: 1px solid var(--grey-lighter);
}

@media only screen and (max-width: 1023px) {
  .project-list-card__texts {
    margin: var(--rem-size-30) 0 0 var(--rem-size-30);
    padding-right: var(--rem-size-30);
  }
}

@media only screen and (min-width: 1024px) {
  .project-list-card__texts {
    margin: var(--rem-size-30) 0 0 var(--rem-size-10);
    padding-right: var(--rem-size-10);
  }
}

@media only screen and (min-width: 1200px) {
  .project-list-card__texts {
    margin: var(--rem-size-30) 0 0 var(--rem-size-30);
    padding-right: var(--rem-size-30);
  }
}

.project-list-card .project-list-card-authors {
  margin-top: var(--rem-size-30);
}

@media only screen and (max-width: 1023px) {
  .project-list-card .project-list-card-authors {
    width: calc(100% - var(--rem-size-30));
    padding-left: var(--rem-size-30);
  }
}

@media only screen and (min-width: 1024px) {
  .project-list-card .project-list-card-authors {
    width: calc(100% - var(--rem-size-10));
    padding-left: var(--rem-size-10);
  }
}

@media only screen and (min-width: 1200px) {
  .project-list-card .project-list-card-authors {
    width: calc(100% - var(--rem-size-30));
    padding-left: var(--rem-size-30);
  }
}

.project-list-card .project-list-card-authors--no-votes {
  margin-top: var(--rem-size-15);
}

.project-list-card .project-list-card-authors__title {
  margin-bottom: var(--rem-size-8);
  font-size: var(--rem-size-12);
}

.project-list-card .project-list-card-authors__list {
  margin-top: var(--rem-size-6);
}

.project-list-card .project-list-card-authors__list__avatars {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--rem-size-8);
}

.project-list-card .project-list-card-authors__list__avatars__avatar {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.project-list-card .project-list-card-authors__list__avatars__avatar__icon {
  background-color: var(--grey);
}

.project-list-card .project-list-card-authors__list__avatars__avatar__name {
  font-size: var(--rem-size-14);
}

.project-list-card .project-list-card-authors__list__avatars__more__indicator {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  font-size: var(--rem-size-14);
  z-index: 1;
}

.project-list-card .project-list-card-authors__list__avatars__more__indicator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: var(--main-color-lighter);
  border-radius: 50%;
  opacity: .9;
  z-index: -1;
}

.project-list-card .project-list-card-authors__list__avatars__more__wording {
  font-size: var(--rem-size-14);
}

.project-list-card .project-list-card-authors__list__avatars__more__label {
  font-size: var(--rem-size-14);
}

.project-list-card .project-list-card-authors__list ::v-deep .users-list-popover__item__user__name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 25ch;
  white-space: nowrap;
}

.project-list-card .project-list-card-authors__list ::v-deep .idt-dropdown {
  display: block;
}

::v-deep .idt-tag {
  height: 20px;
  max-width: 205px;
}

