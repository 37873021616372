




















.idt-expired-token {
  padding-top: 25px;
  padding-bottom: 50px;
  text-align: center;

  &__icon {
    color: var(--grey);
    font-size: 80px;
  }
  &__text {
    max-width: 450px;
    margin: auto;
  }
  h1 {
    margin-bottom: 25px;
  }
}
