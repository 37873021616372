.menu-ticket-info {
  padding: var(--rem-size-5) 1rem;
  background-color: var(--grey-lightest);
  text-align: center;
}

.menu-ticket-info__title {
  margin-bottom: .625rem;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  text-align: center;
  text-transform: uppercase;
}

.menu-ticket-info__credit-list {
  display: flex;
  justify-content: space-around;
}

