.idt-info {
  display: flex;
  align-items: center;
  padding: var(--rem-size-8);
  border-radius: var(--global-radius);
  background-color: var(--info-color-lightest);
  color: var(--info-color);
  font-weight: 400;
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-18);
}

.idt-info__icon {
  margin-right: var(--rem-size-16);
}

.idt-info.danger {
  background-color: var(--danger-color-lightest);
  color: var(--danger-color);
}

.idt-info.success {
  background-color: var(--success-color-lightest);
  color: var(--success-color);
}

.idt-info.warning {
  background-color: var(--warning-color-lightest);
  color: var(--warning-color);
}

