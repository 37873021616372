.conferences-page {
  height: calc(var(--vh, 1vh)*100 - 70px);
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
  overflow: auto;
}

@media only screen and (max-width: 1023px) {
  .conferences-page {
    height: fit-content;
    overflow: unset;
  }
}

.conferences-page__conferences .side-open-enter-active {
  transition: opacity 1s;
}

.conferences-page__conferences .side-open-enter {
  opacity: 0;
}

.conferences-page__conferences__filter-panel {
  position: sticky;
  top: 0;
  max-width: 280px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.conferences-page__conferences__filter-panel.hidden {
  position: absolute;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__filter-panel {
    background-color: var(--white);
    max-width: none;
  }
}

.conferences-page__conferences__filter-panel::-webkit-scrollbar {
  display: none;
}

.conferences-page__conferences__conferences-list__no-more {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: var(--rem-size-15);
}

.conferences-page__conferences__conferences-list__spinner {
  display: flex;
  align-content: left;
}

.conferences-page__conferences__conferences-list__header {
  position: sticky;
  top: 0;
  padding: var(--rem-size-15) 0 var(--rem-size-25) 0;
  background-color: var(--grey-lighter);
  box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
  clip-path: inset(0px -10px 0px -10px);
  z-index: 10;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__header {
    padding: var(--rem-size-15) 0 var(--rem-size-10) 0;
  }
}

.conferences-page__conferences__conferences-list__header__search {
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__header__search {
    height: 100%;
    visibility: initial;
  }
}

.conferences-page__conferences__conferences-list__header__search__bar {
  height: 46px;
  margin-right: var(--rem-size-10);
  background-color: var(--white);
  border: solid 1px var(--grey-light);
  border-radius: var(--global-radius);
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

.conferences-page__conferences__conferences-list__header__search__bar__input {
  height: 44px;
  margin: 0;
  color: var(--black);
  background-color: var(--white);
  border: none;
  font-size: var(--rem-size-16);
}

.conferences-page__conferences__conferences-list__header__search__bar:before {
  content: "";
  display: block;
  top: 84px;
  left: 0px;
  position: absolute;
  color: var(--black);
  font-family: "ideation-icons";
  font-size: 1rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.conferences-page__conferences__conferences-list__header__search__filter {
  height: 46px;
  background-color: var(--white);
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

.conferences-page__conferences__conferences-list__header__title-results {
  top: 0;
  position: sticky;
  height: var(--rem-size-32);
  margin-bottom: var(--rem-size-15);
  color: var(--black);
}

.conferences-page__conferences__conferences-list__header__title-results__title {
  max-height: 48px;
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-bold);
  text-align: left;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__header__title-results__title {
    text-align: center;
  }
}

.conferences-page__conferences__conferences-list__header__title-results__title.search {
  font-size: var(--rem-size-16);
}

.conferences-page__conferences__conferences-list__header__title-results__result {
  align-self: flex-end;
  font-size: var(--rem-size-15);
  text-align: right;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__header__title-results__result {
    display: none;
  }
}

.conferences-page__conferences__conferences-list__header__title-results__mobile-result {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__header__title-results__mobile-result {
    display: flex;
    height: var(--rem-size-22);
    margin: 0;
    font-size: var(--rem-size-14);
    text-align: right;
  }
}

.conferences-page__conferences__conferences-list__header__infos {
  align-content: center;
  height: 64px;
  background: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__header__infos {
    height: 0;
    visibility: hidden;
  }
}

.conferences-page__conferences__conferences-list__header__infos__live {
  margin-left: var(--rem-size-25);
}

.conferences-page__conferences__conferences-list__header__infos__live__button {
  align-items: center;
  width: fit-content;
  height: var(--rem-size-32);
  border: 2px solid var(--red);
  border-radius: 5em/5em;
  color: var(--red);
}

.conferences-page__conferences__conferences-list__header__infos__live__button.active {
  color: var(--white);
  background-color: var(--red);
}

.conferences-page__conferences__conferences-list__header__infos__live__button:hover {
  cursor: pointer;
}

.conferences-page__conferences__conferences-list__header__infos__live__button__label {
  padding: 0 var(--rem-size-15) 0 7px;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
}

.conferences-page__conferences__conferences-list__header__infos__live__button__blob {
  width: var(--rem-size-10);
  height: var(--rem-size-10);
  margin-left: var(--rem-size-10);
  background: var(--red);
  border-radius: 50%;
  box-shadow: 0 0 0 0 var(--red);
  animation: pulse-red 2s infinite;
  transform: scale(1);
}

.conferences-page__conferences__conferences-list__header__infos__live__button__blob.active {
  animation: pulse-white 2s infinite;
  background: var(--white);
  box-shadow: 0 0 0 0 var(--white);
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 var(--red-alpha70);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 var(--rem-size-10) rgba(0,0,0,0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    transform: scale(0.95);
  }
}

@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 var(--white-alpha70);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 var(--rem-size-10) var(--white-alpha10);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 var(--white-alpha10);
    transform: scale(0.95);
  }
}

.conferences-page__conferences__conferences-list__header__infos__live .row {
  align-items: center;
}

.conferences-page__conferences__conferences-list__header__infos__sort__label {
  margin: var(--rem-size-5) var(--rem-size-10) 0 0;
  color: var(--black);
}

.conferences-page__conferences__conferences-list__header__infos__sort__select {
  width: 170px;
  height: 41px;
  margin: 0 var(--rem-size-10) 0 0;
  padding: 0 var(--rem-size-15);
}

.conferences-page__conferences__conferences-list__header__infos__list-mode {
  color: var(--grey);
}

.conferences-page__conferences__conferences-list__header__infos__list-mode__button {
  margin-right: var(--rem-size-10);
}

.conferences-page__conferences__conferences-list__header__infos__list-mode__button:hover {
  cursor: pointer;
}

.conferences-page__conferences__conferences-list__header__infos__list-mode__button.active {
  color: var(--black);
}

.conferences-page__conferences__conferences-list__list__group {
  top: 150px;
  height: 100%;
  position: sticky;
  box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
  clip-path: inset(0px -10px 0px -10px);
  color: var(--black);
  z-index: 5;
}

@media only screen and (max-width: 1023px) {
  .conferences-page__conferences__conferences-list__list__group {
    top: 140px;
  }
}

.conferences-page__conferences__conferences-list__list__group__text {
  background-color: var(--grey-lighter);
  border-bottom: 1px solid var(--grey-dark);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-22);
  font-weight: var(--font-weight-bold);
  text-align: left;
}

.conferences-page__conferences__conferences-list__list__release-date {
  top: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: sticky;
  color: var(--black);
  background-color: var(--grey-lighter);
  box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
  clip-path: inset(0px -10px 0px -10px);
  z-index: 5;
}

.conferences-page__conferences__conferences-list__list__release-date__text {
  margin-left: 10px;
  font-family: var(--text-font-family);
  font-size: var(--rem-size-18);
  text-align: left;
}

.conferences-page__conferences__conferences-list__list__container {
  padding: var(--rem-size-15) 0 var(--rem-size-15) 0;
}

.conferences-page__conferences__conferences-list__no-item-icon {
  padding-bottom: var(--rem-size-15);
}

