































































@import '@/styles/mixins/index';

.idt-ticket-card-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;

  &:not(.free) {
    @include respond-to(max-xlarge) {
      padding: 1rem 0 0.3125rem;
    }
    @include respond-to(xlarge-xxlarge) {
      padding: 1.5rem 0 0.625rem;
    }
    @include respond-to(xxlarge) {
      padding: 2rem 0 1.25rem;
    }
  }
  &.free {
    @include respond-to(max-xlarge) {
      padding: 1rem 0 1.25rem;
    }
    @include respond-to(xlarge-xxlarge) {
      padding: 1.5rem 0 1.5625rem;
    }
    @include respond-to(xxlarge) {
      padding: 2rem 0 2.1876rem;
    }
  }
  &__current {
    font-weight: var(--font-weight-bold);
    line-height: normal;
    text-transform: uppercase;

    @include respond-to(max-xlarge) {
      font-size: 2rem;
    }
    @include respond-to(xlarge) {
      font-size: 2.625rem;
    }
  }
  &__currency {
    font-size: 0.625rem;
  }
  &.modal-mode &__current {
    @include respond-to(xsmall) {
      font-size: 2.625rem;
    }
  }
}
