























































































































































































































































































































.items {
  display: flex;
  justify-items: center;
  flex-flow: column;
  width: 100%;

  &__input {
    padding-right: 0px;
  }

  &__label {
    font-weight: var(--font-weight-medium);
  }

  &__inputs {
    height: 50px;
    margin-bottom: 10px;

    &__item-button {
      .button {
        width: 122px;
        height: 48px;
        font-size: var(--rem-size-16);
        font-weight: var(--font-weight-medium);
      }
    }
    &__text {
      padding-right: 0px;

      &__area {
        overflow: auto;
      }
    }
    &__content {
      height: 20px;

      &__delete {
        margin-right: 15px;
        color: var(--red);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-bold);

        &:hover {
          cursor: pointer;
          filter: brightness(75%);
        }

        &__icon {
          color: var(--red);
        }
      }

      .button {
        margin: 0 0 1.75rem 0;
      }
    }
  }

  &__empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    max-height: 500px;
    margin: 40px 0px 0px 0px;
    border: solid 1px var(--grey-light);

    &__text {
      font-family: var(--text-font-family);
      font-size: var(--rem-size-16);
      font-weight: var(--font-weight-light);
      line-height: 22px;
      text-align: center;
    }
  }
}
