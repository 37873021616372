






































































































































































@import '@/styles/mixins/index';

.live {
  height: 100%;
  padding: 1.5rem 1.25rem;
  color: var(--white);
  background-color: var(--main-color);
  font-size: var(--rem-size-14);

  .highlight {
    margin-bottom: 0.3125rem;
    padding: 0.3125rem 0.9375rem;
    color: var(--main-color-dark);
    background-color: var(--main-color-lighter);
    border-radius: 5px;
  }
  &__users-header {
    display: flex;
    justify-content: space-between;

    &__refresh {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white);
      cursor: pointer;
    }
  }
  &__users-number {
    font-size: var(--rem-size-40);
  }
  &__content {
    .content__title {
      margin-top: 0.9375rem;
      margin-bottom: 0.3125rem;
    }
    .content__number {
      display: flex;
      justify-content: space-between;
    }
  }
  &__top-talk {
    @include respond-to(max-slarge) {
      padding-right: 0.5rem;

      &:last-child {
        padding-left: 0.5rem;
        padding-right: 0;
      }
    }
    .top-talk__header {
      margin-bottom: 0.3125rem;

      @include respond-to(slarge) {
        margin-top: 0.9375rem;
      }
    }
    .top-talk__no-data {
      padding: 0.3125rem 0.9375rem;
    }
  }
  &__top-conference {
    @include respond-to(max-slarge) {
      display: flex;
      margin-top: 1.25rem;
    }
  }
  &__loader {
    width: 50px;
    height: 50px;
    margin-top: 0.625rem;
  }
}
