.bo-form-section-separator {
  display: flex;
  align-items: center;
  width: 100%;
}

.bo-form-section-separator__left-side,
.bo-form-section-separator__right-side {
  flex: 1 1 auto;
}

.bo-form-section-separator__section-title {
  padding: 0 30px;
  font-weight: var(--font-weight-medium);
}

