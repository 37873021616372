.idt-sponsor-stats-metric {
  height: 100%;
  padding: var(--rem-size-18) var(--rem-size-10) 0 var(--rem-size-10);
}

.idt-sponsor-stats-metric__count {
  margin-bottom: var(--rem-size-26);
  font-size: var(--rem-size-16);
}

.idt-sponsor-stats-metric__count ::v-deep .idt-sponsor-stats-metric__count__value {
  color: var(--main-color);
}

.idt-sponsor-stats-metric__export {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--rem-size-23);
}

.idt-sponsor-stats-metric__export .button {
  margin: 0;
  padding: var(--rem-size-4) var(--rem-size-24);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  border-radius: var(--rem-size-23);
}

.idt-sponsor-stats-metric__toolbar {
  display: flex;
  justify-content: space-between;
}

.idt-sponsor-stats-metric__toolbar__filter {
  margin-right: var(--rem-size-8);
}

.idt-sponsor-stats-metric__toolbar__filter+.idt-sponsor-stats-metric__toolbar__sort {
  margin-left: var(--rem-size-8);
}

.idt-sponsor-stats-metric__toolbar__filter+.idt-sponsor-stats-metric__toolbar__sort ::v-deep .idt-dropdown--pane {
  left: auto !important;
  right: 0 !important;
}

.idt-sponsor-stats-metric__toolbar .input-dropdown {
  position: relative;
  cursor: pointer;
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3.313rem;
  padding: var(--rem-size-10) var(--rem-size-16);
  color: var(--grey-light);
  background-color: var(--white-alpha10);
  border: none;
  border-radius: 5px;
  font-size: var(--rem-size-16);
  transition: all .3s ease;
}

@media only screen and (max-width: 639px) {
  .idt-sponsor-stats-metric__toolbar .input-dropdown__input {
    width: 10.625rem;
  }
}

@media only screen and (min-width: 640px) {
  .idt-sponsor-stats-metric__toolbar .input-dropdown__input {
    width: 10.125rem;
  }
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__input.focus {
  background-color: var(--dark-color-dark);
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__input__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__pane-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--rem-size-8) var(--rem-size-20);
  font-size: var(--rem-size-14);
  border-bottom: 1px solid var(--grey);
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__pane-row:last-child {
  border-bottom: none;
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__pane-row:hover {
  background-color: var(--grey-light);
  cursor: pointer;
}

.idt-sponsor-stats-metric__toolbar .input-dropdown__pane-row .pane-row__label {
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 639px) {
  .idt-sponsor-stats-metric__toolbar .input-dropdown ::v-deep .idt-dropdown--pane {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .idt-sponsor-stats-metric__toolbar .input-dropdown ::v-deep .idt-dropdown--pane {
    width: 21.25rem;
  }
}

.idt-sponsor-stats-metric__list {
  height: calc(100% - 11.5rem);
  padding-top: var(--rem-size-18);
}

.idt-sponsor-stats-metric__list__row {
  margin-top: var(--rem-size-4);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
}

.idt-sponsor-stats-metric__list__row__tags {
  display: flex;
  font-size: var(--rem-size-12);
}

.idt-sponsor-stats-metric__list__row__tags__container--live-with-replay {
  margin-right: var(--rem-size-22);
}

.idt-sponsor-stats-metric__list__row__tags__container--no-data {
  display: none;
}

.idt-sponsor-stats-metric__list__row__tag {
  margin-right: var(--rem-size-14);
  padding: var(--rem-size-1) var(--rem-size-12);
  background-color: var(--main-color);
  border-radius: 9px;
}

.idt-sponsor-stats-metric__list__row__tag--talk {
  padding: var(--rem-size-2) var(--rem-size-10);
  border-radius: 4px;
}

.idt-sponsor-stats-metric__list__row__tag--live {
  margin-right: var(--rem-size-9);
  background-color: var(--red);
}

.idt-sponsor-stats-metric__list__row__tag--replay {
  margin-right: var(--rem-size-9);
  background-color: var(--black);
}

.idt-sponsor-stats-metric__list__row__tag--on-demand {
  margin-right: var(--rem-size-11);
  background-color: var(--main-color);
}

::v-deep .idt-dropdown {
  width: 100%;
}

::v-deep .idt-dropdown--pane {
  padding: 0;
  color: var(--black);
  border: none;
  box-shadow: 2px 2px 5px var(--black-alpha20);
  overflow-y: auto;
}

@media only screen and (min-width: 992px) {
  ::v-deep .idt-dropdown--pane {
    max-height: calc(100vh - 400px);
  }
}

