




































































































































































































































































































































































































































































@import '@/styles/mixins';

.idt-user-profile-container {
  position: relative;
  width: 0;
  height: 1rem;
  transition: width 0.3s ease;
  transform: translate3d(2rem, 0, 0);

  @include respond-to(slarge) {
    position: absolute;
    right: calc(100% - 26px);
  }

  @include respond-to(max-slarge) {
    flex: 0 0 auto;
  }

  &.open {
    transform: translate3d(0, 0, 0);

    @include respond-to(max-medium) {
      width: 100%;
    }
    @include respond-to (medium-slarge) {
      width: 564px;
    }
    @include respond-to(slarge-xlarge) {
      width: 580px;
    }
    @include respond-to(xlarge) {
      width: 700px;
    }

    .idt-user-profile {
      @include respond-to(max-medium) {
        transform: translate3d(0, 0, 0);
      }

      &__buttons {
        display: flex;
      }
    }
  }
  .idt-user-profile {
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
    padding: 0 1rem;
    padding-top: 51px;
    overflow: auto;
    background: var(--white);

    @include respond-to(max-medium) {
      width: 100%;
      transform: translate3d(0, 100%, 0);
    }
    @include respond-to (medium-slarge) {
      width: 564px;
    }
    @include respond-to(slarge-xlarge) {
      width: 580px;
    }
    @include respond-to(xlarge) {
      width: 700px;
    }

    &__buttons {
      display: none;
      position: absolute;
      right: 100%;
      top: calc((var(--vh, 1vh) * (-100)) + 1rem + var(--menu-height));
      flex-direction: column;
      padding: 12px;

      @include respond-to(max-medium) {
        left: 0;
        z-index: 1;
      }

      button {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        cursor: pointer;
        background-color: var(--white);
        border: 1px solid var(--grey-light) !important;

        &:first-child {
          margin-bottom: 15px;
        }
      }
    }

    &__info {
      max-width: 600px;
      margin: auto;

      &__summary {
        display: flex;
        align-items: center;
        gap: var(--rem-size-24);

        @include respond-to(max-medium) {
          flex-wrap: wrap;
        }

        &__avatar {
          min-width: 121px;

          @include respond-to(max-medium) {
            margin: auto;
          }
        }
        &-container {
          @include respond-to(max-medium) {
            flex-basis: 100%;
            text-align: center;
          }
        }
        &__name {
          font-size: var(--rem-size-18);
          color: var(--black);
          font-weight: var(--font-weight-bold);
          margin-bottom: 6px;
        }
        &__pro-info {
          color: var(--black);
          font-size: var(--rem-size-14);
          margin-bottom: 9px;
        }

        &__tags {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: var(--rem-size-8);
          margin-bottom: 12px;

          @include respond-to(max-medium) {
            justify-content: center;
          }

          &__prize {
            max-width: 200px;
          }
        }

        &__social-link {
          display: inline-block;
          width: 25px;
          height: 25px;
          margin-right: 5px;
          padding-top: 2px;
          text-align: center;
          border-radius: 5px;
          color: var(--black);

          &:hover {
            color: var(--white);
          }

          &.facebook {
            margin-right: 1px;

            &:hover {
              background-color: var(--facebook-color);
            }
          }

          &.twitter:hover {
            background-color: var(--twitter-color);
          }

          &.linkedIn:hover {
            background-color: var(--linkedin-color);
          }

          &.youtube {
            display: inline-block;
            width: 22px;
            height: 16px;
            padding-top: 2px;
            margin-left: 2px;
            margin-right: .5rem;
            vertical-align: sub;
            color: var(--white);
            background: var(--black);
            border-radius: 4px;

            &:hover {
              background-color: var(--youtube-color);
          }
          }
        }

        &__website {
          color: var(--black);
          text-decoration: underline;
          font-size: var(--rem-size-14);
        }
      }

      &__connect {
        margin-top: 25px;

        @include respond-to(max-medium) {
          text-align: center;
        }

        &__title {
          margin-bottom: 11px;
          text-transform: uppercase;
          color: var(--black);
          font-weight: var(--font-weight-bold);
        }

        &__buttons {
          button {
            padding: 0 35px;
            height: 45px;
            line-height: 45px;

            &:first-child {
              @include respond-to (medium) {
                margin-right: 15px;
              }
            }

            .ideacon {
              margin-right: 15px;
              margin-left: -6px;
              margin-top: -5px;
            }
          }
        }
      }

      &__about {
        margin: 40px 2.2rem 0 2.2rem;

        &__title {
          margin-bottom: 4px;
          color: var(--black);
          font-weight: var(--font-weight-bold);
        }

        &__date {
          font-size: var(--rem-size-12);
          color: var(--black-alpha70)
        }

        &__description {
          margin-top: 29px;
          color: var(--black);
        }
      }
    }

    &__activity {
      min-height: 450px;
      margin: 0 -1rem;
      margin-top: 30px;
      padding: 0 1rem;
      background-color: var(--grey-lighter);

      &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        min-height: 450px;
        margin: auto;
        padding: 30px 0;
      }

      &__tabs {
        display: flex;
        align-items: center;
        margin-left: 0;

        &__item {
          cursor: pointer;
          padding: 0;
          padding-bottom: 5px;
          margin-right: 30px;
          list-style-type: none;
          font-weight: bold;

          &.selected {
            color: var(--black);
            border-bottom: 2px solid var(--main-color);
          }
        }
      }

      &__list {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        flex-grow: 1;
        margin: 0 -10px;

        @include respond-to(max-medium) {
          flex-flow: row nowrap;
          overflow: auto;
        }

        &__item {
          display: flex;
          flex-basis: 50%;
          padding: 10px;

          @include respond-to(max-medium) {
            min-width: 295px;
          }
        }

        &__no-item {
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          h5 {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
