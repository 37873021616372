












































































@import '@/styles/mixins/index';

.bo-section {
  &__title {
    margin: 0 0 30px 0;
    color: var(--grey-darker);
    font-size: 1.7rem;
    font-weight: var(--font-weight-light);

    &__number {
      display: inline-block;
      width: 27px;
      height: 27px;
      color: var(--white);
      background-color: var(--main-color);
      border-radius: 100%;
      font-size: 1.1rem;
      text-align: center;
      line-height: 28px;
      vertical-align: text-top;
    }
  }
  &__separator {
    max-width: 100%;
    color: var(--grey);

    @include respond-to(max-medium) {
      margin: 1rem 0 1.5rem;
    }
    @include respond-to(medium) {
      margin: 1.75rem 0;
    }
  }
}
