.idt-circular-progress {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  border-radius: 50%;
  box-shadow: inset var(--grey) 0px 0px 0px 5px;
}

.idt-circular-progress__circle {
  position: relative;
  width: 40px;
  height: 40px;
  background: rgba(0,0,0,0);
  border-radius: 50%;
  overflow: hidden;
}

.idt-circular-progress__circle__segment {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: var(--main-color);
  transform-origin: 0 0;
  transition: transform .3s ease;
}

.idt-circular-progress__inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateX(-50%) translateY(-50%);
  background: var(--grey-lighter);
  border-radius: 50%;
}

.idt-circular-progress__inner--dark {
  background-color: var(--grey-darkest);
}

.idt-circular-progress__value {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-12);
  color: var(--black);
}

.idt-circular-progress__value--dark {
  color: var(--white);
}

