






















.fo-project-subheader {
  display: flex;
  position: sticky;
  top: 70px;
  justify-content: space-between;
  padding: var(--rem-size-12);
  color: var(--white);
  background-color: var(--grey-darkest);
  z-index: 1;

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
