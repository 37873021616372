



































@import '@/styles/mixins/index';

::v-deep .ideacon {
  stroke-width: 0.6px;
}
.idt-tile {
  display: flex;
  align-items: center;
  height: 75px;
  background-color: var(--grey-lighter);
  border-radius: var(--global-radius);
  transition: box-shadow 0.3s ease-in-out;

  @include respond-to(max-medium) {
    width: 100%;
    margin-bottom: 20px;
  }
  @include respond-to(medium) {
    margin-right: 20px;
  }
  @include respond-to(medium-large) {
    flex: 1 0 auto;
  }
  @include respond-to(large) {
    flex: 0 0 auto;
    width: 300px;
  }

  &:hover {
    // background-color: var(--main-color-light);
    box-shadow: 0px 2px 5px var(--black-alpha20);
  }
  &.has-event {
    cursor: pointer;
  }
  &:not(.has-event) {
    pointer-events: none;
  }
  &__icon {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-right: 10px;
    margin-left: 5px;
    background-color: var(--white);
    border-radius: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &__title {
      margin-bottom: 0.625rem;
      color: var(--black);
      font-family: var(--first-font-family);
      font-size: 1rem;
      line-height: 1.1875rem;
    }
    &__subtitle {
      font-family: var(--first-font-family);
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}
