.idt-sidebar-users-list {
  height: 100%;
  overflow-y: auto;
}

.idt-sidebar-users-list__loader {
  width: 50px;
  height: 50px;
  margin: var(--rem-size-24) auto;
}

.idt-sidebar-users-list__not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: var(--chat-min-with);
  padding: 0 var(--rem-size-16) var(--rem-size-16) var(--rem-size-16);
}

.idt-sidebar-users-list__not-found label {
  display: block;
  color: var(--white);
  font-weight: var(--font-weight-light);
  text-align: center;
}

