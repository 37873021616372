


































































































































.idt-user-list {
  height: 100%;
  padding: 0 0.625rem;

  &__toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: var(--chat-min-with);
    padding: 1rem 0;

    &__searchbar {
      flex: 1 1 auto;
      position: relative;
      width: 100%;
      margin-bottom: 5px;

      input {
        height: 40px;
        margin: 0;
        padding: 0.6566rem 1.5rem 0.6566rem 1rem;
        color: var(--white);
        background-color: var(--white-alpha10);
        border: 0;
        border-radius: 4px;
        font-size: 0.9rem;
        transition: background-color 0.3s ease-in-out;

        &:focus {
          background-color: var(--dark-color-dark);
        }
      }
      ::v-deep .searchbar-icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        color: var(--white);
        font-size: 1.1rem;
        transform: translateY(-50%);
      }
    }
    &__filter {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-top: 5px;
      font-size: 16px;
      line-height: 26px;

      .focus {
        background-color: var(--dark-color-dark) !important;
      }
      &__title {
        font-family: var(--title-font-family);
      }
    }
  }
  &__list {
    height: calc(100% - 161px);
  }
}
::v-deep .idt-dropdown {
  width: 100%;

  &--pane {
    width: 100%;
    padding: 0;
  }
}
.input-dropdown {
  position: relative;
  width: 100%;

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    font-size: 14px;

    &__container {
      position: relative;
      cursor: pointer;
    }
    &.div {
      min-height: 53px;
      color: var(--grey-light);
      background-image: none;
      background-color: var(--white-alpha10);
      border: none;
      font-family: var(--text-font-family);
      transition: background-color 0.3s ease-in-out;
      pointer-events: auto;

      ::v-deep .idea-arrow-nav-down {
        color: var(--white);
      }
      .tag-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .tag-item {
          display: inline-block;
          margin: 2px;
          padding: 0 5px;
          height: 29px;
          color: var(--white);
          background: var(--main-color);
          border: 1px solid var(--main-color);
          border-radius: 3px;
          font-size: 1rem;
          font-weight: 500;
          line-height: 28px;

          .remove-button {
            margin: 0 0 0 5px;
            padding: 0;
            background: 0 0;
            border: none;
            vertical-align: middle;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__button {
    margin: 0;
    color: var(--main-color);
    background-color: var(--white);
    border-color: var(--main-color);
    text-transform: uppercase;

    &.dropdown:after {
      border-color: var(--main-color) transparent transparent;
    }
    &:hover {
      color: var(--main-color);
      background-color: var(--white);
    }
  }
  .idt-dropdown--pane {
    width: 236px;
    max-height: 50vh;
    margin: -2.75rem 0 0 -0.25rem;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 5px var(--black-alpha20);
    overflow-y: auto;

    &__header {
      position: relative;
      min-height: 40px;
      color: var(--main-color);
      background-color: var(--main-color-alpha10);
      font-size: 0.8rem;
      font-weight: 500;
      text-transform: uppercase;

      &:not(.counter):not(.checkbox) {
        padding: 0.7rem 1rem;
      }
      &.counter {
        padding: 0.7rem 2.5rem 0.7rem 1rem;
      }
      .count {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 1rem;
        color: var(--main-color);
        transform: translateY(-50%);
      }
      &.checkbox {
        padding: 0.7rem 3rem 0.7rem 1rem;
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 1.5rem;
          width: 16px;
          height: 16px;
          border: 2px solid var(--white);
          border-radius: 100%;
          box-shadow: 0 0 0 2px var(--main-color);
          transform: translateY(-50%);
        }
        &.checked {
          &:before {
            background-color: var(--main-color);
          }
        }
      }
    }
    button {
      width: 100%;
      height: 40px;
      padding: 0.7rem 1rem;
      border-radius: 0;
      text-align: left;
      cursor: pointer;

      &.add {
        position: relative;
        color: var(--main-color);
        background-color: var(--main-color-alpha10);
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: uppercase;

        i {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
        }
      }
    }
    &__checkbox {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0.7rem 3rem 0.7rem 1rem;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        width: 16px;
        height: 16px;
        border: 2px solid var(--white);
        border-radius: 100%;
        box-shadow: 0 0 0 2px var(--main-color);
        transform: translateY(-50%);
      }
      input[type=radio]:checked + &:before,
      input[type=checkbox]:checked + &:before {
        background-color: var(--main-color);
      }
    }
    input[type=radio],
    input[type=checkbox] {
      display: none;
    }
  }
  &__title {
    background-color: var(--main-color-alpha20);
    color: var(--main-color);
  }
  .menu {
    max-width: 100%;
    font-size: 0.8rem;
    overflow-x: hidden;

    &:not(.multiple) {
      max-height: 200px;
      overflow-y: auto;
    }
    li {
      &:not(:last-child) {
        a,
        button {
          border-bottom: 1px solid var(--grey-lighter);
        }
      }
      a,
      button {
        color: var(--grey-darkest);

        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
}
input[type=checkbox].checked + .dropdown-pane__checkbox:before {
  background-color: var(--main-color);
}
.button-dropdown {
  display: inline-block;
}
