

























































.lobby-room {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  padding: 0 var(--rem-size-16);

  svg {
    width: var(--rem-size-12) !important;
  }

  &,
  &:hover {
    color: var(--grey-darker);
  }

  &__title {
    padding: var(--rem-size-9) 0;
    color: var(--grey-darkest);
    font-size: var(--rem-size-14);
    line-height: var(--rem-size-18);
    word-break: break-word;
  }

  &__icons {
    margin-left: auto;
  }

  &__icon {
    margin-right: var(--rem-size-12);
  }

  &__counter {
    display: inline-flex;
    justify-content: center;
    min-width: 20px;
    margin-left: auto;
    padding: 0px 4px;
    color: var(--white);
    background: var(--danger-color);
    border-radius: var(--rem-size-24);
    font-size: var(--rem-size-12);
  }

  &--selected {
    background: var(--grey-light);
    border: 1px solid var(--grey-darkest-alpha8);

    &::after {
      content: '';
      display: flex;
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: var(--rem-size-24);
      background-color: var(--main-color);
      transform: translateY(-50%) translateX(-1px);
    }
  }
  &--unread {
    font-weight: bold;
  }
}
