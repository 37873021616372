.replay-player {
  height: 100%;
}

.replay-player video {
  width: 100%;
  height: 100%;
  background-color: var(--black);
}

