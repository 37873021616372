.idt-live-chat-reactions {
  display: inline-flex;
  align-items: center;
  margin: 0 var(--rem-size-6);
  min-height: var(--rem-size-24);
  color: var(--grey-darker);
  background: var(--grey-lightest);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.idt-live-chat-reactions .emoji-mart-emoji {
  padding: var(--rem-size-4);
  cursor: pointer;
}

