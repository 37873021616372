.fo-project-mode-view {
  display: flex;
  flex-direction: column;
}

.fo-project-mode-view__content {
  width: 100%;
}

.fo-project-mode-view__content__sections {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 110px;
}

.fo-project-mode-view__content__sections:first-child {
  margin-top: 64px;
}

