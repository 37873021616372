.idt-survey-question-multiple .idt-checkbox {
  margin-bottom: var(--rem-size-8);
  padding: 3px .5rem;
  border: 2px solid rgba(0,0,0,0);
  box-shadow: none;
}

.idt-survey-question-multiple .idt-checkbox:hover:not(.idt-checkbox--checked) {
  border: 2px solid var(--grey-light);
}

.idt-survey-question-multiple .idt-checkbox--checked {
  border: 2px solid var(--main-color);
}

.idt-survey-question-multiple ::v-deep .idt-checkbox label {
  color: var(--black);
  font-weight: var(--font-weight-medium);
}

.idt-survey-question-multiple ::v-deep .idt-checkbox .box {
  margin-right: var(--rem-size-18);
  background: var(--grey);
}

