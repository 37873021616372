

























































































































































































































































































































































































































































@import '@/styles/mixins/index';

.bo-lobby-category {
  display: flex;
  flex-flow: column;
  margin-bottom: var(--rem-size-23);
  padding-bottom: var(--rem-size-12);
  background: var(--white);
  border: 1px solid rgba(203, 203, 209, 0);
  transition: all .2s ease-in-out;

  &--ghost {
    opacity: 0;
  }
  .bo-lobby-category__icon-collapse {
    display: flex;
    padding: var(--rem-size-4);
    border-radius: var(--global-radius);
    transition: transform .25s ease-in-out, background-color .15s ease-in-out;

    &:hover {
      background-color: var(--grey-light);
    }
  }
  &__textarea-autosize {
    position: relative;

    textarea {
      margin-bottom: 0;
      padding: var(--rem-size-6) var(--rem-size-12) var(--rem-size-24);
      font-size: var(--rem-size-14);
    }
    &__counter {
      position: absolute;
      right: var(--rem-size-12);
      bottom: var(--rem-size-6);
      color: var(--grey-dark);
    }
  }
  &__title-right-side {
    margin-left: auto;

    .idt-search {
      input {
        height: 36px;
      }
      &::after {
        color: var(--grey-darker);
        font-size: var(--rem-size-14);
      }
    }
  }
  &--collapsed {
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;

    input {
      cursor: auto;
    }
    .bo-vertical__section__title {
      margin-bottom: 0 !important;
    }
    .bo-lobby-category__icon-collapse {
      transform: rotate(180deg);
    }
  }
  // allow <draggable> animations when everything is collapsed
  &--draggable {
    transition: none;
    transition-delay: 0;
  }
  &--sponsor-cat.bo-lobby-category--collapsed {
    box-shadow: none;
  }
  .row.expanded {
    width: 100%;
  }
  h2.bo-vertical__section__title {
    display: flex;
    margin-bottom: var(--rem-size-8);
    color: var(--grey-darkest);
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-bold);
    transition: margin-bottom .2s ease-in-out;

    > * {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: var(--rem-size-12);
    }
    a {
      color: var(--grey-darkest-alpha75);
    }
  }
  .bo-table-actions {
    margin-bottom: 0;

    .idt-input-container {
      max-width: 300px;

      &:not(.no-margin) {
        padding-bottom: var(--rem-size-8);
      }
      // fix bad alignment of the counter
      .idt-input-counter {
        bottom: .7rem;
      }
    }
    // error handling
    .idt-required-field {
      bottom: calc(-1 * var(--rem-size-25));
      left: 0;
      padding: 0 var(--rem-size-16);
      background: var(--white-alpha80);
    }
  }
  .tooltip-info {
    position: relative;

    &__content {
      display: none;
      position: absolute;
      right: calc(-1 * var(--rem-size-16));
      top: 50%;
      padding: calc(var(--rem-size-8) / 2) var(--rem-size-8);
      background-color: var(--grey-dark);
      border-radius: var(--global-radius);
      color: var(--white);
      font-size: var(--rem-size-12);
      font-weight: var(--font-weight-normal);
      white-space: nowrap;
      transform: translateX(100%) translateY(-50%);
      z-index: 1;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-right-color: var(--grey-dark);
        transform: translateX(-100%) translateY(-50%);
        vertical-align: middle;
      }
    }
    a {
      display: inline-flex;
    }
    a:hover + .tooltip-info__content,
    a:focus + .tooltip-info__content {
      display: block;
    }
  }
  // drag&drop appear animation
  .width-animation-enter-active,
  .width-animation-leave-active {
    max-width: 35px;
    transition: max-width .2s ease-in-out, margin-right .2s ease-in-out;
    overflow: hidden;
  }
  .width-animation-enter,
  .width-animation-leave-to {
    max-width: 0;
    margin-right: 0;
  }
  // opacity animation
  .opacity-animation-enter-active,
  .opacity-animation-leave-active {
    transition: opacity .2s ease-in-out;
  }
  .opacity-animation-enter,
  .opacity-animation-leave-to {
    opacity: 0;
  }
  // collapse animation
  .height-animation-enter-active,
  .height-animation-leave-active {
    max-height: 1200px;
    overflow: hidden;
    transition: max-height .4s ease-out;
  }
  .height-animation-enter,
  .height-animation-leave-to {
    max-height: 0;
  }
  &__yes {
    color: var(--green-dark);
  }
  &__no {
    color: var(--danger-color);
  }
  .text-ellipsis {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
