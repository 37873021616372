.fo-lobby-categories {
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
}

.fo-lobby-categories>* {
  width: 100%;
}

.fo-lobby-categories .lobby-category {
  display: flex;
  flex-flow: row wrap;
}

.fo-lobby-categories .lobby-category__view-more {
  display: flex;
  align-items: center;
  min-height: var(--rem-size-36);
  padding: 0 var(--rem-size-16);
  color: var(--black);
  font-size: var(--rem-size-14);
}

.fo-lobby-categories .lobby-category__view-more svg {
  transition: transform .2s ease-in-out;
}

.fo-lobby-categories .lobby-category__view-more--viewing-more svg {
  transform: rotate(-180deg);
}

.fo-lobby-categories .lobby-category__wrapper {
  display: flex;
  flex-flow: column;
  padding-right: var(--rem-size-16);
  height: 100%;
  overflow-y: auto;
}

@media only screen and (max-width: 991px) {
  .fo-lobby-categories .lobby-category__wrapper {
    padding-left: var(--rem-size-16);
  }
}

.fo-lobby-categories .lobby-category>* {
  width: 100%;
}

.fo-lobby-categories .lobby-category__title {
  margin-bottom: var(--rem-size-16);
  padding: var(--rem-size-9) 0;
  border-bottom: 1px solid var(--grey-dark);
  color: var(--grey-darkest);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-18);
  color: var(--grey-darkest);
}

.fo-lobby-categories .lobby-category__sponsor {
  margin-bottom: var(--rem-size-8);
}

.fo-lobby-categories .lobby-category__regular-rooms .lobby-room {
  margin-bottom: var(--rem-size-4);
}

.fo-lobby-categories .lobby-category__regular-rooms>.lobby-room:last-child,
.fo-lobby-categories .lobby-category__sponsor-rooms>.lobby-room:last-child {
  border-radius: 0 0 5px 5px;
}

.fo-lobby-categories .height-animation-enter-active,
.fo-lobby-categories .height-animation-leave-active {
  transition: max-height .3s ease-out;
  overflow: hidden;
  max-height: 125px;
}

.fo-lobby-categories .height-animation-enter,
.fo-lobby-categories .height-animation-leave-to {
  max-height: 0;
}

.fo-lobby-categories .list-transition-items .list-transition-item {
  transform: translate3d(0, 0, 0);
  transition: all .33s;
}

.fo-lobby-categories .list-transition-leave-active,
.fo-lobby-categories .list-transition-enter-active {
  overflow: hidden !important;
}

.fo-lobby-categories .list-transition-enter {
  max-height: 0;
  margin-bottom: 0;
  opacity: 0;
}

.fo-lobby-categories .list-transition-enter-to,
.fo-lobby-categories .list-transition-leave {
  max-height: 7rem;
}

.fo-lobby-categories .list-transition-leave-to {
  max-height: 0;
  margin-bottom: 0;
  opacity: 0;
}

.fo-lobby-categories--remove-animations .height-animation-enter-active,
.fo-lobby-categories--remove-animations .height-animation-leave-active {
  transition: none;
}

.fo-lobby-categories--remove-animations .list-transition-items .list-transition-item {
  transition: none;
}

.fo-lobby-categories--remove-animations .lobby-sponsor-line__chevron {
  transition: none;
}

