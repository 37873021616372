.modal-answers__content {
  position: absolute;
  width: 100%;
  transition: all .3s ease;
  overflow-y: scroll;
}

@media only screen and (max-width: 639px) {
  .modal-answers__content {
    height: calc(100% - 70px);
  }
}

@media only screen and (min-width: 640px) {
  .modal-answers__content {
    height: calc(100% - 96px);
  }
}

.modal-answers__content.footer-open {
  height: calc(100% - 292px);
}

.modal-answers__content.footer-open.has-file {
  height: calc(100% - 398px);
}

.modal-answers__form {
  border-bottom: 6px solid var(--grey-light);
  background-color: var(--grey-lightest);
}

.modal-answers__form__counter {
  position: relative;
}

.modal-answers__form__counter__textarea {
  width: 100%;
  max-width: 100%;
  max-height: 250px;
  margin-bottom: 10px;
  padding: var(--rem-size-8) var(--rem-size-30) var(--rem-size-8) var(--rem-size-8);
  border-radius: var(--global-radius);
  border: 1px solid var(--grey-light);
  background-color: var(--white);
  transition: border .3s ease;
  resize: vertical;
}

.modal-answers__form__counter__textarea:focus {
  border: 1px solid var(--main-color);
  outline: 0;
}

.modal-answers__form__counter__number {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: var(--rem-size-3);
  border-radius: 0 var(--global-radius) 0 var(--global-radius);
  background-color: var(--main-color);
  font-size: 11px;
  color: var(--white);
}

.modal-answers__form__upload-preview__link {
  display: inline-block;
  position: relative;
}

.modal-answers__form__upload-preview__link__figure,
.modal-answers__form__upload-preview__link__file {
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: var(--global-radius);
}

.modal-answers__form__upload-preview__link__figure {
  background-repeat: no-repeat;
  background-position: center center;
}

.modal-answers__form__upload-preview__link__file {
  background-color: var(--grey-light);
  text-align: center;
}

.modal-answers__form__upload-preview__link__file__icon {
  position: relative;
  top: -18px;
  margin-top: 50%;
  font-size: 36px;
  color: var(--grey-lightest);
}

.modal-answers__form__upload-preview__link__button {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: var(--rem-size-4) var(--rem-size-8);
  border-radius: var(--global-radius);
  border: none;
  background-color: var(--danger-color);
  z-index: 5;
  transition: all .3s ease;
}

.modal-answers__form__upload-preview__link__button:hover {
  background-color: var(--main-color);
}

.modal-answers__form__upload-preview__link__button__icon {
  color: var(--white);
}

.modal-answers__form__upload-preview__link__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: var(--global-radius);
  background-color: var(--black-alpha40);
  text-align: center;
  opacity: 0;
  transition: all .3s ease;
  z-index: 4;
}

.modal-answers__form__upload-preview__link__overlay:hover {
  opacity: 1;
}

.modal-answers__form__upload-preview__link__overlay__icon {
  position: relative;
  top: -12px;
  margin-top: 50%;
  font-size: 24px;
  color: var(--white);
}

.modal-answers__form__button.button--submit {
  display: inline-block;
  width: 100%;
  max-width: 80px;
  border-radius: var(--global-radius);
  border: none;
  background-color: var(--main-color);
  vertical-align: middle;
  color: var(--white);
  transition: all .3s ease;
}

.modal-answers__list-answers,
.modal-answers__no-content {
  padding: 1.25rem 1.5rem;
}

.modal-answers__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--grey-lightest);
  border-top: 2px solid var(--grey);
}

.modal-answers__footer__content {
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease;
}

.modal-answers__footer__content.opened {
  max-height: 500px;
}

