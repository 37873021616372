



























@import '@/styles/functions';

.talk-links {
  display: flex;
  flex-direction: column;
  gap: remCalc(8);
  margin: remCalc(16) 0;
}
