@media only screen and (min-width: 992px) {
  .bo-page__content .dashboard .header .idt-select-container {
    height: 100%;
  }

  .bo-page__content .dashboard .header .idt-select-container .v-select {
    height: 100%;
  }

  .bo-page__content .dashboard .header .idt-select-container .v-select .vs__dropdown-toggle {
    height: 100%;
  }
}

.bo-page__content .dashboard .idt-select-container {
  padding-bottom: 0;
  font-size: var(--rem-size-14);
}

.bo-page__content .dashboard .idt-select-container .v-select {
  margin-bottom: 0;
}

.bo-page__content .dashboard .idt-select-container .v-select .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 30px);
}

.bo-page__content .dashboard .idt-select-container .v-select .vs__selected-options .vs__selected {
  display: block;
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.bo-page__content .dashboard .idt-select-container .v-select .vs__dropdown-toggle {
  padding: 0;
  background: var(--white);
  border: 1px solid var(--black);
}

.bo-page__content .dashboard .idt-select-container svg {
  fill: var(--black);
}

.bo-page__content .dashboard .idt-date-time-container .mx-input {
  border: 1px solid var(--black);
}

.bo-page__content .dashboard .idt-date-time-container i {
  color: var(--black);
}

