.idt-page.confirmemail .idt-profile {
  --confirm-email-margin: 70px;
}

.idt-profile {
  --global-top-margin: var(--confirm-email-margin, 0px);
  margin-top: calc(var(--global-top-margin) + 70px);
  background-color: var(--grey-lighter);
}

.idt-profile .idt-breadcrumb {
  margin-left: 23px;
}

.idt-profile__hint {
  margin-bottom: 5px;
  font-size: var(--rem-size-12);
  color: var(--black-alpha-50);
}

.idt-profile__container {
  display: flex;
  max-width: 1170px;
  margin: auto;
  padding-top: 40px;
  justify-content: space-between;
  padding-left: var(--rem-size-15);
  padding-right: var(--rem-size-15);
}

.idt-profile__container__main {
  flex-shrink: 1;
  max-width: 825px;
  width: 100%;
  padding-bottom: calc(100vh - 740px);
}

@media only screen and (max-width: 991px) {
  .idt-profile__container__main {
    max-width: initial;
  }
}

@media only screen and (max-width: 991px) {
  .idt-profile__container {
    padding: 40px 0;
  }
}

.idt-profile__container>.idt-side-menu {
  --sticky-margin: var(--global-top-margin);
}

@media only screen and (max-width: 991px) {
  .idt-profile__container>.idt-side-menu {
    display: none;
  }
}

.idt-profile__header-button {
  height: 35px;
  padding: 0px 45px;
  margin: 0;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  font-size: 1rem;
}

.idt-profile__input-section {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: var(--rem-size-16);
}

@media only screen and (max-width: 639px) {
  .idt-profile__input-section>.idt-input-container {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .idt-profile__input-section>.idt-input-container {
    max-width: calc(50% - var(--rem-size-8));
  }
}

.idt-profile__input-section__input {
  margin-bottom: 1.5rem;
}

.idt-profile__input-section__input,
.idt-profile__input-section .idt-input-container {
  flex-grow: 1;
  min-width: 250px;
}

@media only screen and (min-width: 992px) {
  .idt-profile__fieldset-action-btn {
    display: none !important;
  }
}

.idt-profile__footer {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 35px;
  padding-top: 0;
  background: rgba(0,0,0,0);
  z-index: 1;
}

@media only screen and (min-width: 992px) {
  .idt-profile__footer {
    display: none;
  }
}

.idt-profile__footer__save-btn {
  margin: 0;
}

.idt-profile__menu-mobile {
  position: fixed;
  top: calc(70px + var(--global-top-margin));
  height: calc(100vh - 70px);
  width: 100%;
  padding: 16px;
  background: var(--white);
  z-index: 1;
}

@media only screen and (min-width: 992px) {
  .idt-profile__menu-mobile {
    display: none;
  }
}

.idt-profile__menu-mobile__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 35px;
  border-top: 2px solid var(--grey-light);
}

.idt-profile__menu-mobile__header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.idt-profile__menu-mobile__header__title {
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-left: 10px;
  text-transform: uppercase;
  color: var(--black);
}

.idt-profile .idt-fo-subheader {
  --sticky-margin: var(--global-top-margin);
}

@media only screen and (max-width: 991px) {
  .idt-profile .idt-fo-subheader {
    display: none;
  }
}

.idt-profile .user-tags {
  margin: 0 -30px;
  padding: 30px;
  background: var(--grey-lightest);
}

.idt-profile .user-tags__item img {
  width: 2rem;
  height: 2rem;
  margin-right: 10px;
}

.idt-profile .idt-user-profile-container {
  position: fixed;
  right: 0;
  height: 100%;
  top: 0px;
  z-index: 2000;
}

.fade-mm-enter-active,
.fade-mm-leave-active {
  transition: all .3s ease;
}

.fade-mm-enter,
.fade-mm-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

