.modal-file-preview {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: calc(var(--vh, 1vh)*100);
  padding: 2rem 1.5rem;
  text-align: center;
  overflow: hidden;
}

.modal-file-preview__dialog {
  overflow-y: auto;
}

.modal-file-preview__dialog.dialog--iframe {
  height: 100%;
}

.modal-file-preview__dialog__content {
  position: relative;
}

.modal-file-preview__dialog__content.content--image {
  display: inline-block;
}

.modal-file-preview__dialog__content.content--iframe {
  height: 100%;
}

.modal-file-preview__dialog__content__close {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 30px;
  color: var(--white);
  cursor: pointer;
}

.modal-file-preview__dialog__content__iframe {
  width: 100%;
  height: 100%;
}

.modal-file-preview__close {
  margin-bottom: 2rem;
  color: var(--white);
  font-size: 1.2rem;
  cursor: pointer;
}

