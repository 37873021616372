.fo-project-section-issue-and-solution-edit {
  display: flex;
  flex-direction: row;
  margin: 2rem 3rem;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-issue-and-solution-edit {
    flex-direction: column;
    margin: 1rem 1.5rem;
  }
}

.fo-project-section-issue-and-solution-edit__part {
  flex: 1;
  max-width: 50%;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-issue-and-solution-edit__part {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}

.fo-project-section-issue-and-solution-edit__part:first-of-type {
  margin-right: var(--rem-size-24);
}

.fo-project-section-issue-and-solution-edit__part__title {
  margin-bottom: 1rem;
  font-size: var(--rem-size-24);
  font-weight: bold;
}

.fo-project-section-issue-and-solution-edit__subpart {
  margin-bottom: var(--rem-size-32);
}

.fo-project-section-issue-and-solution-edit__subpart--required .froala-container {
  border: 1px solid var(--red);
  border-radius: 10px;
}

.fo-project-section-issue-and-solution-edit .idt-required-field {
  margin-top: var(--rem-size-8);
}

