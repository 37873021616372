.live-chat-message-username {
  --admin-color: var(--purple);
  --mentor-color: var(--orange);
  --moderator-color: var(--green-dark);
  --winner-color: var(--yellow);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--rem-size-8);
  font-weight: bold;
}

.live-chat-message-username__respond-icon {
  color: var(--grey-darker);
}

.live-chat-message-username__text.admin {
  color: var(--admin-color);
}

.live-chat-message-username__text.moderator {
  color: var(--moderator-color);
}

.live-chat-message-username__text.winner {
  color: var(--winner-color);
}

.live-chat-message-username__text.mentor {
  color: var(--mentor-color);
}

.live-chat-message-username__container {
  display: flex;
  align-items: center;
  gap: var(--rem-size-4);
  line-height: 16px;
}

.live-chat-message-username__container__role-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: var(--white);
  border-radius: 2px;
  text-align: center;
}

.live-chat-message-username__container__role-icon.admin {
  background-color: var(--admin-color);
}

.live-chat-message-username__container__role-icon.mentor {
  background: var(--mentor-color);
}

.live-chat-message-username__container__role-icon.moderator {
  background: var(--moderator-color);
  font-size: 10px;
}

.live-chat-message-username__container__role-icon.winner {
  background: var(--winner-color);
  font-size: 10px;
}

.live-chat-message-username .role-tag {
  padding: 0 12px;
  background-color: var(--main-color-dark-alpha20);
  color: var(--main-color-dark);
  border-radius: var(--global-radius);
}

.live-chat-message-username .clickable {
  cursor: pointer;
}

