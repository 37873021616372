.idt-live-tag {
  height: 24px;
  padding: 4px 1rem;
  color: var(--white);
  background-color: var(--red);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  text-transform: uppercase;
}

