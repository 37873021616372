.event-prizes {
  position: relative;
  overflow: hidden;
}

.event-prizes__list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .event-prizes__list {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
  }

  .event-prizes__list .idt-event-prize {
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 479px) {
  .event-prizes__list:after {
    content: "";
    min-width: .4rem;
  }
}

.event-prizes__list .idt-event-prize {
  margin: .4rem;
  max-width: 1170px;
}

