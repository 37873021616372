































































































































































































































































































.agenda-categories {
  &__content {
    &__delete {
      color: var(--red);

      &:hover {
        margin-top: 1px;
        cursor: pointer;
        filter: brightness(75%);
      }

      &__icon {
        color: var(--red);
      }
    }
    &__cat-button {
        .button {
          height: 48px;
          width: 122px;
          font-size: var(--rem-size-16);
          font-weight: var(--font-weight-medium);
          margin: 0 0 1.75rem 0;
        }
      }
  }
}
