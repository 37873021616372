


































































.idt-survey-result-like {
  &__table {
    margin-top: 1rem;

    &__row {
      display: flex;
      align-items: stretch;
      margin-bottom: 1rem;
      color: var(--black);

      &__column {
        display: flex;
        flex-direction: column;

        &.progress-column {
          flex-grow: 1;
          justify-content: space-around;
        }

        &.count-column {
          align-items: flex-end;
        }

        &__label {
          cursor: pointer;
        }
      }

      .progress {
        height: 7px;
        margin: 0 var(--rem-size-10);
        border-radius: var(--global-radius);

        &.like {
         --progress-color: var(--main-color);
        }

        &.dislike {
         --progress-color: var(--secondary-color);
        }

        & ::v-deep .progress-meter {
          border-radius: var(--global-radius);
        }
      }
    }
  }
}
