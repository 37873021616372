.idt-ticketing-blocking-modal-time {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
}

@media only screen and (min-width: 640px) {
  .idt-ticketing-blocking-modal-time {
    font-size: 1.5rem;
  }
}

.idt-ticketing-blocking-modal-time .ideacon {
  margin-right: 5px;
}

@media only screen and (min-width: 640px) {
  .idt-ticketing-blocking-modal-time .ideacon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }
}

.idt-ticketing-blocking-modal-time .ideacon.idea-ht-scheduled {
  color: var(--main-color);
}

.idt-ticketing-blocking-modal-time .ideacon.idea-ht-starting-colored {
  color: var(--yellow);
}

.idt-ticketing-blocking-modal-time .ideacon.idea-ht-live-plain {
  color: var(--red);
}

.idt-ticketing-blocking-modal-time .ideacon.idea-ht-warning {
  color: #f9662e;
}

.idt-ticketing-blocking-modal-time .ideacon.idea-ht-play-disc {
  color: var(--white);
}

