.skeleton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.skeleton__label {
  width: 95px;
  height: var(--rem-size-24);
}

.skeleton__button {
  width: 204px;
  height: 37px;
}

.bone {
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }

  25% {
    background-color: var(--grey);
  }

  50% {
    background-color: var(--grey-light);
  }

  75% {
    background-color: var(--grey-lighter);
  }

  100% {
    background-color: var(--grey-light);
  }
}

