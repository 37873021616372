












































































































































































































































































.bo-project-modal-settings-information {
  &__image-upload {
    display: flex;
    align-items: center;

    &__uploader.fs__image-upload {
      height: 170px;
      width: 300px;
    }
  }
  &__logo-upload.fs__image-upload {
    height: 180px;
    width: 180px;
  }
}
