

















































































@import '@/styles/mixins/index';

.idt-ticket-flip-container {
  position: relative;
  max-width: 300px;
  perspective: 1000;

  @include respond-to(max-medium) {
    display: block !important;
    margin: auto;
  }
  @include respond-to(slarge-xlarge) {
    padding: 1rem 0 2rem;
  }
  @include respond-to(xlarge) {
    padding: 3rem 0;
  }
}
.idt-ticket-flip-card {
  position: relative;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease-in-out;

  @include respond-to(medium) {
    transform-style: preserve-3d;
  }

  &.flipped {
    @include respond-to(medium) {
      transform: rotateY(180deg);
    }
  }
  &__side,
  &__side-back {
    width: 100%;
    height: 100%;
    overflow: hidden;
    backface-visibility: hidden;

    @include respond-to(max-xlarge) {
      padding-top: 20px;
    }
    @include respond-to(xlarge) {
      padding-top: 28px;
    }
  }
  &__side {
    transition: all 0.3s ease-in-out;
  }
  &.flipped &__side {
    visibility: hidden;
  }
  &__side-back {
    @include respond-to(max-medium) {
      display: none;
    }
    @include respond-to(medium) {
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--white);
      transform: rotateY(180deg);
    }
  }
}
