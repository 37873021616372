.menu-mobile-shortcut-list-item {
  flex: 1 0 auto;
  max-width: 50%;
  padding: var(--rem-size-8) 0;
}

.menu-mobile-shortcut-list-item.vertical-separator {
  border-right: 1px solid var(--grey);
}

.menu-mobile-shortcut-list-item a,
.menu-mobile-shortcut-list-item button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 var(--rem-size-16);
  color: var(--grey-darkest);
  font-family: var(--text-font-family);
  line-height: 1;
}

.menu-mobile-shortcut-list-item__icon {
  position: relative;
}

.menu-mobile-shortcut-list-item__label {
  margin-top: var(--rem-size-8);
  font-size: var(--rem-size-14);
}

.menu-mobile-shortcut-list-item .idt-notif-pill {
  top: -15%;
  right: -10%;
}

