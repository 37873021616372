.insight-editor required-field {
  color: #f14040;
  font-size: 9pt;
}

.insight-editor .insight-card__banner {
  position: relative;
  text-align: center;
}

.insight-editor .upload-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 236px;
  height: 110px;
  margin: 0;
  padding: 1rem;
  background-color: var(--black-alpha60);
  transform: translate(-50%, -50%);
}

.insight-editor .upload-container__title {
  margin-bottom: .5rem;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
}

.insight-editor .upload-container .upload-insight {
  display: block;
}

.insight-editor .upload-container .upload-file__btn {
  width: 100%;
  padding: .85em 1em;
}

.insight-editor__switch {
  margin-right: .5rem;
}

.insight-editor__title {
  margin: 0;
}

.insight-editor__tags-selector {
  align-items: flex-end;
}

.insight-editor__tags-selector ::v-deep .idt-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.insight-editor__tags-selector .idt-input-container {
  flex: 1;
}

.insight-editor__tags-selector button {
  flex: 0 0 auto;
  height: 48.6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.insight-editor__tags {
  margin-bottom: var(--rem-size-16);
}

.insight-editor__tags ::v-deep .idt-tag {
  display: inline-block;
  margin-left: var(--rem-size-5);
  cursor: pointer;
}

.insight-editor__tags ::v-deep .idt-tag.selected {
  color: var(--main-color-lighter);
  background-color: var(--main-color);
}

