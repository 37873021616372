.idt-history-card {
  position: relative;
  min-height: 115px;
  margin-bottom: 0.5rem;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba($black, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;

  &--hiring {
    border-bottom: 2px solid var(--main-color);
  }
  &:hover {
    box-shadow: 0 2px 4px 0 rgba($black, 0.2);
  }
  &__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    min-height: 115px;
    padding: 25px 15px;
  }
  &__avatar {
    flex: 0 0 auto;
    position: relative;

    &__project {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      &--no-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: var(--grey-darkest-alpha20);
        font-size: 2.25rem;
        text-transform: uppercase;
      }
    }
  }
  &__unread {
    @include notificationPoint($white, var(--secondary-color), "absolute", "left", -4px, -10px);
  }
  &__content {
    flex: 1 1 auto;
    padding: 0 0.75rem;
  }
  &__title {
    margin-bottom: 0.25rem;
    color: var(--grey-darkest);
    line-height: 1;

    .user-tag {
      color: var(--grey-dark);
      text-transform: uppercase;
      &--mentor {
        color: var(--main-color);
      }
    }
  }
  &__title &__sub-title {
    display: block;
    margin-top: 0.25rem;
    color: var(--grey-darker);
    font-size: 0.9rem;
    font-weight: var(--font-weight-bold);
  }
  &__last-msg {
    color: var(--grey-dark);
    font-size: 0.75rem;
    word-break: break-all;
    hyphens: auto;

    &.unread {
      color: $black;
      font-weight: var(--font-weight-medium);
    }
  }
  &__icon {
    color: var(--main-color);
  }
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 64px;
    height: 18px;
    padding: 2px 5px;
    color: $white;
    background-color: var(--grey);
    font-size: 0.7rem;
    text-transform: uppercase;
    line-height: 1;

    &--conf {
      background-color: var(--warning-color);
    }
    &--accepted {
      color: var(--success-color);
      background-color: var(--success-color-alpha20);
    }
    &--declined {
      color: var(--danger-color);
      background-color: var(--danger-color-alpha20);
    }
  }
  &__status-project-logo {
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  &__project-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
}
