











































































































































































@import '@/styles/mixins/index';

.idt-profile-system-checking {
  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--rem-size-24);

    &--inline {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      & > div:first-of-type {
        flex-grow: 1;
      }

      @include respond-to(max-slarge) {
        & > div {
          margin-bottom: var(--rem-size-12);
          flex-grow: 1;
        }
      }
    }

    &__title {
      margin-bottom: var(--rem-size-8);
      color: var(--black);
      font-weight: var(--font-weight-bold);
    }
  }

  &__version-info, &__bandwidth-info {
    display: flex;
    flex-grow: 1;
    padding: var(--rem-size-24);
    background-color: var(--main-color-lighter);
    border-radius: var(--global-radius);

    .idt-icon {
      color: var(--main-color);;
    }

    &--limited {
      background-color: #FFF3DD;

      .idt-icon {
        color: #F1A627;
      }
    }
    &__content {
      margin-left: var(--rem-size-16);
    }
  }
  &__bandwidth-info {
    margin-top: var(--rem-size-16);
    background-color: var(--grey-light);
    color: var(--black);
    font-weight: var(--font-weight-bold);

    .idt-icon {
      color: var(--grey);
    }
  }
  &__gauge {
    img {
      width: 4rem;
    }
  }

  &__bandwidth-test {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    margin-right: var(--rem-size-16);
    padding: 9px var(--rem-size-24);
    background-color: #D6F4F5;
    font-weight: bold;
    color: #2DAEB2;
    font-size: var(--rem-size-14);
    border-radius: 50px;
    cursor: pointer;

    &--loading {
      background-color: var(--grey-light);
      color: var(--grey-darker);
      cursor: not-allowed;

      .idt-round-loader {
        height: var(--rem-size-20);
        width: var(--rem-size-20);
        margin: 0 0 0 var(--rem-size-13);
      }
    }
  }

  .browser-support-card {
    flex-wrap: wrap;
    height: auto;
    margin-top: var(--rem-size-16);

    &__browser {
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 100%;
      margin-bottom: var(--rem-size-12);
    }

    @include respond-to(max-slarge) {
      & {
        padding: var(--rem-size-16);
      }
      &__browser {
        flex-wrap: wrap;
      }
      &__icon {
        margin-bottom: var(--rem-size-12);
      }
      &__link {
        flex-grow: 1;
        margin-bottom: var(--rem-size-12);
      }
    }

    &--limited {
      padding: 0;
    }
  }
}
