










































































































@import '@/styles/mixins/index';

.gamification-how-to {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
  color: var(--black);
  > .idt-fo-card {
    margin: 0 15px;
    flex-grow: 1;
    flex-basis: 0;
    align-self: stretch;
  }
  @include respond-to(max-slarge) {
    flex-direction: column;
    margin: -15px 0;
    > .idt-fo-card {
      margin: 6px 0;
      flex-basis: auto;
    }
  }
}
