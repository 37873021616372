.bo-section__title+.bo-lobby-table {
  margin-top: calc(-1*var(--rem-size-30));
}

.bo-lobby-table {
  width: 100%;
  overflow: initial !important;
}

@media only screen and (max-width: 639px) {
  .bo-lobby-table {
    overflow: auto !important;
  }
}

.bo-lobby-table__add-row .idt-icon {
  color: var(--main-color);
}

.bo-lobby-table__add-row>:first-child,
.bo-lobby-table__add-row>:nth-child(2) {
  cursor: pointer;
}

.bo-lobby-table__add-row>:nth-child(2) {
  transform: translateX(-41px);
}

.bo-lobby-table__add-row .idt-icon {
  display: inline-flex;
  transform: translateY(1px) translateX(3px);
}

.bo-lobby-table__add-row svg {
  transform: rotate(-45deg);
}

.bo-lobby-table__row--header {
  height: 50px;
  box-shadow: none;
}

.bo-lobby-table__row--header .bo-lobby-table__cell {
  color: var(--grey-dark);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
}

.bo-lobby-table .bo-lobby-table__cell .idt-select-container:not(.idt-select-container--multiple) .v-select .vs__selected .column {
  max-width: 141px;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bo-lobby-table .bo-lobby-table__cell input[type=number] {
  appearance: none;
}

.bo-lobby-table .bo-lobby-table__cell input[type=number]::-webkit-outer-spin-button,
.bo-lobby-table .bo-lobby-table__cell input[type=number]::-webkit-inner-spin-button {
  appearance: none;
}

.bo-lobby-table .bo-lobby-table__cell--editing {
  max-width: none !important;
  overflow: initial !important;
}

.bo-lobby-table .bo-lobby-table__cell--placeholder {
  color: var(--grey-dark);
  white-space: nowrap;
  overflow: visible !important;
}

.bo-lobby-table__content {
  position: relative;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
  box-shadow: 0 2px 3px var(--grey-darker-alpha15);
}

.bo-lobby-table__content,
.bo-lobby-table__content__lines {
  width: 100%;
}

.bo-lobby-table__content__lines .bo-lobby-table__row {
  border-bottom: 1px solid var(--grey-light);
  left: 0;
}

.bo-lobby-table .bo-lobby-table__row {
  background: var(--white) !important;
}

.bo-lobby-table .bo-lobby-table__row--ghost {
  opacity: 0;
}

.bo-lobby-table .bo-lobby-table__cell {
  padding-top: var(--rem-size-16);
  padding-bottom: var(--rem-size-16);
  vertical-align: top;
  overflow: hidden;
}

.bo-lobby-table .bo-lobby-table__cell__content {
  display: flex;
  flex: auto;
  white-space: normal;
  word-break: break-word;
}

.bo-lobby-table a {
  color: var(--grey-dark);
}

.bo-lobby-table .bo-lobby-table__actions {
  display: flex;
  align-items: flex-start;
  max-width: none !important;
  white-space: nowrap;
  overflow: initial !important;
  transition: padding-top .1s ease-in-out;
}

.bo-lobby-table .bo-lobby-table__actions a+a {
  margin-left: var(--rem-size-8);
}

.bo-lobby-table .bo-lobby-table__actions.bo-lobby-table__cell--editing {
  padding-top: var(--rem-size-22);
}

.bo-lobby-table .idea-menu {
  cursor: move;
}

.bo-lobby-table .idt-input-container input {
  font-size: var(--rem-size-14);
  padding: var(--rem-size-6) var(--rem-size-16);
}

.bo-lobby-table .idt-input-container .idt-input-counter {
  top: .5rem;
}

.bo-lobby-table .idt-required-field {
  position: relative;
}

.bo-lobby-table .idt-select-container {
  flex: auto;
  padding-bottom: 0;
}

.bo-lobby-table .vs__dropdown-option--disabled {
  display: none !important;
}

.bo-lobby-table .vs__dropdown-toggle {
  height: 34px;
  padding-bottom: 0;
}

.bo-lobby-table .vs__dropdown-toggle input {
  margin-top: 0;
}

.bo-lobby-table .vs__selected {
  padding-left: 7px !important;
}

.bo-lobby-table .vs__selected-options {
  flex-flow: nowrap;
  white-space: nowrap;
}

.bo-lobby-table .idt-select-container--multiple div[role=combobox] {
  height: auto;
  min-height: 34px;
  padding: 2px 0;
}

.bo-lobby-table .idt-select-container--multiple div[role=combobox] .vs__selected-options {
  flex-flow: wrap;
}

.bo-lobby-table .v-select {
  min-height: 35px;
  margin-bottom: 0 !important;
  padding: 0;
}

.bo-lobby-table .bo-lobby-table__actions__handle {
  transition: opacity .5s ease-in-out;
}

.bo-lobby-table--disable-move .bo-lobby-table__actions__handle {
  opacity: .2;
  cursor: not-allowed;
}

.bo-lobby-table--disable-move .bo-lobby-table__actions__handle * {
  pointer-events: none;
}

.bo-lobby-table__table,
.bo-lobby-table__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  width: 100%;
  min-width: 1100px;
}

.bo-lobby-table__row {
  display: grid;
  width: 100%;
  font-size: var(--rem-size-14);
}

.bo-lobby-table__cell {
  padding: var(--rem-size-16);
}

.bo-lobby-table .list-transition-items .list-transition-item {
  transition: all .35s;
}

.bo-lobby-table .list-transition-items .list-transition-item .bo-lobby-table__cell {
  transition: all .35s;
}

.bo-lobby-table .list-transition-leave-active,
.bo-lobby-table .list-transition-enter-active {
  overflow: hidden !important;
}

.bo-lobby-table .list-transition-leave-active .bo-lobby-table__cell,
.bo-lobby-table .list-transition-enter-active .bo-lobby-table__cell {
  overflow: hidden !important;
}

.bo-lobby-table .list-transition-enter {
  max-height: 0;
  opacity: 0;
}

.bo-lobby-table .list-transition-enter .bo-lobby-table__cell {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.bo-lobby-table .list-transition-enter-to,
.bo-lobby-table .list-transition-leave {
  max-height: 125px;
  opacity: 1;
}

.bo-lobby-table .list-transition-enter-to .bo-lobby-table__cell,
.bo-lobby-table .list-transition-leave .bo-lobby-table__cell {
  max-height: 125px;
  padding-top: var(--rem-size-16);
  padding-bottom: var(--rem-size-16);
}

.bo-lobby-table .list-transition-leave-to {
  max-height: 0;
  border-bottom-color: rgba(0,0,0,0) !important;
  opacity: 0;
}

.bo-lobby-table .list-transition-leave-to .bo-lobby-table__cell {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.bo-lobby-category--sponsor-cat .bo-lobby-table__add-row>:nth-child(2) {
  transform: translateX(-18px);
}

