





















































































.idt-user-list-item {
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: var(--rem-size-9);
  border-bottom: 1px solid var(--grey-lighter);
  cursor: pointer;

  &--highlight {
    background-color: var(--grey-lighter);
    border-bottom: 1px solid var(--grey);
  }
  &:hover {
    background-color: var(--grey-lightest);
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__description {
      font-size: var(--rem-size-14);
    }
  }
  .idt-user-avatar {
    margin-right: var(--rem-size-10);
  }
}
