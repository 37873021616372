.color {
  &-primary {
    color: var(--main-color);
  }
  &-secondary {
    color: var(--secondary-color);
  }
  &-danger {
    color: var(--danger-color);
  }
  &-info {
    color: var(--info-color);
  }
  &-success {
    color: var(--success-color);
  }
  &-warning {
    color: var(--warning-color);
  }
}
.hide {
  display: none !important;
}
.hide-from-slarge {
  @include respond-to(slarge) {
    display: none !important;
  }
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.no-margin {
  margin: 0;
}
