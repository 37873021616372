.fo-project-mode-view-skeleton {
  width: 100%;
  margin-bottom: 3.3125rem;
  margin-top: 64px;
  padding-left: var(--rem-size-32);
  padding-right: var(--rem-size-32);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.fo-project-mode-view-skeleton__image {
  height: 372px;
  border: var(--rem-size-6) solid var(--grey-light);
  border-radius: var(--rem-size-12);
}

.fo-project-mode-view-skeleton__content,
.fo-project-mode-view-skeleton__details,
.fo-project-mode-view-skeleton__props {
  display: flex;
}

.fo-project-mode-view-skeleton__content {
  flex-flow: nowrap;
  margin-top: var(--rem-size-32);
}

@media (max-width: 768px) {
  .fo-project-mode-view-skeleton__content {
    flex-flow: column;
  }
}

.fo-project-mode-view-skeleton__project-info {
  flex: auto;
  overflow: hidden;
}

.fo-project-mode-view-skeleton__props {
  flex: auto;
}

.fo-project-mode-view-skeleton__props>div {
  width: 50%;
  max-width: 300px;
  flex-shrink: 1;
}

@media (max-width: 400px) {
  .fo-project-mode-view-skeleton__props>div {
    display: block;
    width: 100%;
    max-width: none;
    flex-shrink: 0;
  }

  .fo-project-mode-view-skeleton__props>div>div {
    width: 100%;
  }
}

.fo-project-mode-view-skeleton__right-props {
  width: 235px;
  flex-shrink: 0;
  margin-left: auto;
}

.fo-project-mode-view-skeleton__right-props>div {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .fo-project-mode-view-skeleton__right-props {
    margin-top: var(--rem-size-24);
    margin-left: 0;
  }

  .fo-project-mode-view-skeleton__right-props>div {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .fo-project-mode-view-skeleton__details {
    flex-flow: column;
  }
}

.fo-project-mode-view-skeleton__circle-s,
.fo-project-mode-view-skeleton__circle-m {
  width: 125px;
  height: 125px;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: var(--grey-light);
  margin-right: var(--rem-size-4);
  margin-bottom: var(--rem-size-4);
}

.fo-project-mode-view-skeleton__circle-s {
  width: 45px;
  height: 45px;
}

.fo-project-mode-view-skeleton__button,
.fo-project-mode-view-skeleton__line-xs,
.fo-project-mode-view-skeleton__line-s,
.fo-project-mode-view-skeleton__line-m,
.fo-project-mode-view-skeleton__line-l {
  width: 100%;
  max-width: 244px;
  height: 24px;
  border-radius: var(--global-radius);
  background-color: var(--grey-light);
  margin-right: var(--rem-size-4);
  margin-bottom: var(--rem-size-4);
}

.fo-project-mode-view-skeleton__button {
  width: 185px;
  height: 45px;
  border-radius: 30px;
}

.fo-project-mode-view-skeleton__line-xs {
  max-width: 63px;
}

.fo-project-mode-view-skeleton__line-s {
  max-width: 135px;
}

.fo-project-mode-view-skeleton__line-l {
  max-width: 634px;
}

.fo-project-mode-view-skeleton .fo-project-mode-view-skeleton__line-l,
.fo-project-mode-view-skeleton .fo-project-mode-view-skeleton__button {
  margin-bottom: var(--rem-size-24);
}

.fo-project-mode-view-skeleton .fo-project-mode-view-skeleton__line-s {
  margin-bottom: var(--rem-size-16);
}

.fo-project-mode-view-skeleton .fo-project-mode-view-skeleton__circle-m {
  margin-right: var(--rem-size-24);
  margin-bottom: var(--rem-size-24);
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

