.notification-webhook__list {
  margin-bottom: 1rem;
  box-shadow: 0 2px 3px var(--grey-darker-alpha15);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.notification-webhook__head,
.notification-webhook__item {
  display: grid;
  gap: 1rem;
  padding: 1rem;
}

@media only screen and (max-width: 991px) {
  .notification-webhook__head,
  .notification-webhook__item {
    grid-template-row: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 992px) {
  .notification-webhook__head,
  .notification-webhook__item {
    grid-template-columns: 40% 40% calc(20% - 2rem);
  }
}

@media only screen and (max-width: 991px) {
  .notification-webhook__head {
    display: none;
  }
}

.notification-webhook__item:not(:last-child) {
  border-bottom: 1px solid var(--grey-light);
}

.notification-webhook__actions {
  text-align: right;
}

.api-notification__item {
  display: flex;
}

.api-notification__item:not(:last-child) {
  border-bottom: 1px solid var(--main-color);
}

.api-notification__display {
  width: 100%;
  padding-bottom: 1.5rem;
}

.api-notification__display__title {
  display: flex;
  align-items: center;
}

.api-notification__display__api-name {
  flex: 1 1 auto;
}

.api-notification__display__actions {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.api-notification__form {
  flex: 1 1 auto;
}

.api-notification__delete {
  flex: 0 0 auto;
  margin-left: var(--rem-size-16);
}

.api-notification__edit-button,
.api-notification__delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  font-size: var(--rem-size-14);
  cursor: pointer;
}

.api-notification__edit-button span,
.api-notification__delete-button span {
  display: block;
  margin-right: .5rem;
}

.api-notification__delete-button {
  color: var(--secondary-color);
}

.api-notification__delete-button:hover {
  color: var(--secondary-color-dark);
}

