







































































































@import '@/styles/mixins/index';

.idt-ticket-card {
  position: relative;

  @include respond-to(medium) {
    min-height: 500px;
  }

  &__ticket {
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 3px solid var(--grey-lighter);
    border-left: 3px solid var(--grey-lighter);
    border-right: 3px solid var(--grey-lighter);
    border-bottom: none;
    border-top-right-radius: var(--global-radius);
    border-top-left-radius: var(--global-radius);

    @include respond-to(max-xsmall) {
      @include respond-to(portrait) {
        height: calc((var(--vh, 1vh) * 100) - (var(--tk-modal-header-height) + var(--outer-elements-height-small)));
      }
      @include respond-to(landscape) {
        height: auto;
      }
    }
    @include respond-to(xsmall-medium) {
      @include respond-to(portrait) {
        height: calc((var(--vh, 1vh) * 100) - (var(--tk-modal-header-height) + var(--outer-elements-height-large)));
      }
      @include respond-to(landscape) {
        height: auto;
      }
    }

    &__no-price {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 auto;
      padding: 1rem;
    }
    &.no-button {
      border-bottom: 3px solid var(--grey-lighter);
      border-bottom-left-radius: var(--global-radius);
      border-bottom-right-radius: var(--global-radius);
    }
    &__content {
      flex: 1 1 auto;
      height: 100%;
      overflow-y: auto;

      @include shadowScroll();
      @include respond-to(xlarge) {
        border-top: 2px solid var(--grey-lighter);
      }
    }
  }
  &__button {
    width: 100%;
    height: 50px;
    color: var(--white);
    background-color: var(--main-color);
    border-radius: 0px 0px var(--global-radius) var(--global-radius);
    font-size: 1.125rem;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    &.invoice {
      background-color: var(--grey-darkest);
    }
  }
}
