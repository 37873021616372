.idt-survey-question-nps__labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 0 var(--rem-size-12);
  margin-top: var(--rem-size-10);
  background-color: var(--grey-lighter);
  border-radius: var(--global-radius);
}

.idt-survey-question-nps__labels__bad,
.idt-survey-question-nps__labels__good {
  font-size: 1rem;
  color: var(--black);
}

.idt-survey-question-nps__slider {
  padding: 0 .5rem;
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.idt-survey-question-nps__slider .vue-slider {
  margin: 0 .5rem;
  margin-top: 38px;
  margin-bottom: .5rem;
}

.idt-survey-question-nps__slider ::v-deep .vue-slider.danger .vue-slider-process {
  background-color: var(--secondary-color);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider.danger .vue-slider-mark-label-active::after {
  background-color: var(--secondary-color);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider.medium .vue-slider-process {
  background-color: var(--yellow);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider.medium .vue-slider-mark-label-active::after {
  background-color: var(--yellow);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider.good .vue-slider-mark-label-active::after {
  background-color: var(--main-color);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider-process {
  border-radius: 4px;
}

.idt-survey-question-nps__slider ::v-deep .vue-slider-dot {
  border-color: var(--grey);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider-mark-label {
  top: initial;
  bottom: 100%;
  margin: 0;
  padding-bottom: 12px;
  font-size: var(--rem-size-12);
  color: var(--black);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider-mark-label-active {
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
}

.idt-survey-question-nps__slider ::v-deep .vue-slider-mark-label:after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 1px;
  height: 8px;
  margin-top: -2px;
  margin-left: -1px;
  background: var(--grey-light);
}

