.gamification-widget {
  height: 44px;
  border: 1px solid var(--grey-light);
  border-radius: 50px;
  padding: .5rem 1rem;
  text-align: center;
  vertical-align: middle;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
}

.gamification-widget__content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.gamification-widget__content img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  align-self: center;
}

.gamification-widget__content img.rotate {
  animation: spin 4s linear infinite;
}

.gamification-widget:hover {
  cursor: pointer;
}

@keyframes spin {
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

