.idt-input-password {
  position: relative;
}

.idt-input-password__toggle {
  position: absolute;
  display: flex;
  padding: 0 12px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  align-items: center;
}

.idt-input-password .password-rules {
  bottom: -56px;
}

