.payment-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: left;
}

.payment-error__container {
  display: flex;
}

@media only screen and (max-width: 639px) {
  .payment-error__container {
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 640px) {
  .payment-error__container {
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
  }
}

.payment-error__icon {
  color: var(--secondary-color);
}

.payment-error__icon.idt-icon ::v-deep .ideacon {
  width: 2.75rem;
  height: 2.75rem;
}

@media only screen and (max-width: 639px) {
  .payment-error__icon.idt-icon ::v-deep .ideacon {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 640px) {
  .payment-error__icon.idt-icon ::v-deep .ideacon {
    margin-right: 25px;
  }
}

.payment-error__message__title {
  color: var(--grey-darkest);
  font-size: 1.75rem;
  font-weight: var(--font-weight-medium);
  line-height: normal;
}

@media only screen and (max-width: 369px) {
  .payment-error__message__title {
    margin-bottom: 5px;
    font-size: 1.75rem;
  }
}

@media only screen and (min-width: 370px)and (max-width: 639px) {
  .payment-error__message__title {
    margin-bottom: 5px;
    font-size: 2rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .payment-error__message__title {
    margin-bottom: 15px;
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-error__message__title {
    margin-bottom: 20px;
    font-size: 2.5rem;
  }
}

.payment-error__message__text {
  color: var(--grey-darker);
  font-weight: var(--font-weight-light);
  line-height: 1.25;
}

@media only screen and (min-width: 370px)and (max-width: 639px) {
  .payment-error__message__text {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .payment-error__message__text {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-error__message__text {
    font-size: 1.5rem;
  }
}

.payment-error__retry {
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .payment-error__retry {
    width: 152px;
    height: 36px;
    margin-top: 20px;
    font-size: 1rem;
    line-height: .5;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .payment-error__retry {
    width: 200px;
    height: 46px;
    margin-top: 20px;
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-error__retry {
    width: 200px;
    height: 46px;
    margin-top: 30px;
    font-size: 1.125rem;
  }
}

