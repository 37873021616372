


































































































































































































































































































@import '@/styles/mixins/index';

.line-chart {
  &__line {
    fill: none;
    stroke: var(--main-color);
    stroke-width: 3px;
  }
  &__circle {
    fill: var(--main-color);
    stroke: var(--main-color);
  }
}
