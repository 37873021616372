.fo-project-mode-edit-new-project-message-modal__content {
  margin-top: var(--rem-size-16);
  margin-bottom: var(--rem-size-16);
  color: var(--grey-darker);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-normal);
  word-break: break-word;
}

.fo-project-mode-edit-new-project-message-modal .button {
  width: 50%;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
}

