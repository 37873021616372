









































.fo-project-section-free-view {
  display: flex;
  flex-direction: column;
  margin: 2rem 3rem;

  &__title {
    margin-bottom: var(--rem-size-16);
    text-align: center;
    color: var(--grey-darkest);
  }

  &__content {
    margin-bottom: var(--rem-size-32);
  }
}
