.event-topic-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  border: solid 3px var(--grey-light);
  border-radius: var(--global-radius);
  text-align: center;
}

.event-topic-card__title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
  font-size: 1.25rem;
}

.event-topic-card__description {
  display: block;
  position: relative;
  min-height: 125px;
  margin-right: 15px;
  margin-left: 15px;
  color: var(--grey-darker);
  font-size: 1rem;
  font-weight: var(--font-weight-normal);
  line-height: 1.44;
  letter-spacing: normal;
}

.event-topic-card__description p {
  word-break: break-word;
  margin-bottom: 0;
}

.event-topic-card__image {
  width: 200px;
  height: 100px;
  margin: -40px auto 0 auto;
  border-radius: var(--global-radius);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
  background-color: var(--white);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

.event-topic-card__insight {
  margin-top: 10px;
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
  color: var(--main-color);
}

.event-topic-card__insight svg {
  margin-top: 3px;
}

@media only screen and (max-width: 639px) {
  .event-challenge {
    flex-flow: column wrap;
  }
}

@media only screen and (min-width: 640px) {
  .event-challenge {
    align-items: center;
  }
}

@media only screen and (max-width: 639px) {
  .event-challenge:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-challenge:not(:last-child) {
    margin-bottom: 130px;
  }
}

.event-challenge__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  color: var(--main-color-darker);
  background-color: var(--main-color-lighter);
  border-radius: 100%;
  font-size: 3.5rem;
  font-weight: var(--font-weight-light);
}

@media only screen and (max-width: 639px) {
  .event-challenge__badge {
    position: relative;
  }

  .event-challenge__badge:after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--main-color-alpha30);
  }
}

@media only screen and (max-width: 639px) {
  .event-challenge__badge__container {
    padding-bottom: 1.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-challenge__badge__container {
    padding-right: 3rem;
    border-right: 1px solid var(--main-color-alpha30);
  }
}

@media only screen and (max-width: 639px) {
  .event-challenge__content {
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-challenge__content {
    padding-left: 3rem;
  }
}

.event-challenge__title {
  margin-bottom: 1.5rem;
  color: var(--grey-darkest);
  font-size: 1.4rem;
  font-weight: 300;
}

.event-challenge__desc {
  color: var(--grey-dark);
  font-size: 1.1rem;
  font-weight: 500;
  white-space: pre-wrap;
}

