.fo-project-bar-votes__content {
  padding: var(--rem-size-16);
}

.fo-project-bar-votes__content__no-votes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--rem-size-16);
}

.fo-project-bar-votes__content__no-votes__text {
  margin-top: var(--rem-size-8);
  font-size: var(--rem-size-12);
}

.fo-project-bar-votes__content__votes__vote {
  margin-bottom: var(--rem-size-8);
  padding: var(--rem-size-8);
  border-radius: 23px;
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-18);
}

.fo-project-bar-votes__content__votes__vote--color0 {
  border: 1px solid var(--project-vote-0);
  color: var(--project-vote-0);
}

.fo-project-bar-votes__content__votes__vote--color1 {
  border: 1px solid var(--project-vote-1);
  color: var(--project-vote-1);
}

.fo-project-bar-votes__content__votes__vote--color2 {
  border: 1px solid var(--project-vote-2);
  color: var(--project-vote-2);
}

