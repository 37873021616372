














































































































.idt-alerts__list {
  position: fixed;
  right: 0;
  width: 100%;
  max-width: 564px;
  flex-shrink: 0;
  z-index: 1000;

  &.has-container {
    position: relative;
    top: 0 !important;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 0 22px;
    background-color: transparent;
  }

  &:not(.top) {
    top: var(--menu-height);
  }
  &.top {
    top: 0;
  }
}

.idt-alert {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 520px;
  min-height: 100px;
  padding: 1rem 1.5rem;
  margin-top: 20px;
  background-color: white;
  border-left: 5px solid var(--alert-color);
  border-radius: var(--global-radius);
  box-shadow: 0px 3px 30px #00000029;
  cursor: pointer;
  transition: box-shadow .2s ease;

  &:hover {
    box-shadow: 0px 2px 5px #00000029;

    .idt-alert__close {
      display: inline-block;
    }
  }

  &__close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1.5rem;
    border: none;
    color: var(--grey-dark);
    cursor: pointer;
  }

  &__icon .idt-icon {
    color: var(--alert-color);
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 0.1rem 3rem 0 1rem;

    &__title {
      font-weight: var(--font-weight-medium);
      color: var(--alert-color);
    }
    &__text {
      margin: 0;
    }
    &__link, &__link:hover {
      margin-top: .8rem;
      color: var(--grey-darker);
      text-decoration: underline;

      &:hover {
        color: var(--grey-darkest);
      }
    }
  }
  &--success {
    --alert-color: var(--success-color);
  }
  &--danger {
    --alert-color: var(--danger-color);
  }
  &--info {
    --alert-color: var(--info-color);
  }
  &--warning {
    --alert-color: var(--warning-color);
  }
}

.alert-transition-enter-active, .alert-transition-leave-active {
  transition: all .3s ease;
}
.alert-transition-enter, .alert-transition-leave-to /* .alert-transition-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translate3d(calc(100% + 22px), 0, 0);
}
