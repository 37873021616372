.skeleton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 126px;
  padding: var(--rem-size-15) 0 var(--rem-size-25);
}

.skeleton__main-info__title {
  width: 90px;
  height: var(--rem-size-24);
  margin-bottom: var(--rem-size-20);
}

.skeleton__main-info__result-label {
  width: 158px;
  height: var(--rem-size-24);
}

.skeleton__display {
  width: 320px;
  height: 37px;
  margin-top: var(--rem-size-15);
}

.bone {
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }

  25% {
    background-color: var(--grey);
  }

  50% {
    background-color: var(--grey-light);
  }

  75% {
    background-color: var(--grey-lighter);
  }

  100% {
    background-color: var(--grey-light);
  }
}

