.users-list-popover__counter {
  font-weight: var(--font-weight-bold);
  color: var(--black);
  border-bottom: 1px solid var(--grey-light);
}

.users-list-popover__item {
  display: flex;
  align-items: center;
  padding: var(--rem-size-4) var(--rem-size-8);
  cursor: pointer;
  transition: background-color var(--default-duration) ease-in-out;
}

.users-list-popover__item:hover {
  background-color: var(--grey-lightest);
}

.users-list-popover__item:not(:last-child) {
  border-bottom: 1px solid var(--grey-light);
}

.users-list-popover__item__avatar {
  flex: 0 0 auto;
}

.users-list-popover__item__user {
  padding-left: .45rem;
  color: var(--black);
}

.users-list-popover__item__user__name {
  font-family: var(--title-font-family);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-16);
  line-height: 1.2;
}

.users-list-popover__item__user__company {
  font-size: var(--rem-size-13);
  line-height: 1;
}

