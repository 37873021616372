






























































































.multiple-choices-field {
  max-width: 500px;
  padding: 0.2rem 0;
  background: none;
  border: none;
  box-shadow: none;
  text-align: left;

  &__separator {
    position: relative;
    right: 10px;
    border-left: 1px solid var(--secondary-color);

    .separator-error {
      margin-left: 10px;
    }
  }

  ::v-deep .box {
    background: var(--white);
  }
}
