.idt-user-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: var(--global-radius);
}

.idt-user-card:hover .idt-user-card__extra {
  display: block;
}

.idt-user-card__extra {
  display: none;
  position: absolute;
  top: var(--rem-size-10);
  right: var(--rem-size-15);
  z-index: 10;
}

.idt-user-card__image {
  max-width: 150px;
  width: 100%;
  max-height: 150px;
  height: 100%;
  margin: var(--rem-size-25) 0 var(--rem-size-20) 0;
}

.idt-user-card__name {
  max-width: 100%;
  color: var(--black);
  font-family: var(--title-font-family);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-20);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.idt-user-card__job-company {
  max-width: 200px;
  height: var(--rem-size-35);
  color: var(--black);
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  text-align: center;
}

.idt-user-card__tags {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin-top: -5px;
  padding-bottom: var(--rem-size-5);
}

.idt-user-card__tags ::v-deep .idt-tag-list__item {
  max-width: 94%;
  margin: 0 var(--rem-size-3);
}

.idt-user-card__tag {
  flex: 0 1 auto;
  height: var(--rem-size-25);
  max-width: calc(100% - 34px);
  margin-right: 5px;
  padding: 2px var(--rem-size-10) 0 var(--rem-size-10);
  background-color: var(--main-color-lighter);
  border-radius: 3px;
}

.idt-user-card__tag__text {
  width: 100%;
  color: var(--main-color-darker);
  font-family: var(--title-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-18);
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.idt-user-card__tag__nb-hidden-items {
  align-self: center;
  background-color: none;
  color: var(--main-color-darker);
  font-family: var(--title-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
}

