.idt-conference-time-schedule {
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding: 10px;
  background-color: var(--white);
  border: 2px solid;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
}

.idt-conference-time-schedule .ideacon {
  margin-right: 15px;
}

