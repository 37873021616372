





















































































@import '@/styles/mixins/index';

.bo-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--menu-height);
  background-color: var(--grey-darkest);

  @include respond-to(max-medium) {
    padding: 0 1rem;
  }
  @include respond-to(medium) {
    padding: 0 1.5rem;
  }

  &__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
  }
  &__title {
    flex: 0 0 auto;
    color: var(--grey);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;

    @include respond-to(max-medium) {
      margin-left: 3rem;
      padding-left: 1rem;
      border-left: 1px solid var(--grey);
    }
  }
  &__content {
    display: flex;

    @include respond-to(max-xlarge) {
      flex-direction: column;
      border-top: 1px solid var(--main-color-light);

      &:not(.open) {
        display: none;
      }
    }
    @include respond-to(xlarge) {
      align-items: center;
    }

    &.open {
      @include respond-to(max-medium) {
        width: 100vw;
      }
      @include respond-to(medium-xlarge) {
        width: 300px;
      }
      @include respond-to(max-xlarge) {
        position: absolute;
        top: var(--menu-height);
        right: 0;
        height: calc((var(--vh) * 100) - var(--menu-height));
        background-color: var(--grey-darkest);
        z-index: 9999;
        overflow-y: auto;
      }
    }
    &__main,
    &__global-features,
    &__features {
      display: flex;
      padding: 0;
      list-style-type: none;

      @include respond-to(max-xlarge) {
        flex-direction: column;
        margin: 0 0 0.75rem 0;
      }
      @include respond-to(xlarge) {
        margin: 0 0.75rem 0 0;
      }
    }
    &__main,
    &__global-features {
      @include respond-to(max-xlarge) {
        padding-bottom: 0.75rem;
        border-bottom: 1px solid var(--grey-dark);
      }
      @include respond-to(xlarge) {
        padding-right: 0.75rem;
        border-right: 1px solid var(--grey-dark);
      }
    }
    &__main {
      @include respond-to(max-xlarge) {
        padding-left: var(--rem-size-18);
      }
    }
  }
  &__btn-fo {
    margin: 0;
    background-color: var(--grey-darker);
    border: none;
    color: var(--white);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;

    &:hover {
      background-color: var(--main-color-light);
      color: var(--grey-darkest);
    }
    &--mobile {
      @include respond-to(max-medium) {
        border-radius: 0;
        font-size: var(--rem-size-16);
      }
      @include respond-to(medium) {
        display: none !important;
      }
    }
    &--desktop {
      @include respond-to(max-medium) {
        display: none;
      }
      @include respond-to(medium) {
        font-size: var(--rem-size-12);
      }
      @include respond-to(medium-xlarge) {
        margin-right: 1rem;
      }
    }
  }
  &__burger {
    @include respond-to(max-small) {
      flex: 0 0 auto;
    }
    @include respond-to(max-xlarge) {
      display: block;
      position: relative;
      width: calc(var(--menu-height) * 0.75);
      height: var(--menu-height);
      color: var(--white);

      &.is-active {
        span {
          &:nth-child(1) {
            transform: translateX(-50%) translateY(11px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateX(-50%) translateY(-11px) rotate(-45deg);
          }
        }
      }
      span {
        display: block;
        position: absolute;
        left: calc(50%);
        width: 35px;
        height: 3px;
        background-color: currentColor;
        transition: all 86ms ease-out;
        transform: translateX(-50%);

        &:nth-child(1) {
          top: calc(50% - 12px);
        }
        &:nth-child(2) {
          top: calc(50% - 1px);
        }
        &:nth-child(3) {
          top: calc(50% + 10px);
        }
      }
    }
    @include respond-to(xlarge) {
      display: none;
    }
  }
}
