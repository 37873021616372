.fo-project-bar-team-notes__edit__content {
  padding: var(--rem-size-16);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-medium);
}

.fo-project-bar-team-notes__edit__content__textarea {
  margin-top: var(--rem-size-16);
}

.fo-project-bar-team-notes__edit__content__buttons {
  display: flex;
  justify-content: space-between;
}

.fo-project-bar-team-notes__view__notes {
  height: 100px;
  margin-top: var(--rem-size-16);
  margin-bottom: var(--rem-size-24);
  padding: var(--rem-size-16);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-normal);
  background-color: var(--white);
  color: var(--grey-darkest);
  overflow: auto;
}

.fo-project-bar-team-notes__view__buttons {
  display: flex;
  justify-content: flex-end;
}

