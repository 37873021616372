.disable {
  pointer-events: none;
}

.idt-fo-card {
  border-radius: 5px;
}

::v-deep .idt-fo-card__header {
  padding: 5px;
}

::v-deep .idt-fo-card__footer {
  margin-top: 5px;
  padding: 10px;
}

::v-deep .idt-fo-card__content {
  padding: 0 10px;
}

::v-deep .noborder .idt-fo-card__footer {
  border-top: 0px;
}

.content__tooltip {
  align-items: center;
  position: absolute;
  top: -10%;
  right: 0;
  left: 0;
  width: fit-content;
  max-width: 228px;
  height: 44px;
  margin-right: auto;
  margin-left: auto;
  color: var(--white);
  background-color: var(--black);
  border-radius: var(--global-radius);
  box-shadow: 0px 3px 6px var(--grey);
}

.content__tooltip__text {
  max-height: var(--rem-size-20);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14);
  line-height: var(--rem-size-20);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content__tooltip__icon {
  padding-left: 0;
}

.content__agenda {
  align-items: center;
  max-width: none;
  margin-left: 0;
}

.content__agenda__item {
  justify-content: center;
  justify-self: center;
  min-height: 25px;
  color: var(--grey-darkest);
}

.content__agenda__item__left {
  display: flex;
  align-items: center;
  color: var(--grey-darkest);
}

.content__agenda__item__left__live {
  margin-right: 10px;
}

.content__agenda__item__left__text {
  margin-left: 5px;
  color: var(--black);
  font-family: var(--first-font-family);
  font-size: var(--rem-size-17);
  line-height: var(--rem-size-20);
  vertical-align: bottom;
}

.content__agenda__item__star-button {
  width: 25px;
  height: 25px;
  color: var(--main-color);
  border-radius: 100%;
  pointer-events: all;
  z-index: 100;
}

.content__agenda__item__star-button:hover {
  cursor: pointer;
}

.content__agenda__item__star-button.inactive {
  border: 1px solid var(--grey);
}

.content__agenda__item__star-button.active {
  border: 1px solid var(--main-color);
}

.content__agenda__description {
  align-items: center;
  justify-content: flex-start;
  height: 36px;
  margin: unset;
}

.content__agenda__description__text {
  color: var(--black);
  font-family: var(--title-font-family);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-20);
  word-break: break-word;
}

.card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-content__speaker-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.card-content__speaker-info__username {
  color: var(--black);
  font: normal normal bold 14px/20px Kreon;
}

.card-content__speaker-info__job {
  color: var(--black);
  font: normal normal normal 12px/14px Roboto;
}

.card-content__speaker-info__multi-speaker {
  color: var(--black);
  font: normal normal bold 12px/14px Kreon;
}

.idt-user-avatar {
  background-color: var(--grey-light);
}

