.submenu-account-list {
  margin-top: var(--rem-size-12);
}

.submenu-account-list__labeled-separator,
.submenu-account-list__separator {
  width: calc(100% - var(--rem-size-20));
}

.submenu-account-list__labeled-separator {
  margin-left: var(--rem-size-10);
}

.submenu-account-list__separator {
  margin: var(--rem-size-10);
  border-color: var(--grey-light);
}

