.idt-ticket-flip-container {
  position: relative;
  max-width: 300px;
  perspective: 1000;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-flip-container {
    display: block !important;
    margin: auto;
  }
}

@media only screen and (min-width: 992px)and (max-width: 1199px) {
  .idt-ticket-flip-container {
    padding: 1rem 0 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-flip-container {
    padding: 3rem 0;
  }
}

.idt-ticket-flip-card {
  position: relative;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: all .3s ease-in-out;
}

@media only screen and (min-width: 640px) {
  .idt-ticket-flip-card {
    transform-style: preserve-3d;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-flip-card.flipped {
    transform: rotateY(180deg);
  }
}

.idt-ticket-flip-card__side,
.idt-ticket-flip-card__side-back {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
}

@media only screen and (max-width: 1199px) {
  .idt-ticket-flip-card__side,
  .idt-ticket-flip-card__side-back {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .idt-ticket-flip-card__side,
  .idt-ticket-flip-card__side-back {
    padding-top: 28px;
  }
}

.idt-ticket-flip-card__side {
  transition: all .3s ease-in-out;
}

.idt-ticket-flip-card.flipped .idt-ticket-flip-card__side {
  visibility: hidden;
}

@media only screen and (max-width: 639px) {
  .idt-ticket-flip-card__side-back {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .idt-ticket-flip-card__side-back {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white);
    transform: rotateY(180deg);
  }
}

