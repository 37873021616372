.post-conference-card {
  height: 100%;
  width: 100%;
  background-color: var(--main-color-lighter);
}

.post-conference-card__content {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--main-color);
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .post-conference-card__content {
    transform: translate(-50%, -50%) scale(0.5);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .post-conference-card__content {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .post-conference-card__content {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

@media only screen and (min-width: 1200px) {
  .post-conference-card__content {
    transform: translate(-50%, -50%);
  }
}

