















































































.conference-menu {
  &__submenu {
    position: absolute;
    top: calc(var(--menu-height) - 13px);
    right: 0;
    width: 380px;
    background-color: var(--white);
    filter: drop-shadow(0 2px 2px var(--black-alpha20));
    z-index: 10;
  }
}
