

















































































@import '@/styles/mixins/index';

.meeting-room-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  padding: var(--rem-size-15) var(--rem-size-20);
  background: var(--white);
  @include respond-to(max-slarge) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
  }
  &__title {
    max-width: calc(100% - 320px);
    color: var(--grey-darkest);
    font-size: var(--rem-size-20);
    font-weight: var(--font-weight-medium);
    line-height: var(--rem-size-26);
    @include respond-to(max-slarge) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__right {
    @include respond-to(max-slarge) {
      margin-top: var(--rem-size-10);
    }

    &__action, &__countdown {
      margin: 0;
    }
    &__action {
      background: var(--red);
      color: var(--white);

      @include respond-to(max-xsmall) {
        width: 100%;
      }
    }
    &__countdown {
      margin-left: var(--rem-size-10);
      background: var(--grey-lightest);
      color: var(--grey-darkest);
      font-size: var(--rem-size-14);
      @include respond-to(max-xsmall) {
        width: 100%;
        margin-top: var(--rem-size-10);
        margin-left: 0;
      }
    }
  }
}
