

















































































@import '@/styles/mixins/index';

.fo-project-section-issue-and-solution-view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 2rem 3rem;

  @include respond-to(max-medium) {
    flex-direction: column;
    margin: 1rem 1.5rem;
  }

  &__part {
    flex: 1;
    flex-basis:50%;
    max-width: calc(50% - var(--rem-size-12));
    padding-left: 24px;
    border-left: 2px solid var(--main-color);

    @include respond-to(max-medium) {
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }

    &:first-of-type {
      margin-right: var(--rem-size-32);
    }
    &__title-small {
      font-size: var(--rem-size-24);
      font-weight: bold;
    }
    &__title-big {
      margin-bottom: var(--rem-size-16);
      font-size: var(--rem-size-36);
      font-weight: bold;
    }
    &__content {
      font-size: var(--rem-size-16);
    }
    &__files {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
