

























































































































@import '@/styles/mixins';

.conference-viewers-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--rem-size-10);
  background-color: var(--white);
  overflow-y: auto;

  .idt-input {
    margin-bottom: var(--rem-size-10);
    padding: var(--rem-size-6) var(--rem-size-10);
  }

  .vs__dropdown-toggle {
    height: 40px;
  }
  .vs__search {
    margin: 0;
    padding-left: var(--rem-size-8) !important;
  }
  .idt-select-container {
    height: 50px;
  }
  &__list {
    flex-grow: 1;
    overflow: auto;
    // hide border-bottom on the last element of the list
    div[role="group"] :last-child .conference-viewers-list-item {
      border-bottom: 0 !important;
    }
  }
}
