.idt-color-picker {
  position: relative;
  margin: 0;
}

.idt-color-picker__icon .ideacon {
  position: absolute;
  left: 1rem;
  bottom: 40px;
}

.idt-color-picker .idt-input {
  padding-left: 2.5rem;
  background-color: var(--white) !important;
  cursor: pointer;
}

.idt-color-picker__picker {
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: calc(100% - 2rem);
  top: calc(100% - 24px);
  right: 1rem;
  margin: auto !important;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0,0,0,.1607843137);
  z-index: 1000;
}

