
































































.gamification-widget-point-progress {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow : 1;

  &__level {
    display: flex;
    justify-content: space-between;

    &--center {
      margin: auto;
    }
    &__points {
      color: var(--secondary-color);
      font-weight: var(--font-weight-bold);
      margin-left: 1rem;

      sup {
        font-size: var(--rem-size-8);
      }
    }
  }
  &.primary {
    .gamification-widget-point-progress__level__points {
      color: var(--main-color);
    }
  }
}
