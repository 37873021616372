








































































@import '@/styles/functions';

.idt-file-download-item {
  display: flex;
  align-items: center;
  gap: remCalc(16);
  width: 100%;
  min-height: 50px;
  padding: 0 remCalc(8);
  border-radius: var(--global-radius);
  color: var(--black);
  text-align: left;
  transition: backaground-color var(--default-duration) ease;
  cursor: pointer;

  &__label {
    flex: 1 0;
  }
  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: remCalc(40);
    height: remCalc(40);
    border-radius: 100%;
    background-color: var(--grey-light);
    transition: background-color var(--default-duration) ease;
  }
  &:hover {
    background-color: var(--grey-lightest);
  }
  &:hover &__action {
    background-color: var(--grey);
  }
}
