










































































@import '@/styles/mixins/index';

.chose-modal {
  width: 100%;

  @include respond-to(max-large) {
    max-width: 100%;
  }

  &__title {
    color: var(--main-color);
    font-weight: var(--font-weight-normal);
  }

  &__subtitle {
    color: var(--grey-900);

    @include respond-to(large) {
      max-width: 550px;
      margin: auto;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    width: 100%;

    @include respond-to(max-large) {
      flex-flow: column wrap;
    }

    &__item {
      flex-flow: column wrap;
      flex: 1 1 auto;

      &.choose {
        text-align: center;

        @include respond-to(medium) {
          padding: 0 90px;
        }
        @include respond-to(large) {
          max-width: 400px;
        }
      }
      &:not(.choose) {
        @include respond-to(medium) {
          padding: 0 35px;
        }
        @include respond-to(large) {
          max-width: 600px;
        }
      }
    }
    &__item:not(:last-child) {
      position: relative;

      @include respond-to(max-large) {
        margin-bottom: 1rem;
      }
      @include respond-to(large) {
        &.choose {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            height: 70%;
            border-right: 1px solid var(--grey);
            transform: translateY(-50%);
          }
        }
      }
    }
    &__icon {
      margin-top: 1rem;
      color: var(--grey-900);
      font-size: 7rem;
    }
    &__title {
      margin-bottom: 1rem;
      color: var(--main-color);
      font-family: var(--title-font-family);
      font-size: 1.25rem;
      text-transform: uppercase;
    }
    &__text {
      @include respond-to(large) {
        min-height: 125px;
      }
    }
  }
  &__button {
    min-width: 235px;
    height: 55px;
    font-family: var(--text-font-family);
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}
