



































































.mentor-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--rem-size-16);

  &__card {
    flex: 0 0 auto;
    max-width: 280px;
    width: 100%;

    &.type-list {
      max-width: unset;
    }
  }
}
