
















.idt-text-cell {
  display: inline-block;
  padding: var(--rem-size-8) 0;
}
