

































































































































.conference-list-container {
  overflow-x: auto;
  overflow-y: hidden;

  &__card {
    width: 305px;
  }
  &__mobile-container {
    flex-flow: nowrap;

    &__mobile-card {
      width: 275px;
      margin-left: 20px;
      padding: 0;
    }
  }
}
