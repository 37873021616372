.scroll-container {
  height: calc(var(--vh, 1vh)*100 - var(--menu-height));
  overflow: auto;
}

.scroll-container .mentors-page {
  display: flex;
  justify-content: center;
  min-height: calc(var(--vh, 1vh)*100 - var(--menu-height));
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page {
    height: fit-content;
    overflow: unset;
  }
}

.scroll-container .mentors-page__filter-panel {
  position: sticky;
  top: 0;
  max-width: 280px;
  height: calc(var(--vh, 1vh)*100 - var(--menu-height));
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__filter-panel {
    display: none;
  }
}

.scroll-container .mentors-page__filter-panel.hidden {
  position: absolute;
}

.scroll-container .mentors-page__filter-panel.mobile {
  display: block;
  background-color: var(--white);
  max-width: none;
  width: 100%;
  padding-inline: var(--rem-size-16);
}

.scroll-container .mentors-page__filter-panel::-webkit-scrollbar {
  display: none;
}

.scroll-container .mentors-page__header {
  position: sticky;
  top: 0;
  padding: var(--rem-size-15) 0 var(--rem-size-25) 0;
  background-color: var(--grey-lighter);
  box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
  clip-path: inset(0px -10px 0px -10px);
  z-index: 20;
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__header {
    padding: var(--rem-size-15) 0 var(--rem-size-10) 0;
  }
}

.scroll-container .mentors-page__header__mobile {
  height: 0;
  visibility: hidden;
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__header__mobile {
    height: 100%;
    visibility: initial;
  }
}

.scroll-container .mentors-page__header__mobile__bar {
  height: 46px;
  margin-right: var(--rem-size-10);
  background-color: var(--white);
  border: solid 1px var(--grey-light);
  border-radius: var(--global-radius);
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

.scroll-container .mentors-page__header__mobile__bar__input {
  height: 44px;
  margin: 0;
  color: var(--black);
  background-color: var(--white);
  border: none;
  font-size: var(--rem-size-16);
}

.scroll-container .mentors-page__header__mobile__bar:before {
  content: "";
  display: block;
  top: 85px;
  left: var(--rem-size-8);
  position: absolute;
  color: var(--black);
  font-family: "ideation-icons";
  font-size: 1rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.scroll-container .mentors-page__header__mobile__filter {
  height: 46px;
  background-color: var(--white);
  box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
}

.scroll-container .mentors-page__header__mobile__results {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__header__mobile__results {
    display: flex;
    height: var(--rem-size-22);
    margin: 0;
    font-size: var(--rem-size-14);
    text-align: right;
  }
}

.scroll-container .mentors-page__header__title-results {
  display: flex;
  flex-direction: row;
  height: var(--rem-size-32);
  margin-bottom: var(--rem-size-15);
  color: var(--black);
}

.scroll-container .mentors-page__header__title-results__title {
  max-height: 48px;
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-bold);
  text-align: left;
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__header__title-results__title {
    text-align: center;
  }
}

.scroll-container .mentors-page__header__title-results__title.search {
  font-size: var(--rem-size-16);
}

.scroll-container .mentors-page__header__title-results__result {
  align-self: flex-end;
  font-size: var(--rem-size-15);
  text-align: right;
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__header__title-results__result {
    display: none;
  }
}

.scroll-container .mentors-page__header__infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
  background: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
}

@media only screen and (max-width: 1023px) {
  .scroll-container .mentors-page__header__infos {
    height: 0;
    visibility: hidden;
  }
}

.scroll-container .mentors-page__header__infos__label {
  display: flex;
  flex-direction: column;
  margin: 5px var(--rem-size-10) 0 0;
  color: var(--black);
}

.scroll-container .mentors-page__header__infos__select {
  display: flex;
  width: 160px;
  height: 41px;
  margin: 0 var(--rem-size-10) 0 0;
  padding: 0 var(--rem-size-15);
}

.scroll-container .mentors-page__header__infos__button {
  display: flex;
  flex-direction: column;
  margin: 0 var(--rem-size-10) 0 0;
  color: var(--grey);
}

.scroll-container .mentors-page__header__infos__button:hover {
  cursor: pointer;
}

.scroll-container .mentors-page__header__infos__button.disabled,
.scroll-container .mentors-page__header__infos__button[disabled] {
  color: var(--black);
  opacity: 1;
  pointer-events: none;
}

.scroll-container .mentors-page__mentors {
  flex-direction: column;
  flex: 1 1 0px;
  max-width: 912px;
  padding-inline: var(--rem-size-20);
}

.scroll-container .mentors-page__mentors.panel-hidden {
  max-width: 1166px;
}

.scroll-container .mentors-page__mentors__container {
  padding: var(--rem-size-15) 0 var(--rem-size-15) 0;
}

.scroll-container .mentors-page__mentors__container__spinner {
  display: flex;
  align-content: left;
}

