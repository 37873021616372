.idt-textarea-container {
  position: relative;
  padding-bottom: 1.5rem;
}

.idt-textarea-container:not(.container-only) {
  position: relative;
}

.idt-textarea-container:not(.container-only) .idt-textarea {
  padding-right: 4rem;
}

.idt-textarea-container.container-only {
  display: contents;
}

.full-width {
  width: 100%;
}

.idt-textarea {
  margin: 0;
}

.idt-textarea.no-resize {
  resize: none;
}

.idt-textarea.has-error {
  border-color: var(--secondary-color);
}

.idt-textarea-counter {
  position: absolute;
  right: 1rem;
  bottom: 2.1875rem;
  color: var(--grey-dark);
}

::v-deep .idt-required-field {
  position: absolute;
  bottom: 0;
  left: 0;
}

