.fo-project-bar-team-members__button {
  display: flex;
  align-items: center;
  padding: var(--rem-size-8);
  background-color: rgba(0,0,0,0);
  color: var(--white);
  cursor: pointer;
  transform: rotate(90deg);
}

.fo-project-bar-team-members__item {
  padding: var(--rem-size-8);
  color: var(--grey-darkest);
  cursor: pointer;
}

.fo-project-bar-team-members__item:hover {
  background-color: var(--grey-dark);
}

.fo-project-bar-team-members__loading {
  width: var(--rem-size-20);
  height: var(--rem-size-20);
  margin-right: var(--rem-size-6);
}

.fo-project-bar-team-members__title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: var(--rem-size-8);
}

::v-deep .idt-dropdown--pane {
  padding: 0;
}

