


























































































@import '@/styles/mixins/index';

.modal-conference {
  width: 100%;
  padding: 1rem;

  @include respond-to(max-large) {
    max-width: 100%;
  }
  &__subtitle {
    @include respond-to(large) {
      max-width: 550px;
      margin: auto;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    width: 100%;

    @include respond-to(max-large) {
      flex-flow: column wrap;
    }

    &-left,
    &-right {
      flex-flow: column wrap;
      flex: 1 1 auto;

      &.choose {
        text-align: center;

        @include respond-to(medium) {
          padding: 0 90px;
        }
        @include respond-to(large) {
          max-width: 450px;
        }
      }
      &:not(.choose) {
        @include respond-to(medium) {
          padding: 0 35px;
        }
        @include respond-to(large) {
          max-width: 600px;
        }
      }
    }
    &-left {
      position: relative;

      @include respond-to(max-large) {
        margin-bottom: var(--rem-size-16);
      }
      @include respond-to(large) {
        &.choose {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            height: 70%;
            border-right: 1px solid var(--grey);
            transform: translateY(-50%);
          }
        }
      }
    }
    &__icon {
      color: var(--grey-900);
      font-size: 7rem;
      -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
    }
    &__title {
      margin-bottom: var(--rem-size-16);
      color: var(--grey-darker);
      font-family: var(--title-font-family);
      font-size: var(--rem-size-20);
      font-weight: 700;
      text-transform: uppercase;
    }
    &__text {
      @include respond-to(large) {
        min-height: 125px;
      }
    }
    &__description {
      height: 140px;
      resize: none;
    }
  }
}
.choise-disabled {
  position: relative;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white-alpha90);
    z-index: 1;
  }
  &__content {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    padding: 1rem;
    color: var(--main-color);
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    z-index: 1;

    &.white {
      color: white;
    }
  }
}
