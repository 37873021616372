.font-selector {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  padding: var(--rem-size-8) var(--rem-size-32) var(--rem-size-8) var(--rem-size-16);
  border: 1px solid var(--grey-dark);
  border-radius: var(--global-radius);
}

.font-selector:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: var(--rem-size-16);
  width: 0;
  height: 0;
  border-left: 5px solid rgba(0,0,0,0);
  border-right: 5px solid rgba(0,0,0,0);
  border-top: 5px solid var(--grey-darkest);
  transform: translate(-50%, -50%);
}

.font-selector:not(.disabled) {
  cursor: pointer;
}

.font-selector.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.font-selector-opt-container {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: var(--white);
  border: 1px solid var(--grey-dark);
  border-radius: 0 0 var(--global-radius) var(--global-radius);
  box-shadow: 0 0 2px var(--black-alpha30),0 4px 8px var(--black-alpha30);
  overflow-x: hiddend;
  overflow-y: auto;
  z-index: 10;
}

.font-selector-opt {
  padding: var(--rem-size-8);
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-dark);
  transition: background-color .3s ease-in-out;
}

.font-selector-opt:hover {
  color: var(--main-color-dark);
  background-color: var(--main-color-lighter);
}

.font-selector-opt.selected {
  color: var(--white);
  background-color: var(--main-color);
}

