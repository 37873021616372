::v-deep .idt-dropdown {
  width: 100%;
}

::v-deep .idt-dropdown--pane {
  width: 100%;
  padding: 0;
}

.input-dropdown {
  position: relative;
}

.input-dropdown__input__container {
  position: relative;
  cursor: pointer;
}

.input-dropdown__input.div {
  min-height: 53px;
  pointer-events: auto;
}

.input-dropdown__input.div .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.input-dropdown__input.div .tag-list .tag-item {
  height: 29px;
  color: var(--white);
  background: var(--main-color);
  font-family: "Roboto",sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  margin: 2px;
  padding: 0 5px;
  display: inline-block;
  border: 1px solid var(--main-color);
  border-radius: 3px;
}

.input-dropdown__input.div .tag-list .tag-item .remove-button {
  margin: 0 0 0 5px;
  padding: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  vertical-align: middle;
}

.input-dropdown__button {
  margin: 0;
  color: var(--main-color);
  background-color: var(--white);
  border-color: var(--main-color);
  text-transform: uppercase;
}

.input-dropdown__button.dropdown:after {
  border-color: var(--main-color) rgba(0,0,0,0) rgba(0,0,0,0);
}

.input-dropdown__button:hover {
  color: var(--main-color);
  background-color: var(--white);
}

.input-dropdown .idt-dropdown--pane {
  max-height: 50vh;
  margin: -2.75rem 0 0 -0.25rem;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 5px var(--black-alpha20);
  overflow-y: auto;
}

.input-dropdown .idt-dropdown--pane__header {
  position: relative;
  min-height: 40px;
  color: var(--main-color);
  background-color: var(--main-color-alpha10);
  font-size: .8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.input-dropdown .idt-dropdown--pane__header:not(.counter):not(.checkbox) {
  padding: .7rem 1rem;
}

.input-dropdown .idt-dropdown--pane__header.counter {
  padding: .7rem 2.5rem .7rem 1rem;
}

.input-dropdown .idt-dropdown--pane__header .count {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1rem;
  color: var(--main-color);
  transform: translateY(-50%);
}

.input-dropdown .idt-dropdown--pane__header.checkbox {
  padding: .7rem 3rem .7rem 1rem;
  cursor: pointer;
}

.input-dropdown .idt-dropdown--pane__header.checkbox:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 16px;
  height: 16px;
  border: 2px solid var(--white);
  border-radius: 100%;
  box-shadow: 0 0 0 2px var(--main-color);
  transform: translateY(-50%);
}

.input-dropdown .idt-dropdown--pane__header.checkbox.checked:before {
  background-color: var(--main-color);
}

.input-dropdown .idt-dropdown--pane button {
  width: 100%;
  height: 40px;
  padding: .7rem 1rem;
  border-radius: 0;
  font-family: var(--text-font-family);
  text-align: left;
  cursor: pointer;
}

.input-dropdown .idt-dropdown--pane button.add {
  position: relative;
  color: var(--main-color);
  background-color: var(--main-color-alpha10);
  font-size: .8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.input-dropdown .idt-dropdown--pane button.add i {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.input-dropdown .idt-dropdown--pane__checkbox {
  position: relative;
  width: 100%;
  margin: 0;
  padding: .7rem 3rem .7rem 1rem;
  cursor: pointer;
}

.input-dropdown .idt-dropdown--pane__checkbox:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 16px;
  height: 16px;
  border: 2px solid var(--white);
  border-radius: 100%;
  box-shadow: 0 0 0 2px var(--main-color);
  transform: translateY(-50%);
}

input[type=radio]:checked+.input-dropdown .idt-dropdown--pane__checkbox:before,
input[type=checkbox]:checked+.input-dropdown .idt-dropdown--pane__checkbox:before {
  background-color: var(--main-color);
}

.input-dropdown .idt-dropdown--pane input[type=radio],
.input-dropdown .idt-dropdown--pane input[type=checkbox] {
  display: none;
}

.input-dropdown__title {
  background-color: var(--main-color-alpha20);
  color: var(--main-color);
}

.input-dropdown .menu {
  max-width: 100%;
  font-size: .8rem;
  overflow-x: hidden;
}

.input-dropdown .menu:not(.multiple) {
  max-height: 200px;
  overflow-y: auto;
}

.input-dropdown .menu li:not(:last-child) a,
.input-dropdown .menu li:not(:last-child) button {
  border-bottom: 1px solid var(--grey-lighter);
}

.input-dropdown .menu li a,
.input-dropdown .menu li button {
  color: var(--grey-darkest);
}

.input-dropdown .menu li a:hover,
.input-dropdown .menu li button:hover {
  color: var(--main-color);
}

input[type=checkbox].checked+.dropdown-pane__checkbox:before {
  background-color: var(--main-color);
}

.button-dropdown {
  display: inline-block;
}

.modal-account {
  max-width: 500px;
}

.modal-account__back.hollow {
  position: fixed;
  margin: 0;
  padding: .6rem 1rem;
  color: var(--grey-darker);
  border-color: var(--grey-darker);
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .modal-account__back.hollow {
    top: .5rem;
    left: .5rem;
    z-index: 100;
  }
}

@media only screen and (min-width: 640px) {
  .modal-account__back.hollow {
    top: 1.875rem;
    left: 1.875rem;
  }
}

.modal-account__back.hollow:hover {
  color: var(--grey-darkest);
  border-color: var(--grey-darkest);
}

.modal-account__textarea {
  width: 100%;
}

.modal-account__input {
  padding-bottom: 2rem;
  margin-bottom: 0px;
}

.modal-account .tags {
  padding: .625rem;
  height: 150px;
  background-color: var(--white);
  border-color: var(--grey);
  border-radius: var(--global-radius);
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.modal-account .tags.focused {
  box-shadow: none;
}

.modal-account .tags .input {
  height: 29px;
  color: var(--main-color);
}

.modal-account .tags .input::-webkit-input-placeholder {
  color: var(--main-color);
}

.modal-account .tags .input::-moz-placeholder {
  color: var(--main-color);
  opacity: 1;
}

.modal-account .tags .input::placeholder {
  color: var(--main-color);
}

.custom-mail__textarea {
  margin-bottom: 0px;
}

