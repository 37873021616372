.gamification-history__no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamification-history__no-data-container__label {
  color: var(--grey-dark);
  font-size: var(--rem-size-20);
}

.gamification-history__no-data-container__icon {
  color: var(--grey-dark);
}

