.idt-round-loader {
  position: relative;
  margin: 0 auto 1.5rem;
}

.idt-round-loader .loading {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: .25rem solid var(--grey-light);
  border-top-color: var(--secondary-color);
  animation: spin 1s infinite linear;
}

.idt-round-loader .loader-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--main-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

