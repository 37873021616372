a,
.link {
  line-height: inherit;
  color: var(--main-color);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--main-color-dark);
  }

  img {
    border: 0;
  }
}
