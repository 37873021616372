.book {
  align-items: center;
  width: fit-content;
  min-width: var(--rem-size-32);
  height: var(--rem-size-32);
  margin: 0;
  background-color: var(--main-color);
  border-radius: var(--rem-size-16);
  color: var(--white);
  cursor: pointer;
}

.book.booked {
  background-color: var(--main-color-darker);
}

.book.deployed {
  height: var(--rem-size-35);
  width: 200px;
  font-size: var(--rem-size-14);
}

.book.deployed .book__icon {
  margin-left: var(--rem-size-10);
}

.book.deployed .book__text {
  display: inline-block;
  max-width: 200px;
  padding-left: var(--rem-size-10);
  padding-right: var(--rem-size-15);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-18);
  cursor: pointer;
}

.book:hover .book.deployed .book__text {
  transition: all .3s ease;
}

.book__icon {
  display: inline-block;
  margin-left: 7px;
}

.book__text {
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
  font-family: var(--text-font-family);
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-14);
  overflow: hidden;
  text-align: left;
  transition: max-width 300ms,padding-left 300ms,padding-right 300ms;
  white-space: nowrap;
}

.book:hover .book__text {
  display: inline-block;
  max-width: 400px;
  padding-left: var(--rem-size-10);
  padding-right: var(--rem-size-15);
  transition-delay: .1s;
  cursor: pointer;
}

