




































































.selection-container-timeslots {
  &__timeslot {
    padding: 40px;

    &:not(:last-child) {
      border-bottom: 2px solid var(--grey);
    }
  }

}
