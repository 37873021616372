


































.progress {
  position: relative;
  background-color: var(--grey-light);
  border-radius: 15px;
  height: 0.5rem;
  margin: 0;
  &:focus{
    outline :none;
  }
  .progress-meter {
    position: static;
    border-radius: 15px;
    background-color: var(--secondary-color);
    &.animated {
      transition: width 3s ease-in-out;
    }
  }
  &.primary {
    .progress-meter {
      background-color: var(--main-color);
    }
  }
}
