.statistics-chart--loading {
  display: flex;
  flex-direction: column;
}

.statistics-chart__status {
  margin-top: auto;
  margin-bottom: auto;
}

.statistics-chart__status .status__loader {
  width: 50px;
  height: 50px;
  margin: 1.5rem auto !important;
}

.statistics-chart .content__svg .overlay {
  fill: none;
  pointer-events: all;
}

.statistics-chart .content__tab {
  flex-grow: 100;
}

