






















































































































































.idt-language-editor {
  margin-bottom: 40px;

  .language-editor {
    &.active {
      .language-editor__key {
        &__local {
          background-color: var(--main-color-alpha30);
        }
        &__translation {
          border-color: var(--main-color-alpha30);
        }
      }
    }

    h3 {
      font-size: 1rem;
    }
    &__key {
      display: flex;

      &.selected {
        .language-editor__key__local {
          background-color: var(--main-color);
          color: white;
        }
        .language-editor__key__translation {
          border-color: var(--main-color);
        }
      }

      &__local {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 90px;
        background-color: var(--grey-light);
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        transition: all 0.3s;
      }
      &__translation {
        height: 80px;
        width: 100%;
        border: 1px var(--grey-light) solid;
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        transition: all 0.3s;
      }
    }
  }
}
