
























































































































@import '@/styles/mixins/index';

.conference-alert {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 40vh;
  height: 380px;
  margin: 5px;
  padding: 1.5rem;
  color: var(--white);
  background-color: var(--grey-darkest-alpha75);
  text-align: center;
  z-index: 5;

  &.conf-advice {
    flex-basis: 60%;
  }
  .idt-conference-time-schedule {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__time {
    margin-bottom: 5px;
    font-size: 2.125rem;
    font-weight: var(--font-weight-bold);

    & ::v-deep .idt-conference-duration {
      color: var(--white);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;

    &__title {
      color: var(--white);
      font-family: var(--title-font-family);
      font-size: 1.75rem;
      text-transform: uppercase;
    }
    &__separator {
      width: 100px;
      margin-top: 0.5rem;
      border: 1px solid var(--main-color);
    }
    &__text--primary {
      margin-bottom: 1rem;
      color: var(--white);
      font-size: 1rem;
    }
    &__text--secondary {
      margin-bottom: 0.5rem;
      color: var(--white);
      font-size: 0.625rem;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
    }
    &__button-group {
      margin-bottom: 20px;
    }
  }
  &__footer {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    &__relaunch-test {
      color: var(--white);
      border-bottom: 1px solid var(--white);
      font-size: 0.6rem;
      font-weight: var(--font-weight-medium);
      &:hover {
        color: var(--main-color);
        border-bottom: 1px solid var(--main-color);
      }
    }
  }
  button,
  a.button {
    min-width: 130px;
    margin-bottom: 0.625rem;
    border-color: var(--white);
    color: var(--main-color-dark);
    background-color: var(--white);
    border-radius: 3px;
    font-size: 0.8rem;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;

    &.postpone-button {
      margin: 0 auto;
      display: block;
      padding: 0.5rem;
      border-color: var(--white);
      color: var(--color);
      font-size: 0.75rem;
      text-transform: none;

      &:hover,
      &:focus {
        color: var(--color);
        background-color: var(--black-alpha30);
        border-color: var(--white);
      }
      &.disabled {
        background-color: var(--grey-darkest);
        border-color: var(--grey-darkest);
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        opacity: 1;
        pointer-events: none;
      }
      svg {
        --spinner-stroke-color: white;
        --spiner-radius: 10px;
      }
    }
  }
  a {
    color: var(--white);

    &:hover {
      color: var(--white);
      background-color: var(--main-color);
    }
  }
}
.conf-live {
  color: var(--color-live);
}
.conf-replay {
  color: var(--color-replay);
  border: none;
}
.conf-scheduled {
  color: var(--color-scheduled);
}
.conf-standby {
  color: var(--color-starting);
}
.conf-starting {
  color: var(--color-starting);
}
