.idt-bottom-navbar {
  display: none;
  flex-flow: nowrap;
  align-items: stretch;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: var(--mobile-bottom-navbar-height);
  height: var(--mobile-bottom-navbar-height);
  width: 100%;
  background: var(--white);
  border-top: 1px solid var(--grey-darkest-alpha20);
  padding: var(--rem-size-6) 0 var(--rem-size-4);
  z-index: 1;
  transition: margin-top .15s ease-in-out,transform .15s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .idt-bottom-navbar {
    display: flex;
    border-radius: 0;
    box-shadow: none;
  }
}

.idt-bottom-navbar--hide {
  margin-top: calc(-1*var(--mobile-bottom-navbar-height));
  transform: translateY(100%);
}

.idt-bottom-navbar--fixed {
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.idt-bottom-navbar__icon-wrapper {
  display: flex;
  flex-flow: column;
  flex: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  flex-shrink: 0;
  overflow: hidden;
  color: var(--grey-darkest);
  font-size: var(--rem-size-12);
  line-height: var(--rem-size-18);
}

.idt-bottom-navbar__icon-wrapper>* {
  width: 100%;
  flex-basis: 100%;
}

.idt-bottom-navbar__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: 32px;
  width: 100%;
  height: 32px;
  max-width: 64px;
  margin-bottom: var(--rem-size-4);
  border-radius: 20px;
  color: var(--grey-darkest);
  transition: background-color .15s ease-in-out;
}

.idt-bottom-navbar__icon-wrapper.active .idt-bottom-navbar__icon {
  color: var(--grey-darkest);
  background-color: var(--main-color-alpha20);
}

