.idt-sidebars-container-toggle {
  position: absolute;
  bottom: 180px;
  left: 0;
  z-index: 1;
  width: 26px;
  padding: 12px 0 10px 2px;
  color: var(--white);
  background-color: var(--main-color);
  border-radius: var(--global-radius) 0 0 var(--global-radius);
  font-size: var(--rem-size-20);
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .idt-sidebars-container-toggle {
    display: none;
  }
}

.idt-sidebars-container-toggle.admin-conference-toggle {
  bottom: 35px;
  background-color: var(--secondary-color);
}

.idt-sidebars-container-toggle.sponsor-stats-toggle {
  bottom: 350px;
  background-color: var(--secondary-color);
}

.idt-sidebars-container-toggle__notifications {
  position: absolute;
  top: 0;
  right: 50%;
  width: 22px;
  height: 22px;
  color: var(--white);
  background-color: var(--secondary-color);
  border-radius: 100%;
  font-size: .7rem;
  text-align: center;
  line-height: 22px;
  transform: translate(50%, -50%);
}

.idt-sidebars-container-toggle__icon-chat {
  line-height: 1;
}

.idt-sidebars-container-toggle__icon-state {
  line-height: 1;
}

.idt-sidebars-container-toggle__label {
  position: relative;
  width: 26px;
  height: 75px;
  margin: 5px 0;
}

.idt-sidebars-container-toggle__label__text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: .9rem;
  font-weight: var(--font-weight-medium);
  line-height: 1.4;
  white-space: nowrap;
  transform: translate(-50%, -50%) rotate(-90deg);
}

