

























































































































































.how-it-works-card {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--rem-size-16);

    &__menu-icon {
      cursor: grabbing;
    }

    &__delete-icon {
      cursor: pointer;
    }
  }

  &__content {
    &__label {
      text-align: left;
    }

    &__image {
      display: flex;
      margin-bottom: var(--rem-size-16);

      &__upload {
        text-align: left;

        &__title {
          margin-bottom: var(--rem-size-8);
          font-weight: var(--font-weight-medium);
          color: var(--black);
        }

        &__description {
          margin-bottom: var(--rem-size-8);
        }

        &__input {
          border: 0;
        }
      }
    }
  }

  ::v-deep .upload-file__btn {
    height: 35px;
    margin-bottom: 0;
    height: fit-content;
    line-height: 50%;
    font-weight: var(--font-weight-medium);
    border-radius: 23px;
    background-color: var(--white);
    color: var(--black);
  }
}
