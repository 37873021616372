
























































































































.idt-duration-picker__dialog {
    position: absolute;
    width: 220px;
    padding: var(--rem-size-14);
    background: var(--white);
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;

    &.top {
      bottom: 55px;
    }

    &.bottom {
      top: 100%;
    }

    &__label {
      text-align: center;
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin: 8px 20px;
        border-radius: 50%;
        color: var(--black);
        background: var(--grey-light);
        cursor: pointer;
      }
    }

    &__picker {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;
      height: 48px;
      background: var(--grey-light);
      border-radius: var(--global-radius);
      color: var(--black);

      &__input {
        display: inline-block;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        font-size: var(--rem-size-36);
        -moz-appearance: textfield;
        text-align: right;

        &:focus {
          border: none;
          background: none;
        }
        &.hours {
          width: 80px;
        }

        &.minutes {
          width: 40px;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &__span {
        font-size: var(--rem-size-24);
      }
    }
  }
