.idt-color-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--rem-size-10);
  margin-left: var(--rem-size-10);
}

.idt-color-tooltip label {
  font-size: var(--rem-size-13);
  font-weight: var(--font-weight-medium);
}

.idt-color-tooltip__tooltip {
  position: relative;
  width: var(--rem-size-28);
  height: var(--rem-size-28);
}

.idt-color-tooltip__tooltip .selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: solid 2px var(--black);
  border-radius: 50%;
  cursor: pointer;
}

.idt-color-tooltip__tooltip__content {
  display: none;
  position: absolute;
  top: -100vh;
  right: -35px;
  width: 214px;
  padding: var(--rem-size-15);
  background-color: var(--white);
  box-shadow: 0 8px 10px -5px var(--black-alpha20),0 8px 24px 2px var(--black-alpha14),0 6px 30px 5px var(--black-alpha12);
  transition: top 0s .5s,opacity .5s;
  z-index: 9999;
}

.idt-color-tooltip__tooltip__content:before {
  content: "";
  position: absolute;
  top: calc(-1*var(--rem-size-10));
  right: 2.625rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 10px 7.5px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) var(--white) rgba(0,0,0,0);
}

.idt-color-tooltip__tooltip__content.show {
  display: block;
  top: 2.625rem;
}

.idt-color-tooltip__tooltip__content label {
  display: block;
  color: var(--black);
  font-size: var(--rem-size-13);
  font-weight: 500;
  opacity: .4;
}

.idt-color-tooltip__tooltip__content .items {
  line-height: 0;
}

.idt-color-tooltip__tooltip__content .items .item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
}

.idt-color-tooltip__tooltip__content .items .item--icon {
  height: 46px;
}

.idt-color-tooltip__tooltip__content .items .item.selected {
  border: solid 2px var(--main-color);
}

.idt-color-tooltip__tooltip__content .items .item__color {
  width: var(--rem-size-30);
  height: var(--rem-size-30);
  border-radius: var(--global-radius);
}

.idt-color-tooltip--inline {
  flex-flow: row;
  margin-left: 0;
}

.idt-color-tooltip--inline>label {
  padding-bottom: 0 !important;
}

.idt-color-tooltip--inline .idt-color-tooltip__tooltip {
  margin-left: auto;
}

.idt-color-tooltip--above .idt-color-tooltip__tooltip__content {
  left: var(--rem-size-30);
  transform: translateX(-100%) translateY(calc(-60px - 100%));
}

.idt-color-tooltip--above .idt-color-tooltip__tooltip__content:before {
  top: auto;
  right: .5625rem;
  bottom: -0.5625rem;
  left: auto;
  border-width: 10px 7.5px 0 7.5px;
  border-color: var(--white) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0);
}

