









































































@import '@/styles/mixins/index';

.idt-menu-mobile-toggle {
  @include respond-to(max-small) {
    flex: 0 0 auto;
  }
  @include respond-to(max-large) {
    display: block;
    position: relative;
    width: calc(var(--menu-height) * 0.75);
    height: var(--menu-height);
    margin-left: auto;
    color: var(--main-color);

    &.is-active {
      span {
        &:nth-child(1) {
          transform: translateX(-50%) translateY(11px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateX(-50%) translateY(-11px) rotate(-45deg);
        }
      }
    }
    span {
      display: block;
      position: absolute;
      left: calc(50%);
      width: 35px;
      height: 3px;
      background-color: currentColor;
      transition: all 86ms ease-out;
      transform: translateX(-50%);

      &:nth-child(1) {
        top: calc(50% - 12px);
      }
      &:nth-child(2) {
        top: calc(50% - 1px);
      }
      &:nth-child(3) {
        top: calc(50% + 10px);
      }
    }
  }
  @include respond-to(large) {
    display: none;
  }

  &__pill {
    transform: translate(0%, 30%);
  }
}

// Transition styles
.fade-mm-enter-active,
.fade-mm-leave-active {
  transition: all 0.3s ease;
}
.fade-mm-enter,
.fade-mm-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
