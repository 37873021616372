







































































































































.idt-conference-tab-surveys {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  background-color: var(--white);

  .idt-survey-card,
  .idt-survey-card-admin {
    margin-bottom: 1rem;
  }
}
