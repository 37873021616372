






















































@import '@/styles/mixins/index';

.modal-fullscreen {
  position: relative;
  width: 100vw;
  height: calc((var(--vh, 1vh) * 100));
  padding: 2rem 1rem;
  background-color: var(--white);
  position: fixed;
  overflow: auto;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 1.5rem 0;
    margin: auto;
  }
  &__title {
    color: var(--grey-darkest);
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;

    @include respond-to(max-medium) {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
    @include respond-to(medium) {
      margin-bottom: 1.5rem;
      font-size: 1.875rem;
    }
  }
  &__subtitle {
    color: var(--main-color);
    font-size: 0.9rem;
    text-align: center;

    @include respond-to(max-medium) {
      margin-bottom: 2rem;
    }
    @include respond-to(medium) {
      margin-bottom: 4rem;
    }
  }
  &__button {
    &__container {
      margin-top: 2rem;
      text-align: center;
    }
  }
  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    &__label {
      /* Display 'close' label if screenWidth >= 640px */
      @include respond-to(max-medium) {
        display: none;
      }
      @include respond-to(medium) {
        display: block;
      }

      @include respond-to(medium-xlarge) {
        margin-right: 0.5rem;
      }
      @include respond-to(xlarge) {
        margin-right: 0.75rem;
      }
    }

    &__icon {
      &.idt-icon ::v-deep .ideacon {
        @include respond-to(max-xlarge) {
          width: 1.875rem;
          height: 1.875rem;
        }
        @include respond-to(xlarge) {
          width: 2.8125rem;
          height: 2.8125rem;
        }
      }
    }

    &__container {
      position: fixed;

      @include respond-to(max-medium) {
        top: 0;
        right: 0;
        padding: 0.5rem;
        background-color: var(--white);
        text-align: right;
      }
      @include respond-to(medium) {
        top: 1.875rem;
        right: 1.875rem;
      }
    }
  }
}
