.bo-card {
  display: flex;
  padding: 0 .9375rem 0rem .9375rem;
  margin-bottom: 1rem;
}

.bo-card__field {
  color: var(--grey-darker);
  font-size: .875rem;
  font-weight: var(--font-weight-medium);
}

.bo-card__field div,
.bo-card__field input,
.bo-card__field textarea {
  margin: 5px 15px 5px 15px;
}

.bo-card__field ::v-deep label {
  color: var(--grey-darker);
  font-size: 1rem;
}

.bo-card__label {
  color: var(--grey-dark);
  font-size: .875rem;
  font-weight: var(--font-weight-medium);
}

.bo-card__content {
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.bo-card__content__bordered {
  padding: 3px 0;
  flex-grow: 1;
}

.bo-card__content__bordered--with-header {
  border-right: solid 2px var(--grey-light);
  border-left: solid 2px var(--grey-light);
}

.bo-card__content__bordered--without-header {
  border-top: solid 2px var(--grey-light);
  border-right: solid 2px var(--grey-light);
  border-left: solid 2px var(--grey-light);
}

.bo-card__content__preview:hover .bo-card__file.has-file {
  background-color: rgba(0,0,0,.7);
}

.bo-card__content__preview:hover .bo-card__file.has-file span.white-hovered {
  color: var(--white);
}

.bo-card__switch {
  display: inline-block;
  right: 0;
  margin-left: 40px;
  margin-bottom: 10px;
}

.bo-card__switch-container {
  display: flex;
  flex-direction: row;
  margin: 5px 15px;
  font-size: .875rem;
  font-weight: var(--font-weight-normal);
}

.bo-card__switch-container label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--grey-darker);
}

