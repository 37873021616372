













































































































































































































































































































































@import '@/styles/mixins/index';

.idt-sponsor-stats-metric {
  height: 100%;
  padding: var(--rem-size-18) var(--rem-size-10) 0 var(--rem-size-10);

  &__count {
    margin-bottom: var(--rem-size-26);
    font-size: var(--rem-size-16);

    & ::v-deep &__value {
      color: var(--main-color);
    }
  }
  &__export {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--rem-size-23);

    .button {
      margin: 0;
      padding: var(--rem-size-4) var(--rem-size-24);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-bold);
      border-radius: var(--rem-size-23);
    }
  }
  &__toolbar {
    display: flex;
    justify-content: space-between;

    &__filter {
      margin-right: var(--rem-size-8);
    }
    &__filter + &__sort {
      margin-left: var(--rem-size-8);

      ::v-deep .idt-dropdown--pane {
        left: auto !important;
        right: 0 !important;
      }
    }
    .input-dropdown {
      position: relative;
      cursor: pointer;

      &__input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 3.313rem;
        padding: var(--rem-size-10) var(--rem-size-16);
        color: var(--grey-light);
        background-color: var(--white-alpha10);
        border: none;
        border-radius: 5px;
        font-size: var(--rem-size-16);
        transition: all 0.3s ease;

        @include respond-to(max-medium) {
          width: 10.625rem;
        }
        @include respond-to(medium) {
          width: 10.125rem;
        }
        &.focus {
          background-color: var(--dark-color-dark);
        }
        &__title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &__pane-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--rem-size-8) var(--rem-size-20);
        font-size: var(--rem-size-14);
        border-bottom: 1px solid var(--grey);

        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background-color: var(--grey-light);
          cursor: pointer;
        }
        .pane-row__label {
          width: 90%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      ::v-deep .idt-dropdown--pane {
        @include respond-to(max-medium) {
          width: 100%;
        }
        @include respond-to(medium) {
          width: 21.25rem;
        }
      }
    }
  }
  &__list {
    height: calc(100% - 11.5rem);
    padding-top: var(--rem-size-18);

    &__row {
      margin-top: var(--rem-size-4);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-bold);

      &__tags {
        display: flex;
        font-size: var(--rem-size-12);

        &__container {

          &--live-with-replay {
            margin-right: var(--rem-size-22);
          }
          &--no-data {
            display: none;
          }
        }
      }
      &__tag {
        margin-right: var(--rem-size-14);
        padding: var(--rem-size-1) var(--rem-size-12);
        background-color: var(--main-color);
        border-radius: 9px;

        &--talk {
          padding: var(--rem-size-2) var(--rem-size-10);
          border-radius: 4px;
        }
        &--live {
          margin-right: var(--rem-size-9);
          background-color: var(--red);
        }
        &--replay {
          margin-right: var(--rem-size-9);
          background-color: var(--black);
        }
        &--on-demand {
          margin-right: var(--rem-size-11);
          background-color: var(--main-color);
        }
      }
    }
  }
}
::v-deep .idt-dropdown {
  width: 100%;

  &--pane {
    padding: 0;
    color: var(--black);
    border: none;
    box-shadow: 2px 2px 5px var(--black-alpha20);
    overflow-y: auto;

    @include respond-to(slarge) {
      max-height: calc(100vh - 400px);
    }
  }
}
