.fo-project-section-free-view {
  display: flex;
  flex-direction: column;
  margin: 2rem 3rem;
}

.fo-project-section-free-view__title {
  margin-bottom: var(--rem-size-16);
  text-align: center;
  color: var(--grey-darkest);
}

.fo-project-section-free-view__content {
  margin-bottom: var(--rem-size-32);
}

