




























































































































































































































































































































































.disable {
  pointer-events: none;
}
.idt-fo-card {
  border-radius: 5px;

  ::v-deep &__header {
      padding: 5px;
    }
  ::v-deep &__footer {
    margin-top: 5px;
    padding: 10px;
  }
  ::v-deep &__content {
    padding: 0 10px;
  }
}
.noborder {
  .idt-fo-card {
    ::v-deep &__footer {
      border-top: 0px;
    }
  }
}
.content {
  &__tooltip {
    align-items: center;
    position: absolute;
    top: -10%;
    right: 0;
    left: 0;
    width: fit-content;
    max-width: 228px;
    height: 44px;
    margin-right: auto;
    margin-left: auto;
    color: var(--white);
    background-color: var(--black);
    border-radius: var(--global-radius);
    box-shadow: 0px 3px 6px var(--grey);

    &__text {
      max-height: var(--rem-size-20);
      font-family: var(--first-font-family);
      font-size: var(--rem-size-14);
      line-height: var(--rem-size-20);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__icon {
      padding-left: 0;
    }
  }
  &__agenda {
    align-items: center;
    max-width: none;
    margin-left: 0;

    &__item {
      justify-content: center;
      justify-self: center;
      min-height: 25px;
      color: var(--grey-darkest);

      &__left {
        display: flex;
        align-items: center;
        color: var(--grey-darkest);

        &__live {
          margin-right: 10px;
        }
        &__text {
          margin-left: 5px;
          color: var(--black);
          font-family: var(--first-font-family);
          font-size: var(--rem-size-17);
          line-height: var(--rem-size-20);
          vertical-align: bottom;
        }
      }
      &__star-button {
        width: 25px;
        height: 25px;
        color: var(--main-color);
        border-radius: 100%;
        pointer-events: all;
        z-index: 100;

        &:hover {
          cursor: pointer;
        }
        &.inactive {
          border: 1px solid var(--grey);
        }
        &.active {
          border: 1px solid var(--main-color);
        }
      }
    }
    &__description {
      align-items: center;
      justify-content: flex-start;
      height: 36px;
      margin: unset;

      &__text {
        color: var(--black);
        font-family: var(--title-font-family);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-bold);
        line-height: var(--rem-size-20);
        word-break: break-word;
      }
    }
  }
}
.card-content {
  display:flex;
  flex-direction: row;
  align-items: center;

  &__speaker-info {
    display: flex;
    flex-direction: column;
    margin-left:10px;

    &__username {
      color: var(--black);
      font: normal normal bold 14px/20px Kreon;
    }
    &__job {
      color: var(--black);
      font: normal normal normal 12px/14px Roboto;
    }
    &__multi-speaker {
      color: var(--black);
      font: normal normal bold 12px/14px Kreon;
    }
  }
}
.idt-user-avatar {
  background-color: var(--grey-light);
}
