@media only screen and (max-width: 639px) {
  .event-topics__list {
    row-gap: var(--rem-size-24);
  }
}

@media only screen and (min-width: 640px) {
  .event-topics__list {
    row-gap: var(--rem-size-48);
  }
}

.event-topics__join {
  margin-top: 20px;
  text-align: center;
}

