.idt-define-availabilities__container {
  width: 325px;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  border-radius: var(--global-radius);
}

.idt-define-availabilities__container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 var(--rem-size-25) 0 var(--rem-size-25);
  border-bottom: solid 1px var(--grey-light);
}

.idt-define-availabilities__container__header__selected {
  color: var(--black);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
}

.idt-define-availabilities__container__header__delete:hover {
  cursor: pointer;
}

.idt-define-availabilities__container__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--rem-size-15);
}

.idt-define-availabilities__container__footer__button {
  width: 280px;
  height: 35px;
  color: var(--white);
  background-color: var(--main-color);
  border: 1.5px solid var(--main-color);
  border-radius: 25px;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-24);
  cursor: pointer;
}

@media only screen and (max-width: 639px) {
  .idt-define-availabilities__select-block {
    flex: 1 1 auto;
  }
}

@media only screen and (min-width: 640px) {
  .idt-define-availabilities__select-block {
    flex: 0 0 auto;
  }
}

.idt-define-availabilities__select-block__header {
  height: 30px;
}

.idt-define-availabilities__select-block__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 53px);
  padding: 0 .5rem;
}

.idt-define-availabilities__calendar {
  padding: var(--rem-size-15) 5px var(--rem-size-15) 5px;
}

.idt-define-availabilities__calendar__table {
  width: 100%;
  padding: 0 var(--rem-size-25) 0 var(--rem-size-25);
  margin: 0;
}

.idt-define-availabilities__calendar__table.idt-define-availabilities__calendar--no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.idt-define-availabilities__calendar__month {
  min-height: 15px;
  cursor: pointer;
}

.idt-define-availabilities__calendar__month.month--next {
  text-align: right;
}

.idt-define-availabilities__calendar__month .ideacons {
  vertical-align: middle;
}

.idt-define-availabilities__calendar__body {
  border: 1px solid var(--main-color);
}

.idt-define-availabilities__calendar__current-date {
  color: var(--black);
  font-size: var(--rem-size-18);
  font-weight: var(--font-weight-bold);
}

.idt-define-availabilities__calendar__week {
  text-transform: uppercase;
}

.idt-define-availabilities__calendar__week-day {
  height: 31px;
  padding: 0;
  color: var(--black);
  background-color: var(--white);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .idt-define-availabilities__calendar__week-day {
    width: 14.28%;
  }
}

@media only screen and (min-width: 640px) {
  .idt-define-availabilities__calendar__week-day {
    width: 31px;
  }
}

.idt-define-availabilities__calendar__week {
  background-color: var(--white);
}

.idt-define-availabilities__calendar__day {
  height: 36px;
  padding: 0;
  color: var(--black);
  background-color: var(--white);
  font-size: .9rem;
  font-weight: var(--font-weight-light);
  cursor: pointer;
}

.idt-define-availabilities__calendar__day:hover {
  color: var(--white);
}

.idt-define-availabilities__calendar__day:hover__cell {
  background-color: var(--main-color-lighter);
  border-radius: 100%;
}

.idt-define-availabilities__calendar__day:not(.day--past):hover {
  background-color: var(--main-color-light);
  border-radius: 100%;
  font-weight: var(--font-weight-bold);
}

.idt-define-availabilities__calendar__day.day--past {
  color: var(--grey-light);
  background-color: var(--white);
  cursor: default;
}

.idt-define-availabilities__calendar__day.day--off {
  color: var(--grey-dark);
  background-color: var(--white);
}

.idt-define-availabilities__calendar__day.day--off:not(.day--past):hover {
  color: var(--main-color);
}

.idt-define-availabilities__calendar__day.day--today {
  color: var(--black);
  background-color: var(--white);
  border: 2px solid var(--main-color);
  border-radius: 100%;
}

.idt-define-availabilities__calendar__day.day--selected {
  color: var(--white);
  background-color: var(--main-color);
  border-radius: 100%;
  font-weight: var(--font-weight-bold);
}

.idt-define-availabilities__calendar__day.day--selecting {
  color: var(--white);
  background-color: var(--main-color-lighter);
  border-radius: 100%;
  font-weight: var(--font-weight-bold);
}

.idt-define-availabilities__calendar__day-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.idt-define-availabilities__calendar__day-cell__first-range {
  position: absolute;
  top: 5px;
  left: -6px;
  width: 2px;
  height: 2px;
  color: var(--black);
  z-index: 1000;
}

.idt-define-availabilities__calendar__day-cell__last-range {
  position: absolute;
  top: 5px;
  right: 6px;
  width: 2px;
  height: 2px;
  z-index: 1000;
  color: var(--black);
}

