






























.fo-project-bar-last-update {
  display: flex;
  justify-content: space-between;
  margin-top: var(--rem-size-16);
  margin-bottom: var(--rem-size-16);
  font-size: var(--rem-size-16);

  &__text {
    font-weight: var(--font-weight-medium);
  }

  &__date {
    font-weight: var(--font-weight-normal);
  }
}
