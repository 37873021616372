.resource-list {
  margin-bottom: 50px;
}

.resource-list__header {
  margin: 50px 0;
  color: var(--black);
  text-align: center;
}

.resource-list__title {
  font-family: var(--title-font-family);
  font-size: 2rem;
  line-height: 2.5rem;
}

.resource-list__subtitle {
  font-size: var(--rem-size-20);
}

.resource-list__wrapper {
  display: flex;
}

.resource-list__wrapper:not(.reverse) {
  flex-direction: column;
}

.resource-list__wrapper.reverse {
  flex-direction: column-reverse;
}

.resource-list__container {
  display: grid;
  gap: var(--rem-size-20);
}

@media only screen and (max-width: 479px) {
  .resource-list__container {
    grid-template-columns: 100%;
  }
}

@media only screen and (min-width: 480px)and (max-width: 639px) {
  .resource-list__container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 640px)and (max-width: 991px) {
  .resource-list__container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 992px)and (max-width: 1023px) {
  .resource-list__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1024px) {
  .resource-list__container {
    grid-template-columns: 1fr;
  }
}

.resource-list__add-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  color: var(--main-color);
  border: 1px dashed var(--main-color);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  cursor: pointer;
}

.resource-list__add-button:hover {
  color: var(--main-color-dark);
  border-color: var(--main-color-dark);
}

.resource-list__add-button:not(.reverse) {
  margin-top: var(--rem-size-20);
}

.resource-list__add-button.reverse {
  margin-bottom: var(--rem-size-20);
}

.resource-list__add-button__label {
  display: inline-block;
  margin-bottom: .5rem;
}

