.skeleton {
  width: 100%;
  max-width: 870px;
  height: 202px;
  margin: 0 0 var(--rem-size-20) 0;
  background-color: var(--white);
  border-radius: var(--global-radius);
}

.skeleton.mobile {
  display: inline-block;
  max-width: 275px;
  height: 382px;
}

.skeleton__image {
  position: relative;
  width: 275px;
  height: 202px;
  max-width: 275px;
  border-right: solid 2px var(--grey-light);
  border-radius: var(--global-radius) 0 0 var(--global-radius);
}

.skeleton__image.mobile {
  height: 144px;
  border-bottom: solid 2px var(--grey-light);
  border-right: none;
  border-radius: var(--global-radius) var(--global-radius) 0 0;
}

.skeleton__image__favorite {
  position: absolute;
  top: var(--rem-size-10);
  right: var(--rem-size-10);
  width: var(--rem-size-32);
  height: var(--rem-size-32);
  background-color: var(--grey);
  border-radius: 100%;
  animation: glowing 1300ms infinite;
}

.skeleton__image__reward {
  position: absolute;
  bottom: var(--rem-size-10);
  left: var(--rem-size-10);
  width: 88px;
  height: var(--rem-size-24);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__image__duration {
  position: absolute;
  right: var(--rem-size-10);
  bottom: var(--rem-size-10);
  width: 56px;
  height: var(--rem-size-24);
  margin-top: 5px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations {
  margin: 7px 0 0 var(--rem-size-20);
  padding-left: 0;
}

.skeleton__informations__date {
  width: 80px;
  height: var(--rem-size-10);
  margin-bottom: var(--rem-size-16);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__title {
  display: flex;
  align-items: center;
  width: 180px;
  height: var(--rem-size-16);
  margin-bottom: var(--rem-size-12);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__title.mobile {
  margin-bottom: var(--rem-size-10);
}

.skeleton__informations__description {
  display: flex;
  width: 250px;
  height: var(--rem-size-10);
  margin-bottom: var(--rem-size-8);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__description.mobile {
  margin-bottom: var(--rem-size-15);
}

.skeleton__informations__description2 {
  display: flex;
  width: 112px;
  height: var(--rem-size-10);
  margin-bottom: var(--rem-size-22);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__description2.mobile {
  margin-bottom: var(--rem-size-30);
}

.skeleton__informations__separator {
  width: 275px;
  margin: var(--rem-size-8) 0 var(--rem-size-15) -20px;
  border-top: solid 1px var(--grey-light);
}

.skeleton__informations__speaker-info {
  display: flex;
  padding: 5px 0 20px 0;
  border-radius: var(--global-radius);
}

.skeleton__informations__speaker-info.mobile {
  margin: 0;
  padding: var(--rem-size-8) 0 var(--rem-size-10) 0;
  border-radius: 0 0 5px 5px;
}

.skeleton__informations__speaker-info__avatar {
  width: var(--rem-size-32);
  height: var(--rem-size-32);
  background-color: var(--grey);
  border-radius: 100%;
  animation: glowing 1300ms infinite;
}

.skeleton__informations__speaker-info__mentor {
  display: flex;
  flex-direction: column;
  margin-left: var(--rem-size-10);
}

.skeleton__informations__speaker-info__mentor__username {
  width: 80px;
  height: 10px;
  margin-bottom: var(--rem-size-8);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__speaker-info__mentor__job-company {
  width: 200px;
  height: 10px;
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__track-tags {
  display: flex;
  flex-direction: row;
}

.skeleton__informations__track-tags__track {
  width: 59px;
  height: var(--rem-size-24);
  margin-right: var(--rem-size-8);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

.skeleton__informations__track-tags__tag {
  width: 48px;
  height: var(--rem-size-24);
  margin-right: var(--rem-size-8);
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }

  25% {
    background-color: var(--grey);
  }

  50% {
    background-color: var(--grey-light);
  }

  75% {
    background-color: var(--grey-lighter);
  }

  100% {
    background-color: var(--grey-light);
  }
}

