.insight-card {
  flex: 0 1 auto;
  position: relative;
  margin-bottom: 2.125rem;
  background-color: var(--white);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.14);
}

@media only screen and (max-width: 1023px) {
  .insight-card {
    width: calc(100vw - 48px);
  }
}

@media only screen and (min-width: 1024px) {
  .insight-card {
    width: 911px;
  }
}

.insight-card:before {
  content: "";
  display: block;
  position: absolute;
  top: 300px;
  background-color: var(--grey-lightest);
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1023px) {
  .insight-card:before {
    left: -1.075rem;
    width: 16px;
    height: 16px;
    border: 1px solid var(--grey);
  }
}

@media only screen and (min-width: 1024px) {
  .insight-card:before {
    left: -5.5rem;
    width: 22px;
    height: 22px;
    border: 2px solid var(--grey);
  }
}

.insight-card__banner {
  display: block;
  height: 300px;
  background-color: var(--grey-lightest);
  background-position: center;
  background-size: cover;
}

.insight-card__banner__container {
  position: relative;
}

.insight-card__status {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  font-weight: 700;
  text-transform: uppercase;
}

.insight-card__status__content {
  padding: .25rem .5rem;
  color: var(--secondary-color-dark);
  background-color: var(--secondary-color-lightest);
}

.insight-card__actions {
  position: absolute;
  right: 1rem;
  bottom: 1.25rem;
}

.insight-card__actions .button {
  margin: 0;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.insight-card__actions .button i {
  font-size: 1.1rem;
  vertical-align: text-bottom;
}

.insight-card__content {
  color: var(--grey-dark);
}

@media only screen and (max-width: 1023px) {
  .insight-card__content {
    padding: 1rem 1.25rem;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .insight-card__content {
    padding: 2rem;
    font-size: 1.1rem;
  }
}

.insight-card__title {
  display: inline-block;
  color: var(--grey-darker);
  font-size: 1.5rem;
  font-weight: 300;
}

.insight-card__info {
  margin-bottom: var(--rem-size-8);
  font-weight: var(--font-weight-medium);
}

.insight-card__info__date,
.insight-card__info__author {
  color: var(--main-color);
}

.insight-card__info__date.pointable,
.insight-card__info__author.pointable {
  cursor: pointer;
}

.insight-card__info__pipe {
  color: var(--grey);
}

.insight-card__text {
  margin-top: var(--rem-size-8);
}

.insight-card__text:not(.article) p {
  display: inline;
}

.insight-card__text:not(.article) img {
  display: none !important;
  width: 0;
  height: 0;
  visibility: hidden;
}

.insight-card__text table {
  display: block;
  max-width: 100%;
  overflow-x: auto;
}

.insight-card.no-illustration .insight-card__actions {
  top: 16px;
}

.insight-card.no-illustration:before {
  top: 50%;
}

