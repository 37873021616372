.pre-conference-canceled-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--grey-light);
}

.pre-conference-canceled-card__content {
  color: var(--grey-dark);
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .pre-conference-canceled-card__content {
    transform: scale(0.6);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .pre-conference-canceled-card__content {
    transform: scale(0.9);
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .pre-conference-canceled-card__content {
    transform: scale(0.7);
  }
}

.pre-conference-canceled-card .date__part {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.pre-conference-canceled-card .date__calendar {
  margin-right: 1rem;
  padding: 0 1.5rem;
}

.pre-conference-canceled-card .date__calendar .idt-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pre-conference-canceled-card .date__day {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}

.pre-conference-canceled-card .date__month {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 55%);
  font-size: .8rem;
  font-weight: 300;
  text-transform: uppercase;
}

.pre-conference-canceled-card .date__text {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
}

.pre-conference-canceled-card .date__hours {
  font-family: var(--title-font-family);
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1;
}

.pre-conference-canceled-card .message {
  font-family: var(--title-font-family);
  font-size: 2.6rem;
  font-weight: 300;
}

.pre-conference-canceled-card .message__container {
  position: relative;
  width: 600px;
  margin-top: 4.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--grey-dark);
  font-size: 2rem;
}

.pre-conference-canceled-card .message__text {
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0 1.25rem;
  background-color: var(--grey-light);
  font-size: 1.4rem;
  font-weight: 300;
  transform: translate(-50%, -50%);
}

