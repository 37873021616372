




























































































































































































































.room {
  &__messages {
    &__container {
      color: red;
      &:hover {
        .room-message__actions {
          display: inline-block;
        }
      }
    }
  }
}
.room-message {
  flex: 0 1 auto;
  position: relative;
  max-width: var(--chat-message-max-width);
  background-color: var(--white);
  border-radius: var(--chat-message-radius);
  font-size: 0.825rem;
  line-height: 1.4;

  &__date {
    margin-bottom: 1.1rem;
    color: var(--grey-dark);
    font-size: 0.75rem;
    text-align: center;
  }
  &__container {
    display: flex;
    position: relative;
    margin-bottom: 1.1rem;
    align-items: center;

    &.ltr {
      justify-content: flex-start;
    }
    &.rtl {
      justify-content: flex-end;
    }
    &:not(.notice) {
      padding: 0 1.75rem 0 0.8125rem;
    }
  }
  &__user {
    flex: 0 0 auto;
    padding: 0.5rem 0.8rem 0 0;

    &__avatar {
      flex: 0 1 auto;
      cursor: pointer;
    }
  }
  &__actions {
    vertical-align: middle;

    .menu.vertical li a {
      display: flex;
      align-items: center;
    }
    .glyphicons-more {
      font-size: 1.5rem;
    }
  }
  &__header {
    position: relative;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.3125rem 0.625rem;
  }
  &__name {
    margin-right: 0.5rem;
    color: var(--grey-dark);
    cursor: pointer;
  }
  &__time {
    color: var(--main-color);
  }
  &__content {
    color: var(--grey-darkest);
    padding: 0.3125rem 0.625rem 0.625rem;
    white-space: pre-line;
    word-break: break-word;

    a {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
  &__delete {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    color: var(--grey-darker);
    background-color: var(--white);
    border: 1px solid var(--grey-darker);
    border-radius: 100%;
    font-size: 0.5rem;
    text-align: center;
    line-height: 16px;
    transition: all 0.3s ease;
    transform: translate(50%, -50%);

    &:hover {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }
  &__file {
    display: block;
    position: relative;
    text-align: left;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+50,0.5+100 */
      /* FF3.6-15 */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(
          startColorstr='#00000000', endColorstr='#80000000', GradientType=0); /* IE6-9 */
    }
  }
  &.file,
  &.file-and-content {
    .room-message {
      &__header {
        border-radius: 0;
        overflow: hidden;
      }
      &__info {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin: 0;
        z-index: 1;
      }
      &__name,
      &__time {
        color: var(--white);
      }
    }
    & ::v-deep .file-preview {
      display: block;
      min-width: 200px;

      &__file {
        width: 100%;
        height: 150px;
      }

      &__figure {
        width: 100%;
        height: 150px;
        background-color: var(--white);
        background-size: cover;
      }
    }
  }
  &.file {
    .room-message__file {
      border-radius: var(--chat-message-radius);
    }
  }
  &.file-and-content {
    .room-message__file {
      border-radius: var(--chat-message-radius) var(--chat-message-radius) 0 0;
    }
  }
  &.personal {
    flex: 0 0 auto;
    color: var(--white);
    background-color: var(--main-color);
    max-width: calc(var(--chat-message-max-width) - 20px);

    .room-message {
      &__content {
        color: var(--white);

        & ::v-deep a {
          color: var(--white);
          text-decoration: underline;
        }
      }
      &__name {
        color: var(--white);
      }
      &__time {
        color: var(--white);
      }
    }
  }
  &.notice {
    width: 100%;
    max-width: 100%;
    padding-top: 0.3125rem;
    background-color: var(--grey-darker-alpha15);
    border-radius: 0;
    font-size: 0.75rem;
    text-align: center;

    .room-message__content {
      color: var(--grey-darker);
    }

    &.danger {
      background-color: var(--secondary-color-alpha15);

      .room-message__content,
      .room-message__content a {
        color: var(--secondary-color);
      }
      .room-message__content a {
        text-decoration: none;
        pointer-events: none;
      }
    }

    &.success {
      background-color: var(--green-alpha15);

      .room-message__content,
      .room-message__content a {
        color: var(--green);
      }
      .room-message__content a {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}

.message-profile {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1000;
}
