


























































































































.one-choice-field {
  max-width: 500px;
  padding: 0.2rem 0;
  background: none;
  box-shadow: none;
  border: none;
  text-align: left;

  ::v-deep .box {
    background: var(--white);
  }

  &__container {
    width: 100%;
  }

  &__separator {
    position: relative;
    right: 10px;
    border-left: 1px solid var(--secondary-color);

    .separator-error {
      margin-left: 10px;
    }
  }

  &__conditional-wrapper {
    margin-bottom: 20px;
    background-color: var(--white);
    border: 1px solid var(--grey);
    border-radius: var(--global-radius);
  }
}
