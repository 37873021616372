.fo-project-section-media-view {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);
}

.fo-project-section-media-view__title {
  color: var(--grey-darkest);
}

.fo-project-section-media-view__carousel {
  margin-bottom: var(--rem-size-32);
}

