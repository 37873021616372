@media only screen and (max-width: 479px) {
  .idt-menu-mobile-toggle {
    flex: 0 0 auto;
  }
}

@media only screen and (max-width: 1023px) {
  .idt-menu-mobile-toggle {
    display: block;
    position: relative;
    width: calc(var(--menu-height)*.75);
    height: var(--menu-height);
    margin-left: auto;
    color: var(--main-color);
  }

  .idt-menu-mobile-toggle.is-active span:nth-child(1) {
    transform: translateX(-50%) translateY(11px) rotate(45deg);
  }

  .idt-menu-mobile-toggle.is-active span:nth-child(2) {
    opacity: 0;
  }

  .idt-menu-mobile-toggle.is-active span:nth-child(3) {
    transform: translateX(-50%) translateY(-11px) rotate(-45deg);
  }

  .idt-menu-mobile-toggle span {
    display: block;
    position: absolute;
    left: 50%;
    width: 35px;
    height: 3px;
    background-color: currentColor;
    transition: all 86ms ease-out;
    transform: translateX(-50%);
  }

  .idt-menu-mobile-toggle span:nth-child(1) {
    top: calc(50% - 12px);
  }

  .idt-menu-mobile-toggle span:nth-child(2) {
    top: calc(50% - 1px);
  }

  .idt-menu-mobile-toggle span:nth-child(3) {
    top: calc(50% + 10px);
  }
}

@media only screen and (min-width: 1024px) {
  .idt-menu-mobile-toggle {
    display: none;
  }
}

.idt-menu-mobile-toggle__pill {
  transform: translate(0%, 30%);
}

.fade-mm-enter-active,
.fade-mm-leave-active {
  transition: all .3s ease;
}

.fade-mm-enter,
.fade-mm-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

