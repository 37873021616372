.idt-separator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 100%;
  margin: 10px 0px;
}

.idt-separator__label {
  position: relative;
  padding: .1rem 1rem;
  background-color: var(--main-color);
  color: var(--white);
  border-radius: 3rem;
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.idt-separator__line {
  position: absolute;
  height: 1px;
  width: 100%;
  border-top: 1px solid var(--main-color);
}

.idt-separator__line--grey {
  border-color: var(--grey-light);
}

.idt-separator--no-margin {
  height: 0;
  margin: 0;
}

