.remaining-credit-box {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 2px;
  color: var(--secondary-color);
  background-color: var(--secondary-color-alpha20);
  font-size: 1rem;
}

.remaining-credit-box__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.remaining-credit-box__text {
  width: 100%;
  background-color: var(--white);
  font-weight: var(--font-weight-normal);
  text-align: center;
  line-height: 46px;
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .remaining-credit-box__text {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .remaining-credit-box__text {
    padding: 0 20px;
  }
}

