.idt-radiobutton {
  margin-bottom: 7px;
  padding: .5rem;
  background: var(--white);
  border: 1px solid var(--grey);
  border-radius: 20px;
}

.idt-radiobutton--no-border {
  box-shadow: none !important;
  border: 0 !important;
}

.idt-radiobutton--no-padding {
  padding: 0;
}

.idt-radiobutton--checked {
  box-shadow: 0px 2px 3px rgba(0,0,0,.15);
  border-color: rgba(0,0,0,0);
}

.idt-radiobutton--checked .content {
  font-weight: var(--font-weight-medium);
}

.idt-radiobutton input {
  display: none;
}

.idt-radiobutton input.error+label .box {
  border: 1px solid var(--red);
}

.idt-radiobutton input+label {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 22px;
  min-width: 100px;
  margin: 0;
  color: var(--text-color, var(--grey-darker));
  font-weight: var(--font-weight-normal);
}

.idt-radiobutton input+label .box {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  vertical-align: top;
  margin-right: 7px;
  border: 1px solid var(--text-color, var(--grey));
  border-radius: 50%;
}

.idt-radiobutton input+label .content {
  display: inline-block;
  flex-grow: 1;
}

.idt-radiobutton input+label .content ::v-deep p:last-child {
  margin-bottom: 0;
}

.idt-radiobutton input+label .idt-icon {
  color: rgba(0,0,0,0);
}

.idt-radiobutton input+label .idt-icon ::v-deep .ideacon {
  position: absolute;
}

.idt-radiobutton input:checked+label .box {
  background-color: var(--white);
  border: 1px solid var(--main-color);
}

.idt-radiobutton input:checked+label .idt-icon {
  color: var(--main-color);
}

.idt-radiobutton__slot-end {
  flex-grow: 0;
}

