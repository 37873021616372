.bo-accounts__password-rule {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bo-accounts__password-rule__label {
  width: 210px;
}

.bo-accounts__password-rule__field {
  width: 100px;
  margin-left: 1rem;
}

.bo-accounts__password-rule__length {
  width: 60px;
  height: 34px;
  margin: 0 0 0 .75rem;
  padding: 0 0 0 .75rem;
  text-align: center;
  line-height: 34px;
}

.bo-accounts__password-rule__warning {
  display: flex;
  padding-top: 5px;
  color: var(--secondary-color);
  font-size: .9rem;
}

.bo-accounts__password-rule__warning .ideacons {
  padding-top: 3px;
  padding-right: 5px;
}

