.menu-conference {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--grey-lighter);
  text-align: left;
  overflow-y: auto;
}

@media only screen and (max-width: 991px) {
  .menu-conference {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh)*100 - 70px);
  }
}

.menu-conference__content {
  overflow-y: auto;
}

@media only screen and (max-width: 991px) {
  .menu-conference__content {
    height: calc(100% - 40px);
  }
}

@media only screen and (min-width: 992px) {
  .menu-conference__content {
    max-height: calc(80vh - 40px);
    padding-bottom: 40px;
  }
}

.menu-conference__category {
  background-color: var(--white);
}

.menu-conference__category__title {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  padding: .2rem 1.25rem;
  color: var(--black);
  background-color: var(--grey-light);
  border-bottom: 1px solid var(--grey);
  font-size: .8rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  z-index: 1;
}

.menu-conference__category__title__label {
  margin-left: var(--rem-size-8);
}

.menu-conference__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.menu-conference__footer__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: var(--white);
  font-size: .75rem;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

