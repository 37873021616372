.idt-status-cell {
  display: inline-block;
  width: 100%;
  padding: .5rem;
  border-radius: var(--global-radius);
  font-size: .75rem;
  text-align: center;
  line-height: 1.2;
}

.idt-status-cell--default {
  color: var(--grey-darkest);
  background-color: var(--grey-lightest);
}

.idt-status-cell--success {
  color: var(--success-color-darker);
  background-color: var(--success-color-lightest);
}

.idt-status-cell--danger {
  color: var(--danger-color-dark);
  background-color: var(--danger-color-lightest);
}

.idt-status-cell--warning {
  color: var(--warning-color-darker);
  background-color: var(--warning-color-lightest);
}

.idt-status-cell--primary {
  color: var(--main-color-darker);
  background-color: var(--main-color-lightest);
}

.idt-status-cell--live {
  color: var(--color-live);
  background-color: var(--color-live-lightest);
}

.idt-status-cell--info {
  color: var(--info-color-darker);
  background-color: var(--info-color-lightest);
}

