.meeting-room {
  --menu-height: 70px;
  --video-height: 48vw;
  --menu-and-footer-height: calc( var(--menu-height) + var(--conference-footer-height) );
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh)*100 - var(--menu-height));
  background: var(--grey-lightest);
}

.meeting-room.cancelled {
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .meeting-room:not(.cancelled) {
    flex-flow: column nowrap;
  }
}

@media only screen and (min-width: 992px) {
  .meeting-room {
    flex-flow: row;
  }
}

.meeting-room__main {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .meeting-room__main {
    flex: 0 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .meeting-room__main {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.meeting-room__main__container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.meeting-room__sidebar {
  height: calc(100vh - 70px);
  background: var(--white);
}

@media only screen and (max-width: 991px) {
  .meeting-room__sidebar {
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 991px)and (min-aspect-ratio: 13/9) {
  .meeting-room__sidebar {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .meeting-room__sidebar {
    flex: 0 0 auto;
    width: 440px;
  }
}

@media only screen and (min-width: 640px) {
  .meeting-room__sidebar ::v-deep .meeting-sidebar {
    border-left: 1px solid var(--grey-lighter);
  }
}

@media only screen and (min-width: 992px) {
  .meeting-room__sidebar ::v-deep .meeting-sidebar {
    flex-grow: 1;
  }
}

