.account-menu {
  display: inline-block;
  position: relative;
}

.account-menu__toggle {
  padding: 0 var(--rem-size-12);
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .show-on-mobile {
    display: none !important;
  }
}

