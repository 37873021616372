

















































































































































































@import '@/styles/mixins/index';

.custom-rtmp-publisher-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--grey-darkest);
  background-color: var(--grey-lighter);
}

.crpc {
  &__admin-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 75%;

    @include respond-to(small-xlarge) {
      flex-direction: column;
      height: 100%;

      .crpc__admin-panel__settings {
        overflow-y: auto;
      }

      .crpc__admin-panel__settings, .crpc__admin-panel__live-preview {
        width: 100%;
      }
    }

    &__settings {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: 100%;
      margin: 10px;
      background-color: var(--white);
      border-radius: var(--global-radius);
      color: var(--grey-darkest);

      &__form {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        height: 100%;
        width: 100%;
        padding: 1rem;

        &__title {
          margin: 20px 0;
          color: var(--black);
          font-size: var(--rem-size-22);
          font-weight: var(--font-weight-medium);
          text-align: center;
          text-overflow: ellipsis;
        }

        &__label, ::v-deep &__one-link .content {
          color: var(--black);
          font-size: var(--rem-size-14);
        }

        &__label {
          font-weight: var(--font-weight-medium);
        }

        ::v-deep &__one-link .content {
          font-weight: var(--font-weight-normal);
        }

        .idt-input-container {
          padding-bottom: 10px;
        }

        &__field {
          &__link {
            margin: 0 5px;
            color: var(--main-color);
            font-weight: var(--font-weight-bold);
            cursor: pointer;
          }

          &__textarea ::v-deep textarea {
            border-radius: var(--global-radius);
          }

          &__access-button {
            height: 35px;
            margin-top: 1rem;
            padding: 0.7rem 1rem;
          }

          &__update-button {
            margin-top: var(--rem-size-20);
            margin-bottom: var(--rem-size-10);
            text-align: center;

            button {
              width: 90px;
              height: 35px;
              margin-left: 0.5rem;
              color: var(--white);
              background-color: var(--main-color);
              border-radius: 23px;
              font-weight: var(--font-weight-bold);
              text-align: center;
              cursor: pointer;
            }
          }
        }

        ::v-deep .idt-text-copy.one-link {
          height: 100px;
        }
      }
    }

    &__live-preview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      height: 100%;
      margin: 5px;

      &__title {
        margin-bottom: 10px;
        color: var(--grey-darkest);
        font-size: 1.125rem;
        font-weight: var(--font-weight-medium);
      }

      &__container {
        width: 100%;
      }
    }
  }
}
