.idt-input-number-container__erroneous-label {
  color: var(--secondary-color) !important;
}

.idt-input[disabled],
.idt-input[readonly] {
  background-color: var(--grey-lighter);
  user-select: none;
}

.required-field input {
  border: 1px solid var(--secondary-color);
}

