.bo-jury-modal {
  padding: var(--rem-size-32) var(--rem-size-16) 0 var(--rem-size-16);
}

.bo-jury-modal h4 {
  margin-bottom: var(--rem-size-24);
}

.bo-jury-modal__enabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bo-jury-modal__image-import {
  margin-bottom: var(--rem-size-24);
}

.bo-jury-modal__image-import__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: var(--color-white);
  border: 1px dashed var(--main-color);
  border-radius: 50%;
  cursor: pointer;
}

.bo-jury-modal__image-import__button.error {
  border-color: var(--secondary-color);
}

.bo-jury-modal__image-import__image {
  border-radius: 50%;
}

.bo-jury-modal__image-import__image:hover {
  opacity: .4;
  cursor: pointer;
}

.bo-jury-modal__image-import__error {
  color: var(--secondary-color);
  font-size: var(--rem-size-12);
  font-weight: 500;
}

.bo-jury-modal__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--rem-size-16);
}

.bo-jury-modal__buttons button {
  width: 6rem;
  margin-left: var(--rem-size-16);
}

