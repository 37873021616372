





































@import '@/styles/mixins/index';

.gamification-points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--grey-light);
  border-radius: 50px;
  color: var(--black);
  font-weight: var(--font-weight-bold);
  padding: 0.25rem 1rem;
  background-color: var(--white);

  @include respond-to(max-slarge) {
    font-size: var(--rem-size-14);
    img {
      height: 12px;
      width: 12px;
    }
  }
  @include respond-to(slarge) {
    font-size: var(--rem-size-18);
    img {
      height: 24px;
      width: 24px;
    }
  }

  &__value {
    margin-left: 1rem;
  }
  &.active {
    color: var(--main-color);
  }
}
