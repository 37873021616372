$priority-palette:
  'danger' '--danger-color',
  'alert' '--danger-color', // only to overwride foundation style
  'info' '--info-color',
  'success' '--success-color',
  'warning' '--warning-color';

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--rem-size-8);
  padding: var(--rem-size-8) var((--rem-size-12));
  color: var(--white);
  background-color: var(--main-color);
  border: 1px solid transparent;
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  cursor: pointer;
  -webkit-appearance: none;

  // Colors
  @each $className, $bgColor, $textColor in $priority-palette {
    &.#{$className} {
      color: var(--white);
      background-color: var(#{$bgColor});

      &:hover {
        color: var(--white);
        background-color: var(#{$bgColor}-dark);
      }
    }
  }
  &.secondary {
    color: var(--grey-darkest);
    background-color: var(--grey);

    &:hover {
      color: var(--white);
      background-color: var(--grey-dark);
    }
  }

  // Others
  &:not(.no-margin) {
    margin: 0 0 1rem 0;
  }
  &:hover,
  &:focus {
    color: var(--white);
    background-color: var(--main-color-dark);
  }
  &.small {
    padding: var(--rem-size-6) var(--rem-size-8);
    font-size: var(--rem-size-12);
  }
  &.smaller {
    font-size: 0.875rem;
  }
  &.larger {
    font-size: 1rem;
    padding: 0 1rem;
  }
  &.large {
    padding: var(--rem-size-10) var(--rem-size-16);
    font-size: var(--rem-size-18);
  }
  &.min-size {
    min-width: 128px;
  }
  &.rounded {
    border-radius: 25px;
  }
  &.square {
    border-radius: 0;
  }
  &.bold {
    font-weight: var(--font-weight-bold);
  }
  &.text-left {
    text-align: left;
  }
  &.expanded {
    display: inline-flex;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  &.hollow {
    color: var(--main-color);
    border: 1px solid var(--main-color);

    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }
    &:hover,
    &:focus {
      border: 1px solid var(--main-color-dark);
      color: var(--main-color-dark);
    }
  }
  &.secondary.hollow {
    color: var(--grey-darker);
    border-color: var(--grey-darker);

    &:hover {
      color: var(--black);
      border-color: var(--black);
    }
  }
  &.disabled,
  &[disabled] {
    opacity: 0.25;
    cursor: not-allowed;

    &:not(.hollow),
    &:hover:not(.hollow),
    &:focus {
      color: var(--white);
      background-color: var(--main-color);
    }
  }
  &.dropdown {
    &:after {
      content: '';
      display: inline-block;
      position: relative;
      top: 0.4em;
      float: right;
      width: 0;
      height: 0;
      margin-left: 1em;
      border: inset 0.4em;
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: var(--white) transparent transparent;
    }
  }
  &.uppercase{
    text-transform: uppercase;
  }
  &.no-margin {
    margin: 0;
  }
  &.grey{
    color: var(--black) !important;
    background-color: var(--grey-light);

    &:hover {
      background-color: var(--main-color);
      color: var(--white) !important;
    }
  }
  &.danger {
    background-color: var(--danger-color);

    &:hover {
      color: var(--white) !important;
      background-color: var(--danger-color-dark);
    }
  }
  &--loading,
  &--loading-small {
    position: relative;
    color: transparent !important;
    cursor: auto;
    user-select: none;
    pointer-events: none;
    transition: none;

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - .75em);
      left: calc(50% - .75em);
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      border: 0.25rem solid var(--grey-lighter);
      border-top-color: var(--secondary-color);
      animation: spin 1s infinite linear;
    }
  }
  &--loading-small::after {
    top: calc(50% - .6em);
    left: calc(50% - .6em);
    width: 1.2em;
    height: 1.2em;
  }
}
