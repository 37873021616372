.yA path,
.grid path,
.yA .tick line {
  stroke: rgba(0,0,0,0);
}

.xA path {
  stroke: rgba(0,0,0,0);
}

.grid line,
.xA .tick line {
  opacity: .3;
}

text {
  color: var(--grey);
}

