.idt-user-booking {
  position: relative;
  margin: 0 -1rem;
  border-bottom: 1px solid var(--grey-lighter);
}

.idt-user-booking__header {
  padding: 1rem 3rem;
}

.idt-user-booking__header__title {
  margin-bottom: .5rem;
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-user-booking__header__subtitle {
  margin-bottom: .5rem;
}

.idt-user-booking__next-timeslot-card,
.idt-user-booking__no-timeslot-card {
  position: absolute;
  top: 228px;
  left: 19%;
}

.idt-user-booking__calendar {
  display: flex;
  user-select: none;
}

.idt-user-booking__calendar-col {
  flex: 1;
  background-color: var(--grey-lightest);
}

.idt-user-booking__calendar-col--navigation {
  flex: 0;
  min-width: 4.5rem;
}

.idt-user-booking__calendar-col__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  padding: 1rem;
  background-color: var(--white);
  border-top: 1px solid var(--grey-lighter);
  border-bottom: 1px solid var(--grey-lighter);
}

.idt-user-booking__calendar-col__header--nav {
  cursor: pointer;
}

.idt-user-booking__calendar-col__header__weekday {
  font-size: var(--rem-size-14);
}

.idt-user-booking__calendar-col__header__day {
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
}

.idt-user-booking__calendar-col__header.first-page {
  cursor: default;
}

.idt-user-booking__calendar-col__slots {
  padding: .5rem .25rem;
}

.idt-user-booking__calendar-col__slots>*+* {
  margin-top: .5rem;
}

.idt-user-booking__calendar-col__slot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.2rem;
}

.idt-user-booking__calendar-col__slot,
.idt-user-booking__calendar-col__empty-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.idt-user-booking__calendar-col__slot {
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  border-radius: .5rem;
  color: var(--black);
  font-size: var(--rem-size-14);
  cursor: pointer;
}

.idt-user-booking__calendar-col__empty-slot {
  width: 1.5rem;
  height: 2px;
  border: 1px solid var(--grey-light);
}

.idt-user-booking__calendar__view-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem 1rem 1rem;
  background-color: var(--grey-lightest);
}

.idt-user-booking__calendar__view-more__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34rem;
  height: 2.2rem;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  border-radius: .5rem;
  color: var(--black);
  font-size: var(--rem-size-14);
  cursor: pointer;
}

.idt-user-booking__attendees {
  padding: 2rem 3rem;
}

.idt-user-booking__attendees__remove-button {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0;
}

.idt-user-booking .slot-button:hover,
.idt-user-booking .slot-button.selected {
  background-color: var(--main-color-lighter);
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

