.idt-profile-custom {
  display: flex;
  flex-flow: row wrap;
}

.idt-profile-custom .idt-profile__input-section {
  flex: 1 1 100%;
  margin: 10px 0;
  padding: 20px;
  background-color: var(--main-color-lightest);
  border-radius: 15px;
}

.idt-profile-custom .idt-profile__input-section__input {
  margin-bottom: 0;
}

.idt-profile-custom__choice-list {
  margin-left: 0;
}

.idt-profile-custom__choice-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  list-style-type: none;
}

.idt-profile-custom__choice-list li input[type=checkbox],
.idt-profile-custom__choice-list li input[type=radio] {
  height: 20px;
  width: 20px;
}

.idt-profile-custom .idt-profile__input-section__input>label>p {
  margin-bottom: 10px;
  word-break: break-all;
}

.idt-profile-custom .idt-profile__input-section__input>label.required::after {
  display: none;
}

.idt-profile-custom .idt-profile__input-section__input>label.required>p:first-child::after {
  content: " *";
}

.idt-profile-custom .idt-radiobutton,
.idt-profile-custom .idt-checkbox {
  --text-color: var(--black);
  background: none;
}

.idt-profile-custom .idt-radiobutton input+label .box,
.idt-profile-custom .idt-checkbox input+label .box {
  background-color: var(--white);
  border-color: var(--grey-dark);
}

.idt-profile-custom .idt-textarea-container {
  padding-bottom: .5rem !important;
}

.idt-profile-custom .idt-select-container {
  padding-bottom: 0;
}

.idt-profile-custom .idt-select-container .v-select {
  background-color: var(--white);
}

.idt-profile-custom .idt-profile-upload-file {
  margin-top: 1rem;
}

