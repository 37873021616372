.submenu-account-info {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: var(--rem-size-5) 1rem;
  background-color: var(--grey-lightest);
}

@media only screen and (max-width: 991px) {
  .submenu-account-info {
    justify-content: space-between;
    text-align: left;
  }
}

@media only screen and (min-width: 992px) {
  .submenu-account-info {
    justify-content: center;
    text-align: center;
  }
}

.submenu-account-info:not(.no-ticket) {
  color: var(--white);
}

.submenu-account-info.no-ticket {
  color: var(--grey-darker);
}

.submenu-account-info__connected {
  font-size: var(--rem-size-12);
  font-style: italic;
  line-height: var(--rem-size-18);
}

.submenu-account-info__name {
  flex: 1 0 auto;
  max-width: 170px;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-21);
  overflow: hidden;
}

.submenu-account-info__ticket {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .submenu-account-info__ticket {
    justify-content: center;
  }
}

.submenu-account-info__ticket__title {
  max-width: 125px;
  margin-left: .375rem;
  font-size: .75rem;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .submenu-account-info__avatar {
    display: none;
  }
}

