.resource {
  display: flex;
  align-items: stretch;
  min-height: 150px;
  background-color: var(--main-color-alpha10);
  border-radius: var(--global-radius);
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .resource {
    flex-direction: column;
  }
}

.resource__img-container {
  flex: 0 0 auto;
  width: var(--resource-img-width);
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 1023px) {
  .resource__img-container {
    width: 100%;
    height: 150px;
  }
}

.resource__content {
  flex: 1 1 auto;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .resource__content {
    padding: 20px 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .resource__content {
    padding: 30px 0 30px 3rem;
  }
}

.resource__edit {
  position: absolute;
  left: .75rem;
  color: var(--main-color);
  font-size: var(--rem-size-14);
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .resource__edit {
    top: .5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .resource__edit {
    top: .75rem;
  }
}

.resource__edit:hover {
  color: var(--main-color-dark);
}

.resource__name,
.resource__description {
  color: var(--black);
}

.resource__name {
  font-family: var(--title-font-family);
}

@media only screen and (max-width: 1023px) {
  .resource__name {
    font-size: var(--rem-size-20);
  }
}

@media only screen and (min-width: 1024px) {
  .resource__name {
    font-size: var(--rem-size-24);
  }
}

@media only screen and (max-width: 1023px) {
  .resource__description {
    font-size: var(--rem-size-14);
  }
}

.resource__cta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

@media only screen and (max-width: 1023px) {
  .resource__cta {
    width: 100%;
    padding: 1rem 3rem;
  }
}

@media only screen and (min-width: 1024px) {
  .resource__cta {
    padding: 0 3rem;
  }
}

.resource__cta .button {
  min-width: 132px;
  margin: 0;
  text-transform: uppercase;
}

