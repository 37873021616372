.idt-dropdown {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

.idt-dropdown--pane {
  display: block;
  position: fixed;
  width: auto;
  min-width: 150px;
  max-height: 400px;
  overflow: auto;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,.2);
  font-size: 1rem;
  z-index: 100;
  visibility: hidden;
}

.idt-dropdown--pane.show {
  visibility: visible;
}

.idt-dropdown--pane .menu a {
  padding: .7rem .65rem;
}

