.fo-comment-input {
  display: flex;
  gap: var(--rem-size-16);
  position: relative;
  margin: var(--rem-size-8);
  border-radius: var(--global-radius);
}

.fo-comment-input__avatar {
  flex: 0 0 auto;
}

.fo-comment-input__input {
  width: 100%;
  margin: 0 !important;
  padding: var(--rem-size-8) var(--rem-size-16) !important;
  color: var(--grey-darkest);
  border: var(--rem-size-1) solid var(--grey-light);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);
}

.fo-comment-input__input:focus {
  border-color: var(--main-color);
}

