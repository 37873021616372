.idt-ticketing-bo-general .legend {
  width: 220px;
  text-align: center;
}

.idt-ticketing-bo-general .legend hr {
  height: 1px;
  background-color: var(--grey);
  border: none;
  margin: 15px auto 20px;
}

.idt-ticketing-bo-general .legend hr:first-child {
  margin: 62px auto 0 auto;
}

.idt-ticketing-bo-general .legend hr.bottom-name {
  margin: 0 auto 20px auto;
}

.idt-ticketing-bo-general .legend-section {
  padding: 20px 0;
  border-bottom: 1px solid var(--grey);
}

.idt-ticketing-bo-general .legend-section:first-child {
  border-top: 1px solid var(--grey);
}

.idt-ticketing-bo-general .legend-section--name {
  height: 53px;
  margin-top: 62px;
  padding: 0;
}

.idt-ticketing-bo-general .legend-section--price {
  height: 98px;
}

.idt-ticketing-bo-general .legend-section--live {
  height: 264px;
}

.idt-ticketing-bo-general .legend-section--replay {
  height: 176px;
}

.idt-ticketing-bo-general .legend-section--interview {
  height: 210px;
}

.idt-ticketing-bo-general .legend-section .title {
  margin-bottom: 15px;
  color: var(--grey-darkest);
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}

.idt-ticketing-bo-general .legend-section .title--name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
}

.idt-ticketing-bo-general .legend-section .title--price {
  margin-bottom: 25px;
}

.idt-ticketing-bo-general .legend-section .description {
  color: var(--black);
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  opacity: .5;
}

.idt-ticketing-bo-general .legend-section .description--live {
  height: 52px;
  margin-bottom: 42px;
}

.idt-ticketing-bo-general .legend-section label {
  margin-bottom: 20px;
  color: var(--grey-darkest);
  font-size: 15px;
  font-weight: 500;
}

.idt-ticketing-bo-general .legend-section label.live-chat {
  margin-bottom: 28px;
}

.idt-ticketing-bo-general .legend-section label.download {
  margin-bottom: 22px;
}

.idt-ticketing-bo-general .bo-ticketing__block__add {
  position: relative;
  height: calc(100% - 64px);
  min-height: 750px;
  margin-top: 62px;
  margin-left: 20px;
  color: var(--main-color);
  border: 2px dashed var(--main-color);
  border-radius: 6px;
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.idt-ticketing-bo-general .bo-ticketing__block__add__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.idt-ticketing-bo-general .bo-ticketing__block__add__content i {
  display: inline-block;
  margin-bottom: .75rem;
  font-size: 5rem;
  vertical-align: middle;
}

.idt-ticketing-bo-general .tickets {
  width: calc(100% - 220px);
  height: 100%;
}

