
























































































































































































































































































































































































































































































@import '@/styles/mixins/index';

.scroll-container {
  height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
  overflow: auto;

  .mentors-page {
    display: flex;
    justify-content: center;
    min-height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
    background-color: var(--grey-lighter);
    font-family: var(--text-font-family);

    @include respond-to(max-large) {
      height: fit-content;
      overflow: unset;
    }

    &__filter-panel {
      position: sticky;
      top: 0;
      max-width: 280px;
      height: calc((var(--vh, 1vh) * 100) - var(--menu-height));
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      @include respond-to(max-large) {
        display: none;
      }
      &.hidden {
        position: absolute;
      }
      &.mobile {
        display: block;
        background-color: var(--white);
        max-width: none;
        width: 100%;
        padding-inline: var(--rem-size-16);
      }
    }
    &__filter-panel::-webkit-scrollbar {
      display: none;
    }

    &__header {
      position: sticky;
      top: 0;
      padding: var(--rem-size-15) 0 var(--rem-size-25) 0;
      background-color: var(--grey-lighter);
      // To Hide box shadow on the talk card when hover and scrolled behind
      box-shadow: 0px 0px 0px var(--rem-size-10) var(--grey-lighter);
      clip-path: inset(0px -10px 0px -10px);
      z-index: 20;

      @include respond-to(max-large) {
        padding: var(--rem-size-15) 0 var(--rem-size-10) 0;
      }
      &__mobile {
        height: 0;
        visibility: hidden;

        @include respond-to(max-large) {
          height : 100%;
          visibility: initial;
        }
        &__bar {
          height: 46px;
          margin-right: var(--rem-size-10);
          background-color: var(--white);
          border: solid 1px var(--grey-light);
          border-radius: var(--global-radius);
          box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);

          &__input {
            height: 44px;
            margin: 0;
            color: var(--black);
            background-color: var(--white);
            border: none;
            font-size: var(--rem-size-16);
          }
          &:before {
            content: '\E96E';
            display: block;
            top: 85px;
            left: var(--rem-size-8);
            position: absolute;
            color: var(--black);
            font-family: "ideation-icons";
            font-size: 1rem;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
          }
        }
        &__filter {
          height: 46px;
          background-color: var(--white);
          box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
        }
        &__results {
        display: none;

        @include respond-to(max-large) {
          display: flex;
          height: var(--rem-size-22);
          margin: 0;
          font-size: var(--rem-size-14);
          text-align: right;
        }
      }
      }
      &__title-results {
        display: flex;
        flex-direction: row;
        height: var(--rem-size-32);
        margin-bottom: var(--rem-size-15);
        color: var(--black);

        &__title {
          max-height: 48px;
          font-size: var(--rem-size-24);
          font-weight: var(--font-weight-bold);
          text-align: left;

          @include respond-to(max-large) {
            text-align: center;
          }

          &.search {
            font-size: var(--rem-size-16);
          }
        }

        &__result {
          align-self: flex-end;
          font-size: var(--rem-size-15);
          text-align: right;

          @include respond-to(max-large) {
            display: none;
          }
        }
      }
      &__infos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 64px;
        background: var(--white);
        border-radius: var(--global-radius);
        box-shadow: 0px 2px 3px var(--grey);

        @include respond-to(max-large) {
          height: 0;
          visibility: hidden;
        }
        &__label {
          display: flex;
          flex-direction: column;
          margin: 5px var(--rem-size-10) 0 0;
          color: var(--black);
        }
        &__select {
          display: flex;
          width: 160px;
          height: 41px;
          margin: 0 var(--rem-size-10) 0 0;
          padding: 0 var(--rem-size-15);
        }
        &__button {
          display: flex;
          flex-direction: column;
          margin: 0 var(--rem-size-10) 0 0;
          color: var(--grey);

          &:hover {
            cursor: pointer;
          }
          &.disabled,
          &[disabled] {
            color: var(--black);
            opacity: 1;
            pointer-events: none;
          }
        }
      }
    }
    &__mentors {
      flex-direction: column;
      flex: 1 1 0px;
      max-width: 912px;
      padding-inline: var(--rem-size-20);

      &.panel-hidden {
        max-width: 1166px;
      }
      &__container {
        padding: var(--rem-size-15) 0 var(--rem-size-15) 0;

        &__spinner {
          display: flex;
          align-content: left;
        }
      }
    }
  }
}
