.meeting-card {
  display: flex;
  align-items: center;
  gap: var(--rem-size-16);
  position: relative;
  padding: 1.6rem 2rem;
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey);
  cursor: pointer;
}

.meeting-card__details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.meeting-card__details__title {
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.meeting-card__details__description {
  display: flex;
  align-items: center;
  font-size: var(--rem-size-14);
  color: var(--grey-dark);
}

.meeting-card__details__description .idt-live-tag {
  text-transform: initial;
}

.meeting-card__details__description__tag {
  margin-left: 1rem;
}

.meeting-card__details__description__tag .idt-tag {
  height: var(--rem-size-24);
  padding: 2px var(--rem-size-24);
}

.meeting-card__details__description__tag .idt-tag.invited {
  background-color: var(--grey-light);
  color: var(--grey-dark);
}

.meeting-card__duration {
  width: 12rem;
  padding-left: 2rem;
  border-left: 2px solid var(--grey-lighter);
}

.meeting-card__duration__label {
  font-size: var(--rem-size-12);
  font-weight: var(--font-weight-bold);
  color: var(--grey-dark);
}

.meeting-card__duration__value {
  font-size: var(--rem-size-14);
  color: var(--black);
}

.meeting-card__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.meeting-card__buttons__action {
  width: var(--rem-size-26);
  height: var(--rem-size-26);
  border-radius: 50%;
  text-align: center;
  line-height: var(--rem-size-26);
  cursor: pointer;
}

.meeting-card__buttons__action:hover {
  background: var(--grey-lighter);
}

.meeting-card__buttons ::v-deep .idt-dropdown--pane {
  width: 100px;
  padding: var(--rem-size-10);
  cursor: pointer;
}

.meeting-card__buttons__join-now .button {
  margin-bottom: 0;
  border-radius: 1.5rem;
}

