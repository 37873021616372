














































































































.mfa {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    text-align: center;
  }

  &__otp-input,
  &__actions {
    margin-top: var(--rem-size-32);
  }

  &__actions {
    display: flex;
    width: 100%;
    gap: var(--rem-size-8);

    & > .button {
      flex-basis: 50%;
    }
  }

  &__error, &__info {
    margin-top: var(--rem-size-16);
    font-size: var(--rem-size-12);
  }

  &__error {
    color: var(--red);
  }

  &__info {
    color: var(--main-color);
  }

  &__resend {
    margin-top: var(--rem-size-16);

    &__button {
      color: var(--main-color);
      font-size: var(--rem-size-14);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  & ::v-deep .otp-input {
    text-align: center;
    width: 4rem;
    height: 6rem;
    margin-bottom: 0;
    font-size: var(--rem-size-24);

    &.error {
      color: var(--red);
      border: 1px solid var(--red);
    }

    &.success {
      color: var(--main-color);
      border: 1px solid var(--main-color);
    }
  }
}
