








































































































































































































@import '@/styles/mixins/index';

.conference-presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 100px 0 30px 0;
  background-color: var(--grey-light);
  color: var(--grey-darkest);
  overflow: auto;

  &__date-container {
    display: flex;
    flex-direction: row;
    vertical-align: center;
    padding: 0.625rem;
    background-color: var(--white);
    font-weight: var(--font-weight-bold);
    border-radius: var(--global-radius);

    ::v-deep .idt-icon {
      margin-top: -5px;
    }
    &__date {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 0.625rem;
      border-right: 1px solid #3A3C43;

      &__text {
        margin-left: 0.625rem;
      }
    }
    &__hour {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.625rem;

      &__text {
        margin-left: 0.625rem;
      }
    }
  }
  &__speakers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 var(--rem-size-16);

    ::v-deep .idt-user-avatar {
      margin: 10px 0;
      border: 5px solid var(--white);
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    }

    &__item {
      flex: 0 0 auto;
      max-width: 200px;
      padding: var(--rem-size-20) var(--rem-size-16);
      text-align: center;

      &__name {
        font-size: 0.875rem;
        font-weight: var(--font-weight-medium);
      }

      &__job {
        font-size: 0.75rem;
        color: var(--grey-darker);
      }
    }
  }
  &__separator {
    width: 50%;
    margin: 2rem 0;
  }
  &__external-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-top: 2.5rem;

    &__input {
      margin: 0 var(--rem-size-16) 0 0;
    }
  }
  &__title {
    max-width: 1000px;
    padding: 1.5rem;
    font-family: var(--title-font-family);
    line-height: 1.1;

    @include respond-to(max-large) {
      font-size: var(--rem-size-24);
    }
    @include respond-to(large-xxlarge) {
      font-size: var(--rem-size-30);
    }
    @include respond-to(xxlarge) {
      font-size: var(--rem-size-35);
    }
  }
}
