






























.idt-survey-question-single {
  .idt-radiobutton {
    margin-bottom: var(--rem-size-8);
    padding: 3px .5rem;
    border: 2px solid transparent;
    border-radius: var(--global-radius);
    box-shadow: none;

    &:hover:not(.idt-radiobutton--checked) {
      border: 2px solid var(--grey-light);
    }

    &--checked {
      border: 2px solid var(--main-color);
    }
  }

  & ::v-deep .idt-radiobutton {

    label {
      color: var(--black);
      font-weight: var(--font-weight-medium);
    }

    .box {
      margin-right: var(--rem-size-18);
      background: var(--grey);
    }
  }
}
