











































.conference-footer {
  height: var(--conference-footer-height);
  border-top: 3px;
  border-color: black;
}
