















































.hiring-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: var(--rem-size-16);

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-width: 10rem;
    max-width: 20rem;
    margin: var(--rem-size-8);
    padding: var(--rem-size-32);
    border: 2px solid var(--grey-light);
    border-radius: var(--global-radius);

    &__title {
      margin: var(--rem-size-16);
      color: black;
    }

    &__button {
      width: 50%
    }
  }
}
