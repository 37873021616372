.color-selector {
  width: 100%;
  border: 1px solid var(--grey-dark);
  box-shadow: none;
}

.color-selector ::v-deep .vc-chrome-fields .vc-input__input {
  font-size: var(--rem-size-14);
}

.theme-fonts {
  margin-top: 60px;
}

.sample-palette {
  --sample-color: hsl(var(--sample-color-hue), var(--sample-color-saturation), var(--sample-color-lightness));
  --sample-color-lightest: hsl(var(--sample-color-hue), var(--sample-color-saturation), 95%);
  --sample-color-lighter: hsl(var(--sample-color-hue), var(--sample-color-saturation), 90%);
  --sample-color-light: hsl(var(--sample-color-hue), var(--sample-color-saturation), 65%);
  --sample-color-dark: hsl(var(--sample-color-hue), var(--sample-color-saturation), 40%);
  --sample-color-darker: hsl(var(--sample-color-hue), var(--sample-color-saturation), 27%);
  --sample-color-darkest: hsl(var(--sample-color-hue), var(--sample-color-saturation), 20%);
  display: flex;
  flex-flow: row wrap;
}

.sample-palette-block {
  flex: 0 0 33%;
}

.sample-palette-block--spacer {
  flex: 0 0 66%;
}

.sample-palette-color {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--rem-size-8);
  padding: var(--rem-size-8);
  border-radius: var(--global-radius);
}

.sample-palette-color--primary {
  color: var(--white);
  background-color: var(--sample-color);
}

.sample-palette-color--primary-light {
  color: var(--white);
  background-color: var(--sample-color-light);
}

.sample-palette-color--primary-lighter {
  background-color: var(--sample-color-lighter);
}

.sample-palette-color--primary-lightest {
  background-color: var(--sample-color-lightest);
}

.sample-palette-color--primary-dark {
  color: var(--white);
  background-color: var(--sample-color-dark);
}

.sample-palette-color--primary-darker {
  color: var(--white);
  background-color: var(--sample-color-darker);
}

.sample-palette-color--primary-darkest {
  color: var(--white);
  background-color: var(--sample-color-darkest);
}

.sample-fonts__title {
  font-family: var(--sample-title-font);
  font-size: var(--rem-size-28);
}

.sample-fonts__text {
  font-family: var(--sample-text-font);
}

