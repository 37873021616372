.sponsor-showroom__wrapper:first-child {
  padding-top: 25px;
}

.sponsor-showroom__wrapper:last-child {
  padding-bottom: 25px;
}

.sponsor-showroom__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsor-showroom__container--top-level {
  background: var(--grey-lighter);
  padding: 50px 1rem;
}

.sponsor-showroom__container--top-level:not(:last-child) {
  margin-bottom: 25px;
}

.sponsor-showroom__container--level {
  padding: 25px 1rem;
}

.sponsor-showroom__row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  max-width: 1272px;
}

.idt-page-section__title {
  margin-bottom: 10px;
}

.idt-page-section__subtitle {
  margin-bottom: 30px;
}

