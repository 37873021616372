.idt-conference-waiting-box {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .idt-conference-waiting-box {
    align-items: center;
    padding: 30px;
  }
}

.idt-conference-waiting-box__credit {
  flex: 0 1 auto;
}

@media only screen and (min-width: 1024px) {
  .idt-conference-waiting-box__credit {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.idt-conference-waiting-box__content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .idt-conference-waiting-box__content {
    transform: scale(0.6);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .idt-conference-waiting-box__content {
    transform: scale(0.9);
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .idt-conference-waiting-box__content {
    transform: scale(0.7);
  }
}

.idt-conference-waiting-box .date__part {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.idt-conference-waiting-box .date__calendar {
  margin-right: 1rem;
  padding: 0 1.5rem;
}

.idt-conference-waiting-box .date__calendar .idt-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.idt-conference-waiting-box .date__day {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}

.idt-conference-waiting-box .date__month {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 55%);
  font-size: .8rem;
  font-weight: 300;
  text-transform: uppercase;
}

.idt-conference-waiting-box .date__text {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
}

.idt-conference-waiting-box .date__hours {
  font-family: var(--title-font-family);
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1;
}

.idt-conference-waiting-box .timer {
  font-family: var(--title-font-family);
  font-size: 2.6rem;
  font-weight: 300;
}

.idt-conference-waiting-box .timer__container {
  position: relative;
  width: 600px;
  padding-top: 1rem;
  border-top: 1px solid var(--main-color);
}

@media only screen and (max-width: 639px) {
  .idt-conference-waiting-box .timer__container {
    margin-top: 2.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .idt-conference-waiting-box .timer__container {
    margin-top: 4.5rem;
  }
}

.idt-conference-waiting-box .timer__text {
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0 1.25rem;
  background-color: var(--main-color-lighter);
  font-size: 1.4rem;
  font-weight: 300;
  transform: translate(-50%, -50%);
}

