.meeting-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--grey-light);
}

.meeting-sidebar ::v-deep .idt-sidebar__content__header {
  display: flex;
  justify-content: space-between;
  padding: var(--rem-size-6);
  background-color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.meeting-sidebar ::v-deep .idt-sidebar__content__header__title {
  text-transform: initial;
}

.meeting-sidebar ::v-deep .idt-sidebar__content__slot {
  display: flex;
  width: 100%;
  overflow-y: auto;
}

.meeting-sidebar ::v-deep .live-chat {
  width: 100%;
}

.meeting-sidebar ::v-deep .idt-sidebar__content__header__title {
  width: 100%;
}

.meeting-sidebar ::v-deep .idt-sidebar__content__header__title__slot {
  width: 100%;
}

.meeting-sidebar__header {
  display: flex;
  justify-content: space-between;
}

.meeting-sidebar__header__main-info {
  display: flex;
  align-items: center;
}

.meeting-sidebar__header__main-info__title {
  padding: var(--rem-size-2) var(--rem-size-10);
  background-color: var(--blue);
  color: var(--white);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
}

.meeting-sidebar__header__main-info__elapsed-time {
  margin-left: var(--rem-size-8);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);
  line-height: var(--rem-size-18);
}

.meeting-sidebar__header__participants-count {
  display: flex;
  align-items: center;
  padding: var(--rem-size-2) var(--rem-size-10);
  background-color: var(--main-color);
  color: var(--white);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  gap: var(--rem-size-5);
  cursor: pointer;
}

.meeting-sidebar__header__participants-count.active {
  background-color: var(--grey-lighter);
  color: var(--black);
}

.meeting-sidebar__header__participants-count.not-clickable {
  cursor: default;
}

