









































































@import '@/styles/mixins/index';

.submenu-account-info {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: var(--rem-size-5) 1rem;
  background-color: var(--grey-lightest);

  @include respond-to(max-slarge) {
    justify-content: space-between;
    text-align: left;
  }
  @include respond-to(slarge) {
    justify-content: center;
    text-align: center;
  }

  &:not(.no-ticket) {
    color: var(--white);
  }
  &.no-ticket {
    color: var(--grey-darker);
  }
  &__connected {
    font-size: var(--rem-size-12);
    font-style: italic;
    line-height: var(--rem-size-18);
  }
  &__name {
    flex: 1 0 auto;
    max-width: 170px;
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-medium);
    line-height: var(--rem-size-21);
    overflow: hidden;
  }
  &__ticket {
    display: flex;
    align-items: center;

    @include respond-to(slarge) {
      justify-content: center;
    }

    &__title {
      max-width: 125px;
      margin-left: 0.375rem;
      font-size: 0.75rem;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__avatar {
    @include respond-to(slarge) {
      display: none;
    }
  }
}
