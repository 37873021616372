




































































































































































































































































































































































.bo-project-settings {
  &__bo-section {
    &__right {
      display: flex;
      flex-direction: column;

      &__deadline {
        display: flex;
        justify-content: space-between;
        margin-top: var(--rem-size-32);

        &__date-picker {
          width: 150px;
        }
        &__timezones {
          width: 40%;
        }
      }
    }
  }
}
