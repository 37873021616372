.lobby-video {
  background: var(--grey-darkest);
}

.lobby-video--full {
  background: rgba(0,0,0,0);
}

.lobby-video--loading {
  position: relative;
}

.lobby-video--loading .idt-round-loader {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lobby-video__footer {
  display: flex;
  flex-flow: wrap;
}

.lobby-video__footer a {
  padding: var(--rem-size-5) var(--rem-size-24);
  border: 1px solid var(--main-color-dark);
  border-radius: 30px;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-bold);
}

.lobby-video__footer a+a {
  margin-left: var(--rem-size-10);
}

