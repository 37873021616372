















































































































































































































.idt-survey-table ::v-deep .idt-table-cell--status .default {
  text-align: left;
}
