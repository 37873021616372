.idt-array-avatar-cell {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.idt-array-avatar-cell__content {
  display: flex;
  align-items: center;
}

