






























































































@import '@/styles/mixins/index';

.menu-item {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  @include respond-to(max-slarge) {
    padding: 0 0.4rem;
  }
  @include respond-to(slarge) {
    padding: 0 0.75rem;
  }

  &__link {
    display: block;
    position: relative;
    color: var(--grey-darker);
    border-radius: var(--global-radius);
    font-size: 0.75rem;
    font-weight: var(--font-weight-bold);
    text-align: center;
    line-height: 44px;
    text-decoration: none;
    cursor: pointer;
  }

  &.active &__link {
    color: var(--main-color);
  }

  &:not(.cta):hover,
  &:not(.cta).active {
    @include respond-to(slarge) {
      &:not(.account) {
        &:after {
          content: '';
          position: absolute;
          bottom: -14px;
          left: 0;
          width: 100%;
          border-bottom: 3px solid var(--main-color);
        }
      }
    }
  }
}
