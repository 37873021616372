































































.idt-color-picker {
  position: relative;
  margin: 0;

  &__icon {
    .ideacon {
      position: absolute;
      left: 1rem;
      bottom: 40px;
    }
  }

  .idt-input {
    padding-left: 2.5rem;
    background-color: var(--white) !important;
    cursor: pointer;
  }

  &__picker {
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    top: calc(100% - 24px);
    right: 1rem;
    margin: auto !important;
    padding: 1rem;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1000;
  }
}
