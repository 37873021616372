.event-map {
  z-index: 1;
}

.event-map__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.event-map__background img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100% !important;
  min-height: 100% !important;
  transform: translate(-50%, -50%);
}

.event-map__info {
  max-width: 600px;
  color: var(--white);
  background-color: var(--black-alpha60);
}

@media only screen and (max-width: 639px) {
  .event-map__info {
    padding: 2.5rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-map__info {
    padding: 4rem 5rem;
  }
}

.event-map__info__container {
  display: flex;
  justify-content: flex-end;
}

.event-map__info__name {
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (max-width: 639px) {
  .event-map__info__name {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-map__info__name {
    font-size: 2rem;
  }
}

.event-map__info__name i {
  vertical-align: middle;
}

.event-map__info__dates {
  padding: 1rem 0;
  font-weight: 700;
}

@media only screen and (max-width: 639px) {
  .event-map__info__dates {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 640px) {
  .event-map__info__dates {
    font-size: 1.8rem;
  }
}

.event-map__info__address {
  font-size: 1.1rem;
  line-height: 1.2;
}

.event-map__info__address i {
  font-size: 2.5rem;
}

