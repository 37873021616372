


























.card-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  color: var(--white);
  background-color: var(--secondary-color);
  border: solid 1px var(--secondary-color);
  border-radius: 0 0 var(--global-radius) var(--global-radius);
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}
