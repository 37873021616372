











































@import '@/styles/mixins/index';

.idt-ticket-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
  padding: 0 15px;

  &:not(.modal-mode) {
    position: absolute;

    @include respond-to(max-xlarge) {
      top: -20px;
    }
    @include respond-to(xlarge) {
      top: -28px;
    }
  }
  &__name {
    background-color: var(--white);
    font-family: var(--title-font-family);
    font-weight: var(--font-weight-bold);
    text-align: center;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include respond-to(max-xlarge) {
      font-size: 1.5rem;
    }
    @include respond-to(xlarge) {
      font-size: 1.875rem;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    box-shadow: 0 0 0 3px var(--white);
    border-radius: 100%;

    @include respond-to(max-xlarge) {
      width: 38px;
      height: 38px;
      min-width: 38px;
    }
    @include respond-to(xlarge) {
      width: 50px;
      height: 50px;
      min-width: 50px;

      .ideacons {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.5rem;
      }
    }
  }
  &.modal-mode &__name {
    @include respond-to(xsmall) {
      font-size: 1.875rem;
    }
  }
  &.modal-mode &__icon {
    @include respond-to(xsmall) {
      width: 50px;
      height: 50px;

      .ideacons {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.5rem;
      }
    }
  }
}
