









































































.idt-simple-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 12px 24px 12px;
  color: var(--grey-darker);
  background-color: var(--white);
  box-shadow: 0px 2px 3px var(--black-alpha20);
  border-bottom: 2px solid transparent;
  transition: border 0.25s ease-in-out;

  &:hover {
    border-bottom-color: var(--main-color);
  }
  &.no-link {
    cursor: default;
    pointer-events: none;
  }
  &__image-container {
    display: flex;
    justify-content: center;
    padding-top: 8%;
    padding-left: 12.5%;
    padding-right: 12.5%;
    overflow: hidden;
  }
  &__image {
    max-width: 300px;
    max-height: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__name {
    margin: 0 10% 0 10%;
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-medium);
  }
}
