.question {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
  cursor: pointer;
}

.question__part {
  flex: 0 0 auto;
}

.question .author {
  display: flex;
  align-items: center;
  order: 1;
  color: var(--white);
  background-color: var(--main-color);
}

@media only screen and (max-width: 639px) {
  .question .author {
    width: 100%;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .question .author {
    width: 50%;
    height: 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .question .author {
    flex-direction: row;
    padding: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .question .author {
    flex-direction: column;
    justify-content: center;
    width: 274px;
    height: 170px;
    padding: 1.25rem 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .question .author .author__avatar {
    width: 40px !important;
    height: 40px !important;
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .question .author .author__avatar {
    margin-bottom: .85rem;
  }
}

.question .author .author__avatar .idt-user-avatar {
  filter: drop-shadow(2px 2px 3px var(--black-alpha50));
}

@media only screen and (max-width: 639px) {
  .question .author .author__information {
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 1023px) {
  .question .content {
    order: 3;
    width: 100%;
    padding: 1rem 1.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .question .content {
    order: 2;
    width: calc(100% - 434px);
    padding: 0 2.2rem;
  }
}

.question .content__actions {
  display: flex;
  margin-bottom: .5rem;
}

.question .content__actions__delete {
  position: relative;
  margin-left: 2rem;
  color: var(--main-color);
  vertical-align: middle;
}

@media only screen and (min-width: 640px) {
  .question .content__actions__delete {
    font-size: 1.25rem;
  }
}

.question .content__actions__delete:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -1rem;
  height: 100%;
  border-left: 1px solid var(--grey);
  transform: translateY(-50%);
}

.question .content__created {
  color: var(--grey-darker);
  font-weight: 300;
  line-height: 1;
}

@media only screen and (min-width: 640px) {
  .question .content__created {
    font-size: 1.25rem;
  }
}

.question .content__text {
  color: var(--grey-dark);
  font-weight: 500;
  line-height: 1.15;
  overflow: hidden;
}

@media only screen and (max-width: 639px) {
  .question .content__text {
    height: 74px;
  }
}

@media only screen and (min-width: 640px) {
  .question .content__text {
    height: 64px;
    font-size: 1.15rem;
  }
}

@media only screen and (max-width: 639px) {
  .question .info {
    width: 100%;
    padding-bottom: 1rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .question .info {
    justify-content: flex-end;
    width: 50%;
    height: 70px;
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 1023px) {
  .question .info {
    display: flex;
    align-items: center;
    order: 2;
    color: var(--white);
    background-color: var(--main-color);
  }
}

@media only screen and (min-width: 1024px) {
  .question .info {
    order: 3;
    width: 160px;
    color: var(--grey-darker);
    padding-right: 2.5rem;
  }
}

.question .info__block {
  display: flex;
}

@media only screen and (max-width: 639px) {
  .question .info__block {
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    text-align: center;
  }
}

@media only screen and (min-width: 640px) {
  .question .info__block {
    justify-content: flex-end;
    align-items: center;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .question .info__block {
    text-align: right;
  }
}

@media only screen and (max-width: 1023px) {
  .question .info__block {
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .question .info__block {
    text-align: center;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .question .info__block:first-child {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media only screen and (max-width: 1023px) {
  .question .info__block:first-child {
    border-right: 1px solid var(--white);
  }
}

@media only screen and (min-width: 1024px) {
  .question .info__block:first-child {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--grey);
  }
}

@media only screen and (min-width: 640px) {
  .question .info__block__text {
    flex: 1 0 auto;
  }
}

.question .info__block__number {
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1;
}

@media only screen and (max-width: 1023px) {
  .question .info__block__number {
    display: inline-block;
  }
}

.question .info__block__label {
  font-weight: 500;
}

@media only screen and (max-width: 1023px) {
  .question .info__block__label {
    display: inline-block;
    font-size: .9rem;
    line-height: 1;
  }
}

.question .info__block__icon {
  padding-left: 1rem;
  line-height: 1;
}

