.idt-fo-subheader {
  position: sticky;
  top: calc(var(--sticky-margin, 0px) + 70px);
  width: 100%;
  height: 55px;
  background-color: var(--white);
  z-index: 100;
}

.idt-fo-subheader__container {
  max-width: 1170px;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

