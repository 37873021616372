





































































.user-input-container {
  padding: 20px 40px 20px;
}
