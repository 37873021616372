

















































































































































































































































































.color-selector {
  width: 100%;
  border: 1px solid var(--grey-dark);
  box-shadow: none;

  & ::v-deep .vc-chrome-fields .vc-input__input {
    font-size: var(--rem-size-14);
  }
}
.theme-fonts {
  margin-top: 60px;
}
.sample-palette {
  --sample-color: hsl(var(--sample-color-hue), var(--sample-color-saturation), var(--sample-color-lightness));
  --sample-color-lightest: hsl(var(--sample-color-hue), var(--sample-color-saturation), 95%);
  --sample-color-lighter: hsl(var(--sample-color-hue), var(--sample-color-saturation), 90%);
  --sample-color-light: hsl(var(--sample-color-hue), var(--sample-color-saturation), 65%);
  --sample-color-dark: hsl(var(--sample-color-hue), var(--sample-color-saturation), 40%);
  --sample-color-darker: hsl(var(--sample-color-hue), var(--sample-color-saturation), 27%);
  --sample-color-darkest: hsl(var(--sample-color-hue), var(--sample-color-saturation), 20%);

  display: flex;
  flex-flow: row wrap;

  &-block {
    flex: 0 0 33%;

    &--spacer {
      flex: 0 0 66%;
    }
  }
  &-color {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--rem-size-8);
    padding: var(--rem-size-8);
    border-radius: var(--global-radius);

    &--primary {
      color: var(--white);
      background-color: var(--sample-color);
    }
    &--primary-light {
      color: var(--white);
      background-color: var(--sample-color-light);
    }
    &--primary-lighter {
      background-color: var(--sample-color-lighter);
    }
    &--primary-lightest {
      background-color: var(--sample-color-lightest);
    }
    &--primary-dark {
      color: var(--white);
      background-color: var(--sample-color-dark);
    }
    &--primary-darker {
      color: var(--white);
      background-color: var(--sample-color-darker);
    }
    &--primary-darkest {
      color: var(--white);
      background-color: var(--sample-color-darkest);
    }
  }
}
.sample-fonts {
  &__title {
    font-family: var(--sample-title-font);
    font-size: var(--rem-size-28);
  }
  &__text {
    font-family: var(--sample-text-font);
  }
}
