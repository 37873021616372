@media only screen and (max-width: 639px) {
  .event-description {
    padding: 2rem 1rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .event-description {
    padding: 4rem 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-description {
    padding: 5rem 3rem;
  }
}

.event-description__title {
  margin-top: 0;
  font-size: 2.1875rem;
  font-weight: 300;
  text-transform: uppercase;
}

.event-description__text {
  margin: .625rem .625rem;
  text-align: justify;
}

.event-description__text blockquote {
  margin: .625rem 1.25rem;
  padding: 0 .625rem;
  border-left: 1px solid var(--grey);
}

.event-description__text blockquote p {
  margin: .3125px auto;
  color: var(--grey-darker);
  font-weight: var(--font-weight-light);
}

@media only screen and (max-width: 1023px) {
  .event-description__text {
    margin-bottom: 2rem;
  }
}

