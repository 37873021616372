.event-edit-section {
  display: flex;
  align-items: center;
  gap: var(--rem-size-16);
  position: absolute;
  padding: .5rem 1rem;
  background-color: var(--white);
}

@media only screen and (max-width: 639px) {
  .event-edit-section {
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--main-color-alpha70);
    text-align: right;
  }
}

@media only screen and (min-width: 640px) {
  .event-edit-section {
    top: 30px;
    right: 30px;
    border-radius: var(--global-radius);
  }
}

.event-edit-section__link {
  display: inline-flex;
  align-items: center;
  gap: var(--rem-size-4);
  font-size: 1.2rem;
}

