


















































































.idt-accordion {
  margin-bottom: var(--rem-size-16);
  border-radius: var(--global-radius);
  background-color: var(--white-alpha10);

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--rem-size-16);
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-medium);
    color: var(--white);
  }

  &__content {
    border-top: 1px solid var(--grey-darkest);
    overflow: hidden;
    transition: 300ms ease-out;
  }
}

.rotate-180 {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}

.rotate-0 {
  transform: rotate(0deg);
  transition-duration: 0.3s;
}
