













































.menu-mobile-list {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
}
