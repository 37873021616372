




















































































































































































































































































































@import '@/styles/mixins/index';

.conference-page {
  --menu-height: 70px;
  --video-height: 48vw;
  --menu-and-footer-height: calc(
    var(--menu-height) + var(--conference-footer-height)
  );

  display: flex;
  position: relative;
  width: 100%;
  height: calc(100vh - var(--menu-height));
  height: calc((var(--vh, 1vh) * 100) - var(--menu-height));

  @include respond-to(max-slarge) {
    flex-flow: column nowrap;
  }
  @include respond-to(slarge) {
    flex-flow: row;
  }

  &__video {
    position: relative;

    @include respond-to(max-slarge) {
      flex: 0 0 auto;
    }
    @include respond-to(slarge) {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &__container {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: calc((var(--vh, 1vh) * 100));
      overflow: hidden;

      @include respond-to(large) {
        max-height: calc(100% - var(--conference-footer-height)
        ) !important;
      }
    }
  }
  &__chat {
    height: calc(100vh - 70px);

    @include respond-to(max-slarge) {
      flex: 1 1 auto;

      @include respond-to(landscape) {
        display: none;
      }
    }
    @include respond-to(slarge) {
      flex: 0 0 auto;
      width: 440px;
    }

    ::v-deep .conference-sidebar {
      @include respond-to(medium) {
        border-left: 1px solid var(--grey-lighter);
      }
      @include respond-to(slarge) {
        flex-grow: 1;
      }

      &__title {
        font-size: 18px;
        text-transform: uppercase;
      }
    }

    ::v-deep .room {
      flex-grow: 1;
    }
  }

  @include respond-to(max-large) {
    .mentor-mobile-chat {
      &:not(.pending),
      &:not(.replay) {
        height: calc(100vh - var(--menu-and-header-height));
        height: calc((var(--vh, 1vh) * 100) - var(--menu-and-header-height));
      }
      &.pending,
      &.replay {
        height: calc(100vh - (var(--video-height) + 110px));
        height: calc((var(--vh, 1vh) * 100) - (var(--video-height) + 110px));
      }
    }

    .participant-mobile-chat {
      @include respond-to(landscape) {
        display: none;
      }
    }
  }
}
