









































































.how-it-works-card {
  &__content {
    &__description {
      white-space: pre-line;
    }
  }
}
