@media only screen and (max-width: 639px) {
  .bo-page__side-button {
    display: block;
    position: fixed;
    top: 1.15rem;
    left: 1rem;
    width: 44px;
    height: 34px;
    margin: 0;
    color: var(--main-color-light);
    text-align: center;
    z-index: 1010;
    transition: all .3s ease;
  }

  .bo-page__side-button.open {
    transform: rotate(180deg);
  }

  .bo-page__side-button i {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 640px) {
  .bo-page__side-button {
    display: none;
  }
}

@media only screen and (max-width: 639px) {
  .bo-page__container {
    transform: translateX(0);
    transition: all .3s ease;
  }

  .bo-page__container.open {
    transform: translateX(90px);
  }
}

@media only screen and (min-width: 640px) {
  .bo-page__container {
    margin-left: 90px;
  }
}

.bo-page__content {
  min-height: calc(100vh - 190px);
}

@media only screen and (max-width: 639px) {
  .bo-page__content {
    padding: 2rem .5rem;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .bo-page__content {
    padding: 1rem;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .bo-page__content {
    padding: 2rem 2rem 6rem;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-page__content {
    padding: 2rem 3rem 6rem;
  }
}

.bo-page hr {
  max-width: 100%;
  border-color: var(--grey);
}

