























































@import '@/styles/mixins/index';

.idt-fo-card{
  &__header {
    &__title {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

    i {
        margin-right: 0.5rem;
        color: var(--main-color);
        font-size: var(--rem-size-20);
      }
    }
    .gamification-points {
      margin: auto 0;
    }
  }
  &__content {
    font-size: var(--rem-size-14);
  }
  &__footer {
    > div,
    > button {
      min-width: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &__number {
      color: var(--grey-dark);
      font-style: italic;
      flex-grow: 2;
    }
    &__view-more {
      color: var(--secondary-color);
      font-weight: var(--font-weight-bold);
      text-align: end;
    }
  }
}
