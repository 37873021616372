.progress {
  position: relative;
  background-color: var(--grey-light);
  border-radius: 15px;
  height: .5rem;
  margin: 0;
}

.progress:focus {
  outline: none;
}

.progress .progress-meter {
  position: static;
  border-radius: 15px;
  background-color: var(--secondary-color);
}

.progress .progress-meter.animated {
  transition: width 3s ease-in-out;
}

.progress.primary .progress-meter {
  background-color: var(--main-color);
}

