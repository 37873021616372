.export-dropdown-content {
  display: flex;
  flex-direction: column;
}

.export-dropdown-content>* {
  margin-bottom: 5px;
}

.import-input {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.import-input+label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: .85em 1em;
  color: var(--white);
  background-color: var(--main-color);
  border: 1px solid rgba(0,0,0,0);
  border-radius: var(--global-radius);
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

