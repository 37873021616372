






































.how-it-works-card {
  background-color: transparent;
  border: 2px dashed var(--grey-light)
}
