.project-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  background: var(--white);
  overflow: hidden;
  color: var(--grey-darkest);
}

.project-card__preview {
  width: 100%;
  height: 180px;
  background-color: var(--main-color-lighter);
  background-size: cover;
  border-radius: var(--rem-size-5);
  font-size: var(--rem-size-16);
}

.project-card__texts {
  margin-top: var(--rem-size-22);
}

.project-card__texts__title {
  font-weight: var(--font-weight-bold);
}

.project-card__texts__description {
  height: var(--rem-size-48);
}

.project-card__texts__challenges {
  height: var(--rem-size-24);
  margin-top: var(--rem-size-6);
  margin-bottom: var(--rem-size-10);
  overflow: hidden;
}

.project-card__footer {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  padding-top: .625rem;
}

.project-card__footer__vote {
  display: inline-block;
  flex: 0 0 auto;
  padding: var(--rem-size-3) var(--rem-size-14);
  border: 2px solid var(--white);
  border-radius: var(--rem-size-30);
  font-size: var(--rem-size-14);
}

.project-card__footer__vote.allin {
  color: var(--project-vote-0);
  border-color: var(--project-vote-0);
}

.project-card__footer__vote.pivot {
  color: var(--project-vote-1);
  border-color: var(--project-vote-1);
}

.project-card__footer__vote.kill {
  color: var(--project-vote-2);
  border-color: var(--project-vote-2);
}

.project-grid-card {
  padding: .625rem;
  background-color: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px 0px var(--grey);
}

.project-grid-card__preview {
  position: relative;
}

.project-grid-card__members {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: -1.25rem;
  margin-right: .125rem;
}

.project-grid-card__texts {
  margin-top: .25rem;
}

.project-grid-card .project-card__texts__title {
  height: 1.5rem;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.project-grid-card .project-card__footer {
  border-top: 1px solid var(--grey-light);
}

::v-deep .idt-tag {
  height: 20px;
  max-width: 205px;
}

