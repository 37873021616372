













































.fo-project-list-deadline {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--rem-size-36);
  padding: 0 var(--rem-size-24);
  color: var(--warning-color);
  background-color: var(--yellow-lightest);
  border-radius: 80px;

  &.reached {
    color: var(--main-color);
    background: var(--main-color-lighter);
  }

  &.none {
    color: var(--grey-darker);
    background: var(--grey-light);
  }

  &__text {
    margin-left: var(--rem-size-10);
    font-weight: var(--font-weight-medium);
    font-size: var(--rem-size-14);
  }
}
