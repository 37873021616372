.fo-lobby-message-skeleton {
  position: relative;
  padding: var(--rem-size-15) 1.5rem;
  padding-left: 4rem;
}

.fo-lobby-message-skeleton__line {
  display: flex;
  padding-bottom: var(--rem-size-8);
}

.fo-lobby-message-skeleton__line>* {
  height: var(--rem-size-24);
  background: var(--grey-alpha30);
  margin-right: var(--rem-size-8);
  border-radius: var(--global-radius);
}

.fo-lobby-message-skeleton__avatar {
  width: var(--rem-size-24);
  height: var(--rem-size-24);
  border-radius: 100%;
  background: var(--grey-alpha30);
  position: absolute;
  top: var(--rem-size-10);
  left: var(--rem-size-24);
}

.fo-lobby-message-skeleton__cell-info {
  width: 4rem;
}

.fo-lobby-message-skeleton__cell-pseudo {
  width: 7rem;
}

.fo-lobby-message-skeleton__cell-long {
  height: var(--rem-size-16);
  width: 60%;
}

@media only screen and (max-width: 991px) {
  .fo-lobby-message-skeleton__cell-long {
    width: 70%;
  }
}

.fo-lobby-message-skeleton__cell-short {
  height: var(--rem-size-16);
  width: 16%;
}

@media only screen and (max-width: 991px) {
  .fo-lobby-message-skeleton__cell-short {
    width: 20%;
  }
}

.fo-lobby-message-skeleton__shine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, var(--white-alpha60) 60px, rgba(255, 255, 255, 0) 120px);
  background-repeat: no-repeat;
  animation: shine 4s infinite;
}

@media only screen and (max-width: 991px) {
  .fo-lobby-message-skeleton__shine {
    animation: shine-mobile 3s infinite;
  }
}

@keyframes shine {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: calc(65vw + 100px);
  }
}

@keyframes shine-mobile {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: calc(100vw + 100px);
  }
}

