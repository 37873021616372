body {
  margin: 0;
  padding: 0;
  font-family: var(--text-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
code {
  border-color: var(--grey);
  border-radius: var(--global-radius);
  background-color: var(--grey-lightest);
}
pre {
  padding: remCalc(8);
  border: 1px solid var(--grey);
  border-radius: var(--global-radius);
  background-color: var(--grey-lightest);
  font-size: remCalc(14);
}
/* Prevent body from scrolling when mobile menu is open */
.ovh {
  width: 100%;
  height: calc((var(--vh, 1vh) * 100));
  overflow: hidden;

  @include respond-to(max-large) {
    position: fixed;
  }
}
img {
  display: inline-block;
  max-width: 100%;
}
*[disabled] {
  cursor: not-allowed;
  opacity: 0.33;
}
// Style for vue-line-clamp directive
.vue-line-clamp {
  word-break: break-word !important;
}
