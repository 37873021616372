.idt-survey-modal__form__add-question {
  margin-top: var(--rem-size-12) !important;
}

.idt-survey-modal__form__schedule {
  max-width: 450px;
}

.idt-survey-modal__form__results-visibility {
  width: 420px;
}

.idt-survey-modal__form__tabs {
  margin-bottom: 5px;
}

.idt-survey-modal__form__tabs__button {
  padding: 2px;
  border: solid 2px var(--main-color);
  border-radius: var(--global-radius);
  cursor: pointer;
}

.idt-survey-modal__form__tabs__button.selected {
  background-color: var(--main-color);
  color: var(--white);
}

