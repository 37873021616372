.conference-list-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.conference-list-container__card {
  width: 305px;
}

.conference-list-container__mobile-container {
  flex-flow: nowrap;
}

.conference-list-container__mobile-container__mobile-card {
  width: 275px;
  margin-left: 20px;
  padding: 0;
}

