.skeleton {
  width: 280px;
  padding: var(--rem-size-16);
}

@media only screen and (max-width: 1023px) {
  .skeleton {
    display: none;
  }
}

.skeleton__search {
  height: var(--rem-size-40);
  margin-bottom: var(--rem-size-8);
}

.skeleton__reset-button {
  height: var(--rem-size-32);
  margin-bottom: 52px;
}

.skeleton__title {
  height: var(--rem-size-32);
  margin-bottom: var(--rem-size-16);
}

.skeleton__sort-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--rem-size-22);
}

.skeleton__sort-by__label {
  width: 59px;
  height: var(--rem-size-24);
  margin-right: var(--rem-size-16);
}

.skeleton__sort-by__field {
  width: 100%;
  height: var(--rem-size-40);
}

.skeleton__category {
  width: 87px;
  height: var(--rem-size-24);
  margin-bottom: var(--rem-size-20);
}

.skeleton__sub-category {
  width: 63px;
  height: var(--rem-size-24);
  margin-bottom: var(--rem-size-20);
}

.skeleton__filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--rem-size-12);
}

.skeleton__filters__label {
  width: 130px;
  height: var(--rem-size-24);
}

.skeleton__filters__checkbox {
  width: var(--rem-size-24);
  height: var(--rem-size-24);
}

.bone {
  background-color: var(--grey);
  border-radius: var(--global-radius);
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--grey-light);
  }

  25% {
    background-color: var(--grey);
  }

  50% {
    background-color: var(--grey-light);
  }

  75% {
    background-color: var(--grey-lighter);
  }

  100% {
    background-color: var(--grey-light);
  }
}

