







































































































































































































































































































































.conference-controls {
  margin-top: 22px;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__autostart {
    ::v-deep .idt-checkbox {
      margin: 0;
      background: none;
      border: none;

      &--checked {
        box-shadow: none;
      }
    }
  }
  &__left-panel,
  &__right-panel {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
  }
  &__left-panel {
    border-radius: 23px 0 0 23px;
    background-color: var(--white);
    color: var(--main-color);

    &--speaker {
      border-radius: 23px;
    }
    &__label {
      padding: 0 var(--rem-size-20);
      white-space: nowrap;
    }
    &__started {
      color: var(--black);
    }
  }

  &__right-panel {
    border-radius: 0 23px 23px 0;
    background-color: var(--main-color);

    &__button {
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem var(--rem-size-20);
      color: var(--white);
      font-weight: var(--font-weight-bold);
      cursor: pointer;

      &__label {
        margin-right: 10px;
        white-space: nowrap;
      }
    }

    &__started {
      background-color: #ea4335;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 0 1rem;
    border-radius: 23px;
    background-color: var(--white);
    color: #ffaf22;

    &__label {
      white-space: nowrap;
    }

    &__spinner {
      margin-left: 15px;
    }
  }

}

.conference-moderator-buttons-container {
  display: flex;
  flex-direction: column;
  height: 90px;

  &__start-stream {
    width: 180px;
    height: 45px;
    border-radius: 25px;
    background: var(--white);
    color: var(--main-color);
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    &--dark {
      background: var(--main-color);
      color: var(--white);
    }

    &:hover {
      background: var(--main-color-light);
      color: var(--main-color-darker);
    }

    ::v-deep .idt-icon {
      margin-left: 7px;
    }
  }

  &__launching {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 45px;
    border-radius: 25px;
    background: var(--main-color-darker);

    &__label {
      color: var(--white);
      font-size: 1rem;
      font-weight: var(--font-weight-bold);
    }

    &__spinner {
      margin-left: 7px;
    }
  }

  &__live {
    width: 85px;
    height: 35px;
    border: 1px solid var(--white);
    border-radius: var(--global-radius);
    color: var(--white);
    font-size: 1rem;
    font-weight: var(--font-weight-bold);

    ::v-deep .idt-icon {
      margin-left: 7px;
    }
  }

  &__stop-stream {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 45px;
    margin-top: 10px;
    border-radius: 25px;
    background: var(--white);
    color: #FF3D30;
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    &--dark {
      border: 1px solid #FF3D30;
    }

    &__square {
      width: 12px;
      height: 12px;
      margin-left: 7px;
      border-radius: 2px;
      background: #FF3D30;
    }

    &:hover {
      background: #FF3D30;
      color: var(--white);

      div {
        background: var(--white);
      }
    }
  }
}
