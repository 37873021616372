.idt-survey-modal-results__card {
  margin-bottom: 1.5rem;
}

.idt-survey-modal-results__user-list {
  max-height: 400px;
  padding-bottom: 10px;
  overflow-y: auto;
}

.idt-survey-modal-results__user-list__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.idt-survey-modal-results__user-list__header__hint {
  color: var(--black);
}

.idt-survey-modal-results__user-list__header__hint .color {
  color: var(--main-color);
}

.idt-survey-modal-results__user-list__header .idt-select-container {
  padding: 0;
}

.idt-survey-modal-results__user-list__item {
  padding: 1rem .5rem;
  border-top: 1px solid var(--grey);
}

.idt-survey-modal-results__user-list__item>div {
  display: flex;
  align-items: center;
}

.idt-survey-modal-results__user-list__item__avatar {
  margin-right: var(--rem-size-10);
  cursor: pointer;
}

.idt-survey-modal-results__user-list__item__name {
  margin: 0;
  color: var(--black);
  font-weight: var(--font-weight-bold);
  font-size: var(--rem-size-14);
}

.idt-survey-modal-results__user-list__item__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 0;
  margin: 0;
  margin-left: auto;
  border-radius: 50%;
}

.idt-survey-modal-results__user-list__item__value {
  margin-top: .6rem;
  padding: .8rem;
  background-color: var(--white);
  border-radius: var(--global-radius);
}

