













































































.fo-lobby-team-member {
  display: flex;
  flex-flow: column;
  background: var(--white);
  border-radius: var(--global-radius);
  padding: var(--rem-size-24);
  border: 1px solid var(--grey-dark-alpha-50);
  margin-bottom: var(--rem-size-8);

  &__infos {
    display: flex;
    gap: var(--rem-size-16);

    :last-child {
      flex: auto;
    }
  }

  &__name {
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--rem-size-4);
  }

  &__buttons {
    display: flex;
    gap: var(--rem-size-8);
    margin-top: var(--rem-size-25);

    > * {
      width: 50%;
    }
    :first-child:last-child {
      width: 100%;
    }
  }
}
