







































@import '@/styles/mixins';

.modal-side {
  position: absolute;
  width: 100%;
  right: 0;
  height: 100%;
  background-color: var(--grey-lighter);

  @include respond-to(medium) {
    width: 640px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 70px;
    padding: 1rem 1.5rem;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-lighter);

    @include respond-to(max-medium) {
      width: 100%;
    }
    @include respond-to(medium) {
      width: 640px;
    }

    &__title {
      flex: 1 1 auto;
      margin: 0;
      font-weight: 300;

      @include respond-to(max-medium) {
        font-size: 1.2rem;
      }
      @include respond-to(medium) {
        font-size: 1.5rem;
      }
    }
    &__actions {
      .button {
        margin: 0;
      }
    }
    &__cancel {
      color: var(--white) !important;
    }
    &__close {
      display: block;
      position: absolute;
      top: 50%;
      right: 1.5rem;
      font-size: 25px;
      line-height: 1;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__alerts {
    position: absolute;
    top: 80px;
    width: 100%;
    z-index: 10;
  }

  &__content {
    position: absolute;
    width: 100%;
    max-height: calc(100% - 70px);
    margin: 70px 0 0 0;
    overflow-x: hidden;
    overflow-y: auto;

    &.has-footer {
      @include respond-to(max-medium) {
        max-height: calc(100% - (70px + 70px));
        margin: 70px 0;
      }
      @include respond-to(medium) {
        max-height: calc(100% - (70px + 96px));
        margin: 70px 0 96px 0;
      }
    }
    &.is-full-height {
      height: 100%;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: var(--rem-size-16);
    background-color: var(--grey-lightest);
    border-top: 2px solid var(--grey);
    text-align: center;

    @include respond-to(medium) {
      width: 580px;
    }

    &__validate {
      display: block !important;
      max-width: 250px;
      text-transform: uppercase;

      @include respond-to(max-medium) {
        margin: 1.0255rem auto !important;
      }
      @include respond-to(medium) {
        margin: 1.8385rem auto !important;
      }
    }
  }
}
