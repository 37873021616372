.fs__image-upload {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  overflow: hidden;

  @include respond-to(max-medium) {
    height: 30vh;
  }
  @include respond-to(medium) {
    height: 300px;
  }
  &--logo,
  &--favicon {
    height: 150px;
    background-size: contain;
  }
  &--logo {
    max-width: 250px;
  }
  &--favicon {
    max-width: 150px;
  }
  &--banner {
    @include respond-to(max-medium) {
      height: 25vh;
    }
    @include respond-to(medium) {
      height: 290px;
    }
  }
  &__label {
    display: block;
    width: 100%;
    height: 100%;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
    }
    &.is-set {
      color: var(--white);
      background-color: var(--black-alpha70);
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__button {
    text-align: center;

    &__spec {
      margin-top: var(--rem-size-16);
      font-size: 0.8rem;
      text-align: center;
    }
  }
  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 0.5rem;
    color: var(--white);
    border-bottom-left-radius: var(--global-radius);
    background-color: var(--danger-color);
    font-size: 1.2rem;
    text-align: center;
    line-height: 14px;
    cursor: pointer;
  }
}
