









































































































































.bo-footer-logo {
  background-size: 90% !important;
}
