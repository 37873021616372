.jitsi-publisher-container {
  height: 100%;
}

.jitsi-publisher-container__video-container {
  display: block;
  width: 100%;
  max-width: 177.78vh;
  height: 56.25vw;
  max-height: 100%;
  background: var(--grey-darkest);
}

.jitsi-publisher-container__video-container--loading {
  position: relative;
}

.jitsi-publisher-container__video-container--loading .idt-round-loader {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jitsi-publisher-container__pr-warning {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2vh;
  left: 50%;
  width: 55vw;
  max-width: 90%;
  transform: translateX(-50%);
  cursor: pointer;
}

.jitsi-publisher-container__pr-warning__close {
  position: absolute;
  top: 1rem;
  right: .75rem;
  color: var(--black);
  font-size: var(--rem-size-20);
  line-height: 0;
}

.jitsi-publisher-container__pr-warning ::v-deep .alert-card {
  width: 100%;
}

.jitsi-publisher-container__launch-stream-button {
  margin-right: 1rem;
}

.jitsi-publisher-container__ingestor-input {
  padding-bottom: 0;
  width: 30%;
}

.jitsi-publisher-container__ingestor-input ::v-deep .idt-input {
  height: 50px;
  margin: 0;
  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.jitsi-publisher-container__ingestor-input ::v-deep .idt-input input[disabled] {
  background-color: var(--white);
}

.jitsi-publisher-container__clipboard-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

