









































































































.tags-container {
  height: 120px;
  margin-bottom: 15px;
  border: 1px solid var(--grey);
  overflow: auto;
}
.vue-tags-input {
  max-width: none !important;
  width: 100% !important;
}
.vue-tags-input .ti-input {
  border: none !important;
}
.vue-tags-input .ti-tag {
  height: 30px;
  background-color: var(--main-color) !important;
  border-radius: var(--global-radius) !important;
  font-family: var(--first-font-family);
  font-size: var(--rem-size-14) !important;
  font-weight: var(--font-weight-bold);
}
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  background-color: var(--secondary-color-alpha20);
}
