
























































































































.idt-survey-conference-modal {

  &__title {
    margin-bottom: 1rem;
    font-size: var(--rem-size-18);
    font-weight: var(--font-weight-bold);
    color: var(--black);
  }

  &__content {
    .idt-input-container ::v-deep label {
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }

    &__list {
      height: 300px;
      overflow: auto;

      &__item {
        padding-bottom: var(--rem-size-12);
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--grey);
        cursor: pointer;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    height: 72px;
    padding: 0 1rem;
    border-top: 2px solid var(--grey);

    .button {
      min-width: 115px;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}
