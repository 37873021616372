






























































































































































































































































































































































































































































































































@import '@/styles/functions';
@import '@/styles/mixins';

// idt-table-actions
.idt-table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: remCalc(16);
  margin-bottom: remCalc(16);

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: remCalc(8);
  }
}

// idt-table styles
//
//  Table of Contents:
//
//  1. Common styles
//  2. Table rows
//  3. Table header cells
//  4. Table cells
//  5. Table checkbox
//  6. Table dropdown
//  7. Table pagination
//
// -----------------------------

// -----------------------------
// 1. Common styles
// -----------------------------
.idt-table {
  display: table;
  width: 100%;
  color: var(--grey-darkest);

  &__container {
    max-width: 100%;
    overflow: auto;
  }
  &__header {
    box-shadow: 0 0 0 1px var(--grey) inset;

    button {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }
  &__content {
    display: table-row-group;

    .idt-table-row {
      &:nth-child(even) {
        background-color: var(--grey-lightest);
      }
      &:nth-child(odd) {
        background-color: var(--grey-lighter);
      }
    }
  }
  button {
    white-space: nowrap;
  }
  input,
  select {
    margin: 0;
  }
  select {
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    color: var(--grey-darkest);
    font-family: var(--text-font-family) !important;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: left;
  }
}

// -----------------------------
// 2. Table rows
// -----------------------------
.idt-table-row {
  display: table-row;
  height: 60px;

  i {
    vertical-align: middle;
  }
}

// -----------------------------
// 3. Table header cells
// -----------------------------
.idt-table-header-cell {
  display: table-cell;
  padding: 0 1rem;
  font-size: 0.8rem;
  font-weight: 300;
  vertical-align: middle;
  white-space: nowrap;

  &--checkbox,
  &--action {
    position: sticky;
    border: 1px solid var(--grey);
    background-color: var(--white);
    z-index: 1;
  }
  &--checkbox {
    width: 54px;
    left: 0;
  }
  &--action,
  &--source,
  &--status,
  &--votes {
    text-align: center;
  }
  &--action {
    width: 78px;
    right: 0;

    @include respond-to(max-medium) {
      padding: 0 0.5rem;
    }
  }
  &--votes {
    max-width: 4vw;
  }
  &--answers {
    max-width: 5vw;
  }
  &--like {
    max-width: 4vw;
  }
  button {
    color: var(--grey-darkest);
  }
}

// -----------------------------
// 4. Table cells
// -----------------------------
.idt-table-cell {
  display: table-cell;
  padding: 0 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;

  &--checkbox,
  &--action {
    position: sticky;
    border-top-width: 0;
    border-right-width: 1px;
    border-bottom-width: 0;
    border-left-width: 1px;
    border-style: solid;
    border-color: var(--grey);
    background-color: inherit;
    z-index: 1;
  }
  &--checkbox {
    width: 54px;
    left: 0;
  }
  &--type,
  &--targetedgroups {
    text-transform: capitalize;
  }
  &--questions {
    max-width: 15vw;
    white-space: pre-wrap;
  }
  &--answers {
    max-width: 5vw;
  }
  &--like {
    max-width: 4vw;
  }
  &--tag {
    select {
      margin-left: -8px;
    }
  }
  &--action,
  &--source,
  &--status,
  &--votes {
    text-align: center;
  }
  &--action {
    width: 78px;
    right: 0;

    @include respond-to(max-medium) {
      padding: 0 0.5rem;
    }

    i {
      font-size: 1.75rem;
    }
  }
  &--title {
    a {
      color: var(--grey-darkest);
      &:hover {
        color: var(--main-color);
        transition: color .3s;
      }
    }
  }
  &--array-preview {
    display: inline-block;
    width: 30%;
    padding: 0.5rem;
    color: var(--grey-darkest);
    background-color: var(--grey-darkest-alpha20);
    border-radius: var(--global-radius);
    overflow: hidden;
    font-size: 0.75rem;
    text-align: center;
    text-overflow: ellipsis;
    line-height: 1.2;
    white-space: nowrap;
  }
  &--show-ellipsis {
    overflow: inherit;
    white-space: inherit;
  }
  &--hide-ellipsis {
    overflow: hidden;
    white-space: nowrap;
  }
  .idt-table-dropdown {
    display: inline-block;
    vertical-align: middle;

    &__toggle {
      padding: var(--rem-size-8);
      color: var(--main-color);
      cursor: pointer;
    }
  }
  idt-link-cell > span {
    display: flex;
  }
  idt-link-cell > span > a {
    display: inline-block;
    max-width: 100%;
    height: 2rem;
    line-height: 2.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// -----------------------------
// 5. Table checkbox
// -----------------------------
.idt-table-checkbox {
  position: absolute;
  left: -9999px;

  & + label {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0;
    border: 1px solid var(--grey);
    border-radius: calc(var(--global-radius) / 2);
    vertical-align: middle;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: transparent;
      border-radius: calc(var(--global-radius) / 3);
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
    }
  }

  &:not([checked=checked]) {
    &:checked + label:after {
      background-color: var(--grey);
    }

    &:not(:checked) + label:after {
      background-color: transparent;
    }
  }

  &[checked=checked] {
    & + label:after {
      background-color: var(--grey);
    }
  }

  &.semi-selected + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: transparent;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent #bcbec0;
    box-sizing: border-box;
    border-radius: 0;
    transition: none;
  }
}

// -----------------------------
// 6. Table dropdown
// -----------------------------
.idt-table-dropdown {
  .dropdown-pane {
    width: auto;
    min-width: 150px;
    margin-top: -1.5rem;
    padding: 0.65rem;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 5px var(--black-alpha20);
  }
  .menu {
    font-size: 0.8rem;

    li {
      &:not(:last-child) {
        &.idt-table-cell--array-preview--more {
          border-bottom: 1px solid var(--grey-lightest);
        }
        a, label {
          border-bottom: 1px solid var(--grey-lightest);
        }
      }
      a, label {
        color: var(--grey-darkest);

        &:hover {
          color: var(--main-color);
        }
      }
      .idt-table-cell--array-preview--more {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}
.idt-table-participants {
  display: inline-block;
  margin-right: 0.15rem;

  .dropdown-pane {
    width: auto;
    margin-top: 0.5rem;
    padding: 0.65rem;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 5px var(--black-alpha20);
  }
  .menu {
    li {
      &:not(:last-child) {
        border-bottom: 1px solid var(--grey-lightest);
      }
    }
  }
  &__name {
    font-size: 0.8rem;
    font-weight: 500;
  }
  &__tag {
    font-size: 0.75rem;
    font-weight: 300;
  }
}

.idt-table {
  .idt-table__header-cell {
    font-size: var(--rem-size-14);
  }
  .idt-table-cell--action {
    & ::v-deep .idt-dropdown--pane {
      max-width: 180px;
    }
  }
}
// TODO: Rename idt-table-fake-checkbox to idt-table-checkbox when Angular version will be deleted
.idt-table-fake-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 1px solid var(--grey);
  border-radius: calc(var(--global-radius) / 2);
  vertical-align: middle;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: transparent;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: transparent;
    transform: translate(-50%, -50%);
    transition: all 0.15s ease;
  }
  &.checked {
    &:after {
      border-color: var(--grey-dark);
    }
  }
  &.semi-checked {
    &:after {
      border-color: transparent transparent transparent var(--grey-dark);
    }
  }
}
