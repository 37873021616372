


























































@import '@/styles/mixins/index';

.idt-availabilities-slot-list {
  @include respond-to(max-medium) {
    width: 100%;
    height: auto;
    max-height: 400px;
    margin-bottom: var(--rem-size-15);
  }

  padding: var(--rem-size-20);
  background: var(--grey-lightest);
  overflow: auto;

  &__slot-item {
    padding: var(--rem-size-5) var(--rem-size-15);
    background: var(--main-color-dark);
    border-radius: var(--global-radius);
    text-align: left;

    &:not(:last-child) {
      margin-bottom: var(--rem-size-5);
    }

    &.empty {
      padding: var(--rem-size-10) var(--rem-size-15);
      color: var(--grey-darker);
      background: var(--grey);
      font-size: var(--rem-size-16);
      text-align: center;
      line-height: var(--rem-size-24);
    }

    &__date, &__range {
      color: var(--white);
      line-height: var(--rem-size-18);
    }

    &__date {
      font-size: var(--rem-size-12);
      font-weight: var(--font-weight-bold);
    }

    &__range {
      font-size: var(--rem-size-10);
      font-weight: var(--font-weight-medium);
    }
  }
}
