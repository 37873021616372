


























.timeline {
  min-width: 120px;
  width: 100%;
  height: 100%;
  padding-top: var(--rem-size-10);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: var(--rem-size-15);
    width: 1px;
    height: 100%;
    background-color: var(--main-color);
    transform: translateX(-50%);
  }
  &__container {
    display: flex;
    align-items: center;
    height: 128px;
    margin-bottom: var(--rem-size-10);

    &__hour {
      position: relative;
      width: 100%;
      color: var(--grey-darker);
      font-family: var(--first-font-family);
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-normal);
      text-align: center;
      text-transform: uppercase;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10%;
        height: 10px;
        width: 10px;
        background-color: var(--main-color);
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
