






















































































































































































@import '@/styles/mixins';

.event-sponsors {
  padding: 0;

  &__subtitle {
    margin-bottom: 30px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-to(max-large) {
      padding: 3rem 1rem;
    }
    @include respond-to(large) {
      padding: 5rem 3rem;
    }

    &--top-level {
      background: var(--grey-lighter);
    }
    &--level {
      padding: 25px 1rem;
    }
  }
  &__section-ultimate {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    max-width: calc(424px * 3);
  }
  &__carousel-item {
    ::v-deep .idt-simple-card {
      width: 100% !important;
      margin: 0;
    }
  }
  .idt-page-section__title {
    margin-bottom: 10px;
  }
  ::v-deep .idt-carousel {
    margin-top: 0;

    .slick-slide {
      padding: 0 10px 10px 10px;
    }
  }
}
