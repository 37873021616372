.footer-link {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem;
}

@media only screen and (min-width: 640px) {
  .footer-link__container--one {
    flex: 1 1 auto;
  }
}

@media only screen and (min-width: 640px) {
  .footer-link__container--two {
    flex: 1 1 auto;
  }
}

@media only screen and (min-width: 640px) {
  .footer-link__container--three {
    flex: 0 0 auto;
  }
}

.footer-link__input {
  padding-bottom: 0;
}

.footer-link__delete {
  color: var(--secondary-color);
  font-size: var(--rem-size-14);
  cursor: pointer;
}

.footer-link__delete:hover {
  color: var(--secondary-color-dark);
}

