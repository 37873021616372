





































































































































































































































































































































































































































































































































































































































































































































































































































































@import '@/styles/mixins';

.idt-page.confirmemail {
  .idt-profile {
    --confirm-email-margin: 70px;
  }
}
.idt-profile {
  --global-top-margin: var(--confirm-email-margin, 0px);

  margin-top: calc(var(--global-top-margin) + 70px);
  background-color: var(--grey-lighter);

  .idt-breadcrumb {
    margin-left: 23px;
  }
  &__hint{
    margin-bottom: 5px;
    font-size: var(--rem-size-12);
    color: var(--black-alpha-50);
  }
  &__container {
    display: flex;
    max-width: 1170px;
    margin: auto;
    padding-top: 40px;
    justify-content: space-between;
    padding-left: var(--rem-size-15);
    padding-right: var(--rem-size-15);

    &__main {
      flex-shrink: 1;
      max-width:825px;
      width:100%;
      padding-bottom: calc(100vh - 740px); // For big screens to intersect correctly with last fieldset

      @include respond-to(max-slarge) {
        max-width: initial;
      }
    }

    @include respond-to(max-slarge) {
      padding: 40px 0;
    }

    & > .idt-side-menu {
      --sticky-margin: var(--global-top-margin);

      @include respond-to(max-slarge) {
        display: none;
      }
    }
  }
  &__header-button {
    height: 35px;
    padding: 0px 45px;
    margin: 0;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-size: 1rem;
  }
  &__input-section {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: var(--rem-size-16);

    & > .idt-input-container {
      @include respond-to(max-medium) {
        width: 100%;
      }
      @include respond-to(medium) {
        max-width: calc(50% - var(--rem-size-8));
      }
    }
    &__input {
      margin-bottom: 1.5rem;
    }
    &__input, .idt-input-container {
      flex-grow: 1;
      min-width: 250px;
    }
  }
  &__fieldset-action-btn {
    @include respond-to(slarge){
      display: none !important;
    }
  }
  &__footer {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 35px;
    padding-top: 0;
    background: transparent;
    z-index: 1;

    @include respond-to(slarge) {
      display: none;
    }

    &__save-btn {
      margin: 0;
    }
  }
  &__menu-mobile {
    position: fixed;
    top: calc(70px + var(--global-top-margin));
    height: calc(100vh - 70px);
    width: 100%;
    padding: 16px;
    background: var(--white);
    z-index: 1;

    @include respond-to(slarge) {
      display: none;
    }

    &__footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 16px 35px;
      border-top: 2px solid var(--grey-light);
    }
    &__header {
      display:flex;
      align-items:center;
      margin-bottom: 12px;

      &__title {
        font-weight: var(--font-weight-bold);
        margin: 0;
        margin-left: 10px;
        text-transform: uppercase;
        color: var(--black);
      }
    }
  }
  .idt-fo-subheader {
    --sticky-margin: var(--global-top-margin);

    @include respond-to(max-slarge){
      display: none;
    }
  }
  .user-tags {
    margin: 0 -30px;
    padding: 30px;
    background: var(--grey-lightest);

    &__item img {
      width: 2rem;
      height: 2rem;
      margin-right: 10px;
    }
  }
  .idt-user-profile-container {
    position: fixed;
    right: 0;
    height: 100%;
    top: 0px;
    z-index: 2000;
  }
}

// Transition styles
.fade-mm-enter-active,
.fade-mm-leave-active {
  transition: all 0.3s ease;
}
.fade-mm-enter,
.fade-mm-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
