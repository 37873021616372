









































































































































































































































































































.idt-video-controls__exit-fullscreen.dark {
  color: var(--grey-darkest);
}
