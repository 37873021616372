















































































































































































@import '@/styles/mixins/index';

.insight-card {
  flex: 0 1 auto;
  position: relative;
  margin-bottom: 2.125rem;
  background-color: var(--white);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);

  @include respond-to(max-large) {
    width: calc(100vw - 48px);
  }
  @include respond-to(large) {
    width: 911px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 300px;
    background-color: var(--grey-lightest);
    border-radius: 100%;
    transform: translate(-50%, -50%);

    @include respond-to(max-large) {
      left: -1.075rem;
      width: 16px;
      height: 16px;
      border: 1px solid var(--grey);
    }
    @include respond-to(large) {
      left: -5.5rem;
      width: 22px;
      height: 22px;
      border: 2px solid var(--grey);
    }
  }
  &__banner {
    display: block;
    height: 300px;
    background-color: var(--grey-lightest);
    background-position: center;
    background-size: cover;

    &__container {
      position: relative;
    }
  }
  &__status {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white);
    font-weight: 700;
    text-transform: uppercase;

    &__content {
      padding: 0.25rem 0.5rem;
      color: var(--secondary-color-dark);
      background-color: var(--secondary-color-lightest);
    }
  }
  &__actions {
    position: absolute;
    right: 1rem;
    bottom: 1.25rem;

    .button {
      margin: 0;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;

      i {
        font-size: 1.1rem;
        vertical-align: text-bottom;
      }
    }
  }
  &__content {
    color: var(--grey-dark);

    @include respond-to(max-large) {
      padding: 1rem 1.25rem;
      font-size: 1rem;
    }
    @include respond-to(large) {
      padding: 2rem;
      font-size: 1.1rem;
    }
  }
  &__title {
    display: inline-block;
    color: var(--grey-darker);
    font-size: 1.5rem;
    font-weight: 300
  }
  &__info {
    margin-bottom: var(--rem-size-8);
    font-weight: var(--font-weight-medium);

    &__date,
    &__author {
      color: var(--main-color);

      &.pointable {
        cursor: pointer;
      }
    }
    &__pipe {
      color: var(--grey);
    }
  }
  &__text {
    margin-top: var(--rem-size-8);

    &:not(.article) {
      p {
        display: inline;
      }
      img {
        display: none !important;
        width: 0;
        height: 0;
        visibility: hidden;
      }
    }
    table {
      display: block;
      max-width: 100%;
      overflow-x: auto;
    }
  }
  &.no-illustration &__actions {
    top: 16px;
  }
  &.no-illustration:before {
    top: 50%;
  }
}
