








































































.sponsor-edit-modal {
  margin: -45px -40px -25px;

  &__content,
  &__buttons,
  &__title {
    padding: var(--rem-size-24) var(--rem-size-40);
    border-bottom: 1px solid var(--grey);
  }

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: var(--rem-size-18);
    line-height: var(--rem-size-24);
    color: var(--black);
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: none;

    .button {
      margin: 0;
    }
  }

  .idt-textarea-container {
    padding-bottom: 0;

    label {
      margin-bottom: var(--rem-size-8);
      font-weight: var(--font-weight-bold);
      font-size: var(--rem-size-12);
      line-height: var(--rem-size-18);
      color: var(--black);
    }

    .idt-textarea-counter {
      bottom: 0;
      font-size: var(--rem-size-10);
      line-height: var(--rem-size-12);
    }
  }
}
