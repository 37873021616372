.chat-hiring-modal {
  display: block;
  position: relative;
  width: 100%;
  background-color: var(--white);
  border-bottom: 2px solid var(--main-color);
  z-index: 1;

  &__content {
    padding: 0.9rem 40px;
    background-color: var(--main-color-lighter);
  }
  &__text {
    margin-bottom: 0.25rem;
    color: var(--grey-darkest);
    font-weight: var(--font-weight-medium);
    text-align: center;
  }
  &__timer {
    margin-bottom: 0.5rem;
    color: var(--grey-darkest);
    font-size: 0.9rem;
    font-weight: var(--font-weight-medium);
    text-align: center;

    .ideacons {
      margin-right: 0.25rem;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 0;

    .ideacons {
      vertical-align: middle;
    }
  }
  .button {
    margin: 0 0.25rem !important;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .idea-ht-checked,
    .idea-ht-close {
      margin-left: 0.25rem;
      font-size: 0.8rem;
    }
  }
}
