
































































.idt-bo-survey-results {
  &__export-action {
    padding: 8px 40px;
  }

  &__item {
    padding: 1rem .7rem;
    border-top: 1px solid var(--grey);
  }
}
