.custom-questions-tab {
  padding-top: var(--rem-size-12);
}

.custom-questions-tab__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 639px) {
  .custom-questions-tab__container {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 640px) {
  .custom-questions-tab__container {
    padding: 0;
  }
}

.custom-questions-tab__container__title {
  max-width: 600px;
  margin-bottom: 15px;
  color: var(--grey-darkest);
  font-family: var(--text-font-family);
  font-weight: var(--font-weight-light);
  font-size: 1.875rem;
  text-align: center;
  text-transform: uppercase;
}

.custom-questions-tab__container__description {
  max-width: 600px;
  margin-bottom: 25px;
  color: var(--main-color);
  font-family: var(--text-font-family);
  font-weight: var(--font-weight-normal);
  font-size: 1rem;
  text-align: center;
}

.custom-questions-tab__back {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grey-darker);
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.custom-questions-tab__back {
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;
}

.custom-questions-tab__back span {
  margin-left: 10px;
}

