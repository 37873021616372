.conference-primary-info {
  width: 100%;
  min-height: var(--conference-footer-height);
  background: #eeeff2;
  border-top: #d0cfd5 solid 3px;
}

.conference-primary-info--no-separator {
  border-top: none;
}

.conference-primary-info__container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.conference-primary-info__presentation-image,
.conference-primary-info__avatar {
  flex: 0 0 auto;
  width: 75px;
  height: 75px;
  margin: 1.875rem;
}

.conference-primary-info__presentation-image {
  border-radius: 50%;
  background-color: var(--grey-darkest-alpha20);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

.conference-primary-info__title {
  flex: 0 1 auto;
  margin-top: 22px;
  color: var(--black);
  font-size: 1.375rem;
  font-weight: var(--font-weight-medium);
  text-overflow: ellipsis;
  overflow: hidden;
}

.conference-primary-info__tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--rem-size-3);
  padding-bottom: 1rem;
}

.conference-primary-info__favorite,
.conference-primary-info__moderator-buttons {
  flex: 0 0 auto;
  margin: 1.25rem 1.25rem 0 auto;
  padding-left: .75rem;
}

.conference-primary-info__favorite ::v-deep .ideacon,
.conference-primary-info__moderator-buttons ::v-deep .ideacon {
  vertical-align: unset;
}

