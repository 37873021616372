.input-dropdown,
.button-dropdown {
  position: relative;

  &__input {
    &[disabled] {
      margin: 0;
      background-color: var(--white);
      padding-right: 2rem;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
      background-position: 98% center;
      background-repeat: no-repeat;
      opacity: 1;
      cursor: pointer;
      pointer-events: none;
    }
    &__container {
      position: relative;
      cursor: pointer;
    }
    &.div {
      min-height: 53px;
      pointer-events: auto;

      .tag-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .tag-item {
          height: 29px;
          color: var(--white);
          background: var(--main-color);
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          font-weight: 500;
          line-height: 28px;
          margin: 2px;
          padding: 0 5px;
          display: inline-block;
          border: 1px solid var(--main-color);
          border-radius: 3px;

          .remove-button {
            margin: 0 0 0 5px;
            padding: 0;
            border: none;
            color: var(--white);
            background: 0 0;
            vertical-align: middle;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__button {
    margin: 0;
    color: var(--main-color);
    background-color: var(--white);
    border-color: var(--main-color);
    text-transform: uppercase;

    &.dropdown:after {
      border-color: var(--main-color) transparent transparent;
    }
    &:hover {
      color: var(--main-color);
      background-color: var(--white);
    }
  }
  .dropdown-pane {
    width: 236px;
    max-height: 50vh;
    margin: -2.75rem 0 0 -0.25rem;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 5px rgba($black, 0.2);
    overflow-y: auto;

    &__header {
      position: relative;
      min-height: 40px;
      color: var(--main-color-dark);
      background-color: var(--main-color-alpha10);
      font-size: 0.8rem;
      font-weight: 500;
      text-transform: uppercase;

      &:not(.counter):not(.checkbox) {
        padding: 0.7rem 1rem;
      }
      &.counter {
        padding: 0.7rem 2.5rem 0.7rem 1rem;
      }
      .count {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 1rem;
        color: var(--secondary-color);
        transform: translateY(-50%);
      }
      &.checkbox {
        padding: 0.7rem 3rem 0.7rem 1rem;
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 1.5rem;
          width: 16px;
          height: 16px;
          border: 2px solid var(--white);
          border-radius: 100%;
          box-shadow: 0 0 0 2px var(--main-color);
          transform: translateY(-50%);
        }

        &.checked {
          &:before {
            background-color: var(--main-color);
          }
        }
      }
    }
    button {
      width: 100%;
      height: 40px;
      padding: 0.7rem 1rem;
      border-radius: 0;
      font-family: var(--text-font-family);
      text-align: left;
      cursor: pointer;

      &.add {
        position: relative;
        color: var(--main-color-dark);
        background-color: var(--main-color-alpha10);
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: uppercase;

        i {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
        }
      }
    }
    &__checkbox {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0.7rem 3rem 0.7rem 1rem;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        width: 16px;
        height: 16px;
        border: 2px solid var(--white);
        border-radius: 100%;
        box-shadow: 0 0 0 2px var(--main-color);
        transform: translateY(-50%);
      }
      input[type=radio]:checked + &:before,
      input[type=checkbox]:checked + &:before,
      input[type=checkbox].ng-valid + &:before {
        background-color: var(--main-color);
      }
    }
    input[type=radio],
    input[type=checkbox] {
      display: none;
    }
  }
  &__title {
    background-color: var(--main-color-alpha20);
    color: var(--main-color);
  }
  .menu {
    max-width: 100%;
    font-size: 0.8rem;
    overflow-x: hidden;

    &:not(.multiple) {
      max-height: 200px;
      overflow-y: auto;
    }
    li {
      &:not(:last-child) {
        a,
        button {
          border-bottom: 1px solid var(--grey-lightest);
        }
      }
      a,
      button {
        color: var(--grey-darker);

        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
}

input[type=checkbox].checked + .dropdown-pane__checkbox:before {
  background-color: var(--main-color);
}

.button-dropdown {
  display: inline-block;
}
