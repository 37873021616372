
































































































































































@import '@/styles/mixins/index';
.idt-time-picker {
  user-select: none;
  &__step {
    @include respond-to(max-medium) {
      height: 40px;
      line-height: 48px;
    }
    width: 55px;
    height: 17px;
    border: 1px solid var(--grey-light);
    border-radius: var(--global-radius);
    text-align: center;
    line-height: var(--rem-size-22);
    cursor: pointer;

    &:hover {
      border-color: var(--grey-darker);
    }

    .idt-icon {
      color: var(--grey-darkest);
    }
  }

  &__time {
    &__input {
      padding-bottom: 0;

      ::v-deep input {
        width: 55px;
        height: 34px;
        margin: var(--rem-size-4) 0;
        padding: 0;
        border: 1px solid var(--main-color);
        border-radius: var(--global-radius);
        font-size: var(--rem-size-30);
        text-align: center;
        box-sizing: border-box;
        outline: none;
      }
    }

    &__display {
      width: 55px;
      height: 34px;
      line-height: 34px;
      margin: var(--rem-size-4) 0;
      color: var(--black);
      background: var(--grey-lighter);
      border-radius: var(--global-radius);
      font-size: var(--rem-size-30);
      text-align: center;

      &.editable {
        cursor: text;
      }
    }
  }
}
