.facebook {
  background-color: var(--facebook-color);
}

.youtube {
  background-color: var(--youtube-color);
}

.linkedin {
  background-color: var(--linkedin-color);
}

.twitter {
  background-color: var(--twitter-color);
}

.hide {
  display: none;
}

.sub-block {
  padding-top: var(--rem-size-40);
}

.social-media__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--rem-size-8);
  margin-bottom: var(--rem-size-8);
}

.social-media__content__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  color: var(--white);
  border-radius: 50%;
}

.social-media__content__input {
  width: calc(100% - 52px);
  padding-bottom: 0 !important;
}

.social-media__content__input.error {
  padding-bottom: var(--rem-size-24) !important;
}

.social-media__content__input ::v-deep .idt-required-field {
  position: initial;
}

@media only screen and (max-width: 639px) {
  .fo-project-section-information-edit {
    --image-preview-height: 10rem;
    padding: var(--rem-size-20) var(--rem-size-20) var(--rem-size-40) var(--rem-size-20);
  }
}

@media only screen and (min-width: 640px) {
  .fo-project-section-information-edit {
    --image-preview-height: 372px;
    padding: 48px;
  }
}

.fo-project-section-information-edit h2 {
  margin-bottom: var(--rem-size-24);
  font-size: var(--rem-size-24);
}

.fo-project-section-information-edit__medias .tabs-selection {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background-color: var(--grey-light);
}

.fo-project-section-information-edit__medias .tabs-selection__button {
  width: 50%;
  margin: var(--rem-size-4);
  color: var(--grey-darkest);
  background-color: rgba(0,0,0,0);
  border-radius: 0;
  font-weight: bold;
}

.fo-project-section-information-edit__medias .tabs-selection__button:first-child {
  margin-right: var(--rem-size-2);
}

.fo-project-section-information-edit__medias .tabs-selection__button:last-child {
  margin-left: var(--rem-size-2);
}

.fo-project-section-information-edit__medias .tabs-selection__button.selected {
  color: var(--main-color);
  background-color: var(--white);
  box-shadow: 0px 1px 1px var(--black-alpha15);
}

.fo-project-section-information-edit__medias .tab {
  padding-top: var(--rem-size-16);
}

.fo-project-section-information-edit__medias .tab__image__container {
  display: flex;
  flex-wrap: wrap;
}

.fo-project-section-information-edit__medias .tab__image__container__preview-file {
  position: relative;
  width: 100%;
}

.fo-project-section-information-edit__medias .tab__image__container__preview-file__component {
  width: 100%;
  height: var(--image-preview-height);
  min-height: 200px;
}

.fo-project-section-information-edit__medias .tab__image__container__preview-file__actions {
  display: inline-grid;
  gap: var(--rem-size-8);
  grid-template-columns: 1fr 1fr;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: var(--rem-size-10);
  background-color: var(--grey-darkest-alpha75);
  border-radius: var(--rem-size-5);
  transform: translate(-50%, -50%);
  z-index: 5;
}

.fo-project-section-information-edit__medias .tab__image__container__upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--image-preview-height);
  min-height: 200px;
  padding: var(--rem-size-32);
  border: 1px dashed var(--grey);
  border-radius: var(--global-radius);
}

.fo-project-section-information-edit__medias .tab__image__container__upload-file.error {
  border-color: var(--red);
}

.fo-project-section-information-edit__medias .tab__image__container .upload-infos {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--rem-size-8);
  font-size: var(--rem-size-14);
}

.fo-project-section-information-edit__medias .tab__image ::v-deep .file-preview__figure {
  width: 100%;
  height: var(--image-preview-height);
  min-height: 200px;
  border-radius: var(--rem-size-12);
}

.fo-project-section-information-edit__logo {
  display: flex;
  justify-content: space-between;
}

.fo-project-section-information-edit__logo .project-logo {
  width: 96px;
  height: 96px;
}

.fo-project-section-information-edit__logo .project-logo__letter,
.fo-project-section-information-edit__logo .project-logo__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--grey-darkest);
  background-color: var(--grey-lighter);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  font-size: var(--rem-size-24);
}

.fo-project-section-information-edit__logo .project-logo-actions {
  width: calc(100% - 120px);
}

.fo-project-section-information-edit__logo .project-logo-actions__text__title {
  color: var(--grey-darkest);
}

.fo-project-section-information-edit__logo .project-logo-actions__text__resolution {
  color: var(--grey-darker);
}

.fo-project-section-information-edit__logo .project-logo-actions__buttons {
  margin-top: var(--rem-size-4);
}

@media only screen and (min-width: 640px) {
  .fo-project-section-information-edit__logo .project-logo-actions__buttons {
    display: flex;
    justify-content: flex-start;
    gap: var(--rem-size-8);
  }
}

.fo-project-section-information-edit__ordered {
  display: flex;
  flex-direction: column;
}

.fo-project-section-information-edit__ordered.no-social-links .fo-project-section-information-edit__ordered__about,
.fo-project-section-information-edit__ordered.no-social-links .fo-project-section-information-edit__ordered__links {
  width: 100% !important;
  padding-right: 0;
}

@media only screen and (min-width: 640px) {
  .fo-project-section-information-edit__ordered {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--rem-size-64);
  }
}

.fo-project-section-information-edit__ordered--description {
  margin-bottom: var(--rem-size-4);
  padding: 0;
  border: none;
}

.fo-project-section-information-edit__ordered--description ::v-deep .idt-textarea-counter {
  bottom: var(--rem-size-8);
}

.fo-project-section-information-edit__ordered__about {
  order: 1;
  flex: 1 1 auto;
  min-width: 50%;
}

@media only screen and (max-width: 991px) {
  .fo-project-section-information-edit__ordered__social-medias {
    order: 3;
  }
}

@media only screen and (min-width: 992px) {
  .fo-project-section-information-edit__ordered__social-medias {
    order: 2;
  }
}

@media only screen and (max-width: 991px) {
  .fo-project-section-information-edit__ordered__social-medias {
    width: 100%;
  }
}

@media only screen and (min-width: 992px)and (max-width: 1023px) {
  .fo-project-section-information-edit__ordered__social-medias {
    width: 33%;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .fo-project-section-information-edit__ordered__social-medias {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) {
  .fo-project-section-information-edit__ordered__social-medias {
    width: 33%;
  }
}

@media only screen and (max-width: 991px) {
  .fo-project-section-information-edit__ordered__links {
    order: 2;
  }
}

@media only screen and (min-width: 992px) {
  .fo-project-section-information-edit__ordered__links {
    order: 3;
  }
}

@media only screen and (max-width: 991px) {
  .fo-project-section-information-edit__ordered__links {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px)and (max-width: 1199px) {
  .fo-project-section-information-edit__ordered__links {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) {
  .fo-project-section-information-edit__ordered__links {
    width: calc(100% - (33% + var(--rem-size-64)));
  }
}

