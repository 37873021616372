.idt-radio-button-group {
  display: flex;
  align-items: center;
  border-radius: var(--global-radius);
}

.idt-radio-button-group label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  padding: var(--rem-size-12);
  border: 1px solid var(--main-color);
  color: var(--main-color);
  text-transform: capitalize;
  transition: all .25s ease-out,color .25s ease-out;
}

.idt-radio-button-group label:first-of-type {
  border-radius: var(--global-radius) 0 0 var(--global-radius);
  border-right: none;
}

.idt-radio-button-group label:last-of-type {
  border-radius: 0 var(--global-radius) var(--global-radius) 0;
  border-left: none;
}

.idt-radio-button-group label:hover {
  border-color: var(--main-color-light);
  color: var(--main-color-light);
}

.idt-radio-button-group input[type=radio]:checked+label {
  background-color: var(--main-color);
  color: var(--white);
}

.idt-radio-button-group input[type=radio]:checked+label:hover {
  background-color: var(--main-color-light);
}

.idt-radio-button-group input[type=radio] {
  display: none;
}

.simple .idt-radio-button-group__container {
  display: none;
}

.simple label {
  justify-content: flex-start;
  margin-left: var(--rem-size-8);
  padding: 0;
  color: var(--grey-darkest);
  border: 0;
  font-weight: 400;
  font-size: var(--rem-size-14);
}

.simple label:hover {
  color: var(--grey-darkest);
}

.simple input[type=radio]::before {
  content: "";
  width: var(--rem-size-8);
  height: var(--rem-size-8);
  border-radius: 50%;
  box-shadow: inset var(--rem-size-8) var(--rem-size-8) var(--idt-info-color);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.simple input[type=radio]:checked::before {
  transform: scale(1);
}

.simple input[type=radio]:checked+label {
  color: var(--grey-darkest);
  background-color: rgba(0,0,0,0);
}

.simple input[type=radio]:checked+label:hover {
  background-color: rgba(0,0,0,0);
}

.simple input[type=radio] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--rem-size-18);
  height: var(--rem-size-18);
  margin: 0;
  color: var(--idt-info-color);
  border: var(--rem-size-2) solid var(--idt-info-color);
  border-radius: 50%;
  appearance: none;
  font: inherit;
}

