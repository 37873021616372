




































































































































































.filters {
  height: 100%;
  width: 300px;
  background-color: var(--white);
  margin-right: var(--rem-size-16);
  overflow-y: auto;
  padding-top: var(--rem-size-16);
  &__title {
    margin-left: 20px;
    margin-top: 18px;
    color: var(--black);
    font-family: var(--first-font-family);
    font-size: var(--rem-size-14);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.7px;
    text-align: center;
    text-transform: uppercase;
  }
  &__close-btn {
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      color: var(--red);
    }
  }
  &__categories {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 18px;
    &__category {
      padding-bottom: var(--rem-size-8);
      &__container {
        align-items: center;
        min-height: 25px;
        border-radius: 25px;
        font-family: var(--first-font-family);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-bold);
        word-break: break-word;
        &.opened {
          background-color: var(--main-color);
          color: var(--white);
        }
        &.closed {
          background-color: var(--grey-lighter);
          color: var(--black);
        }
        &__selected-number {
          height: 15px;
          width: 15px;
          background-color: var(--main-color);
          border-radius: 100%;
          color: var(--white);
          font-family: var(--first-font-family);
          font-size: var(--rem-size-10);
          font-weight: var(--font-weight-medium);
          text-align: center;
        }
      }
      &__container:hover {
        cursor: pointer;
      }
      &__tracks {
        margin-top: 10px;
        &__container {
          align-items: center;
          margin-bottom: 10px;
          margin-right: 14px;
          cursor: pointer;
          color: var(--black);
          font-family: var(--first-font-family);
          font-size: var(--rem-size-14);
          font-weight: var(--font-weight-medium);
          letter-spacing: 0px;
          line-height: 16px;
          text-align: left;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          &:hover {
            color: var(--grey-darker);
          }
          &__customCheckMark {
            height: 18px;
            width: 18px;
            background-color: var(--grey);
            border-radius: var(--global-radius);
          }
          &__customCheckMark:after {
            content: "";
            display: none;
            position: relative;
          }
        }
        &__container input {
          position: absolute;
          height: 0;
          width: 0;
          cursor: pointer;
        }
        &__container input:checked ~ &__container__customCheckMark {
          background-color: var(--main-color);
        }
        &__container input:checked ~ &__container__customCheckMark:after {
          display: flex;
        }
        &__container &__container__customCheckMark:after {
          left: 6px;
          height: 14px;
          width: 7px;
          border: solid var(--white);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
        }
        &__separation {
          margin: 0;
          margin-bottom: var(--rem-size-8);
        }
      }
    }
  }
}

