.live {
  height: 100%;
  padding: 1.5rem 1.25rem;
  color: var(--white);
  background-color: var(--main-color);
  font-size: var(--rem-size-14);
}

.live .highlight {
  margin-bottom: .3125rem;
  padding: .3125rem .9375rem;
  color: var(--main-color-dark);
  background-color: var(--main-color-lighter);
  border-radius: 5px;
}

.live__users-header {
  display: flex;
  justify-content: space-between;
}

.live__users-header__refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  cursor: pointer;
}

.live__users-number {
  font-size: var(--rem-size-40);
}

.live__content .content__title {
  margin-top: .9375rem;
  margin-bottom: .3125rem;
}

.live__content .content__number {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .live__top-talk {
    padding-right: .5rem;
  }

  .live__top-talk:last-child {
    padding-left: .5rem;
    padding-right: 0;
  }
}

.live__top-talk .top-talk__header {
  margin-bottom: .3125rem;
}

@media only screen and (min-width: 992px) {
  .live__top-talk .top-talk__header {
    margin-top: .9375rem;
  }
}

.live__top-talk .top-talk__no-data {
  padding: .3125rem .9375rem;
}

@media only screen and (max-width: 991px) {
  .live__top-conference {
    display: flex;
    margin-top: 1.25rem;
  }
}

.live__loader {
  width: 50px;
  height: 50px;
  margin-top: .625rem;
}

