




























































.idt-circular-progress {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  border-radius: 50%;
  box-shadow: inset var(--grey) 0px 0px 0px 5px;

  &__circle {
    position: relative;
    width: 40px;
    height: 40px;
    background: transparent;
    border-radius: 50%;
    overflow: hidden;

    &__segment {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      background: var(--main-color);
      transform-origin: 0 0;
      transition: transform .3s ease;
    }
  }

  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 32px;
    height: 32px;
    transform: translateX(-50%) translateY(-50%);
    background: var(--grey-lighter);
    border-radius: 50%;

    &--dark {
      background-color: var(--grey-darkest);
    }
  }

  &__value {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: var(--font-weight-bold);
    font-size: var(--rem-size-12);
    color: var(--black);

    &--dark {
      color: var(--white);
    }
  }
}
