


































































@import '@/styles/mixins/index';

.idt-confirm-email-headband {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: var(--menu-height);
  color: var(--warning-color-dark);
  background-color: var(--warning-color-alpha20);
  z-index: 999;

  @include respond-to(max-medium) {
    flex-direction: column;
    padding: var(--rem-size-16);
  }
  @include respond-to(medium) {
    padding: 0 var(--rem-size-16);
  }

  &__text {
    @include respond-to(max-medium) {
      margin: var(--rem-size-16) 0;
      text-align: center;
    }
    @include respond-to(medium) {
      margin: 0 var(--rem-size-16);
    }
  }
  &__btn {
    @include respond-to(medium) {
      margin-left: auto !important;
    }
  }
}
