.conference-viewers-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--rem-size-10);
  background-color: var(--white);
  overflow-y: auto;
}

.conference-viewers-list .idt-input {
  margin-bottom: var(--rem-size-10);
  padding: var(--rem-size-6) var(--rem-size-10);
}

.conference-viewers-list .vs__dropdown-toggle {
  height: 40px;
}

.conference-viewers-list .vs__search {
  margin: 0;
  padding-left: var(--rem-size-8) !important;
}

.conference-viewers-list .idt-select-container {
  height: 50px;
}

.conference-viewers-list__list {
  flex-grow: 1;
  overflow: auto;
}

.conference-viewers-list__list div[role=group] :last-child .conference-viewers-list-item {
  border-bottom: 0 !important;
}

