.fo-project-mode-edit-new-project-message-modal__title {
  font-weight: var(--font-weight-medium);
  color: var(--black);
}

.fo-project-mode-edit-new-project-message-modal__content {
  margin-top: var(--rem-size-16);
  margin-bottom: var(--rem-size-20);
  color: var(--grey-darker);
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-normal);
}

.fo-project-mode-edit-new-project-message-modal__content__first-line {
  margin-bottom: 0;
}

.fo-project-mode-edit-new-project-message-modal__input {
  width: 90%;
}

.fo-project-mode-edit-new-project-message-modal .button {
  width: 45%;
  font-size: var(--rem-size-16);
  font-weight: var(--font-weight-medium);
}

