.idt-create-survey-rating__title {
  font-size: var(--rem-size-12);
  color: var(--black);
  font-weight: var(--font-weight-bold);
}

.idt-create-survey-rating__row {
  display: flex;
  align-items: center;
  margin-bottom: var(--rem-size-10);
}

.idt-create-survey-rating__row__stars .idt-icon.active {
  color: var(--yellow);
}

.idt-create-survey-rating__row .idt-input-container {
  flex-grow: 1;
  padding-bottom: 0;
  margin-left: 1rem;
}

