.fo-project-section-hiring-edit {
  text-align: center;
}

.fo-project-section-hiring-edit__title {
  margin-bottom: var(--rem-size-16);
  color: #000;
}

.fo-project-section-hiring-edit__no-hiring {
  margin-bottom: var(--rem-size-16);
  color: #000;
  text-align: center;
}

.fo-project-section-hiring-edit__not-hiring {
  display: flex;
  justify-content: center;
  font-size: var(--rem-size-36);
  margin: var(--rem-size-40) 0;
}

