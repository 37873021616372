.chose-modal {
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .chose-modal {
    max-width: 100%;
  }
}

.chose-modal__title {
  color: var(--main-color);
  font-weight: var(--font-weight-normal);
}

.chose-modal__subtitle {
  color: var(--grey-900);
}

@media only screen and (min-width: 1024px) {
  .chose-modal__subtitle {
    max-width: 550px;
    margin: auto;
  }
}

.chose-modal__content {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .chose-modal__content {
    flex-flow: column wrap;
  }
}

.chose-modal__content__item {
  flex-flow: column wrap;
  flex: 1 1 auto;
}

.chose-modal__content__item.choose {
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .chose-modal__content__item.choose {
    padding: 0 90px;
  }
}

@media only screen and (min-width: 1024px) {
  .chose-modal__content__item.choose {
    max-width: 400px;
  }
}

@media only screen and (min-width: 640px) {
  .chose-modal__content__item:not(.choose) {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 1024px) {
  .chose-modal__content__item:not(.choose) {
    max-width: 600px;
  }
}

.chose-modal__content__item:not(:last-child) {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .chose-modal__content__item:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .chose-modal__content__item:not(:last-child).choose:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 70%;
    border-right: 1px solid var(--grey);
    transform: translateY(-50%);
  }
}

.chose-modal__content__icon {
  margin-top: 1rem;
  color: var(--grey-900);
  font-size: 7rem;
}

.chose-modal__content__title {
  margin-bottom: 1rem;
  color: var(--main-color);
  font-family: var(--title-font-family);
  font-size: 1.25rem;
  text-transform: uppercase;
}

@media only screen and (min-width: 1024px) {
  .chose-modal__content__text {
    min-height: 125px;
  }
}

.chose-modal__button {
  min-width: 235px;
  height: 55px;
  font-family: var(--text-font-family);
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

