.fo-project-section-information-view {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);
  margin-bottom: 3.3125rem;
}

.fo-project-section-information-view__image {
  height: 372px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-color-lighter);
  border-radius: var(--rem-size-12);
}

.fo-project-section-information-view__image div {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__image {
    height: 200px;
  }
}

.fo-project-section-information-view__video {
  height: 372px !important;
  border-radius: var(--rem-size-12);
  overflow: hidden;
}

.fo-project-section-information-view__video iframe {
  height: 100% !important;
}

.fo-project-section-information-view__content {
  display: flex;
  margin-top: var(--rem-size-32);
  color: var(--grey-darker);
  font-size: var(--rem-size-16);
  line-height: var(--rem-size-24);
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__content {
    display: block;
    margin-top: var(--rem-size-24);
  }
}

.fo-project-section-information-view__content strong {
  display: block;
  color: var(--grey-darkest);
  font-weight: var(--font-weight-medium);
}

.fo-project-section-information-view__content__avatar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  margin-right: var(--rem-size-24);
  color: var(--grey-darkest);
  font-size: var(--rem-size-24);
  font-weight: var(--font-weight-medium);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--grey-lightest);
  border-radius: 999px;
}

.fo-project-section-information-view__content__avatar div {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__content__avatar {
    margin-bottom: var(--rem-size-24);
  }
}

.fo-project-section-information-view__content__texts {
  flex: auto;
  word-break: break-word;
}

.fo-project-section-information-view__content__texts__blocks {
  display: flex;
  flex: auto;
  margin-top: var(--rem-size-25);
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__content__texts__blocks {
    display: block;
  }
}

.fo-project-section-information-view__content__texts__blocks__categories {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: calc(var(--rem-size-8)*-1);
}

.fo-project-section-information-view__content__texts__blocks__categories>div {
  width: calc(50% - var(--rem-size-16));
  margin: var(--rem-size-8);
  padding-left: var(--rem-size-10);
  border-left: var(--rem-size-1) solid var(--grey-lighter);
  line-height: var(--rem-size-24);
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__content__texts__blocks__categories {
    align-items: stretch;
    margin-left: calc(var(--rem-size-18)*-1);
  }

  .fo-project-section-information-view__content__texts__blocks__categories>div:nth-child(odd) {
    border-left: none;
  }
}

.fo-project-section-information-view__misc {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 0;
  min-width: 300px;
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__misc {
    margin-top: var(--rem-size-24);
  }
}

@media only screen and (min-width: 1200px) {
  .fo-project-section-information-view__misc .idt-dropdown--pane {
    transform: translateX(calc(45px - 100%));
  }
}

.fo-project-section-information-view__misc__votes {
  display: inline-flex;
  align-items: center;
  margin-bottom: var(--rem-size-24);
  margin-left: var(--rem-size-4);
  padding: 0 var(--rem-size-24);
  height: 45px;
  color: var(--white);
  background: var(--main-color);
  border-radius: 999px;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
}

.fo-project-section-information-view__misc__votes .idt-icon {
  display: block;
  margin-right: var(--rem-size-18);
}

.fo-project-section-information-view__misc__votes:hover {
  background: var(--main-color-dark);
}

.fo-project-section-information-view__misc__links,
.fo-project-section-information-view__misc__social-links {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__misc__links,
  .fo-project-section-information-view__misc__social-links {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1200px) {
  .fo-project-section-information-view__misc__links,
  .fo-project-section-information-view__misc__social-links {
    justify-content: flex-end;
  }
}

.fo-project-section-information-view__misc__links {
  flex-direction: column;
  align-items: flex-end;
  margin-top: var(--rem-size-16);
}

.fo-project-section-information-view__misc__links a {
  max-width: 250px;
  padding: var(--rem-size-8) var(--rem-size-12);
  color: var(--grey-darkest);
  border-radius: var(--global-radius);
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fo-project-section-information-view__misc__links a:hover {
  color: var(--grey-darkest);
  background: var(--grey-lighter);
}

.fo-project-section-information-view__misc__social-links {
  flex-wrap: wrap;
}

.fo-project-section-information-view__misc__social-links>a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  color: var(--grey-darkest);
  background-color: var(--grey-light);
  border-radius: 100%;
  transition: color background-color .3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .fo-project-section-information-view__misc__social-links>a {
    margin-right: var(--rem-size-8);
  }
}

@media only screen and (min-width: 1200px) {
  .fo-project-section-information-view__misc__social-links>a {
    margin-left: var(--rem-size-8);
  }
}

.fo-project-section-information-view__misc__social-links>a:hover {
  color: var(--grey-light);
  background-color: var(--grey-darkest);
}

.fo-project-section-information-view__image--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0,0,0,0);
  color: var(--grey-dark);
  font-weight: var(--font-weight-medium);
  border: var(--rem-size-1) var(--grey-dark) dashed;
}

.fo-project-section-information-view__image--empty .idt-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-bottom: var(--rem-size-16);
  background: var(--grey);
  color: var(--grey-darkest);
  border-radius: 100%;
}

.fo-project-section-information-view__image--empty div {
  display: block;
}

.fo-project-section-information-view__content__avatar--empty {
  background: var(--grey-lightest);
}

.fo-project-section-information-view__content__avatar--empty span {
  display: none;
}

.fo-project-section-information-view__content__avatar--empty div {
  display: block;
}

