





























































































































































































@import '@/styles/mixins/index';

.mx-datepicker {
  width: 100%;

  ::v-deep .mx-input {
    height: auto;
    padding: 0.6566rem 1rem;
    margin: 0;
    color: var(--black);
  }
}
.bo-date-location {
  &__separator {
    color: var(--grey);
    font-size: 1.5625rem;
    font-weight: 300;
    text-align: center;
    line-height: 48px;

    @include respond-to(large) {
      padding-top: 1.875rem;
    }
  }
  &__select {
    height: 48px;
  }
}
