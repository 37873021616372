








































.day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: var(--rem-size-25);
  margin: 0 2px 2px 0;
  padding: 9px var(--rem-size-12);
  border-radius: var(--global-radius);
  background-color: var(--grey);
  cursor: default;

  &__text {
    color: var(--black);
    font-size: var(--rem-size-12);
    font-weight: var(--font-weight-bold);
    line-height: var(--rem-size-18);
  }

  &__button {
    padding: 9px 0 0 10px;
    color: var(--black);

    &:hover {
      cursor: pointer;
    }
  }
}
