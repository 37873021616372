



































// reference: https://codepen.io/Abdelrahman3D/pen/bpPxjW

idt-spiner {
  display: block;
}

.idt-spinner {
  --PI: 3.14159265359;
  --spinner-stroke-width: 2px;
  --spinner-circumference: calc(2 * var(--PI) * (var(--spinner-radius) - var(--spinner-stroke-width)));

  width: calc(var(--spinner-radius) * 2);
  height: calc(var(--spinner-radius) * 2);
  animation: spinning 2.5s linear infinite;

  &.dark {
    --spinner-stroke-color: var(--main-color-lighter);
  }
  &.light {
    --spinner-stroke-color: var(--main-color-dark);
  }
  &.white {
    --spinner-stroke-color: var(--white);
  }
  &.yellow {
    --spinner-stroke-color: #ffaf22;
  }
  circle {
    fill: none;
    webkit-text-stroke: var(--spinner-stroke-color);
    webkit-text-stroke-width: var(--spinner-stroke-width);
    webkit-text-stroke-dasharray: var(--spinner-circumference);
    webkit-text-stroke-linecap: round;
    stroke: var(--spinner-stroke-color);
    stroke-width: var(--spinner-stroke-width);
    stroke-dasharray: var(--spinner-circumference);
    stroke-linecap: round;
    r: calc(var(--spinner-radius) - var(--spinner-stroke-width));
    cx: var(--spinner-radius);
    cy: var(--spinner-radius);
    transition: all 2s ease-in-out;
    animation: spinningCircle 2.5s linear infinite;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinningCircle {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: calc(var(--spinner-circumference) - 10px);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
