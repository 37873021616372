.idt-user-avatar {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--grey-light);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

.idt-user-avatar.avatar--invited {
  background-color: var(--grey-light);
}

.idt-user-avatar__initial {
  display: inline-block;
  color: var(--white);
}

.idt-user-avatar__prize {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 50%;
  line-height: 0;
}

.idt-user-avatar__prize__icon {
  border-radius: 50%;
}

