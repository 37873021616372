.mfa {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mfa>* {
  text-align: center;
}

.mfa__otp-input,
.mfa__actions {
  margin-top: var(--rem-size-32);
}

.mfa__actions {
  display: flex;
  width: 100%;
  gap: var(--rem-size-8);
}

.mfa__actions>.button {
  flex-basis: 50%;
}

.mfa__error,
.mfa__info {
  margin-top: var(--rem-size-16);
  font-size: var(--rem-size-12);
}

.mfa__error {
  color: var(--red);
}

.mfa__info {
  color: var(--main-color);
}

.mfa__resend {
  margin-top: var(--rem-size-16);
}

.mfa__resend__button {
  color: var(--main-color);
  font-size: var(--rem-size-14);
  text-decoration: underline;
  cursor: pointer;
}

.mfa ::v-deep .otp-input {
  text-align: center;
  width: 4rem;
  height: 6rem;
  margin-bottom: 0;
  font-size: var(--rem-size-24);
}

.mfa ::v-deep .otp-input.error {
  color: var(--red);
  border: 1px solid var(--red);
}

.mfa ::v-deep .otp-input.success {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

