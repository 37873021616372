





























@import '@/styles/mixins/index';

.ticketing-upgrade-button {
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;

  @include respond-to(max-slarge) {
    width: 160px;
    height: 40px;
    margin-top: 15px;
    font-size: 1rem;
  }
  @include respond-to(slarge) {
    width: 115px;
    height: 34px;
    font-size: 0.875rem;
  }
}
