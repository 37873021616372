















































































.activity {
  display: flex;
  align-items: center;
  height: 45px;
  background-color: var(--grey-lighter);
  border-radius: 3px;
  color: var(--black);
  cursor: grab;

  &.selected {
    background-color: var(--white);
    box-shadow: 0 0 10px 0 var(--black-alpha15);
  }
  &__activity-description {
    padding: unset;

    &__title {
      display: flex;
      align-items: center;
      max-width: 100%;

      &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__date {
        min-width: 30%;
        font-style: italic;
      }
    }
  }
  &__edit {
    margin: 5px 5px 0px 0px;
  }
  &__cancel {
    margin-top: 8px;
    color: var(--grey-dark);
    font-size: 16px;
    font-weight: var(--font-weight-medium);
  }
  button {
    cursor:pointer;
  }
}
