.btn-add-field {
  display: flex;
  align-items: center;
  width: fit-content;
}

.btn-add-field__text {
  margin-left: 12px;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

