






























































































.fo-project-section-free-edit {
  text-align: center;
  margin: 2rem 3rem;

  &__title {
    color: var(--grey-darkest);
  }

  &__content {
    margin-top: 2rem;

    &--required {
      .froala-container {
        border: 1px solid var(--red);
        border-radius: 10px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
  &__add-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    margin-top: 2rem;
    border: 2px dashed var(--grey);
    border-radius: var(--global-radius);

    &--required {
      border-color: var(--red);
    }
  }
  .idt-required-field {
    margin-top: var(--rem-size-8);
  }
}
