.idt-rounded-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  &__label {
    display: block;
    width: 20px;
    height: 20px;
    border: 4px solid white;
    border-radius: 100%;
    box-shadow: 0 0 0 2px var(--grey-dark);
    input[type=radio]:checked + &,
    input[type=checkbox]:checked + &,
    input[type=checkbox].ng-valid + &{
      background-color: var(--main-color);
    }
  }
  input[type=radio],
  input[type=checkbox] {
    display: none;
  }
}
