












































































.idt-survey-add-answer {
  --height: 38px;

  &__button {
    width: 100%;
    height: var(--height);
    padding: 3px .5rem;
    text-align: left;
    border: 2px dashed var(--grey);
    color: var(--grey);
    cursor: pointer;

    & ::v-deep .ideacon {
      margin-right: var(--rem-size-18);
    }
  }

  &__input {
    position: relative;

    & ::v-deep .idt-input {
      height: var(--height);
    }

    &__button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 var(--rem-size-10);
      height: var(--height);
      color: var(--main-color);
      cursor: pointer;
    }
  }
}
