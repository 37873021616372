




















































@import '@/styles/mixins';

.lobby-user-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--rem-size-22) var(--rem-size-24);
    border-bottom: 1px solid var(--grey-light);
    font-size: var(--rem-size-18);
    font-weight: bold;

    > div {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    .idt-icon {
      cursor: pointer;
    }
    &__text {
      margin-right: var(--rem-size-4);
      color: var(--grey-darkest);
    }
    &__left {
      @include respond-to(max-medium) {
        display: flex;
        align-items: center;
        margin-right: var(--rem-size-4);
      }
      @include respond-to(medium) {
        display: none;
      }
    }
    &__close {
      @include respond-to(max-medium) {
        display: none;
      }
      @include respond-to(medium) {
        display: inline-block;
      }
    }
  }

  @include respond-to(max-slarge) {
    box-shadow: none;
  }
}
