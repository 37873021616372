.event-contact {
  position: relative;
  padding: 0;
  background: rgba(0,0,0,0);
  overflow: hidden;
  z-index: 10;
}

.event-contact__background {
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-filter: blur(28px);
  -moz-filter: blur(28px);
  -o-filter: blur(28px);
  -ms-filter: blur(28px);
  filter: blur(28px);
}

.event-contact__container {
  padding: 5rem 3rem;
  background-color: var(--white-alpha80);
}

.event-contact__title {
  margin-bottom: 1rem;
  line-height: 1;
}

.event-contact__email {
  color: var(--grey-darkest);
  font-size: 1.35rem;
}

.event-contact__email:hover {
  color: var(--black);
}

