


























































































































































.bar-chart {
  .xA .tick line {
    stroke: transparent;
  }
}
