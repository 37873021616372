





















































































































































































































































































































































.idt-date-time-container {
  position: relative;
  padding-bottom: 1.5rem;

  .mx-datepicker {
    width: 100%;

    &.has-error {
      ::v-deep .mx-input {
        border: 1px solid var(--secondary-color);
      }
    }

    ::v-deep .mx-input {
      height: auto;
      margin: 0;
      padding: 0.6566rem 1rem;
      background-color: var(--white);
      border: 1px solid var(--grey);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      font-size: 1rem;
      line-height: 1.6;
    }
  }
}
::v-deep .idt-required-field {
  position: absolute;
  bottom: 0;
  left: 0;
}
