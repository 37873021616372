







































































































































































































































































































































































@import '@/styles/mixins';

.live-chat-form {
  position: relative;
  border-top: 1px solid var(--grey-lighter);

  &__container {
    display: flex;
    align-items: flex-end;
    padding: var(--rem-size-10);
  }

  &__text {
    height: auto;
    min-height: var(--live-chat-form-height);
    max-height: 75px;
    margin-bottom: 0;
    padding: 0;
    color: var(--black);
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-size: var(--rem-size-14);
    line-height: var(--live-chat-form-height);
    overflow: initial !important;
    resize: none;
  }

  ::v-deep &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--rem-size-10);
    height: var(--live-chat-form-height);

    .action {
      height: 27px;
      width: 27px;
      border-radius: 3px;
      cursor: pointer;
      z-index: 1001;

      &[disabled] {
        cursor: default;
      }

      & > .idt-icon {
        color: var(--white);
      }
    }
    &__submit {
      background: var(--main-color);
    }
    &__image {
      font-size: var(--rem-size-24);

      &:hover {
        .idea-ht-image-bicolor {
          --color2: var(--info-color-lightest);
          --color3: var(--success-color-dark);
        }
      }
    }
    &__locked-submit {
      width: 33px;
      height: 33px;
      background: var(--red);
      border-radius: 50%;
    }
  }
  &__gif-container {
    position: relative;
    display: inline-block;
    padding: var(--rem-size-10);

    img {
      max-width: 200px;
      border-radius: var(--global-radius);
    }

    &__cancel {
      position: absolute;
      top: var(--rem-size-15);
      right: var(--rem-size-15);
      width: var(--rem-size-20);
      height: var(--rem-size-20);
      background: var(--white);
      text-align: center;
      line-height: var(--rem-size-26);
      cursor: pointer;
    }
  }
  &__upload {
    &-container {
      @include respond-to(max-large) {
        padding: 0.5rem;
        padding-bottom: 0px;
        background-color: var(--white);
      }
      @include respond-to(large) {
        padding: 1.125rem 1.125rem 0 1.125rem;
        background-color: var(--white);
      }
    }
  }
  &__reply {
    &-container {
      max-height: 200px;
      padding: var(--rem-size-10) var(--rem-size-20) 0;
      font-size: var(--rem-size-14);
      overflow-y: auto;

      &__informations {
        color: var(--grey-darker);

        &__author {
          color: var(--black);
          font-weight: bold;
        }
        &__cancel {
          position: absolute;
          top: 5px;
          right: 5px;
          width: var(--rem-size-25);
          height: var(--rem-size-25);
          padding: 5px;
          color: var(--grey-darkest);
          text-align: center;
          cursor: pointer;

          &:hover {
            background: var(--grey-light);
          }
          &.speaker {
            right: var(--rem-size-30);
          }
        }
      }
    }
  }
}

// UI for the lobby
.live-chat-form--in-lobby {
  border: 0;

  .live-chat-form__container {
    display: flex;
    flex-flow: column;
    padding: var(--rem-size-12) var(--rem-size-24) var(--rem-size-24);

    @include respond-to(max-slarge) {
      padding: var(--rem-size-12) 0 0;
    }

    .live-chat-form__text {
      width: 100%;
      border: 1px solid var(--grey-darkest-alpha20);
      border-radius: var(--global-radius) var(--global-radius) 0 0;
      border-bottom: 0;
      padding: 16px;
      min-height: 60px;

      @include respond-to(max-slarge) {
        border-radius: 0;
        border-right: 0;
        border-left: 0;
      }
    }
    .live-chat-form__actions {
      position: relative;
      width: 100%;
      padding: 8px 16px;
      border: 1px solid var(--grey-darkest-alpha20);
      border-top: 0;
      border-radius: 0 0 var(--global-radius) var(--global-radius);
      z-index: 1;

      @include respond-to(max-slarge) {
        border: 0;
      }

      &::before {
        content: '';
        display: flex;
        position: absolute;
        top: 0;
        left: 16px;
        width: calc(100% - 32px);
        background: var(--grey-darkest-alpha8);
        height: 1px;
      }
    }
  }
}
.live-chat-form--disabled {
  filter: grayscale(1);
  user-select: none;
  pointer-events: none;
}
