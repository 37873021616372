.category {
  display: flex;
  align-items: center;
  height: 45px;
  background-color: var(--grey-lighter);
  border-radius: 3px;
  color: var(--black);
  cursor: grab;
}

.category.selected {
  background-color: var(--white);
  box-shadow: 0 0 10px 0 var(--black-alpha15);
}

.category__category-description {
  display: flex;
  align-items: center;
  gap: var(--rem-size-8);
}

.category__category-description__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category__category-description__info {
  align-self: center;
}

.category__edit {
  margin: 5px 5px 0px 0px;
}

.category__cancel {
  margin-top: 8px;
  color: var(--grey-dark);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.category button {
  cursor: pointer;
}

