












































.idt-tag {
  padding: 0 10px;
  margin-bottom: 5px;
  color: var(--main-color-dark);
  background: var(--main-color-lighter);
  border-radius: var(--global-radius);
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-medium);

  &.clickable {
    cursor: pointer;
  }
  &.clickable:hover,
  &.selected {
    color: var(--main-color-lighter);
    background-color: var(--main-color-darker);
  }
  ::v-deep .ideacon {
    margin-left: var(--rem-size-2);
    vertical-align: middle;
  }
}
