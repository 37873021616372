@font-face {
  font-family: 'ideation-icons';
  src:
    url('fonts/ideation-icons.ttf?n5oetk') format('truetype'),
    url('fonts/ideation-icons.woff?n5oetk') format('woff'),
    url('fonts/ideation-icons.svg?n5oetk#ideation-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ideacons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ideation-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.idea-add-circle:before {
  content: "\e900";
}
.idea-add-file-old:before {
  content: "\e901";
}
.idea-add-image:before {
  content: "\e902";
}
.idea-answer:before {
  content: "\e903";
}
.idea-arrow-left:before {
  content: "\e904";
}
.idea-arrow-nav-down:before {
  content: "\e905";
}
.idea-arrow-nav-left:before {
  content: "\e906";
}
.idea-arrow-nav-right:before {
  content: "\e907";
}
.idea-arrow-nav-up:before {
  content: "\e908";
}
.idea-arrow-right:before {
  content: "\e909";
}
.idea-back-all-projects:before {
  content: "\e90a";
}
.idea-bulb:before {
  content: "\e90b";
}
.idea-calendar:before {
  content: "\e90c";
}
.idea-cam:before {
  content: "\e90d";
}
.idea-caution:before {
  content: "\e90e";
}
.idea-chat:before {
  content: "\e90f";
}
.idea-close:before {
  content: "\e911";
}
.idea-close-circle:before {
  content: "\e910";
}
.idea-colapse:before {
  content: "\e912";
}
.idea-conf:before {
  content: "\e913";
}
.idea-edit:before {
  content: "\e914";
}
.idea-exclamation:before {
  content: "\e915";
}
.idea-facebook:before {
  content: "\e916";
}
.idea-file:before {
  content: "\e917";
}
.idea-fullscreen:before {
  content: "\e918";
}
.idea-google:before {
  content: "\e919";
}
.idea-guillemet-close:before {
  content: "\e91a";
}
.idea-guillemet-open:before {
  content: "\e91b";
}
.idea-home:before {
  content: "\e91c";
}
.idea-hour:before {
  content: "\e91d";
}
.idea-ht-Arrow-down:before {
  content: "\ea4f";
}
.idea-ht-Arrow-up:before {
  content: "\ea50";
}
.idea-ht-Copy:before {
  content: "\eaa6";
}
.idea-ht-More:before {
  content: "\eaa7";
}
.idea-ht-admin:before {
  content: "\ea52";
}
.idea-ht-ai:before {
  content: "\e97f";
}
.idea-ht-alien:before {
  content: "\e99d";
}
.idea-ht-anchor:before {
  content: "\e99e";
}
.idea-ht-archive-box:before {
  content: "\e99f";
}
.idea-ht-archive-download:before {
  content: "\ea4c";
}
.idea-ht-arrow-bottom:before {
  content: "\e947";
}
.idea-ht-arrow-bottom-right:before {
  content: "\e993";
}
.idea-ht-arrow-enter:before {
  content: "\e994";
}
.idea-ht-arrow-left:before {
  content: "\e948";
}
.idea-ht-arrow-right:before {
  content: "\e949";
}
.idea-ht-arrow-right-2:before {
  content: "\ea06";
}
.idea-ht-arrow-top:before {
  content: "\e94a";
}
.idea-ht-back-office:before {
  content: "\e96f";
}
.idea-ht-bandwidth:before {
  content: "\e973";
}
.idea-ht-bicycle:before {
  content: "\e9a0";
}
.idea-ht-bike:before {
  content: "\e9cf";
}
.idea-ht-bin:before {
  content: "\e94b";
}
.idea-ht-birthday-cake:before {
  content: "\e9a1";
}
.idea-ht-bolt:before {
  content: "\e94c";
}
.idea-ht-bubble:before {
  content: "\e98f";
}
.idea-ht-bubble-help:before {
  content: "\e9f5";
}
.idea-ht-bubble2:before {
  content: "\e9b8";
}
.idea-ht-bubbles:before {
  content: "\e9b9";
}
.idea-ht-building:before {
  content: "\e9a2";
}
.idea-ht-bullhorn:before {
  content: "\e995";
}
.idea-ht-calendar:before {
  content: "\e94f";
}
.idea-ht-calendar-2:before {
  content: "\ea12";
}
.idea-ht-calendar-dotted:before {
  content: "\e94d";
}
.idea-ht-calendar-light:before {
  content: "\e94e";
}
.idea-ht-camera:before {
  content: "\e9d0";
}
.idea-ht-camera-off:before {
  content: "\e974";
}
.idea-ht-camera-on:before {
  content: "\e975";
}
.idea-ht-canceled:before {
  content: "\e987";
}
.idea-ht-car:before {
  content: "\e9a3";
}
.idea-ht-card-view:before {
  content: "\ea2d";
}
.idea-ht-castle:before {
  content: "\e9a4";
}
.idea-ht-challenge:before {
  content: "\e950";
}
.idea-ht-channel:before {
  content: "\ea56";
}
.idea-ht-chat:before {
  content: "\ea1d";
}
.idea-ht-chat-starter:before {
  content: "\ea13";
}
.idea-ht-checked:before {
  content: "\e996";
}
.idea-ht-checked-round:before {
  content: "\e9ac";
}
.idea-ht-chevron-bottom:before {
  content: "\e951";
}
.idea-ht-chevron-left:before {
  content: "\e952";
}
.idea-ht-chevron-right:before {
  content: "\e953";
}
.idea-ht-chevron-top:before {
  content: "\e954";
}
.idea-ht-circle:before {
  content: "\e967";
}
.idea-ht-circle-arrow-left:before {
  content: "\ea18";
  color: #8f9092;
}
.idea-ht-circle-arrow-right:before {
  content: "\e98d";
}
.idea-ht-circle-bubble:before {
  content: "\e997";
}
.idea-ht-circle-checked:before {
  content: "\e955";
}
.idea-ht-circle-checked-full:before {
  content: "\ea21";
}
.idea-ht-circle-close:before {
  content: "\e956";
}
.idea-ht-circle-info:before {
  content: "\e957";
}
.idea-ht-circle-plus:before {
  content: "\e958";
}
.idea-ht-clock:before {
  content: "\e986";
}
.idea-ht-clock-outline:before {
  content: "\e9c7";
}
.idea-ht-clock-outline-2:before {
  content: "\ea11";
}
.idea-ht-close:before {
  content: "\e959";
}
.idea-ht-close-round:before {
  content: "\e9ad";
}
.idea-ht-color:before {
  content: "\ea2c";
}
.idea-ht-compress:before {
  content: "\e9aa";
}
.idea-ht-conference:before {
  content: "\e976";
}
.idea-ht-conference-2:before {
  content: "\e9ef";
}
.idea-ht-connection:before {
  content: "\e977";
}
.idea-ht-connexion:before {
  content: "\e9d1";
}
.idea-ht-controller:before {
  content: "\e9d2";
}
.idea-ht-crown:before {
  content: "\e9a5";
}
.idea-ht-data:before {
  content: "\e969";
}
.idea-ht-delete:before {
  content: "\ea0f";
}
.idea-ht-diamon:before {
  content: "\e980";
}
.idea-ht-diamond:before {
  content: "\e9d3";
}
.idea-ht-diversification:before {
  content: "\e9d4";
}
.idea-ht-download:before {
  content: "\e99c";
}
.idea-ht-emoji .path1:before {
  content: "\ea3c";
  color: rgb(238, 239, 242);
}
.idea-ht-emoji .path2:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-emoji .path3:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-emoji .path4:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-end-stream:before {
  content: "\ea31";
}
.idea-ht-eventbrite:before {
  content: "\e96a";
}
.idea-ht-expand:before {
  content: "\e9ab";
}
.idea-ht-eye:before {
  content: "\ea5d";
}
.idea-ht-eye-close:before {
  content: "\ea0e";
}
.idea-ht-eye-open:before {
  content: "\ea0c";
}
.idea-ht-facebook-logo:before {
  content: "\ea23";
}
.idea-ht-faq:before {
  content: "\e972";
}
.idea-ht-file .path1:before {
  content: "\ea29";
  color: rgb(112, 112, 112);
}
.idea-ht-file .path2:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(51, 199, 204);
}
.idea-ht-file-all .path1:before {
  content: "\ea47";
  color: rgb(112, 112, 112);
}
.idea-ht-file-all .path2:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(51, 199, 204);
}
.idea-ht-file-all .path3:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.idea-ht-file-all .path4:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.idea-ht-file-all .path5:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.idea-ht-file-empty:before {
  content: "\ea20";
}
.idea-ht-film:before {
  content: "\e9d5";
}
.idea-ht-flag:before {
  content: "\ea30";
}
.idea-ht-foreign:before {
  content: "\ea4d";
}
.idea-ht-game:before {
  content: "\e9d6";
}
.idea-ht-general:before {
  content: "\e9e9";
}
.idea-ht-gif .path1:before {
  content: "\ea38";
  color: rgb(238, 239, 242);
}
.idea-ht-gif .path2:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-gif .path3:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-gif .path4:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-grow:before {
  content: "\e9d7";
}
.idea-ht-hackathon:before {
  content: "\e95a";
}
.idea-ht-home:before {
  content: "\e95b";
}
.idea-ht-image:before {
  content: "\e95c";
}
.idea-ht-image-bicolor .path1:before {
  content: "\ea40";
  color: rgb(238, 239, 242);
}
.idea-ht-image-bicolor .path2:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(54, 54, 57);
}
.idea-ht-image-outline:before {
  content: "\e990";
}
.idea-ht-incomplete:before {
  content: "\ea5e";
}
.idea-ht-increase:before {
  content: "\ea46";
}
.idea-ht-infinity:before {
  content: "\e9ae";
}
.idea-ht-info:before {
  content: "\ea25";
}
.idea-ht-info-outline:before {
  content: "\ea24";
}
.idea-ht-info-without-circle:before {
  content: "\ea57";
}
.idea-ht-invoice:before {
  content: "\ea0d";
}
.idea-ht-ip-filter:before {
  content: "\e9af";
}
.idea-ht-judging:before {
  content: "\e9ea";
}
.idea-ht-key:before {
  content: "\e9d8";
}
.idea-ht-keynote:before {
  content: "\ea07";
}
.idea-ht-king:before {
  content: "\e9d9";
}
.idea-ht-languages:before {
  content: "\e9eb";
}
.idea-ht-line-view:before {
  content: "\ea2e";
}
.idea-ht-list:before {
  content: "\ea19";
}
.idea-ht-live:before {
  content: "\e981";
}
.idea-ht-live-channel:before {
  content: "\ea58";
}
.idea-ht-live-plain .path1:before {
  content: "\e9b0";
  color: rgb(0, 0, 0);
}
.idea-ht-live-plain .path2:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.idea-ht-lobby-user:before {
  content: "\ea59";
}
.idea-ht-location:before {
  content: "\e95e";
}
.idea-ht-location-arrow:before {
  content: "\e9a6";
}
.idea-ht-location-outline:before {
  content: "\e95d";
}
.idea-ht-lock:before {
  content: "\e989";
}
.idea-ht-lock-outline:before {
  content: "\e95f";
}
.idea-ht-lock1:before {
  content: "\e9da";
}
.idea-ht-logout:before {
  content: "\e9ba";
}
.idea-ht-mail:before {
  content: "\e988";
}
.idea-ht-mail-2:before {
  content: "\e998";
}
.idea-ht-map:before {
  content: "\e960";
}
.idea-ht-meatball-menu:before {
  content: "\ea1b";
}
.idea-ht-medal:before {
  content: "\e9db";
}
.idea-ht-meeting:before {
  content: "\ea1c";
}
.idea-ht-member:before {
  content: "\e963";
}
.idea-ht-member-cyclope:before {
  content: "\e961";
}
.idea-ht-member-outline:before {
  content: "\e962";
}
.idea-ht-mentor:before {
  content: "\e964";
}
.idea-ht-mentors-jury:before {
  content: "\e9ec";
}
.idea-ht-message:before {
  content: "\e965";
}
.idea-ht-messenger:before {
  content: "\ea5a";
}
.idea-ht-mic-off:before {
  content: "\e978";
}
.idea-ht-mic-on:before {
  content: "\e979";
}
.idea-ht-multispeaker:before {
  content: "\ea15";
}
.idea-ht-my-talks:before {
  content: "\e9f4";
}
.idea-ht-no-favorite:before {
  content: "\ea22";
}
.idea-ht-no-plugin:before {
  content: "\e97a";
}
.idea-ht-no-talk:before {
  content: "\ea2f";
}
.idea-ht-no-video:before {
  content: "\e97b";
}
.idea-ht-not-included:before {
  content: "\e9c8";
}
.idea-ht-notification:before {
  content: "\e9ed";
}
.idea-ht-on-the-radar:before {
  content: "\ea45";
}
.idea-ht-online:before {
  content: "\e966";
}
.idea-ht-out:before {
  content: "\e9bb";
}
.idea-ht-paper-plane:before {
  content: "\e9dc";
}
.idea-ht-paperclip:before {
  content: "\e98e";
}
.idea-ht-partners:before {
  content: "\e9ee";
}
.idea-ht-pen:before {
  content: "\e968";
}
.idea-ht-people:before {
  content: "\e98c";
}
.idea-ht-people-outline:before {
  content: "\e98b";
}
.idea-ht-people-outline-plus:before {
  content: "\e991";
}
.idea-ht-phone:before {
  content: "\e9dd";
}
.idea-ht-pin:before {
  content: "\ea32";
}
.idea-ht-plane:before {
  content: "\e9a7";
}
.idea-ht-plane1:before {
  content: "\e9de";
}
.idea-ht-play:before {
  content: "\e983";
}
.idea-ht-play-disc:before {
  content: "\e982";
}
.idea-ht-play-disc-2 .path1:before {
  content: "\e9b2";
  color: rgb(26, 188, 156);
}
.idea-ht-play-disc-2 .path2:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.idea-ht-play-outline:before {
  content: "\e9f3";
}
.idea-ht-powerpoint:before {
  content: "\ea08";
}
.idea-ht-prize-pool:before {
  content: "\e9b5";
}
.idea-ht-prizes:before {
  content: "\ea4e";
}
.idea-ht-profile:before {
  content: "\e96b";
}
.idea-ht-profile2:before {
  content: "\e9bc";
}
.idea-ht-profiles:before {
  content: "\e9f0";
}
.idea-ht-project:before {
  content: "\e96c";
}
.idea-ht-questions:before {
  content: "\ea26";
}
.idea-ht-questions-outline:before {
  content: "\ea43";
}
.idea-ht-radio:before {
  content: "\ea17";
}
.idea-ht-reload:before {
  content: "\ea44";
}
.idea-ht-replay:before {
  content: "\ea14";
}
.idea-ht-resources:before {
  content: "\e96d";
}
.idea-ht-resources-2:before {
  content: "\ea09";
}
.idea-ht-resources-pdf:before {
  content: "\e99b";
}
.idea-ht-reverse:before {
  content: "\ea0a";
}
.idea-ht-rocket:before {
  content: "\e9df";
}
.idea-ht-rocket-outline:before {
  content: "\e9a8";
}
.idea-ht-scheduled:before {
  content: "\e9b4";
}
.idea-ht-school:before {
  content: "\e9e0";
}
.idea-ht-screen:before {
  content: "\ea0b";
}
.idea-ht-search:before {
  content: "\e96e";
}
.idea-ht-send:before {
  content: "\ea34";
}
.idea-ht-share-screen:before {
  content: "\e97c";
}
.idea-ht-share-social-outline:before {
  content: "\ea1f";
}
.idea-ht-shortlist:before {
  content: "\ea16";
}
.idea-ht-sleep:before {
  content: "\ea53";
}
.idea-ht-sliders:before {
  content: "\e98a";
}
.idea-ht-sound-off:before {
  content: "\e97d";
}
.idea-ht-sound-on:before {
  content: "\e97e";
}
.idea-ht-sparkles:before {
  content: "\ea5f";
}
.idea-ht-speak:before {
  content: "\ea51";
}
.idea-ht-sport-car:before {
  content: "\e9e1";
}
.idea-ht-star:before {
  content: "\e9e2";
}
.idea-ht-star-outline:before {
  content: "\e9e3";
}
.idea-ht-starting:before {
  content: "\e984";
}
.idea-ht-starting-colored:before {
  content: "\e9b6";
  color: #f1c100;
}
.idea-ht-statistics:before {
  content: "\ea27";
}
.idea-ht-stats-chart:before {
  content: "\ea55";
}
.idea-ht-strike:before {
  content: "\e9e4";
}
.idea-ht-survey:before {
  content: "\ea28";
}
.idea-ht-survey-outline:before {
  content: "\ea37";
}
.idea-ht-talk-chat:before {
  content: "\ea2b";
}
.idea-ht-talk-chat-outline:before {
  content: "\ea42";
}
.idea-ht-thumb-up:before {
  content: "\e999";
}
.idea-ht-ticket:before {
  content: "\e9e5";
}
.idea-ht-time:before {
  content: "\e970";
}
.idea-ht-timer:before {
  content: "\ea10";
}
.idea-ht-timer1:before {
  content: "\e992";
}
.idea-ht-toothed-wheel:before {
  content: "\e985";
}
.idea-ht-toothed-wheel-outline:before {
  content: "\e9f2";
}
.idea-ht-tree:before {
  content: "\e9a9";
}
.idea-ht-trophee:before {
  content: "\e9e6";
}
.idea-ht-txt:before {
  content: "\ea1a";
}
.idea-ht-unknown:before {
  content: "\ea54";
}
.idea-ht-unpin:before {
  content: "\ea5b";
}
.idea-ht-up-down:before {
  content: "\ea33";
}
.idea-ht-video:before {
  content: "\e971";
}
.idea-ht-video-channel:before {
  content: "\ea5c";
}
.idea-ht-video-session .path1:before {
  content: "\e9c4";
}
.idea-ht-video-session .path2:before {
  content: "\e9c5";
  margin-left: -2.4091796875em;
}
.idea-ht-video-session .path3:before {
  content: "\e9c6";
  margin-left: -2.4091796875em;
}
.idea-ht-vote:before {
  content: "\e99a";
}
.idea-ht-vr:before {
  content: "\e9e7";
}
.idea-ht-warning:before {
  content: "\e9e8";
}
.idea-ht-warning1:before {
  content: "\e9f1";
}
.idea-ht-winner:before {
  content: "\ea1e";
}
.idea-ht-youtube:before {
  content: "\eaa8";
}
.idea-ht-zoom-in:before {
  content: "\ea36";
}
.idea-ht-zoom-out:before {
  content: "\ea35";
}
.idea-ibm:before {
  content: "\eaa9";
}
.idea-illu-amex:before {
  content: "\e9f6";
  color: #006fcf;
}
.idea-illu-coin .path1:before {
  content: "\e9c9";
  color: rgb(255, 185, 74);
}
.idea-illu-coin .path2:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 231, 192);
}
.idea-illu-coin .path3:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(255, 185, 74);
}
.idea-illu-coin .path4:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 231, 192);
}
.idea-illu-coin .path5:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 185, 74);
}
.idea-illu-coin .path6:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 231, 192);
}
.idea-illu-lock .path1:before {
  content: "\e9bd";
  color: rgb(241, 241, 241);
}
.idea-illu-lock .path2:before {
  content: "\e9be";
  margin-left: -0.75em;
  color: rgb(209, 209, 209);
}
.idea-illu-lock .path3:before {
  content: "\e9bf";
  margin-left: -0.75em;
  color: rgb(227, 227, 227);
}
.idea-illu-lock .path4:before {
  content: "\e9c0";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
}
.idea-illu-lock .path5:before {
  content: "\e9c1";
  margin-left: -0.75em;
  color: rgb(227, 227, 227);
}
.idea-illu-lock .path6:before {
  content: "\e9c2";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
}
.idea-illu-lock .path7:before {
  content: "\e9c3";
  margin-left: -0.75em;
  color: rgb(241, 241, 241);
}
.idea-illu-maestro .path1:before {
  content: "\e9f7";
  color: rgb(0, 0, 0);
}
.idea-illu-maestro .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(118, 115, 192);
}
.idea-illu-maestro .path3:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.idea-illu-maestro .path4:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(0, 161, 223);
}
.idea-illu-maestro .path5:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(0, 161, 223);
}
.idea-illu-mastercard .path1:before {
  content: "\e9fc";
  color: rgb(35, 31, 32);
}
.idea-illu-mastercard .path2:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(255, 95, 0);
}
.idea-illu-mastercard .path3:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.idea-illu-mastercard .path4:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.idea-illu-mastercard .path5:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.idea-illu-metamask .path1:before {
  content: "\ea60";
  color: rgb(205, 189, 178);
}
.idea-illu-metamask .path2:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(205, 189, 178);
}
.idea-illu-metamask .path3:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(57, 57, 57);
}
.idea-illu-metamask .path4:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(248, 156, 53);
}
.idea-illu-metamask .path5:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask .path6:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask .path7:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(234, 141, 58);
}
.idea-illu-metamask .path8:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask .path9:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask .path10:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(234, 142, 58);
}
.idea-illu-metamask .path11:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask .path12:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask .path13:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(232, 130, 30);
}
.idea-illu-metamask .path14:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(223, 206, 195);
}
.idea-illu-metamask .path15:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(223, 206, 195);
}
.idea-illu-metamask .path16:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(57, 57, 57);
}
.idea-illu-metamask .path17:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(232, 143, 53);
}
.idea-illu-metamask .path18:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(142, 90, 48);
}
.idea-illu-metamask .path19:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask .path20:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask .path21:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(234, 141, 58);
}
.idea-illu-metamask .path22:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask .path23:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask .path24:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(234, 142, 58);
}
.idea-illu-metamask .path25:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask .path26:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask .path27:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(232, 130, 30);
}
.idea-illu-metamask .path28:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(57, 57, 57);
}
.idea-illu-metamask .path29:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(232, 143, 53);
}
.idea-illu-metamask .path30:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(142, 90, 48);
}
.idea-illu-metamask-horizontal .path1:before {
  content: "\ea7e";
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path2:before {
  content: "\ea7f";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path3:before {
  content: "\ea80";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path4:before {
  content: "\ea81";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path5:before {
  content: "\ea82";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path6:before {
  content: "\ea83";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path7:before {
  content: "\ea84";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path8:before {
  content: "\ea85";
  margin-left: -4em;
  color: rgb(22, 22, 22);
}
.idea-illu-metamask-horizontal .path9:before {
  content: "\ea86";
  margin-left: -4em;
  color: rgb(205, 189, 178);
}
.idea-illu-metamask-horizontal .path10:before {
  content: "\ea87";
  margin-left: -4em;
  color: rgb(205, 189, 178);
}
.idea-illu-metamask-horizontal .path11:before {
  content: "\ea88";
  margin-left: -4em;
  color: rgb(57, 57, 57);
}
.idea-illu-metamask-horizontal .path12:before {
  content: "\ea89";
  margin-left: -4em;
  color: rgb(248, 156, 53);
}
.idea-illu-metamask-horizontal .path13:before {
  content: "\ea8a";
  margin-left: -4em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask-horizontal .path14:before {
  content: "\ea8b";
  margin-left: -4em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask-horizontal .path15:before {
  content: "\ea8c";
  margin-left: -4em;
  color: rgb(234, 141, 58);
}
.idea-illu-metamask-horizontal .path16:before {
  content: "\ea8d";
  margin-left: -4em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask-horizontal .path17:before {
  content: "\ea8e";
  margin-left: -4em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask-horizontal .path18:before {
  content: "\ea8f";
  margin-left: -4em;
  color: rgb(234, 142, 58);
}
.idea-illu-metamask-horizontal .path19:before {
  content: "\ea90";
  margin-left: -4em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask-horizontal .path20:before {
  content: "\ea91";
  margin-left: -4em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask-horizontal .path21:before {
  content: "\ea92";
  margin-left: -4em;
  color: rgb(232, 130, 30);
}
.idea-illu-metamask-horizontal .path22:before {
  content: "\ea93";
  margin-left: -4em;
  color: rgb(223, 206, 195);
}
.idea-illu-metamask-horizontal .path23:before {
  content: "\ea94";
  margin-left: -4em;
  color: rgb(223, 206, 195);
}
.idea-illu-metamask-horizontal .path24:before {
  content: "\ea95";
  margin-left: -4em;
  color: rgb(57, 57, 57);
}
.idea-illu-metamask-horizontal .path25:before {
  content: "\ea96";
  margin-left: -4em;
  color: rgb(232, 143, 53);
}
.idea-illu-metamask-horizontal .path26:before {
  content: "\ea97";
  margin-left: -4em;
  color: rgb(142, 90, 48);
}
.idea-illu-metamask-horizontal .path27:before {
  content: "\ea98";
  margin-left: -4em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask-horizontal .path28:before {
  content: "\ea99";
  margin-left: -4em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask-horizontal .path29:before {
  content: "\ea9a";
  margin-left: -4em;
  color: rgb(234, 141, 58);
}
.idea-illu-metamask-horizontal .path30:before {
  content: "\ea9b";
  margin-left: -4em;
  color: rgb(248, 157, 53);
}
.idea-illu-metamask-horizontal .path31:before {
  content: "\ea9c";
  margin-left: -4em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask-horizontal .path32:before {
  content: "\ea9d";
  margin-left: -4em;
  color: rgb(234, 142, 58);
}
.idea-illu-metamask-horizontal .path33:before {
  content: "\ea9e";
  margin-left: -4em;
  color: rgb(216, 124, 48);
}
.idea-illu-metamask-horizontal .path34:before {
  content: "\ea9f";
  margin-left: -4em;
  color: rgb(235, 143, 53);
}
.idea-illu-metamask-horizontal .path35:before {
  content: "\eaa0";
  margin-left: -4em;
  color: rgb(232, 130, 30);
}
.idea-illu-metamask-horizontal .path36:before {
  content: "\eaa1";
  margin-left: -4em;
  color: rgb(57, 57, 57);
}
.idea-illu-metamask-horizontal .path37:before {
  content: "\eaa2";
  margin-left: -4em;
  color: rgb(232, 143, 53);
}
.idea-illu-metamask-horizontal .path38:before {
  content: "\eaa3";
  margin-left: -4em;
  color: rgb(142, 90, 48);
}
.idea-illu-visa .path1:before {
  content: "\ea01";
  color: rgb(0, 87, 159);
}
.idea-illu-visa .path2:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(0, 87, 159);
}
.idea-illu-visa .path3:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(0, 87, 159);
}
.idea-illu-visa .path4:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(0, 87, 159);
}
.idea-illu-visa .path5:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(250, 166, 26);
}
.idea-illu-wallet-connect .path1:before {
  content: "\eaa4";
  color: rgb(57, 153, 250);
}
.idea-illu-wallet-connect .path2:before {
  content: "\eaa5";
  margin-left: -4em;
  color: rgb(58, 153, 251);
}
.idea-image:before {
  content: "\e91f";
}
.idea-image-file:before {
  content: "\e91e";
}
.idea-insight:before {
  content: "\e920";
}
.idea-interview:before {
  content: "\e921";
}
.idea-linkedin:before {
  content: "\e922";
}
.idea-lock:before {
  content: "\e923";
}
.idea-logo-hackathon:before {
  content: "\e9b7";
}
.idea-logout:before {
  content: "\e924";
}
.idea-mail:before {
  content: "\e925";
}
.idea-menu:before {
  content: "\e926";
}
.idea-message:before {
  content: "\e928";
}
.idea-message-phone:before {
  content: "\e927";
}
.idea-mic-off:before {
  content: "\e929";
}
.idea-mic-on:before {
  content: "\e92a";
}
.idea-nocam:before {
  content: "\e92b";
}
.idea-people:before {
  content: "\e92c";
}
.idea-play:before {
  content: "\e92d";
}
.idea-presenatation:before {
  content: "\e92e";
}
.idea-prez-project:before {
  content: "\e92f";
}
.idea-profile:before {
  content: "\e930";
}
.idea-project:before {
  content: "\e931";
}
.idea-question:before {
  content: "\e932";
}
.idea-replay:before {
  content: "\e933";
}
.idea-screenshot:before {
  content: "\e934";
}
.idea-skype:before {
  content: "\e936";
}
.idea-sound-off:before {
  content: "\e937";
}
.idea-sound-on:before {
  content: "\e938";
}
.idea-tic:before {
  content: "\e939";
}
.idea-timer:before {
  content: "\e93a";
}
.idea-trash:before {
  content: "\e93c";
}
.idea-trash-circle:before {
  content: "\e93b";
}
.idea-try-it:before {
  content: "\e93d";
}
.idea-twitter:before {
  content: "\e93e";
}
.idea-txt:before {
  content: "\e93f";
}
.idea-url:before {
  content: "\e940";
}
.idea-valid:before {
  content: "\e941";
}
.idea-visitus:before {
  content: "\e942";
}
.idea-vote:before {
  content: "\e943";
}
.idea-wallet:before {
  content: "\e944";
}
.idea-www:before {
  content: "\e945";
}
.idea-zoom:before {
  content: "\e946";
}
