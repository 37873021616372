
































































































































































































































































































































































@import '@/styles/mixins/index';

.single-sponsor-page {
  padding-top: 75px;
}
.header {
  display:flex;
  align-items: center;

  @include respond-to(max-medium) {
    flex-direction: column;
    margin-bottom: 20px;
  }
  @include respond-to(medium) {
    flex-flow: row wrap;
    margin-bottom: 60px;
  }

  &__image {
    flex: 0 0 auto;
    position: relative;
    width: 230px;
    height: 114px;
    transition: 0.3s ease-in-out;
    opacity: 1;
    backface-visibility: hidden;
    object-fit: contain;

    @include respond-to(max-medium) {
      margin-bottom: 40px;
    }
    @include respond-to(medium-slarge) {
      margin-bottom: 20px;
    }
    @include respond-to(slarge) {
      margin-right: 40px;
    }

    .idt-card-file-upload-header__preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: none !important;
      z-index: 10;
    }
  }
  &__connect {
    flex: 1 0 auto;

    @include respond-to(max-slarge) {
      width: 100%;
    }

    &__title {
      color: var(--black);
      font-family: var(--title-font-family);
      line-height: 1.875rem;

      @include respond-to(max-medium) {
        margin-bottom: var(--rem-size-16);
        font-size: 1.25rem;
      }
      @include respond-to(medium) {
        margin-bottom: var(--rem-size-8);
        font-size: 1.5rem;
      }
    }
    &__cards {
      display: flex;

      @include respond-to(max-medium) {
        flex-direction: column;
      }
    }
  }
  &__toggle-edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    margin-left: auto;
  }
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  padding-bottom: 50px;

  &__media {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    object-fit: contain;

    .idt-card-file-upload-header__preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-image: none !important;
      z-index: 10;
    }

    &.no-media {
      height: 400px;

      .idt-card-file-upload-header__preview {
        height: 400px;
      }
    }
  }
  &__navigation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 400px;
  }
  &__froala {
    width: 100%;
    margin-top: 20px;
  }
}

.talks-speakers {
  width: 100%;
  background: var(--grey-lighter);

  &__row {
    &:last-child {
      padding-bottom: 50px;
    }
  }
  &__headers {
    margin: 50px 0;
    color: var(--black);
    font-family: var(--title-font-family);
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
  }
  &__column {
    flex: 0 0 auto;
    width: 100%;
    max-width: 398px;
    padding: 0 12px 24px 12px;
  }
}
.idt-card-file-upload-header__preview {
  padding-top: 0;
  height: 100%;
}
