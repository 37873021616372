









































.login-box {
  position: fixed;
  width: 305px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.2s;
  opacity: 0;
  z-index: 100;
  &__content {
    padding: 30px 35px 25px 35px;
  }
  &__title {
    color: var(--grey-darkest);
    font-size: 1rem;
  }
  &__message {
    color: var(--grey-darker);
    font-size: 1rem;
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  &__sign-in {
    width: 100px;
    margin-right: 15px;
    padding: 10px 18px;
    color: var(--grey-darker);
    border-radius: 3px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: var(--grey-darkest);
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &__register {
    width: 100px;
    margin-bottom: 0;
    padding: 10px 18px;
    border-radius: 3px;
    font-size: 0.875rem;
    text-transform: uppercase;
    border: none;
  }
}
