

















































@import '@/styles/mixins';

.event-resource {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  background-color: var(--white);
  border: 1px solid var(--grey);
  transition: box-shadow var(--default-duration) ease-in-out;

  @include respond-to(max-medium) {
    flex-flow: column wrap;
    padding-bottom: 1rem;
  }
  @include respond-to(medium) {
    flex-flow: row wrap;
  }

  &:hover {
    box-shadow: 0 2px var(--rem-size-8) 0 var(--black-alpha10);
  }
  &__img {
    flex: 0 1 auto;
    align-self: stretch;
    min-height: 146px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-to(medium) {
      width: 23.5%;
    }
  }
  &__info {
    flex: 1 1 0;

    @include respond-to(max-large) {
      padding: 1rem 1.5rem;
    }
    @include respond-to(large) {
      padding: 1rem 2.5rem;
    }
  }
  &__title {
    color: var(--grey-darkest);
    font-size: 1.8rem;
    font-weight: 300;
  }
  &__desc {
    color: var(--grey-dark);
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.2;
  }
  &__link {
    flex: 0 1 auto;

    @include respond-to(max-large) {
      padding: 1rem;
    }
    @include respond-to(large) {
      padding: 1rem 1.5rem;
    }

    .btn {
      padding: 0.75rem 1rem;
      color: var(--main-color-dark);
      background-color: var(--main-color-alpha20);
      font-weight: 500;
      text-transform: uppercase;

      &:hover {
        background-color: var(--main-color-dark-alpha20);
      }
    }
  }
}
