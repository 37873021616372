@import './colors';
@import './rem-size';
@import './typo';
@import './utils';

:root {
  @include colors();
  @include remSize();
  @include typo();
  @include utils();
}

// TODO remove after Foundation deletion
$black: #000000;
$white: #FFFFFF;
$grey-1: #D1D1D1;
$grey-2: #BCBEC0;
$grey-3: #6D6F71;
$grey-12: #4D4D4D;
$global-radius: 6px;
