


























































.fo-project-section-hiring-edit {
  text-align: center;

  &__title {
    margin-bottom: var(--rem-size-16);
    color: black;
  }
}
