
// -----------------------------
// 1. Statistics
// -----------------------------
.bo-section-activation {
  display: none;
}

// -----------------------------
// 2. Vertical Menu
// -----------------------------
.bo-vertical {
  @include respond-to(xxlarge) {
    .xlarge-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  hr {
    max-width: 100%;
    border-color: var(--grey);

    &:not(.title) {
      @include respond-to(max-medium) {
        margin: 1rem 0 1.5rem;
      }
      @include respond-to(medium) {
        margin: 1.75rem 0;
      }
    }

    &.title {
      @include respond-to(max-medium) {
        margin: 1rem 0 1.5rem;
      }
      @include respond-to(medium) {
        margin: 0.25rem 0 1.75rem;
      }
    }
  }
  &__sidebar {
    position: fixed;
    width: 90px;
    height: 100%;
    background-color: var(--main-color);

    @include respond-to(max-medium) {
      transform: translateX(-100%);
      transition: all 0.3s ease;

      &.open {
        transform: translateX(0);
      }
    }
  }
  &__side-button {
    @include respond-to(max-medium) {
      display: block;
      position: fixed;
      top: 1.15rem;
      left: 1rem;
      width: 44px;
      height: 34px;
      text-align: center;
      z-index: 1010;
      transition: all 0.3s ease;

      &.open {
        transform: rotate(180deg);
      }
      i {
        font-size: 2rem;
      }
    }
    @include respond-to(medium) {
      display: none;
    }
  }
  &__menu {
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: center;
    list-style-type: none;
  }

  &__link {
    position: relative;
    display: block;
    padding: 1rem 0.5rem 0.5rem;
    height: 70px;
    color: var(--white);
    font-size: 0.8rem;
    transition: all 0.3s ease;

    &--too-long {
      padding: 0.7rem 0.5rem 0.5rem;
      line-height: 14px;

      .idt-icon .ideacon {
        margin-bottom: 0.2rem;
      }
    }

    &:hover,
    &.active {
      color: var(--main-color);
      background-color: var(--main-color-lighter);
    }
  }

  &__content {
    min-height: calc(100vh - (70px + 120px));

    @include respond-to(max-medium) {
      padding: 2rem 0.5rem;
      transform: translateX(0);
      transition: all 0.3s ease;

      &.open {
        transform: translateX(90px);
      }
    }
    @include respond-to(medium) {
      margin-left: 90px;
    }
    @include respond-to(medium-large) {
      padding: 1rem;
    }
    @include respond-to(large-xlarge) {
      padding: 2rem;
    }
    @include respond-to(xlarge) {
      padding: 2rem 3rem 3rem;
    }
  }

  &__title {
    font-size: 2.1875rem;
    font-weight: 300;
    line-height: 1;

    @include respond-to(max-medium) {
      width: 100%;
    }

    &.bordered {
      padding-right: calc(2 * 0.9375rem);
      border-right: 1px solid var(--grey);
      line-height: 1;
    }
  }
  &__section {
    &__title {
      font-size: 1.7rem;
      font-weight: 300;

      &:not(.no-space) {
        margin: 0 0 30px 0;
      }
      &.no-space {
        margin: 0;
      }
    }
    &__number {
      display: inline-block;
      width: 27px;
      height: 27px;
      color: $white;
      background-color: var(--main-color);
      border-radius: 100%;
      font-size: 1.1rem;
      text-align: center;
      line-height: 28px;
      vertical-align: text-top;
    }
  }

  &__tips {
    &__container {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      @include respond-to(max-medium) {
        flex-flow: column;
      }
      @include respond-to(medium) {
        flex-flow: row wrap;
      }

      &.has-label {
        @include respond-to(large) {
          margin-top: 24px;
        }
      }
    }

    &__title {
      flex: 0 1 auto;
      color: var(--main-color);
      font-size: 1.5rem;
      font-weight: 300;
      text-transform: uppercase;

      @include respond-to(medium) {
        padding: 0.30rem .9375rem 0.30rem 0;
        border-right: 1px solid var(--grey);
      }

      &.alert {
        color: var(--secondary-color);
      }
    }
    &__text {
      flex: 1 1 0px;

      @include respond-to(medium) {
        padding-left: 0.9375rem;
      }
    }
  }
  &__btn-save {
    margin: 0;
    padding: 0.75rem;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.bo-modal {
  &__title {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
  }
  &__subtitle {
    color: var(--main-color);
    text-align: center;
  }
}

// -----------------------------
// 3. Actions
// -----------------------------
.bo-actions {
  @include respond-to(max-slarge) {
    margin-top: 1rem;
  }

  .button {
    @include respond-to(max-medium) {
      width: 100%;
      max-width: 400px;
      margin: 0.25rem;
    }
    @include respond-to(medium) {
      margin: 0;
    }
    @media (hover:hover) {
      &:hover {
        color: var(--white) !important;
        background-color: var(--main-color) !important;
        border-color: var(--main-color) !important;
      }
    }
  }
}

// -----------------------------
// 4. Statistics
// -----------------------------
.bo-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  border-radius: calc(var(--global-radius) * 2);
  box-shadow: 0 1px 10px -4px $black;

  &__title {
    color: var(--main-color);
    font-size: 1.5rem;
    font-weight: 300;
  }
  &__list {
    display: flex;

    &__item:not(:last-child) {
      position: relative;
      margin-right: 1.5rem;
      padding-right: 1.5rem;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        height: 22px;
        border-right: 1px solid var(--grey);
        transform: translateY(-50%);
      }
    }
  }
  &__value,
  &__label {
    display: inline-block;
  }
  &__value {
    padding-right: 0.5rem;
    font-size: 1.75rem;
    vertical-align: middle;
  }
  &__label {
    max-width: 70px;
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.1;
    vertical-align: middle;
  }
}

// -----------------------------
// 5. Quick actions
// -----------------------------
.bo-table-actions {
  align-items: flex-end;
  margin-bottom: 1rem;

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: remCalc(8);
  }
  &__search {
    display: inline-block;
    position: relative;
    width: 290px;
    vertical-align: middle;

    &:after {
      content: '\e96e';
      display: block;
      position: absolute;
      top: 50%;
      right: 0.5rem;
      font-family: var(--ideacons-font);
      font-size: 1rem;
      transform: translateY(-50%);
    }
    &__input {
      height: 34px !important;
      margin: 0;
      padding-right: 1.75rem !important;
    }
  }
  &__buttons {
    display: flex;
    align-items: flex-end;
    gap: var(--rem-size-4);

    @include respond-to(max-medium) {
      margin-bottom: var(--rem-size-8);
    }
  }
  &__ticket {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: var(--rem-size-4);

    &__label {
      flex: 0 0 auto;
      width: 100%;
      margin-bottom: var(--rem-size-4);
      color: var(--grey-darkest);
      font-size: var(--rem-size-12);
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
    }
  }
  .separator {
    flex: 0 0 auto;
    height: var(--rem-size-32);
    margin: 0 var(--rem-size-4);
    border-left: 1px solid var(--grey);
  }
}

// Notifications
.bo-notif-tags {
  display: flex;
  align-items: flex-start;

  &__title {
    margin-bottom: 1rem;
    font-weight: 300;
  }
  &__type {
    flex: 0 0 120px;
    position: relative;
    padding-right: 1rem;
    color: var(--grey-darker);
    font-weight: 500;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      height: 18px;
      border-right: 1px solid var(--grey);
      transform: translateY(-50%);
    }
  }
  &__content {
    flex: 1 1 auto;
    padding-left: 1rem;
  }
  &__list {
    margin: 0;
    list-style-type: none;
  }
  &__info {
    margin: 10px 0 15px 0;
    font-size: 0.75rem;
    color: var(--grey-darker);
  }
  &__tag {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    &--missing {
      .bo-notif-tags__tag__name {
        color: var(--white);
        font-weight: var(--font-weight-bold);
        background-color: var(--red);
      }
    }

    &--exists {
      .bo-notif-tags__tag__name {
        color: var(--white);
        font-weight: var(--font-weight-bold);
        background-color: var(--main-color);
      }
    }

    &__name {
      flex: 0 0 auto;
      display: inline-block;
      width: 125px;
      height: 25px;
      margin-right: 0.5rem;
      color: var(--grey-darker);
      background-color: var(--grey-alpha20);
      border-radius: var(--global-radius);
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;
      line-height: 26px;
    }
    &__desc {
      color: var(--grey-darker);
      font-size: 0.75rem;
      line-height: 1;
    }
  }
}
