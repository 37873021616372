.bo-dashboard-tooltip rect {
  background-color: var(--black);
  stroke: rgba(0,0,0,0);
}

.bo-dashboard-tooltip polyline {
  stroke: rgba(0,0,0,0);
}

.bo-dashboard-tooltip text {
  color: var(--white);
  fill: var(--white);
  font-family: var(--text-font-family);
}

.bo-dashboard-tooltip circle.inner {
  fill: var(--white);
}

.bo-dashboard-tooltip circle.outer {
  fill: var(--main-color);
}

.bo-dashboard-tooltip__value {
  font-weight: var(--font-weight-bold);
}

