





















































.idt-create-survey-rating {

  &__title {
    font-size: var(--rem-size-12);
    color: var(--black);
    font-weight: var(--font-weight-bold);
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: var(--rem-size-10);

    &__stars {
      .idt-icon.active {
        color: var(--yellow);
      }
    }

    .idt-input-container {
      flex-grow: 1;
      padding-bottom: 0;
      margin-left: 1rem;
    }
  }
}
