


















































































































.bo-card {
  display: flex;
  padding: 0 0.9375rem 0rem 0.9375rem;
  margin-bottom: 1rem;

  &__field {
    color: var(--grey-darker);
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);

    div,
    input,
    textarea {
      margin: 5px 15px 5px 15px;
    }

    ::v-deep label {
      color: var(--grey-darker);
      font-size: 1rem;
    }
  }

  &__label {
    color: var(--grey-dark);
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);
  }

  &__content {
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;

    &__bordered {
      padding: 3px 0;
      flex-grow: 1;

      &--with-header {
        border-right: solid 2px var(--grey-light);
        border-left: solid 2px var(--grey-light);
      }

      &--without-header {
        border-top: solid 2px var(--grey-light);
        border-right: solid 2px var(--grey-light);
        border-left: solid 2px var(--grey-light);
      }
    }
  }

  &__content__preview:hover &__file.has-file {
    background-color: rgba(0, 0, 0, 0.7);

    span.white-hovered {
      color: var(--white);
    }
  }

  &__switch {
    display: inline-block;
    right: 0;
    margin-left: 40px;
    margin-bottom: 10px;
  }

  &__switch-container {
    display: flex;
    flex-direction: row;
    margin: 5px 15px;
    font-size: 0.875rem;
    font-weight: var(--font-weight-normal);

    label {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 1rem;
      color: var(--grey-darker);
    }
  }
}
