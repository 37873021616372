












































































@import '@/styles/mixins/index';

.idt-user-booking-selected-slot {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  padding: 0 1.5rem;
  background-color: var(--white);
  filter: drop-shadow(0 2px 2px var(--black-alpha20));

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5rem;
    margin-right: .5rem;

    &__title {
      font-size: var(--rem-size-14);
      font-weight: var(--font-weight-bold);
    }

    &__content {
      display: inline-flex;
      align-items: center;
      height: var(--rem-size-36);
      font-size: var(--rem-size-16);
    }

    &__attendees {
      flex-grow: 1;

      ::v-deep .idt-tag {
        height: var(--rem-size-36);
      }
    }

    .button {
      margin: 0;
    }
  }
}
