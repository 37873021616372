.idt-file-upload-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--rem-size-14) 0;
  color: var(--black);
  text-align: left;
}

.idt-file-upload-item__icon__preview {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--grey-light);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: background-color 200ms ease,transform 200ms ease;
  will-change: transform;
}

.idt-file-upload-item__icon__preview:hover {
  transform: scale(5);
  z-index: 1;
}

.idt-file-upload-item__progress {
  flex-grow: 1;
  min-width: 0;
  margin: 0 var(--rem-size-22) 0 var(--rem-size-10);
  padding-bottom: 5px;
  font-weight: var(--font-weight-medium);
}

.idt-file-upload-item__progress__label {
  display: flex;
  flex-grow: 2;
  font-weight: var(--font-weight-bold);
  line-height: var(--rem-size-32);
  transition: transform 200ms ease;
  will-change: transform;
}

.idt-file-upload-item__progress__label.no-progress {
  transform: translateY(0.3rem);
}

.idt-file-upload-item__progress__label.read-only {
  color: var(--grey-dark);
}

.idt-file-upload-item__progress__label__error {
  position: absolute;
  width: 100%;
  color: var(--secondary-color);
  font-weight: var(--font-weight-normal);
}

.idt-file-upload-item__progress__label__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.idt-file-upload-item__progress__label__size {
  margin-left: auto;
  color: var(--grey-dark);
  font-weight: var(--font-weight-normal);
  white-space: nowrap;
}

.idt-file-upload-item__progress__label button {
  margin: 0 var(--rem-size-12);
  color: var(--grey-dark);
  text-decoration: underline;
  transition: color 200ms ease;
  cursor: pointer;
}

.idt-file-upload-item__progress__label button:hover {
  color: var(--black);
}

.idt-file-upload-item__progress ::v-deep [role=progressbar] {
  height: .56rem;
  margin: 0;
  background-color: var(--grey-light);
  border-radius: 50px;
}

.idt-file-upload-item__progress ::v-deep [role=progressbar] .progress-meter {
  border-radius: 50px;
}

.idt-file-upload-item__actions {
  flex-shrink: 0;
  width: 5.725rem;
  margin-left: auto;
  text-align: right;
}

.idt-file-upload-item__actions__action {
  width: 2.5rem;
  background-color: var(--grey-light);
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  transition: background-color 200ms ease;
}

.idt-file-upload-item__actions__action:not([disabled]):hover {
  background-color: var(--grey);
  cursor: pointer;
}

.idt-file-upload-item__actions__action.idt-file-upload-item--hidden {
  display: none;
}

.idt-file-upload-item__actions__action+.idt-file-upload-item__actions__action {
  margin-left: .375rem;
}

.idt-file-upload-item__actions__action ::v-deep .idea-ht-out {
  margin-top: .15rem;
}

