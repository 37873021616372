
































































































.fo-project-mode-edit-new-project-message-modal {
  &__title {
    font-weight: var(--font-weight-medium);
    color: var(--black);
  }

  &__content {
    margin-top: var(--rem-size-16);
    margin-bottom: var(--rem-size-20);
    color: var(--grey-darker);
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-normal);

    &__first-line {
      margin-bottom: 0;
    }
  }

  &__input {
    width: 90%;
  }

  .button {
    width: 45%;
    font-size: var(--rem-size-16);
    font-weight: var(--font-weight-medium);
  }
}
