





























































.idt-sort {
  &.clickable {
    cursor: pointer;
  }
}
