.idt-input-container {
  position: relative;
}

@media only screen and (max-width: 639px) {
  .idt-input-container:not(.no-margin) {
    padding-bottom: var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .idt-input-container:not(.no-margin) {
    padding-bottom: var(--rem-size-24);
  }
}

.idt-input-container.limited .idt-input {
  padding-right: 4rem;
}

.idt-input-container.container-only {
  display: contents;
}

.idt-input-container__icon {
  position: absolute;
  bottom: var(--rem-size-30);
  right: var(--rem-size-15);
}

.idt-input {
  margin-bottom: 0rem;
}

.idt-input[disabled],
.idt-input[readonly] {
  background-color: var(--grey-lighter);
  user-select: none;
}

.idt-input.has-error {
  border-color: var(--danger-color) !important;
}

.idt-input-counter {
  position: absolute;
  right: 1rem;
  bottom: 2.1875rem;
  color: var(--grey-dark);
}

::v-deep .idt-required-field {
  position: absolute;
  bottom: 0;
  left: 0;
}

