.fo-lobby {
  flex: auto;
  align-items: flex-start;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .fo-lobby {
    padding: 0;
  }
}

.fo-lobby__wrapper {
  display: flex;
  flex-flow: column;
  height: calc(var(--vh, 1vh)*100 - var(--menu-height));
  background-color: var(--grey-lighter);
}

.fo-lobby__mobile-close-header {
  padding: var(--rem-size-16);
  display: flex;
}

.fo-lobby__mobile-close-header a {
  display: inline-flex;
  margin-left: auto;
}

.fo-lobby--sponsor .fo-lobby__mobile-close-header a {
  margin-left: 0;
}

.fo-lobby .fo-lobby-categories,
.fo-lobby__right-panel {
  flex-shrink: 0;
  width: 26.2376%;
  max-width: 348px;
  height: 100%;
  transition: width .3s ease-in-out;
}

.fo-lobby .fo-lobby-categories--hidden,
.fo-lobby__right-panel--hidden {
  width: 0;
  overflow-x: hidden;
}

.fo-lobby__content {
  display: flex;
  flex-flow: column;
  flex: auto;
  align-self: stretch;
  align-items: stretch;
  background: var(--white);
  border-radius: var(--global-radius);
  box-shadow: 0px 2px 3px var(--grey-darkest-alpha20);
  font-size: var(--rem-size-14);
  overflow: hidden;
  transition: width .3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .fo-lobby__content {
    border-radius: 0;
    box-shadow: none;
  }
}

.fo-lobby__mobile-only {
  display: none;
}

.fo-lobby__right-panel--users {
  position: absolute;
  top: 0;
  right: 0;
  width: 388px;
  max-width: 388px;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  transition: transform .3s ease-in-out;
  transform: translateX(100%);
}

@media only screen and (max-width: 991px) {
  .fo-lobby__right-panel--users {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 992px) {
  .fo-lobby__right-panel--users {
    padding: var(--rem-size-24);
  }
}

@media only screen and (max-width: 991px) {
  .fo-lobby__mobile-only {
    display: flex;
  }

  .fo-lobby .fo-lobby-categories,
  .fo-lobby__right-panel {
    left: 0;
    width: 100%;
    max-width: none;
    padding-bottom: var(--mobile-bottom-navbar-height);
    background: var(--white);
    overflow: auto;
    transition: transform .3s ease-in-out;
  }

  .fo-lobby .fo-lobby-categories:not(.fo-lobby__right-panel--users),
  .fo-lobby__right-panel:not(.fo-lobby__right-panel--users) {
    position: fixed;
    top: var(--menu-height);
    height: calc(var(--vh, 1vh)*100 - var(--menu-height));
    z-index: 1001;
  }

  .fo-lobby .fo-lobby-categories {
    transform: translateX(-100%);
  }

  .fo-lobby__right-panel {
    transform: translateX(100%);
  }
}

.fo-lobby--categories .fo-lobby-categories {
  transform: translateX(0);
}

.fo-lobby--sponsor .fo-lobby__right-panel--sponsor {
  transform: translateX(0);
}

.fo-lobby--users .fo-lobby__right-panel--users {
  transform: translateX(0);
}

