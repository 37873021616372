.tab {
  height: 100%;
  margin-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-size: var(--rem-size-14);
}

.tab__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.tab__content .content__container__data {
  border-top: 1px solid var(--grey-lighter);
}

.tab__content .content__container__row {
  display: flex;
  justify-content: space-between;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  font-size: var(--rem-size-14);
}

.tab__content .content__container__row.border {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  border-bottom: 1px solid var(--grey);
}

.tab__content .content__container__row.border:last-child {
  border-bottom: 1px solid var(--grey-lighter);
}

.tab__content .content__container__row.header {
  margin-bottom: .625rem;
  font-weight: var(--font-weight-bold);
}

.tab__content .content__container__row .row__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tab__content .content__container__row .row__value {
  margin-left: 1.5rem;
}

@media only screen and (max-width: 1199px) {
  .tab__content .idt-table-pagination li a {
    width: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .tab__content .idt-table-pagination li a {
    width: 35px;
  }
}

