


















































.gamification-widget {
  height: 44px;
  border: 1px solid var(--grey-light);
  border-radius: 50px;
  padding: 0.5rem 1rem;
  text-align: center;
  vertical-align: middle;
  font-size: var(--rem-size-14);
  font-weight: var(--font-weight-bold);
  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    img {
      height: 24px;
      width: 24px;
      margin-right: 10px;
      align-self: center;
      &.rotate {
        animation:spin 4s linear infinite;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}
@keyframes spin { 100% { -webkit-transform: rotateY(360deg); transform:rotateY(360deg); } }
