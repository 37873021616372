.fo-project-section-team-view {
  margin: 0 2rem 2rem 2rem;
}

.fo-project-section-team-view__title {
  margin-bottom: 2.5rem;
  text-align: center;
  color: var(--grey-darkest);
}

.fo-project-section-team-view__participants {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.fo-project-section-team-view__no-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2.25rem;
  border: 1px dashed var(--grey);
  border-radius: var(--global-radius);
}

.fo-project-section-team-view__no-content__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
  color: var(--grey-darkest);
  background-color: var(--grey);
  border-radius: 50%;
}

.fo-project-section-team-view__no-content__text {
  font-weight: var(--font-weight-medium);
  color: var(--grey);
}

