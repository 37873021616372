

































































































































































@import '@/styles/mixins/index';

.signin {
  &__no-account {
    margin-bottom: var(--rem-size-16);
    text-align: center;
  }
  &__error {
    position: relative;
    top: -15px;
    height: 0;
  }
  &__forgot {
    position: relative;
    text-align: right;
    margin-bottom: 1.5rem;

    &__button {
      color: var(--main-color);
      font-size: var(--rem-size-14);
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__mentor {
    text-align: center;

    &__button {
      cursor: pointer;
    }
  }
  &__no-account__link,
  &__mentor__button {
    color: var(--grey-darker);
    font-size: var(--rem-size-14);
    text-decoration: underline;
  }
}
