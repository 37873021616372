











































































@import '@/styles/mixins/index';

.modal-large {
  &__close {
    position: absolute;
    top: var(--rem-size-15);
    right: var(--rem-size-15);
    cursor: pointer;
  }
  &__content {
    @include respond-to(max-large) {
      padding: 45px var(--rem-size-20) var(--rem-size-25);
    }
    @include respond-to(mdeium) {
      padding: 45px var(--rem-size-40) var(--rem-size-25);
    }
  }
}
