




































































































.idt-emoji-picker {
  &__emoji-button {
    &:hover {
      --color2: var(--emoji-color);
    }
  }
  &__picker-container {
    position: relative;

    & .emoji-mart {
      position: absolute;
      bottom: 40px;
      right: 0;
      z-index: 2;
    }
  }
}
