.idt-profile-info textarea {
  height: 157px;
  margin-bottom: 0;
}

.idt-profile-info textarea.has-error {
  border-color: var(--secondary-color);
}

.idt-profile-info__section {
  margin-bottom: 25px;
}

.idt-profile-info__avatar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.idt-profile-info__avatar__label {
  font-weight: var(--font-weight-bold);
}

.idt-profile-info__avatar__image {
  margin-right: 12px;
}

.idt-profile-info__avatar__actions__label {
  line-height: 1.2rem;
  font-weight: var(--font-weight-bold);
}

.idt-profile-info__avatar__actions__buttons {
  display: flex;
  align-items: center;
  gap: var(--rem-size-8);
}

.idt-profile-info__avatar__actions__buttons__remove:not(:hover).button {
  color: var(--black) !important;
  background-color: var(--grey-light);
}

.idt-profile-info__tags {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--rem-size-8);
}

.idt-profile-info__tags__label {
  margin-bottom: 5px;
}

.idt-profile-info__select-status {
  margin: 0;
}

