











































































































































@import '@/styles/mixins/index';

.idt-sidebar-user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--grey-alpha20);
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--white-alpha10);
  }
  &__content {
    display: flex;
    align-items: center;
    width: calc(100% - 3.75rem);
    padding: var(--rem-size-14) 0;
    cursor: pointer;

    .content__avatar {
      flex: 0 0 auto;
      position: relative;
      width: 2.625rem;
      height: 2.625rem;
      background-color: var(--white);
      border-radius: 100%;
    }
    .content__text {
      width: calc(100% - 2.625rem);
      padding: 0 var(--rem-size-11);

      &__name {
        color: var(--white);
        font-size: var(--rem-size-14);
        font-weight: var(--font-weight-bold);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__description {
        color: var(--white-alpha70);
        font-size: var(--rem-size-12);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  &__action {
    position: relative;

    &__button {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      margin: 0 var(--rem-size-14);
      color: var(--white);
      background-color: var(--main-color);
      border-radius: 50%;
      cursor: pointer;
    }
    &__pane-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--rem-size-8) var(--rem-size-20);
      font-size: var(--rem-size-14);
      border-bottom: 1px solid var(--grey);

      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: var(--grey-light);
        cursor: pointer;
      }
      &__label {
        white-space: nowrap;
      }
    }
  }
  ::v-deep .idt-dropdown {
    width: unset;

    &--pane {
      width: unset;
      left: unset !important;
      right: 5px;
    }
  }
}
