









































.idt-edit-button {
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  cursor: pointer;

  &:hover {
    background: #EEEFF2;
    color: var(--black);
    border-radius: var(--global-radius);
  }

  &__label {
    margin-left: 10px;
    margin-right: 5px;
  }
}
