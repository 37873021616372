.bo-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--menu-height);
  background-color: var(--grey-darkest);
}

@media only screen and (max-width: 639px) {
  .bo-menu {
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 640px) {
  .bo-menu {
    padding: 0 1.5rem;
  }
}

.bo-menu__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
}

.bo-menu__title {
  flex: 0 0 auto;
  color: var(--grey);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

@media only screen and (max-width: 639px) {
  .bo-menu__title {
    margin-left: 3rem;
    padding-left: 1rem;
    border-left: 1px solid var(--grey);
  }
}

.bo-menu__content {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .bo-menu__content {
    flex-direction: column;
    border-top: 1px solid var(--main-color-light);
  }

  .bo-menu__content:not(.open) {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-menu__content {
    align-items: center;
  }
}

@media only screen and (max-width: 639px) {
  .bo-menu__content.open {
    width: 100vw;
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .bo-menu__content.open {
    width: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-menu__content.open {
    position: absolute;
    top: var(--menu-height);
    right: 0;
    height: calc(var(--vh)*100 - var(--menu-height));
    background-color: var(--grey-darkest);
    z-index: 9999;
    overflow-y: auto;
  }
}

.bo-menu__content__main,
.bo-menu__content__global-features,
.bo-menu__content__features {
  display: flex;
  padding: 0;
  list-style-type: none;
}

@media only screen and (max-width: 1199px) {
  .bo-menu__content__main,
  .bo-menu__content__global-features,
  .bo-menu__content__features {
    flex-direction: column;
    margin: 0 0 .75rem 0;
  }
}

@media only screen and (min-width: 1200px) {
  .bo-menu__content__main,
  .bo-menu__content__global-features,
  .bo-menu__content__features {
    margin: 0 .75rem 0 0;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-menu__content__main,
  .bo-menu__content__global-features {
    padding-bottom: .75rem;
    border-bottom: 1px solid var(--grey-dark);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-menu__content__main,
  .bo-menu__content__global-features {
    padding-right: .75rem;
    border-right: 1px solid var(--grey-dark);
  }
}

@media only screen and (max-width: 1199px) {
  .bo-menu__content__main {
    padding-left: var(--rem-size-18);
  }
}

.bo-menu__btn-fo {
  margin: 0;
  background-color: var(--grey-darker);
  border: none;
  color: var(--white);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.bo-menu__btn-fo:hover {
  background-color: var(--main-color-light);
  color: var(--grey-darkest);
}

@media only screen and (max-width: 639px) {
  .bo-menu__btn-fo--mobile {
    border-radius: 0;
    font-size: var(--rem-size-16);
  }
}

@media only screen and (min-width: 640px) {
  .bo-menu__btn-fo--mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 639px) {
  .bo-menu__btn-fo--desktop {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .bo-menu__btn-fo--desktop {
    font-size: var(--rem-size-12);
  }
}

@media only screen and (min-width: 640px)and (max-width: 1199px) {
  .bo-menu__btn-fo--desktop {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 479px) {
  .bo-menu__burger {
    flex: 0 0 auto;
  }
}

@media only screen and (max-width: 1199px) {
  .bo-menu__burger {
    display: block;
    position: relative;
    width: calc(var(--menu-height)*.75);
    height: var(--menu-height);
    color: var(--white);
  }

  .bo-menu__burger.is-active span:nth-child(1) {
    transform: translateX(-50%) translateY(11px) rotate(45deg);
  }

  .bo-menu__burger.is-active span:nth-child(2) {
    opacity: 0;
  }

  .bo-menu__burger.is-active span:nth-child(3) {
    transform: translateX(-50%) translateY(-11px) rotate(-45deg);
  }

  .bo-menu__burger span {
    display: block;
    position: absolute;
    left: 50%;
    width: 35px;
    height: 3px;
    background-color: currentColor;
    transition: all 86ms ease-out;
    transform: translateX(-50%);
  }

  .bo-menu__burger span:nth-child(1) {
    top: calc(50% - 12px);
  }

  .bo-menu__burger span:nth-child(2) {
    top: calc(50% - 1px);
  }

  .bo-menu__burger span:nth-child(3) {
    top: calc(50% + 10px);
  }
}

@media only screen and (min-width: 1200px) {
  .bo-menu__burger {
    display: none;
  }
}

