.bo-project-modal-settings-information__image-upload {
  display: flex;
  align-items: center;
}

.bo-project-modal-settings-information__image-upload__uploader.fs__image-upload {
  height: 170px;
  width: 300px;
}

.bo-project-modal-settings-information__logo-upload.fs__image-upload {
  height: 180px;
  width: 180px;
}

