






























































































































































































































































































































































































































































































































































@import '@/styles/functions';
@import '@/styles/mixins';

.projects-card {
  &__container {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
    gap: var(--rem-size-14) var(--rem-size-14);
    padding: var(--rem-size-5);

    &__no-more {
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: var(--rem-size-15);
    }
    &.grid {
      @include respond-to(max-small) {
        flex-direction: column;
        align-items: center;
      }
      ::v-deep .project-grid-card {
        width: calc(33% - var(--rem-size-8));
        min-width: 280px;
      }
    }
    &.list {
      gap: var(--rem-size-4);

      &__card {
        width: 100%;
      }
    }
  }
}
.projects-page {
  height: calc((var(--vh, 1vh) * 100) - 70px);
  background-color: var(--grey-lighter);
  font-family: var(--text-font-family);
  overflow: auto;

  @include respond-to(max-large) {
    height: fit-content;
    overflow: unset;
    background-color: var(--white);
  }
  &__projects {
    position: relative;

    .side-open-enter-active {
      transition: opacity 1s;
    }
    .side-open-enter {
      opacity: 0;
    }
    &__filter-panel {
      position: sticky;
      top: 0;
      max-width: 280px;
      height: calc(100vh - 70px);
      padding: var(--rem-size-16);
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      @include respond-to(max-large) {
        background-color: var(--white);
        max-width: none;
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, 0);
        transition: transform .3s ease;
        z-index: 11;

        &.mobile-hidden {
          transform: translate3d(-100%, 0, 0);
        }
      }
    }
    &__filter-panel::-webkit-scrollbar {
      display: none;
    }
    &__projects-list {
      &__header {
        position: sticky;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: var(--rem-size-15) 0 var(--rem-size-25) 0;
        background-color: var(--grey-lighter);
        z-index: 10;

        @include respond-to(max-large) {
          top: 70px;
          background-color: var(--white);
          padding: var(--rem-size-15) 0 var(--rem-size-10) 0;
        }

        &__left {
          flex-shrink: 0;
          margin-right: var(--rem-size-16);

          &__title {
            margin-bottom: var(--rem-size-16);
            font-size: var(--rem-size-24);
            line-height: var(--rem-size-30);
            font-weight: var(--font-weight-medium);
            color: var(--grey-darkest);
          }
          &__results {
            font-weight: var(--rem-size-16);
            line-height: var(--rem-size-24);
            color: var(--grey-darker);
          }
        }
        &__right {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: var(--rem-size-16);

          &__list-type {
            display: flex;
            align-items: center;
            margin-left: var(--rem-size-24);

            @include respond-to(max-large) {
              display: none;
            }
            &__button {
              color: var(--grey-darkest);
              cursor: pointer;

              &:first-child {
                margin-right: var(--rem-size-16);
              }
              &.active {
                color: var(--main-color);
              }
            }
          }
        }

        &__infos {
          align-content: center;
          height: 64px;
          background: var(--white);
          border-radius: var(--global-radius);
          box-shadow: 0px 2px 3px var(--grey);

          @include respond-to(max-large) {
            height: 0;
            visibility: hidden;
          }
          &__sort {
            &__label {
              margin: var(--rem-size-5) var(--rem-size-10) 0 0;
              color: var(--black);
            }
            &__select {
              width: 170px;
              height: 41px;
              margin: 0 var(--rem-size-10) 0 0;
            }
          }
          &__list-mode {
            color: var(--grey);

            &__button {
              margin-right: var(--rem-size-10);
              &:hover {
                cursor: pointer;
              }
              &.active {
                color: var(--black);
              }
            }
          }
        }
      }
      &__list {
        &__mine {

          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: var(--rem-size-24);

            &__title {
              font-size: var(--rem-size-18);
              line-height: var(--rem-size-24);
              font-weight: var(--font-weight-medium);
              color: var(--grey-darkest);
            }
          }
          &__empty-dialog {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            min-height: 128px;
            margin: 0 var(--rem-size-5);
            padding: var(--rem-size-16) var(--rem-size-32);
            background-color: var(--white);
            border-radius: var(--global-radius);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);

            @include respond-to(max-medium) {
              padding: var(--rem-size-20);
            }
            &__icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 60px;
              margin-right: var(--rem-size-24);
              background-color: var(--main-color-lighter);
              color: var(--main-color);
              border-radius: 50%;

              .idt-icon ::v-deep .ideacon {
                transform: rotate(45deg);
              }
            }
            &__text {
              margin: var(--rem-size-16) 0;
              margin-right: auto;

              &__headline {
                margin-bottom: var(--rem-size-4);
                font-size: var(--rem-size-18);
                line-height: var(--rem-size-24);
                font-weight: var(--font-weight-medium);
                color: var(--grey-darkest);
              }
              &__subtitle {
                max-width: 450px;
                margin-bottom: 0;
                font-size: var(--rem-size-14);
                line-height: var(--rem-size-20);
                color: var(--grey-darker);
              }
            }

            &__button {
              margin-bottom: 0;

              .idt-icon ::v-deep .ideacon {
                transform: rotate(45deg);
              }
            }
          }
          &__empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;

            &__icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              margin-bottom: var(--rem-size-16);
              color: var(--grey-darker);
              background: var(--grey-light);
              border-radius: 50%;
            }
            &__text {
              font-size: var(--rem-size-16);
              line-height: var(--rem-size-24);
              color: var(--grey-darkest);
            }
          }
        }

        &__all {
          @include respond-to(max-slarge) {
            margin: remCalc(40) 0 remCalc(96);
          }
          @include respond-to(slarge) {
            margin: remCalc(40) 0;
          }

          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: var(--rem-size-24);

            &__title {
              font-size: var(--rem-size-18);
              line-height: var(--rem-size-24);
              font-weight: var(--font-weight-medium);
              color: var(--grey-darkest);
            }
          }
          &__empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;

            &__icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              margin-bottom: var(--rem-size-16);
              color: var(--grey-darker);
              background: var(--grey-light);
              border-radius: 50%;
            }
            &__text {
              font-size: var(--rem-size-16);
              line-height: var(--rem-size-24);
              color: var(--grey-darkest);
            }
          }
        }
      }
      &__no-item-icon {
        padding-bottom: var(--rem-size-15);
      }
    }

    // Project specific design changes on <idt-panel-filter>
    ::v-deep .idt-panel-filter__select label,
    ::v-deep .idt-panel-filter__filters__filter__title {
      margin-left: 0;
      text-transform: uppercase;
    }
    ::v-deep .idt-panel-filter__filters__filter {
      margin-right: 0;

      &__values {
        margin-left: 0;
      }

      &__showmore {
        margin-bottom: var(--rem-size-10);
        text-decoration: none;
      }
    }
    ::v-deep .idt-panel-filter__buttons__button {
      &.active {
        background-color: var(--main-color-dark);
      }
      &.reset {
        border-color: var(--main-color-dark);

        &.idt-panel-filter__buttons__button__title {
          color: var(--main-color-dark);
        }
      }
    }
  }
  .add-project-button {
    display: flex;
    align-items: center;
    padding: var(--rem-size-8) var(--rem-size-24);
    font-weight: var(--font-weight-medium);
    font-size: var(--rem-size-16);

    .idt-icon ::v-deep .ideacon {
      margin-left: var(--rem-size-16);
      color: var(--white);
      transform: rotate(45deg);
    }
  }
}
