
























































































.fo-project-section-custom-questions-edit {
  margin-left: var(--rem-size-32);
  margin-right: var(--rem-size-32);

  &__title {
    margin-bottom: var(--rem-size-16);
    text-align: center;
    color: var(--grey-darkest);
  }
}
