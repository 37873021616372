.custom-form {
  width: 100%;
}

.custom-form__message-container {
  width: 100%;
  padding-bottom: 1.875rem;
}

.custom-form__start-message {
  padding: 1rem;
  background-color: var(--grey-lighter);
  color: var(--grey-darker);
  font-weight: var(--font-weight-medium);
}

.custom-form__end-message {
  padding: 1rem;
  color: var(--grey-dark);
  font-size: .875rem;
}

.custom-form__field {
  width: 100%;
  margin-bottom: 20px;
  background-color: var(--main-color-lightest);
  border-radius: 15px;
}

.custom-form__field ::v-deep label {
  color: var(--grey-darker);
  font-size: .875rem;
  text-align: left;
  word-break: break-word;
}

