
































































.idt-survey-conference-item {
  display: flex;
  align-items: center;

  &__img {
    height: 50px;
    width: 75px;
    margin-right: .5rem;
  }

  &__start {
    margin-bottom: .25rem;
    font-size: var(--rem-size-14);
    color: var(--black);
  }

  &__title {
    margin-bottom: .25rem;
    font-size: 1rem;
    color: var(--black);
    font-weight: var(--font-weight-bold);
  }

  &__slot-end {
    margin-left: auto;
  }
}
