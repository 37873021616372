.idt-language-editor {
  margin-bottom: 40px;
}

.idt-language-editor .language-editor.active .language-editor__key__local {
  background-color: var(--main-color-alpha30);
}

.idt-language-editor .language-editor.active .language-editor__key__translation {
  border-color: var(--main-color-alpha30);
}

.idt-language-editor .language-editor h3 {
  font-size: 1rem;
}

.idt-language-editor .language-editor__key {
  display: flex;
}

.idt-language-editor .language-editor__key.selected .language-editor__key__local {
  background-color: var(--main-color);
  color: #fff;
}

.idt-language-editor .language-editor__key.selected .language-editor__key__translation {
  border-color: var(--main-color);
}

.idt-language-editor .language-editor__key__local {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 90px;
  background-color: var(--grey-light);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: all .3s;
}

.idt-language-editor .language-editor__key__translation {
  height: 80px;
  width: 100%;
  border: 1px var(--grey-light) solid;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: all .3s;
}

