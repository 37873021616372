.bo-vertical-menu {
  position: fixed;
  width: 90px;
  height: 100%;
  background-color: var(--main-color);
}

@media only screen and (max-width: 639px) {
  .bo-vertical-menu {
    transform: translateX(-100%);
    transition: all .3s ease;
  }

  .bo-vertical-menu.open {
    transform: translateX(0);
  }
}

.bo-vertical-menu__list {
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: center;
  list-style-type: none;
}

.bo-vertical-menu__link {
  display: block;
  position: relative;
  height: 70px;
  padding: 1rem .5rem .5rem;
  color: var(--white);
  font-size: .8rem;
  transition: all .3s ease;
}

.bo-vertical-menu__link--too-long {
  padding: .7rem .5rem .5rem;
  line-height: 14px;
}

.bo-vertical-menu__link--too-long .idt-icon ::v-deep .ideacon {
  margin-bottom: .2rem;
}

.bo-vertical-menu__link:hover,
.bo-vertical-menu__link.active {
  color: var(--main-color);
  background-color: var(--main-color-lighter);
}

