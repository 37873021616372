












































































































































































































































































































































































































































































































































































































.idt-survey-modal {
  &__form {
    &__add-question {
      margin-top: var(--rem-size-12) !important;
    }
    &__schedule {
      max-width: 450px;
    }
    &__results-visibility {
      width: 420px;
    }
    &__tabs {
      margin-bottom: 5px;

      &__button {
        padding: 2px;
        border: solid 2px var(--main-color);
        border-radius: var(--global-radius);
        cursor: pointer;

        &.selected {
          background-color: var(--main-color);
          color: var(--white);
        }
      }
    }
  }
}
