






































































































































































































@import '@/styles/mixins';

.idt-city-input {
  display: inline-block;
  width: 100%;

  &__input-container {
    display: flex;
  }
  &__search {
    width: 75%;
    margin: 0; /* Used to counter the global margin applied to all inputs... */
    border-radius: var(--global-radius);
    outline: none;

    @include respond-to(max-small) {
      width: 65%;
    }

    &--with-timezone {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:focus {
      border-color: var(--grey-darker);
    }
    &.has-error {
      border-color: var(--secondary-color);
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  }
  &__timezone {
    flex: 1;
    margin: 0; /* Used to counter the global margin applied to all inputs... */
    background-color: white;
    border-left: none;
    border-radius: var(--global-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
