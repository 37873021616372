
































































































.idt-user-information {
  line-height: 1.2;

  &__username {
    margin-right: var(--rem-size-4);
    font-weight: var(--font-weight-bold);

    &.username--is-stacked {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__classification {
    font-weight: var(--font-weight-light);

    &__tags {
      display: inline;
      display: content;
    }
    &__tag {
      &:not(:last-child):after {
        content: ', ';
      }
    }
    &__label {
      &:not(:last-child):after {
        content: ', ';
      }
    }
  }
}
