.idt-survey-result-rating__table {
  margin-top: 1rem;
}

.idt-survey-result-rating__table__row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--black);
  cursor: pointer;
}

.idt-survey-result-rating__table__row .progress {
  --progress-color: var(--yellow);
  flex-grow: 1;
  height: 7px;
  margin: 0 var(--rem-size-10);
  border-radius: var(--global-radius);
}

.idt-survey-result-rating__table__row .progress ::v-deep .progress-meter {
  border-radius: var(--global-radius);
}

