



































































































































.fo-project-bar-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__switch ::v-deep .idt-switch-small__label {
    color: var(--white);
    font-weight: var(--font-weight-bold);
  }
}
