.idt-preview-modal .modal-fullscreen {
  height: calc(var(--vh, 1vh)*100);
  background: rgba(0,0,0,0);
}

.idt-preview-modal .modal-fullscreen__content {
  height: calc(var(--vh, 1vh)*100);
  padding: 0;
  overflow: hidden;
}

.idt-preview-modal__wrapper {
  width: 1000px;
  max-width: 100%;
  max-height: calc(var(--vh, 1vh)*100);
}

.idt-preview-modal__header,
.idt-preview-modal__footer {
  display: flex;
  align-items: center;
  height: var(--menu-height);
  padding: 0 var(--rem-size-32);
  background-color: var(--white);
}

.idt-preview-modal__header {
  justify-content: space-between;
  border-bottom: 1px solid var(--grey);
}

.idt-preview-modal__header__close {
  cursor: pointer;
}

.idt-preview-modal__content {
  display: flex;
  overflow: hidden;
  background: var(--grey-light);
}

.idt-preview-modal__preview,
.idt-preview-modal__form {
  padding: var(--rem-size-16) var(--rem-size-32);
}

@media only screen and (max-width: 639px) {
  .idt-preview-modal__preview {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .idt-preview-modal__preview {
    flex: 0 0 50%;
    overflow: auto;
  }
}

.idt-preview-modal__form {
  height: calc(var(--vh, 1vh)*100 - var(--menu-height)*2);
  background: var(--white);
  overflow-y: auto;
}

@media only screen and (max-width: 639px) {
  .idt-preview-modal__form {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .idt-preview-modal__form {
    flex: 0 0 50%;
  }
}

.idt-preview-modal__footer {
  justify-content: flex-end;
  gap: var(--rem-size-8);
  border-top: 1px solid var(--grey);
}

.idt-preview-modal .idt-select-container {
  width: 100%;
}

.idt-preview-modal .vs__dropdown-option--disabled {
  display: none !important;
}

.idt-preview-modal .fade-height-enter,
.idt-preview-modal .fade-height-leave-to {
  max-height: 0;
}

.idt-preview-modal .fade-height-leave,
.idt-preview-modal .fade-height-enter-to {
  max-height: 100px;
}

.idt-preview-modal .fade-height-enter-active,
.idt-preview-modal .fade-height-leave-active {
  transition: max-height .25s ease;
  overflow: hidden;
}

