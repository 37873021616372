.idt-survey-modal-results {
  margin-top: 70px;
  padding: 1rem 0;
  background: var(--grey-lighter);
}

.idt-survey-modal-results__select-talk {
  padding: 8px;
}

.idt-survey-modal-results__select-talk .idt-select-container ::v-deep label {
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

.idt-survey-modal-results__select-talk .idt-select-container ::v-deep .v-select {
  background-color: var(--white);
}

.idt-survey-modal-results__select-talk .idt-select-container ::v-deep .v-select .vs__dropdown-option--highlight {
  background-color: var(--grey-light) !important;
}

.idt-survey-modal-results__select-talk .idt-select-container ::v-deep .v-select:not(.vs--open) .vs__search::placeholder {
  color: var(--black) !important;
}

.idt-survey-modal-results__select-talk .idt-select-container ::v-deep .vs__dropdown-option {
  border-bottom: 1px solid var(--grey-light);
}

