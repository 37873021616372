.export-viewer-stats-modal {
  padding: var(--rem-size-8);
}

.export-viewer-stats-modal__title {
  margin-bottom: var(--rem-size-8);
  font-size: 18px;
  font-weight: 500;
  color: var(--grey-darkest);
}

.export-viewer-stats-modal__content {
  margin-top: var(--rem-size-8);
}

.export-viewer-stats-modal__content__info {
  margin-bottom: var(--rem-size-16);
}

.export-viewer-stats-modal__content__label {
  margin-bottom: var(--rem-size-8);
  font-size: var(--rem-size-16);
  font-weight: 500;
  color: var(--grey-darkest);
}

.export-viewer-stats-modal__content__radio {
  margin: var(--rem-size-16);
  max-width: 250px;
}

.export-viewer-stats-modal__footer {
  display: flex;
  justify-content: flex-end;
}

.export-viewer-stats-modal__footer__button__cancel {
  margin-right: var(--rem-size-8);
}

