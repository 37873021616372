.event-standard-section {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .event-standard-section {
    padding: 3rem 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .event-standard-section {
    padding: 5rem 3rem;
  }
}

.event-standard-section:nth-child(odd) {
  background: var(--grey-lightest);
}

.event-standard-section:nth-child(even) {
  background: var(--white);
}

.event-standard-section__title {
  font-family: var(--title-font-family);
  font-size: 2rem;
  font-weight: var(--font-weight-light);
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (min-width: 640px)and (max-width: 1023px) {
  .event-standard-section__title {
    margin: 0 0 3rem 0;
  }
}

@media only screen and (min-width: 1024px) {
  .event-standard-section__title {
    margin: 0 0 4rem 0;
  }
}

.event-standard-section__title.has-intro {
  margin-bottom: 1rem;
}

.event-standard-section__intro {
  margin-bottom: 3.75rem;
  color: var(--grey-dark);
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

