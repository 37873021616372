




































































@import '@/styles/mixins/index';

.idt-sidebar {
  display: block;
  position: relative;
  width: 0;
  height: 1rem;
  transition: all 0.3s ease;

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--white);
    background-color: var(--dark-color);
    overflow: hidden;

    @include respond-to(max-medium) {
      width: 100%;
      height: calc((var(--vh, 1vh) * 100));
    }
    @include respond-to(medium) {
      width: 360px;
      height: calc(100vh - var(--menu-height));
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: var(--chat-min-with);
      height: 2.75rem;
      padding: var(--rem-size-12) var(--rem-size-16);
      background-color: var(--dark-color-dark);
      box-shadow: 0 0 6px 0 var(--black-alpha20);

      &__title {
        display: flex;
        align-items: center;
        width: 90%;
        font-family: var(--title-font-family);
        text-transform: uppercase;

        &__slot {
          width: 90%;
          margin-left: var(--rem-size-5);
        }
        .ideacons {
          margin-right: var(--rem-size-8);
          font-size: 1.3rem;
          vertical-align: text-top;
        }
      }
      &--white {
        background-color: var(--white);
      }
    }
    &--white {
      color: var(--black);
      background-color: var(--grey-lightest);
    }
    &__slot {
      height: calc(100% - 2.75rem);
    }
  }
  &.open {
    @include respond-to(max-medium) {
      width: 100%;
    }
  }

  &.open:not(.fullsize) {
    @include respond-to(medium) {
      width: 22.5rem;
    }
  }

  &.fullsize {
    width: 100%;
    height: 100%;
    .idt-sidebar__content {
      width: 100%;
      height: 100%;
    }
  }

  @include respond-to(max-xxlarge) {
    flex: 0 0 auto;
  }
}
